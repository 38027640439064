import styled from '@emotion/styled'
import WarningIcon from '@mui/icons-material/Warning'
import { useTranslation } from 'react-i18next'

import { useTypedSelector } from '..'
import Header from '../components/header/Header'
import { FooterPage } from '../components/page/Page'
import { Container } from '../components/page/PageComponents'
import moment from '../lib/moment-fi'
import { selectMessagesSortByKey } from '../reducers/smDisruptionSlice'
import { defaultTextColor, getColor, theme } from '../Theme'
import { Moment } from '../types'
import { SmDisruptionMessage, SmDisruptionMessageType } from '../types/Input'

const Row = styled.div`
  text-decoration: none;
  ${theme.spacing.all('large')};
  background: ${(p) => getColor(p.theme, ['white'], ['nightBlack'])};
  color: ${defaultTextColor};
  box-sizing: border-box;
  border-bottom: 1px solid ${(p) => getColor(p.theme, ['grayBlue'], ['nightSeparator'])};
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
`

const IconRow = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const RowIcon = styled.div`
  color: ${(p) => getColor(p.theme, ['primaryBlue'], ['primaryBlue'])};
`

const Title = styled.div`
  ${theme.spacing.ends('huge')};
  ${theme.text('largest', 'decorative', 'bold')};
  text-align: center;
  color: ${defaultTextColor};
`

const Subtitle = styled.div`
  ${theme.text('large')};
  color: ${defaultTextColor};
`

const SmDisruptionsPage = () => {
  const { t } = useTranslation()

  const messages = useTypedSelector((state) => selectMessagesSortByKey(state, 'occursAt'))
  const closeUrl = '/options'
  const pairedMessages: SmDisruptionMessage[][] = []
  console.log(messages)
  const firstStart = messages.findIndex(
    (e) => e.type === SmDisruptionMessageType.SM_DISTRUPTION_START
  )
  for (let i = firstStart; i < messages.length; i += 2) {
    if (messages[i + 1] === undefined) {
      break
    }
    pairedMessages.push([messages[i], messages[i + 1]])
  }
  pairedMessages.reverse() // order messages new to old
  return (
    <FooterPage>
      <Header
        border={true}
        title={t('pastSmDisruptions')}
        links={{
          prev: { url: closeUrl, disabled: false },
          next: null,
          close: { url: closeUrl, disabled: false },
        }}
      >
        <div></div>
      </Header>
      <Container>
        <Title>{t('pastSmDisruptionsPageTitle')}</Title>
        {pairedMessages.map(([start, end]) => (
          <IconRow key={start.sentAt}>
            <Subtitle>
              {formatDisruptionDuration(moment(start.occursAt), moment(end.occursAt))}
            </Subtitle>
            <RowIcon>
              <WarningIcon />
            </RowIcon>
          </IconRow>
        ))}
      </Container>
    </FooterPage>
  )
}

const formatDisruptionDuration = (start: Moment, end: Moment) => {
  start.local()
  const startFormat = `DD.MM${moment().isSame(start, 'year') ? '' : '.YYYY'} [klo] HH:mm`
  const endFormat = start.isSame(end, 'day') ? 'HH:mm' : startFormat
  return `${start.format(startFormat)} - ${end.format(endFormat)}`
}

export default SmDisruptionsPage
