import { TOGGLE_SCHEDULE_EVENTS, TOGGLE_SCHEDULE_FEEDBACK } from '../actions/schedulepage'
import { SchedulePageAction } from '../actions/schedulepage'
import { SchedulePageState } from '../types'

const initialState = {
  eventsOpen: false,
  feedbackOpen: {},
}

const schedulePageReducer = (
  state: SchedulePageState = initialState,
  action: SchedulePageAction
): SchedulePageState => {
  switch (action.type) {
    case TOGGLE_SCHEDULE_FEEDBACK:
      return {
        ...state,
        feedbackOpen: {
          ...state.feedbackOpen,
          [action.payload.listStartTimestamp]:
            !state.feedbackOpen[action.payload.listStartTimestamp],
        },
      }

    case TOGGLE_SCHEDULE_EVENTS:
      return {
        ...state,
        eventsOpen: !state.eventsOpen,
      }

    default:
      return state
  }
}

export default schedulePageReducer
