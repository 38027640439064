import {
  START_SENDING_CUSTOMER_FEEDBACK,
  UPDATE_CUSTOMER_FEEDBACK,
} from '../actions/customerFeedback'
import { CustomerFeedbackAction } from '../actions/customerFeedback'
import { CustomerFeedbackState } from '../types'

export const initialState = {
  loading: false,
  error: '',
}

const customerFeedbackReducer = (
  state: CustomerFeedbackState = initialState,
  action: CustomerFeedbackAction
): CustomerFeedbackState => {
  switch (action.type) {
    case START_SENDING_CUSTOMER_FEEDBACK: {
      return { loading: true, error: '' }
    }

    case UPDATE_CUSTOMER_FEEDBACK: {
      return { loading: false, error: action.payload.error }
    }

    default:
      return state
  }
}

export default customerFeedbackReducer
