import styled from '@emotion/styled'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useTypedDispatch, useTypedSelector } from '..'
import { createDefectAction, options as optData } from '../actions/create-defect'
import { fetchEquipmentsAction } from '../actions/defect-common'
import { fetchOpenDefectsAction } from '../actions/defects/index'
import CreateDefectComponent from '../components/create-defect/CreateDefectComponent'
import { CreateDefectFormData, FieldConfig, ItemData } from '../components/create-defect/types'
import Page from '../components/page/Page'
import { Container, PageHeader } from '../components/page/PageComponents'
import { HeaderText } from '../components/page/PageComponents'
import { DEFAULT_DEFECT_GROUP } from '../constants'
import ArrowLeft from '../icons/ArrowLeft'
import {
  addIdenticalLabels,
  addIdtoDescription,
  convertToDropDownItem,
  createCriticalityDropdownOptions,
  createEquipmentDropdownOption,
  getDefectCodes,
  uniqByKey,
} from '../lib/createDefectFormUtils'
import { clearDataByKey, resetOptionsStatusByKey } from '../reducers/createDefectSlice'
import { resetEquipmentsStatus } from '../reducers/defectCommonDataSlice'
import { resetDefects } from '../reducers/defectsSlice'
import { getColor, theme } from '../Theme'

const Constrain = styled.div`
  ${theme.layout.flexRow};
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  position: relative;
  box-sizing: border-box;
  width: 100%;
`

const PaddedContainer = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`

const BackIcon = styled.div`
  align-content: start;
  display: flex;
  padding-left: 8px;
  color: ${(p) => getColor(p.theme, ['black'], ['white'])};
`

const getDefaultFormValues = (
  defectGroup: string,
  installationGroup: string,
  vehicleType: string
): CreateDefectFormData => ({
  issuedTime: new Date(),
  comment: '',
  digitalAssetUuids: [],
  defectGroup,
  installationGroup,
  installationItem: '',
  vehiclePosition: '',
  productGroup: '',
  defectCode: '',
  defectType: '',
  criticality: '',
  restrictionCode: '',
  affectToUsage: '',
  installationSerialNumber: '',
  vehicleType,
  vehicleSerialNumber: installationGroup,
  type: 'equipment',
})

type Equipment = {
  installationGroup: string
  description: string
  itemId: string
  fleet?: string
}

const TEMP_ROOT_NODE = {
  itemId: '',
  serialNumber: '',
  parentSerialNumber: '',
  description: '',
  parentItemId: '',
  uniqueId: '',
  parentUniqueId: '',
  seperator: '|',
} as const

const getRootNode = (selectedEquipment?: Equipment): ItemData =>
  selectedEquipment
    ? {
        itemId: selectedEquipment.itemId,
        serialNumber: selectedEquipment.installationGroup,
        parentSerialNumber: '',
        description: selectedEquipment.description,
        parentItemId: '',
        uniqueId: `${selectedEquipment.itemId}|${selectedEquipment.installationGroup}`,
        parentUniqueId: '',
        seperator: '|',
      }
    : TEMP_ROOT_NODE

const CreateDefectPage = () => {
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()

  const found = useTypedSelector((state) => state.found)
  const createDefectStatus = useTypedSelector((state) => state.defect.status)
  const defectGroups = useTypedSelector((state) => state.defect.defectGroups)
  const items = useTypedSelector((state) => state.defect.items)
  const positions = useTypedSelector((state) => state.defect.positions)
  const defectTrees = useTypedSelector((state) => state.defect.defectTrees)
  const restrictions = useTypedSelector((state) => state.defect.restriction)
  const affectToUsage = useTypedSelector((state) => state.defect.affectToUsage)
  const files = useTypedSelector((state) => state.defect.assetIdentifiers)
  const preSelectedSerialNumber = useTypedSelector((state) => state.defect.preSelectedSerialNumber)
  const equipments = useTypedSelector((state) => state.defectCommonData.equipments)

  const [defaultDefectGroup, setDefaultDefectGroup] = useState<string>('')
  const [selectedEquipment, setSelectedEquipment] = useState<Equipment>()
  const [defectTypes, setDefectTypes] = useState<string[]>([])
  const [positionRequired, setPositionRequired] = useState(false)
  const [attachmentRequired, setAttachmentRequired] = useState<boolean>(false)
  const [openDefectsEnabled] = useState<boolean>(
    found && found?.match?.location?.state?.from !== '/search/defects'
  )

  // check if any options fetch failed
  const options = [
    {
      status: defectGroups.status,
      clearFunc: () => dispatch(resetOptionsStatusByKey('defectGroups')),
    },
    {
      status: items.status,
      clearFunc: () => dispatch(resetOptionsStatusByKey('items')),
    },
    {
      status: positions.status,
      clearFunc: () => dispatch(resetOptionsStatusByKey('positions')),
    },
    {
      status: defectTrees.status,
      clearFunc: () => dispatch(resetOptionsStatusByKey('defectTrees')),
    },
    {
      status: restrictions.status,
      clearFunc: () => dispatch(resetOptionsStatusByKey('restriction')),
    },
    {
      status: affectToUsage.status,
      clearFunc: () => dispatch(resetOptionsStatusByKey('affectToUsage')),
    },
    {
      status: equipments.status,
      clearFunc: () => dispatch(resetEquipmentsStatus()),
    },
  ]

  const failedOptions = options.filter((o) => o.status === 'failed').map((o) => o.clearFunc)

  const methods = useForm<CreateDefectFormData>({
    defaultValues: getDefaultFormValues(
      defaultDefectGroup,
      selectedEquipment?.installationGroup ?? '',
      selectedEquipment?.itemId ?? ''
    ),
    mode: 'onTouched',
  })

  const { setValue, getValues, resetField, reset } = methods

  const resetForm = useCallback(
    (resetEquipment = false): void => {
      reset(
        getDefaultFormValues(
          defaultDefectGroup,
          selectedEquipment?.installationGroup ?? '',
          selectedEquipment?.itemId ?? ''
        )
      )
      if (resetEquipment) setSelectedEquipment(undefined)

      setPositionRequired(false)
      setAttachmentRequired(false)
    },
    [reset, defaultDefectGroup, selectedEquipment]
  )

  useEffect(() => {
    setDefaultDefectGroup(
      defectGroups?.data?.some((g) => g.id === DEFAULT_DEFECT_GROUP) ? DEFAULT_DEFECT_GROUP : ''
    )
    resetForm()
  }, [defectGroups?.data, resetForm, defaultDefectGroup])

  useEffect(() => {
    if (preSelectedSerialNumber && equipments?.data) {
      const preSelectedEquipment = equipments.data.find(
        (equipment) => equipment.installationGroup === preSelectedSerialNumber
      )
      if (preSelectedEquipment) {
        setSelectedEquipment(preSelectedEquipment)
        setValue('installationGroup', preSelectedEquipment.installationGroup)
      }
    }
  }, [equipments?.data, setValue, preSelectedSerialNumber])

  const resetDependentFields = useCallback(
    (field: keyof CreateDefectFormData): void => {
      switch (field) {
        // @ts-expect-error: fallthrough is intentional
        case 'vehicleSerialNumber':
          resetField('installationSerialNumber')
          resetField('installationItem')
        // @ts-expect-error: fallthrough is intentional
        case 'installationSerialNumber':
          resetField('productGroup')
        // @ts-expect-error: fallthrough is intentional
        case 'productGroup':
          resetField('defectType')
        // eslint-disable-next-line no-fallthrough
        case 'defectType':
          resetField('defectCode')
          resetField('criticality')
      }
    },
    [resetField]
  )

  // onChange handlers
  const defectCodeChanged = (value?: string): void => {
    resetDependentFields('productGroup')
    if (!value) {
      setDefectTypes([])
      return
    }
    setDefectTypes(
      defectTrees?.data
        ?.filter((defect) => defect.productGroups === value)
        ?.map((defect) => defect.defectType) ?? []
    )
  }

  const vehicleChanged = useCallback(
    (value?: string, skipReset?: boolean): void => {
      if (!skipReset) resetDependentFields('vehicleSerialNumber')

      const newValue = value ?? ''
      const equipment = equipments?.data?.find(
        (equipment) => equipment.installationGroup === newValue
      )
      const itemId = equipment?.itemId ?? ''
      setSelectedEquipment(equipment)
      // vehicleType is used as installationItem if no sub device is selected
      setValue('type', 'equipment')
      setValue('vehicleType', itemId)
      // use productGroup for serial number when only main device used
      setValue('vehicleSerialNumber', newValue)

      if (newValue === '') {
        dispatch(resetDefects())
        dispatch(clearDataByKey('items'))
        dispatch(clearDataByKey('defectTrees'))
        dispatch(clearDataByKey('positions'))
        setDefectTypes([])

        return
      }

      if (openDefectsEnabled) {
        dispatch(fetchOpenDefectsAction({ installationGroups: newValue }))
      }

      dispatch(optData.fetchItemsAction({ filter: newValue, itemId }))
      dispatch(optData.fetchDefectTreesAction(itemId))
      dispatch(optData.fetchPositionsAction(''))
    },
    [dispatch, equipments.data, resetDependentFields, setValue, openDefectsEnabled]
  )

  const defectTypeChanged = (value?: string): void => {
    resetDependentFields('defectType')
    const defectCode = getValues('productGroup')
    const defectTree = defectTrees?.data?.find(
      (defect) => defect.productGroups === defectCode && defect.defectType === value
    ) ?? { criticality: '', defectCode: '' }
    setValue('criticality', defectTree.criticality)
    setValue('defectCode', defectTree.defectCode)
  }

  const defectGroupChanged = (value?: string): void => {
    if (value === 'IL') {
      setPositionRequired(true)
      setAttachmentRequired(true)
    } else if (value === 'VA') {
      setPositionRequired(false)
      setAttachmentRequired(true)
    } else {
      setPositionRequired(false)
      setAttachmentRequired(false)
    }
  }

  const attachmentsChanged = (): void => {
    if (files.data) {
      setValue('digitalAssetUuids', Object.keys(files.data))
    }
  }

  const installationItemChanged = (value?: string): void => {
    resetDependentFields('installationSerialNumber')
    const item = items.data?.[value ?? '']
    const serialNumber = item?.serialNumber ?? ''
    setValue('installationSerialNumber', serialNumber ?? '')

    dispatch(clearDataByKey('defectTrees'))
    dispatch(clearDataByKey('positions'))

    if (item) {
      setValue('type', 'item')
      dispatch(optData.fetchDefectTreesAction(item.itemId))
      dispatch(optData.fetchPositionsAction(''))
      return
    }

    vehicleChanged(selectedEquipment?.installationGroup, true)
  }
  // onChange handlers end

  const onSubmit = (payload: CreateDefectFormData): void => {
    payload.installationItem = items.data?.[payload.installationItem]?.itemId ?? ''
    dispatch(createDefectAction(payload))
  }

  const scrollToTop = () => {
    document.getElementById('create-defect-scroll-anchor')?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    dispatch(fetchEquipmentsAction(null))
    dispatch(optData.fetchDefectGroupsAction(null))
    dispatch(optData.fetchRestrictionsAction(null))
    dispatch(optData.fetchAffectToUsageAction(null))
  }, [dispatch])

  useEffect(() => {
    if (preSelectedSerialNumber && equipments?.data?.length) {
      vehicleChanged(preSelectedSerialNumber, false)
    }
  }, [dispatch, equipments, preSelectedSerialNumber, vehicleChanged])

  const fields: FieldConfig = {
    defectGroup: {
      options: defectGroups?.data?.map((o) => convertToDropDownItem(o, 'id', 'description')) ?? [],
      onChange: defectGroupChanged,
      required: true,
      status: defectGroups.status,
    },
    installationGroup: {
      options: equipments?.data?.map((o) => createEquipmentDropdownOption(o)) ?? [],
      onChange: vehicleChanged,
      required: true,
      status: equipments.status,
    },
    installationItem: {
      options: items.data ?? {},
      root: getRootNode(selectedEquipment),
      onChange: installationItemChanged,
      status: items.status,
    },
    vehiclePosition: {
      options: uniqByKey(positions.data ?? [], 'description').map((o) =>
        convertToDropDownItem(o, 'id', 'description')
      ),
      required: positionRequired,
      status: positions.status,
    },
    productGroup: {
      options: getDefectCodes(defectTrees.data ?? []),
      onChange: defectCodeChanged,
      required: true,
      status: defectTrees.status,
      multiline: true,
    },
    defectType: {
      options: addIdenticalLabels([...new Set(defectTypes)]),
      onChange: defectTypeChanged,
      required: true,
      status: defectTrees.status,
    },
    criticality: {
      options: createCriticalityDropdownOptions(t),
      status: 'succeeded',
    },
    restrictionCode: {
      options: addIdtoDescription(restrictions.data || []),
      status: restrictions.status,
    },
    affectToUsage: {
      options: addIdtoDescription(affectToUsage.data || []),
      status: affectToUsage.status,
    },
    digitalAssetUuids: {
      required: attachmentRequired,
      onChange: attachmentsChanged,
      files: files.data ?? {},
      status: files.status,
    },
    issuedTime: {},
    comment: {},
  }

  useEffect(() => {
    scrollToTop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForm])

  return (
    <Page overflowVisible={true}>
      <div id="create-defect-scroll-anchor" />
      <Container>
        <PageHeader onClick={() => window.history.back()} height="64px">
          <Constrain>
            <BackIcon>
              <ArrowLeft iconSize="normal" />
            </BackIcon>
            <HeaderText>{t('defect.create.title')}</HeaderText>
          </Constrain>
        </PageHeader>
        <PaddedContainer>
          <CreateDefectComponent
            fields={fields}
            onSubmit={onSubmit}
            createDefectStatus={createDefectStatus}
            formMethods={methods}
            failedToFetchOptions={failedOptions}
            resetForm={resetForm}
            selectedEquipment={selectedEquipment}
            scrollToTop={scrollToTop}
            openDefectsEnabled={openDefectsEnabled}
          />
        </PaddedContainer>
      </Container>
    </Page>
  )
}

export default CreateDefectPage
