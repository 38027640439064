import { Box, Typography } from '@mui/material'
import { differenceInCalendarDays, format } from 'date-fns'

import { formatDuration } from '../../lib/time'
import MuiDurationIcon from '../calendar/MuiDurationIcon'

const DATE_FORMAT_STRING = 'HH:mm dd.MM.yyyy'
const TIME_FORMAT_STRING = 'HH:mm'

export const getFormattedDateTime = (date: string) =>
  `${format(new Date(date), DATE_FORMAT_STRING)}`

const formatDateRange = (fromDate: string, toDate: string) => {
  const from = new Date(fromDate)
  const to = new Date(toDate)

  if (differenceInCalendarDays(from, to))
    return `${format(from, DATE_FORMAT_STRING)} - ${format(to, DATE_FORMAT_STRING)}`
  return `${format(from, TIME_FORMAT_STRING)} - ${format(to, DATE_FORMAT_STRING)}`
}

type Props = {
  startTime?: string
  finishTime?: string
  duration: string
}

const DurationRow = ({ startTime, finishTime, duration }: Props): JSX.Element => {
  return (
    <Box sx={(theme) => ({ ...theme.layout.flexRow, gap: theme.spacing('smaller') })}>
      {startTime && finishTime && <Typography>{formatDateRange(startTime, finishTime)}</Typography>}
      <Box sx={(theme) => ({ ...theme.layout.flexRow, gap: theme.spacing('smaller') })}>
        <MuiDurationIcon />
        <Typography>{formatDuration(duration)}</Typography>
      </Box>
    </Box>
  )
}

export default DurationRow
