import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { fetchTowingFormStateById } from '../actions/api'
import ActionError from '../components/ActionError'
import {
  BackIcon,
  Container,
  Content,
  Footer,
  FooterBackIcon,
  FooterText,
  HeaderText,
  PageHeader,
} from '../components/page/PageComponents'
import TowingConfirmButton from '../components/towing/form/TowingConfirmButton'
import TowingFormRoot from '../components/towing/form/TowingFormRoot'
import ArrowLeft from '../icons/ArrowLeft'
import moment from '../lib/moment-fi'
import { formActions, formStates, stepStates } from '../lib/towingUtils'
import { getColor, theme } from '../Theme'
import type { Action, AppState, Dispatch, TowingFormContent, TowingFormStateChange } from '../types'

const DeletionContainer = styled.div`
  margin-right: ${theme.spacing.sizes.small};
  margin-left: ${theme.spacing.sizes.small};
`

const DeletionHeader = styled.h2`
  color: ${(p) => getColor(p.theme, ['black'], ['white'])};
`

const DeletionText = styled.p`
  color: ${(p) => getColor(p.theme, ['black'], ['white'])};
  font-size: ${theme.font.mediumSmall};
`

const ExpandButtonContainer = styled.div`
  padding-bottom: ${theme.spacing.sizes.small};
  margin-right: ${theme.spacing.sizes.small};
  margin-left: ${theme.spacing.sizes.small};
  margin-top: ${theme.spacing.sizes.large};
  margin-bottom: ${theme.spacing.sizes.biggest};
  & + & {
    margin-top: ${theme.spacing.sizes.smaller};
  }
  gap: 20px;
`

const getFormElementKeys = (elements) => {
  const branchPattern = /^branch[\d]+$/
  const selections = elements.filter((el) => el.contentType === 'towingSelection')
  const keys = elements.flatMap((el) => el.key)
  if (selections && selections.length > 0) {
    const branches = selections.flatMap((el) =>
      Object.keys(el).filter((key) => branchPattern.test(key))
    )
    const selectionsElements = selections
      .flatMap((el) =>
        branches.flatMap((branch) => {
          return el[branch] ? el[branch].elements : null
        })
      )
      .filter((el) => !!el)
    if (selectionsElements.length > 0) {
      return keys.concat(getFormElementKeys(selectionsElements))
    }
  }
  return keys
}

const DeletedActionList = ({ t, actions }) => {
  return (
    <DeletionContainer>
      <DeletionHeader>Lomakkeelta poistuneet aiemmin tehdyt tehtävät:</DeletionHeader>
      {actions.map((action) => (
        <DeletionText key={action[0]}>{`${action[0]}: ${t(
          `towingStates.${action[1]}`
        )}`}</DeletionText>
      ))}
    </DeletionContainer>
  )
}

type Props = {
  t: TFunction
  loadingForm: boolean
  formError: string
  towingForm: TowingFormContent
  towingStatus: TowingFormStateChange | Record<any, any> | string
  latestNonHandoverStatus: string
  id: string
  vehicleType: string
  vehicleNumber: string
  formType: string
  error: string
  showStop: boolean
  elementStates: any
  fetchTowingForm: () => any
  fetchTowingFormStateById: (arg1: string) => any
}

const TowingPage = ({
  t,
  towingForm,
  loadingForm,
  formError,
  id,
  vehicleNumber,
  formType,
  towingStatus,
  latestNonHandoverStatus,
  error,
  showStop,
  elementStates,
  fetchTowingFormStateById,
}: Props) => {
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (id) fetchTowingFormStateById(id)
  }, [fetchTowingFormStateById, id])

  const formElementKeys =
    towingForm &&
    towingForm.setupRoot &&
    towingForm.startRoot &&
    towingForm.stopRoot &&
    towingForm.finishRoot
      ? getFormElementKeys(towingForm.setupRoot.elements)
          .concat(towingForm.startRoot.elements)
          .concat(towingForm.stopRoot.elements)
          .concat(towingForm.finishRoot.elements)
      : []

  const actionsFromDeletedForm =
    elementStates.length > 0 &&
    Object.entries(elementStates).filter(
      ([key, value]: [any, any]) =>
        (value === stepStates.DONE || value === stepStates.COUNTERED_IN_FINISH) &&
        !formElementKeys.includes(key)
    )

  return (
    <Container>
      <PageHeader onClick={() => window.history.back()} height="64px">
        <BackIcon>
          <ArrowLeft iconSize="normal" />
        </BackIcon>
        <HeaderText>
          {`${(towingForm && towingForm.name) || ''} ${vehicleNumber || ''} ${
            formType === 'setup' ? t('towingSetup').toLowerCase() : t('towingFinish').toLowerCase()
          }`}
        </HeaderText>
      </PageHeader>
      <Content>
        {error === 'errors.towingFormState.conflictError' ||
        error === 'errors.towingFormState.validationError' ||
        formError !== '' ? (
          <Fragment>
            <ActionError
              error={formError !== '' ? formError : error}
              action={() => fetchTowingFormStateById(id)}
              actionText="reloadTowingForm"
            />
          </Fragment>
        ) : (
          towingForm &&
          vehicleNumber !== '' && (
            <Fragment>
              {actionsFromDeletedForm && actionsFromDeletedForm.length > 0 && (
                <DeletedActionList t={t} actions={actionsFromDeletedForm} />
              )}
              {formType === 'setup' && (
                <Fragment>
                  {towingForm.setupRoot && (
                    <TowingFormRoot
                      root={towingForm.setupRoot}
                      formType="setup"
                      formAction={formActions.EDIT}
                    />
                  )}
                  {towingForm.startRoot &&
                    (latestNonHandoverStatus === formStates.SETUP_DONE ||
                      latestNonHandoverStatus === formStates.START_IN_PROGRESS ||
                      latestNonHandoverStatus === formStates.IN_TOWING) && (
                      <TowingFormRoot
                        root={towingForm.startRoot}
                        formType="start"
                        formAction={formActions.EDIT}
                      />
                    )}
                </Fragment>
              )}
              {formType === 'finish' && (
                <Fragment>
                  {towingForm.stopRoot && showStop && (
                    <Fragment>
                      {towingForm.setupRoot && (
                        <TowingFormRoot
                          root={towingForm.setupRoot}
                          formType="setup"
                          formAction={
                            expanded
                              ? formActions.SHOW_SELECTIONS_AND_STEPS
                              : formActions.SHOW_SELECTIONS_WITHOUT_STEPS
                          }
                        />
                      )}
                      <ExpandButtonContainer>
                        <TowingConfirmButton
                          buttonText={
                            expanded ? t('towingFormHideSteps') : t('towingFormShowSteps')
                          }
                          disabled={false}
                          confirmed={false}
                          secondary={true}
                          onClick={() => {
                            setExpanded(!expanded)
                          }}
                        />
                      </ExpandButtonContainer>
                      <TowingFormRoot
                        root={towingForm.stopRoot}
                        counterRoot={towingForm.startRoot}
                        formType="stop"
                        formAction={formActions.EDIT}
                      />
                    </Fragment>
                  )}
                  {towingForm.finishRoot &&
                    (towingStatus === formStates.SETUP_DONE ||
                      towingStatus === formStates.FINISH_IN_PROGRESS ||
                      towingStatus === formStates.FINISHED) && (
                      <TowingFormRoot
                        root={towingForm.finishRoot}
                        counterRoot={towingForm.setupRoot}
                        formType="finish"
                        formAction={formActions.EDIT}
                      />
                    )}
                </Fragment>
              )}
            </Fragment>
          )
        )}
        {loadingForm && <HeaderText>{loadingForm}</HeaderText>}
      </Content>
      <Footer onClick={() => window.history.back()}>
        <FooterBackIcon>
          <ArrowLeft iconSize="normal" />
        </FooterBackIcon>
        <FooterText>{t('back')}</FooterText>
      </Footer>
    </Container>
  )
}

const mapStateToProps = (state: AppState) => {
  const error = state.towingFormState.error
  const id = state.found.match.params.id
  const formType = state.found.match.params.formType
  const loadingForm = state.towingForm.loading
  const formError = state.towingForm.error
  const towingForm = state.towingForm.towingForm
  const vehicleNumber = state.towingFormState.vehicleNumber

  const stateChangesLatestFirst =
    state.towingFormState.stateChanges &&
    state.towingFormState.stateChanges.slice().sort((firstEl, secondEl) => {
      return moment(secondEl.changedAt).diff(moment(firstEl.changedAt))
    })

  const shouldShowStop = (stateChangesLatestFirst) => {
    if (stateChangesLatestFirst && stateChangesLatestFirst.length > 0) {
      if (
        stateChangesLatestFirst[0].status === formStates.IN_TOWING ||
        stateChangesLatestFirst[0].status === formStates.STOP_IN_PROGRESS
      ) {
        return true
      }
      const stopInProgressIndex = stateChangesLatestFirst.findIndex(
        (s) => s.status === formStates.STOP_IN_PROGRESS
      )
      if (
        stopInProgressIndex > 0 &&
        stateChangesLatestFirst[stopInProgressIndex - 1].status === formStates.SETUP_DONE
      ) {
        return true
      }
    }
    return false
  }

  const towingStatus =
    stateChangesLatestFirst && stateChangesLatestFirst.length > 0
      ? stateChangesLatestFirst[0].status
      : {}
  const showStop = shouldShowStop(stateChangesLatestFirst)

  const elementStates = state.towingFormState.actionsAndSelectionsByKey
  const latestNonHandoverState =
    stateChangesLatestFirst &&
    stateChangesLatestFirst.find((stateChange) => stateChange.status !== formStates.HANDOVER)
  const latestNonHandoverStatus = (latestNonHandoverState && latestNonHandoverState.status) || ''

  return {
    id,
    formType,
    vehicleNumber,
    loadingForm,
    formError,
    towingForm,
    towingStatus,
    latestNonHandoverStatus,
    error,
    showStop,
    elementStates,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTowingFormStateById: (id) => dispatch(fetchTowingFormStateById(id) as unknown as Action),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TowingPage))
