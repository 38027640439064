import React from 'react'

type Props = {
  height: number
}

const Logo = ({ height = 70 }: Props) => {
  return (
    <svg width={height * (169 / 70)} height={height} viewBox="0 0 169 70">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M144.05 70c-9.48 0-13.628-4.464-16.39-8.684l-1.664-2.572c-2.815-4.493-5.696-7.453-13.705-7.453H91.928l7.18-11.037c2.068-3.176 6.362-8.585 16.469-8.585h23.557c2.049 0 6.204-1.636 6.204-5.984 0-4.631-4.173-6.089-6.204-6.089H108.25c-10.678 0-14.766 6.092-16.407 8.622L70.36 61.219c-3.583 5.588-8.785 8.763-15.264 8.763-6.448 0-11.686-3.578-15.251-8.763L0 0h16.268c8.16 0 13.122 3.477 16.478 8.642l22.292 34.263L77.294 8.657C80.024 4.337 84.72 0 93.78 0h48.76c14.33 0 25.947 11.357 25.947 25.685 0 11.987-7.83 21.865-18.892 24.84l-.986.264L161.11 70H144.05"
      />
    </svg>
  )
}

export default Logo
