export type UpdateCalendar = {
  type: 'UPDATE_CALENDAR'
  payload: string
}
export const UPDATE_CALENDAR = 'UPDATE_CALENDAR'
export const updateCalendar = (url: string): UpdateCalendar => ({
  type: UPDATE_CALENDAR,
  payload: url,
})

export type SetCalendarError = {
  type: 'CALENDAR_ERROR'
  payload: string
}
export const CALENDAR_ERROR = 'CALENDAR_ERROR'
export const calendarError = (error: string): SetCalendarError => ({
  type: CALENDAR_ERROR,
  payload: error,
})

export type StartFetchingCalendar = {
  type: 'START_FETCHING_CALENDAR'
}
export const START_FETCHING_CALENDAR = 'START_FETCHING_CALENDAR'
export const startFetchingCalendar = (): StartFetchingCalendar => ({
  type: START_FETCHING_CALENDAR,
})

export type CalendarAction = UpdateCalendar | SetCalendarError | StartFetchingCalendar
