import {
  START_SIGNING_IN,
  UPDATE_SIGN_IN_STATUS,
  UPDATE_SIGN_IN_STATUSES,
} from '../actions/sign-in-status'
import { SignInStatusAction } from '../actions/sign-in-status'
import { SignInStatus, SignInStatusState } from '../types'
import { replaceWithId } from './Common'

export const initialState = {
  byId: {},
}

const signInStatusReducer = (
  state: SignInStatusState = initialState,
  action: SignInStatusAction
): SignInStatusState => {
  let id: string, status: SignInStatus

  switch (action.type) {
    case START_SIGNING_IN:
      id = action.payload.id
      status = state.byId[id] || { id, state: 'open', loading: false, error: '' }

      return {
        byId: replaceWithId(state.byId, { ...status, loading: true, error: '' }),
      }

    case UPDATE_SIGN_IN_STATUS:
      return {
        byId: replaceWithId(state.byId, action.payload),
      }

    case UPDATE_SIGN_IN_STATUSES:
      return {
        byId: action.payload.reduce(
          (byId: Record<string, SignInStatus>, s: SignInStatus) => ({ ...byId, [s.id]: s }),
          initialState.byId
        ),
      }

    default:
      return state
  }
}

export default signInStatusReducer
