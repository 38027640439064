import React from 'react'

import exhaustiveCheck from '../../lib/exhaustiveCheck'
import { AttachmentUpload } from './AttachmentUploadContainer'
import { FormInputAutocomplete } from './input/FormInputAutocomplete'
import { FormInputDropdown } from './input/FormInputDrowpdown'
import { FormInputText } from './input/FormInputText'
import { FormInputTreeSelection } from './input/FormInputTreeSelection'
import { FieldData, inputTypes } from './types'

export const fieldPicker = (field: FieldData): JSX.Element => {
  const type = field.type
  switch (type) {
    case inputTypes.select:
      return (
        <FormInputDropdown
          isDisabled={field.disabled ?? field.options.length === 0}
          label={field.title ?? ''}
          name={field.key}
          options={field.options}
          required={field.required}
          customOnChange={field.onChange}
          status={field.status}
        />
      )

    case inputTypes.auto:
      return (
        <FormInputAutocomplete
          isDisabled={field.disabled ?? field.options.length === 0}
          label={field.title ?? ''}
          defaultValue={field.value ?? ''}
          name={field.key}
          options={field.options}
          required={field.required}
          customOnChange={field.onChange}
          status={field.status}
          multiline={field.multiline}
        />
      )

    case inputTypes.input:
      return (
        <FormInputText
          label={field.title ?? ''}
          name={field.key}
          multiline
          required={field.required}
        />
      )

    case inputTypes.upload:
      return (
        <AttachmentUpload
          instructions={field.instructions}
          fileWord={field.fileWord}
          onChange={field.onChange}
          files={field.files}
          status={field.status}
          required={field.required}
          name={field.key}
          label={field.title ?? ''}
        />
      )

    case inputTypes.item:
      return (
        <FormInputTreeSelection
          isDisabled={field.disabled ?? Object.keys(field.options).length === 0}
          label={field.title ?? ''}
          name={field.key}
          options={field.options}
          rootEquipment={field.root}
          required={field.required}
          customOnChange={field.onChange}
          status={field.status}
        />
      )

    default:
      return exhaustiveCheck(type)
  }
}
