import styled from '@emotion/styled'
import React from 'react'

import moment from '../lib/moment-fi'
import { getColor, theme } from '../Theme'
import { Timestamp } from '../types'
import CalendarIllustration from './calendar/CalendarIllustration'

const Title = styled.div`
  ${theme.text('normal', 'decorative', 'bold')};
  color: ${(p) => getColor(p.theme, ['black'], ['grayPink'])};
  position: absolute;
  top: ${theme.spacing.sizes.large};
  left: ${theme.spacing.sizes.larger};
  text-transform: capitalize;
`

type Props = {
  startsAt: Timestamp
}

const NextMonth = ({ startsAt }: Props) => (
  <CalendarIllustration startsAt={startsAt}>
    <Title>{moment(startsAt).format('MMMM YYYY')}</Title>
  </CalendarIllustration>
)

export default NextMonth
