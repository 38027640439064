import { createSelector, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '..'
import { fetchSmDisruptionsAction } from '../actions/sm-disruptions'
import { ACTIVE_SM_DISRUPTION_END_MESSAGE_DURATION } from '../constants'
import moment from '../lib/moment-fi'
import { ActionStatus, SmDisruptionMessage, SmDisruptionMessageType } from '../types/Input'

export interface SmDisruptionState {
  status: ActionStatus
  messages: Record<string, SmDisruptionMessage>
  lastOrigin?: string
  error?: string
}

const initialState: SmDisruptionState = {
  status: 'none',
  messages: {},
}

export const smDisruptionSlice = createSlice({
  name: 'sm-disruptions',
  initialState,
  reducers: {
    addSmDisruptionMessage: (state, { payload }) => {
      state.messages[createId(payload)] = payload
      state.lastOrigin = 'push'
    },
    dismissSmDisruptionsNotification: (state) => {
      state.lastOrigin = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSmDisruptionsAction.pending, (state) => {
      state.status = 'loading'
      state.error = undefined
    })

    builder.addCase(fetchSmDisruptionsAction.fulfilled, (state, { payload }) => {
      for (const msg of payload) {
        state.messages[createId(msg)] = msg
      }
      state.lastOrigin = 'fetch'
      state.status = 'succeeded'
    })

    builder.addCase(fetchSmDisruptionsAction.rejected, (state, { payload }) => {
      if (payload) {
        state.error = payload.message
      }
      state.status = 'failed'
    })
  },
})

const createId = (msg: SmDisruptionMessage) => `${msg.type}#${msg.messageId}`

export const { addSmDisruptionMessage, dismissSmDisruptionsNotification } =
  smDisruptionSlice.actions

export default smDisruptionSlice.reducer
const selectSmDisruptions = (state: RootState) => state.smDisruptions

export const selectMessages = createSelector(selectSmDisruptions, (state) =>
  Object.values(state.messages)
)

export const selectMessagesSortByKey = createSelector(
  [
    selectMessages,
    (state: RootState, key: keyof SmDisruptionMessage): keyof SmDisruptionMessage => key,
  ],
  (messages: SmDisruptionMessage[], key: keyof SmDisruptionMessage) =>
    [...messages].sort((a, b) => a[key].localeCompare(b[key]))
)

const isDisruptionActive = (activeMessageCandidate?: SmDisruptionMessage): boolean => {
  if (!activeMessageCandidate) return false
  if (activeMessageCandidate.type === SmDisruptionMessageType.SM_DISTRUPTION_START) {
    return true
  } else if (
    moment(activeMessageCandidate.sentAt).isAfter(
      moment().subtract(ACTIVE_SM_DISRUPTION_END_MESSAGE_DURATION)
    )
  )
    return true
  else return false
}

export const selectSmDisruptionNotification = createSelector(
  (state) => selectMessagesSortByKey(state, 'sentAt').reverse(),
  (messages: SmDisruptionMessage[]) => (isDisruptionActive(messages[0]) ? messages[0] : null)
)
