import { Router } from 'found'
import { TFunction } from 'i18next'
import { isEqual, uniq } from 'lodash'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import { EMPTY_CN_CODES } from '../../constants'
import type { Car, DangerousGood } from '../../types'
import Switch from './Switch'
import {
  ContentCol,
  SectionTitle,
  StickyCol,
  Table,
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableRow,
} from './TableComponents'

type Props = {
  cars: Array<Car>
  t: TFunction
  color: string
  sourceSystem: string
  router: Router
  shortNumbers: boolean
  onToggle: () => void
}

type State = {
  shortNumbers: boolean
}

//The purpose of this function is to create an array that can be used for table formatting
//E.g. if we have cars 1-6 with 'dangerousGoods' descriptions of type A and B and N for none
//and they are arranged [ A | A | N | N | B | B ], then this function would create the array
//[ 2 | 0 | 1 | 1 | 2 | 0 ] and using rowspan later on we would display the first table in
//the following form [   A   | N | N |   B   ]
const createRowspanTable = (cars: Array<Car>): Array<number> => {
  const rowspans: number[] = []
  cars.forEach((car, i) => {
    const prev = i > 0 ? cars[i - 1] : null
    const prevUnnos =
      prev && prev.dangerousGoods ? uniq(prev.dangerousGoods.map((dg) => dg.unno)).sort() : []
    const currUnnos =
      car && car.dangerousGoods ? uniq(car.dangerousGoods.map((dg) => dg.unno)).sort() : []

    if (currUnnos.length > 0 && isEqual(prevUnnos, currUnnos)) {
      for (let x = i; x >= 0; x--) {
        if (rowspans[x] > 0) {
          rowspans[x] += 1
          rowspans.push(0)
          break
        }
      }
    } else {
      rowspans.push(1)
    }
  })
  return rowspans
}

const createAdditionalInfoText = (dangerousGoods: Array<DangerousGood>) => {
  const cargoElements: JSX.Element[] = []
  const unnos: string[] = []
  dangerousGoods.forEach((dangerousGood) => {
    if (!unnos.includes(dangerousGood.unno)) {
      cargoElements.push(
        <p key={cargoElements.length}>
          VAK: <b>{dangerousGood.description}</b>, {dangerousGood.hazardLabel}, YK:{' '}
          <b>{dangerousGood.unno}</b>, Tunnus: <b>{dangerousGood.hazardNumber}</b>
        </p>
      )
      unnos.push(dangerousGood.unno)
    }
  })
  return <div>{cargoElements}</div>
}

class CarList extends Component<Props, State> {
  changeNumberFormat = () => {
    localStorage.setItem('shortNumbers', this.props.shortNumbers ? 'false' : 'true')
    this.props.onToggle()
  }

  render() {
    const { t, cars, color, sourceSystem, router, shortNumbers }: Props = this.props
    const rowspans = createRowspanTable(cars)
    return (
      <div>
        <SectionTitle>{t('carInformation')}</SectionTitle>
        <TableContainer stickyCol>
          <Table width="600px">
            <tbody>
              <TableHeaderRow color={color}>
                {sourceSystem === 'KAPU' ? (
                  <StickyCol
                    colSpan={2}
                    color={color}
                    left="0"
                    width="150px"
                    onClick={this.changeNumberFormat}
                  >
                    {t('carNumber') + '\t'}
                    <Switch marginLeft="5px" ready={shortNumbers} offline={false} />
                  </StickyCol>
                ) : (
                  <StickyCol colSpan={2} color={color} left="0" width="150px">
                    {t('carNumber')}
                  </StickyCol>
                )}
                {sourceSystem !== 'RCS' && <TableHeader>{t('kj')}</TableHeader>}
                <TableHeader>{t('brakeWeight')}</TableHeader>
                <TableHeader>{t('grossWeight')}</TableHeader>
                <TableHeader>{t('kuljetusLaatu')}</TableHeader>
                <TableHeader>{t('origStation')}</TableHeader>
                <TableHeader>{t('destinationStation')}</TableHeader>
                <TableHeader>{t('additionalInfo')}</TableHeader>
              </TableHeaderRow>
              {cars.map((car, index) => {
                // @ts-expect-error brakeWeight is typed as string, unsure if typing or code is wrong
                // TODO: check what exactly the type should be (could be that this comes from rallibus)
                const background = car.brakeWeight === 0 ? 'yellow' : 'inherit'
                const danger = car.dangerousGoods && car.dangerousGoods.length > 0
                const additionalInfo = danger ? createAdditionalInfoText(car.dangerousGoods) : ''
                const emptyVAK =
                  car.cnCodes &&
                  car.cnCodes.length > 0 &&
                  car.cnCodes.every((cnCode) => EMPTY_CN_CODES.includes(cnCode))
                return (
                  <TableRow key={'car-' + car.orderNumber} danger={danger}>
                    <StickyCol borderRight blue left="0" width="35px">
                      {car.orderNumber}
                    </StickyCol>
                    <StickyCol
                      borderRight
                      blue
                      onClick={() =>
                        router.push(`/restrictionInfos/${car.carNumberShort || car.carNumber}`)
                      }
                      left="30px"
                      width="120px"
                    >
                      {shortNumbers && car.carNumberShort ? car.carNumberShort : car.carNumber}
                    </StickyCol>
                    {sourceSystem !== 'RCS' && <ContentCol>{car.trackBrakeStatus}</ContentCol>}
                    <ContentCol background={background}>{car.brakeWeight}</ContentCol>
                    <ContentCol background={background}>{car.grossWeight}</ContentCol>
                    <ContentCol background={background}>
                      {emptyVAK ? t('vakEmptyContainer') : car.qualityOfTransport}
                    </ContentCol>
                    <ContentCol background={background}>
                      {car.origStation.stationAbbreviation}
                    </ContentCol>
                    <ContentCol borderRight background={background}>
                      {car.destinationStation.stationAbbreviation}
                    </ContentCol>
                    {rowspans[index] > 0 ? (
                      <ContentCol rowSpan={rowspans[index]} width="200px">
                        {additionalInfo}
                      </ContentCol>
                    ) : undefined}
                  </TableRow>
                )
              })}
            </tbody>
          </Table>
        </TableContainer>
      </div>
    )
  }
}

export default withTranslation()(CarList)
