import { EnergyEfficiency } from '../types/Input'

export type FetchEnergyEfficiency = {
  type: 'START_FETCHING_ENERGY_EFFICIENCY'
}
export const START_FETCHING_ENERGY_EFFICIENCY = 'START_FETCHING_ENERGY_EFFICIENCY'
export const startFetchingEnergyEfficiency = (): FetchEnergyEfficiency => ({
  type: START_FETCHING_ENERGY_EFFICIENCY,
})

export type UpdateEnergyEfficiency = {
  type: 'UPDATE_ENERGY_EFFICIENCY'
  payload: EnergyEfficiency
  identifier: string
}
export const UPDATE_ENERGY_EFFICIENCY = 'UPDATE_ENERGY_EFFICIENCY'
export const updateEnergyEfficiency = (
  payload: EnergyEfficiency,
  identifier: string
): UpdateEnergyEfficiency => ({
  type: UPDATE_ENERGY_EFFICIENCY,
  payload,
  identifier,
})

export type EnergyEfficiencyError = {
  type: 'ENERGY_EFFICIENCY_ERROR'
  payload: string
}
export const ENERGY_EFFICIENCY_ERROR = 'ENERGY_EFFICIENCY_ERROR'
export const energyEfficiencyError = (payload: string): EnergyEfficiencyError => ({
  type: ENERGY_EFFICIENCY_ERROR,
  payload,
})

export type EnergyEfficiencyAction =
  | FetchEnergyEfficiency
  | UpdateEnergyEfficiency
  | EnergyEfficiencyError
