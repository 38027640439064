import styled from '@emotion/styled'

import { getColor, theme } from '../../../Theme'

const CardHeader = styled.div`
  font-size: ${theme.text('large')};
  color: ${(p) => getColor(p.theme, ['black'], ['white'])};
  padding: 10px 20px 10px 20px;
`

export default CardHeader
