import { networkChange } from '../actions/system'
import { Store } from '../types'

export const onlineEvent = (store: Store) => {
  return (): void => {
    store.dispatch(networkChange('ONLINE'))
  }
}

export const offlineEvent = (store: Store) => {
  return (): void => {
    store.dispatch(networkChange('OFFLINE'))
  }
}
