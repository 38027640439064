import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React from 'react'
import { withTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

import Close from '../../icons/Close'
import moment from '../../lib/moment-fi'
import { color, defaultTextColor, getColor, px, theme } from '../../Theme'
import { Event } from '../../types'
import Button from '../button/Button'
import { EmphasisLarge, EmphasisNormal } from '../Emphasis'
import Text from '../Text'

const Drawer = styled.div<{ top: number }>`
  width: ${theme.maxWidths.column};
  position: absolute;
  z-index: 2;
  margin-top: ${theme.spacing.sizes.huge};
  ${theme.effects.shadow};
  top: ${(p) => (p.top ? px(p.top) : 'auto')};
  left: 0;
  @media (max-width: ${theme.breakpoints.medium}) {
    width: 100%;
  }
`

const Arrow = styled.div`
  position: absolute;
  top: -${theme.spacing.sizes.small};
  left: ${px(14)};
  width: 0;
  height: 0;
  border-left: ${px(8)} solid transparent;
  border-right: ${px(8)} solid transparent;
  border-bottom: ${theme.spacing.sizes.small} solid
    ${(p) => getColor(p.theme, ['white'], ['nightHighlight'])};
`

const Flex = styled.div`
  ${theme.layout.flexColumn};
  background: ${(p) => getColor(p.theme, ['white'], ['nightHighlight'])};
`

const Title = styled.div`
  ${theme.layout.flexRow};
  ${theme.text('normal', 'content', 'bold')};
  text-transform: capitalize;
  flex: 8;
`

const IconPadding = styled.div`
  text-align: right;
  position: absolute;
  cursor: pointer;
  color: ${color('primaryBlue')};
  top: ${theme.spacing.sizes.large};
  right: ${theme.spacing.sizes.large};
  width: ${theme.spacing.sizes.huge};
`

const Row = styled.div`
  ${theme.layout.flexRow};
  ${theme.spacing.all('large')};
  box-sizing: border-box;
  position: relative;
  width: 100%;
`

const Container = styled.div`
  width: 100%;
  max-height: 375px;
  overflow-y: auto;
`

const EventNumber = styled.div`
  margin-left: ${theme.spacing.sizes.small};
  border-radius: ${theme.borderRadius.circle};
  box-sizing: border-box;
  width: auto;
  height: ${px(22)};
  padding: 0 ${px(8)};
  background: ${color('primaryYellow')};
  color: ${(p) => getColor(p.theme, ['white'], ['nightHighlight'])};
`

const EventRow = styled.div`
  position: relative;
  ${theme.spacing.all('large')};
  box-sizing: border-box;
  width: 100%;
  border-bottom: 1px solid ${color('grayBlue')};
`

const MarkAsRead = styled(Button)`
  margin-top: ${theme.spacing.sizes.small};
`

const AckTime = styled.div`
  ${theme.text('normal', 'content', 'bold')};
  color: ${defaultTextColor}c0;
  text-transform: uppercase;
`

const Unread = styled.div`
  position: absolute;
  top: 40px;
  right: 24px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: ${(p) => getColor(p.theme, ['primaryYellow'], ['primaryYellow'])};
`

const SentAt = styled.div<{ read: boolean }>`
  position: absolute;
  bottom: ${(p) => (p.read ? '20px' : '70px')};
  right: 24px;
  ${theme.text('small', 'content', 'bold')};
  color: ${defaultTextColor}c0;
  text-transform: uppercase;
  padding-top: 4px;
`

type Props = {
  t: TFunction
  events: Array<Event>
  top: number
  readHandlers: Record<string, (id: string) => void>
  close: () => void
}

function NotificationDrawer({ t, events, top, readHandlers, close }: Props) {
  return (
    <Drawer top={top}>
      <Arrow />
      <Flex>
        <Row>
          <Title>
            <EmphasisLarge>{t('events')}</EmphasisLarge>
            <EventNumber>{events.reduce((sum, e) => sum + (e.read ? 0 : 1), 0)}</EventNumber>
          </Title>
          <IconPadding onClick={() => close()}>
            <Close iconSize="small" />
          </IconPadding>
        </Row>
        <Container>
          {events.map((e, i) => (
            <EventRow key={i}>
              <Text color="grayDark">{moment(e.date).format('L')}</Text>
              <br />
              <EmphasisNormal>{t(`eventType.${e.type}`)}&nbsp;</EmphasisNormal>
              <Text color="grayDark">
                <ReactMarkdown>{e.message}</ReactMarkdown>
              </Text>
              {e.read ? undefined : <Unread />}
              {e.read ? undefined : (
                <MarkAsRead onClick={() => readHandlers[e.type](e.id)}>
                  {t(e.markAsReadText)}
                </MarkAsRead>
              )}
              {e.read && e.type === 'readCrewNotice' && e.data && e.data.eventAt ? (
                <AckTime>{`${t('acknowledged')} ${moment(e.data && e.data.eventAt).format(
                  'H:mm'
                )}`}</AckTime>
              ) : undefined}
              {e.type === 'readCrewNotice' || e.type === 'pendingCrewNotice' ? (
                <SentAt read={e.read}>
                  {e.data && e.data.sentAt ? moment(e.data.sentAt).format('H:mm') : null}
                </SentAt>
              ) : undefined}
            </EventRow>
          ))}
        </Container>
      </Flex>
    </Drawer>
  )
}

export default withTranslation()(NotificationDrawer)
