import { createConnectedRouter, createRender, HttpError } from 'found'
import { ScrollManager, ScrollManagerRenderArgs } from 'found-scroll'
import React, { FunctionComponent } from 'react'

import App from './App'
import { info } from './lib/logger'

const router = (ErrorPage: FunctionComponent) => {
  const renderFn = createRender({
    renderError: ({ error }: { error: HttpError }) => {
      info('Could not found route', error)
      return (
        <App>
          <ErrorPage />
        </App>
      )
    },
  })

  return createConnectedRouter({
    render: (renderArgs) => (
      <ScrollManager renderArgs={renderArgs as ScrollManagerRenderArgs}>
        {renderFn(renderArgs)}
      </ScrollManager>
    ),
  })
}

export default router
