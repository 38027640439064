import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const Logout = ({ iconSize, stroke = 'currentColor' }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          key="0"
          stroke={stroke}
          strokeLinecap="round"
          d="M15.4817379,8.76267748 L0.585186207,8.76267748"
        />
        <polyline
          key="1"
          stroke={stroke}
          strokeLinecap="round"
          points="3.89553103 5.25760649 0.585186207 8.76267748 3.89553103 12.2677485"
        />
        <path
          key="2"
          stroke={stroke}
          strokeLinecap="round"
          d="M8.34935172,5.2556787 C9.5990069,2.41043732 12.3225931,0.437958621 15.4814897,0.437958621 C19.8238345,0.437958621 23.3435586,4.16560162 23.3435586,8.76250223 C23.3435586,13.3602791 19.8238345,17.0870458 15.4814897,17.0870458 C12.3225931,17.0870458 9.5990069,15.1145671 8.34935172,12.2693258"
        />
      </g>
    </svg>
  )
}

export default Logout
