import {
  ASSEMBLY_LOAD_ERROR,
  CLEAR_ASSEMBLIES,
  START_LOADING_ASSEMBLIES,
  UPDATE_ASSEMBLIES,
} from '../actions/assembly'
import { AssemblyAction } from '../actions/assembly'
import { replaceManyWithId } from '../reducers/Common'
import { AssemblyState } from '../types'

export const initialState = {
  loading: false,
  error: '',
  byId: {},
}

const assemblyReducer = (
  state: AssemblyState = initialState,
  action: AssemblyAction
): AssemblyState => {
  switch (action.type) {
    case START_LOADING_ASSEMBLIES:
      return { ...state, loading: true }

    case UPDATE_ASSEMBLIES:
      return {
        ...state,
        error: '',
        loading: false,
        byId: replaceManyWithId(state.byId, action.payload),
      }

    case ASSEMBLY_LOAD_ERROR:
      return { ...state, loading: false, error: action.payload.error }

    case CLEAR_ASSEMBLIES:
      return initialState

    default:
      return state
  }
}

export default assemblyReducer
