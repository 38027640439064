import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React from 'react'

import { getColor } from '../../Theme'
import { SelectedProps } from '../../types/App'

const Container = styled.div`
  padding-top: 16px;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 4px;
  color: white;
  text-align: center;
`

const Button = styled.div<SelectedProps>`
  border: 2px solid;
  border-color: ${(p) =>
    p.selected
      ? getColor(p.theme, ['primaryBlue'], ['primaryBlue'])
      : getColor(p.theme, ['grayLight'], ['nightGray'])};
  border-radius: 3px;
  padding: 8px;
  width: 50%;
  background: ${(p) =>
    p.selected
      ? getColor(p.theme, ['primaryBlue'], ['primaryBlue'])
      : getColor(p.theme, ['grayLight'], ['nightGray'])};
  color: ${(p) => (p.selected ? 'grayLight' : getColor(p.theme, ['black'], ['grayDark']))};
`

type Props = {
  t: TFunction
  customSelected: boolean
  onClick: (arg0: boolean) => void
}

const YesNoButtons = ({ t, customSelected, onClick }: Props) => (
  <Container>
    <ButtonContainer>
      <Button selected={customSelected} onClick={() => onClick(true)}>
        {t('yes')}
      </Button>
      <Button selected={!customSelected} onClick={() => onClick(false)}>
        {t('no')}
      </Button>
    </ButtonContainer>
  </Container>
)

export default YesNoButtons
