import {
  FINDINGS_ERROR,
  FindingsAction,
  START_FETCHING_FINDINGS,
  UPDATE_FINDINGS,
} from '../actions/findings'
import { Finding, FindingsState } from '../types'

export const initialState = {
  equipmentLoading: [],
  equipmentErrors: {},
  findingsByLocId: {},
  equipmentWithError: [],
}

const addToArray = (returnedEqtIds: Array<string>, arr?: Array<string>): Array<string> => {
  returnedEqtIds.forEach((eqtId: string) => {
    if (arr && arr.indexOf(eqtId) === -1) {
      arr.push(eqtId)
    }
  })
  return arr || []
}

const removeFromArray = (toRemove: Array<string>, arr?: Array<string>): Array<string> => {
  let result = arr
  toRemove.forEach((eqtId: string) => {
    if (result) {
      result = result.filter((e) => e !== eqtId)
    }
  })
  return result || []
}

const removeFromMapByKey = (removedKeys: Array<string>, map?: Record<string, unknown>): any => {
  if (!map || !removedKeys) return
  const result: Record<string, any> = { ...map }
  removedKeys.forEach((k: string): void => {
    delete result[k]
  })
  return result
}

const findingsReducer = (
  state: FindingsState = initialState,
  action: FindingsAction
): FindingsState => {
  switch (action.type) {
    case START_FETCHING_FINDINGS: {
      return {
        ...state,
        equipmentLoading: addToArray(action.payload, state.equipmentLoading),
        equipmentErrors: removeFromMapByKey(action.payload, state.equipmentErrors),
      }
    }
    case UPDATE_FINDINGS: {
      const findingsMap: Record<string, { findings: Array<Finding>; timestamp: number }> = {}
      const returnedEqtIds: Array<string> = []

      const now: number = new Date().getTime()
      // insert new findings
      action.payload.findings.forEach((f) => {
        const findings = findingsMap[f.equipment.equipmentId]?.findings || []
        findings.push(f)
        findingsMap[f.equipment.equipmentId] = {
          findings,
          timestamp: now,
        }
        if (!returnedEqtIds.includes(f.equipment.equipmentId))
          returnedEqtIds.push(f.equipment.equipmentId)
      })
      // Handle equipment for which we requested findings but got none
      const requestedEqtIds = removeFromArray(returnedEqtIds, action.equipments)
      requestedEqtIds.forEach((eqtId: string): void => {
        findingsMap[eqtId] = { findings: [], timestamp: now }
      })
      return {
        ...state,
        equipmentErrors: removeFromMapByKey(action.equipments, state.equipmentErrors),
        equipmentLoading: removeFromArray(action.equipments, state.equipmentLoading),
        findingsByLocId: { ...state.findingsByLocId, ...findingsMap },
        equipmentWithError: removeFromArray(action.equipments, state.equipmentWithError),
      }
    }
    case FINDINGS_ERROR: {
      const errors: Record<string, string> = {}
      action.equipmentId.forEach((id: string): void => {
        errors[id] = action.payload
      })
      return {
        ...state,
        findingsByLocId: removeFromMapByKey(action.equipmentId, state.findingsByLocId),
        equipmentErrors: { ...state.equipmentErrors, ...errors },
        equipmentLoading: removeFromArray(action.equipmentId, state.equipmentLoading),
        equipmentWithError: addToArray(action.equipmentId, state.equipmentWithError),
      }
    }
    default:
      return state
  }
}

export default findingsReducer
