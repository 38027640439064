import styled from '@emotion/styled'
import React from 'react'

import Rest from '../../icons/Rest'
import { theme } from '../../Theme'

const FlexContainer = styled.div`
  ${theme.layout.flexRow};
  flex-align: center;
`

const IconContainer = styled.div`
  padding-top: 2px;
  margin-right: ${theme.spacing.sizes.small};
`

type Props = {
  children: JSX.Element
}

function IconContent({ children }: Props) {
  return (
    <FlexContainer>
      <IconContainer>
        <Rest iconSize="smallest" />
      </IconContainer>
      <div>{children}</div>
    </FlexContainer>
  )
}

export default IconContent
