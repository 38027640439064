import { NEWS_ERROR, START_FETCHING_NEWS, UPDATE_NEWS } from '../actions/news'
import { NewsAction } from '../actions/news'
import { NewsState } from '../types'

export const initialState = {
  loading: false,
  error: '',
  news: [],
}

const newsReducer = (state: NewsState = initialState, action: NewsAction): NewsState => {
  switch (action.type) {
    case START_FETCHING_NEWS:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case UPDATE_NEWS:
      return {
        ...state,
        error: '',
        loading: false,
        news: action.payload,
      }
    case NEWS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default newsReducer
