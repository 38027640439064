import styled from '@emotion/styled'
import React, { Component, MutableRefObject } from 'react'

import { defaultTextColor, getColor, theme } from '../Theme'

type Props = {
  onChange?: (value: string) => void
  rows: number
  value: string
  disabled: boolean
}
class FocusedInput extends Component<Props> {
  private ref: MutableRefObject<HTMLTextAreaElement>

  constructor(props: Props) {
    super(props)
    this.ref = React.createRef() as MutableRefObject<HTMLTextAreaElement>
    this.onFocus = this.onFocus.bind(this)
    this.onChange = this.onChange.bind(this)
    this.captureRef = this.captureRef.bind(this)
  }
  captureRef(input: HTMLTextAreaElement): void {
    this.ref.current = input
  }
  onFocus(): void {
    if (this.ref && this.ref.current && typeof this.ref.current.scrollIntoView === 'function') {
      this.ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }
  }
  onChange(ev: React.ChangeEvent<HTMLTextAreaElement>): void {
    if (this.props.onChange) {
      this.props.onChange(ev.target.value)
    }
  }
  render() {
    return (
      <textarea
        {...this.props}
        ref={this.captureRef}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onClick={this.onFocus}
      />
    )
  }
}

const StyledInput = styled(FocusedInput)`
  ${theme.text()};
  color: ${defaultTextColor};
  display: block;
  background: ${(p) => getColor(p.theme, ['white'], ['nightBlack'])};
  width: 100%;
`

export default StyledInput
