import { RollingGuideData } from '../types/Input'

export type FetchRollingGuides = {
  type: 'START_FETCHING_ROLLING_GUIDES'
}
export const START_FETCHING_ROLLING_GUIDES = 'START_FETCHING_ROLLING_GUIDES'
export const startFetchingRollingGuides = (): FetchRollingGuides => ({
  type: START_FETCHING_ROLLING_GUIDES,
})

export type UpdateRollingGuides = {
  type: 'UPDATE_ROLLING_GUIDES'
  payload: RollingGuideData
}
export const UPDATE_ROLLING_GUIDES = 'UPDATE_ROLLING_GUIDES'
export const updateRollingGuides = (payload: RollingGuideData): UpdateRollingGuides => ({
  type: UPDATE_ROLLING_GUIDES,
  payload,
})

export type RollingGuidesError = {
  type: 'ROLLING_GUIDES_ERROR'
  payload: string
}
export const ROLLING_GUIDES_ERROR = 'ROLLING_GUIDES_ERROR'
export const rollingGuidesError = (payload: string): RollingGuidesError => ({
  type: ROLLING_GUIDES_ERROR,
  payload,
})

export type RollingGuidesAction = FetchRollingGuides | UpdateRollingGuides | RollingGuidesError
