import { styled } from '@mui/material/styles'

const SIZE = 12
const BORDER = 1
const INNER_SIZE = SIZE - BORDER * 2
const BOX_SIZE = INNER_SIZE / 2

const Border = styled('div')(({ theme }) => ({
  display: 'inline-block',
  position: 'relative',
  width: `${SIZE}px`,
  height: `${SIZE}px`,
  border: `${BORDER}px solid ${theme.palette.text.primary}`,
  borderRadius: `${SIZE}px`,
  overflow: 'hidden',
}))

const InnerCircle = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: `${BORDER}px`,
  left: `${BORDER}px`,
  width: `${INNER_SIZE}px`,
  height: `${INNER_SIZE}px`,
  borderRadius: `${INNER_SIZE}px`,
  overflow: 'hidden',
  color: `${theme.palette.text.primary}`,
}))

const Box = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '0',
  left: `${BOX_SIZE}px`,
  width: `${BOX_SIZE}px`,
  height: `${INNER_SIZE}px`,
  background: `${theme.palette.text.primary}`,
}))

const Box2 = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: `${BOX_SIZE}px`,
  left: '0',
  width: `${BOX_SIZE}px`,
  height: `${BOX_SIZE}px`,
  background: `${theme.palette.text.primary}`,
}))

const MuiDurationIcon = (): JSX.Element => {
  return (
    <Border>
      <InnerCircle>
        <Box />
        <Box2 />
      </InnerCircle>
    </Border>
  )
}
export default MuiDurationIcon
