import styled from '@emotion/styled'
import React from 'react'

import { ContactProps } from '../../types/Input'
import ContactLabel from './ContactLabel'

const Column = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  flex-direction: column;
`

type Props = {
  contacts: Array<ContactProps> | null
  shiftId: string
  taskIndex: number
}

const ContactList = ({ contacts, shiftId, taskIndex }: Props) => {
  return (
    <Column>
      {contacts &&
        contacts.map((contact, i) => (
          <ContactLabel key={i} shiftId={shiftId} taskIndex={taskIndex} contact={contact} />
        ))}
    </Column>
  )
}

export default ContactList
