import { color } from '../../Theme'
import IconProps from '../Icon'

const Battery20 = ({ fillColor = color('grayDark') }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="20" viewBox="0 0 40 20" fill="none">
    <g clipPath="url(#clip0_1249_2109)">
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 17V3H37V17H6ZM3 1C3 0.447715 3.44772 0 4 0H39C39.5523 0 40 0.447715 40 1V19C40 19.5523 39.5523 20 39 20H4C3.44771 20 3 19.5523 3 19V14H0V6H3V1Z"
        fill={fillColor}
      />
      <rect opacity="0.5" x="7" y="4" width="5" height="12" fill={fillColor} />
      <rect opacity="0.5" x="13" y="4" width="5" height="12" fill={fillColor} />
      <rect opacity="0.5" x="19" y="4" width="5" height="12" fill={fillColor} />
      <rect opacity="0.5" x="25" y="4" width="5" height="12" fill={fillColor} />
      <rect x="31" y="4" width="5" height="12" fill={fillColor} />
    </g>
    <defs>
      <clipPath id="clip0_1249_2109">
        <rect width="40" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Battery20
