import { getSize, sizes } from '../theme/iconSizes'
import IconProps from './Icon'

const Statistics = ({ iconSize, width, fillColor }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      height={size === sizes.default ? 20 : size}
      width={width ?? (size === sizes.default ? 40 : size)}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 360 327"
    >
      <g fill={fillColor} fillRule="evenodd">
        <rect key="0" x="4" y="307" width="352" height="16" />
        <path
          key="1"
          d="M372,354v8H28v-8H372m8-8H20v24H380V346Z"
          transform="translate(-20 -43)"
          fill={fillColor ?? 'currentColor'}
        />
        <rect key="2" x="44" y="161" width="42" height="109" />
        <path
          key="3"
          d="M102,208V309H68V208h34m8-8H60V317h50V200Z"
          transform="translate(-20 -43)"
          fill={fillColor ?? 'currentColor'}
        />
        <rect key="4" x="159" y="4" width="42" height="266" />
        <path
          key="5"
          d="M217,51V309H183V51h34m8-8H175V317h50V43Z"
          transform="translate(-20 -43)"
          fill={fillColor ?? 'currentColor'}
        />
        <rect key="6" x="274" y="89" width="42" height="181" />
        <path
          key="7"
          d="M332,136V309H298V136h34m8-8H290V317h50V128Z"
          transform="translate(-20 -43)"
          fill={fillColor ?? 'currentColor'}
        />
      </g>
    </svg>
  )
}

export default Statistics
