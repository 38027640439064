import styled from '@emotion/styled'
import { TFunction, TOptions } from 'i18next'
import React from 'react'

import Close from '../../../icons/Close'
import { getColor, theme } from '../../../Theme'
import { SearchDeleteParams } from '../../../types/Input'
import Card from './Card'
import CardHeader from './CardHeader'

const Content = styled(Card)`
  margin-bottom: 16px;
  background: ${(p) => getColor(p.theme, ['white'], ['black'])};
`

const DeleteLink = styled.div`
  display: flex;
  text-align: right;
  flex-direction: row;
  justify-content: flex-end;
  padding 10px 10px 0 0;
`
const DeleteButton = styled.button`
  border: none;
  border-radius: ${theme.borderRadius.button};
  background: ${(p) => getColor(p.theme, ['white'], ['nightGray'])};
  color: ${(p) => getColor(p.theme, ['primaryBlue'], ['grayDark'])};
  cursor: pointer;
  ${theme.layout.flexRow};
`

const OpenLink = styled.div``

const InfoText = styled.div`
  font-size: ${theme.text('normal')};
  color: ${(p) => getColor(p.theme, ['grayDark'], ['grayDark'])};
  padding: 10px 20px 10px 20px;
`

type Props = {
  t: TFunction
  textKey: string
  args: TOptions
  onClick: (arg0: unknown, func: () => unknown) => unknown
  additionalText?: string
  deleteAction: (
    searchDeleteParams: SearchDeleteParams | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => unknown
}

const CardWithSpace = ({ t, textKey, args, onClick, additionalText, deleteAction }: Props) => {
  return (
    <Content>
      <DeleteLink>
        <DeleteButton onClick={deleteAction}>
          <Close iconSize="normal" />
        </DeleteButton>
      </DeleteLink>
      <OpenLink
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick={(event) => onClick(event, () => {})}
      >
        <CardHeader>{t(textKey, args)}</CardHeader>
        {additionalText && <InfoText>{additionalText}</InfoText>}
      </OpenLink>
    </Content>
  )
}

export default CardWithSpace
