import {
  FETCH_PERSONNEL,
  PERSONNEL_ERROR,
  SET_PERSONNEL_FROM_HISTORY,
  UPDATE_PERSONNEL,
  UPDATE_PERSONNEL_HISTORY,
} from '../actions/personnel'
import { PersonnelAction } from '../actions/personnel'
import { Personnel, PersonnelPageState } from '../types'

/* eslint-disable @typescript-eslint/no-empty-function */
const noobj = () => {}

export const initialState = {
  loading: false,
  error: '',
  personnelList: noobj() as unknown as Personnel,
  personnelHistory: [],
}

const personnelReducer = (
  state: PersonnelPageState = initialState,
  action: PersonnelAction
): PersonnelPageState => {
  switch (action.type) {
    case FETCH_PERSONNEL:
      return {
        ...state,
        error: '',
        loading: true,
      }

    case UPDATE_PERSONNEL:
      return {
        ...state,
        error: '',
        loading: false,
        personnelList: action.payload,
      }

    case UPDATE_PERSONNEL_HISTORY:
      return {
        ...state,
        personnelHistory: action.payload,
        error: '',
      }

    case SET_PERSONNEL_FROM_HISTORY:
      return {
        ...state,
        personnelList: action.payload,
        loading: false,
        error: '',
      }

    case PERSONNEL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default personnelReducer
