import { TFunction } from 'i18next'
import React from 'react'
import { withTranslation } from 'react-i18next'

import { Route } from '../../types/Input'
import {
  ContentCol,
  SectionTitle,
  StickyCol,
  Table,
  TableContainer,
  TableHeader,
  TableHeaderRow,
  TableRow,
} from './TableComponents'

type Props = {
  jarruLaji?: string
  color: string
  brakeHeaderColor: string
  t: TFunction
  title: JSX.Element
  route: unknown
  brakeRegime: string
  noteList: Array<string>
  sourceSystem: string
  tractionPower: string
  departureTrack: string | number | null
}

const findTopSpeed = (noteList: Array<string>, sourceSystem: string) => {
  if (sourceSystem === 'KAPU') {
    const maxSpeedNote = noteList.find((note) => note.includes('Max nopeus'))
    return maxSpeedNote
      ? maxSpeedNote.split('Max nopeus')[1].split(',')[0].replace(/^\D+/g, '')
      : ''
  }

  let topSpeed = ''
  const regex = /\s*([a-zA-Z]+)\s*=\s*(\d+)[,]?/g
  const topSpeedAbbreviations = ['JNS', 'SNJ', 'SJN']
  noteList.forEach((note) => {
    let match: RegExpExecArray | null
    while ((match = regex.exec(note)) !== null) {
      if (
        match?.length > 2 &&
        // eslint-disable-next-line no-loop-func
        topSpeedAbbreviations.find((abb) => abb.toLowerCase() === match?.[1]?.toLowerCase())
      ) {
        topSpeed = match[2]
      }
    }
    if (sourceSystem === 'RCS' && note.toLowerCase().includes('km/h')) {
      const splittedNote = note.split(' ')
      if (splittedNote.length > 3) {
        topSpeed = splittedNote[splittedNote.length - 2]
      }
    }
  })
  return topSpeed
}

const lowBrakeWeightPercentageSpeedReduced = (
  noteList: Array<string>,
  route: Route,
  sourceSystem: string
) => {
  let matches = undefined
  if (sourceSystem === 'KAPU') {
    return route.maximumSpeedReduced === 'true'
  } else if (sourceSystem === 'RCS')
    matches = noteList.find((note) =>
      note.startsWith('Jarrupainoprosentti on liian alhainen. Uusi nopeusrajoitus on')
    )
  return matches !== undefined
}

const RouteList = ({
  t,
  title,
  route,
  color,
  brakeHeaderColor,
  brakeRegime,
  noteList,
  sourceSystem,
  tractionPower,
  departureTrack,
}: Props) => {
  const typedRoute = route as Route

  return (
    <>
      <SectionTitle>{title}</SectionTitle>
      <TableContainer stickyCol>
        <Table>
          <tbody>
            <TableHeaderRow color={color}>
              <StickyCol borderRight color={color} left="0" width="100px">
                {t('route')}
              </StickyCol>
              <TableHeader color={color} left="30px" width="120px">
                {t('locomotive')}
              </TableHeader>
              <TableHeader color={color}>{t('departureTrack')}</TableHeader>
              <TableHeader color={color}>{t('axels')}</TableHeader>
              <TableHeader color={color}>{t('tons')}</TableHeader>
              <TableHeader color={color}>{t('brakeType')}</TableHeader>
              <TableHeader color={color}>{t('topSpeed')}</TableHeader>
              <TableHeader color={color}>{t('length')}</TableHeader>
              <TableHeader color={color}>{t('weight')}</TableHeader>
              <TableHeader color={brakeHeaderColor}>{t('brakeWeight')}</TableHeader>
              <TableHeader color={brakeHeaderColor}>{t('brakeWeightPercent')}</TableHeader>
              <TableHeader color={color}>{t('pt')}</TableHeader>
              <TableHeader color={color}>{t('maxForce')}</TableHeader>
            </TableHeaderRow>
            {typedRoute.routeParts.map((routePart) => (
              <TableRow key={'route-' + routePart}>
                <StickyCol borderRight blue left="0" width="100px" nowrap>
                  {`${routePart.origStation.stationAbbreviation} - ${routePart.destinationStation.stationAbbreviation}`}
                </StickyCol>
                <ContentCol
                  nowrap
                >{`${routePart.engineCount} ${routePart.engineSeries}`}</ContentCol>
                <ContentCol>{departureTrack}</ContentCol>
                <ContentCol>{routePart.axel}</ContentCol>
                <ContentCol>{routePart.carsTons}</ContentCol>
                <ContentCol>{brakeRegime}</ContentCol>
                <ContentCol
                  background={
                    lowBrakeWeightPercentageSpeedReduced(noteList, typedRoute, sourceSystem)
                      ? 'yellow'
                      : ''
                  }
                >
                  {findTopSpeed(noteList, sourceSystem)}
                </ContentCol>
                <ContentCol>{routePart.length}</ContentCol>
                <ContentCol>{routePart.tons}</ContentCol>
                <ContentCol>{routePart.brakeWeight}</ContentCol>
                <ContentCol>{routePart.brakeWeightPercent}%</ContentCol>
                <ContentCol>{routePart.pt}</ContentCol>
                <ContentCol>{tractionPower}</ContentCol>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </>
  )
}

export default withTranslation()(RouteList)
