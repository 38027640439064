import React from 'react'
import { connect } from 'react-redux'

import { visitURL } from '../../lib/url'
import { Action, Dispatch } from '../../types'
import Button from './Button'

type Props = {
  children: JSX.Element | string
  to: string
  onClick: () => void
  topOpen?: boolean
  bottomOpen: boolean
}

const ButtonLink = ({ children, onClick, topOpen, bottomOpen }: Props) => (
  <Button onClick={onClick} topOpen={topOpen} bottomOpen={bottomOpen}>
    {children}
  </Button>
)

type PropsIn = {
  to: string
  onClick?: (() => void) | null
}

const mapStateToProps = () => ({})
const mapDispatchToProps = (dispatch: Dispatch, { to, onClick }: PropsIn) => ({
  onClick: (/*ev*/) => {
    if (onClick) {
      onClick()
    }
    dispatch(visitURL(to) as unknown as Action)
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ButtonLink)
