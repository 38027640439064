import { Box, Button, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { TFunction } from 'i18next'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useTypedDispatch, useTypedSelector } from '../..'
import { fetchServiceOrdersAction } from '../../actions/serviceOrders'
import { MediumErrorText, SmallErrorText } from '../../components/ErrorText'
import LoadingIndicator from '../../components/LoadingIndicator'
import Page from '../../components/page/Page'
import { Container } from '../../components/page/PageComponents'
import SearchBox from '../../components/search/SearchBox'
import SearchContainer from '../../components/search/SearchContainer'
import SearchHeader from '../../components/search/SearchHeader'
import Input from '../../components/search/SearchInput'
import SearchPageHeader from '../../components/search/SearchPageHeader'
import ServiceOrdersList from '../../components/serviceOrders/ServiceOrdersList'
import { ServiceOrder } from '../../components/serviceOrders/types'
import { updateSelectedServiceOrder } from '../../reducers/serviceOrders/serviceOrdersSlice'

const PaddedContainer = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing('smaller'),
  marginRight: theme.spacing('smaller'),
  marginTop: theme.spacing('smaller'),
  color: theme.palette.text.primary,
}))

const StickySearchContainer = styled(SearchContainer)(({ theme }) => ({
  position: 'sticky',
  overflow: 'visible',
  top: '0px',
  zIndex: '4',
  marginBottom: theme.spacing('smaller'),
}))

const getListContent = (
  t: TFunction,
  serviceOrders: Array<ServiceOrder>,
  installationGroup: string,
  filterString: string,
  setFilterString: (value: string) => void,
  updateSelectedSO: (serviceOrder: ServiceOrder) => void
): JSX.Element => {
  if (!serviceOrders.length) {
    return (
      <MediumErrorText>
        {t('serviceOrder.list.noResults', { installationGroup: installationGroup })}
      </MediumErrorText>
    )
  } else {
    return (
      <>
        <StickySearchContainer>
          <SearchBox active={true}>
            <SearchHeader>{t('serviceOrder.list.filterByWB')}</SearchHeader>
            <Input
              type="text"
              value={filterString}
              onChange={(event) => setFilterString(event.target.value)}
            />
          </SearchBox>
        </StickySearchContainer>
        <ServiceOrdersList
          filterByInstallationGroupOrWB={filterString}
          updateSelectedServiceOrder={updateSelectedSO}
        />
      </>
    )
  }
}

const ServiceOrdersListPage = (): JSX.Element => {
  const [searchString, setSearchString] = useState('')
  const [filterString, setFilterString] = useState('')
  const [searchParameterInvalid, setSearchParameterInvalid] = useState(false)
  const [searched, setSearched] = useState('')

  const { t } = useTranslation()
  const dispatch = useTypedDispatch()

  const admin = useTypedSelector((state) => state.user.admin)
  const serviceOrdersStatus = useTypedSelector((state) => state.serviceOrders.serviceOrders.status)
  const serviceOrders = useTypedSelector((state) => state.serviceOrders.serviceOrders.data)

  const updateSelectedSO = (serviceOrder: ServiceOrder) =>
    dispatch(updateSelectedServiceOrder(serviceOrder))

  const fetchServiceOrders = useCallback(
    (installationGroups: string) => {
      dispatch(fetchServiceOrdersAction(installationGroups))
    },
    [dispatch]
  )

  return (
    <Page overflowVisible={true}>
      <Container>
        <SearchPageHeader name="serviceOrders" text={`${t('serviceOrder.list.title')}`} />
        <PaddedContainer>
          <Box
            sx={(theme) => ({
              ...theme.layout.flexRow,
              justifyContent: 'space-between',
              width: '100%',
            })}
          >
            <Box>
              <SearchBox active={true}>
                <SearchHeader>{t('serviceOrder.list.installationGroup')}</SearchHeader>
                <Input
                  type="number"
                  value={searchString}
                  onChange={(event) => {
                    setSearchParameterInvalid(false)
                    setSearchString(event.target.value)
                  }}
                />
              </SearchBox>
            </Box>
            <Button
              variant="submitButtonHalfWidth"
              onClick={() => {
                if (!searchString && !admin) {
                  setSearchParameterInvalid(true)
                } else {
                  setSearched(searchString)
                  fetchServiceOrders(searchString)
                }
              }}
            >
              <Typography>{t('search')}</Typography>
            </Button>
          </Box>
          {searchParameterInvalid && (
            <SmallErrorText>{t('errors.serviceOrders.missingInstallationGroup')}</SmallErrorText>
          )}
          {((): JSX.Element => {
            switch (serviceOrdersStatus) {
              case 'loading':
                return <LoadingIndicator size="normal" padded={true} />
              case 'failed':
                return <MediumErrorText>{t('errors.serviceOrders.failed')}</MediumErrorText>
              case 'succeeded':
                return getListContent(
                  t,
                  serviceOrders,
                  searched || '',
                  filterString,
                  setFilterString,
                  updateSelectedSO
                )
              default:
                return <></>
            }
          })()}
        </PaddedContainer>
      </Container>
    </Page>
  )
}

export default ServiceOrdersListPage
