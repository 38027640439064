import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const Download = ({ iconSize, fillColor }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <path
        d="M11.4725963,19.6492322 C11.4791553,19.6558723 11.4878012,19.6610033 11.4946584,19.6676434 C11.5229814,19.6945056 11.5521988,19.7204623 11.5846957,19.7424953 C11.5996025,19.7524554 11.616,19.7593974 11.6318012,19.768452 C11.6583354,19.783845 11.6842733,19.7998415 11.7125963,19.8122163 C11.7313789,19.8200636 11.7519503,19.8242891 11.7713292,19.8309292 C11.7981615,19.8393803 11.8240994,19.849944 11.8518261,19.8556787 C11.9001242,19.865337 11.9496149,19.8707698 11.9994037,19.8707698 C11.9994037,19.8707698 11.9994037,19.8707698 11.9997019,19.8707698 C12.0056646,19.8707698 12.0104348,19.8692607 12.0163975,19.8689588 C12.0602236,19.8680534 12.1040497,19.8644315 12.1466832,19.8556787 C12.1848447,19.8481331 12.2209193,19.8354566 12.2563975,19.8221764 C12.2659379,19.8185545 12.2760745,19.8167436 12.2856149,19.8128199 C12.3276522,19.7953142 12.3667081,19.7732812 12.4042733,19.7488336 C12.4072547,19.7467208 12.4105342,19.7455135 12.4132174,19.7437026 C12.4549565,19.7153313 12.4937143,19.6833382 12.5288944,19.6474213 L19.4915776,12.5986644 C19.7828571,12.3040859 19.7828571,11.8263013 19.4915776,11.5317228 C19.2002981,11.2371443 18.728646,11.2368424 18.4376646,11.5317228 L12.7453416,17.2938114 L12.7453416,0.75455563 C12.7453416,0.3377391 12.4117267,0 12,0 C11.5882733,0 11.2546584,0.3377391 11.2546584,0.75455563 L11.2546584,17.294415 L5.56203727,11.5308173 C5.27075776,11.235937 4.79910559,11.235937 4.50812422,11.5308173 C4.21684472,11.8253958 4.21684472,12.3031805 4.50812422,12.597759 L11.4725963,19.6492322 Z M23.2546584,17.963555 C22.8429317,17.963555 22.5093168,18.3012941 22.5093168,18.7181106 L22.5093168,22.4908887 L1.49068323,22.4908887 L1.49068323,18.7181106 C1.49068323,18.3012941 1.15706832,17.963555 0.745341615,17.963555 C0.333614907,17.963555 0,18.3012941 0,18.7181106 L0,23.2454444 C0,23.6622609 0.333614907,24 0.745341615,24 L23.2546584,24 C23.6663851,24 24,23.6622609 24,23.2454444 L24,18.7181106 C24,18.3012941 23.6663851,17.963555 23.2546584,17.963555 Z"
        fill={fillColor ?? 'currentColor'}
      />
    </svg>
  )
}

export default Download
