import { Checkbox, FormControlLabel } from '@mui/material'
import { useState } from 'react'

import { useTypedSelector } from '../../../..'
import {
  selectUpdatedAction,
  selectUpdatedActionStatus,
} from '../../../../reducers/serviceOrders/actionsSlice'
import { ActionStatus } from '../../../../types/Input'
import { Action, ActionPayload, MeasurementType } from '../../types'
import { constructPayload, getBorderStyle, handleSubmit } from './InputUtils'

type Props = {
  wbNotStarted: boolean
  action: Action
  measurement: MeasurementType
  updateAction: (actionId: string, payload: ActionPayload) => void
}

const MeasurementCheckbox = ({
  wbNotStarted,
  action,
  measurement,
  updateAction,
}: Props): JSX.Element => {
  const [checked, setChecked] = useState(
    action.status === 'MEASURED' ? action.measuredValueAlphanumeric : null
  )

  const updatedAction = useTypedSelector<Action | undefined>((state) => selectUpdatedAction(state))
  const updatedStatus = useTypedSelector<ActionStatus>((state) => selectUpdatedActionStatus(state))
  const showBorder =
    ['failed', 'succeeded'].includes(updatedStatus) &&
    updatedAction?.inspection === action.inspection

  const value = measurement.values[0]

  const update = (submittedValue: number) =>
    updateAction(
      action.id.toString(),
      constructPayload(action.inspection, submittedValue, undefined)
    )

  return (
    <FormControlLabel
      sx={{
        width: '100%',
        justifyContent: 'flex-start',
        margin: '0',
        ...getBorderStyle(updatedStatus, showBorder),
      }}
      value={measurement.values[0].id}
      control={
        <Checkbox
          sx={{ '&.Mui-checked': { color: 'common.kenttaBlue' } }}
          checked={checked !== null}
          disabled={wbNotStarted || checked !== null || updatedStatus === 'loading'}
          onChange={(event) => {
            const initialValue = checked ? action.measuredValueAlphanumeric : undefined
            const submittedValue = parseInt(event.target.value)
            if (submittedValue !== initialValue) {
              handleSubmit(setChecked, submittedValue, update)
            }
          }}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      }
      label={value.description}
      labelPlacement="end"
    />
  )
}

export default MeasurementCheckbox
