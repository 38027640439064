import { CrewNotice, CrewNoticeAck, Timestamp } from '../types'

export type FetchCrewNotice = {
  type: 'START_FETCHING_CREW_NOTICE'
  payload: string
}
export const START_FETCHING_CREW_NOTICE = 'START_FETCHING_CREW_NOTICE'
export const startFetchingCrewNotice = (payload: string): FetchCrewNotice => ({
  type: START_FETCHING_CREW_NOTICE,
  payload,
})

export type FetchAllCrewNotices = {
  type: 'START_FETCHING_ALL_CREW_NOTICES'
}
export const START_FETCHING_ALL_CREW_NOTICES = 'START_FETCHING_ALL_CREW_NOTICES'
export const startFetchingAllCrewNotices = (): FetchAllCrewNotices => ({
  type: START_FETCHING_ALL_CREW_NOTICES,
})

export type BatchUpdateCrewNotice = {
  type: 'BATCH_UPDATE_CREW_NOTICE'
  payload: Array<CrewNotice>
}
export const BATCH_UPDATE_CREW_NOTICE = 'BATCH_UPDATE_CREW_NOTICE'
export const batchUpdateCrewNotice = (payload: Array<CrewNotice>): BatchUpdateCrewNotice => ({
  type: BATCH_UPDATE_CREW_NOTICE,
  payload,
})

export type UpdateCrewNotice = {
  type: 'UPDATE_CREW_NOTICE'
  payload: CrewNotice
}
export const UPDATE_CREW_NOTICE = 'UPDATE_CREW_NOTICE'
export const updateCrewNotice = (payload: CrewNotice): UpdateCrewNotice => ({
  type: UPDATE_CREW_NOTICE,
  payload,
})

export type UpdateCrewNoticeAck = {
  type: 'UPDATE_CREW_NOTICE_ACK'
  payload: {
    crewNoticeId: string
    ack: CrewNoticeAck
    eventAt: Timestamp
  }
}
export const UPDATE_CREW_NOTICE_ACK = 'UPDATE_CREW_NOTICE_ACK'
export const updateCrewNoticeAck = (
  crewNoticeId: string,
  ack: CrewNoticeAck,
  eventAt: Timestamp
): UpdateCrewNoticeAck => ({
  type: UPDATE_CREW_NOTICE_ACK,
  payload: { crewNoticeId, ack, eventAt },
})

export type CrewNoticeError = {
  type: 'CREW_NOTICE_ERROR'
  payload: {
    crewNoticeId: string
    error: string
  }
}
export const CREW_NOTICE_ERROR = 'CREW_NOTICE_ERROR'
export const crewNoticeError = (crewNoticeId: string, error: string): CrewNoticeError => ({
  type: CREW_NOTICE_ERROR,
  payload: { crewNoticeId, error },
})

export type CrewNoticeErrorAll = {
  type: 'CREW_NOTICE_ERROR_ALL'
  payload: string
}
export const CREW_NOTICE_ERROR_ALL = 'CREW_NOTICE_ERROR_ALL'
export const crewNoticeErrorAll = (payload: string): CrewNoticeErrorAll => ({
  type: CREW_NOTICE_ERROR_ALL,
  payload,
})

export type CrewNoticeAction =
  | FetchCrewNotice
  | FetchAllCrewNotices
  | BatchUpdateCrewNotice
  | UpdateCrewNotice
  | UpdateCrewNoticeAck
  | CrewNoticeError
  | CrewNoticeErrorAll
