import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import _ from 'lodash'
import React, { HTMLAttributes } from 'react'
import { withTranslation } from 'react-i18next'

import { MOMENT_TIME_FORMAT } from '../../constants'
import moment from '../../lib/moment-fi'
import { relativeDate } from '../../lib/time'
import { color, getColor } from '../../Theme'
import { MaybeColor, Moment, Timestamp } from '../../types'
import Text from '../Text'
import Annotated from './Annotated'

interface TimeProps extends HTMLAttributes<HTMLDivElement> {
  past: boolean
  color?: MaybeColor
  horizontal: boolean
}

const Time = styled(Text)<TimeProps>`
  color: ${(p) =>
    p.past ? color('grayDark') : getColor(p.theme, [p.color, 'black'], ['grayPink'])};
  display: block;
  flex: 2;
  text-align: ${(p) => (p.horizontal ? 'right' : 'inherit')};
  width: 100%;
  text-decoration: ${(p) => (p.past ? 'line-through' : 'none')};
`

const DeEmphasis = styled(Text)`
  color: ${(p) => getColor(p.theme, ['grayDark'], ['grayDark'])};
  text-decoration: inherit;
`

type Props = {
  t: TFunction
  horizontal: boolean
  now: Moment
  title: string
  timestamp: Timestamp
  duration: string
}

function AnnotatedTime({ t, horizontal, now, title, timestamp, duration }: Props) {
  const past = now.isAfter(timestamp)
  return (
    <Annotated title={title} horizontal={horizontal}>
      <Time past={past} horizontal={horizontal}>
        <Text>{_.capitalize(relativeDate(t, now, timestamp))}&nbsp;</Text>
        <Text>{moment(timestamp).format(MOMENT_TIME_FORMAT)}&nbsp;</Text>
        {duration ? <DeEmphasis>({duration})</DeEmphasis> : undefined}
      </Time>
    </Annotated>
  )
}

export default withTranslation()(AnnotatedTime)
