import styled from '@emotion/styled'
import React, { useState } from 'react'
import { connect } from 'react-redux'

import { saveTowingSteps } from '../../../actions/api'
import { formActions } from '../../../lib/towingUtils'
import { getColor, theme } from '../../../Theme'
import { Action } from '../../../types'
import TowingSelectionButton from '../TowingSelectionButton'
import TowingCounterStepList from './TowingCounterStepList'
import { TowingElementList } from './TowingFormRoot'
import TowingFormRow from './TowingFormRow'

const TowingSelectedText = ({ text }) => {
  return <SelectedText>{text}</SelectedText>
}

const SelectedText = styled.div`
  padding-top: ${theme.spacing.sizes.normal};
  color: ${(p) => getColor(p.theme, ['primaryGreen'], ['primaryGreen'])};
  font-weight: bold;
`

const Text = styled.div`
  font-size: ${theme.font.mediumLarge};
  padding-left: ${theme.spacing.sizes.normal};
  padding-top: ${theme.spacing.sizes.normal};
  flex-grow: 1;
  color: ${(p) => getColor(p.theme, ['black'], ['white'])};
`

const TowingSelectionContainer = styled.div`
  padding-bottom: ${theme.spacing.sizes.small};
  margin-right: ${theme.spacing.sizes.normal};
  margin-left: ${theme.spacing.sizes.normal};
  margin-top: ${theme.spacing.sizes.tiny};
  margin-bottom: ${theme.spacing.sizes.tiny};
  & + & {
    margin-top: ${theme.spacing.sizes.smaller};
  }
  display: flex;
  flex-direction: row;
  gap: 20px;
`

const NoPaddingText = styled(Text)`
  padding-left: 0px;
  padding-right: 0px;
`

const TowingInformationText = styled.div`
  flex-grow: 1;
  font-size: ${theme.font.mediumSmall};
  padding-left: ${theme.spacing.sizes.normal};
  color: ${(p) => getColor(p.theme, ['grayDark'], ['grayPink'])};
`

const SelectionElementLists = ({
  names,
  selection,
  selectionState,
  selectedBranchElements,
  actionsAndSelectionsByKey,
  formAction,
}) => {
  return (
    <div>
      {names.map(
        (name) =>
          selection[name.branch] &&
          selectionState !== name.branch && (
            <div key={name.branch}>
              <TowingCounterStepList
                branchElements={selection[name.branch].elements}
                selectedBranchElements={selectedBranchElements}
                actionsAndSelectionsByKey={actionsAndSelectionsByKey}
                formAction={formAction}
              />
            </div>
          )
      )}
      {names.map(
        (name) =>
          selection[name.branch] &&
          selectionState === name.branch &&
          selection[name.branch].elements.length > 0 && (
            <div key={name.branch}>
              <TowingElementList
                key={name.branch}
                elements={selection[name.branch].elements}
                formAction={formAction}
              />
            </div>
          )
      )}
    </div>
  )
}

const TowingSelection = ({
  selection,
  selectionState,
  actionsAndSelectionsByKey,
  onSelection,
  saving,
  formAction,
}) => {
  const handleSelection = (branch) => {
    onSelection({ [selection.key]: branch })
  }

  const namePattern = /^name[\d]+$/
  const names = Object.keys(selection)
    .filter((key) => namePattern.test(key))
    .map((name) => ({ name, branch: name.replace('name', 'branch') }))

  const [expanded, setExpanded] = useState(false)

  const selectedBranch =
    selectionState && names.find((name) => selection[name.branch] && selectionState === name.branch)
  const selectedBranchElements = selectedBranch && selection[selectedBranch.branch].elements

  return (
    <div>
      <TowingFormRow onClick={() => setExpanded(!expanded)}>
        {!selectionState || expanded ? (
          <div>
            <Text>{selection.preContent && selection.preContent[0].title}</Text>
            <TowingSelectionContainer>
              {names.map((name) => (
                <TowingSelectionButton
                  key={name.name}
                  onClick={(e) => {
                    if (formAction === formActions.EDIT) {
                      e.stopPropagation()
                      handleSelection(name.branch)
                      setExpanded(false)
                    }
                  }}
                  buttonText={selection[name.name]}
                  selected={name.branch === selectionState}
                  disabled={
                    saving || (formAction !== formActions.EDIT && name.branch !== selectionState)
                  }
                />
              ))}
            </TowingSelectionContainer>
            {selection.postContent?.[0].richTextAbove?.content?.[0].content?.[0].nodeType ===
              'text' && (
              <TowingInformationText>
                {selection.postContent[0].richTextAbove.content[0].content[0].value}
              </TowingInformationText>
            )}
          </div>
        ) : (
          <TowingSelectionContainer>
            <NoPaddingText>{selection.preContent && selection.preContent[0].title}</NoPaddingText>
            <TowingSelectedText
              text={selection[(names.find((name) => selectionState === name.branch) || {}).name]}
            />
          </TowingSelectionContainer>
        )}
      </TowingFormRow>
      {selectionState && (
        <SelectionElementLists
          names={names}
          selection={selection}
          actionsAndSelectionsByKey={actionsAndSelectionsByKey}
          selectionState={selectionState}
          selectedBranchElements={selectedBranchElements}
          formAction={formAction}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state, { selection }) => {
  const actionsAndSelectionsByKey = state.towingFormState.actionsAndSelectionsByKey
  const selectionState = actionsAndSelectionsByKey[selection.key]
  const saving = state.towingFormState.saving

  return {
    actionsAndSelectionsByKey,
    selectionState,
    saving,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onSelection: (actionsAndSelectionsByKey) =>
    dispatch(saveTowingSteps(actionsAndSelectionsByKey) as unknown as Action),
})

export default connect(mapStateToProps, mapDispatchToProps)(TowingSelection)
