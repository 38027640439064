import {
  SET_VEHICLE_PATTERNS_ERROR,
  START_FETCHING_VEHICLE_PATTERNS,
  UPDATE_VEHICLE_PATTERNS,
} from '../actions/towingVehiclePatterns'
import { VehiclePatternAction } from '../actions/towingVehiclePatterns'
import { TowingPatternState } from '../types'

export const initialState = {
  loading: false,
  error: '',
  towingPatterns: [],
}

const towingPatternsReducer = (
  state: TowingPatternState = initialState,
  action: VehiclePatternAction
): TowingPatternState => {
  switch (action.type) {
    case START_FETCHING_VEHICLE_PATTERNS:
      return {
        ...state,
        error: '',
        loading: true,
      }

    case UPDATE_VEHICLE_PATTERNS:
      return {
        error: '',
        loading: false,
        towingPatterns: action.payload,
      }

    case SET_VEHICLE_PATTERNS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default towingPatternsReducer
