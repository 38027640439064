import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useTypedSelector } from '../../../..'
import { selectUpdatedActionStatus } from '../../../../reducers/serviceOrders/actionsSlice'
import { ActionStatus } from '../../../../types/Input'
import LoadingIndicator from '../../../LoadingIndicator'
import { Action, ActionPayload, MeasurementType } from '../../types'
import { constructPayload } from './InputUtils'

type Props = {
  action: Action
  containsNotMeasured: boolean
  containsDefective: boolean
  measurement: MeasurementType
  updateAction: (actionId: string, payload: ActionPayload) => void
}

const CompleteTaskButton = ({
  action,
  containsNotMeasured,
  containsDefective,
  measurement,
  updateAction,
}: Props): JSX.Element => {
  const [defectsChecked, setDefectsChecked] = useState(false)

  const isCompleted = action.status === 'MEASURED'
  const updatedStatus = useTypedSelector<ActionStatus>((state) => selectUpdatedActionStatus(state))

  const { t } = useTranslation()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDefectsChecked((event.target as HTMLInputElement).checked)
  }

  return (
    <>
      {containsDefective && !containsNotMeasured && !isCompleted && (
        <FormControlLabel
          sx={{ color: 'warning.main' }}
          checked={defectsChecked}
          onChange={(event) => handleChange(event as React.ChangeEvent<HTMLInputElement>)}
          control={<Checkbox />}
          required
          label={t('serviceOrder.actions.isDefectCreated')}
        />
      )}
      <Button
        variant="submitButtonFullWidth"
        disabled={containsNotMeasured || isCompleted || (containsDefective && !defectsChecked)}
        onClick={() => {
          updateAction(
            action.id.toString(),
            constructPayload(
              action.inspection,
              parseInt(measurement.values.at(0)?.id || ''),
              undefined
            )
          )
        }}
        endIcon={
          updatedStatus === 'loading' && !containsNotMeasured && <LoadingIndicator size="small" />
        }
      >
        <Typography variant="subtitle1">
          {isCompleted ? measurement.values.at(0)?.description : measurement.description}
        </Typography>
      </Button>
    </>
  )
}

export default CompleteTaskButton
