import styled from '@emotion/styled'
import { Router } from 'found'
import { TFunction } from 'i18next'
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { fetchTowingFormState } from '../../../actions/api'
import moment from '../../../lib/moment-fi'
import { getColor, theme } from '../../../Theme'
import { Action, Dispatch, TowingVehicle } from '../../../types'
import TowingSelectionButton from '../TowingSelectionButton'
import TowingResultsHeader from './TowingResultsHeader'

const TowingSearchResultButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const InnerButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${theme.spacing.sizes.large};
  gap: 20px;
  flex: 1;
  justify-content: flex-end;
`

const TowingResultRow = styled.div`
  ${theme.effects.shadow};
  background: ${(p) => getColor(p.theme, ['white'], ['black'])};
  ${theme.layout.flexColumn};
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: ${theme.spacing.sizes.moreHuge};
`

const BoldText = styled.div`
  font-weight: bold;
`

const getTowingStatus = (stateChanges) => {
  const stateChangesLatestFirst = stateChanges.slice().sort((firstEl, secondEl) => {
    return moment(secondEl.updatedAt).diff(moment(firstEl.updatedAt))
  })
  return stateChangesLatestFirst[0].status
}

const isNotValidForFinish = (status) => {
  return !(
    status === 'SETUP_DONE' ||
    status === 'IN_TOWING' ||
    status === 'STOP_IN_PROGRESS' ||
    status === 'FINISH_IN_PROGRESS'
  )
}

const isNotValidForSetupAndStart = (status) => {
  return status === 'STOP_IN_PROGRESS' || status === 'FINISH_IN_PROGRESS' || status === 'IN_TOWING'
}

const getText = (vehicleStates, t) => {
  const sortedStates = vehicleStates.sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt)))
  const latestState = sortedStates.find(Boolean)
  if (!latestState) {
    return t('noTowings')
  }
  const previousState = sortedStates.slice(1).find(Boolean)

  return getStatusChangeText(latestState, previousState, t)
}

const getStatusChangeText = (statusChange, previousChange, t) => {
  const fullName = statusChange.updatedBy.name
  const changeTime = moment(statusChange.updatedAt)
  //TODO: Rest of the status texts to be defined
  if (statusChange.status === 'READY') {
    return <Fragment>{t('noTowings')}</Fragment>
  }
  if (statusChange.status === 'IN_SETUP') {
    return (
      <Fragment>
        <BoldText>{t('towingSetupInProgress')}</BoldText>
        <Fragment>{`${changeTime.format('DD.MM.YYYY [Klo] LT')} ${fullName || ''}`}</Fragment>
      </Fragment>
    )
  }
  if (statusChange.status === 'HANDOVER') {
    return (
      <Fragment>
        <BoldText>{t('towingHandoverDone')}</BoldText>
        <Fragment>{`${changeTime.format('DD.MM.YYYY [Klo] LT')} ${fullName || ''}`}</Fragment>
      </Fragment>
    )
  }
  if (
    statusChange.status === 'SETUP_DONE' &&
    previousChange &&
    previousChange.status === 'STOP_IN_PROGRESS'
  ) {
    return fullName
      ? `${fullName} otti hinauksesta ${changeTime.format('DD.MM.YYYY')} Klo ${changeTime.format(
          'HH:mm'
        )}. `
      : `${t('towingSetupDone')} ${changeTime.format('DD.MM.YYYY')} Klo ${changeTime.format(
          'HH:mm'
        )}.`
  }
  if (statusChange.status === 'SETUP_DONE') {
    return (
      <Fragment>
        <BoldText>{t('towingSetupDone')}</BoldText>
        <Fragment>{`${changeTime.format('DD.MM.YYYY [Klo] LT')} ${fullName || ''}`}</Fragment>
      </Fragment>
    )
  }
  if (
    statusChange.status === 'IN_TOWING' &&
    previousChange &&
    previousChange.status !== 'STOP_IN_PROGRESS'
  ) {
    return fullName
      ? `${fullName} otti hinaukseen ${changeTime.format('DD.MM')} Klo ${changeTime.format(
          'HH:mm'
        )}. `
      : t('confirmedTowingStartWithoutPersonnel', {
          changedAt: changeTime.format('DD.MM [Klo] LT'),
        })
  }
  if (statusChange.status === 'START_IN_PROGRESS') {
    return fullName
      ? `${fullName} aloitti hinauksen aloituksen ${changeTime.format('DD.MM [Klo] LT')}`
      : t('startedTowingStartWithoutPersonnel', { changedAt: changeTime.format('DD.MM [Klo] LT') })
  }
  if (statusChange.status === 'STOP_IN_PROGRESS') {
    return fullName
      ? `${fullName} aloitti paikallaanpysymisen varmistuksen ${changeTime.format(
          'DD.MM [Klo] LT'
        )}`
      : t('startedTowingStopWithoutPersonnel', { changedAt: changeTime.format('DD.MM [Klo] LT') })
  }
  if (statusChange.status === 'FINISH_IN_PROGRESS') {
    return fullName
      ? `${fullName} aloitti hinaukseenlaiton purun ${changeTime.format('DD.MM [Klo] LT')}`
      : t('startedTowingFinishWithoutPersonnel', { changedAt: changeTime.format('DD.MM [Klo] LT') })
  }
}

type Props = {
  towing: TowingVehicle
  createTowingForm: (arg0: string, arg1: string, arg2: (arg0: string) => void) => void
  t: TFunction
  router: Router
}

const TowingSearchResult = ({ towing, createTowingForm, router, t }: Props) => {
  const handleCreateTowingForm = (vehicleType, vehicleNumber) => {
    createTowingForm(vehicleType, vehicleNumber, (id: string) => router.push(`/towing/${id}/setup`))
    // TODO: handle missing activeFormID
  }

  const fetchTowing = (id: string, formType: string) => {
    router.push(`/towing/${id}/${formType}`) // TODO: handle missing activeFormid
  }

  if (!towing) {
    return null
  }

  // TODO: harmonize font sizes
  // TODO: check for missing activeFormId
  if (towing.isDummy || !towing.vehicleStates || towing.vehicleStates.length === 0) {
    return (
      <TowingResultRow>
        <TowingResultsHeader
          vehicleType={towing.vehicleType}
          vehicleNumber={towing.vehicleNumber}
          vehicleStateString={getText(towing.vehicleStates, t)}
        />
        <TowingSearchResultButtonContainer>
          <InnerButtonContainer>
            <TowingSelectionButton
              buttonText={t('startTowingSetup')}
              onClick={() => handleCreateTowingForm(towing.vehicleType, towing.rawVehicleNumber)}
              disabled={false}
              padding={theme.spacing.sizes.normal}
            />
          </InnerButtonContainer>
        </TowingSearchResultButtonContainer>
      </TowingResultRow>
    )
  }

  const lastState = getTowingStatus(towing.vehicleStates)
  const isReady = lastState === 'READY'
  const towingStatus = getTowingStatus(towing.vehicleStates)
  const setupDisabled = isNotValidForSetupAndStart(towingStatus)
  const finishDisabled = isNotValidForFinish(towingStatus)

  return (
    <TowingResultRow>
      <TowingResultsHeader
        vehicleType={towing.vehicleType}
        vehicleNumber={towing.vehicleNumber}
        vehicleStateString={getText(towing.vehicleStates, t)}
      />
      <TowingSearchResultButtonContainer>
        {isReady ? (
          <InnerButtonContainer>
            <TowingSelectionButton
              buttonText={t('startTowingSetup')}
              onClick={() => handleCreateTowingForm(towing.vehicleType, towing.rawVehicleNumber)}
              disabled={false}
            />
          </InnerButtonContainer>
        ) : (
          <InnerButtonContainer>
            {!setupDisabled && (
              <TowingSelectionButton
                buttonText={
                  towingStatus === 'SETUP_DONE' || towingStatus === 'START_IN_PROGRESS'
                    ? t('continueTowingStart')
                    : t('continueTowingSetup')
                }
                onClick={() => fetchTowing(towing.activeFormId, 'setup')}
                disabled={isNotValidForSetupAndStart(getTowingStatus(towing.vehicleStates))}
                padding={theme.spacing.sizes.normal}
              />
            )}
            {!finishDisabled && (
              <TowingSelectionButton
                buttonText="Pura hinaus"
                onClick={() => fetchTowing(towing.activeFormId, 'finish')}
                disabled={isNotValidForFinish(getTowingStatus(towing.vehicleStates))}
              />
            )}
          </InnerButtonContainer>
        )}
      </TowingSearchResultButtonContainer>
    </TowingResultRow>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createTowingForm: (vehicleType, userVehicleNumber, changeView) =>
    dispatch(
      fetchTowingFormState(
        vehicleType,
        undefined,
        userVehicleNumber,
        changeView
      ) as unknown as Action
    ),
})

export default connect(null, mapDispatchToProps)(withTranslation()(TowingSearchResult))
