import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import CloseIcon from '../../icons/Close'
import { shiftListFeedbackURL } from '../../lib/url'
import { shiftListFeedback } from '../../Selectors'
import { color, px, theme } from '../../Theme'
import { AppState, Feedback, Shift } from '../../types'
import ButtonLink from '../button/ButtonLink'
import { EmphasisNormal } from '../Emphasis'
import FeedbackIcon from '../FeedbackIcon'
import RatingLinks from '../rating/RatingLinks'

const FeedbackContainer = styled.div<{ top: number }>`
  width: ${theme.maxWidths.column};
  position: absolute;
  z-index: 2;
  margin-top: ${theme.spacing.sizes.huge};
  ${theme.effects.shadow};
  top: ${(p) => (p.top ? px(p.top) : 'auto')};
  right: 0;
  @media (max-width: ${theme.breakpoints.medium}) {
    width: 100%;
  }
`

const Arrow = styled.div`
  position: absolute;
  top: -${theme.spacing.sizes.small};
  right: ${px(30)};
  width: 0;
  height: 0;
  border-left: ${px(8)} solid transparent;
  border-right: ${px(8)} solid transparent;
  border-bottom: ${theme.spacing.sizes.small} solid ${color('primaryBlue')};
`

const Flex = styled.div`
  ${theme.layout.flexRow};
  ${theme.spacing.left('small')};
  background: ${color('primaryBlue')};
`

const IconPadding = styled.div`
  flex: 1;
  width: ${theme.spacing.sizes.huge};
  ${theme.spacing.right('small')};
`

const FeedbackText = styled.div`
  flex: 5;
  position: relative;
  ${theme.text()};
  ${theme.spacing.ends('large')};
  ${theme.spacing.left('small')};
  ${theme.spacing.right('huge')};
  color: ${color('white')};
`

const Close = styled.div`
  ${theme.spacing.all('small')};
  width: ${theme.spacing.sizes.large};
  color: ${color('white')};
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`

type Props = {
  t: TFunction
  hasFeedback?: boolean
  feedback?: Feedback | null
  shift: Shift
  top: number
  onToggle: () => void
}

const ShiftListFeedbackWidget = ({ t, hasFeedback, feedback, shift, top, onToggle }: Props) => (
  <FeedbackContainer top={top}>
    <Arrow />
    <Flex>
      <IconPadding>
        <FeedbackIcon color="white" notification={false} />
      </IconPadding>
      <FeedbackText>
        <EmphasisNormal color={'white'}>
          {hasFeedback ? t('feedbackGiven') : t('yourThoughtsOnShiftList')}
        </EmphasisNormal>
        <div>{hasFeedback ? '' : t('giveFeedbackFromShiftList')}</div>
        <Close onClick={onToggle}>
          <CloseIcon iconSize="normal" />
        </Close>
      </FeedbackText>
    </Flex>
    {feedback && hasFeedback ? (
      <ButtonLink
        topOpen={true}
        bottomOpen={false}
        onClick={onToggle}
        to={shiftListFeedbackURL(shift.id, feedback.stars)}
      >
        {t('viewFeedback')}
      </ButtonLink>
    ) : (
      <RatingLinks
        bg=""
        isMobile={false}
        onClick={onToggle}
        stars={feedback ? feedback.stars : 0}
        getUrl={(stars) => shiftListFeedbackURL(shift.id, stars)}
      />
    )}
  </FeedbackContainer>
)

type PropsIn = {
  shift: Shift
  isMobile?: boolean
}

const mapStateToProps = (state: AppState, { shift, isMobile }: PropsIn): unknown => {
  const feedback = shiftListFeedback(shift, state)
  const hasFeedback = feedback && feedback.id
  const showInline = !isMobile && !hasFeedback
  const showBelow = isMobile || hasFeedback

  return {
    hasFeedback,
    feedback,
    showBelow,
    showInline,
  }
}

export default withTranslation()(connect(mapStateToProps)(ShiftListFeedbackWidget))
