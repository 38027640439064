import { SearchedShift, Shift } from '../types'

export type SearchShifts = {
  type: 'SEARCH_SHIFTS'
}
export const SEARCH_SHIFTS = 'SEARCH_SHIFTS'
export const searchShifts = (): SearchShifts => ({
  type: SEARCH_SHIFTS,
})

export type UpdateSearchedShifts = {
  type: 'UPDATE_SEARCHED_SHIFTS'
  payload: SearchedShift
}
export const UPDATE_SEARCHED_SHIFTS = 'UPDATE_SEARCHED_SHIFTS'
export const updateSearchedShifts = (shifts: SearchedShift): UpdateSearchedShifts => ({
  type: UPDATE_SEARCHED_SHIFTS,
  payload: shifts,
})

export type SearchedShiftsError = {
  type: 'SEARCHED_SHIFTS_ERROR'
  payload: string
}
export const SEARCHED_SHIFTS_ERROR = 'SEARCHED_SHIFTS_ERROR'
export const searchedShiftsLoadError = (error: string): SearchedShiftsError => ({
  type: SEARCHED_SHIFTS_ERROR,
  payload: error,
})

export type FetchSearchedShift = {
  type: 'FETCH_SEARCHED_SHIFT'
}
export const FETCH_SEARCHED_SHIFT = 'FETCH_SEARCHED_SHIFT'
export const fetchSearchedShift = (): FetchSearchedShift => ({
  type: FETCH_SEARCHED_SHIFT,
})

export type UpdateSelectedShift = {
  type: 'UPDATE_SELECTED_SHIFT'
  payload: Shift
}
export const UPDATE_SELECTED_SHIFT = 'UPDATE_SELECTED_SHIFT'
export const updateSelectedShift = (shift: Shift): UpdateSelectedShift => ({
  type: UPDATE_SELECTED_SHIFT,
  payload: shift,
})

export type UpdateShiftHistory = {
  type: 'UPDATE_SHIFT_HISTORY'
  payload: Array<Shift>
}
export const UPDATE_SHIFT_HISTORY = 'UPDATE_SHIFT_HISTORY'
export const updateShiftHistory = (shifts: Array<Shift>): UpdateShiftHistory => ({
  type: UPDATE_SHIFT_HISTORY,
  payload: shifts,
})

export type ShiftSearchAction =
  | SearchShifts
  | SearchedShiftsError
  | UpdateSearchedShifts
  | FetchSearchedShift
  | UpdateSelectedShift
  | UpdateShiftHistory
