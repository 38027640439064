import type { CSSObject } from '@emotion/react'
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  filledInputClasses,
  formLabelClasses,
  outlinedInputClasses,
  Snackbar,
  SnackbarProps,
  styled,
  TextField,
  TextFieldProps,
  Theme,
} from '@mui/material'
import React from 'react'

interface TemporaryAlertProps extends SnackbarProps {
  position?: CSSObject['position']
}

export const TemporaryAlert = styled(
  (props: SnackbarProps) => (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} {...props} />
  ),
  { shouldForwardProp: (prop) => prop !== 'position' }
)<TemporaryAlertProps>(({ position }) => ({
  position: position,
}))

export const VrTextField = styled((props: TextFieldProps) => (
  <TextField
    InputLabelProps={{ shrink: true }}
    variant="outlined"
    color="info"
    // Known issue: https://github.com/mui/material-ui/issues/27593
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: component string is not assignable to type Partial<...>
    FormHelperTextProps={{ component: 'div' }}
    inputProps={{
      sx: {
        '&::placeholder': {
          color: (theme: Theme) => theme.palette.background.altField,
          opacity: 0.4,
        },
      },
    }}
    {...props}
  />
))(({ theme }) => ({
  [`& .${outlinedInputClasses.root}`]: {
    backgroundColor: theme.palette.background.altField,
    borderRadius: '4px',
  },
  [`& .${filledInputClasses.root}`]: {
    borderRadius: '3px',
    height: '68px',
    borderColor: theme.palette.fieldBorderGrey,
    borderWidth: 1,
    borderStyle: 'solid',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  [`& .${formLabelClasses.root}`]: {
    color: theme.palette.text.secondary,
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-focused': {
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
  },
  '& .MuiFilledInput-underline': {
    '&:before': {
      borderBottom: 'none !important',
    },
    '&:after': {
      borderBottom: 'none !important',
    },
  },
}))

export const UploadBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant?: 'normal' | 'error' | 'drag' }>(({ variant, theme }) => {
  let borderColor
  let borderStyle = 'solid'
  let backgroundColor

  switch (variant) {
    case 'drag':
      borderColor = theme.palette.info.main
      backgroundColor = theme.palette.info.lighter
      borderStyle = 'dashed'
      break
    case 'error':
      borderColor = theme.palette.error.main
      backgroundColor = theme.palette.background.altField
      break
    default:
      borderColor = theme.palette.fieldBorderGrey
      backgroundColor = theme.palette.background.altField
  }

  return {
    backgroundColor,
    borderColor,
    borderStyle,
    alignItems: 'center',
    flexDirection: 'column',
    borderWidth: 1,
    borderRadius: '8px',
    flexGrow: 3,
    display: 'flex',
    flex: '1 1 80px',
    justifyContent: 'center',
    height: '100%',
    padding: '0.5rem 0.75rem',
    color: theme.palette.info.text,
  }
})

const AttachmentItemCss = ({ theme }: { theme: Theme }): CSSObject => {
  const marginWidth = 4
  return {
    backgroundColor: theme.palette.attachmentBackground,
    border: `1px solid ${theme.palette.attachmentBorder}`,
    borderRadius: '16px',
    color: theme.palette.info.text,
    justifyContent: 'space-between',
    width: `calc(99% - ${marginWidth * 2}px)`,
    maxWidth: '300px',
    minWidth: '0',
    whiteSpace: 'nowrap',
    paddingRight: '20px',
    paddingLeft: '32px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '40px',
    margin: `2px ${marginWidth}px`,
    alignItems: 'center',
    display: 'flex',
  }
}

export const AttachmentItemButton = styled(Button)<ButtonProps>(AttachmentItemCss)

export const AttachmentItem = styled(Box)<BoxProps>(AttachmentItemCss) as typeof Box

export const DropdownItemCss = (
  theme: Theme,
  optionSelector: string,
  selectedSelector: string
): CSSObject => ({
  [optionSelector]: {
    [selectedSelector]: {
      backgroundColor: `${theme.palette.infoHighlight} !important`,
    },
    '&:hover': {
      backgroundColor: `${theme.palette.infoHighlight} !important`,
      opacity: 0.9,
    },
  },
})
