import { color } from '../Theme'
import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const Error = ({ iconSize, fillColor = color('red'), stroke = color('white') }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={size} height={size} viewBox="0 0 120 120">
      <g fill="none" fillRule="evenodd">
        <circle key="0" cx={60} cy={60} r={60} fill={fillColor} />
        <circle key="1" cx={60} cy={60} r={59} stroke={stroke} strokeWidth="2" />
        <path
          key="2"
          stroke={stroke}
          strokeLinecap="round"
          strokeWidth="2"
          d="M36 36l47.917 47.917M83.917 36L36 83.917"
        />
      </g>
    </svg>
  )
}

export default Error
