import styled from '@emotion/styled'

import { getColor, theme } from '../../Theme'

interface Props {
  blue?: boolean
  nowrap?: boolean
  borderRight?: boolean
  left?: string
  width?: string
}

interface ContentColProps extends Props {
  background?: string
  brakeWeightPercent?: number
}

interface StickyColProps extends Props {
  color?: string
  onClick?: () => void
}

interface TableHeaderProps extends Props {
  main?: boolean
}

export const Table = styled.table`
  border: none;
  border-collapse: collapse;
  @media (max-width: ${theme.breakpoints.mediumLarge}) {
    width: ${(p) => p.width || '100%'};
  }
  @media (min-width: ${theme.breakpoints.mediumLarge}) {
    margin-left: 50%;
    transform: translateX(-50%);
  }
`
export const TableHeader = styled.th<TableHeaderProps>`
  font-size: ${theme.font.small};
  font-weight: normal;
  box-shadow: ${(p) =>
    `inset -0.3px -0.3px ${getColor(
      p.theme,
      ['grayLight'],
      ['grayLight']
    )}, inset 0.3px 0.3px ${getColor(p.theme, ['grayLight'], ['grayLight'])}`};
  position: ${(p) => (p.main ? 'absolute' : 'auto')};
  width: ${(p) => (p.main ? '32px' : 'inherit')};
  padding: 5px;
  background-color: ${(p) => p.color};
  white-space: nowrap;
`

export const ContentCol = styled.td<ContentColProps>`
  font-size: ${theme.font.small};
  color: ${(p) =>
    p.blue
      ? getColor(p.theme, ['primaryBlue'], ['black'])
      : getColor(p.theme, ['black'], p.background === 'yellow' ? ['black'] : ['white'])};
  background: ${(p) => p.background};
  width: ${(p) => p.width || 'inherit'};
  padding: 5px;
  text-align: center;
  box-shadow: ${(p) =>
    `inset -0.3px -0.3px ${getColor(
      p.theme,
      ['grayLight'],
      ['grayLight']
    )}, inset 0.3px 0.3px ${getColor(p.theme, ['grayLight'], ['grayLight'])}`};
  ${(p) => p.nowrap && 'white-space: nowrap;'}
`

export const StickyCol = styled.td<StickyColProps>`
  @media (max-width: ${theme.breakpoints.mediumLarge}) {
    position: sticky;
    width: ${(p) => p.width};
    left: ${(p) => p.left};
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }
  box-shadow: ${(p) =>
    `inset -0.3px -0.3px ${getColor(
      p.theme,
      ['grayLight'],
      ['grayLight']
    )}, inset 0.3px 0.3px ${getColor(p.theme, ['grayLight'], ['grayLight'])}`};
  cursor: ${(p) => (p.onClick ? 'pointer' : 'default')};
  background-color: ${(p) => (p.color ? p.color : 'inherit')};
  font-size: ${theme.font.small};
  color: ${(p) =>
    p.blue
      ? getColor(p.theme, ['primaryBlue'], ['primaryBlue'])
      : getColor(p.theme, ['blackAbsolute'], ['blackAbsolute'])};
  padding: 5px;
  text-align: center;
  ${(p) => p.nowrap && 'white-space: nowrap;'}
`

export const TableRow = styled.tr<{ danger?: boolean }>`
  background: ${(p) =>
    p.danger
      ? getColor(p.theme, ['grayPink'], ['grayWarm'])
      : getColor(p.theme, ['grayBackground'], ['nightBackground'])};
  padding: 5px;
`

export const TableHeaderRow = styled.tr`
  background-color: ${(p) => p.color};
  padding: 5px;
`

export const TableContainer = styled.div<{ stickyCol: boolean }>`
  @media (max-width: ${theme.breakpoints.mediumLarge}) {
    margin: ${(p) => (p.stickyCol ? '0' : 'auto')};
    overflow-x: scroll;
  }
`

export const SectionTitle = styled.div`
  padding-top: 32px;
  font-size: ${theme.font.small};
  font-weight: bold;
  color: ${(p) => getColor(p.theme, ['black'], ['white'])};
  text-transform: uppercase;
  text-align: center;
`
