import styled from '@emotion/styled'

import { color, getColor, theme } from '../../Theme'

interface SearchBoxProps {
  active?: boolean
  disabled?: boolean | string
  marginRight?: string
  marginLeft?: string
  disableWidth?: boolean
  onClick?: (arg0: boolean) => void
}

const SearchBox = styled.div<SearchBoxProps>`
  border-style: solid;
  border-width: 2px;
  border-color: ${(p) =>
    p.active
      ? `${color('primaryBlue')}`
      : p.disabled
      ? `${getColor(p.theme, ['grayLight'], ['nightGray'])}`
      : `${getColor(p.theme, ['grayLight'], ['nightGray'])}`};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  background-color: ${(p) =>
    p.disabled
      ? getColor(p.theme, ['grayLight'], ['nightGray'])
      : getColor(p.theme, ['white'], ['black'])};
  margin-right: ${(p) => p.marginRight || 'none'};
  margin-left: ${(p) => p.marginLeft || 'none'};
  width: ${(p) => (p.disableWidth ? 'none' : '100%')};
  & + & {
    margin-top: ${theme.spacing.sizes.smaller};
  }
`

export default SearchBox
