import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import moment from 'moment-timezone'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { markFeedbackAsRead, sendCrewNoticeAck } from '../../actions/api'
import { toggleScheduleEvents } from '../../actions/schedulepage'
import NotificationIcon from '../../icons/Notification'
import Refresh from '../../icons/Refresh'
import { scheduleEventsSelector } from '../../Selectors'
import { color, theme } from '../../Theme'
import { Action, AppState, Dispatch } from '../../types'
import { Event } from '../../types/Input'
import Circle from '../circle/Circle'
import HeaderContainer from '../header/HeaderContainer'
import { HeaderAction } from '../header/HeaderLink'
import HeaderTitle from '../header/HeaderTitle'
import NotificationDrawer from '../notification/NotificationDrawer'

const Relative = styled.div`
  position: relative;
`

const Notification = styled(Circle)`
  ${theme.layout.flexRow};
  ${theme.spacing.all('smallest')};
  justify-content: center;
  position: absolute;
  top: -1px;
  right: -4px;
  width: ${theme.spacing.sizes.tiny};
  height: ${theme.spacing.sizes.tiny};
  color: ${color('white')};
  background: ${(p) => color(p.color, 'primaryYellow')};
  border: 1px solid ${(p) => color(p.color, 'primaryYellow')};
`

type Props = {
  t: TFunction
  loading?: boolean
  onReload: () => void
  onToggleNotifications?: () => void
  events: Array<Event>
  eventsOpen: boolean
  hasUnreadEvents: boolean
  onToggleEvents: () => void
  readEvent: (arg0: string) => void
  sendAck: (arg0: string) => void
}

const ScheduleHeader = ({
  t,
  events,
  hasUnreadEvents,
  eventsOpen,
  onReload,
  onToggleEvents,
  readEvent,
  sendAck,
  loading,
}: Props) => {
  const readHandlers = {
    feedbackResponse: readEvent,
    pendingCrewNotice: sendAck,
    /* eslint-disable @typescript-eslint/no-empty-function */
    readCrewNotice: () => {},
  }

  return (
    <HeaderContainer border={true}>
      <>
        <HeaderAction onClick={onToggleEvents}>
          <Relative>
            <NotificationIcon iconSize="normal" />
            {hasUnreadEvents ? <Notification>!</Notification> : undefined}
          </Relative>
        </HeaderAction>
        {eventsOpen ? (
          <NotificationDrawer
            events={events}
            top={24}
            readHandlers={readHandlers}
            close={onToggleEvents}
          />
        ) : undefined}
        <HeaderTitle title={t('shiftSchedule')} subtitle="" />
        <HeaderAction onClick={onReload} loading={loading}>
          <Refresh iconSize="normal" />
        </HeaderAction>
      </>
    </HeaderContainer>
  )
}

const mapStateToProps = (state: AppState) => {
  const events = scheduleEventsSelector(state).sort((a, b) => -1 * moment(a.date).diff(b.date))
  const hasUnreadEvents = !!events.find((e) => !e.read)

  return {
    hasUnreadEvents,
    events,
    eventsOpen: state.schedulePage.eventsOpen,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onToggleEvents: () => dispatch(toggleScheduleEvents()),
  readEvent: (feedbackId: string) => dispatch(markFeedbackAsRead(feedbackId) as unknown as Action),
  sendAck: (crewNoticeId: string) =>
    dispatch(sendCrewNoticeAck(crewNoticeId, 'ACKNOWLEDGED', moment()) as unknown as Action),
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ScheduleHeader))
