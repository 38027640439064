import { Personnel } from '../types'

export type UpdatePersonnel = {
  type: 'UPDATE_PERSONNEL'
  payload: Personnel
}
export const UPDATE_PERSONNEL = 'UPDATE_PERSONNEL'
export const updatePersonnel = (personnel: Personnel): UpdatePersonnel => ({
  type: UPDATE_PERSONNEL,
  payload: personnel,
})

export type FetchPersonnel = {
  type: 'FETCH_PERSONNEL'
}
export const FETCH_PERSONNEL = 'FETCH_PERSONNEL'
export const fetchPersonnel = () => ({ type: FETCH_PERSONNEL })

export type UpdatePersonnelHistory = {
  type: 'UPDATE_PERSONNEL_HISTORY'
  payload: Array<Personnel>
}
export const UPDATE_PERSONNEL_HISTORY = 'UPDATE_PERSONNEL_HISTORY'
export const updatePersonnelHistory = (history: Array<Personnel>) => ({
  type: UPDATE_PERSONNEL_HISTORY,
  payload: history,
})

export type SetPersonnelFromHistory = {
  type: 'SET_PERSONNEL_FROM_HISTORY'
  payload: Personnel
}
export const SET_PERSONNEL_FROM_HISTORY = 'SET_PERSONNEL_FROM_HISTORY'
export const setPersonnelFromHistory = (personnel: Personnel) => ({
  type: SET_PERSONNEL_FROM_HISTORY,
  payload: personnel,
})

export type SetPersonnelError = {
  type: 'PERSONNEL_ERROR'
  payload: string
}
export const PERSONNEL_ERROR = 'PERSONNEL_ERROR'
export const personnelLoadError = (error: string) => ({ type: PERSONNEL_ERROR, payload: error })

export type PersonnelAction =
  | FetchPersonnel
  | SetPersonnelFromHistory
  | SetPersonnelError
  | UpdatePersonnelHistory
  | UpdatePersonnel
