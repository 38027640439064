import styled from '@emotion/styled'
import React from 'react'

import { defaultTextColor, theme } from '../../../Theme'
import Text from '../../Text'

const Column = styled.div`
  ${theme.layout.flexColumn};
  align-items: flex-start;
  padding-left: 16px;
  padding-top: 16px;
  padding-right: 16px;
`

const TextContainer = styled(Text)`
  ${theme.text('mediumLarge', 'content')};
  color: ${defaultTextColor};
`

const HeaderContainer = styled(Text)`
  ${theme.text('secondLargest', 'content')};
  color: ${defaultTextColor};
`

type Props = {
  vehicleType: string
  vehicleNumber: string
  vehicleStateString?: string
}

const TowingResultsHeader = ({ vehicleType, vehicleNumber, vehicleStateString }: Props) => {
  return (
    <Column>
      <HeaderContainer>{`${vehicleType} - ${vehicleNumber}`}</HeaderContainer>
      <TextContainer>{vehicleStateString}</TextContainer>
    </Column>
  )
}

export default TowingResultsHeader
