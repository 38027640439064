import { sequentially } from './async'
import { unixTimestampMs } from './time'

export type Task = {
  fn: (arg1: any) => Promise<any>
  params: any
  retries: number
  wait: number
  onFail: any | null | undefined
  key: string | null | undefined
}
type QueueTask = Task & {
  executeAt: number
}
type Queue = Array<QueueTask>

/* Async function executor
 *
 * Stores tasks (functions & parameters) which
 * can be retried if there is error
 *
 * Tasks can be scheduled
 */
class Executor {
  queue: Queue
  current: Promise<undefined>
  constructor() {
    this.queue = []
    this.current = Promise.resolve(undefined)
  }
  enqueue(task: Task): void {
    if (task.key && this.queue.find((t) => t.key === task.key)) {
      // Task is duplicate, don't enqueue
    } else {
      this.queue.push({
        ...task,
        executeAt: unixTimestampMs() + task.wait,
      })
    }
  }
  execute(): Promise<undefined> {
    return this.current.then(() => {
      if (this.queue.length > 0) {
        const now = unixTimestampMs()
        // Determine time when next waiting task is ready to execute
        const timeToNext = this.queue.reduce((diff, task) => {
          return Math.min(diff, task.executeAt - now)
        }, 30000)
        // Schedule next batch
        return new Promise((resolve) => {
          setTimeout(() => resolve(this.nextBatch()), timeToNext)
        })
      }
    })
  }
  nextBatch(): Promise<undefined> {
    const now = unixTimestampMs()
    // Separate tasks that need to wait and tasks that can execute
    const [newQueue, tasks] = this.queue.reduce(
      ([waiting, going]: [any, any], task) => {
        if (task.executeAt > now) {
          return [waiting.concat(task), going]
        }
        return [waiting, going.concat(task)]
      },
      [[], []]
    )

    // If there are executable tasks execute them
    if (tasks.length > 0) {
      this.queue = newQueue
      this.current = this.runQueue(tasks)
      return this.execute()
    }
    if (newQueue.length > 0) {
      return this.execute()
    }
    return Promise.resolve(undefined)
  }

  // Execute tasks and re-enqueue failed tasks
  runQueue(tasks: Queue): Promise<undefined> {
    return sequentially(tasks, (task: QueueTask): Promise<unknown> => {
      return task.fn(task.params).catch((err) => {
        if (task.retries > 0) {
          this.enqueue({
            ...task,
            retries: task.retries - 1,
          })
        } else if (task.onFail) {
          task.onFail(err, task.params)
        }
      })
    }).then(() => undefined)
  }
}

export function createTask(
  fn: (arg1: any) => Promise<any>,
  params: any,
  retries = 0,
  wait = 1000,
  onFail: any | null = null
): Task {
  return {
    fn,
    params,
    retries,
    wait,
    onFail,
    key: undefined,
  }
}

export function createKeyedTask(
  key: string,
  fn: (arg1: any) => Promise<any>,
  params: any,
  retries = 0,
  wait = 1000,
  onFail: any | null = null
): Task {
  return {
    fn,
    params,
    retries,
    wait,
    onFail,
    key,
  }
}

export default Executor
