import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import TreeItem, { treeItemClasses, TreeItemProps } from '@mui/lab/TreeItem'
import TreeView from '@mui/lab/TreeView'
import { alpha, Box, Collapse, CollapseProps, styled, Typography } from '@mui/material'
import React from 'react'
import { SyntheticEvent, useState } from 'react'

import { ItemData, Tree } from './types'

function TransitionComponent(props: CollapseProps): JSX.Element {
  return <Collapse {...props} />
}

const treeItemContentCSSSelector = `& .${treeItemClasses.content}.${treeItemClasses.selected}, & .${treeItemClasses.content}.${treeItemClasses.focused}, & .${treeItemClasses.content}.${treeItemClasses.selected}.${treeItemClasses.focused}, & .${treeItemClasses.focused}`

const replaceWithSelectedIcon = (isSelected: boolean): JSX.Element | undefined => {
  if (isSelected) {
    return (
      <CheckBoxIcon
        sx={{
          color: 'checkBox',
        }}
      />
    )
  }
  return undefined
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} color="info" />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [treeItemContentCSSSelector]: {
    // backgroundColor: theme.palette.infoHighlight,
    borderRadius: '0.25rem',
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: '1rem',
    paddingLeft: '0.5rem',
    borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  padding: '0.25rem',
})) as typeof TreeItem

export const itemLabel = (item: ItemData): string =>
  `${item.itemId}(${item.serialNumber})${item.description ? ` - ${item.description}` : ''}`

const compareItems = (a: Tree<ItemData>, b: Tree<ItemData>): number => {
  if (a.itemId > b.itemId) {
    return 1
  }
  if (a.itemId < b.itemId) {
    return -1
  }
  return 0
}

const createLeaves = (children: Tree<ItemData>[], selected: string): JSX.Element[] => {
  return children.sort(compareItems).map((child) => createTreeItems(child, selected))
}

const createTreeItems = (tree: Tree<ItemData>, selected: string): JSX.Element => {
  return (
    <StyledTreeItem
      color="info"
      key={tree.uniqueId}
      nodeId={tree.uniqueId}
      label={<Typography>{itemLabel(tree)}</Typography>}
      icon={replaceWithSelectedIcon(selected === tree.uniqueId)}
    >
      {createLeaves(tree.children, selected)}
    </StyledTreeItem>
  )
}

export default function CustomizedTreeView({
  items,
  selected,
  setSelected,
  defaultExpanded,
}: {
  selected: string
  setSelected: (id: string) => void
  items: Tree<ItemData>
  defaultExpanded: string[]
}): JSX.Element {
  const [expanded, setExpanded] = useState<string[]>(defaultExpanded)
  const toggleSelection = (_event: SyntheticEvent, nodeId: string): void => {
    if (selected === nodeId) {
      setSelected('')
      return
    }
    setSelected(nodeId)
  }
  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]): void => {
    setExpanded(nodeIds)
  }

  return (
    <Box sx={{ flexGrow: 1, overflowY: 'auto', minWidth: { tablet: '650px' } }}>
      <TreeView
        defaultCollapseIcon={<RemoveCircleOutlineIcon />}
        defaultExpandIcon={<AddCircleOutlineIcon />}
        defaultEndIcon={<CheckBoxOutlineBlankIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        color="info"
        onNodeSelect={toggleSelection}
      >
        {createTreeItems(items, selected)}
      </TreeView>
    </Box>
  )
}
