import { Theme, withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React from 'react'
import { ThemeConfig } from 'react-select'

import { AMENDMENT_CONFIRMATION_DURATION_MS } from '../../constants'
import ArrowLeft from '../../icons/ArrowLeft'
import { getPunctualityStationName } from '../../lib/stations'
import { color, defaultTextColor, getColor, theme } from '../../Theme'
import type { Causes, FirstLevelCause, SecondLevelCause } from '../../types'
import { ThemeProps } from '../../types/App'
import { SendStatus } from '../../types/States'
import Button, { OutlineButton } from '../button/Button'
import LoadingIndicator from '../LoadingIndicator'
import AmendmentErrorModal from './AmendmentErrorModal'
import { CauseDisplay, getTypedTranslationKey } from './Common'

const AmendFormContainer = styled.div`
  ${theme.layout.flexColumn};
  align-items: flex-start;
`
AmendFormContainer.displayName = 'AmendFormContainer'

const CauseDisplayContainer = styled.div`
  ${theme.layout.flexRow};
`
CauseDisplayContainer.displayName = 'CauseDisplayContainer'

const CauseDisplayColumn = styled.div`
  ${theme.layout.flexColumn};
  align-items: flex-start;
  color: ${defaultTextColor};
  ${theme.spacing.ends('normal')};
`
CauseDisplayColumn.displayName = 'CauseDisplayColumn'

const CurrentCauseDescription = styled.div`
  ${theme.text('small', 'content', 'normal')};
  color: ${defaultTextColor};
  ${theme.spacing.all('smallest')}
`

//Width needs to be 99% because the textarea will otherwise look
//wider than other elements on the page
const DescriptionArea = styled.textarea<ThemeProps>`
  width: 99%;
  margin-top: 10px;
  background: ${(p) => getColor(p.theme, ['white'], ['grayPink'])};
  color: ${(p) => getColor(p.theme, ['black'], ['black'])};
  ${theme.text('normal', 'content')};
`
DescriptionArea.displayName = 'DescriptionArea'

const TrainNumberDescription = styled.span`
  margin-right: 6px;
  ${theme.text('normal')}
`
TrainNumberDescription.displayName = 'TrainNumberDescription'

const TrainNumberArea = styled.input`
  resize: none;
  width: 100px;
  height: 22px;
`
TrainNumberArea.displayName = 'TrainNumberArea'

const TrainNumberContainer = styled.div`
  ${theme.layout.flexRow};
  width: 100%;
  justify-content: flex-end;
`
TrainNumberContainer.displayName = 'TrainNumberContainer'

const ReturnButton = styled(OutlineButton)`
  flex-basis: 12%;
`
ReturnButton.displayName = 'ReturnButton'

const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 10px;
`
SubmitButton.displayName = 'SubmitButton'

const ErrorText = styled.div`
  ${theme.spacing.ends('small')}
  ${theme.text('large')};
  color: ${color('red')};
  margin: auto;
`
ErrorText.displayName = 'ErrorText'

const SendingIndicator = styled.div`
  align-self: center;
`
SendingIndicator.displayName = 'SendingIndicator'

const SuccessContainer = styled.div`
  ${theme.layout.flexColumn}
`
SuccessContainer.displayName = 'SuccessContainer'

const SuccessTitle = styled.div`
  ${theme.text('largest', 'content', 'bold')};
  color: ${defaultTextColor};
  margin-top: 20px;
`
SuccessTitle.displayName = 'SuccessTitle'

const SuccessText = styled.div`
  ${theme.text('large')};
  color: ${defaultTextColor};
  text-align: center;
  ${theme.spacing.all('large')}
`
SuccessText.displayName = 'SuccessText'

const SuccessReturnButton = styled(Button)`
  max-width: 100px;
`
SuccessReturnButton.displayName = 'SuccessReturnButton'

type PropsIn = {
  t: TFunction
  theme: ThemeConfig & Theme
  causeTree: Causes
  selectedGroupKey: string | null | undefined
  selectedFirstLevelKey: string | null | undefined
  selectedSecondLevelKey: string | null | undefined
  delayMinutes: number
  depOcp: string
  arrOcp: string
  causeCode: string
  depOrArr: string
  resetDisplay: () => void
  sendAmendment: (arg1: string, arg2: number) => void
  sendStatus: SendStatus
  amendmentError: string
  type: string
  askTrainNumber: boolean | null | undefined
  resetError: () => void
  returnToPunctualityPage: () => void
  originalDescription: string | null | undefined
}

type State = {
  description: string
  relatedTrainNumber: string
  requireDescriptionNotification: boolean
  showSendConfirmation: boolean
  startedReturn: boolean
}

class CompleteAmendment extends React.Component<PropsIn, State> {
  constructor(props: PropsIn) {
    super(props)

    this.state = {
      description: '',
      relatedTrainNumber: '',
      requireDescriptionNotification: false,
      showSendConfirmation: false,
      startedReturn: false,
    }
  }

  componentWillUnmount() {
    this.props.resetError()
  }

  handleDescriptionTextInput = (event: any) => {
    this.setState({
      description: event.target.value,
      requireDescriptionNotification:
        event.target.value && event.target.value.length > 0
          ? false
          : this.state.requireDescriptionNotification,
    })
  }

  handleTrainNumberTextInput = (event: any) => {
    this.setState({
      relatedTrainNumber: event.target.value,
    })
  }

  handleSubmit = () => {
    if (!this.state.description) {
      this.setState({
        requireDescriptionNotification: true,
      })
      return
    }
    this.props.sendAmendment(this.state.description, parseInt(this.state.relatedTrainNumber))
  }

  initiateReturn = () => {
    if (!this.state.startedReturn) {
      this.setState({
        ...this.state,
        startedReturn: true,
      })
      this.props.returnToPunctualityPage()
    }
  }

  enableSendConfirmation = () => {
    this.setState({
      showSendConfirmation: true,
    })
    setTimeout(() => {
      this.initiateReturn()
    }, AMENDMENT_CONFIRMATION_DURATION_MS)
  }

  render() {
    const {
      t,
      theme,
      causeTree,
      selectedGroupKey,
      selectedFirstLevelKey,
      selectedSecondLevelKey,
      delayMinutes,
      depOcp,
      arrOcp,
      causeCode,
      depOrArr,
      resetDisplay,
      sendStatus,
      amendmentError,
      type,
      askTrainNumber,
      resetError,
      originalDescription,
    }: PropsIn = this.props

    const selectedGroup = causeTree[selectedGroupKey || '']

    if (!selectedGroup) return null

    const selectedFirstLevel: FirstLevelCause | null | undefined = selectedFirstLevelKey
      ? selectedGroup.firstLevelCodes[selectedFirstLevelKey]
      : null

    if (!this.state.showSendConfirmation && sendStatus === 'sent') {
      this.enableSendConfirmation()
    }

    if (!selectedFirstLevel) return null

    const selectedSecondLevel: SecondLevelCause | null | undefined = selectedSecondLevelKey
      ? selectedFirstLevel.secondLevelCodes
        ? selectedFirstLevel.secondLevelCodes[selectedSecondLevelKey]
        : null
      : null

    return this.state.showSendConfirmation ? (
      <SuccessContainer>
        <SuccessTitle>{t(`deviationAmendment.sent.${type}`)}</SuccessTitle>
        <SuccessText>{t('deviationAmendment.returnToPunctualityPage')}</SuccessText>
        <LoadingIndicator size="normal" padded={true} />
        <SuccessText>{t('deviationAmendment.manualReturn')}</SuccessText>
        <SuccessReturnButton onClick={() => this.initiateReturn()}>
          {t('return')}
        </SuccessReturnButton>
      </SuccessContainer>
    ) : (
      <AmendFormContainer>
        <AmendmentErrorModal
          isOpen={!!amendmentError}
          message={amendmentError}
          closeErrorModal={resetError}
        />
        <CurrentCauseDescription>
          {t(
            getTypedTranslationKey(
              delayMinutes > 0 ? 'delayDescription' : 'earlyDescription',
              depOrArr
            ),
            {
              delay: delayMinutes,
              departure: getPunctualityStationName(depOcp),
              arrival: arrOcp ? getPunctualityStationName(arrOcp) : '',
            }
          )}
          : <b>{causeCode}</b>
        </CurrentCauseDescription>
        <CurrentCauseDescription>{originalDescription}</CurrentCauseDescription>
        <CauseDisplayContainer>
          <ReturnButton onClick={() => resetDisplay()}>
            <ArrowLeft stroke={color('primaryGreen')} />
          </ReturnButton>
          <CauseDisplayColumn>
            {t('yourChoice')}
            <CauseDisplay>
              {selectedGroupKey} - {selectedGroup.description}
            </CauseDisplay>
            <CauseDisplay>
              {selectedFirstLevelKey} - {selectedFirstLevel.description}
            </CauseDisplay>
            {selectedSecondLevel && (
              <CauseDisplay>
                {selectedSecondLevelKey} - {selectedSecondLevel.description}
              </CauseDisplay>
            )}
          </CauseDisplayColumn>
        </CauseDisplayContainer>
        {askTrainNumber && (
          <TrainNumberContainer>
            <TrainNumberDescription>{t('deviationAmendment.relatedTrain')}</TrainNumberDescription>
            <TrainNumberArea
              type="number"
              name="trainNumber"
              value={this.state.relatedTrainNumber}
              onChange={this.handleTrainNumberTextInput}
            />
          </TrainNumberContainer>
        )}
        <DescriptionArea
          name="description"
          theme={theme}
          placeholder="Kirjoita perustelu"
          value={this.state.description}
          onChange={this.handleDescriptionTextInput}
          rows={6}
        />
        {this.state.requireDescriptionNotification && (
          <ErrorText>{t('deviationAmendment.descriptionIsRequired')}</ErrorText>
        )}
        {amendmentError && amendmentError.length > 0 && <ErrorText>{amendmentError}</ErrorText>}
        {sendStatus === 'sending' ? (
          <SendingIndicator>
            <LoadingIndicator size="normal" padded={true} />
          </SendingIndicator>
        ) : (
          <SubmitButton onClick={this.handleSubmit}>
            {t(`deviationAmendment.send.${type}`)}
          </SubmitButton>
        )}
      </AmendFormContainer>
    )
  }
}

export default withTheme(CompleteAmendment)
