import { createSlice } from '@reduxjs/toolkit'

import { fetchActionsAction, updateActionValueAction } from '../../actions/serviceOrders/actions'
import { Action } from '../../components/serviceOrders/types'
import { ActionStatus } from '../../types/Input'

export interface DataWithStatus<T> {
  status: ActionStatus
  data: T
  error?: string
}

interface StateWithActions {
  serviceOrderActions: {
    actions: Array<{ key: string; value: DataWithStatus<Action[]> }>
    updateAction?: Action
    updateStatus: ActionStatus
  }
}

export interface ActionsState {
  actions: Array<{ key: string; value: DataWithStatus<Action[]> }>
  updateAction?: Action
  updateStatus: ActionStatus
}

const initialState: ActionsState = {
  actions: [],
  updateStatus: 'none',
}

const getUpdatedActionsForFetch = (
  searchKey: string,
  prevActions: Array<{ key: string; value: DataWithStatus<Action[]> }>,
  status: ActionStatus,
  data: Action[]
): Array<{ key: string; value: DataWithStatus<Action[]> }> => {
  if (prevActions.length === 0) {
    return [{ key: searchKey, value: { status, data, error: 'undefined' } }]
  }

  if (!prevActions.find((val) => val.key === searchKey)) {
    return [
      ...prevActions,
      {
        key: searchKey,
        value: { status, data, error: 'undefined' },
      },
    ]
  }

  return prevActions.map(({ key, value }) => {
    if (key !== searchKey) {
      return { key, value }
    }
    return {
      key,
      value: {
        status,
        error: 'undefined',
        data,
      },
    }
  })
}

export const serviceOrderActionsSlice = createSlice({
  name: 'serviceOrderActions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchActionsAction.pending, (state, { meta }) => {
      const modifyKey = `${meta.arg?.orderId}:${meta.arg?.activityId}:${meta.arg?.taskId}`
      state.actions = getUpdatedActionsForFetch(modifyKey, state.actions, 'loading', [])
    })

    builder.addCase(fetchActionsAction.fulfilled, (state, { payload }) => {
      state.actions = getUpdatedActionsForFetch(
        payload.key,
        state.actions,
        'succeeded',
        payload.actions
      )
    })

    builder.addCase(fetchActionsAction.rejected, (state, { payload }) => {
      if (payload) {
        const oldObjectData =
          state.actions.find(({ key, value }) => key === payload.key)?.value.data || []
        state.actions = state.actions.map(({ key, value }) => {
          if (payload.key !== key) {
            return { key, value }
          }
          return {
            key,
            value: {
              status: 'failed',
              data: oldObjectData,
              ...(payload.message && { error: payload.message }),
            },
          }
        })
      }
    })

    builder.addCase(updateActionValueAction.pending, (state) => {
      state.updateStatus = 'loading'
    })

    builder.addCase(updateActionValueAction.fulfilled, (state, { payload, meta }) => {
      const modifyKey = `${meta.arg?.params.orderId}:${meta.arg?.params.activityId}:${meta.arg?.params.taskId}`
      state.updateAction = payload
      const prevActions = state.actions.find(({ key, value }) => key === modifyKey)?.value
      const newData =
        prevActions?.data.map((action: Action) => {
          if (action.inspection === payload.inspection)
            return { ...payload, measurementType: action.measurementType }
          else return action
        }) || []
      state.actions = state.actions.map(({ key, value }) => {
        if (key !== modifyKey) {
          return { key, value }
        }
        return {
          key,
          value: { status: 'succeeded', data: newData },
        }
      })
      state.updateStatus = 'succeeded'
    })

    builder.addCase(updateActionValueAction.rejected, (state, { payload }) => {
      state.updateStatus = 'failed'
    })
  },
})

export const selectUpdatedAction = (state: StateWithActions): Action | undefined =>
  state.serviceOrderActions.updateAction
export const selectUpdatedActionStatus = (state: StateWithActions): ActionStatus =>
  state.serviceOrderActions.updateStatus

export const selectActions = (state: StateWithActions, searchKey: string): Action[] =>
  state.serviceOrderActions.actions.find(({ key, value }) => key === searchKey)?.value?.data || []

export const selectActionsStatus = (state: StateWithActions, searchKey: string): ActionStatus =>
  state.serviceOrderActions.actions.find(({ key, value }) => key === searchKey)?.value?.status ||
  'none'

export default serviceOrderActionsSlice.reducer
