import { isEqual } from 'lodash'

export function replaceWithId<
  T extends {
    readonly id?: string
  },
>(map: Record<string, T>, value: T): Record<string, T> {
  const id = value.id
  if (id && map[id] && isEqual(map[id], value)) {
    return map
  }
  return id ? { ...map, [id]: value } : map
}

export function replaceManyWithId<
  T extends {
    readonly id?: string
  },
>(map: Record<string, T>, objects: Array<T>): Record<string, T> {
  return objects.reduce(replaceWithId, map)
}
