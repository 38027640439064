import { TypographyVariantsOptions } from '@mui/material'

import colors from './MuiColors'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    placeholder: React.CSSProperties
    defectDateSmall: React.CSSProperties
    adminBarBase: React.CSSProperties
    adminBarSmall: React.CSSProperties
    adminBarLabel: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    placeholder?: React.CSSProperties
    defectDateSmall?: React.CSSProperties
    adminBarBase?: React.CSSProperties
    adminBarSmall?: React.CSSProperties
    adminBarLabel?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    placeholder: true
    defectDateSmall: true
    adminBarBase: true
    adminBarSmall: true
    adminBarLabel: true
  }
}

const typography: TypographyVariantsOptions = {
  fontSize: 16,
  h1: {
    fontSize: '28px',
    fontWeight: 300,
    lineHeight: '46px',
  },
  h2: {
    fontSize: '24px',
    fontWeight: 300,
    lineHeight: '38px',
  },
  h3: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '30px',
  },
  h4: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '30px',
  },
  h5: {
    fontSize: '16px',
    lineHeight: '28px',
    fontWeight: 400,
  },
  h6: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
  },
  adminBarBase: {
    color: 'white',
    fontSize: '15px',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: '23px',
  },
  adminBarSmall: {
    color: 'white',
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: 500,
  },
  adminBarLabel: {
    color: 'white',
    fontSize: '15px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: '23px',
  },
  body1: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  body2: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  button: {
    textTransform: 'uppercase',
    fontSize: '13px',
    fontWeight: 700,
    lineHeight: '16px',
  },
  subtitle1: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  subtitle2: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '18px',
  },
  placeholder: {
    color: colors.grey[400],
    fontSize: '18px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    lineHeight: '24px',
  },
  defectDateSmall: {
    fontSize: '11px',
    fontFamily: 'Roboto',
    fontWeight: 400,
    lineHeight: '20px',
  },
}

export default typography
