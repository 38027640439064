import { Button, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { useRouter } from 'found'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useTypedDispatch, useTypedSelector } from '../../../..'
import { setPreSelectedSerialNumber } from '../../../../reducers/createDefectSlice'
import {
  selectUpdatedAction,
  selectUpdatedActionStatus,
} from '../../../../reducers/serviceOrders/actionsSlice'
import { ActionStatus } from '../../../../types/Input'
import { Action, ActionPayload, MeasurementType } from '../../types'
import { constructPayload, getBorderStyle, handleSubmit } from './InputUtils'

type Props = {
  wbNotStarted: boolean
  action: Action
  measurement: MeasurementType
  updateAction: (actionId: string, payload: ActionPayload) => void
}

const MeasurementRadioGroup = ({
  wbNotStarted,
  action,
  measurement,
  updateAction,
}: Props): JSX.Element => {
  const [value, setValue] = useState<number | null>(
    action.status === 'MEASURED' ? action.measuredValueAlphanumeric : null
  )

  const { t } = useTranslation()
  const { router } = useRouter()

  const updatedAction = useTypedSelector<Action | undefined>((state) => selectUpdatedAction(state))
  const updatedStatus = useTypedSelector<ActionStatus>((state) => selectUpdatedActionStatus(state))
  const showBorder =
    ['failed', 'succeeded'].includes(updatedStatus) &&
    updatedAction?.inspection === action.inspection

  const dispatch = useTypedDispatch()

  const update = (submittedValue: number) =>
    updateAction(
      action.id.toString(),
      constructPayload(action.inspection, submittedValue, undefined)
    )

  const defectiveId = measurement.values
    .filter((mt) => mt.description.toUpperCase() === 'VIALLINEN')
    .pop()?.id
  const showDefectButton = value !== null && defectiveId === value.toString()

  return (
    <>
      <RadioGroup
        sx={{ width: '100%' }}
        value={value}
        onClick={(event) => {
          const submittedValue = parseInt((event.target as HTMLInputElement).value)
          if (updatedStatus === 'failed' && submittedValue === value) {
            handleSubmit(setValue, submittedValue, update)
          }
        }}
        onChange={(event) => {
          const submittedValue = parseInt(event.target.value)
          if (submittedValue !== value) {
            handleSubmit(setValue, submittedValue, update)
          }
        }}
      >
        {measurement.values.map((mv) => (
          <FormControlLabel
            sx={{
              width: '100%',
              justifyContent: 'flex-start',
              margin: '0',
              ...getBorderStyle(
                updatedStatus,
                showBorder && updatedAction.measuredValueAlphanumeric.toString() === mv.id
              ),
            }}
            key={mv.id}
            value={mv.id}
            disabled={wbNotStarted || updatedStatus === 'loading'}
            control={<Radio sx={{ '&.Mui-checked': { color: 'common.kenttaBlue' } }} />}
            label={mv.description}
            labelPlacement="end"
          />
        ))}
      </RadioGroup>
      {showDefectButton && (
        <Button
          sx={(theme) => ({ marginTop: theme.spacing('small') })}
          variant="submitButtonFullWidth"
          onClick={() => {
            dispatch(setPreSelectedSerialNumber(action.serialNumber))
            router.push('/create-defect')
          }}
        >
          <Typography>{t('serviceOrder.actions.createDefect')}</Typography>
        </Button>
      )}
    </>
  )
}

export default MeasurementRadioGroup
