import 'react-datepicker/dist/react-datepicker.css'

import fi from 'date-fns/locale/fi'
import { Router } from 'found'
import { TFunction } from 'i18next'
import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'

import LoadingIndicator from '../../components/LoadingIndicator'
import moment from '../../lib/moment-fi'
import { roleOrder } from '../../lib/roleOrder'
import { Action, Personnel } from '../../types'
import { ActivateFunction } from '../../types/App'
import { PersonnelList, SearchDeleteParams } from '../../types/Input'
import { PunctualityPersonnelState } from '../../types/States'
import CardWithSpace from '../search/card/CardWithSpace'
import DateContainer from '../search/DateContainer'
import SearchBox from '../search/SearchBox'
import SearchButton from '../search/SearchButton'
import SearchContainer from '../search/SearchContainer'
import SearchHeader from '../search/SearchHeader'
import SearchHistoryHeader from '../search/SearchHistoryHeader'
import SearchInput from '../search/SearchInput'
import SearchPageHeader from '../search/SearchPageHeader'

const calculateContacts = (personnel: PersonnelList) => {
  let contactCount = 0
  roleOrder.forEach((role) => {
    if (Object.keys(personnel).includes(role.name)) {
      contactCount += personnel[role.name as keyof PersonnelList]?.length || 0
    }
  })
  return contactCount
}

type Props = {
  activateDate: () => void
  activateTrainNumber: ActivateFunction
  changeDate: (date: Date | null) => void
  changeTrainNumber: (value: string) => void
  fetchPersonnelInformation: () => void
  loading: boolean
  openCalendar: (arg0: boolean) => void
  personnelFromHistory: (personnel: Personnel, func: () => unknown) => Action
  personnelHistory: Array<Personnel>
  deletePersonnelFromHistory: (searchDeleteParams: SearchDeleteParams) => unknown
  router: Router
  setError: (arg0: string) => void
  state: PunctualityPersonnelState
  t: TFunction
}

const PersonnelSearch = ({
  fetchPersonnelInformation,
  activateTrainNumber,
  changeTrainNumber,
  activateDate,
  openCalendar,
  state,
  changeDate,
  router,
  personnelHistory,
  personnelFromHistory,
  deletePersonnelFromHistory,
  t,
  loading,
  setError,
}: Props) => {
  const { date, dateActive, trainNumber, trainNumberActive } = state

  const validate = () => {
    if (trainNumber !== '') return true
    return false
  }

  registerLocale('fi', fi)

  return (
    <div
      onKeyDown={
        validate()
          ? (ev) => {
              return ev.keyCode === 13 && fetchPersonnelInformation()
            }
          : undefined
      }
    >
      <SearchPageHeader name="personnel" text={t('searchTool.title.personnel')} />
      <SearchContainer>
        <SearchBox marginRight="8px" onClick={activateTrainNumber} active={trainNumberActive}>
          <SearchHeader>{t('trainNumber')}</SearchHeader>
          <SearchInput
            type="number"
            value={trainNumber}
            onChange={(event) => changeTrainNumber(event.target.value)}
          />
        </SearchBox>
        <SearchBox marginLeft="8px" onClick={activateDate} active={dateActive}>
          <SearchHeader>{t('startDay')}</SearchHeader>
          <SearchInput type="text" readOnly onClick={() => openCalendar(true)} value={date} />
        </SearchBox>
      </SearchContainer>
      {dateActive && (
        <DateContainer>
          <DatePicker
            readOnly
            locale="fi"
            inline
            selected={moment(date, 'DD.MM.YYYY').toDate()}
            onChange={(value) => changeDate(value)}
          />
        </DateContainer>
      )}
      {loading ? (
        <LoadingIndicator size="normal" padded={true} />
      ) : (
        <SearchButton
          t={t}
          validate={validate}
          setError={setError}
          errorKey="trainNumberMandatory"
          onClick={fetchPersonnelInformation}
        />
      )}
      {personnelHistory && personnelHistory.length > 0 && (
        <div>
          <SearchHistoryHeader>{t('lastSearch')}</SearchHistoryHeader>
          <div>
            {personnelHistory.map((train) => (
              <CardWithSpace
                key={`${train.trainNumber}+${train.depTime}`}
                t={t}
                textKey={'personnelHistoryResult'}
                args={{
                  train: train.trainNumber,
                  start: `${moment(train.depTime).format('DD.MM.YYYY')}`,
                  end: `${moment(train.arrTime).format('DD.MM.YYYY')}`,
                  from: train.depOcp,
                  to: train.arrOcp,
                }}
                onClick={() =>
                  personnelFromHistory(train, () =>
                    router.push(
                      `/personnelList/${train.trainNumber}/${moment(train.depTime).format(
                        'DD.MM.YYYY'
                      )}/cached`
                    )
                  )
                }
                additionalText={`${calculateContacts(train.personnel)} yhteystietoa`}
                deleteAction={() =>
                  deletePersonnelFromHistory({
                    trainNumber: train.trainNumber,
                    date: train.depTime,
                  })
                }
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default PersonnelSearch
