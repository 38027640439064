import styled from '@emotion/styled'
import React, { useContext } from 'react'
import { connect } from 'react-redux'

import { toggleNightMode } from '../../actions/system'
import { ColorModeContext } from '../../ColorModeContext'
import { asThunk } from '../../lib/thunk'
import { isMobileSelector, nightModeSelector } from '../../Selectors'
import { theme } from '../../Theme'
import { AppState } from '../../types'
import { isMobileProps } from '../../types/App'

interface HandleProps extends isMobileProps {
  nightMode: boolean
}

const Relative = styled.div<isMobileProps>`
  position: relative;
  top: 4px;
`

const Background = styled.img<isMobileProps>`
  width: auto;
  height: ${(p) => (p.isMobile ? theme.spacing.sizes.large : theme.spacing.sizes.larger)};
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`

const Handle = styled.img<HandleProps>`
  position: absolute;
  height: ${(p) => (p.isMobile ? theme.spacing.sizes.large : theme.spacing.sizes.larger)};
  width: auto;
  transition: left 0.2s linear;
  left: ${(p) =>
    p.nightMode ? (p.isMobile ? theme.spacing.sizes.large : theme.spacing.sizes.larger) : 0};
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`

type Props = {
  nightMode: boolean
  isMobile: boolean
  onToggle: () => void
}

function NightMode({ nightMode, isMobile, onToggle }: Props) {
  const dayOrNight = nightMode ? 'night' : 'day'
  const colorMode = useContext(ColorModeContext)

  const toggleNightModeStorage = () => {
    localStorage.setItem('nightMode', nightMode ? 'false' : 'true')
    colorMode.toggleColorMode()
    onToggle()
  }

  return (
    <Relative isMobile={isMobile}>
      <Background
        isMobile={isMobile}
        onClick={toggleNightModeStorage}
        src={`/illustrations/switch/${dayOrNight}-bg.png`}
      />
      <Handle
        isMobile={isMobile}
        nightMode={nightMode}
        onClick={toggleNightModeStorage}
        src={`/illustrations/switch/${dayOrNight}-handle.png`}
      />
    </Relative>
  )
}

const mapStateToProps = (state: AppState) => ({
  nightMode: nightModeSelector(state),
  isMobile: isMobileSelector(state),
})
const mapDispatchToProps = { onToggle: asThunk(toggleNightMode) }

export default connect(mapStateToProps, mapDispatchToProps)(NightMode)
