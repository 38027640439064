import React from 'react'

import { SvgProps } from '../types'

const UploadCloud = ({ stroke, size = 24 }: SvgProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 53 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.125 34.0723H40.9922C46.6855 34.0723 51.3437 31.0486 51.3437 25.4184C51.3437 19.7881 45.8574 16.9849 41.4062 16.7645C40.486 7.95941 34.0566 2.60352 26.5 2.60352C19.3574 2.60352 14.7572 7.3435 13.25 12.0441C7.03906 12.6342 1.65625 16.5864 1.65625 23.0582C1.65625 29.53 7.24609 34.0723 14.0781 34.0723H19.875"
      stroke={stroke ?? 'currentColor'}
      strokeWidth="3.3125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.125 22.4785L26.5 15.8535L19.875 22.4785M26.5 42.397V17.5098"
      stroke={stroke ?? 'currentColor'}
      strokeWidth="3.3125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default UploadCloud
