import { MARK_READ_FEEDBACK } from '../actions/response-read'
import { ResponseReadAction } from '../actions/response-read'
import { ResponseReadState } from '../types'

const initialState = {
  byId: {},
}

const responseReadReducer = (
  state: ResponseReadState = initialState,
  action: ResponseReadAction
): ResponseReadState => {
  let id: string

  switch (action.type) {
    case MARK_READ_FEEDBACK:
      id = action.payload.id

      return {
        byId: { ...state.byId, [id]: action.payload.read },
      }

    default:
      return state
  }
}

export default responseReadReducer
