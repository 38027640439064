import styled from '@emotion/styled'
import React, { ButtonHTMLAttributes } from 'react'

import Success from '../../icons/Success'
import { color, MaybeColor, theme } from '../../Theme'
import LoadingIndicator from '../LoadingIndicator'

const br = theme.borderRadius.button

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  topOpen?: boolean
  bottomOpen?: boolean
  color?: MaybeColor
}

const Button = styled.button<ButtonProps>`
  ${theme.layout.flexRow};
  ${theme.text('small', 'decorative', 'bold')};
  ${theme.spacing.ends('small')};
  justify-content: center;
  background: ${(p) => color(p.color, 'primaryBlue')};
  border-radius: ${(p) =>
    `${p.topOpen ? 0 : br} ${p.topOpen ? 0 : br} ${p.bottomOpen ? 0 : br} ${
      p.bottomOpen ? 0 : br
    }`};
  border: none;
  color: ${color('white')};
  cursor: pointer;
  height: 100%;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`

export const OutlineButton = styled(Button)`
  background: transparent;
  color: ${(p) => color(p.color, 'primaryBlue')};
`

const Arrow = styled.div`
  ${theme.text('largest', 'content')};
  line-height: 0;
  color: ${color('white')};
  display: inline-block;
`

type Props = {
  sending: boolean
  sent: boolean
  title: string
  onClick: () => void
}

export const SendButton = ({ sending, sent, title, onClick }: Props) => (
  <Button topOpen={false} bottomOpen={true} color={sent ? 'primaryGreen' : ''} onClick={onClick}>
    {sent ? <Success iconSize="small" /> : undefined}
    {sending ? <LoadingIndicator size="small" padded={false} /> : undefined}
    {!sent && !sending ? <Arrow>→</Arrow> : undefined}
    &nbsp;
    {title}
  </Button>
)

export default Button
