import { Box, Button, Typography } from '@mui/material'
import { useParams } from 'found'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useTypedDispatch, useTypedSelector } from '../..'
import {
  completeActivityAction,
  fetchServiceOrderAction,
  handoverWorkshopBookingAction,
} from '../../actions/serviceOrders'
import { SmallErrorText } from '../../components/ErrorText'
import LoadingIndicator from '../../components/LoadingIndicator'
import BasicFooter from '../../components/page/BasicFooter'
import BasicHeader from '../../components/page/BasicHeader'
import Page from '../../components/page/Page'
import { Container, Content } from '../../components/page/PageComponents'
import ActivityRow from '../../components/serviceOrders/ActivityRow'
import ServiceOrderHeader from '../../components/serviceOrders/ServiceOrderHeader'
import { Task } from '../../components/serviceOrders/types'
import {
  selectSelectedServiceOrder,
  selectSelectedServiceOrderStatus,
  selectServiceOrderCompleteError,
  selectServiceOrderCompleteStatus,
  updateSelectedServiceOrderTask,
} from '../../reducers/serviceOrders/serviceOrdersSlice'

const CompleteServiceOrder = (): JSX.Element => {
  const { t } = useTranslation()
  const { orderId } = useParams()
  const dispatch = useTypedDispatch()

  const serviceOrder = useTypedSelector((state) => selectSelectedServiceOrder(state))
  const serviceOrderStatus = useTypedSelector((state) => selectSelectedServiceOrderStatus(state))
  const completeSOStatus = useTypedSelector((state) => selectServiceOrderCompleteStatus(state))
  const completeSOError = useTypedSelector((state) => selectServiceOrderCompleteError(state))

  const fetchServiceOrder = useCallback(
    (orderId: string) => {
      dispatch(fetchServiceOrderAction(orderId))
    },
    [dispatch]
  )
  const completeServiceOrder = (orderId: string, workshopBookingId: string) =>
    dispatch(handoverWorkshopBookingAction({ orderId, workshopBookingId }))
  const setSelectedTask = (task: Task) => dispatch(updateSelectedServiceOrderTask(task))
  const completeActivity = (orderId: string, activityId: string) =>
    dispatch(completeActivityAction({ orderId, activityId }))

  useEffect(() => {
    if (!serviceOrder && serviceOrderStatus !== 'loading') {
      fetchServiceOrder(orderId)
    }
  }, [dispatch, fetchServiceOrder, orderId, serviceOrder, serviceOrderStatus])

  return (
    <Page overflowVisible={true}>
      <Container>
        <BasicHeader title={t('serviceOrder.title', { serviceOrderId: serviceOrder?.id })} />
        {serviceOrder ? (
          <Content>
            <Box sx={{ color: 'text.primary' }}>
              <ServiceOrderHeader serviceOrder={serviceOrder} showStatus />
              <Typography variant="h1" sx={{ color: 'common.primaryGreen' }}>
                {t('serviceOrder.activities.title')}
              </Typography>
              {serviceOrder.activities.map((activity) => (
                <ActivityRow
                  key={activity.id}
                  activity={activity}
                  orderId={serviceOrder.id}
                  setSelectedTask={setSelectedTask}
                  completeActivity={completeActivity}
                />
              ))}
              <Button
                variant="submitButtonFullWidth"
                disabled={
                  !!serviceOrder.activities.find((activity) => activity.status !== 'COMPLETED') ||
                  serviceOrder.status !== 'RELEASED' ||
                  ['loading', 'succeeded'].includes(completeSOStatus)
                }
                endIcon={completeSOStatus === 'loading' && <LoadingIndicator size="small" />}
                onClick={() => {
                  // it would be nicer to notify the user that we cannot complete service order if we are unable to identify the workshopBookingId
                  const workshopBookingId = serviceOrder.activities.at(0)?.workshopBooking || ''
                  completeServiceOrder(serviceOrder.id, workshopBookingId)
                }}
              >
                <Typography variant="subtitle1">{t('serviceOrder.complete')}</Typography>
              </Button>
              {completeSOStatus === 'failed' && (
                <SmallErrorText>{t(completeSOError)}</SmallErrorText>
              )}
            </Box>
          </Content>
        ) : undefined}
        <BasicFooter />
      </Container>
    </Page>
  )
}

export default CompleteServiceOrder
