import styled from '@emotion/styled'
import { HTMLAttributes } from 'react'

import { defaultTextColor, getColor, theme } from '../../Theme'
import Button, { OutlineButton } from '../button/Button'

interface CauseDisplayProps extends HTMLAttributes<HTMLDivElement> {
  notSelectable?: boolean
}

// Display states
export const STATE_INIT = 'INIT'
export const STATE_SELECT_GROUP = 'SELECT_GROUP'
export const STATE_SELECT_LEVEL1 = 'SELECT_LEVEL1'
export const STATE_SELECT_LEVEL2 = 'SELECT_LEVEL2'
export const STATE_COMPLETE = 'COMPLETE'

// cause type
export const TYPE_ARRIVAL = 'ARRIVAL'
export const TYPE_DEPARTURE = 'DEPARTURE'

// amendment type
export const AMEND_TYPE_AMEND = 'AMEND'
export const AMEND_TYPE_CHALLENGE = 'CHALLENGE'

export const CauseButton = styled(Button)`
  ${theme.effects.shadow}
  background: ${(p) => getColor(p.theme, ['white'], ['black'])};
  color: ${defaultTextColor};
  margin-bottom: ${theme.spacing.sizes.tiny};
  justify-content: space-between;
  text-transform: none;
  text-align: left;
  ${theme.text('normal', 'content', 'normal')}
  &:first-of-type {
    margin-top: ${theme.spacing.sizes.tiny};
  }
`
CauseButton.displayName = 'CauseButton'

export const CauseDisplay = styled.div<CauseDisplayProps>`
  ${theme.text('large', 'content', 'bold')};
  ${theme.layout.flexRow};
  ${(p) => p.notSelectable && 'margin-left: 35px;'}
  color: ${(p) =>
    p.notSelectable ? getColor(p.theme, ['grayDark'], ['grayDark']) : defaultTextColor};
`
export const CauseDescription = styled.p`
  ${theme.text('small', 'content', 'normal')};
  color: ${defaultTextColor};
`

export const BackNavigationButton = styled(OutlineButton)`
  color: ${(p) => getColor(p.theme, ['primaryGreen'], ['primaryGreen'])};
  width: 35px;
`

export const getTypedTranslationKey = (lastPart: string, depOrArr: string): string => {
  return depOrArr === TYPE_ARRIVAL
    ? `deviationAmendment.arrival.${lastPart}`
    : `deviationAmendment.departure.${lastPart}`
}

export const CauseSelectionHeader = styled.div`
  ${theme.layout.flexColumn};
  margin-bottom: ${theme.spacing.sizes.normal};
  align-items: flex-start;
`

export const CauseSelectionTitle = styled.p`
  ${theme.text('small', 'content', 'bold')}
  color: ${defaultTextColor};
`
