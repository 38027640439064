import styled from '@emotion/styled'

import { getColor, theme } from '../../../Theme'

const TowingFormRow = styled.div`
  border-radius: 3px;
  padding-bottom: ${theme.spacing.sizes.small};
  background-color: ${(p) => getColor(p.theme, ['white'], ['black'])};
  margin-right: ${theme.spacing.sizes.small};
  margin-left: ${theme.spacing.sizes.small};
  margin-top: ${theme.spacing.sizes.smaller};
  & + & {
    margin-top: ${theme.spacing.sizes.smaller};
  }
`

export default TowingFormRow
