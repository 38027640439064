import { CALENDAR_ERROR, START_FETCHING_CALENDAR, UPDATE_CALENDAR } from '../actions/calendar'
import { Action } from '../types'
import { CalendarState } from '../types'

export const initialState = {
  loading: false,
  error: '',
  url: '',
}

const calendarReducer = (state: CalendarState = initialState, action: Action): CalendarState => {
  switch (action.type) {
    case START_FETCHING_CALENDAR:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_CALENDAR:
      return {
        ...state,
        error: '',
        loading: false,
        url: action.payload,
      }

    case CALENDAR_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default calendarReducer
