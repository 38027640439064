import { Button, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

import { useTypedSelector } from '../..'
import { selectCompleteActivityStatus } from '../../reducers/serviceOrders/serviceOrdersSlice'
import { Mode } from '../../theme/MuiTheme'
import { SmallErrorText } from '../ErrorText'
import LoadingIndicator from '../LoadingIndicator'
import { getFormattedDateTime } from './DurationRow'
import TaskRow from './TaskRow'
import { Activity, Task } from './types'

const ContentRow = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing('normal'),
  boxShadow: theme.palette.mode === Mode.LIGHT ? '0 2px 6px 1px rgba(0, 0, 0, 0.1)' : '',
  border: theme.palette.mode === Mode.LIGHT ? '' : `1px solid ${theme.palette.background.border}`,
  background: theme.palette.background.secondary,
  padding: theme.spacing('smaller'),
}))

type Props = {
  activity: Activity
  orderId: string
  setSelectedTask: (task: Task) => void
  completeActivity: (orderId: string, activityId: string) => Promise<any>
}

const ActivityRow = ({
  activity,
  orderId,
  setSelectedTask,
  completeActivity,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  const completeActivityStatus = useTypedSelector((state) => selectCompleteActivityStatus(state))

  return (
    <ContentRow>
      <Typography variant="h4">{activity.description}</Typography>
      <Typography>
        {activity.installationItem} ({activity.installationSerialNumber})
      </Typography>
      <Typography variant="subtitle2" sx={{ color: 'common.primaryGreen' }}>
        {t('serviceOrder.activities.plannedStartTime')}
      </Typography>
      <Typography>{getFormattedDateTime(activity.plannedStartTime)}</Typography>
      <Typography variant="subtitle2" sx={{ color: 'common.primaryGreen' }}>
        {t('serviceOrder.activities.status')}
      </Typography>
      <Typography sx={{ textTransform: 'uppercase' }}>
        {t(`serviceOrder.status.${activity.status}`)}
      </Typography>
      <Typography variant="h4" sx={{ color: 'common.primaryGreen' }}>
        {t('serviceOrder.tasks.title')}
      </Typography>
      {activity.tasks.map((task) => (
        <TaskRow
          key={task.id}
          task={task}
          orderId={orderId}
          activityId={activity.id.toString()}
          activityStatus={activity.status}
          setSelectedTask={setSelectedTask}
        />
      ))}
      <Button
        variant="submitButtonFullWidth"
        disabled={
          ['COMPLETED', 'PLANNED'].includes(activity.status) ||
          completeActivityStatus === 'succeeded'
        }
        endIcon={completeActivityStatus === 'loading' && <LoadingIndicator size="small" />}
        onClick={() => completeActivity(orderId, activity.id.toString())}
      >
        <Typography variant="subtitle1">{t('serviceOrder.activities.complete')}</Typography>
      </Button>
      {completeActivityStatus === 'failed' && (
        <SmallErrorText>{t('serviceOrder.activities.error.complete')}</SmallErrorText>
      )}
    </ContentRow>
  )
}

export default ActivityRow
