import { Table } from 'dexie'

import config from '../config'
import { OBSERVATION_MESSAGE_TTL_HRS, S_IN_HOUR, TEST_PUSH_MESSAGE_TTL_HRS } from '../constants'
import { State as ShiftSearchState } from '../pages/SearchPage'
import {
  removeDupeContacts,
  selectNumericTrainNumber,
  shiftHasInvalidEnding,
  shiftLastTask,
} from '../Selectors'
import {
  Compositions,
  DBContacts,
  EnergyEfficiency,
  Feedback,
  Finding,
  LazyPromise,
  Locomotive,
  News,
  ObservationMessage,
  Personnel,
  ResponseRead,
  RollingGuideData,
  Schedule,
  SearchedShift,
  Session,
  Shift,
  TaskDone,
  TaskParams,
  TestPushMessage,
  Timestamp,
  TowingAuditMessage,
  TowingFormContent,
  TowingStep,
  TowingVehicle,
  TowingVehiclePattern,
  Update,
} from '../types'
import {
  Assembly,
  Causes,
  HandlingStations,
  InputFeedback,
  InputSchedule,
  InputShift,
  InputShiftHead,
  JWTToken,
  PhoneContactGroup,
  ShiftNotice,
  SignedIn,
  SignInStatus,
  TimetableParams,
  TowingFormContentTimestamped,
  TowingFormState,
  TrainPunctuality,
} from '../types/Input'
import { AmendmentData } from '../types/States'
import { apiGET, deleteWithRetry, getWithRetry, postWithRetry } from './api'
import db from './db'
import { ErrorProps, getErrorString } from './errors'
import { decode } from './jwt'
import { error } from './logger'
import moment from './moment-fi'
import { unixTimestamp } from './time'

interface ResponseData {
  body: string
}

interface ResponseDataWithStatusCode extends ResponseData {
  statusCode: number
}

interface FeedbacksResponse {
  feedbacks: Array<InputFeedback>
}

interface Train {
  trainDate: string
  trainNumber: string
}

function throwEmpty<T>(dbPromise: Promise<T | undefined>): Promise<T> {
  return dbPromise.then((v) => {
    if (v === undefined || v === null) {
      return Promise.reject()
    }
    return v
  })
}

// Executes providers one by one, returns result of first
// successful provider. Essentially utility to make list of fallback
// value providing functions
export function firstSuccess<T>(providers: Array<LazyPromise<T>>): Promise<T> {
  const [first, ...rest] = providers
  if (providers.length > 1) {
    return Promise.resolve(first()).catch((err) => {
      // Use first informative error
      if (err instanceof Error) {
        return firstSuccess(rest).catch(() => {
          throw err
        })
      }
      return firstSuccess(rest)
    })
  }
  return first ? first() : Promise.reject()
}

// Executes providers in order
export function eachSuccess<T>(
  providers: Array<LazyPromise<T>>,
  onSuccess: (t: T) => Promise<unknown>
): Promise<unknown> {
  const [first, ...rest] = providers
  if (providers.length > 1) {
    const promise = Promise.resolve(first())
      .then(onSuccess)
      .catch((err) => {
        if (err) {
          error(err)
        }
        return Promise.resolve()
      })

    return promise.then(() => eachSuccess(rest, onSuccess))
  }
  return first ? first().then(onSuccess) : Promise.reject()
}

// Saves value to database collection
function saveValue<T, I>(table: Table<T, I>): (t: T) => Promise<T> {
  return (value: T) => {
    return table
      .put(value)
      .then(() => value)
      .catch((err) => {
        error(err)
        return value
      })
  }
}

// Removes value from database collection, use primary key for selection
function removeValue<T, I>(table: Table<T, I>): (arg0: I) => Promise<void> {
  return (id: I) => {
    return table.delete(id).catch((err) => {
      error(err)
      throw err
    })
  }
}

// Deletes old sessions
export const closeSession = (): Promise<unknown> => {
  return db.sessions.clear()
}

export const clearTaskDones = (): Promise<unknown> => {
  return db.tasksdone.clear()
}

export const clearSignInStatuses = (): Promise<unknown> => {
  return db.signins.clear()
}

export const clearResponseReads = (): Promise<unknown> => {
  return db.reads.clear()
}

export const clearFeedbacks = (): Promise<unknown> => {
  return db.feedbacks.clear().then(() => clearResponseReads())
}

export const clearUserData = (): Promise<unknown> => {
  return clearTaskDones()
    .then(() => clearFeedbacks())
    .then(() => clearSignInStatuses())
}

export const clearOldShifts = (endedBefore: Timestamp): Promise<number> => {
  return db.shifts.where('created_at').below(moment(endedBefore).unix()).delete()
}

/* Shift */

const getShiftFromDB = (shiftId: string, ttlSeconds: number): LazyPromise<Shift> => {
  const createdAfter = unixTimestamp() - ttlSeconds
  const id = shiftId.toString()
  return () =>
    throwEmpty(
      db.shifts
        .where('created_at')
        .above(createdAfter)
        .filter((s) => s.id.toString() === id)
        .reverse()
        .first()
    )
}

type ShiftHeadOptions = {
  startDateTime: Timestamp
  endDateTime: Timestamp
  preparation: string
  wrapUp: string
}

const getShiftFromAPI = (
  shiftId: string,
  { startDateTime, endDateTime, preparation, wrapUp }: ShiftHeadOptions,
  searched?: boolean | null
): LazyPromise<Shift> => {
  return (): Promise<Shift> => {
    return getWithRetry<InputShift>(
      `/shifts/${shiftId}/${config.version}${searched ? '?search=true' : ''}`
    )
      .then((shift): Shift => {
        return {
          id: shiftId,
          shiftId: shift.shiftId,
          date: shift.startDateTime,
          created_at: unixTimestamp(),
          startDateTime: shift.startDateTime,
          endDateTime: shift.endDateTime,
          scheduleStartTime: startDateTime,
          scheduleEndTime: endDateTime,
          notifications: shift.notifications,
          dutyEvaluationPercentage: shift.dutyEvaluationPercentage,
          listStartTimestamp: shift.listStartTimestamp,
          preparation: shift.preparation,
          wrapUp: shift.wrapUp,
          schedulePreparation: preparation,
          scheduleWrapUp: wrapUp,
          error: '',
          duration: shift.duration,
          partDuration:
            startDateTime && endDateTime && !shift.isCommuter
              ? Math.abs(moment(startDateTime).diff(moment(endDateTime)))
              : shift.duration,
          loading: false,
          isCommuter: shift.isCommuter,
          crewNotices: shift.crewNotices,
          contacts: shift.contacts,
          assemblies: shift.assemblies,

          // InputShift may contain more tasks than shift actually contains, for example in case of long rest
          // Filter tasks based on shift head starting information
          tasks: shift.tasks
            .filter((task) => {
              // Don't filter tasks when shift times are missing or invalid
              if (shift.startDateTime >= shift.endDateTime) {
                return true
              }
              if (
                task.taskStartDateTime >= shift.startDateTime &&
                task.taskEndDateTime <= shift.endDateTime
              ) {
                return true
              }

              return false
            })
            .map((task, i) => ({
              ...task,
              id: i,
              trainNumberNumeric: task.trainNumber && selectNumericTrainNumber(task.trainNumber),
              contacts: removeDupeContacts(task.contacts),
            })),
        }
      })
      .then((shift) => {
        // Use last task ending time when shift has invalid ending time
        if (shiftHasInvalidEnding(shift)) {
          const task = shiftLastTask(shift)
          return {
            ...shift,
            endDateTime: task
              ? moment(task.taskEndDateTime)
                  .add(moment.duration(task.wrapUpMinutes))
                  .toISOString(true)
              : shift.endDateTime,
          }
        }
        return shift
      })
      .then((shift) => {
        const workingTime =
          parseInt(shift.duration) -
          (shift && shift.tasks
            ? shift.tasks.reduce(
                (acc, curr) =>
                  curr.restTask === 'true'
                    ? acc +
                      Math.abs(moment(curr.taskStartDateTime).diff(moment(curr.taskEndDateTime)))
                    : acc,
                0
              )
            : 0)
        return {
          ...shift,
          workingTime: workingTime,
        }
      })
      .then((shift) => {
        if (searched) {
          updateOrSaveSearchedShiftToDB(shift)
          return shift
        } else {
          return saveValue(db.shifts)(shift)
        }
      })
  }
}

// use this only in shift search
export const getShiftById = (shiftId: string, options: ShiftHeadOptions): Promise<Shift> =>
  firstSuccess([getShiftFromAPI(shiftId, options, true)])

// Shifts are stored in redux while the tab is not closed
// But are cached for 1 day in offline mode
export const getShift = (shiftId: string, options: ShiftHeadOptions): Promise<Shift> =>
  firstSuccess([getShiftFromAPI(shiftId, options), getShiftFromDB(shiftId, 24 * S_IN_HOUR)])

export const getShiftSignInStatuses = (): Promise<Array<SignInStatus>> => {
  return db.signins.toArray()
}

export const refreshShift = (shift: Shift): Promise<Shift> =>
  firstSuccess([
    getShiftFromAPI(shift.id, {
      startDateTime: shift.startDateTime,
      endDateTime: shift.endDateTime,
      preparation: shift.preparation,
      wrapUp: shift.wrapUp,
    }),
  ])

export const shiftSignIn = (
  id: string,
  startTime: Timestamp,
  now: Timestamp
): Promise<SignInStatus> => {
  return postWithRetry(`/sign-in`, { id, startTime, now })
    .then(() => ({
      id,
      state: 'signed-in' as SignedIn,
      error: '',
      loading: false,
    }))
    .then(saveValue(db.signins))
    .catch((err) => ({
      id,
      state: 'open',
      error: getErrorString(err, 'shiftSignIn'),
      loading: false,
    }))
}

export const getShiftDoneTasks = (): Promise<Array<TaskDone>> => {
  return db.tasksdone.toArray()
}

export const saveShiftDoneTasks = (
  shiftId: string,
  taskIndexes: Array<number>
): Promise<Array<TaskDone>> => {
  return Promise.all(
    taskIndexes.map((index) => {
      const done: TaskDone = { id: `${shiftId}:${index}`, shiftId, index }
      return saveValue(db.tasksdone)(done)
    })
  )
}

export const removeShiftDoneTasks = (
  shiftId: string,
  taskIndexes: Array<number>
): Promise<unknown> => {
  const ids = taskIndexes.map((index) => `${shiftId}:${index}`)
  const remove = removeValue(db.tasksdone)
  return Promise.all(ids.map(remove))
}

/* Schedule */
const calculateScheduleId = (ids: Array<string>): string => {
  const first = ids[0] || 0
  const last = ids[ids.length - 1] || 0
  return `${first}-${last}`
}

const onlySignedIn = (inputShiftHead: InputShiftHead): boolean =>
  inputShiftHead.signInStatus === 'signed-in'
const onlySignedOut = (inputShiftHead: InputShiftHead): boolean => !onlySignedIn(inputShiftHead)

const saveSignIn = (inputShiftHead: InputShiftHead): Promise<SignInStatus> => {
  const signIn: SignInStatus = {
    id: inputShiftHead.id,
    state: 'signed-in',
    error: '',
    loading: false,
  }
  return saveValue(db.signins)(signIn)
}
const removeSignIn = (inputShiftHead: InputShiftHead): Promise<unknown> => {
  return removeValue(db.signins)(inputShiftHead.id)
}

const syncSignIns = (schedule: Schedule): Promise<Schedule> => {
  const signedIn = schedule.shifts.filter(onlySignedIn)
  const signedOut = schedule.shifts.filter(onlySignedOut)

  const savePromises = signedIn.map(saveSignIn)
  const removePromises = signedOut.map(removeSignIn)

  return Promise.all([...savePromises, ...removePromises]).then(() => schedule)
}

const removeOldShifts = (schedule: Schedule): Promise<Schedule> => {
  return clearOldShifts(schedule.from).then(() => schedule)
}

const getScheduleFromAPI = (userNumber: string): LazyPromise<Schedule> => {
  return (): Promise<Schedule> => {
    return getWithRetry<InputSchedule>(`/me/schedule/${config.version}`)
      .then((schedule) => ({
        ...schedule,
        userNumber,
        id: calculateScheduleId(schedule.shifts.map((s) => s.id)),
        created_at: unixTimestamp(),
        loading: false,
        error: '',
      }))
      .then(syncSignIns)
      .then(saveValue(db.schedules))
      .then(removeOldShifts)
  }
}

const getScheduleFromDB = (userNumber: string, ttlSeconds: number): LazyPromise<Schedule> => {
  const createdAfter = unixTimestamp() - ttlSeconds

  return (): Promise<Schedule> =>
    throwEmpty(
      db.schedules
        .where('created_at')
        .above(createdAfter)
        .filter((s) => s.userNumber.toString() === userNumber.toString())
        .reverse()
        .first()
    )
}

// Schedule is always loaded from API
// And then again 3 days of offline data storage
export const getSchedule = (userNumber: string): Promise<Schedule> => {
  return firstSuccess([
    getScheduleFromAPI(userNumber),
    () => Promise.reject({ message: 'Dummy' }), // FIXME: HACK: For some reason removing this line breaks this
    getScheduleFromDB(userNumber, 3 * 24 * S_IN_HOUR),
  ]).catch(
    (err: ErrorProps): Schedule => ({
      userNumber,
      personnelGroup: '',
      serviceDriver: false,
      id: '0-0',
      shifts: [],
      from: '',
      to: '',
      created_at: unixTimestamp(),
      loading: false,
      error: getErrorString(err, 'schedule'),
    })
  )
}

export const refreshSchedule = (userNumber: string): Promise<Schedule> => {
  return getScheduleFromAPI(userNumber)().catch(
    (err: unknown): Schedule => ({
      userNumber,
      personnelGroup: '',
      serviceDriver: false,
      id: '0-0',
      shifts: [],
      from: '',
      to: '',
      created_at: unixTimestamp(),
      loading: false,
      error: getErrorString(err as ErrorProps, 'schedule'),
    })
  )
}

/* Feedback */

const saveFeedback = (feedback: Feedback) => {
  if (feedback.id) {
    return saveValue(db.feedbacks)(feedback)
  }
  return Promise.resolve(feedback)
}

const postFeedback = (feedback: Feedback) => {
  return postWithRetry<InputFeedback>(`/shifts/${feedback.shiftId}/feedback`, {
    feedback: { ...feedback, id: null },
  })
    .then((inputFeedback): Feedback => {
      return {
        ...inputFeedback,
        shiftId: inputFeedback.shiftId.toString(),
        loading: false,
        error: '',
      }
    })
    .then(saveFeedback)
}

export const sendFeedback = (feedback: Feedback): Promise<Feedback> => {
  return saveFeedback({ ...feedback, loading: false })
    .then(postFeedback)
    .catch(
      (err: unknown): Feedback => ({
        ...feedback,
        loading: false,
        error: getErrorString(err as ErrorProps, 'feedback'),
      })
    )
}

export const getFeedbacksFromAPI = (): LazyPromise<Array<Feedback>> => {
  return () =>
    getWithRetry('/me/feedback')
      .then((res) =>
        (res as FeedbacksResponse).feedbacks.map((inputFeedback: InputFeedback) => ({
          ...inputFeedback,
          loading: false,
          error: '',
        }))
      )
      .then((feedbacks: Array<Feedback>) => {
        return Promise.all(feedbacks.map(saveFeedback)).catch(() => feedbacks)
      })
      .catch(() => [])
}

export const getFeedbacksFromDB = (): LazyPromise<Array<Feedback>> => {
  return () => db.feedbacks.toArray()
}

export const getFeedbacks = (onSuccess: (array: Array<Feedback>) => Promise<unknown>): void => {
  eachSuccess([getFeedbacksFromDB(), getFeedbacksFromAPI()], onSuccess)
}

// Feedback response reads

export const getResponseReadsFromDB = (): Promise<Array<ResponseRead>> => {
  return db.reads.toArray()
}

export const getResponseReads: () => Promise<Array<ResponseRead>> = getResponseReadsFromDB

export const readFeedback = (id: string): Promise<ResponseRead> => {
  return postWithRetry(`/me/feedback/${id}`).then(() => {
    const readStatus: ResponseRead = {
      id,
      state: 'read',
      error: '',
      loading: false,
    }
    return saveValue(db.reads)(readStatus)
  })
}

/* Session */
type SessionProvider = LazyPromise<Session>

export const getSessionFromDB = (): SessionProvider => {
  return () =>
    throwEmpty(
      db.sessions
        .where('exp')
        .above(unixTimestamp())
        .filter((s) => s.token !== '')
        .reverse()
        .first()
    )
}

const getSessionFromAPI = (exchangeToken: string): SessionProvider => {
  if (!exchangeToken) {
    return () =>
      Promise.resolve({
        token: '',
        name: '',
        familyName: '',
        number: '',
        personnelGroup: '',
        originalNumber: '',
        serviceDriver: false,
        admin: false,
        read_admin: false,
        commuter_driver: false,
        commuter_manager: false,
        driver: false,
        logistics_driver: false,
        commuter_conductor: false,
        conductor: false,
        maintenance: false,
        other: false,
        created_at: unixTimestamp(),
        loading: false,
        error: '',
      })
  }
  return () =>
    postWithRetry<ResponseData>('/token', { exchangeToken })
      .then((r) => getSessionFromJWT(JSON.parse(r.body).token))
      .then(saveValue(db.sessions))
      .catch(
        (err: unknown): Session => ({
          number: '',
          originalNumber: '',
          name: '',
          familyName: '',
          personnelGroup: '',
          serviceDriver: false,
          admin: false,
          read_admin: false,
          commuter_driver: false,
          commuter_manager: false,
          driver: false,
          logistics_driver: false,
          commuter_conductor: false,
          conductor: false,
          maintenance: false,
          other: false,
          token: '',
          created_at: unixTimestamp(),
          loading: false,
          error: getErrorString(err as ErrorProps, 'session'),
        })
      )
}

const getSessionFromJWT = (jwtString: string): Session => {
  const now = unixTimestamp()
  const decoded: JWTToken = decode(jwtString)
  if (decoded && decoded.exp < now) {
    return {
      ...decoded,
      number: '',
      personnelGroup: '',
      serviceDriver: false,
      name: '',
      token: jwtString,
      created_at: now,
      loading: false,
      error: 'errors.session.expired',
    }
  }
  return {
    ...decoded,
    token: jwtString,
    personnelGroup: '',
    serviceDriver: false,
    created_at: now,
    loading: false,
    error: '',
  }
}

export const getSessionWithExchangeToken = (exchangeToken: string): Promise<Session> => {
  if (exchangeToken) {
    return firstSuccess([getSessionFromAPI(exchangeToken), getSessionFromDB()])
  } else {
    return firstSuccess([getSessionFromDB(), getSessionFromAPI(exchangeToken)])
  }
}

export const getSessionAsUser = (
  userNumber: string,
  actAsCommuter: boolean,
  actAsMaintenance: boolean,
  actAsServiceDriver: boolean,
  session: Session
): Promise<Session> => {
  return (
    postWithRetry<ResponseData>('/me/act-as', {
      number: userNumber,
      actAsCommuter,
      actAsMaintenance,
      actAsServiceDriver,
    })
      .then((r) => getSessionFromJWT(JSON.parse(r.body).token))
      // Clear data related to other user
      .then((session) => {
        return clearUserData().then(() => saveValue(db.sessions)(session))
      })
      .catch((err) => {
        return {
          ...session,
          loading: false,
          error: getErrorString(err, 'act-as'),
        }
      })
  )
}

export const recheckSession = (session: Session): Promise<Session> => {
  return Promise.resolve(getSessionFromJWT(session.token))
}

export const recordNewUpdate = (): Promise<Update> => {
  const update: Update = {
    receivedAt: moment().toISOString(true),
    confirmed: 'no',
  }
  return saveValue(db.updates)(update)
}

export const hasUpdates = (): Promise<boolean> => {
  return db.updates
    .where('confirmed')
    .equals('no')
    .count()
    .then((number) => number > 0)
}

export const confirmUpdates = (): Promise<unknown> => {
  return db.updates.where('confirmed').equals('no').modify({ confirmed: 'yes' })
}

/* Personnel */

export const getPersonnelFromDB = (date: string, trainNumber: string): Promise<Array<Personnel>> =>
  db.personnel.where('[trainNumber+depTime]').equals([trainNumber, date]).toArray()

export const deletePersonnelFromDB = (trainNumber: string, date: string): Promise<unknown> =>
  db.personnel.where('[trainNumber+depTime]').equals([trainNumber, date]).delete()

export const getPersonnelHistoryFromDB = (): Promise<Array<Personnel>> => {
  // delete 36 hour old or older searches
  const time = moment().subtract(36, 'hours').format()
  db.personnel.where('depTime').belowOrEqual(time).delete()

  return db.personnel.toArray()
}

export const getPersonnelFromAPI = (date: string, trainNumber: string): Promise<Personnel> => {
  return getWithRetry<Personnel>(`/personnel/${trainNumber}/${date}`)
    .then(saveValue(db.personnel))
    .then((savedPersonnel) => {
      cleanCacheState('personnel', ['trainNumber', 'depTime'], 'trainNumber+depTime')
      return savedPersonnel
    })
    .catch((error) =>
      getPersonnelFromDB(moment(date, 'YYYY-MM-DD').toISOString(true), trainNumber).then(
        (personnelFromDB) => {
          if (personnelFromDB.length > 0) {
            return personnelFromDB[0]
          }
          throw Error(error)
        }
      )
    )
}

/* Shift Search */

export const getSearchedShiftFromDB = (shiftId: string, date: string): Promise<Array<Shift>> =>
  db.searchedShifts.where('[id+date]').equals([shiftId, date]).toArray()

export const deleteSearchedShiftFromDB = (shiftId: string, date: string): Promise<unknown> =>
  db.searchedShifts.where('[id+date]').equals([shiftId, date]).delete()

export const getShiftInformationFromAPI = (params: ShiftSearchState): Promise<SearchedShift> => {
  let url = '/shifts/search'
  const date = moment(params.date, 'DD.MM.YYYY').format('YYYY-MM-DD')
  if (params.trainNumber && params.trainNumber !== '') {
    url = `${url}?trainNumber=${params.trainNumber}&date=${date}&ocp=${params.startStation}`
  } else {
    url = `${url}?shiftId=${params.shiftId}&date=${date}`
  }
  return getWithRetry<SearchedShift>(url).catch((error) => {
    throw Error(error)
  })
}

const cleanCacheState = (tableName: string, params: Array<string>, keys: string) =>
  db[tableName]
    .count()
    .then((count) => {
      if (count >= 4) {
        return db[tableName]
          .toCollection()
          .first()
          .then((first) => {
            db[tableName].where(`[${keys}]`).equals([first[params[0]], first[params[1]]]).delete()
          })
      }
    })
    .catch((err?: string) => {
      throw Error(err)
    })

const updateOrSaveSearchedShiftToDB = (shift: Shift) => {
  const shiftObject = { ...shift, ...{ date: moment(shift.startDateTime).format('YYYY-MM-DD') } }
  return db.searchedShifts
    .update([shiftObject.id, shiftObject.date], shiftObject)
    .then((result) => {
      if (result === 0) {
        return saveValue(db.searchedShifts)(shiftObject).then(
          cleanCacheState('searchedShifts', ['id', 'date'], 'id+date')
        )
      }
    })
}

export const getSearchedShiftSearchHistoryFromDB = (): Promise<Array<Shift>> => {
  // delete 36 hour old or older searches
  const time = moment().subtract(36, 'hours').unix() // timestamp not string!
  db.searchedShifts.where('created_at').belowOrEqual(time).delete()

  return db.searchedShifts.toArray()
}

export const getFindings = (equipments: Array<string>): Promise<Array<Finding>> => {
  const url = `findings?equipments=${equipments.join(',')}`
  return getWithRetry<ResponseDataWithStatusCode>(url).then((res) => {
    if (res.statusCode !== 200) {
      throw Error(res.body)
    }
    return JSON.parse(res.body)
  })
}

/* contacts */

export const getContactsFromDB = (): Promise<DBContacts[]> => {
  const time = moment().subtract(3, 'months').unix()
  db.phoneContacts.where('created_at').belowOrEqual(time).delete()

  return db.phoneContacts.where('created_at').above(time).toArray()
}

export const fetchContactsFromAPI = (): Promise<PhoneContactGroup[]> => {
  //TODO: keskitä tulleen datan sanitycheckit joko tänne tai actions/api.js
  const url = `contacts`
  return getWithRetry<ResponseData>(url)
    .then((res) => JSON.parse(res.body))
    .then((contacts) => {
      if (contacts.error === undefined) {
        const contactToSave = { id: 1, created_at: moment().unix().toString(), contacts: contacts }
        saveValue(db.phoneContacts)(contactToSave)
      }
      return contacts
    })
}

export const fetchHandlingStationsFromAPI = (
  date: string,
  trainNumber: string
): Promise<HandlingStations> => {
  const url = `handlingStations?trainNumber=${trainNumber}&trainDate=${date}`
  return getWithRetry<ResponseDataWithStatusCode>(url).then((res) => {
    if (res.statusCode !== 200) {
      throw Error('Failed fetching handling stations')
    }
    return JSON.parse(res.body)
  })
}

export const fetchCompositionsFromAPI = (
  date: string,
  trainNumber: string,
  station: string
): Promise<Compositions> => {
  let url = ''
  if (date.includes('T') && moment(date).isValid()) {
    url = `compositions/search?departureDateTime=${date}&trainNumber=${trainNumber}&station=${station}`
  } else {
    url = `compositions/search?departureDate=${date}&trainNumber=${trainNumber}&station=${station}`
  }
  return getWithRetry<ResponseDataWithStatusCode>(url)
    .then((res) => JSON.parse(res.body))
    .then((composition) => {
      if (composition.resultCode === '0000') {
        updateOrSaveSearchedCompositionToDB(date, composition)
      }
      return composition
    })
}

export const postCompositionConfirmation = (
  trainNumber: string,
  // trainDate is YYYY-MM-DD
  trainDate: string,
  countryCode: string,
  locationCode: string,
  // checkDateTime is YYYY-MM-DDTHH:mm:ss.SSSZ for example 2019-03-01T12:00:00.000+02:00
  checkDateTime?: string,
  type?: string | null,
  locomotives?: Array<Locomotive> | null
): Promise<Compositions> => {
  const url = `compositions/confirm?trainNumber=${trainNumber}&trainDate=${trainDate}&countryCode=${countryCode}&locationCode=${locationCode}&type=${type}&checkDateTime=${checkDateTime}&timeout=60`
  return postWithRetry<ResponseData>(url, locomotives).then((res) => JSON.parse(res.body))
}

export const getCompositionSearchHistoryFromDB = (): Promise<Array<Compositions>> => {
  // delete 36 hour old or older searches
  const time = moment().subtract(36, 'hours').format()
  db.searchedCompositions.where('timestamp').belowOrEqual(time).delete()

  return db.searchedCompositions.toArray()
}

export const searchedCompositionFromDB = (
  date: string,
  station: string,
  trainNumber: string
): Promise<Array<Compositions>> =>
  db.searchedCompositions
    .where('[trainNumber+station+date]')
    .equals([trainNumber, station, date])
    .toArray()

export const deleteCompositionFromDB = (
  trainNumber: string,
  station: string,
  date: string
): Promise<unknown> =>
  db.searchedCompositions
    .where('[trainNumber+station+date]')
    .equals([trainNumber, station, date])
    .delete()

const updateOrSaveSearchedCompositionToDB = (queryDate: string, composition: Compositions) => {
  const compositionObject = {
    ...{
      date: queryDate,
      trainNumber: composition.route.trainNumber,
      station: composition.route.origStation.stationAbbreviation,
      timestamp: composition.route.listTimestamp,
    },
    ...composition,
  }
  return db.searchedCompositions
    .update(
      [compositionObject.trainNumber, compositionObject.station, compositionObject.date],
      compositionObject
    )
    .then((result) => {
      if (result === 0) {
        return saveValue(db.searchedCompositions)(compositionObject).then(
          cleanCacheState(
            'searchedCompositions',
            ['trainNumber', 'station', 'date'],
            'trainNumber+station+date'
          )
        )
      }
    })
}

export const fetchTimetableFromAPI = (
  isUsedForDriving: boolean,
  parts: Array<TimetableParams> | null
): Promise<ArrayBuffer> => {
  const url = `timetablePDF${isUsedForDriving ? '/usedForDriving' : ''}`
  return postWithRetry(url, parts, { responseType: 'arraybuffer' })
}

export const fetchCalendarURLFromAPI = (): Promise<string> => {
  const url = `/me/calendar`
  return getWithRetry(url)
}

const getTrainPunctuality = (
  trainDate: string,
  trainNumber: string
): Promise<TrainPunctuality | null> => {
  const url = `punctuality?trainDate=${trainDate}&trainNumber=${trainNumber}` // trainDate in YYYY-MM-DD format
  return apiGET<ResponseData>(url)
    .then((res) => JSON.parse(res.body))
    .then((punctuality) => {
      const fetched = !punctuality.error
        ? punctuality.find((t: { trainNumber: number }) => t.trainNumber.toString() === trainNumber)
        : undefined
      return fetched ? { ...fetched, ...{ updatedAt: moment() } } : undefined
    })
}

export const fetchTrainPunctualityFromAPI = (
  trains: Array<Train>
): Promise<Array<TrainPunctuality | null>> => {
  return Promise.allSettled(
    trains.map((t) => getTrainPunctuality(t.trainDate, t.trainNumber))
  ).then((results) =>
    results
      .filter((result) => result.status === 'fulfilled')
      .map((result) => (result as PromiseFulfilledResult<TrainPunctuality>).value)
  )
}

/* Search towings */
export const fetchTowingVehiclesFromAPI = (
  vehicleType: string,
  vehicleNumber: string
): Promise<TowingVehicle> => {
  let url = ''
  if (vehicleNumber && vehicleNumber !== '')
    url = `/towingVehicle?vehicleType=${vehicleType}&vehicleNumber=${vehicleNumber}`
  else url = `/towingVehicle?vehicleType=${vehicleType}`
  return getWithRetry(url)
}

export const fetchMultipleTowingVehiclesFromAPI = (
  vehicleCompounds: Array<string>
): Promise<Array<TowingVehicle>> => {
  const url = `/towingVehicles?vehicleCompounds=${vehicleCompounds.join(',')}`
  return getWithRetry(url)
}

export const postVersionInfo = (log: object) => {
  return postWithRetry(`/log`, { log: { ...log, id: null } }).catch(error)
}

export const fetchReasonCodes = (): Promise<Causes> => {
  const url = 'reasonCodes'
  return getWithRetry(url)
}

export const sendDeviationAmendmentToAPI: (body: AmendmentData) => Promise<unknown> = (
  body: object
) => {
  const url = '/amendDeviationReason'
  return postWithRetry(url, { ...body })
}

export const sendPushSubscription = (subscription: unknown) => {
  const url = '/push/register'
  return postWithRetry(url, { subscription })
}

export const sendPushUnsubscription = () => {
  const url = '/push/unregister'
  return deleteWithRetry(url)
}

export const sendPushNotification = (data: object) => {
  const url = '/push/send'
  return postWithRetry(url, { ...data })
}

export const fetchCrewNoticesFromAPI = (): Promise<unknown> => {
  const url = `ohjusCrewNotice`
  return getWithRetry(url)
}

export const fetchCrewNoticeFromAPI = (crewNoticeId: string) => {
  const url = `ohjusCrewNotice/${crewNoticeId}`
  return getWithRetry(url)
}

export const sendCrewNoticeAckToAPI = (data: object) => {
  const url = `/ohjusCrewNoticeAck`
  return postWithRetry(url, { ...data })
}

export const fetchShiftNoticesFromAPI = (): Promise<ShiftNotice[]> => {
  const url = `shiftNotices`
  return getWithRetry(url)
}

export const sendCustomerFeedbackToAPI = (data: {
  feedback: string
  messageDateTime: Timestamp
}) => {
  const url = '/customerFeedback'
  return postWithRetry(url, { ...data })
}
export const saveObservationMessageToDB = (
  observationMessage: ObservationMessage
): Promise<unknown> => {
  return saveValue(db.observationMessages)(observationMessage)
}

export const getObservationMessagesFromDB = () => {
  const time = moment().subtract(OBSERVATION_MESSAGE_TTL_HRS, 'hours').unix()
  db.observationMessages.where('created_at').belowOrEqual(time).delete()

  return db.observationMessages.toArray()
}

export const sendObservationMessageToAPI = (data: ObservationMessage) => {
  const url = '/observationMessage'
  return postWithRetry(url, { ...data })
}

export const updateOrSaveTestPushMessageToDB = (
  testPushMessage: TestPushMessage
): Promise<unknown> => {
  return db.testPushMessages
    .update([testPushMessage.messageId, testPushMessage.sentAt], testPushMessage)
    .then((result) => {
      if (result === 0) {
        return saveValue(db.testPushMessages)(testPushMessage)
      }
    })
}

export const getTestPushMessagesFromDB = () => {
  const time = moment().subtract(TEST_PUSH_MESSAGE_TTL_HRS, 'hours').unix()
  db.testPushMessages.where('created_at').belowOrEqual(time).delete()

  return db.testPushMessages.toArray()
}

export const sendTestPushMessageToAPI = (testPushMessage: TestPushMessage) => {
  const url = '/push/test'
  return postWithRetry(url, { ...testPushMessage })
}

export const getTowingFormFromAPI = (vehicleType: string): Promise<TowingFormContent> => {
  const url = `/towingForm?vehicleType=${vehicleType}`
  return getWithRetry<TowingFormContent>(url).then((towingForm) => {
    return {
      ...towingForm,
      received_at: unixTimestamp(),
    }
  })
}

export const getTowingFormByIdFromAPI = (
  contentfulId: string
): Promise<TowingFormContentTimestamped> => {
  const url = `/towingForm/${contentfulId}`
  return getWithRetry<TowingFormContent>(url).then((towingForm: TowingFormContent) => {
    return {
      ...towingForm,
      received_at: unixTimestamp(),
    }
  })
}

export const updateOrSaveTowingFormToDB = (
  towingForm: TowingFormContentTimestamped | TowingFormContent
): Promise<unknown> => {
  return db.towingForms
    .update([towingForm.id, towingForm.equipmentType], towingForm)
    .then((result) => {
      if (result === 0) {
        return saveValue(db.towingForms)(towingForm)
      }
    })
}

export const getTowingFormByIdFromDB = (id: string): Promise<TowingFormContent | undefined> => {
  return db.towingForms.where('id').equals(id).first()
}

export const getTowingStepFromAPI = (stepId: string): Promise<TowingStep> => {
  const url = `/towingStep?stepId=${stepId}`
  return getWithRetry<TowingStep>(url).then((towingStep) => {
    return {
      ...towingStep,
      received_at: unixTimestamp(),
    }
  })
}

export const updateOrSaveTowingStepToDB = (towingStep: TowingStep): Promise<unknown> => {
  return db.towingSteps.update(towingStep.id, towingStep).then((result) => {
    if (result === 0) {
      return saveValue(db.towingSteps)(towingStep)
    }
  })
}

export const createOrGetTowingFormStateFromAPI = (
  vehicleType: string,
  vehicleNumber: string | null,
  userVehicleNumber: string | null
): Promise<TowingFormState> => {
  const url = `/towingFormState?vehicleType=${vehicleType}${
    vehicleNumber ? `&vehicleNumber=${vehicleNumber}` : ''
  }${userVehicleNumber ? `&userVehicleNumber=${userVehicleNumber}` : ''}`
  return getWithRetry(url)
}

export const getTowingFormStateByIdFromAPI = (id: string): Promise<TowingFormState> => {
  const url = `/towingFormState/${id}`
  return getWithRetry(url)
}

export const saveTowingFormStateToAPI = (
  towingFormState: TowingFormState,
  action?: string
): Promise<TowingFormState> => {
  let url = ''
  if (action && action !== '') {
    url = `towingFormState/${towingFormState.id}/${action}`
  } else {
    url = `towingFormState/${towingFormState.id}`
  }
  return postWithRetry(url, { towingFormState })
}

export const getTowingFormStateFromDB = (
  vehicleType: string,
  vehicleId: string | null
): Promise<TowingFormState> => {
  return db.towingFormStates // TODO: implent a ttl for towingFormstates
    .where('[vehicleType+vehicleNumber]')
    .equals([vehicleType, vehicleId])
    .first() as Promise<TowingFormState> // TODO: can there be multiple formStates for one type+id pair?
}

export const getTowingFormStateByIdFromDB = (id: string): Promise<TowingFormState> => {
  return db.towingFormStates // TODO: implent a ttl for towingFormstates
    .where('id')
    .equals(id)
    .first() as Promise<TowingFormState>
}

export const updateOrSaveTowingFormStateToDB = (
  towingFormState: TowingFormState
): Promise<unknown> => {
  return db.towingFormStates.update(towingFormState.id, towingFormState).then((result) => {
    if (result === 0) {
      return saveValue(db.towingFormStates)(towingFormState)
    }
  })
}

export const getTowingVehiclesFromAPI = (vehicleType: string): Promise<Array<TowingVehicle>> => {
  const url = `towingVehicle?vehicleType=${vehicleType}`
  return getWithRetry(url)
}

/*towingAuditMessage*/
export const sendTowingAuditMessage = (towingAuditMessage: TowingAuditMessage) => {
  postTowingAuditMessage(towingAuditMessage)
  getSavedTowingAuditMessages().then((res) => {
    if (res && res.length > 0) postPreviouslyFailedTowingAuditMessage(res)
  })
}

const postPreviouslyFailedTowingAuditMessage = (towingAuditMessages: Array<TowingAuditMessage>) => {
  return postWithRetry<string[]>(`/towing/auditMessage`, towingAuditMessages)
    .then((res) => {
      res.forEach((id) => removeValue(db.towingAuditMessages)(id))
    })
    .catch(
      /* eslint-disable @typescript-eslint/no-empty-function */
      () => {}
    )
}

const postTowingAuditMessage = (towingAuditMessage: TowingAuditMessage) => {
  return postWithRetry(`/towing/auditMessage`, [{ ...towingAuditMessage }]).catch(() => {
    saveValue(db.towingAuditMessages)(towingAuditMessage)
  })
}

export const getTowingAuditMessagesFromDB = (): Promise<Array<TowingAuditMessage>> => {
  return db.towingAuditMessages.toArray()
}

export const getSavedTowingAuditMessages = (): Promise<Array<TowingAuditMessage>> =>
  getTowingAuditMessagesFromDB()

export const getTowingVehiclePatternsFromAPI = (): Promise<Array<TowingVehiclePattern>> => {
  const url = `towingVehiclePatterns`
  return getWithRetry(url)
}

/* Assemblies */
export const getAssembliesFromAPI = (params: TaskParams): Promise<Array<Assembly>> => {
  const url = `assemblies/${params
    .map(
      (p) => `trainNumber=${p.trainNumber}&operatingDateTime=${p.operatingDateTime}&ocp=${p.ocp}`
    )
    .join(',')}`
  return getWithRetry(url)
}

/* Energy Efficiency */
export const getEnergyEfficiencyByTrainAndDatesFromAPI = (
  trainNumber: string,
  taskStartDateTime: Timestamp,
  taskEndDateTime: Timestamp
): Promise<EnergyEfficiency> =>
  getWithRetry(
    `energyEfficiency/${trainNumber}?taskStartDateTime=${taskStartDateTime}&taskEndDateTime=${taskEndDateTime}`
  )

export const getEnergyEfficiencyFromAPI = (): Promise<EnergyEfficiency> =>
  getWithRetry('energyEfficiency')

export const getRollingGuidesFromAPI = (): Promise<RollingGuideData> =>
  getWithRetry('rollingGuides')

export const getNewsFromAPI = (): Promise<Array<News>> => getWithRetry('newsfeed')
