import {
  START_FETCHING_PERMISSION_STATE,
  START_SENDING_TEST_PUSH_MESSAGE,
  TEST_PUSH_MESSAGE_ERROR,
  UPDATE_PERMISSION_STATE,
  UPDATE_TEST_PUSH_MESSAGES,
} from '../actions/testPushMessages'
import { TestPushMessageAction } from '../actions/testPushMessages'
import { TestPushMessagesState } from '../types'

const initialState = {
  permissionState: '',
  permissionLoading: false,
  testPushMessages: [],
  loading: false,
  error: '',
}

const testPushMessagesReducer = (
  state: TestPushMessagesState = initialState,
  action: TestPushMessageAction
) => {
  switch (action.type) {
    case UPDATE_TEST_PUSH_MESSAGES:
      return {
        ...state,
        loading: false,
        error: '',
        testPushMessages: action.payload,
      }

    case START_SENDING_TEST_PUSH_MESSAGE:
      return {
        ...state,
        loading: true,
        error: '',
      }

    case TEST_PUSH_MESSAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    case START_FETCHING_PERMISSION_STATE:
      return {
        ...state,
        permissionLoading: true,
      }

    case UPDATE_PERMISSION_STATE:
      return {
        ...state,
        permissionLoading: false,
        permissionState: action.payload,
      }

    default:
      return state
  }
}

export default testPushMessagesReducer
