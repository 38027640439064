import { PersonnelGroup, Session } from '../types/Input'

export type UpdateSession = {
  type: 'UPDATE_SESSION'
  payload: Session
}
export const UPDATE_SESSION = 'UPDATE_SESSION'
export const updateSession = (session: Session): UpdateSession => ({
  type: UPDATE_SESSION,
  payload: session,
})

export type UpdatePersonnelGroup = {
  type: 'UPDATE_PERSONNELGROUP'
  payload: PersonnelGroup
}
export const UPDATE_PERSONNELGROUP = 'UPDATE_PERSONNELGROUP'
export const updatePersonnelGroup = (personnelGroup: PersonnelGroup): UpdatePersonnelGroup => ({
  type: UPDATE_PERSONNELGROUP,
  payload: personnelGroup,
})

export type UpdateServiceDriverRole = {
  type: 'UPDATE_SERVICEDRIVERROLE'
  payload: boolean
}
export const UPDATE_SERVICEDRIVERROLE = 'UPDATE_SERVICEDRIVERROLE'
export const updateServiceDriverRole = (serviceDriver: boolean): UpdateServiceDriverRole => ({
  type: UPDATE_SERVICEDRIVERROLE,
  payload: serviceDriver,
})

export type UserAction = UpdateSession | UpdatePersonnelGroup | UpdateServiceDriverRole
