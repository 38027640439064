import { createAsyncThunk } from '@reduxjs/toolkit'

import { Status } from '../../components/create-defect/types'
import { apiGET } from '../../lib/api'
import { Defect, DefectsFilter } from '../../types'

interface DefectsResponse {
  defects: Array<Defect>
  token?: string
}

interface FetchDefectsError {
  message: string
}

const fetchDefects = (status: Status, defectsFilters?: DefectsFilter): Promise<DefectsResponse> =>
  apiGET(`defects?installationGroups=${defectsFilters?.installationGroups}&status=${status}`)

export const fetchOpenDefectsAction = createAsyncThunk<
  DefectsResponse,
  DefectsFilter | undefined,
  { rejectValue: FetchDefectsError }
>('defects/fetchOpenDefects', async (defectsFilters, thunkApi) => {
  try {
    const response = await fetchDefects('open', defectsFilters)

    return {
      defects: response.defects,
      token: response.token,
    } as DefectsResponse
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: 'Failed to fetch open defects',
    })
  }
})

export const fetchClosedDefectsAction = createAsyncThunk<
  DefectsResponse,
  DefectsFilter | undefined,
  { rejectValue: FetchDefectsError }
>('defects/fetchClosedDefects', async (defectsFilters, thunkApi) => {
  try {
    const response = await fetchDefects('closed', defectsFilters)

    return {
      defects: response.defects,
      token: response.token,
    } as DefectsResponse
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: 'Failed to fetch closed defects',
    })
  }
})

const fetchMoreDefects = (
  status: Status,
  defectsFilters?: DefectsFilter
): Promise<DefectsResponse> =>
  apiGET(
    `defects?installationGroups=${defectsFilters?.installationGroups}&status=${status}&token=${defectsFilters?.token}`
  )

export const fetchMoreOpenDefectsAction = createAsyncThunk<
  DefectsResponse,
  DefectsFilter | undefined,
  { rejectValue: FetchDefectsError }
>('defects/fetchMoreOpenDefects', async (defectsFilters, thunkApi) => {
  try {
    const response = await fetchMoreDefects('open', defectsFilters)

    return {
      defects: response.defects,
      token: response.token,
    } as DefectsResponse
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: 'Failed to fetch more open defects',
    })
  }
})

export const fetchMoreClosedDefectsAction = createAsyncThunk<
  DefectsResponse,
  DefectsFilter | undefined,
  { rejectValue: FetchDefectsError }
>('defects/fetchMoreClosedDefects', async (defectsFilters, thunkApi) => {
  try {
    const response = await fetchMoreDefects('closed', defectsFilters)

    return {
      defects: response.defects,
      token: response.token,
    } as DefectsResponse
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: 'Failed to fetch more closed defects',
    })
  }
})
