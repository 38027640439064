import styled from '@emotion/styled'
import React from 'react'
import { connect } from 'react-redux'

import LoginForm from '../components/LoginForm'
import SeasonalScenery from '../components/SeasonalScenery'
import { getSeasonFromMonth } from '../lib/time'
import { nowSelector } from '../Selectors'
import { AppState } from '../types'

const SceneryHolder = styled.div`
  position: fixed;
  z-index: -1;
  bottom: 0;
  height: 530px;
  width: 100%;
  @media (max-height: 800px) {
    height: 370px;
  }
  @media (max-height: 640px) {
    height: 260px;
  }
  @media (max-height: 520px) {
    display: none;
  }
`

type Props = {
  season: string
  errorTextKey?: string
}

const LoginPage = ({ season, errorTextKey }: Props) => (
  <div>
    <SceneryHolder>
      <SeasonalScenery simple={false} season={season} brightness={100} />
    </SceneryHolder>
    <LoginForm errorTextKey={errorTextKey} />
  </div>
)

const mapStateToProps = (state: AppState) => {
  const now = nowSelector(state)
  const season = getSeasonFromMonth(now.month())
  return { season }
}

export default connect(mapStateToProps)(LoginPage)
