import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React from 'react'

import { MOMENT_TIME_FORMAT } from '../../constants'
import moment from '../../lib/moment-fi'
import { getName } from '../../lib/stations'
import { color, defaultTextColor, getColor, theme } from '../../Theme'
import { Color, Task } from '../../types'
import { ActiveProps } from '../../types/App'
import { TracksFromPunctuality } from '../../types/Input'

const textNightWeak = ({ active }: { active: boolean }): Color => {
  return active ? 'grayLight' : 'grayDark'
}

const Row = styled.div`
  ${theme.layout.flexRow};
  ${theme.layout.fluidWidth(theme.maxWidths.column)};
  color: ${color('primaryGreen')};
  justify-content: space-between;
  padding-left: 12px;
`

const Column = styled.div`
  ${theme.layout.flexColumn};
  align-items: flex-start;
`
const Track = styled.div`
  ${theme.text('small')};
  color: ${defaultTextColor};
  line-height: ${theme.font.larger};
  height: ${theme.font.larger};
  text-overflow: ellipsis;
`

const Location = styled.div`
  ${theme.text('mediumLarge', 'content', 'normal')};
  color: ${defaultTextColor};
  line-height: ${theme.font.larger};
  word-wrap: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: flex-row;
  align-items: center;
  justify-content: flex-start;
  width: 84px;
`

const Time = styled.div`
  padding-right: 5px;
`

const Bold = styled.div`
  ${theme.text('mediumLarge', 'content', 'bold')};
`

const Weak = styled.div<ActiveProps>`
  ${theme.text('mediumLarge', 'content')};
  color: ${(p) => getColor(p.theme, ['grayDark'], [textNightWeak(p)])};
`

type Props = {
  t: TFunction
  task: Task
  delays: Array<unknown>
  delayMinutes: number
  trainCategory: string
  compactView: boolean
  nightMode: boolean
  active: boolean
  isCommuter?: boolean
  tracksFromPunctuality: Partial<TracksFromPunctuality>
}

function RouteTitle({ task, compactView, active, isCommuter, tracksFromPunctuality }: Props) {
  const startName = getName(task.fromStation)
  const endName = getName(task.toStation)
  const startTime = moment(task.taskStartDateTime).format(MOMENT_TIME_FORMAT)
  const endTime = moment(task.taskEndDateTime).format(MOMENT_TIME_FORMAT)
  const startTrack = task.departureTrack
    ? task.departureTrack
    : tracksFromPunctuality.departureTrack
    ? tracksFromPunctuality.departureTrack
    : ''
  const endTrack = task.arrivalTrack
    ? task.arrivalTrack
    : tracksFromPunctuality.arrivalTrack
    ? tracksFromPunctuality.arrivalTrack
    : ''

  const showTracks = startTrack || endTrack
  const startPlace = task.departureTrack ? `${startName} – ${startTrack}` : startName
  const endPlace = task.arrivalTrack ? `${endName} – ${endTrack}` : endName

  return (
    <Row>
      <Column>
        <Location title={startPlace}>
          <Time>
            <Bold>{startTime}</Bold>
          </Time>
          <Weak active={active}>{task.fromStation}</Weak>
        </Location>
        {(!compactView || isCommuter) && showTracks && <Track>{startTrack}</Track>}
      </Column>
      <Column>
        <Location title={endPlace}>
          <Time>
            <Weak active={active}>{endTime}</Weak>
          </Time>
          <Bold>{task.toStation}</Bold>
        </Location>
        {(!compactView || isCommuter) && showTracks && <Track>{endTrack}</Track>}
      </Column>
    </Row>
  )
}

export default RouteTitle
