import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { updateSession } from '../actions/user'
import config from '../config'
import Logo from '../icons/Logo'
import { standaloneMode } from '../lib/pwa'
import { color, getColor, px, theme, unPx } from '../Theme'
import { AppState, Dispatch, Session } from '../types'
import LoadingIndicator from './LoadingIndicator'
import NotificationBar from './notification/NotificationBar'

const Flex = styled.div`
  ${theme.layout.flexColumn};
`

const LogoSpacing = styled.div`
  margin-top: ${px(unPx(theme.spacing.sizes.biggest) * 2)};
  margin-bottom: ${theme.spacing.sizes.biggest};
  color: #57a50b;
`

const LoginButton = styled.div`
  ${theme.text('normal', 'decorative', 'bold')};
  box-sizing: border-box;
  color: ${color('primaryBlue')};
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  ${theme.spacing.all('large')};
  border-radius: 0 0 ${theme.borderRadius.button} ${theme.borderRadius.button};
  cursor: pointer;
`

const Constrain = styled.div`
  ${theme.layout.staticWidth(theme.maxWidths.paddedContent)};
`

const AppTitle = styled.div`
  text-align: center;
  margin-top: ${theme.spacing.sizes.large};
  ${theme.text('largest', 'decorative', 'bold')};
  color: ${(p) => getColor(p.theme, ['primaryGreen'], ['grayPink'])};
`

const AppVersion = styled.div`
  text-align: center;
  margin-top: ${theme.spacing.sizes.small};
  margin-bottom: ${theme.spacing.sizes.huge};
  ${theme.text('large', 'content')};
  color: ${(p) => getColor(p.theme, ['grayDark'], ['grayPink'])};
`

const Form = styled.div`
  ${theme.layout.staticWidth(theme.maxWidths.column)};
  ${theme.spacing.sides('small')};
  ${theme.layout.flexColumn};
  justify-content: center;
`

type Props = {
  session: Session
  onLogin: (session: Session) => () => void
  errorTextKey?: string
}

function LoginForm({ session, onLogin, errorTextKey }: Props) {
  const { t } = useTranslation()

  return (
    <Constrain>
      <Flex>
        <LogoSpacing>
          <Logo height={70} />
        </LogoSpacing>
        <Form>
          {session.error ? (
            <NotificationBar text={t(session.error)} marginBottom={theme.spacing.sizes.tiny} />
          ) : undefined}
          {errorTextKey && !session.loading ? (
            <NotificationBar text={t(errorTextKey)} marginBottom={theme.spacing.sizes.tiny} />
          ) : undefined}
          <AppTitle>{t('appName')}</AppTitle>
          <AppVersion>
            {t('versionNumber', { number: config.version, environment: config.environment })}
          </AppVersion>
          {session.loading ? <LoadingIndicator size="normal" padded={true} /> : undefined}
          <LoginButton onClick={onLogin(session)}>{t('login')}</LoginButton>
        </Form>
      </Flex>
    </Constrain>
  )
}

const mapStateToProps = (state: AppState) => ({ session: state.user })
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onLogin: (session: Session) => () => /*ev*/ {
      // PWA cannot show page from different domain
      // so open login in different tab
      // after login tab is closed
      const loadingSession = {
        ...session,
        loading: true,
      } as Session
      dispatch(updateSession(loadingSession))
      if (standaloneMode()) {
        window.open(`${config.apiBaseUrl}/login`)
      } else {
        window.location.href = `${config.apiBaseUrl}/login`
      }
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
