import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { Dispatch } from 'react'

import { Action, ActionPayload, ActionsFilter } from '../../../components/serviceOrders/types'
import { apiGET, apiPOST } from '../../../lib/api'

interface ActionsResponse {
  key: string
  actions: Action[]
}

export interface ActionsError {
  key?: string
  message: string
}

const fetchActions = (actionsFilter: ActionsFilter): Promise<Array<Action>> =>
  apiGET(
    `serviceOrder/actions?orderId=${actionsFilter.orderId}&activityId=${actionsFilter.activityId}&taskId=${actionsFilter.taskId}`
  )

export const fetchActionsAction = createAsyncThunk<
  ActionsResponse,
  ActionsFilter,
  { rejectValue: ActionsError }
>('actions-fetch', async (actionsFilter, thunkApi) => {
  const key = `${actionsFilter.orderId}:${actionsFilter.activityId}:${actionsFilter.taskId}`
  try {
    const actions = await fetchActions(actionsFilter)

    return {
      key,
      actions,
    }
  } catch (err) {
    const message =
      err instanceof AxiosError ? err?.response?.data?.message : 'Failed to fetch actions'

    return thunkApi.rejectWithValue({
      key,
      message,
    })
  }
})

export const dispatchActionsFetching = (
  dispatch: Dispatch<unknown>,
  actionsFilters: ActionsFilter
): void => {
  dispatch(fetchActionsAction({ ...actionsFilters }))
}

const postActionUpdate = (
  actionFilter: ActionsFilter,
  actionId: string,
  payload: ActionPayload
): Promise<Action> =>
  apiPOST(
    `serviceOrder/updateAction?orderId=${actionFilter.orderId}&activityId=${actionFilter.activityId}&taskId=${actionFilter.taskId}&actionId=${actionId}`,
    payload
  )

type ActionUpdateObject = {
  params: ActionsFilter
  actionId: string
  payload: ActionPayload
}

export const updateActionValueAction = createAsyncThunk<
  Action,
  ActionUpdateObject | undefined,
  { rejectValue: ActionsError }
>('action-update', async (actionUpdateObject, thunkApi) => {
  try {
    if (
      !actionUpdateObject ||
      !actionUpdateObject.params ||
      !actionUpdateObject.payload ||
      !actionUpdateObject.actionId
    ) {
      return thunkApi.rejectWithValue({
        message: 'Cannot update action',
      })
    }

    return await postActionUpdate(
      actionUpdateObject.params,
      actionUpdateObject.actionId,
      actionUpdateObject.payload
    )
  } catch (err) {
    const message =
      err instanceof AxiosError ? err?.response?.data?.message : 'Failed to update action'

    return thunkApi.rejectWithValue({
      message,
    })
  }
})
