export type StartSendingCustomerFeedback = {
  type: 'START_SENDING_CUSTOMER_FEEDBACK'
}
export const START_SENDING_CUSTOMER_FEEDBACK = 'START_SENDING_CUSTOMER_FEEDBACK'
export const startSendingCustomerFeedback = (): StartSendingCustomerFeedback => ({
  type: START_SENDING_CUSTOMER_FEEDBACK,
})

export type UpdateCustomerFeedback = {
  type: 'UPDATE_CUSTOMER_FEEDBACK'
  payload: {
    error: string
  }
}
export const UPDATE_CUSTOMER_FEEDBACK = 'UPDATE_CUSTOMER_FEEDBACK'
export const updateCustomerFeedback = (error: string): UpdateCustomerFeedback => ({
  type: UPDATE_CUSTOMER_FEEDBACK,
  payload: { error },
})

export type CustomerFeedbackAction = StartSendingCustomerFeedback | UpdateCustomerFeedback
