import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { isSameDay } from 'date-fns'
import { Trans, useTranslation } from 'react-i18next'

import { Column } from '../page/PageComponents'
import Incident from './Incident'
import { LiitoIncident } from './types'

const Container = styled(Column)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.background.border}`,
  paddingTop: theme.spacing('normal'),
  marginBottom: theme.spacing('huge'),
  marginTop: theme.spacing('huge'),
}))

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '1em',
})

type Props = {
  incidents: LiitoIncident[]
}

const IncidentsList = ({ incidents }: Props) => {
  const { t } = useTranslation('common', { keyPrefix: 'incidents' })
  const today = new Date()
  const todaysIncidents = incidents.filter((i) => isSameDay(new Date(i.lastModifiedAt), today))
  const earlierIncidents = incidents.filter((i) => !isSameDay(new Date(i.lastModifiedAt), today))
  return (
    <Container>
      <Typography variant="h6" sx={{ color: 'grey.600' }}>
        <Trans
          i18nKey={incidents.length > 0 ? 'readNews' : 'noNews'}
          values={{ feed: 'Radalla' }}
          components={{ bold: <Box sx={{ display: 'inline', color: 'common.kenttaBlue' }} /> }}
        />
      </Typography>
      {todaysIncidents.length > 0 && (
        <SubIncidentList incidents={todaysIncidents} title={t('latestIncidents')} />
      )}
      {earlierIncidents.length > 0 && (
        <SubIncidentList incidents={earlierIncidents} title={t('earlierIncidents')} />
      )}
    </Container>
  )
}

const SubIncidentList = ({ incidents, title }: { incidents: LiitoIncident[]; title: string }) => {
  return (
    <Content>
      <Typography variant="subtitle1" sx={{ color: 'common.primaryGreen' }}>
        {title}
      </Typography>
      <div>
        {incidents.map((incident) => {
          return <Incident key={incident.id} incident={incident} />
        })}
      </div>
    </Content>
  )
}

export default IncidentsList
