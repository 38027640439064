import _ from 'lodash'

import {
  CHANGE_SHIFT_TASK_STATE,
  CLEAR_SHIFT_TASK_SELECTION,
  CLOSE_SHIFT_TASKS,
  MARK_SHIFT_TASKS_DONE,
  MARK_SHIFT_TASKS_UNDONE,
  OPEN_NEXT_TASK,
  OPEN_SHIFT_TASK,
  SELECT_SHIFT_TASK,
  TOGGLE_SHOW_TASK_CONTACT,
} from '../actions/shiftpage'
import { ShiftPageAction } from '../actions/shiftpage'
import { ShiftPageState, TaskState } from '../types'

export const initialState = {
  taskState: {},
}

type ShiftState = Record<number, TaskState>

export const DEFAULT_TASK_STATE: TaskState = {
  open: false,
  crossed: false,
  selected: false,
  visibleContacts: [],
}

// Changes state of one shift
const changeShiftState = (
  state: ShiftPageState,
  shiftId: string,
  fn: (shiftState: ShiftState) => ShiftState
): ShiftPageState => {
  const shiftState: ShiftState = state.taskState[shiftId] || {}

  return {
    ...state,
    taskState: {
      ...state.taskState,
      [shiftId]: fn(shiftState),
    },
  }
}

const changeEachTaskState = (
  state: ShiftPageState,
  shiftId: string,
  fn: (taskState: TaskState) => TaskState
): ShiftPageState => {
  const reducer = (st: ShiftState, i: number) => {
    const t: TaskState = st[i] || DEFAULT_TASK_STATE
    return { ...st, [i]: fn(t) }
  }
  return changeShiftState(state, shiftId, (shiftState: ShiftState): ShiftState => {
    const taskIndexes = _.keys(shiftState).map((s) => parseInt(s, 10))
    return taskIndexes.reduce(reducer, shiftState)
  })
}

// Changes state of one task
const changeTaskState = (
  state: ShiftPageState,
  shiftId: string,
  taskIndex: number,
  fn: (taskState: TaskState) => TaskState
): ShiftPageState => {
  return changeShiftState(state, shiftId, (shiftState: ShiftState) => {
    const taskState: TaskState = shiftState[taskIndex] || DEFAULT_TASK_STATE

    return {
      ...shiftState,
      [taskIndex]: fn(taskState),
    }
  })
}

const toggleOpen = (taskState: TaskState): TaskState => ({
  ...taskState,
  open: !taskState.open,
})
const closeTask = (taskState: TaskState): TaskState => ({
  ...taskState,
  open: false,
})
const openTask = (taskState: TaskState): TaskState => ({
  ...taskState,
  open: true,
})
const selectTask = (taskState: TaskState): TaskState => ({
  ...taskState,
  selected: true,
})
const deSelectTask = (taskState: TaskState): TaskState => ({
  ...taskState,
  selected: false,
})
const crossTask = (taskState: TaskState): TaskState => ({
  ...taskState,
  selected: false,
  crossed: true,
  open: false,
})
const uncrossTask = (taskState: TaskState): TaskState => ({
  ...taskState,
  selected: false,
  crossed: false,
  open: false,
})

const shiftPageReducer = (
  state: ShiftPageState = initialState,
  action: ShiftPageAction
): ShiftPageState => {
  let taskCount: number, contactNumber: string | null, taskIndexes: Array<number>

  switch (action.type) {
    case CHANGE_SHIFT_TASK_STATE:
      return changeTaskState(state, action.payload.shiftId, action.payload.taskIndex, toggleOpen)

    case OPEN_SHIFT_TASK: {
      const tempState: ShiftPageState = changeEachTaskState(
        state,
        action.payload.shiftId,
        closeTask
      )
      return changeTaskState(tempState, action.payload.shiftId, action.payload.taskIndex, openTask)
    }

    case CLOSE_SHIFT_TASKS:
      return changeEachTaskState(state, action.payload.shiftId, closeTask)

    case SELECT_SHIFT_TASK:
      return changeTaskState(
        state,
        action.payload.shiftId,
        action.payload.taskIndex,
        action.payload.selected ? selectTask : deSelectTask
      )

    case CLEAR_SHIFT_TASK_SELECTION:
      return changeEachTaskState(state, action.payload.shiftId, deSelectTask)

    case MARK_SHIFT_TASKS_DONE:
      taskIndexes = action.payload.indexes

      return changeShiftState(
        state,
        action.payload.shiftId,
        (shiftState: ShiftState): ShiftState => {
          // Mark tasks with indexes as crossed
          return taskIndexes.reduce((s: ShiftState, i: number) => {
            const t: TaskState = s[i] || DEFAULT_TASK_STATE

            return { ...s, [i]: crossTask(t) }
          }, shiftState)
        }
      )

    case MARK_SHIFT_TASKS_UNDONE:
      taskIndexes = action.payload.indexes

      return changeShiftState(
        state,
        action.payload.shiftId,
        (shiftState: ShiftState): ShiftState => {
          // Mark tasks with indexes as crossed
          return taskIndexes.reduce((s: ShiftState, i: number) => {
            const t: TaskState = s[i] || DEFAULT_TASK_STATE

            return { ...s, [i]: uncrossTask(t) }
          }, shiftState)
        }
      )

    // Opens first task that is not crossed
    case OPEN_NEXT_TASK:
      taskCount = action.payload.taskCount

      if (taskCount < 1) {
        return state
      }

      return changeShiftState(
        state,
        action.payload.shiftId,
        (shiftState: ShiftState): ShiftState => {
          const indexes: Array<number> = _.range(taskCount)
          const taskStates = indexes.map(
            (taskIndex: number) => shiftState[taskIndex] || DEFAULT_TASK_STATE
          )
          const index = _.findIndex(taskStates, (t: TaskState) => t.crossed === false)

          if (index !== -1) {
            const uncrossed = taskStates[index]
            return {
              ...shiftState,
              [index]: { ...uncrossed, open: true },
            }
          }

          return shiftState
        }
      )

    case TOGGLE_SHOW_TASK_CONTACT:
      contactNumber = action.payload.contactNumber
      return changeTaskState(
        state,
        action.payload.shiftId,
        action.payload.taskIndex,
        (taskState: TaskState): TaskState => {
          return {
            ...taskState,
            visibleContacts:
              !contactNumber || _.includes(taskState.visibleContacts, contactNumber)
                ? _.filter(taskState.visibleContacts, (n) => n !== contactNumber)
                : _.concat(taskState.visibleContacts, contactNumber),
          }
        }
      )

    default:
      return state
  }
}

export default shiftPageReducer
