import { useState } from 'react'

type SetValue<T> = (value: T | ((prevValue: T) => T)) => void

type UseLocalStorageReturn<T> = [T, SetValue<T>, (key: string, setValue: SetValue<T>) => void]

export function useLocalStorage<T extends { toString(): string }>(
  key: string,
  initialValue: T
): UseLocalStorageReturn<T> {
  const [storedValue, setStoredValue] = useState<T>(() => {
    const itemString = window.localStorage.getItem(key)
    try {
      if (itemString !== null) {
        return JSON.parse(itemString)
      }
    } catch (error) {
      return itemString ?? initialValue
    }
  })

  const setValue: SetValue<T> = (value) => {
    try {
      const newValue = value instanceof Function ? value(storedValue) : value
      setStoredValue(newValue)
      window.localStorage.setItem(key, newValue.toString())
    } catch (error) {
      console.error(error)
    }
  }

  const removeValue = (): void => {
    try {
      window.localStorage.removeItem(key)
      setValue(null as unknown as T)
    } catch (error) {
      console.error(error)
    }
  }

  return [storedValue, setValue, removeValue]
}
