import styled from '@emotion/styled'

import { theme } from '../../../Theme'
import Shadow from '../../Shadow'

const Card = styled(Shadow)`
  ${theme.layout.fluidWidth(theme.maxWidths.column)};
`

export default Card
