import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { fetchPersonnelInformation, loadPersonnelFromHistory } from '../actions/api'
import LoadingIndicator from '../components/LoadingIndicator'
import {
  BackIcon,
  Container,
  Content,
  Footer,
  FooterBackIcon,
  FooterText,
  HeaderText,
  PageHeader,
  TitleText,
} from '../components/page/PageComponents'
import PersonnelList from '../components/personnel/PersonnelList'
import ArrowLeft from '../icons/ArrowLeft'
import moment from '../lib/moment-fi'
import { nightModeSelector } from '../Selectors'
import { theme } from '../Theme'
import { Action, AppState, Dispatch, Personnel, PersonnelList as TrainPersonnel } from '../types'
import { MatchProps } from '../types/App'

const Constrain = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.paddedColumn)};
`

type Props = {
  t: TFunction
  loading: boolean
  error: string
  personnel: Personnel | null
  params: Array<unknown>
  nightMode: boolean
  fetchPersonnelInformation: (arg0: string, arg1: string, arg2?: () => unknown | null) => unknown
  loadPersonnelFromHistory: (arg0: string, arg1: string) => unknown
  match: MatchProps
}

type State = {
  trainNumber: string
  date: string
}

class PersonnelListPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      trainNumber: '',
      date: moment().format('DD.MM.YYYY'),
    }
  }

  componentDidMount() {
    const pathParameters = this.props.match.params[0].split('/')
    if (!this.props.personnel) {
      if (pathParameters.length > 2 && pathParameters[2] === 'cached') {
        return this.props.loadPersonnelFromHistory(pathParameters[1], pathParameters[0])
      }
      return this.props.fetchPersonnelInformation(
        moment(pathParameters[1], 'DD.MM.YYYY').format('YYYY-MM-DD'),
        pathParameters[0]
      )
    }
  }

  render() {
    const { loading, personnel, t, error }: Props = this.props

    const trainNumber = personnel ? personnel.trainNumber : ''
    const depTime = personnel ? moment(personnel.depTime).format('DD.MM.YYYY') : ''
    const arrTime = personnel ? moment(personnel.arrTime).format('DD.MM.YYYY') : ''
    const depOcp = personnel ? personnel.depOcp : ''
    const arrOcp = personnel ? personnel.arrOcp : ''
    const personnelList: TrainPersonnel = personnel ? personnel.personnel : {}

    const header = (personnel: Personnel | null) =>
      personnel
        ? `${t('trainNumber')} ${trainNumber}, ${depTime} - ${arrTime}, ${t(
            'leg'
          )} ${depOcp}-${arrOcp}`
        : ''

    return (
      <Container>
        <PageHeader onClick={() => window.history.back()} height="64px">
          <BackIcon>
            <ArrowLeft iconSize="normal" />
          </BackIcon>
          <HeaderText>{t('personnelSearchResults')}</HeaderText>
        </PageHeader>
        <Content>
          {loading ? (
            <LoadingIndicator size="normal" padded={true} />
          ) : error !== '' ? (
            <div>{t(error)}</div>
          ) : (
            <Constrain>
              <TitleText>{header(personnel)}</TitleText>
              <div>
                {personnelList ? (
                  <PersonnelList
                    personnel={personnelList}
                    trainNumber={this.props.match.params[0].split('/')[0]}
                  /> //TODO: crashes react
                ) : (
                  <div>{t(error)}</div>
                )}
              </div>
            </Constrain>
          )}
        </Content>
        <Footer onClick={() => window.history.back()}>
          <FooterBackIcon>
            <ArrowLeft iconSize="normal" />
          </FooterBackIcon>
          <FooterText>{t('back')}</FooterText>
        </Footer>
      </Container>
    )
  }
}

const mapStateToProps = (state: AppState) => {
  const loading = state.personnel.loading
  const error = state.personnel.error

  const personnel = state.personnel.personnelList

  return { personnel, loading, error, nightMode: nightModeSelector(state) }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchPersonnelInformation: (date: string, trainNumber: string) =>
    dispatch(fetchPersonnelInformation(date, trainNumber) as unknown as Action),
  loadPersonnelFromHistory: (date: string, train: string) =>
    dispatch(loadPersonnelFromHistory(date, train) as unknown as Action),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PersonnelListPage))
