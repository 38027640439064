import styled from '@emotion/styled'

import { getColor, theme } from '../Theme'
import { ColorProps } from '../types/App'

const Emp = styled.div<Partial<ColorProps>>`
  display: inline-block;
  color: ${(p) => getColor(p.theme, [p.color, 'black'], [p.color, 'grayPink'])};
`

const Emphasis = styled(Emp)`
  ${theme.text('small', 'content', 'bold')};
  text-transform: uppercase;
`

export const EmphasisNormal = styled(Emp)`
  ${theme.text('normal', 'content', 'bold')};
`

export const EmphasisLarge = styled(Emp)`
  ${theme.text('large', 'content', 'bold')};
`

export default Emphasis
