import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import SuccessPlain from '../../icons/SuccessPlain'
import moment from '../../lib/moment-fi'
import { color, getColor, theme } from '../../Theme'
import { AppState } from '../../types'
import { Route } from '../../types'
import { ExclamationProps } from '../../types/App'
import Switch from './Switch'

interface ConfirmButtonProps {
  offline?: boolean
}
interface InspectionContainerProps extends ConfirmButtonProps {
  inspectionRequired?: boolean
  background?: string
  height?: string
}

interface TextProps {
  bold: string
  uppercase: string
}

interface RoundBackgroundProps extends ConfirmButtonProps {
  size?: string
}

const Container = styled.div`
  ${theme.layout.flexColumn};
  display: flex;
  justify-content: space-around;
  color: white;
  margin-bottom: 2px;
`

const InspectionContainer = styled.div<InspectionContainerProps>`
  ${theme.layout.flexRow};
  width: 100%;
  background: ${(p) =>
    p.background !== undefined && p.background !== ''
      ? p.background
      : p.inspectionRequired
      ? getColor(p.theme, ['red'], ['red'])
      : getColor(p.theme, ['primaryGreen'], ['primaryGreen'])};
  height: ${(props) => (props.height ? props.height : '67px')};
  display: flex;
  justify-content: space-around;
`

const Text = styled.div<TextProps>`
  font-size: ${theme.font.small};
  font-weight: ${(p) => p.bold || 'normal'};
  text-transform: ${(p) => p.uppercase || 'normal'};
`

const TimeText = styled.div`
  font-size: ${theme.font.small};
  font-weight: normal;
  color: ${color('grayBlue')};
`

const UpdateText = styled.div`
  font-size: ${theme.font.smaller};
  font-weight: bold;
  text-transform: uppercase;
  background: ${color('darkGreen')};
  width: 100%;
  padding: 2px 0px 2px 0px;
`

const SmallColumn = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
`

const BigColumn = styled.div<ConfirmButtonProps>`
  width: 60%;
  display: flex;
  justify-content: left;
  flex-direction: column;
`

const RoundBackground = styled.div<RoundBackgroundProps>`
  width: ${(p) => p.size || '26px'};
  height: ${(p) => p.size || '26px'};
  border-radius: 50%;
  color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
`

const Exclamation = styled.div<ExclamationProps>`
  color: ${(p) =>
    p.inspectionRequired
      ? getColor(p.theme, ['red'], ['red'])
      : getColor(p.theme, ['primaryGreen'], ['primaryGreen'])};
`

type Props = {
  route: Route
  t: TFunction
  confirmCompleted: string
  buttonName: string
  onClick: () => void
  text: string
  fullBrakeCheckCompleted?: string
  sourceSystem: string
  updateConfirmation?: () => void
  showConfirmButton?: boolean
}

const isButtonEnabledKAPU = (
  status: string,
  buttonName: string,
  fullBrakeCheckCompleted?: string
) => {
  if (buttonName === 'L') {
    return true
  }
  if (buttonName === 'TJT' && ['E', 'V', 'J', 'S'].includes(status)) {
    return true
  }
  if (buttonName === 'OJT' && status === 'V' && fullBrakeCheckCompleted) {
    return true
  }
  return false
}

const isButtonEnabledRCS = (
  status: string,
  buttonName: string,
  route: Route,
  fullBrakeCheckCompleted?: string
) => {
  if (status === 'E' && buttonName === 'TJT') {
    return true
  }
  if (
    status === 'E' &&
    fullBrakeCheckCompleted &&
    route.origStation.stationAbbreviation !== route.firstStation.stationAbbreviation
  ) {
    return true
  }
  return false
}

const isSwitchReady = (
  confirmCompleted: string,
  route: Route,
  buttonName: string,
  sourceSystem: string
) => {
  if (
    sourceSystem === 'RCS' &&
    route &&
    route.origStation &&
    route.firstStation &&
    route.origStation.stationAbbreviation !== route.firstStation.stationAbbreviation
  ) {
    return false
  }
  if (sourceSystem === 'RCS' && buttonName === 'TJT' && (route && route.status) === 'E') {
    return false
  }
  if (
    sourceSystem === 'RCS' &&
    buttonName === 'TJT' &&
    route &&
    route.listStatus === 'JP-ENNAKOITU-LISTAUS'
  ) {
    return false
  }
  return !!confirmCompleted
}

const showConfirmTime = (confirmCompleted: string, sourceSystem: string, buttonName: string) => {
  if (
    confirmCompleted &&
    (sourceSystem === 'KAPU' || (sourceSystem === 'RCS' && buttonName === 'TJT'))
  ) {
    return moment(confirmCompleted).format('DD.MM.YYYY HH.mm')
  }
  return ''
}

const inspectionRequired = (confirmCompleted: string, status: string, sourceSystem: string) => {
  if (sourceSystem === 'RCS' && status === 'E') {
    return true
  }
  return !confirmCompleted
}

const ConfirmButton = ({
  route,
  onClick,
  buttonName,
  confirmCompleted,
  text,
  fullBrakeCheckCompleted,
  sourceSystem,
  updateConfirmation,
  t,
  showConfirmButton,
}: Props) => {
  const buttonEnabled =
    sourceSystem === 'KAPU'
      ? isButtonEnabledKAPU(route.status, buttonName, fullBrakeCheckCompleted)
      : isButtonEnabledRCS(route.status, buttonName, route, fullBrakeCheckCompleted)
  const offline = showConfirmButton ? buttonName === 'L' && !!confirmCompleted : true

  return buttonEnabled || fullBrakeCheckCompleted ? (
    <Container>
      <InspectionContainer
        inspectionRequired={inspectionRequired(confirmCompleted, route.status, sourceSystem)}
        offline={offline}
      >
        <SmallColumn>
          <RoundBackground offline={offline}>
            {!inspectionRequired(confirmCompleted, route.status, sourceSystem) ? (
              <SuccessPlain iconSize="smallest" fillColor="green" />
            ) : (
              <Exclamation
                inspectionRequired={inspectionRequired(
                  confirmCompleted,
                  route.status,
                  sourceSystem
                )}
              >
                !
              </Exclamation>
            )}
          </RoundBackground>
        </SmallColumn>
        <BigColumn offline={offline}>
          <Text bold="bold" uppercase="uppercase">
            {text}
          </Text>
          <TimeText>{showConfirmTime(confirmCompleted, sourceSystem, buttonName)}</TimeText>
        </BigColumn>
        <SmallColumn
          /* eslint-disable @typescript-eslint/no-empty-function */
          onClick={offline ? () => {} : onClick}
        >
          <Switch
            ready={isSwitchReady(confirmCompleted, route, buttonName, sourceSystem)}
            offline={offline}
            marginLeft="none"
          />
        </SmallColumn>
      </InspectionContainer>
      {sourceSystem === 'KAPU' && buttonName === 'TJT' && confirmCompleted ? (
        <InspectionContainer background={color('darkGreen')} height={'30px'}>
          <SmallColumn />
          <BigColumn>
            <UpdateText onClick={updateConfirmation}>
              {t('confirmCompleteBrakeCheckUpdate')}
            </UpdateText>
          </BigColumn>
          <SmallColumn />
        </InspectionContainer>
      ) : (
        <div />
      )}
    </Container>
  ) : null
}

const mapStateToProps = (state: AppState): unknown => {
  return {
    showConfirmButton:
      state.user.admin ||
      state.user.commuter_driver ||
      state.user.driver ||
      state.user.logistics_driver ||
      state.user.conductor ||
      state.user.other,
  }
}

export default withTranslation()(connect(mapStateToProps)(ConfirmButton))
