import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import {
  fetchPushManagerPermissionState,
  fetchTestPushMessages,
  sendTestPushMessage,
} from '../actions/api'
import Button from '../components/button/Button'
import ErrorText from '../components/ErrorText'
import LoadingIndicator from '../components/LoadingIndicator'
import {
  BackIcon,
  Container,
  Content,
  HeaderText,
  PageHeader,
} from '../components/page/PageComponents'
import ArrowLeft from '../icons/ArrowLeft'
import moment from '../lib/moment-fi'
import { defaultTextColor, getColor, theme } from '../Theme'
import { Action, AppState, Dispatch, TestPushMessage } from '../types'

const Constrain = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.paddedColumn)};
`

const Title = styled.h1`
  ${theme.text('larger', 'decorative', 'normal')};
  color: ${defaultTextColor};
  ${theme.spacing.ends('small')};
`
const MessageContainer = styled.div`
  margin-bottom: ${theme.spacing.sizes.smaller};
`
const Message = styled.div<{ isReceived?: boolean }>`
  ${theme.layout.flexColumn};
  width: 100%;
  background: ${(p) =>
    p.isReceived ? getColor(p.theme, ['primaryGreenTinted'], ['primaryGreen']) : ''};
  justify-content: flex-start;
  ${theme.spacing.ends('smaller')};
  ${theme.effects.shadow};
`

const Permission = styled(Message)<{ permissionState?: string }>`
  background: ${(p) => {
    if (p.permissionState === 'granted') {
      return getColor(p.theme, ['primaryGreenTinted'], ['primaryGreen'])
    } else if (p.permissionState === 'prompt') {
      return getColor(p.theme, ['primaryYellowTinted'], ['primaryYellow'])
    } else {
      return getColor(p.theme, ['redTinted'], ['red'])
    }
  }};
  align-text: center;
`

const Row = styled.div`
  ${theme.layout.flexRow};
  ${theme.spacing.sides('tiny')};
  ${theme.spacing.ends('smallest')};
  flex: 1;
  width: 80%;
  justify-content: space-between;
`

const TitleRow = styled(Row)`
  ${theme.text('large', 'content', 'bold')}
  ${theme.layout.flexCenterH};
  color: ${(p) => getColor(p.theme, ['yellowDark'], ['primaryYellow'])};
`

const MessageList = styled.div`
  ${theme.layout.flexColumn};
  ${theme.spacing.ends('normal')};
  align-items: stretch;
`

type Props = {
  t: TFunction
  loading: boolean
  permissionLoading: boolean
  error: string
  testPushMessages: Array<TestPushMessage>
  permissionState: string
  fetchTestPushMessages: () => void
  fetchPushManagerPermissionState: () => void
  sendTestPushMessage: (testPushMessage: TestPushMessage) => void
}

/*
  TODO:
  - Testiviestin määrän rajaaminen
  - Linkki ohjeisiin
*/

const PushMessagesPage = ({
  t,
  loading,
  permissionLoading,
  error,
  testPushMessages,
  permissionState,
  fetchTestPushMessages,
  fetchPushManagerPermissionState,
  sendTestPushMessage,
}: Props) => {
  useEffect(() => {
    fetchTestPushMessages()
    fetchPushManagerPermissionState()
  }, [fetchPushManagerPermissionState, fetchTestPushMessages])

  const handleTestPush = () => {
    const testMessage = {
      messageId: uuidv4(),
      sentAt: moment().toISOString(),
    }
    sendTestPushMessage(testMessage)
  }

  return (
    <Container>
      <PageHeader onClick={() => window.history.back()} height="64px">
        <BackIcon>
          <ArrowLeft iconSize="normal" />
        </BackIcon>
        <HeaderText>{t('pushMessageAbout')}</HeaderText>
      </PageHeader>
      <Content>
        <Constrain>
          {permissionLoading ? (
            <LoadingIndicator size="normal" padded={true} />
          ) : (
            <MessageContainer>
              <Permission permissionState={permissionState}>
                <TitleRow>
                  {permissionState !== '' ? t(`pushMessagePermission${permissionState}`) : ''}
                </TitleRow>
              </Permission>
            </MessageContainer>
          )}
          <Title>{t('pushMessageTest')}</Title>
          <Button onClick={handleTestPush}>{t('sendTestPushMessage')}</Button>
          {error !== '' && <ErrorText>{t(error)}</ErrorText>}
          {loading ? (
            <LoadingIndicator size="normal" padded={true} />
          ) : (
            <MessageList>
              {testPushMessages.map((message) => (
                <MessageContainer key={message.sentAt}>
                  <Message isReceived={!!message.receivedAt}>
                    {`${t('testPushMessageRequested')}: ${moment(message.sentAt).format(
                      'DD.MM.YYYY HH:mm'
                    )}`}
                    <br />
                    {message.receivedAt
                      ? `${t('pushMessageReceived')}: ${moment(message.receivedAt).format(
                          'DD.MM.YYYY HH:mm'
                        )}`
                      : t('pushMessageNotReceived')}
                  </Message>
                </MessageContainer>
              ))}
            </MessageList>
          )}
        </Constrain>
      </Content>
    </Container>
  )
}

const mapStateToProps = (state: AppState) => {
  const loading = state.testPushMessages.loading
  const testPushMessages = state.testPushMessages.testPushMessages.sort((a, b) =>
    moment(b.sentAt).diff(moment(a.sentAt))
  )

  const permissionLoading = state.testPushMessages.permissionLoading
  const permissionState = state.testPushMessages.permissionState

  const error = state.testPushMessages.error

  return {
    loading,
    permissionLoading,
    error,
    testPushMessages,
    permissionState,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTestPushMessages: () => dispatch(fetchTestPushMessages() as unknown as Action),
  fetchPushManagerPermissionState: () =>
    dispatch(fetchPushManagerPermissionState() as unknown as Action),
  sendTestPushMessage: (testPushMessage: TestPushMessage) =>
    dispatch(sendTestPushMessage(testPushMessage) as unknown as Action),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PushMessagesPage))
