import styled from '@emotion/styled'
import React from 'react'
import { withTranslation } from 'react-i18next'

import Phone from '../../icons/Phone'
import { getColor, theme } from '../../Theme'
import { ExternalLink } from '../Link'
import CardHeader from '../search/card/CardHeader'
import Text from '../Text'

interface PhoneContactProps {
  name: string
  phoneNumber: string
  additionalInfo?: string | null
}

type Props = {
  contact: PhoneContactProps
}

const CardText = styled(Text)`
  margin-left: 20px;
  color: ${(p) => getColor(p.theme, ['black'], ['white'])};
`

const Number = styled.div`
  font-size: ${theme.text('normal')};
`

const Info = styled.div`
  font-size: ${theme.text('normal')}:;
`

const Header = styled(CardHeader)`
  font-weight: 600;
`
const Divider = styled.div`
  height: 1px;
  background-color: ${(p) => getColor(p.theme, ['grayLight'], ['grayDark'])};
  width: 100%;
  margin-bottom: 8px;
  margin-top: 8px;
`

const Contact = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  ${theme.layout.flexRow}
  justify-content: space-between;
`

const PhoneContactCard = ({ contact }: Props) => (
  <div>
    <Contact>
      <div>
        <Header>{contact.name}</Header>
        <CardText>
          <ExternalLink href={`tel:${contact.phoneNumber}`}>
            <Number>
              {contact.phoneNumber} <br />
            </Number>
          </ExternalLink>
          <Info>{contact.additionalInfo}</Info>
        </CardText>
      </div>
      <div style={{ marginRight: 20 }}>
        <ExternalLink href={`tel:${contact.phoneNumber}`}>
          <Phone iconSize="moreHuge" />
        </ExternalLink>
      </div>
    </Contact>
    <Divider />
  </div>
)

export default withTranslation()(PhoneContactCard)
