type Config = {
  apiBaseUrl: string
  version: string
  environment: 'prod' | 'test' | 'dev'
  features: {
    shiftFeedback: boolean
  }
  vapid: string
}

const parseEnv = (appEnv?: string) => {
  switch (appEnv) {
    case 'prod':
    case 'test':
    case 'dev':
      return appEnv
    default:
      return 'dev'
  }
}

const environment = parseEnv(process.env.REACT_APP_ENV)

const config: Config = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || '',
  version: `${process.env.REACT_APP_VERSION || '0.0.1'}`,
  environment,
  features: {
    shiftFeedback: true,
  },
  vapid: process.env.REACT_APP_PUBLIC_VAPID_KEY || '',
}

export default config
