import { Theme, ThemeCssVarOverrides } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface Theme {
    layout: {
      flexCenterH: React.CSSProperties
      flexRow: React.CSSProperties
      flexColumn: React.CSSProperties
      staticWidth: (theme: Theme, width: string) => React.CSSProperties
      fluidWidth: (theme: Theme, maxWidth: string) => React.CSSProperties
      fluidWidthMargin: (theme: Theme, maxWidthPx: number, marginPx: number) => React.CSSProperties
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    layout?: {
      flexCenterH?: React.CSSProperties
      flexRow?: React.CSSProperties
      flexColumn?: React.CSSProperties
      staticWidth?: (Theme: Theme, width: string) => React.CSSProperties
      fluidWidth?: (Theme: Theme, maxWidth: string) => React.CSSProperties
      fluidWidthMargin?: (theme: Theme, maxWidthPx: number, marginPx: number) => React.CSSProperties
    }
  }
}

const layout: ThemeCssVarOverrides = {
  flexCenterH: {
    display: 'flex',
    justifyContent: 'center',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  staticWidth: (theme: Theme, width: 'mobile' | 'tablet' | 'desktop') => ({
    margin: '0 auto',
    width: theme.breakpoints.values[width],
    boxSizing: 'border-box',
    [theme.breakpoints.down(theme.breakpoints.values[width])]: {
      maxWidth: '100%',
      width: '100%',
      minWidth: '100%',
    },
  }),
  fluidWidth: (theme: Theme, maxWidth: 'mobile' | 'tablet' | 'desktop') => ({
    margin: '0 auto',
    maxWidth: theme.breakpoints.values[maxWidth],
    width: theme.breakpoints.values[maxWidth],
    boxSizing: 'border-box',
    [theme.breakpoints.down(theme.breakpoints.values[maxWidth])]: {
      maxWidth: '100%',
      width: '100%',
      minWidth: '100%',
    },
  }),
  fluidWidthMargin: (theme: Theme, maxWidthPx: number, marginPx: number) => ({
    marginLeft: `${marginPx}px`,
    marginRight: `${marginPx}px`,
    maxWidth: `${maxWidthPx}px`,
    width: `${maxWidthPx}px`,
    boxSizing: 'border-box',
    [theme.breakpoints.down(maxWidthPx - marginPx * 2 - 1)]: {
      maxWidth: `calc(100% - ${marginPx * 2}px)`,
      width: `calc(100% - ${marginPx * 2}px)`,
      minWidth: `calc(100% - ${marginPx * 2}px)`,
    },
  }),
}

export default layout
