import { Feedback, FeedbackEmphasis, FeedbackError } from '../types/Input'

export type ToggleFeedbackError = {
  type: 'TOGGLE_FEEDBACK_ERROR'
  payload: {
    error: FeedbackError
    feedback: Feedback
  }
}
export const TOGGLE_FEEDBACK_ERROR = 'TOGGLE_FEEDBACK_ERROR'
export const toggleFeedbackError = (
  error: FeedbackError,
  feedback: Feedback
): ToggleFeedbackError => ({
  type: TOGGLE_FEEDBACK_ERROR,
  payload: { error, feedback },
})

export type ToggleFeedbackEmphasis = {
  type: 'TOGGLE_FEEDBACK_EMPHASIS'
  payload: {
    emphasis: FeedbackEmphasis
    feedback: Feedback
  }
}
export const TOGGLE_FEEDBACK_EMPHASIS = 'TOGGLE_FEEDBACK_EMPHASIS'
export const toggleFeedbackEmphasis = (
  emphasis: FeedbackEmphasis,
  feedback: Feedback
): ToggleFeedbackEmphasis => ({
  type: TOGGLE_FEEDBACK_EMPHASIS,
  payload: { emphasis, feedback },
})

export type SetFeedbackMessage = {
  type: 'SET_FEEDBACK_MESSAGE'
  payload: {
    message: string
    feedback: Feedback
  }
}
export const SET_FEEDBACK_MESSAGE = 'SET_FEEDBACK_MESSAGE'
export const setFeedbackMessage = (message: string, feedback: Feedback): SetFeedbackMessage => ({
  type: SET_FEEDBACK_MESSAGE,
  payload: { message, feedback },
})

export type SetFeedbackErrorMessage = {
  type: 'SET_FEEDBACK_ERROR_MESSAGE'
  payload: {
    errorMessage: string
    feedback: Feedback
  }
}
export const SET_FEEDBACK_ERROR_MESSAGE = 'SET_FEEDBACK_ERROR_MESSAGE'
export const setFeedbackErrorMessage = (
  errorMessage: string,
  feedback: Feedback
): SetFeedbackErrorMessage => ({
  type: SET_FEEDBACK_ERROR_MESSAGE,
  payload: { errorMessage, feedback },
})

export type ToggleFeedbackRequestAnswer = {
  type: 'TOGGLE_FEEDBACK_REQUEST_ANSWER'
  payload: Feedback
}
export const TOGGLE_FEEDBACK_REQUEST_ANSWER = 'TOGGLE_FEEDBACK_REQUEST_ANSWER'
export const toggleFeedbackRequestAnswer = (feedback: Feedback): ToggleFeedbackRequestAnswer => ({
  type: TOGGLE_FEEDBACK_REQUEST_ANSWER,
  payload: feedback,
})

export type UpdateFeedbackStars = {
  type: 'UPDATE_FEEDBACK_STARS'
  payload: {
    stars: number
    feedback: Feedback
  }
}
export const UPDATE_FEEDBACK_STARS = 'UPDATE_FEEDBACK_STARS'
export const updateFeedbackStars = (stars: number, feedback: Feedback): UpdateFeedbackStars => ({
  type: UPDATE_FEEDBACK_STARS,
  payload: { stars, feedback },
})

export type UpdateFeedback = {
  type: 'UPDATE_FEEDBACK'
  payload: Feedback
}
export const UPDATE_FEEDBACK = 'UPDATE_FEEDBACK'
export const updateFeedback = (feedback: Feedback): UpdateFeedback => ({
  type: UPDATE_FEEDBACK,
  payload: feedback,
})

export type ClearFeedbacks = {
  type: 'CLEAR_FEEDBACKS'
}
export const CLEAR_FEEDBACKS = 'CLEAR_FEEDBACKS'
export const clearFeedbacks = (): ClearFeedbacks => ({
  type: CLEAR_FEEDBACKS,
})

export type FeedbackAction =
  | ToggleFeedbackError
  | ToggleFeedbackEmphasis
  | SetFeedbackMessage
  | SetFeedbackErrorMessage
  | ToggleFeedbackRequestAnswer
  | UpdateFeedbackStars
  | UpdateFeedback
  | ClearFeedbacks
