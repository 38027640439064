import styled from '@emotion/styled'
import React from 'react'

import { getColor, theme } from '../../Theme'
import { Color } from '../../types'
import Circle from './Circle'

interface CircleHighlightProps {
  colors: Array<Color>
  backgrounds: Array<Color>
}

const CircleHighlight = styled(Circle)<CircleHighlightProps>`
  color: ${(p) => getColor(p.theme, [p.colors[0]], [p.colors[1]])};
  background-color: ${(p) => getColor(p.theme, [p.backgrounds[0]], [p.backgrounds[1]])};
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  position: relative;
`

const ArrowAdjust = styled.div<{ left: string }>`
  width: ${theme.spacing.sizes.normal};
  position: absolute;
  top: 9px;
  left: ${(p) => p.left || 'auto'};
`

type ToggleStyle = 'white' | 'blue' | 'green'

const colorMap = {
  white: {
    colors: ['grayDark', 'primaryBlue'],
    backgrounds: ['white', 'nightBlack'],
  },
  blue: {
    colors: ['primaryBlue', 'primaryBlue'],
    backgrounds: ['shallowBlue', 'nightSeparator'],
  },
  green: {
    colors: ['white', 'white'],
    backgrounds: ['primaryGreen', 'primaryGreen'],
  },
} as Record<ToggleStyle, { colors: Array<Color>; backgrounds: Array<Color> }>

type Props = {
  icon: JSX.Element
  left: string
  onClick: () => void
  color: ToggleStyle
}

function CircleToggle({ icon, onClick, left, color }: Props) {
  return (
    <CircleHighlight
      onClick={onClick}
      colors={colorMap[color].colors}
      backgrounds={colorMap[color].backgrounds}
    >
      <ArrowAdjust left={left}>{icon}</ArrowAdjust>
    </CircleHighlight>
  )
}

export default CircleToggle
