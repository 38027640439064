import { Checkbox, FormControlLabel, Input, Typography } from '@mui/material'
import { styled, SxProps } from '@mui/material/styles'
import { TFunction } from 'i18next'
import { useState } from 'react'
import { withTranslation } from 'react-i18next'

import { firstSuccess, getSessionFromDB } from '../../lib/data'
import { Session } from '../../types'
import Button from '../button/Button'

const PaddedButton = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing('small'),
}))

const Row = styled('div')(({ theme }) => ({
  ...theme.layout.flexRow,
  justifyContent: 'space-evenly',
  paddingTop: theme.spacing('small'),
  paddingBottom: theme.spacing('small'),
  borderBottom: '1px solid white',
}))

const CheckBox = styled(Checkbox)(({ checked, theme }) => ({
  '& .MuiSvgIcon-root': {
    fontSize: 22,
    border: 'none',
    fill: checked ? theme.palette.warning.main : theme.palette.common.white,
    transform: 'scale(1.6)',
  },
  '& .PrivateSwitchBase-input': {
    opacity: 1,
  },
  padding: 0,
}))

const checkBoxLabelGroup: SxProps = {
  '& input': { margin: '0' },
  padding: '0',
  margin: '0',
}

type Props = {
  t: TFunction
  onSubmit: (
    number: string,
    isCommuter: boolean,
    isMaintenance: boolean,
    isServiceDriver: boolean,
    session: Session
  ) => void
  isCommuterManager: boolean
}

const ActAs = (props: Props): JSX.Element => {
  const [number, setNumber] = useState('')
  const [isCommuter, setCommuter] = useState(!!props.isCommuterManager)
  const [isMaintenance, setMaintenance] = useState(false)

  const { t, onSubmit } = props

  const onSubmitHandler = async (): Promise<void> => {
    const session = await firstSuccess([getSessionFromDB()])
    onSubmit(number, isCommuter, isMaintenance, false, session)
  }

  return (
    <>
      <Input
        type="text"
        autoFocus
        placeholder={t('userNumber')}
        value={number}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            onSubmitHandler()
          }
        }}
        onChange={(e) => setNumber(e.target.value)}
        disableUnderline
        fullWidth
        sx={(theme) => ({
          '& input': { padding: 0 },
          fontSize: '15px',
          fontWeight: '400',
          boxSizing: 'border-box',
          py: theme.spacing('small'),
          px: theme.spacing('large'),
          color: theme.palette.common.black,
          background: 'field',
        })}
      />
      {!props.isCommuterManager && (
        <>
          <Row>
            <FormControlLabel
              id="commuter-checkbox"
              control={<CheckBox />}
              label={
                <Typography variant="adminBarLabel" sx={{ width: '200px', paddingRight: '40px' }}>
                  {t('commuterPersonnel')}
                </Typography>
              }
              disabled={props.isCommuterManager}
              labelPlacement="start"
              checked={isCommuter}
              onChange={() => {
                setCommuter(!isCommuter)
                if (!isCommuter) setMaintenance(false)
              }}
              sx={checkBoxLabelGroup}
            />
          </Row>
          <Row>
            <FormControlLabel
              id="maintenance-checkbox"
              control={<CheckBox />}
              label={
                <Typography variant="adminBarLabel" sx={{ width: '200px', paddingRight: '40px' }}>
                  {t('maintenancePersonnel')}
                </Typography>
              }
              labelPlacement="start"
              checked={isMaintenance}
              onChange={() => {
                setMaintenance(!isMaintenance)
                if (!isMaintenance) setCommuter(false)
              }}
              sx={checkBoxLabelGroup}
            />
          </Row>
        </>
      )}
      <PaddedButton>
        <Button topOpen={false} bottomOpen={false} onClick={onSubmitHandler}>
          {t('actAsPerson')}
        </Button>
      </PaddedButton>
    </>
  )
}

export default withTranslation()(ActAs)
