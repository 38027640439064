import styled from '@emotion/styled'
import React from 'react'

import { getColor, theme } from '../../Theme'

const Background = styled.div<{ border: boolean }>`
  width: 100%;
  background: ${(p) => getColor(p.theme, ['white'], ['nightBlack'])};
  border-bottom: ${(p) =>
    p.border ? `1px solid ${getColor(p.theme, ['grayBlue'], ['transparent'])}` : 'none'};
`

const Constrain = styled.div`
  ${theme.layout.flexRow};
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  position: relative;
  box-sizing: border-box;
`

type Props = {
  border: boolean
  children: JSX.Element
}

function HeaderContainer({ border, children }: Props) {
  return (
    <Background border={border}>
      <Constrain>{children}</Constrain>
    </Background>
  )
}
export default HeaderContainer
