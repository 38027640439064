import styled from '@emotion/styled'
import React from 'react'

import { getColor } from '../../Theme'

interface RelativeSliderProps {
  offline: boolean | null
  marginLeft?: string | null
}

interface SliderProps extends RelativeSliderProps {
  ready: boolean
}

const Relative = styled.div<RelativeSliderProps>`
  position: relative;
  display: inline-block;
  visibility: ${(p) => (p.offline ? 'hidden' : 'visible')};
  margin-left: ${(p) => p.marginLeft || 'none'};
`

const Slider = styled.div<SliderProps>`
  position: absolute;
  cursor: ${(p) => (p.offline ? '' : 'pointer')};
  top: -3px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(p) => getColor(p.theme, ['white'], ['nightBlack'])};
  -webkit-transition: 0.2s;
  transition: 0.2s;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  transform: ${(p) => p.ready && 'translateX(64%)'};
  box-shadow: 0 1px 2px 0 rgba(52, 64, 74, 0.5);
`

const Background = styled.div`
  width: 30px;
  height: 14px;
  background-color: ${(p) => getColor(p.theme, ['grayBackground'], ['grayDark'])};
  border-radius: 15px;
  box-shadow: inset 0 1px 2px 0 rgba(52, 64, 74, 0.25);
`

type Props = {
  ready: boolean
  offline: boolean | null
  marginLeft: string | null
}

const Switch = ({ ready, offline, marginLeft }: Props) => (
  <Relative offline={offline} marginLeft={marginLeft}>
    <Background />
    <Slider ready={ready} offline={offline} />
  </Relative>
)

export default Switch
