import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const Stopwatch = ({ iconSize, width }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={width ?? size} height={size} viewBox="0 0 64 64">
      <path
        d="M31.229 10.267c-14.832 0-26.856 12.023-26.856 26.857 0 14.83 12.024 26.855 26.856 26.855s26.855-12.025 26.855-26.855c0-14.834-12.023-26.857-26.855-26.857zm0 50.293c-12.945 0-23.438-10.493-23.438-23.436 0-12.945 10.492-23.438 23.438-23.438 12.941 0 23.438 10.492 23.438 23.438C54.666 50.066 44.17 60.56 31.229 60.56zM37.75.021H24.706a1.837 1.837 0 0 0-1.842 1.842v1.52a1.85 1.85 0 0 0 1.842 1.856h.645v1.615c.03.429.234 1.248 1.391 1.106h.003a30.524 30.524 0 0 1 8.794-.021l.016.001c1.292.15 1.519-.746 1.551-1.212v-1.49h.645a1.85 1.85 0 0 0 1.842-1.856v-1.52A1.838 1.838 0 0 0 37.75.021z"
        fill="currentColor"
      />
      <path
        d="M51.617 15.888s1.111 1.037 2.041-.293v-.001l1.117-1.599c.477-.688-.141-1.164-.203-1.211l-8.279-5.785c-.51-.354-.848-.139-1.027.061l-.146.213-1.213 1.734s-.775 1.192.293 1.743l.002.002a30.534 30.534 0 0 1 7.412 5.139l.003-.003zM50.344 35.278c-1.686-13.701-12.184-16.669-16.87-17.297-.009-.001-.021-.001-.03-.004-.072-.01-.135-.016-.203-.024-1.838-.308-2.003 1.087-2.012 1.517v15.719c.009.42.168 1.895 1.936 1.926 2.557-.007 7.709-.016 15.518.005 1.46-.016 1.667-1.162 1.661-1.842z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Stopwatch
