import styled from '@emotion/styled'
import { Link } from 'found'

import { getColor, theme } from '../Theme'

interface StyledLinkProps {
  disabled?: boolean
}
interface ExternalLinkProps extends StyledLinkProps {
  small?: boolean
}

export const ExternalLink = styled.a<ExternalLinkProps>`
  ${theme.text('small', 'decorative', 'bold')};
  text-decoration: none;
  text-transform: lowercase;
  color: ${(p) =>
    getColor(
      p.theme,
      [p.disabled ? 'grayLight' : 'primaryBlue'],
      [p.disabled ? 'grayDark' : 'primaryBlue']
    )};
  cursor: pointer;
`

const StyledLink = styled(Link)<StyledLinkProps>`
  ${theme.text('small', 'decorative', 'bold')};
  text-decoration: none;
  text-transform: uppercase;
  color: ${(p) =>
    getColor(
      p.theme,
      [p.disabled ? 'grayLight' : 'primaryBlue'],
      [p.disabled ? 'grayDark' : 'primaryBlue']
    )};
  cursor: pointer;
`

export default StyledLink
