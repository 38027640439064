import styled from '@emotion/styled'
import React from 'react'
import { connect } from 'react-redux'

import moment from '../../lib/moment-fi'
import { getSeasonFromMonth } from '../../lib/time'
import { color, px, theme } from '../../Theme'
import { AppState, Timestamp } from '../../types'
import Illustration from '../Illustration'

const Container = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  color: ${color('white')};
  margin-top: ${theme.spacing.sizes.large};
  margin-bottom: ${theme.spacing.sizes.large};
  height: ${px(96)};
  position: relative;
  overflow: hidden;
  border-radius: ${theme.borderRadius.button};
`

const Offset = styled.div`
  position: absolute;
  top: -145px;
  width: 100%;
  height: ${px(96)};
`

type Props = {
  children: React.JSX.Element
  season: string
  startsAt: string
}

const CalendarIllustration = ({ children, season }: Props) => (
  <Container>
    <Offset>
      <Illustration large={true} timeOfDay="day" season={season} transparentBg={true} />
    </Offset>
    {children}
  </Container>
)

const mapStateToProps = (
  state: AppState,
  {
    startsAt,
  }: {
    startsAt: Timestamp
  }
) => {
  const m = moment(startsAt)
  const month = m.month()
  const season = getSeasonFromMonth(month)

  return {
    season,
  }
}

export default connect(mapStateToProps)(CalendarIllustration)
