import { TowingSearch, TowingVehicle } from '../types/Input'

export type FetchTowingVehicle = {
  type: 'START_FETCHING_TOWING_VEHICLE'
}
export const START_FETCHING_TOWING_VEHICLE = 'START_FETCHING_TOWING_VEHICLE'
export const startFetchingTowingVehicle = (): FetchTowingVehicle => ({
  type: START_FETCHING_TOWING_VEHICLE,
})

export type UpdateTowingVehicle = {
  type: 'UPDATE_TOWING_VEHICLE'
  payload: TowingVehicle
}
export const UPDATE_TOWING_VEHICLE = 'UPDATE_TOWING_VEHICLE'
export const updateTowingVehicle = (payload: TowingVehicle): UpdateTowingVehicle => ({
  type: UPDATE_TOWING_VEHICLE,
  payload,
})

export type UpdateTowingVehicles = {
  type: 'UPDATE_TOWING_VEHICLES'
  payload: Array<TowingVehicle>
}
export const UPDATE_TOWING_VEHICLES = 'UPDATE_TOWING_VEHICLES'
export const updateTowingVehicles = (payload: Array<TowingVehicle>): UpdateTowingVehicles => ({
  type: UPDATE_TOWING_VEHICLES,
  payload,
})

export type TowingError = {
  type: 'TOWING_ERROR'
  payload: string
}
export const TOWING_ERROR = 'TOWING_ERROR'
export const towingError = (payload: string): TowingError => ({
  type: TOWING_ERROR,
  payload,
})

export type ClearTowingVehicle = {
  type: 'CLEAR_TOWING_VEHICLE'
}
export const CLEAR_TOWING_VEHICLE = 'CLEAR_TOWING_VEHICLE'
export const clearTowingVehicle = (): ClearTowingVehicle => ({
  type: CLEAR_TOWING_VEHICLE,
})

export type RefetchTowingVehicle = {
  type: 'REFETCH_TOWING_VEHICLE'
  payload: TowingVehicle
}
export const REFETCH_TOWING_VEHICLE = 'REFETCH_TOWING_VEHICLE'
export const refetchTowingVehicle = (payload: TowingVehicle): RefetchTowingVehicle => ({
  type: REFETCH_TOWING_VEHICLE,
  payload,
})

export type UpdateLastSearched = {
  type: 'UPDATE_LAST_SEARCHED'
  payload: TowingSearch
}
export const UPDATE_LAST_SEARCHED = 'UPDATE_LAST_SEARCHED'
export const updateLastSearched = (payload: TowingSearch): UpdateLastSearched => ({
  type: UPDATE_LAST_SEARCHED,
  payload,
})

export type ClearLastSearched = {
  type: 'CLEAR_LAST_SEARCHED'
}
export const CLEAR_LAST_SEARCHED = 'CLEAR_LAST_SEARCHED'
export const clearLastSearched = (): ClearLastSearched => ({
  type: CLEAR_LAST_SEARCHED,
})

export type TowingAction =
  | FetchTowingVehicle
  | UpdateTowingVehicle
  | UpdateTowingVehicles
  | TowingError
  | ClearTowingVehicle
  | RefetchTowingVehicle
  | UpdateLastSearched
  | ClearLastSearched
