import { Color, MaybeColor } from '../Theme'

export type Responsible = {
  code: string
  responsible: Color
}

export const responsibles: Array<Responsible> = [
  {
    code: 'H',
    responsible: 'VR',
  },
  {
    code: 'J',
    responsible: 'VR',
  },
  {
    code: 'K',
    responsible: 'VR',
  },
  {
    code: 'V',
    responsible: 'VR',
  },
  {
    code: 'A',
    responsible: 'VR',
  },
  {
    code: 'E',
    responsible: 'VR',
  },
  {
    code: 'L',
    responsible: 'Traffic',
  },
  {
    code: 'P',
    responsible: 'Väylä',
  },
  {
    code: 'S',
    responsible: 'Väylä',
  },
  {
    code: 'T',
    responsible: 'Väylä',
  },
  {
    code: 'R',
    responsible: 'Väylä',
  },
  {
    code: 'O',
    responsible: 'Outside',
  },
  {
    code: 'M',
    responsible: 'Outside',
  },
  {
    code: 'I',
    responsible: 'Outside',
  },
  {
    code: 'H1',
    responsible: 'VR',
  },
  {
    code: 'H2',
    responsible: 'VR',
  },
  {
    code: 'H3',
    responsible: 'VR',
  },
  {
    code: 'J1',
    responsible: 'VR',
  },
  {
    code: 'K1',
    responsible: 'VR',
  },
  {
    code: 'K2',
    responsible: 'VR',
  },
  {
    code: 'K3',
    responsible: 'VR',
  },
  {
    code: 'K4',
    responsible: 'VR',
  },
  {
    code: 'K5',
    responsible: 'VR',
  },
  {
    code: 'K6',
    responsible: 'VR',
  },
  {
    code: 'V1',
    responsible: 'VR',
  },
  {
    code: 'V2',
    responsible: 'VR',
  },
  {
    code: 'V3',
    responsible: 'VR',
  },
  {
    code: 'V4',
    responsible: 'VR',
  },
  {
    code: 'A1',
    responsible: 'VR',
  },
  {
    code: 'A2',
    responsible: 'VR',
  },
  {
    code: 'A3',
    responsible: 'VR',
  },
  {
    code: 'E1',
    responsible: 'VR',
  },
  {
    code: 'E2',
    responsible: 'VR',
  },
  {
    code: 'L1',
    responsible: 'Traffic',
  },
  {
    code: 'L2',
    responsible: 'Traffic',
  },
  {
    code: 'L3',
    responsible: 'Traffic',
  },
  {
    code: 'L4',
    responsible: 'Outside',
  },
  {
    code: 'L5',
    responsible: 'Traffic',
  },
  {
    code: 'L6',
    responsible: 'Traffic',
  },
  {
    code: 'L7',
    responsible: 'Finrail',
  },
  {
    code: 'L8',
    responsible: 'Finrail',
  },
  {
    code: 'P1',
    responsible: 'Väylä',
  },
  {
    code: 'P2',
    responsible: 'VR',
  },
  {
    code: 'P3',
    responsible: 'Väylä',
  },
  {
    code: 'P4',
    responsible: 'Finrail',
  },
  {
    code: 'S1',
    responsible: 'Väylä',
  },
  {
    code: 'S2',
    responsible: 'Väylä',
  },
  {
    code: 'T1',
    responsible: 'Väylä',
  },
  {
    code: 'T2',
    responsible: 'Väylä',
  },
  {
    code: 'T3',
    responsible: 'Väylä',
  },
  {
    code: 'R1',
    responsible: 'Väylä',
  },
  {
    code: 'R2',
    responsible: 'Väylä',
  },
  {
    code: 'R3',
    responsible: 'Väylä',
  },
  {
    code: 'R4',
    responsible: 'Väylä',
  },
  {
    code: 'O1',
    responsible: 'Outside',
  },
  {
    code: 'O2',
    responsible: 'Outside',
  },
  {
    code: 'O3',
    responsible: 'Outside',
  },
  {
    code: 'O4',
    responsible: 'Outside',
  },
  {
    code: 'M1',
    responsible: 'Outside',
  },
  {
    code: 'M2',
    responsible: 'Outside',
  },
  {
    code: 'I1',
    responsible: 'Outside',
  },
  {
    code: 'I2',
    responsible: 'Outside',
  },
  {
    code: 'I3',
    responsible: 'Outside',
  },
  {
    code: 'I4',
    responsible: 'Outside',
  },
  {
    code: 'H101',
    responsible: 'VR',
  },
  {
    code: 'H102',
    responsible: 'VR',
  },
  {
    code: 'H103',
    responsible: 'VR',
  },
  {
    code: 'H104',
    responsible: 'VR',
  },
  {
    code: 'H105',
    responsible: 'VR',
  },
  {
    code: 'H106',
    responsible: 'VR',
  },
  {
    code: 'H301',
    responsible: 'VR',
  },
  {
    code: 'H302',
    responsible: 'Outside',
  },
  {
    code: 'J101',
    responsible: 'VR',
  },
  {
    code: 'J102',
    responsible: 'VR',
  },
  {
    code: 'J103',
    responsible: 'VR',
  },
  {
    code: 'J104',
    responsible: 'VR',
  },
  {
    code: 'J105',
    responsible: 'VR',
  },
  {
    code: 'J106',
    responsible: 'VR',
  },
  {
    code: 'J107',
    responsible: 'VR',
  },
  {
    code: 'J108',
    responsible: 'VR',
  },
  {
    code: 'J109',
    responsible: 'VR',
  },
  {
    code: 'K101',
    responsible: 'VR',
  },
  {
    code: 'K102',
    responsible: 'VR',
  },
  {
    code: 'K201',
    responsible: 'VR',
  },
  {
    code: 'K202',
    responsible: 'VR',
  },
  {
    code: 'K203',
    responsible: 'VR',
  },
  {
    code: 'K204',
    responsible: 'VR',
  },
  {
    code: 'K205',
    responsible: 'VR',
  },
  {
    code: 'K206',
    responsible: 'VR',
  },
  {
    code: 'K207',
    responsible: 'VR',
  },
  {
    code: 'K208',
    responsible: 'VR',
  },
  {
    code: 'K209',
    responsible: 'VR',
  },
  {
    code: 'K210',
    responsible: 'VR',
  },
  {
    code: 'K211',
    responsible: 'VR',
  },
  {
    code: 'K212',
    responsible: 'VR',
  },
  {
    code: 'K213',
    responsible: 'VR',
  },
  {
    code: 'K301',
    responsible: 'VR',
  },
  {
    code: 'K302',
    responsible: 'VR',
  },
  {
    code: 'K303',
    responsible: 'VR',
  },
  {
    code: 'K304',
    responsible: 'VR',
  },
  {
    code: 'K305',
    responsible: 'VR',
  },
  {
    code: 'K306',
    responsible: 'VR',
  },
  {
    code: 'K401',
    responsible: 'VR',
  },
  {
    code: 'K402',
    responsible: 'VR',
  },
  {
    code: 'K403',
    responsible: 'VR',
  },
  {
    code: 'K501',
    responsible: 'VR',
  },
  {
    code: 'K502',
    responsible: 'VR',
  },
  {
    code: 'V101',
    responsible: 'VR',
  },
  {
    code: 'V102',
    responsible: 'VR',
  },
  {
    code: 'V201',
    responsible: 'VR',
  },
  {
    code: 'V202',
    responsible: 'VR',
  },
  {
    code: 'V203',
    responsible: 'VR',
  },
  {
    code: 'V204',
    responsible: 'VR',
  },
  {
    code: 'V205',
    responsible: 'VR',
  },
  {
    code: 'V206',
    responsible: 'VR',
  },
  {
    code: 'V207',
    responsible: 'VR',
  },
  {
    code: 'V208',
    responsible: 'VR',
  },
  {
    code: 'V209',
    responsible: 'VR',
  },
  {
    code: 'V301',
    responsible: 'VR',
  },
  {
    code: 'V302',
    responsible: 'VR',
  },
  {
    code: 'V303',
    responsible: 'VR',
  },
  {
    code: 'V304',
    responsible: 'VR',
  },
  {
    code: 'V305',
    responsible: 'VR',
  },
  {
    code: 'V306',
    responsible: 'VR',
  },
  {
    code: 'A101',
    responsible: 'VR',
  },
  {
    code: 'A102',
    responsible: 'VR',
  },
  {
    code: 'A103',
    responsible: 'VR',
  },
  {
    code: 'A104',
    responsible: 'VR',
  },
  {
    code: 'A105',
    responsible: 'VR',
  },
  {
    code: 'A106',
    responsible: 'VR',
  },
  {
    code: 'A201',
    responsible: 'VR',
  },
  {
    code: 'A202',
    responsible: 'VR',
  },
  {
    code: 'A203',
    responsible: 'VR',
  },
  {
    code: 'A204',
    responsible: 'VR',
  },
  {
    code: 'A205',
    responsible: 'VR',
  },
  {
    code: 'E101',
    responsible: 'VR',
  },
  {
    code: 'E102',
    responsible: 'VR',
  },
  {
    code: 'E103',
    responsible: 'VR',
  },
  {
    code: 'E104',
    responsible: 'Finrail',
  },
  {
    code: 'E105',
    responsible: 'VR',
  },
  {
    code: 'E201',
    responsible: 'VR',
  },
  {
    code: 'E202',
    responsible: 'VR',
  },
  {
    code: 'E203',
    responsible: 'VR',
  },
  {
    code: 'E204',
    responsible: 'VR',
  },
  {
    code: 'L101',
    responsible: 'Traffic',
  },
  {
    code: 'L102',
    responsible: 'Traffic',
  },
  {
    code: 'L201',
    responsible: 'Traffic',
  },
  {
    code: 'L202',
    responsible: 'Traffic',
  },
  {
    code: 'L203',
    responsible: 'Traffic',
  },
  {
    code: 'L204',
    responsible: 'Traffic',
  },
  {
    code: 'L301',
    responsible: 'Traffic',
  },
  {
    code: 'L302',
    responsible: 'Traffic',
  },
  {
    code: 'L303',
    responsible: 'Traffic',
  },
  {
    code: 'L304',
    responsible: 'Traffic',
  },
  {
    code: 'L501',
    responsible: 'Traffic',
  },
  {
    code: 'L502',
    responsible: 'Traffic',
  },
  {
    code: 'L503',
    responsible: 'Traffic',
  },
  {
    code: 'L504',
    responsible: 'Traffic',
  },
  {
    code: 'L601',
    responsible: 'Traffic',
  },
  {
    code: 'L602',
    responsible: 'Traffic',
  },
  {
    code: 'L603',
    responsible: 'Traffic',
  },
  {
    code: 'L604',
    responsible: 'Traffic',
  },
  {
    code: 'L605',
    responsible: 'Traffic',
  },
  {
    code: 'L606',
    responsible: 'Traffic',
  },
  {
    code: 'L607',
    responsible: 'Traffic',
  },
  {
    code: 'L608',
    responsible: 'Traffic',
  },
  {
    code: 'L701',
    responsible: 'Finrail',
  },
  {
    code: 'L702',
    responsible: 'Finrail',
  },
  {
    code: 'L703',
    responsible: 'Finrail',
  },
  {
    code: 'L704',
    responsible: 'Finrail',
  },
  {
    code: 'L705',
    responsible: 'Finrail',
  },
  {
    code: 'P101',
    responsible: 'Väylä',
  },
  {
    code: 'P102',
    responsible: 'Väylä',
  },
  {
    code: 'P103',
    responsible: 'Väylä',
  },
  {
    code: 'P104',
    responsible: 'Väylä',
  },
  {
    code: 'P105',
    responsible: 'Väylä',
  },
  {
    code: 'P106',
    responsible: 'Väylä',
  },
  {
    code: 'P107',
    responsible: 'Väylä',
  },
  {
    code: 'P108',
    responsible: 'Väylä',
  },
  {
    code: 'P109',
    responsible: 'Väylä',
  },
  {
    code: 'P110',
    responsible: 'Väylä',
  },
  {
    code: 'P111',
    responsible: 'Väylä',
  },
  {
    code: 'P112',
    responsible: 'Väylä',
  },
  {
    code: 'P113',
    responsible: 'Väylä',
  },
  {
    code: 'P114',
    responsible: 'Väylä',
  },
  {
    code: 'P115',
    responsible: 'Väylä',
  },
  {
    code: 'P116',
    responsible: 'VR',
  },
  {
    code: 'P117',
    responsible: 'Väylä',
  },
  {
    code: 'P201',
    responsible: 'VR',
  },
  {
    code: 'P202',
    responsible: 'VR',
  },
  {
    code: 'P203',
    responsible: 'VR',
  },
  {
    code: 'P204',
    responsible: 'Outside',
  },
  {
    code: 'P205',
    responsible: 'Finrail',
  },
  {
    code: 'P206',
    responsible: 'Finrail',
  },
  {
    code: 'P207',
    responsible: 'Finrail',
  },
  {
    code: 'P208',
    responsible: 'Finrail',
  },
  {
    code: 'P209',
    responsible: 'Finrail',
  },
  {
    code: 'P210',
    responsible: 'Finrail',
  },
  {
    code: 'P211',
    responsible: 'Finrail',
  },
  {
    code: 'P212',
    responsible: 'Finrail',
  },
  {
    code: 'P213',
    responsible: 'Finrail',
  },
  {
    code: 'P214',
    responsible: 'Finrail',
  },
  {
    code: 'P215',
    responsible: 'Finrail',
  },
  {
    code: 'P216',
    responsible: 'Finrail',
  },
  {
    code: 'P217',
    responsible: 'Finrail',
  },
  {
    code: 'P301',
    responsible: 'Väylä',
  },
  {
    code: 'P302',
    responsible: 'Väylä',
  },
  {
    code: 'P303',
    responsible: 'Väylä',
  },
  {
    code: 'P304',
    responsible: 'Väylä',
  },
  {
    code: 'P401',
    responsible: 'Finrail',
  },
  {
    code: 'P402',
    responsible: 'Finrail',
  },
  {
    code: 'P403',
    responsible: 'Finrail',
  },
  {
    code: 'S101',
    responsible: 'Väylä',
  },
  {
    code: 'S102',
    responsible: 'Väylä',
  },
  {
    code: 'S103',
    responsible: 'Outside',
  },
  {
    code: 'S104',
    responsible: 'Väylä',
  },
  {
    code: 'S201',
    responsible: 'Väylä',
  },
  {
    code: 'S202',
    responsible: 'Väylä',
  },
  {
    code: 'S203',
    responsible: 'Väylä',
  },
  {
    code: 'S204',
    responsible: 'Väylä',
  },
  {
    code: 'S205',
    responsible: 'Väylä',
  },
  {
    code: 'S206',
    responsible: 'Väylä',
  },
  {
    code: 'T101',
    responsible: 'Väylä',
  },
  {
    code: 'T102',
    responsible: 'Väylä',
  },
  {
    code: 'T301',
    responsible: 'Väylä',
  },
  {
    code: 'T302',
    responsible: 'Väylä',
  },
  {
    code: 'T303',
    responsible: 'Väylä',
  },
  {
    code: 'R201',
    responsible: 'Väylä',
  },
  {
    code: 'R202',
    responsible: 'Väylä',
  },
  {
    code: 'R203',
    responsible: 'Väylä',
  },
  {
    code: 'R204',
    responsible: 'Väylä',
  },
  {
    code: 'R205',
    responsible: 'Väylä',
  },
  {
    code: 'R206',
    responsible: 'Väylä',
  },
  {
    code: 'O401',
    responsible: 'Outside',
  },
  {
    code: 'O402',
    responsible: 'Outside',
  },
  {
    code: 'O403',
    responsible: 'Outside',
  },
  {
    code: 'O404',
    responsible: 'Outside',
  },
  {
    code: 'O405',
    responsible: 'Outside',
  },
  {
    code: 'M101',
    responsible: 'Outside',
  },
  {
    code: 'M102',
    responsible: 'Outside',
  },
  {
    code: 'M103',
    responsible: 'Outside',
  },
  {
    code: 'M104',
    responsible: 'Outside',
  },
  {
    code: 'M105',
    responsible: 'Outside',
  },
  {
    code: 'M106',
    responsible: 'Outside',
  },
  {
    code: 'M107',
    responsible: 'Outside',
  },
  {
    code: 'M108',
    responsible: 'Outside',
  },
  {
    code: 'M109',
    responsible: 'Outside',
  },
  {
    code: 'M110',
    responsible: 'Outside',
  },
  {
    code: 'I301',
    responsible: 'Outside',
  },
  {
    code: 'I302',
    responsible: 'Outside',
  },
  {
    code: 'I303',
    responsible: 'Outside',
  },
  {
    //special code used for ellipsis labels
    code: '...',
    responsible: 'Kentta',
  },
]

export const getResponsibleColor = (code: string): MaybeColor => {
  let responsibleObject
  //if the given code yields no object, default to higher level code responsibles
  while (!responsibleObject && code.length > 1) {
    // eslint-disable-next-line no-loop-func
    responsibleObject = responsibles.find((r) => r.code === code)
    if (code.length === 4) code = code.slice(0, 2)
    else if (code.length === 2) code = code.slice(0, 1)
    else break
  }
  return responsibleObject ? responsibleObject.responsible : ''
}
