import React from 'react'

import ArrowRight from '../../icons/ArrowRight'
import { getColor } from '../../Theme'
import { CauseButton } from './Common'

type CauseSelectorProps = {
  nightMode: boolean
  onClick: () => void
  code?: string | null
  description?: string
}

export const CauseSelector = ({ nightMode, onClick, code, description }: CauseSelectorProps) => (
  <CauseButton key={code} onClick={onClick}>
    {code}
    {code && description && ' - '}
    {description}
    <ArrowRight
      iconSize="normal"
      stroke={getColor({ nightMode }, ['primaryGreen'], ['primaryGreen'])}
    />
  </CauseButton>
)
