import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const ArrowLeft = ({ iconSize, strokeWidth = 3, stroke = 'currentColor' }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <polyline points="17 1 7 12 17 23" stroke={stroke} strokeWidth={strokeWidth} />
      </g>
    </svg>
  )
}

export default ArrowLeft
