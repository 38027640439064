import { PaletteOptions } from '@mui/material'

import colors from './MuiColors'

declare module '@mui/material/styles' {
  interface Palette {
    common: CommonColors
    info: PaletteColor
  }

  interface TypeBackground {
    secondary?: string
    altSecondary?: string
    border?: string
    field?: string
    altField?: string
  }

  interface SimplePaletteColorOptions {
    lighter?: string
    text?: string
  }

  interface CommonColors {
    kenttaBlue: string
    primaryGreen: string
  }

  interface PaletteColor {
    lighter: string
    text: string
  }

  interface Palette {
    cardTitle: string
    checkBox: string
    infoHighlight: string
    primaryHighlight: string
    infoHighlightText: string
    primaryHighlightText: string
    fieldBorderGrey: string
    attachmentBackground: string
    attachmentBorder: string
  }

  interface PaletteOptions {
    cardTitle?: string
    checkBox?: string
    infoHighlight?: string
    primaryHighlight?: string
    infoHighlightText?: string
    primaryHighlightText?: string
    fieldBorderGrey?: string
    attachmentBackground?: string
    attachmentBorder?: string
    pageTitle?: string
    inputBorder?: string
    network?: {
      background: string
      color: string
    }
  }
}

export const commonPalette: PaletteOptions = {
  common: colors.common,
  grey: colors.grey,
  warning: colors.warning,
  network: colors.network,
}

export const darkPalette: PaletteOptions = {
  primary: {
    ...colors.primary,
    lighter: colors.backgroundShades[4],
  },
  secondary: {
    main: colors.common.black,
  },
  info: { ...colors.info, text: colors.info.light },
  text: {
    primary: colors.grey[200],
    secondary: colors.grey[300],
  },
  background: {
    default: colors.backgroundShades[4],
    paper: colors.backgroundShades[6],
    secondary: colors.backgroundShades[5],
    altSecondary: colors.backgroundShades[6],
    field: colors.backgroundShades[8],
    altField: colors.backgroundShades[9],
    border: colors.backgroundShades[5],
  },
  cardTitle: colors.primary.light,
  checkBox: colors.common.white,
  infoHighlight: colors.info.main,
  primaryHighlight: colors.primary.main,
  infoHighlightText: colors.common.white,
  primaryHighlightText: colors.common.white,
  fieldBorderGrey: colors.grey[500],
  attachmentBackground: colors.common.black,
  attachmentBorder: colors.backgroundShades[3],
  pageTitle: colors.grey[200],
  inputBorder: colors.grey[600],
}

export const lightPalette: PaletteOptions = {
  primary: {
    ...colors.primary,
    main: colors.common.primaryGreen,
  },
  secondary: {
    main: colors.common.white,
  },
  info: { ...colors.info, text: colors.info.main },
  text: {
    primary: colors.common.black,
    secondary: colors.backgroundShades[3],
  },
  background: {
    default: colors.common.white,
    paper: colors.common.white,
    secondary: colors.common.white,
    altSecondary: colors.common.white,
    border: colors.backgroundShades[2],
    field: colors.backgroundShades[1],
    altField: colors.common.white,
  },
  cardTitle: colors.primary.dark,
  checkBox: colors.info.main,
  infoHighlight: colors.info.lighter,
  primaryHighlight: colors.primary.lighter,
  infoHighlightText: colors.info.main,
  primaryHighlightText: colors.primary.dark,
  fieldBorderGrey: colors.grey[200],
  attachmentBackground: colors.grey[50],
  attachmentBorder: colors.backgroundShades[7],
  pageTitle: colors.grey[600],
  inputBorder: colors.grey[100],
}
