import React from 'react'

import ArrowLeft from '../../icons/ArrowLeft'
import ArrowRight from '../../icons/ArrowRight'
import Close from '../../icons/Close'
import HeaderContainer from './HeaderContainer'
import HeaderLink from './HeaderLink'
import HeaderTitle from './HeaderTitle'

type Links = {
  url: string
  disabled: boolean
}

type Props = {
  title: string
  children: JSX.Element | string
  border: boolean
  links: {
    prev: Links | null
    next: Links | null
    close: Links | null
  }
}

function Header({ children, title, links, border }: Props) {
  return (
    <HeaderContainer border={border}>
      <>
        {links.prev ? (
          <HeaderLink disabled={links.prev.disabled} to={links.prev.url}>
            <ArrowLeft iconSize="normal" />
          </HeaderLink>
        ) : undefined}
        {links.next ? (
          <HeaderLink disabled={links.next.disabled} to={links.next.url}>
            <ArrowRight iconSize="normal" />
          </HeaderLink>
        ) : undefined}
        <HeaderTitle title={title} subtitle={children} />
        {links.close ? (
          <HeaderLink disabled={links.close.disabled} to={links.close.url}>
            <Close iconSize="normal" />
          </HeaderLink>
        ) : undefined}
      </>
    </HeaderContainer>
  )
}
export default Header
