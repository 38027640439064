import { Router } from 'found'
import { TFunction } from 'i18next'
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { fetchTimetable } from '../../actions/api'
import { checkInModal, openModal } from '../../actions/timetable'
import Download from '../../icons/Download'
import { isMobileSelector, shiftTasksToTimetableParams } from '../../Selectors'
import { Action, AppState, Dispatch, Shift } from '../../types'
import { CheckedModal as ModalType, TimetableParams } from '../../types/Input'
import LoadingIndicator from '../LoadingIndicator'
import TaskButton from '../task/TaskButton'
import UsedForDrivingModal from './UsedForDrivingModal'

type Props = {
  router?: Router
  fetchTimetable: (isUsedForDriving: boolean, parts: Array<TimetableParams>) => void
  isCommuter?: boolean
  isMobile: boolean
  timetableParams: Array<TimetableParams>
  loading?: boolean
  modal: ModalType
  openModal: (isOpen: boolean, usedTimetableParams: Array<TimetableParams>) => void
  checkInModal: (checked: boolean) => void
  allowTimetableFetch: boolean
}

const TimetableButton = ({
  timetableParams,
  fetchTimetable,
  isCommuter,
  loading,
  modal,
  openModal,
  checkInModal,
  isMobile,
  allowTimetableFetch,
}: Props) => {
  if (!timetableParams || timetableParams.length === 0) {
    return null
  }

  return (
    <Fragment>
      <UsedForDrivingModal
        modal={modal}
        fetchTimetable={fetchTimetable}
        openModal={openModal}
        timetableParams={timetableParams}
        checkInModal={checkInModal}
        isMobile={isMobile}
      />
      {allowTimetableFetch && (
        <TaskButton
          timetableMode
          isCommuter={isCommuter}
          onClick={() => openModal(true, timetableParams)}
        >
          {loading ? (
            <LoadingIndicator size="small" padded={false} />
          ) : (
            <Download iconSize="smallest" />
          )}
        </TaskButton>
      )}
    </Fragment>
  )
}

type PropsIn = {
  t: TFunction
  shift?: Shift
  timetableParams: TimetableParams
}

const mapStateToProps = (state: AppState, { shift, timetableParams }: PropsIn) => {
  const isMobile = isMobileSelector(state)

  const params = shift
    ? shiftTasksToTimetableParams(shift)
    : timetableParams
    ? [timetableParams]
    : []
  const paramsFromState = state.timetable.usedTimetableParams

  return {
    isMobile,
    timetableParams: paramsFromState && paramsFromState.length > 0 ? paramsFromState : params,
    loading: state.timetable.loading,
    modal: state.timetable.modal,
    allowTimetableFetch:
      state.user.admin ||
      state.user.commuter_driver ||
      state.user.commuter_manager ||
      state.user.driver ||
      state.user.logistics_driver ||
      state.user.conductor ||
      state.user.other,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTimetable: (isUsedForDriving: boolean, parts: TimetableParams[]) =>
    dispatch(fetchTimetable(isUsedForDriving, parts) as unknown as Action),
  openModal: (isOpen: boolean, usedTimetableParams: TimetableParams[]) =>
    dispatch(openModal({ isOpen, usedTimetableParams })),
  checkInModal: (checked: boolean) => dispatch(checkInModal(checked)),
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TimetableButton))
