import styled from '@emotion/styled'

import { color, getColor } from '../../Theme'

interface InputProps {
  fontSize?: string
  disabled?: boolean
}

const SearchInput = styled.input<InputProps>`
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  outline: none;
  background-color: ${(p) =>
    p.disabled
      ? getColor(p.theme, ['grayLight'], ['nightGray'])
      : getColor(p.theme, ['white'], ['black'])};
  color: ${(p) => (p.disabled ? color('grayLight') : getColor(p.theme, ['black'], ['grayBlue']))};
  border: none;
  padding: 8px;
  -moz-appearance: textfield;
  width: 80%;
  font-size: ${(p) => (p.fontSize ? p.fontSize : '3')};
`

export default SearchInput
