import { TFunction } from 'i18next'
import { Fragment, SetStateAction } from 'react'

import { getPunctualityStationName } from '../../lib/stations'
import { FlattenedCause } from '../../types/Input'
import { CauseSelector } from './CauseSelector'
import {
  CauseDescription,
  CauseSelectionTitle,
  getTypedTranslationKey,
  STATE_SELECT_GROUP,
  STATE_SELECT_LEVEL1,
  STATE_SELECT_LEVEL2,
} from './Common'

type OverviewProps = {
  t: TFunction
  nightMode: boolean
  delayMinutes: number
  depOcp: string
  arrOcp: string
  causeCode: string
  depOrArr: string
  currentCauseGroup: FlattenedCause
  currentFirstLevelCause: FlattenedCause | null
  currentSecondLevelCause?: FlattenedCause | null
  setDisplayState: (setDisplayState: SetStateAction<string>) => void
  setCauseGroup: (currentCauseGroupCode?: string | null) => void
  setFirstLevel: (firstLevelKey?: string | null) => void
  scheduledTime: string
  actualTime: string
  amendable?: boolean
}

const Overview = ({
  t,
  nightMode,
  delayMinutes,
  depOcp,
  arrOcp,
  causeCode,
  currentCauseGroup,
  depOrArr,
  currentFirstLevelCause,
  currentSecondLevelCause,
  setDisplayState,
  setCauseGroup,
  setFirstLevel,
  scheduledTime,
  actualTime,
  amendable,
}: OverviewProps) => {
  return (
    <div>
      <CauseDescription>
        {t(getTypedTranslationKey('scheduledTime', depOrArr))}: {scheduledTime}
        <br />
        {t(getTypedTranslationKey('actualTime', depOrArr))}: {actualTime}
      </CauseDescription>
      <CauseDescription>
        {t(
          getTypedTranslationKey(
            delayMinutes > 0 ? 'delayDescription' : 'earlyDescription',
            depOrArr
          ),
          {
            delay: delayMinutes,
            departure: getPunctualityStationName(depOcp),
            arrival: arrOcp ? getPunctualityStationName(arrOcp) : '',
          }
        )}
        : <b>{causeCode}</b>
      </CauseDescription>
      <CauseDescription>{t('deviationAmendment.prompt')}</CauseDescription>
      <CauseSelectionTitle>{t('deviationAmendment.challenge')}</CauseSelectionTitle>
      <CauseSelector
        onClick={() => setDisplayState(STATE_SELECT_GROUP)}
        code={currentCauseGroup.code}
        description={currentCauseGroup.description}
        nightMode={nightMode}
      />
      {!currentFirstLevelCause && amendable && (
        <Fragment>
          <CauseSelectionTitle>{t('deviationAmendment.amend')}</CauseSelectionTitle>
          <CauseSelector
            onClick={() => {
              setCauseGroup(currentCauseGroup.code)
              setDisplayState(STATE_SELECT_LEVEL1)
            }}
            description={t('deviationAmendment.amendPrompt')}
            nightMode={nightMode}
          />
        </Fragment>
      )}
      {currentFirstLevelCause && (
        <Fragment>
          <CauseSelector
            onClick={() => {
              setCauseGroup(currentCauseGroup.code)
              setDisplayState(STATE_SELECT_LEVEL1)
            }}
            code={currentFirstLevelCause.code}
            description={currentFirstLevelCause.description}
            nightMode={nightMode}
          />
          {currentSecondLevelCause && (
            <CauseSelector
              onClick={() => {
                setCauseGroup(currentCauseGroup.code)
                setFirstLevel((currentFirstLevelCause && currentFirstLevelCause.code) || null)
                setDisplayState(STATE_SELECT_LEVEL2)
              }}
              code={currentSecondLevelCause.code}
              description={currentSecondLevelCause.description}
              nightMode={nightMode}
            />
          )}
          {!currentSecondLevelCause && currentFirstLevelCause.hasSubCodes && amendable && (
            <Fragment>
              <CauseSelectionTitle>{t('deviationAmendment.amend')}</CauseSelectionTitle>
              <CauseSelector
                onClick={() => {
                  setCauseGroup(currentCauseGroup.code)
                  setFirstLevel(currentFirstLevelCause && currentFirstLevelCause.code)
                  setDisplayState(STATE_SELECT_LEVEL2)
                }}
                description={t('deviationAmendment.amendPrompt')}
                nightMode={nightMode}
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default Overview
