import { Tree } from '../components/create-defect/types'

/** This function takes in a list of nodes, a root node, and two keys id and parentId.
 * The keys are used to traverse the list of nodes and create a tree.
 * The tree is then returned.
 *
 * ### Notes:
 * - Takes extra memory to create a root level node for all items in the list.
 * - Creates extra nodes for parenIds incase some nodes are missing.
 *   - These are discarded as they are not children of the root node.
 *   - Should be handled some other way. Currently API can produce non-existent parentIds.
 * @param nodes - List of nodes to be converted to a tree.
 * @param rootNode - Root node of the tree.
 * @param idKey - Key of the id of the node.
 * @param parentIdKey - Key of the parentId of the node.
 * @returns A tree, with the root node as the root of the tree and a node's children in the children array.
 * @example
 * const nodes = [
 *  { id: 2, parentId: 1, name: 'child' },
 *  { id: 3, parentId: 2, name: 'grandchild' },
 *  { id: 33, parentId: 2, name: 'grandchild2' },
 *  { id: 4, parentId: 3, name: 'greatgrandchild' },
 *  { id: 5, parentId: 4, name: 'greatgreatgrandchild' },
 *  { id: 'a', parentId: 'b', name: 'should not be included' },
 * ]
 * const rootNode = { id: 1, parentId: 0, name: 'root' }
 * createTree(nodes, rootNode, 'id', 'parentId')
 * // returns
 * {
 *   id: 1,
 *   parentId: 0,
 *   name: 'root',
 *   children: [
 *     {
 *       id: 2,
 *       parentId: 1,
 *       name: 'child',
 *       children: [
 *         {
 *           id: 33,
 *           parentId: 2,
 *           name: 'grandchild2',
 *           children: [],
 *         },
 *         {
 *           id: 3,
 *           parentId: 2,
 *           name: 'grandchild',
 *           children: [
 *             {
 *               id: 4,
 *               parentId: 3,
 *               name: 'greatgrandchild',
 *               children: [
 *                 {
 *                   id: 5,
 *                   parentId: 4,
 *                   name: 'greatgreatgrandchild',
 *                   children: [],
 *                 },
 *               ],
 *             },
 *           ],
 *         },
 *       ],
 *     },
 *   ],
 * }

 */
const createTree = <T, K1 extends keyof T, K2 extends keyof Omit<T, K1>>(
  nodes: T[],
  rootNode: T,
  idKey: K1,
  parentIdKey: K2
): Tree<T> => {
  const tree = Object.create(null)
  nodes.forEach((item) => (tree[item[parentIdKey]] = { [idKey]: item[parentIdKey], children: [] }))
  tree[rootNode[idKey]] = { ...rootNode, children: [] }
  nodes.forEach((item) => (tree[item[idKey]] = { ...item, children: [] }))
  nodes.forEach((item) => {
    tree[item[parentIdKey]].children.push(tree[item[idKey]])
  })
  return tree[rootNode[idKey]]
}

export default createTree
