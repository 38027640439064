import styled from '@emotion/styled'
import React from 'react'

import { getColor, theme } from '../../Theme'

const Fixed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Shroud = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
  width: 100vw;
  height: 100vh;
  z-index: -1;
`

const Container = styled.div`
  width: 100%;
  ${theme.layout.fluidWidthMargin(theme.maxWidths.column, theme.spacing.sizes.normal)};
  ${theme.spacing.all('large')};
  background: ${(p) => getColor(p.theme, ['white'], ['nightGray'])};
  border-radius: ${theme.borderRadius.button};
  display: flex;
  flex-direction: column;
  align-items: center;
`

type Props = {
  onClose: () => void
  children: JSX.Element
}

const Popup = ({ onClose, children }: Props) => (
  <Fixed>
    <Shroud onClick={onClose} />
    <Container>{children}</Container>
  </Fixed>
)

export default Popup
