import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const ObservationMessage = ({ iconSize, fillColor }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"
          fill={fillColor ?? 'currentColor'}
        />
      </g>
    </svg>
  )
}

export default ObservationMessage
