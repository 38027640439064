import { User } from '../types'

export const maintenanceUrlPatterns = [
  /\/login*/,
  /\/towing*/,
  /\/search\/towings/,
  /\/options*/,
  /\/about*/,
]

export const isMaintenanceUser = (user: User) => user.maintenance || !!user.actAsMaintenance

export const isWithinMaintenanceUrls = (pathName: string) =>
  maintenanceUrlPatterns.some((pattern) => pattern.test(pathName))

export type DriverType = 'commuter_driver' | 'driver'

export const checkUserForDriverRole = (user: User): DriverType | null => {
  const commuter =
    user.commuter_driver ||
    (user.actAsCommuter && (user.admin || user.read_admin || user.commuter_manager))
  const driver = user.driver || user.admin || user.read_admin
  return commuter ? 'commuter_driver' : driver ? 'driver' : null
}
