import { ActionStatus } from '../../../../types/Input'
import { ActionPayload } from '../../types'

export const getBorderStyle = (status: ActionStatus, showBorder: boolean): React.CSSProperties => {
  return showBorder
    ? {
        border: '1px solid',
        borderColor: `${status === 'failed' ? 'warning.main' : 'common.primaryGreen'}`,
        borderRadius: '4px',
      }
    : {}
}

export const constructPayload = (
  inspection: string,
  measuredValueAlphanumeric?: number,
  measuredValueNumeric?: number
): ActionPayload => {
  const data = {
    inspection,
  }
  if (measuredValueAlphanumeric) {
    return {
      ...data,
      measuredValueAlphanumeric,
    }
  }
  return {
    ...data,
    measuredValueNumeric,
  }
}

export const handleSubmit = (
  setValue: (value: number) => void,
  submittedValue: number,
  updateAction: (value: number) => void
) => {
  setValue(submittedValue)
  updateAction(submittedValue)
}
