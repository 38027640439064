import styled from '@emotion/styled'
import { Router } from 'found'
import { TFunction } from 'i18next'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

import { compareFaults } from '../../lib/findings'
import moment from '../../lib/moment-fi'
import { color, defaultTextColor, getColor, MaybeColor, px, theme } from '../../Theme'
import { Fault, Moment, SearchedShift, Shift } from '../../types'
import RestrictionIcon from './RestrictionIcon'

interface ContainerProps {
  past?: boolean
  signedIn?: boolean
  height?: number
  color?: MaybeColor
}

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 8px;
`

const Container = styled.div<ContainerProps>`
  ${theme.spacing.all('small')};
  ${theme.spacing.right('biggest')};
  ${(p) => (p.past ? '' : theme.effects.shadow)};
  position: relative;
  flex: 1;
  color: ${(p) => color(p.color)};
  background-color: ${(p) => {
    if (p.past) {
      return getColor(p.theme, ['grayBlue'], ['nightBlack'])
    }
    if (p.signedIn) {
      return color('secondaryGreen')
    }
    return getColor(p.theme, ['white'], ['nightHighlight'])
  }};
  color: ${defaultTextColor};
  border-radius: ${theme.borderRadius.button};
  height: ${(p) => (p.past ? 'auto' : px(p.height as number))};
`

const Row = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  display: flex;
  margin-top: ${theme.spacing.sizes.small};
  min-width: ${theme.maxWidths.column};
`

const InfoText = styled.div`
  ${theme.text('normal', 'content')};
  color: ${(p) => getColor(p.theme, ['grayDark'], ['grayLight'])};
`

const Bold = styled.div`
  font-weight: bold;
`

const Text = styled.div`
  white-space: pre-line;
`

const Criticality = styled.div`
  ${theme.text('smaller', 'content')};
  font-weight: bold;
  padding-left: 8px;
`

const InfoContainer = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`

const ShowButton = styled.div`
  color: ${(p) => getColor(p.theme, ['primaryBlue'], ['primaryBlue'])};
`

const OuterContainer = styled.div`
  margin-bottom: 16px;
`

type State = {
  openDescription: number | null
}

type Props = {
  t: TFunction
  router?: Router
  loading?: boolean
  error?: string
  now?: Moment
  isMobile?: boolean
  shiftSearch?: SearchedShift
  selected?: Shift
  nightMode: boolean
  fetchShiftInformation?: (shift: Shift, func: () => unknown) => () => unknown
  faults: Array<Fault>
}

class FindingList extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      openDescription: null,
    }
  }

  showDescription(index: number | null) {
    this.setState({ openDescription: this.state.openDescription === index ? null : index })
  }

  render() {
    const { faults, t, nightMode }: Props = this.props
    return (
      <OuterContainer>
        {faults.sort(compareFaults).map((finding, index) => {
          return (
            <Row key={index}>
              <Container>
                <IconContainer>
                  <RestrictionIcon
                    criticality={finding.criticality.level}
                    usageCode={finding.usageCode}
                    nightMode={nightMode}
                    status="open"
                  />
                  <Criticality>{finding.criticality.description}</Criticality>
                </IconContainer>
                <InfoText>
                  {finding.symptomDescriptions.length === 0 ? t('faultCode') : t('symptomCode')}
                </InfoText>
                <Bold>{(finding.faultCode && finding.faultCode.code) || '-'}</Bold>
                <Text>
                  {finding.faultCode &&
                    finding.faultCode.descriptions &&
                    finding.faultCode.descriptions.map((desc, index) => (
                      <div key={index}>- {desc}</div>
                    ))}
                </Text>
                <InfoContainer>
                  <InfoText>
                    {finding.faultType && finding.faultType !== ''
                      ? t('faultType')
                      : t('symptomDescriptions')}
                  </InfoText>
                  <Bold>
                    {finding.faultType ||
                      (finding.symptomDescriptions &&
                        finding.symptomDescriptions.map((desc, index) => (
                          <div key={index}>{desc}</div>
                        )))}
                  </Bold>
                </InfoContainer>
                {finding.usageCode && finding.usageCode !== '' && (
                  <InfoContainer>
                    <InfoText>{t('usageCode')}</InfoText>
                    <Bold>{finding.usageCode || ''}</Bold>
                  </InfoContainer>
                )}
                {finding.description && finding.description !== '' && (
                  <InfoContainer>
                    <InfoText>{t('freeDescription')}</InfoText>
                    <div>
                      <Text onClick={() => this.showDescription(index)}>
                        {finding.description &&
                        finding.description.length > 50 &&
                        this.state.openDescription !== index
                          ? `${finding.description.substring(0, 50)}...`
                          : finding.description}
                      </Text>
                      {finding.description && finding.description.length > 50 && (
                        <ShowButton onClick={() => this.showDescription(index)}>
                          {this.state.openDescription === index ? t('hide') : t('show')}
                        </ShowButton>
                      )}
                    </div>
                  </InfoContainer>
                )}
                <InfoText>{`${finding.status} ${moment(finding.date).format(
                  'DD.MM.YYYY HH:mm'
                )}`}</InfoText>
              </Container>
            </Row>
          )
        })}
      </OuterContainer>
    )
  }
}

export default withTranslation()(FindingList)
