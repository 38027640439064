declare module '@mui/material/styles' {
  interface Theme {
    iconSizes: (s: IconSize | number) => number
  }
  interface ThemeOptions {
    iconSizes?: (s: IconSize | number) => number
  }
}

export type IconSize = keyof typeof sizes

export const sizes = {
  smallest: 16, // smallest
  smaller: 18, // smaller
  small: 20, // small
  normal: 22, // normal
  default: 24, // default
  large: 28, // large
  larger: 32, // larger
  huge: 36, // huge
  moreHuge: 42, // moreHuge
  extraHuge: 48, // extraHuge
  gigantic: 64, // gigantic
  colossal: 84, // colossal
  biggest: 112, // biggest
}

export const getSize = (iconSize?: IconSize | number): number => {
  if (typeof iconSize === 'number') return iconSize
  if (!iconSize) return sizes.default
  return sizes[iconSize] ?? sizes.default
}

const iconSizes = (amount: IconSize | number) => {
  if (typeof amount === 'string') return sizes[amount]

  // support mui default 8px scaling factor along with named amounts
  if (typeof amount === 'number') return 8 * amount

  return 0
}

export default iconSizes
