import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import { useEffect, useState } from 'react'

import { PHONECONTACT_LIST_CHUNK_SIZE } from '../../constants'
import { PhoneContact, PhoneContactGroup } from '../../types'
import PhoneContactCard from './PhoneContactCard'

const ButtonStyle = styled.div`
  background-color: #2479b3;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 56px;
  margin-top: 32px;
  margin-bottom: 16px;
  justify-content: center;
`

const ButtonText = styled.div`
  color: white;
`

type Props = {
  t: TFunction
  phoneContacts?: Array<PhoneContactGroup>
  searchString: string
}

const PhoneContactList = ({ phoneContacts, searchString, t }: Props) => {
  const [cardsShown, setCardsShown] = useState(PHONECONTACT_LIST_CHUNK_SIZE)

  useEffect(() => {
    setCardsShown(PHONECONTACT_LIST_CHUNK_SIZE)
  }, [searchString])

  const renderContacts = (contacts: PhoneContact[]) => {
    const contactsToShow = contacts.length < cardsShown ? contacts : contacts.slice(0, cardsShown)

    return contactsToShow.map((contact, idx) => (
      <PhoneContactCard contact={contact} key={`${contact.phoneNumber}+${idx}`} />
    ))
  }
  const searchStringLowerCase = searchString.toLowerCase()

  const filterContacts = (group: PhoneContactGroup) => {
    if (
      (group.name && group.name.toLowerCase().includes(searchStringLowerCase)) ||
      (group.searchKeys && group.searchKeys.toLowerCase().includes(searchStringLowerCase)) ||
      (group.additionalInfo && group.additionalInfo.toLowerCase().includes(searchStringLowerCase))
    ) {
      return group
    } else {
      return {
        ...group,
        contacts: group.contacts.filter(
          (contact) =>
            contact.name.toLowerCase().includes(searchStringLowerCase) ||
            contact.phoneNumber.toLowerCase().includes(searchStringLowerCase) ||
            (contact.additionalInfo &&
              contact.additionalInfo.toLowerCase().includes(searchStringLowerCase)) ||
            (contact.searchKeys && contact.searchKeys.toLowerCase().includes(searchStringLowerCase))
        ),
      }
    }
  }

  const validContacts = phoneContacts
    ? phoneContacts.map((group) => ({
        ...group,
        contacts: group.contacts.filter((contact) => contact != null),
      }))
    : []
  const filteredContacts = validContacts.map((group) => filterContacts(group))
  const contactsWithInfo = filteredContacts.map((group) => ({
    ...group,
    contacts: group.contacts.map((contact) => ({
      ...contact,
      additionalInfo: `${contact && contact.additionalInfo ? contact.additionalInfo : ''}
        ${contact.additionalInfo && group.additionalInfo ? ', ' : ''}
        ${group.additionalInfo ? group.additionalInfo : ''}`,
    })),
  }))

  const flattenedContacts = contactsWithInfo
    .reduce<PhoneContact[]>((acc, res) => acc.concat(res.contacts), [])
    .sort((a, b) => a.name.localeCompare(b.name))

  return (
    <div>
      {renderContacts(flattenedContacts)}
      {cardsShown < flattenedContacts.length && (
        <ButtonStyle onClick={() => setCardsShown(cardsShown + PHONECONTACT_LIST_CHUNK_SIZE)}>
          <ButtonText>{t('showMoreResults')}</ButtonText>
        </ButtonStyle>
      )}
    </div>
  )
}

export default PhoneContactList
