import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { shiftFeedbackURL } from '../../lib/url'
import { shiftFeedback } from '../../Selectors'
import { color, getColor, theme } from '../../Theme'
import { AppState, Feedback, Shift } from '../../types'
import { isMobileProps } from '../../types/App'
import ButtonLink from '../button/ButtonLink'
import { EmphasisNormal } from '../Emphasis'
import FeedbackIcon from '../FeedbackIcon'
import RatingLinks from '../rating/RatingLinks'

const FeedbackContainer = styled.div<isMobileProps>`
  ${theme.effects.shadow};
  display: ${(p) => (p.isMobile ? 'block' : 'flex')};
  margin-top: ${theme.spacing.sizes.large};
`

const Flex = styled.div`
  ${theme.layout.flexRow};
  ${theme.spacing.left('small')};
  box-sizing: border-box;
  width: 100%;
  background: ${(p) => getColor(p.theme, ['white'], ['nightSeparator'])};
`

const IconPadding = styled.div`
  ${theme.spacing.right('small')};
  flex: 1;
  width: ${theme.spacing.sizes.huge};
`

const FeedbackText = styled.div`
  ${theme.text()};
  ${theme.spacing.ends('small')};
  color: ${color('grayDark')};
  flex: 7;
`

type Props = {
  t: TFunction
  feedback?: Feedback | null
  shift: Shift
  hasFeedback?: boolean
  showInline?: boolean
  showBelow?: boolean
  isMobile: boolean
  past?: boolean
}

const renderLinks = ({
  isMobile,
  shift,
  feedback,
}: {
  isMobile: boolean
  shift: Shift
  feedback?: Feedback | null
}) => (
  <RatingLinks
    bg="grayBackground"
    isMobile={isMobile}
    onClick={undefined}
    getUrl={(stars) => shiftFeedbackURL(shift.id, stars)}
    stars={feedback ? feedback.stars : 0}
  />
)

function ShiftFeedback({
  t,
  feedback,
  shift,
  isMobile,
  past,
  hasFeedback,
  showInline,
  showBelow,
}: Props) {
  const links =
    feedback && feedback.id ? (
      <ButtonLink
        topOpen={showBelow}
        bottomOpen={false}
        to={shiftFeedbackURL(shift.id, feedback.stars)}
      >
        {t('viewFeedback')}
      </ButtonLink>
    ) : (
      renderLinks({ isMobile, shift, feedback })
    )

  return (
    <FeedbackContainer isMobile={showBelow}>
      <Flex>
        <IconPadding>
          <FeedbackIcon notification={false} />
        </IconPadding>
        <FeedbackText>
          <EmphasisNormal>{t(past ? 'shiftHasPassed' : 'shiftHasStarted')}.</EmphasisNormal>
          <div>{hasFeedback ? t('feedbackGiven') : t('giveFeedbackFromShift')}</div>
        </FeedbackText>
        {showInline ? links : undefined}
      </Flex>
      {showBelow ? links : undefined}
    </FeedbackContainer>
  )
}

type PropsIn = {
  shift: Shift
  isMobile: boolean
}

const mapStateToProps = (state: AppState, { shift, isMobile }: PropsIn): unknown => {
  const feedback = shiftFeedback(shift, state)
  const hasFeedback = feedback && feedback.id
  const showInline = !isMobile && !hasFeedback
  const showBelow = isMobile || hasFeedback

  return {
    hasFeedback,
    feedback,
    showBelow,
    showInline,
  }
}

export default withTranslation()(connect(mapStateToProps)(ShiftFeedback))
