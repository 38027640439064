import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const Network = ({ iconSize, fillColor }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={size} height={size} viewBox="-3 -3 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          key="0"
          d="M2.11764706,15.4285714 C2.11764706,15.6306122 1.95952941,15.7959184 1.76470588,15.7959184 L1.05882353,15.7959184 C0.864,15.7959184 0.705882353,15.6306122 0.705882353,15.4285714 L0.705882353,14.6938776 C0.705882353,14.4918367 0.864,14.3265306 1.05882353,14.3265306 L1.76470588,14.3265306 C1.95952941,14.3265306 2.11764706,14.4918367 2.11764706,14.6938776 L2.11764706,15.4285714 Z M1.76470588,13.5918367 L1.05882353,13.5918367 C0.475058824,13.5918367 0,14.0862857 0,14.6938776 L0,15.4285714 C0,16.0361633 0.475058824,16.5306122 1.05882353,16.5306122 L1.76470588,16.5306122 C2.34847059,16.5306122 2.82352941,16.0361633 2.82352941,15.4285714 L2.82352941,14.6938776 C2.82352941,14.0862857 2.34847059,13.5918367 1.76470588,13.5918367 L1.76470588,13.5918367 Z"
          fill={fillColor ?? 'currentColor'}
        />
        <path
          key="1"
          d="M6.35294118,15.4285714 C6.35294118,15.6306122 6.19482353,15.7959184 6,15.7959184 L5.29411765,15.7959184 C5.09929412,15.7959184 4.94117647,15.6306122 4.94117647,15.4285714 L4.94117647,10.2857143 C4.94117647,10.0836735 5.09929412,9.91836735 5.29411765,9.91836735 L6,9.91836735 C6.19482353,9.91836735 6.35294118,10.0836735 6.35294118,10.2857143 L6.35294118,15.4285714 Z M6,9.18367347 L5.29411765,9.18367347 C4.71035294,9.18367347 4.23529412,9.67812245 4.23529412,10.2857143 L4.23529412,15.4285714 C4.23529412,16.0361633 4.71035294,16.5306122 5.29411765,16.5306122 L6,16.5306122 C6.58376471,16.5306122 7.05882353,16.0361633 7.05882353,15.4285714 L7.05882353,10.2857143 C7.05882353,9.67812245 6.58376471,9.18367347 6,9.18367347 L6,9.18367347 Z"
          fill={fillColor ?? 'currentColor'}
        />
        <path
          key="2"
          d="M9.17647059,5.87755102 C9.17647059,5.67477551 9.33529412,5.51020408 9.52941176,5.51020408 L10.2352941,5.51020408 C10.4294118,5.51020408 10.5882353,5.67477551 10.5882353,5.87755102 L10.5882353,10.1336327 L9.17647059,8.6642449 L9.17647059,5.87755102 Z M13.4117647,1.46938776 C13.4117647,1.26661224 13.5705882,1.10204082 13.7647059,1.10204082 L14.4705882,1.10204082 C14.6647059,1.10204082 14.8235294,1.26661224 14.8235294,1.46938776 L14.8235294,14.5417959 L13.4117647,13.0724082 L13.4117647,1.46938776 Z M17.544,17.3725714 L15.5294118,15.2764898 L15.5294118,1.46938776 C15.5294118,0.861795918 15.0543529,0.367346939 14.4705882,0.367346939 L13.7647059,0.367346939 C13.1809412,0.367346939 12.7058824,0.861795918 12.7058824,1.46938776 L12.7058824,12.3377143 L11.2941176,10.8683265 L11.2941176,5.87755102 C11.2941176,5.26995918 10.8190588,4.7755102 10.2352941,4.7755102 L9.52941176,4.7755102 C8.94564706,4.7755102 8.47058824,5.26995918 8.47058824,5.87755102 L8.47058824,7.92955102 L1.30870588,0.474612245 C1.17035294,0.331346939 0.947294118,0.331346939 0.808941176,0.474612245 C0.671294118,0.618612245 0.671294118,0.85077551 0.808941176,0.99477551 L17.0442353,17.8927347 C17.1134118,17.964 17.2037647,18 17.2941176,18 C17.3844706,18 17.4748235,17.964 17.544,17.8927347 C17.6816471,17.7487347 17.6816471,17.5165714 17.544,17.3725714 L17.544,17.3725714 Z"
          fill={fillColor ?? 'currentColor'}
        />
        <path
          key="3"
          d="M10.5882353,15.4285714 C10.5882353,15.6306122 10.4294118,15.7959184 10.2352941,15.7959184 L9.52941176,15.7959184 C9.33529412,15.7959184 9.17647059,15.6306122 9.17647059,15.4285714 L9.17647059,10.8051429 L8.47058824,10.070449 L8.47058824,15.4285714 C8.47058824,16.0361633 8.94564706,16.5306122 9.52941176,16.5306122 L10.2352941,16.5306122 C10.8190588,16.5306122 11.2941176,16.0361633 11.2941176,15.4285714 L11.2941176,13.0092245 L10.5882353,12.2745306 L10.5882353,15.4285714 Z"
          fill={fillColor ?? 'currentColor'}
        />
        <path
          key="4"
          d="M13.7647059,15.7959184 C13.5705882,15.7959184 13.4117647,15.6306122 13.4117647,15.4285714 L13.4117647,15.2133061 L12.7058824,14.4786122 L12.7058824,15.4285714 C12.7058824,16.0361633 13.1809412,16.5306122 13.7647059,16.5306122 L14.4705882,16.5306122 C14.5348235,16.5306122 14.5969412,16.5225306 14.6583529,16.5107755 L13.9715294,15.7959184 L13.7647059,15.7959184 Z"
          fill={fillColor ?? 'currentColor'}
        />
      </g>
    </svg>
  )
}

export default Network
