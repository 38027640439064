import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const Close = ({ iconSize, fillColor }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          fill={fillColor ?? 'currentColor'}
          fillRule="nonzero"
          d="M12,11.2928932 L1.35355339,0.646446609 L0.646446609,1.35355339 L11.2928932,12 L0.646446609,22.6464466 L1.35355339,23.3535534 L12,12.7071068 L22.6464466,23.3535534 L23.3535534,22.6464466 L12.7071068,12 L23.3535534,1.35355339 L22.6464466,0.646446609 L12,11.2928932 Z"
        />
      </g>
    </svg>
  )
}

export default Close
