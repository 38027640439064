import {
  SHIFT_NOTICES_ERROR,
  START_FETCHING_SHIFT_NOTICES,
  UPDATE_SHIFT_NOTICES,
} from '../actions/shiftNotices'
import { ShiftNoticeAction } from '../actions/shiftNotices'
import { ShiftNoticeState } from '../types'

export const initialState = {
  loading: false,
  error: '',
  notices: [],
}

const shiftNoticesReducer = (
  state: ShiftNoticeState = initialState,
  action: ShiftNoticeAction
): ShiftNoticeState => {
  switch (action.type) {
    case START_FETCHING_SHIFT_NOTICES:
      return {
        ...state,
        loading: true,
        error: '',
      }

    case UPDATE_SHIFT_NOTICES: {
      return {
        ...state,
        error: '',
        loading: false,
        notices: action.payload,
      }
    }

    case SHIFT_NOTICES_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default shiftNoticesReducer
