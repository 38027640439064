import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React, { Fragment, HTMLAttributes, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { fetchCalendarURL } from '../../actions/api'
import { CALENDAR_APPS } from '../../constants'
import ArrowDown from '../../icons/ArrowDown'
import ArrowRight from '../../icons/ArrowRight'
import { defaultTextColor, getColor, theme } from '../../Theme'
import { Action, AppState, CalendarState, Dispatch } from '../../types'
import Button from '../button/Button'
import LoadingIndicator from '../LoadingIndicator'

interface DivProps extends HTMLAttributes<HTMLDivElement> {
  optionsVersion?: boolean
}

interface UrlBoxProps extends HTMLAttributes<HTMLInputElement> {
  rows: number
}

const Relative = styled.div<DivProps>`
  position: relative;
  ${(p) =>
    p.optionsVersion &&
    `border-bottom: 1px solid ${getColor(p.theme, ['grayBlue'], ['nightSeparator'])};`}
`
Relative.displayName = 'Relative'
const Content = styled.div<DivProps>`
  box-sizing: border-box;
  ${theme.layout.flexRow};
  justify-content: space-between;
  ${(p) => theme.spacing.ends(p.optionsVersion ? 'small' : 'smallest')};
  ${(p) => theme.spacing.sides(p.optionsVersion ? 'large' : 'small')};
  ${(p) => !p.optionsVersion && theme.effects.shadow};
  ${(p) => !p.optionsVersion && 'margin: 10px 10px 0 46px;'}
  background: ${(p) =>
    getColor(p.theme, ['white'], [p.optionsVersion ? 'nightBlack' : 'nightHighlight'])};
`

Content.displayName = 'Content'

const Text = styled.div`
  ${theme.text('normal', 'content', 'normal')}
  white-space: nowrap;
  flex-grow: 1;
  color: ${defaultTextColor};
`

const ErrorText = styled.div`
  ${theme.text('normal', 'decorative', 'bold')};
  white-space: nowrap;
  color: red;
`

const UrlBox = styled.input<UrlBoxProps>`
  background: ${(p) => getColor(p.theme, ['white'], ['nightBackground'])};
  color: ${defaultTextColor};
  ${theme.text('normal', 'content')};
  flex-grow: 1;
  margin-right: 6px;
`

const DownloadButton = styled(Button)`
  width: 80px;
`

const CopyButton = styled(Button)`
  width: 80px;
`

const InfoSpacing = styled.div<DivProps>`
  ${(p) => !p.optionsVersion && 'margin: 0 10px 0 46px;'}
  ${theme.spacing.ends('smaller')};
  ${(p) => theme.spacing.sides(p.optionsVersion ? 'large' : 'small')};
  ${(p) =>
    !p.optionsVersion &&
    `border: 1px solid ${getColor(p.theme, ['grayBlue'], ['nightSeparator'])};`}
`

const CalendarInfo = styled.div<DivProps>`
  ${theme.layout.flexRow};
  justify-content: space-between;
  color: ${(p) => getColor(p.theme, ['grayDark'], ['grayPink'])};
  ${theme.text('smaller')};
`

const InstructionContainer = styled.div`
  ${theme.layout.flexColumn};
  align-items: flex-start;
`

const InstructionLink = styled.a`
  ${theme.text('smaller', 'content', 'bold')};
  text-decoration: underline;
  color: ${(p) => getColor(p.theme, ['primaryBlue'], ['primaryBlue'])};
`

type Props = {
  t: TFunction
  loading: boolean
  url: string
  error: string
  optionsVersion?: boolean
  fetchCalendar: () => void
  optionsPageRedirect?: () => void
}

const CalendarButton = ({
  t,
  loading,
  error,
  url,
  optionsVersion,
  fetchCalendar,
  optionsPageRedirect,
}: Props) => {
  const [copySuccess, setCopySuccess] = useState(false)
  const [showInstructions, setShowInstructions] = useState(false)

  const copyUrlToClipboard = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => setCopySuccess(true))
      .catch(() => setCopySuccess(false))
  }

  const calendarApps: Array<string> = CALENDAR_APPS

  const toggleCalendarInfo = () => {
    console.log('toggle instructions from ', showInstructions) //eslint-disable-line
    setShowInstructions(!showInstructions)
  }

  return (
    <Relative optionsVersion={optionsVersion}>
      <Fragment>
        {loading ? (
          <LoadingIndicator size={'small'} padded={true} />
        ) : (
          <Content optionsVersion={optionsVersion}>
            {error && error.length > 0 ? (
              <ErrorText>{error}</ErrorText>
            ) : (
              <Fragment>
                {url && url.length > 0 ? (
                  <Fragment>
                    <UrlBox value={url} readOnly rows={1} />
                    <CopyButton onClick={copyUrlToClipboard}>
                      {copySuccess ? t('copied') : t('copy')}
                    </CopyButton>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Text>{t('calendarDownload')}</Text>
                    <DownloadButton onClick={fetchCalendar}>{t('load')}</DownloadButton>
                  </Fragment>
                )}
              </Fragment>
            )}
          </Content>
        )}
        {url && url.length > 0 && (
          <InfoSpacing optionsVersion={optionsVersion}>
            {optionsVersion ? (
              <Fragment>
                <CalendarInfo optionsVersion={optionsVersion} onClick={toggleCalendarInfo}>
                  {t('calendarInstructions')}
                  {showInstructions ? (
                    <ArrowDown iconSize="smallest" />
                  ) : (
                    <ArrowRight iconSize="smallest" />
                  )}
                </CalendarInfo>
                {showInstructions && (
                  <InstructionContainer>
                    {calendarApps.map((app, i) => (
                      <InstructionLink
                        key={i}
                        target={'_blank'}
                        href={t(`calendarInstructionLinks.${app}`)}
                      >
                        {t(`calendarInstructionLinkTexts.${app}`)}
                      </InstructionLink>
                    ))}
                  </InstructionContainer>
                )}
              </Fragment>
            ) : (
              <CalendarInfo optionsVersion={optionsVersion} onClick={optionsPageRedirect}>
                {t('calendarMoreInfo')}
                <ArrowRight iconSize="smallest" />
              </CalendarInfo>
            )}
          </InfoSpacing>
        )}
      </Fragment>
    </Relative>
  )
}

const mapStateToProps = (state: AppState) => {
  const { url, loading, error }: CalendarState = state.calendar

  return {
    url,
    loading,
    error,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCalendar: () => dispatch(fetchCalendarURL() as unknown as Action),
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CalendarButton))
