import { HandlingStation } from '../types'

export type UpdateHandlingStations = {
  type: 'UPDATE_STATIONS'
  payload: Array<HandlingStation>
}
export const UPDATE_STATIONS = 'UPDATE_STATIONS'
export const updateHandlingStations = (
  stations: Array<HandlingStation>
): UpdateHandlingStations => ({
  type: UPDATE_STATIONS,
  payload: stations,
})

export type FetchHandlingStations = {
  type: 'START_FETCHING_STATIONS'
}
export const START_FETCHING_STATIONS = 'START_FETCHING_STATIONS'
export const startFetchingStations = (): FetchHandlingStations => ({
  type: START_FETCHING_STATIONS,
})

export type SetHandlingStationsError = {
  type: 'STATIONS_ERROR'
  payload: string
}
export const STATIONS_ERROR = 'STATIONS_ERROR'
export const handlingStationsError = (error: string) => ({ type: STATIONS_ERROR, payload: error })

export type ClearHandlingStations = {
  type: 'CLEAR_HANDLING_STATIONS'
}
export const CLEAR_HANDLING_STATIONS = 'CLEAR_HANDLING_STATIONS'
export const clearHandlingStations = (): ClearHandlingStations => ({
  type: CLEAR_HANDLING_STATIONS,
})

export type HandlingStationsAction =
  | UpdateHandlingStations
  | SetHandlingStationsError
  | FetchHandlingStations
  | ClearHandlingStations
