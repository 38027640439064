import styled from '@emotion/styled'
import React from 'react'

import { theme } from '../../Theme'
import { MaybeColor } from '../../types'
import Label from '../Label'

interface ContainerPaddedLabelProps {
  horizontal: boolean
  isMobile?: boolean
}

interface ContainerProps extends ContainerPaddedLabelProps {
  alignStart?: boolean
}

const Container = styled.div<ContainerProps>`
  align-items: ${(p) => (p.horizontal ? 'center' : 'flex-start')};
  display: flex;
  flex-direction: ${(p) => (p.horizontal ? 'row' : 'column')};
  justify-content: ${(p) =>
    p.horizontal ? (p.isMobile ? 'center' : 'space-between') : 'flex-start'};
  width: ${(p) => (p.horizontal ? 'auto' : '100%')};
  align-self: ${(p) => (p.alignStart ? 'flex-start' : 'inherit')};
`

const PaddedLabel = styled(Label)<ContainerPaddedLabelProps>`
  ${(p) => (p.horizontal ? '' : theme.spacing.bottom('smallest'))};
  display: block;
  flex: 1;
  min-height: ${theme.spacing.sizes.large};
  visibility: ${(p) => (p.isMobile ? 'none' : 'visible')};
`

type Props = {
  title: string
  children?: JSX.Element | JSX.Element[]
  horizontal: boolean
  color?: MaybeColor
  alignStart?: boolean
  isMobile?: boolean
}

function Annotated({ title, children, horizontal, color, alignStart, isMobile }: Props) {
  return (
    <Container horizontal={horizontal} alignStart={alignStart} isMobile={isMobile}>
      {!isMobile && (
        <PaddedLabel horizontal={horizontal} color={color}>
          {title}
        </PaddedLabel>
      )}
      {children}
    </Container>
  )
}

export default Annotated
