import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const Shift = ({ iconSize, width }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={width ?? size} height={size} viewBox="0 0 21.16 22.157">
      <path
        d="M12.767 0c-3.145 0-7.141 2.751-7.13 6.61.022.65 1.028.63 1.04.016.027-3.847 3.605-5.558 6.09-5.558a7.324 7.324 0 0 1 7.325 7.325c0 4.044-2.594 7.324-6.638 7.324-.802.123-.563 1.057 0 1.068 4.635 0 7.706-3.758 7.706-8.392A8.392 8.392 0 0 0 12.767 0Z"
        fill="currentColor"
      />
      <path
        fill="currentColor"
        d="M6 14.157a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm-3 1h6a3 3 0 0 1 3 3v3a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-3a3 3 0 0 1 3-3z"
      />
      <rect
        transform="rotate(41.591)"
        ry=".62"
        y="-3.003"
        x="16.096"
        height="1.24"
        width="5.285"
        fill="currentColor"
      />
      <rect
        ry=".62"
        transform="rotate(-90)"
        fill="currentColor"
        width="5.285"
        height="1.24"
        x="-7.069"
        y="12.461"
      />
    </svg>
  )
}

export default Shift
