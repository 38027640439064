import { Finding } from '../types'

export type UpdateFindings = {
  type: 'UPDATE_FINDINGS'
  payload: {
    findings: Array<Finding>
  }
  equipments: Array<string>
}
export const UPDATE_FINDINGS = 'UPDATE_FINDINGS'
export const updateFindings = (
  payload: {
    findings: Array<Finding>
  },
  equipments: Array<string>
): UpdateFindings => ({
  type: UPDATE_FINDINGS,
  payload,
  equipments,
})

export type SetFindingsError = {
  type: 'FINDINGS_ERROR'
  payload: string
  equipmentId: Array<string>
}
export const FINDINGS_ERROR = 'FINDINGS_ERROR'
export const findingsFetchingError = (
  error: string,
  equipmentId: Array<string>
): SetFindingsError => ({
  type: FINDINGS_ERROR,
  payload: error,
  equipmentId,
})

export type FetchFindings = {
  type: 'START_FETCHING_FINDINGS'
  payload: Array<string>
}
export const START_FETCHING_FINDINGS = 'START_FETCHING_FINDINGS'
export const startFetchingFindings = (equimpentIds: Array<string>): FetchFindings => ({
  type: START_FETCHING_FINDINGS,
  payload: equimpentIds,
})

export type FindingsAction = UpdateFindings | SetFindingsError | FetchFindings
