import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const Star = ({ iconSize, fillColor }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          d="M0.315395485,9.91008455 C-0.223108708,9.3594427 -0.0549116134,8.81539707 0.689257969,8.70096681 L6.66257949,7.78466434 C7.40674907,7.67052087 8.29039917,6.9997129 8.62651986,6.29420302 L11.3772945,0.531391824 C11.7098598,-0.176125605 12.2562952,-0.177272775 12.5913219,0.528810691 L15.373548,6.29047472 C15.7124036,6.99455063 16.5985151,7.66363785 17.3426847,7.77749452 L23.3102629,8.69465737 C24.0544325,8.80937442 24.2231766,9.35428043 23.6852194,9.90549587 L19.3186587,14.4382535 C18.7839834,14.9929104 18.4511446,16.0824356 18.5791385,16.8596437 L19.6047305,23.0879193 C19.7327244,23.8651274 19.2913096,24.2026823 18.624538,23.8378821 L13.1448681,20.8925217 C12.4761821,20.5317366 11.3844052,20.5377592 10.7192746,20.9054274 L5.39111885,23.8507877 C4.72544125,24.2184559 4.28347945,23.8829085 4.40846493,23.1051269 L5.40233199,16.7500889 C5.52458256,15.9720205 5.18381251,14.8853632 4.64448785,14.3352949 L0.315395485,9.91008455 Z"
          fill={fillColor ?? 'currentColor'}
        />
      </g>
    </svg>
  )
}

export default Star
