import { Theme, withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Router } from 'found'
import { TFunction } from 'i18next'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ReactSelect, { ThemeConfig } from 'react-select'

import { refetchTowing, searchTowings } from '../../../actions/api'
import { clearTowingFormState } from '../../../actions/towingFormState'
import { clearLastSearched, updateLastSearched } from '../../../actions/towingVehicle'
import LoadingIndicator from '../../../components/LoadingIndicator'
import { getVehicleCompound } from '../../../lib/towingUtils'
import { getColor, theme } from '../../../Theme'
import { Action, AppState, Dispatch, TowingVehicle, TowingVehiclePattern } from '../../../types'
import { ActivateFunction, ThemeProps } from '../../../types/App'
import SearchBox from '../../search/SearchBox'
import SearchButton from '../../search/SearchButton'
import SearchContainer from '../../search/SearchContainer'
import SearchHeader from '../../search/SearchHeader'
import SearchInput from '../../search/SearchInput'
import SearchPageHeader from '../../search/SearchPageHeader'
import TowingSearchResult from './TowingSearchResult'

interface Equipment {
  equipment: string
  value: string
}

const TowingSearchContainer = styled.div`
  ${theme.layout.staticWidth(theme.maxWidths.paddedContent)};
`

const ErrorText = styled.div`
  ${theme.text('normal', 'content')};
  color: ${(p) => getColor(p.theme, ['red'], ['red'])};
`

const StyledReactSelect = styled(ReactSelect)<ThemeProps>`
  & .react-select__control {
    border: none;
    box-shadow: none;
    background-color: ${(p) => getColor(p.theme, ['white'], ['black'])};
  }
  & .react-select__single-value {
    color: ${(p) => getColor(p.theme, ['black'], ['white'])};
  }
  & .react-select__menu {
    background-color: ${(p) => getColor(p.theme, ['white'], ['black'])};
  }
  & .react-select__option {
    margin: 0px;
    background-color: ${(p) => getColor(p.theme, ['white'], ['black'])};
    color: ${(p) => getColor(p.theme, ['black'], ['white'])};
  }
  & .react-select__option--is-selected {
    background-color: ${(p) => getColor(p.theme, ['primaryBlue'], ['primaryBlue'])};
  }
  & .react-select__option--is-focused {
    background-color: ${(p) => getColor(p.theme, ['primaryBlueDisabled'], ['primaryBlueDisabled'])};
  }
  & .react-select__indicator-separator {
    width: 0px;
  }
  & .react-select__dropdown-indicator {
    color: black;
  }
`

type Props = {
  activateVehicleNumber: ActivateFunction
  fetchTowings: (arg0: string, arg1: string) => void
  activateVehicleType: ActivateFunction
  refetch: (arg0: string, arg1: string) => void
  clearTowingFormState: () => void
  saveSearchParams: (arg0: string, arg1: string, arg2: string) => void
  clearSearchParams: () => void
  loading: boolean
  towingVehiclesByCompound: Record<string, TowingVehicle>
  towingFormStateVehicleType: string
  towingFormStateVehicleNumber: string
  searchStateVehicleType: string
  searchStateVehicleNumber: string
  searchStateFormName: string
  setError: (arg0: string) => void
  towingPatterns: Array<TowingVehiclePattern>
  state: any
  t: TFunction
  router: Router
  theme: ThemeConfig & Theme
}

const TowingSearch = ({
  activateVehicleNumber,
  fetchTowings,
  activateVehicleType,
  refetch,
  clearTowingFormState,
  saveSearchParams,
  clearSearchParams,
  towingVehiclesByCompound,
  towingFormStateVehicleType,
  towingFormStateVehicleNumber,
  searchStateVehicleType,
  searchStateVehicleNumber,
  searchStateFormName,
  towingPatterns,
  state,
  t,
  router,
  loading,
  theme,
}: Props) => {
  const { vehicleNumberActive, vehicleTypeActive } = state

  const getVehicleNumberKeyFromPattern = useCallback(
    (name, number): string | null => {
      const patternObject = towingPatterns.find((pattern) => pattern.formName === name)
      if (patternObject) {
        const pattern = new RegExp(patternObject.userInputExpression)
        const match = number.match(pattern)
        if (match) {
          return match[1]
        }
      }
      return undefined
    },
    [towingPatterns]
  )

  const validateWithParams = useCallback(
    (name, number) => {
      const patternObject = towingPatterns.find((pattern) => pattern.formName === name)
      if (patternObject) {
        const pattern = new RegExp(patternObject.userInputExpression)
        return pattern.test(number)
      }
      return false
    },
    [towingPatterns]
  )

  const [validationError, setValidationError] = useState('')
  const [vehicleType, setVehicleType] = useState('')
  const [vehicleNumber, setVehicleNumber] = useState('')
  const [formName, setFormName] = useState('')

  const validate = useCallback(() => {
    return validateWithParams(formName, vehicleNumber)
  }, [formName, validateWithParams, vehicleNumber])

  const mappedSearchVehicleNumber = getVehicleNumberKeyFromPattern(formName, vehicleNumber)
  const searchCompound =
    validate() && mappedSearchVehicleNumber
      ? getVehicleCompound(vehicleType, mappedSearchVehicleNumber)
      : ''
  const towingSearchResult = towingVehiclesByCompound && towingVehiclesByCompound[searchCompound]

  const changeVehicleType = (type, formName) => {
    setVehicleType(type)
    setFormName(formName)
  }
  const changeVehicleNumber = (value) => {
    setVehicleNumber(value)
  }

  useEffect(() => {
    if (validate()) {
      setValidationError('')
    }
  }, [vehicleNumber, formName, validate])

  useEffect(() => {
    // TODO: fix refetch
    const mappedSearchVehicleNumber = getVehicleNumberKeyFromPattern(
      searchStateFormName,
      searchStateVehicleNumber
    )
    if (
      !loading &&
      mappedSearchVehicleNumber &&
      searchStateFormName !== '' &&
      searchStateVehicleType !== '' &&
      searchStateVehicleType === towingFormStateVehicleType &&
      mappedSearchVehicleNumber === towingFormStateVehicleNumber &&
      towingVehiclesByCompound[
        getVehicleCompound(searchStateVehicleType, mappedSearchVehicleNumber)
      ] &&
      validateWithParams(searchStateFormName, searchStateVehicleNumber)
    ) {
      changeVehicleType(searchStateVehicleType, searchStateFormName)
      changeVehicleNumber(searchStateVehicleNumber)
      refetch(searchStateVehicleType, searchStateVehicleNumber)
    } else {
      clearSearchParams()
    }
    return () => clearTowingFormState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clearSearchParams,
    clearTowingFormState,
    getVehicleNumberKeyFromPattern,
    refetch,
    searchStateFormName,
    searchStateVehicleNumber,
    searchStateVehicleType,
    validateWithParams,
  ])

  const handleValidationError = (name) => {
    if (!name) {
      setValidationError(t('selectVehicleType'))
    } else {
      const validationMessage = towingPatterns.find((pattern) => pattern.formName === name)
      if (validationMessage) {
        setValidationError(validationMessage.hint)
      }
    }
  }

  const handleEnterKey = () => {
    const mappedSearchVehicleNumber = getVehicleNumberKeyFromPattern(formName, vehicleNumber)
    if (mappedSearchVehicleNumber && validate()) {
      saveSearchParams(formName, vehicleNumber, vehicleType)
      return fetchTowings(vehicleType, vehicleNumber)
    } else {
      handleValidationError(formName)
    }
  }

  const options = towingPatterns
    .map((pattern) => {
      return {
        value: pattern.formName,
        label: pattern.formName,
        equipment: pattern.equipmentType,
      }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  return (
    <TowingSearchContainer>
      <div
        onKeyDown={(ev) => {
          return ev.keyCode === 13 && handleEnterKey()
        }}
      >
        <SearchPageHeader name="towing" text={t('searchTowing')} />
        <SearchContainer>
          <SearchBox marginRight="8px" onClick={activateVehicleType} active={vehicleTypeActive}>
            <SearchHeader>{t('reportingGroup')}</SearchHeader>
            <StyledReactSelect
              theme={theme}
              classNamePrefix="react-select"
              value={
                formName
                  ? options.find((option) => option.value === formName)
                  : { value: null, label: t('selectVehicleType') }
              }
              onChange={(e: Equipment) => {
                changeVehicleType(e.equipment, e.value)
              }}
              options={options}
              isSearchable={false}
            />
          </SearchBox>
          <SearchBox marginLeft="8px" onClick={activateVehicleNumber} active={vehicleNumberActive}>
            <SearchHeader>{t('vehicleNumber')}</SearchHeader>
            <SearchInput
              fontSize="16px"
              type="number"
              value={vehicleNumber}
              onChange={(event) => changeVehicleNumber(event.target.value)}
            />
          </SearchBox>
        </SearchContainer>
        {loading ? (
          <LoadingIndicator size="normal" padded={true} />
        ) : (
          <Fragment>
            {validationError && <ErrorText>{validationError}</ErrorText>}
            <SearchButton
              t={t}
              validate={validate}
              setError={() => handleValidationError(formName)}
              errorKey="reportingGroupMandatory"
              customTopMargin="20px"
              onClick={() => {
                const mappedSearchVehicleNumber = getVehicleNumberKeyFromPattern(
                  formName,
                  vehicleNumber
                )
                saveSearchParams(formName, vehicleNumber, vehicleType)
                mappedSearchVehicleNumber && fetchTowings(vehicleType, vehicleNumber)
              }}
            />
          </Fragment>
        )}
      </div>
      {towingSearchResult && <TowingSearchResult towing={towingSearchResult} router={router} />}
    </TowingSearchContainer>
  )
}

const mapStateToProps = (state: AppState) => {
  const towingVehiclesByCompound = state.towingSearch.towingVehiclesByCompound
  const towingFormStateVehicleType = state.towingFormState.vehicleType
  const towingFormStateVehicleNumber = state.towingFormState.vehicleNumber
  const searchStateFormName = state.towingSearch.lastSearched.formName
  const searchStateVehicleNumber = state.towingSearch.lastSearched.vehicleNumber
  const searchStateVehicleType = state.towingSearch.lastSearched.vehicleType

  return {
    towingVehiclesByCompound,
    towingFormStateVehicleNumber,
    towingFormStateVehicleType,
    searchStateVehicleNumber,
    searchStateFormName,
    searchStateVehicleType,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTowings: (vehicleType, vehicleNumber) =>
    dispatch(searchTowings(vehicleType, vehicleNumber) as unknown as Action),
  saveSearchParams: (formName, vehicleNumber, vehicleType) =>
    dispatch(
      updateLastSearched({
        formName: formName,
        vehicleNumber: vehicleNumber,
        vehicleType: vehicleType,
      })
    ),
  refetch: (vehicleType, vehicleNumber) =>
    dispatch(refetchTowing(vehicleType, vehicleNumber) as unknown as Action),
  clearTowingFormState: () => dispatch(clearTowingFormState()),
  clearSearchParams: () => dispatch(clearLastSearched as unknown as Action),
})

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(TowingSearch))
