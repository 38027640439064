import styled from '@emotion/styled'
import { styled as muiStyled } from '@mui/material/styles'

import { color, getColor, MaybeColor, theme } from '../../Theme'

interface PageHeaderProps {
  color?: MaybeColor
  flexDirection?: string
  height?: string
}

export const Container = styled.div`
  ${theme.layout.flexCenterH};
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  flex-direction: column;
`

export const Content = styled.div<{ marginBottom?: string }>`
  ${theme.layout.fluidWidthMargin(theme.maxWidths.content, theme.spacing.sizes.small)};
  flex: 1 0 auto;
  align-self: center;
  margin-bottom: ${(p) => p.marginBottom || '68px'};
  min-width: ${theme.maxWidths.content};
`

export const Column = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.column)};
  @media (min-width: ${theme.breakpoints.medium}) {
    width: 420px;
    max-width: 420px;
  }
`

export const Footer = styled.div`
  position: fixed;
  z-index: 100;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: ${(p) => getColor(p.theme, ['primaryBlue'], ['grayDark'])};
  height: 64px;
  right: 0;
  text-align: center;
  color: white;
  align-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-top: 130%;
`

export const FooterText = styled.div`
  text-align: center;
  align-items: center;
  align-self: center;
  align-content: center;
  flex: 1;
`

export const PageHeader = styled.div<PageHeaderProps>`
  left: 0;
  background-color: ${(p) => getColor(p.theme, [p.color, 'white'], [p.color, 'grayDark'])};
  right: 0;
  text-align: center;
  align-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  flex-direction: ${(p) => (p.flexDirection === 'column' ? 'column' : 'row')};
  height: ${(p) => (p.height ? p.height : '')};
`

export const HeaderText = styled.div`
  font-size: 16px;
  color: ${(p) => getColor(p.theme, ['primaryGreen'], ['white'])};
  text-transform: uppercase;
  text-align: center;
  align-items: center;
  align-self: center;
  align-content: center;
  flex: 1;
`

export const TitleText = styled.div`
  ${theme.text()};
  color: ${(p) => getColor(p.theme, ['black'], ['grayLight'])};
  text-align: center;
  margin-bottom: 16px;
  padding-top: 20px;
`

export const BackIcon = styled.div`
  align-content: start;
  display: flex;
  padding-left: 8px;
  color: ${(p) => getColor(p.theme, ['primaryBlue'], ['white'])};
`

export const FooterBackIcon = styled(BackIcon)`
  color: ${color('white')};
`

export const Constrain = muiStyled('div')(({ theme }) => ({
  ...theme.layout.flexRow,
  ...theme.layout.fluidWidthMargin(theme, 500, 0),
  position: 'relative',
  height: '60px',
  boxSizing: 'border-box',
}))
