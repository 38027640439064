import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React, { useState } from 'react'

import ArrowDown from '../../icons/ArrowDown'
import ArrowUp from '../../icons/ArrowUp'
import moment from '../../lib/moment-fi'
import { getColor, MaybeColor, theme } from '../../Theme'
import { Defect } from '../../types'
import { Status } from '../create-defect/types'
import RestrictionIcon from '../findings/RestrictionIcon'
import Text from '../Text'

interface BoldProps {
  width: string
}

const Bold = styled.div<BoldProps>`
  ${theme.text('normal')};
  font-weight: bold;
  width: ${(p) => p.width};
  display: flex;
  word-break: break-word;
  padding-left: 1px;
  line-height: 1;
`

interface IconContainerProps {
  justifyContent: string
}

enum DefectStatus {
  ACCEPTED = 'ACCEPTED',
  ASSIGNED = 'ASSIGNED',
  IN_PROCESS = 'IN_PROCESS',
  REGISTERED = 'REGISTERED',
  SOLVED = 'SOLVED',
  TRANSFERRED = 'TRANSFERRED',
}

const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  width: 10%;
  justify-content: ${(p) => p.justifyContent};
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 4px;
`

const DefectHeaderContainer = styled.div`
  ${theme.layout.flexRow};
  width: 80%;
  margin-left: 10px;
`

interface DefectRowBaseContainerProps {
  open: boolean
}

const DefectRowBaseContainer = styled.div<DefectRowBaseContainerProps>`
  background: ${(p) =>
    getColor(p.theme, [p.color as MaybeColor, 'white'], [p.color as MaybeColor, 'grayDark'])};
  width: 100%;
  max-height: ${(props) => (props.open ? 'none' : '62px')};
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.1);
  gap: 10px;
`

const DefectRowContainer = styled.div`
  width: 97%;
  background: ${(p) =>
    getColor(p.theme, [p.color as MaybeColor, 'white'], [p.color as MaybeColor, 'grayDark'])};
  ${theme.layout.flexRow};
  padding: 8px;
  align-content: center;
  flex-basis: 100%;
`

const DefectAdditionalDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  padding-bottom: 10px;
  gap: 8px;
  width: 85%;
`

const IssuedTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  width: 45px;
`

type Props = {
  t: TFunction
  defect: Defect
  nightMode: boolean
  status: Status
}

const DefectsRow = ({ defect, t, nightMode, status }: Props): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false)

  const formatIssuedTime = (issuedTime: string): { dayMonth: string; year: string } => {
    const fallback = { dayMonth: '-', year: '' }
    try {
      if (!issuedTime) return fallback
      const date = moment(issuedTime)
      return {
        dayMonth: date.format('D.M.'),
        year: date.format('YYYY'),
      }
    } catch (e) {
      return fallback
    }
  }

  const formatDescription = (description: string): string => {
    try {
      const parts = description.split('|')
      return parts.length > 2 ? `${parts[1]} - ${parts[2]}` : '-'
    } catch (e) {
      return '-'
    }
  }

  const formatDefectCodeDescription = (description: string): string | undefined => {
    try {
      const parts = description?.split('|')
      if (parts?.length > 0) {
        return parts.reduce<string | undefined>((prev, cur) => {
          if (!prev) return cur
          return cur?.length ? `${prev} > ${cur}` : prev
        }, undefined)
      }
    } catch (e) {
      return '-'
    }
  }

  const formatCompletionTime = (completionTime: string): string => {
    try {
      return completionTime ? moment(completionTime).format('D.M.Y h:m') : ''
    } catch (e) {
      return ''
    }
  }

  function getStatusTranslation(status: DefectStatus): string {
    switch (status) {
      case DefectStatus.ACCEPTED:
        return t('defect.status.ACCEPTED')
      case DefectStatus.ASSIGNED:
        return t('defect.status.ASSIGNED')
      case DefectStatus.IN_PROCESS:
        return t('defect.status.IN_PROCESS')
      case DefectStatus.REGISTERED:
        return t('defect.status.REGISTERED')
      case DefectStatus.SOLVED:
        return t('defect.status.SOLVED')
      case DefectStatus.TRANSFERRED:
        return t('defect.status.TRANSFERRED')
      default:
        return status
    }
  }

  const textColor = nightMode ? 'grayPink' : 'grayDark'
  const issuedTime = formatIssuedTime(defect.issuedTime)

  return (
    <DefectRowBaseContainer open={open}>
      <DefectRowContainer onClick={() => setOpen(!open)}>
        <IconContainer justifyContent="flex-start">
          <RestrictionIcon
            criticality={defect.criticality}
            usageCode={defect.restrictionCodeDescription}
            nightMode={nightMode}
            status={status}
          />
        </IconContainer>
        <DefectHeaderContainer>
          <IssuedTimeContainer>
            <Text>{issuedTime.dayMonth}</Text>
            <Text>{issuedTime.year}</Text>
          </IssuedTimeContainer>
          <Bold width="68%">{formatDescription(defect.defectDescription)}</Bold>
        </DefectHeaderContainer>
        <IconContainer justifyContent="flex-end">
          {open ? (
            <ArrowUp
              iconSize="normal"
              stroke={getColor({ nightMode }, ['mediumGreen'], ['primaryGreen'])}
            />
          ) : (
            <ArrowDown
              iconSize="normal"
              stroke={getColor({ nightMode }, ['mediumGreen'], ['primaryGreen'])}
            />
          )}
        </IconContainer>
      </DefectRowContainer>
      {open && (
        <DefectAdditionalDetailsContainer>
          {defect.restrictionCodeDescription && (
            <Bold width="90%">{defect.restrictionCodeDescription}</Bold>
          )}
          <Text color={textColor}>{formatDefectCodeDescription(defect.defectCodeDescription)}</Text>
          <Bold width="90%">{defect.comments?.[0]?.comment}</Bold>
          <Text color={textColor}>
            {getStatusTranslation(defect.status as DefectStatus)}{' '}
            {formatCompletionTime(defect.repairCompletionTime)}
          </Text>
        </DefectAdditionalDetailsContainer>
      )}
    </DefectRowBaseContainer>
  )
}

export default DefectsRow
