import { Box, LinearProgress, Theme, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useRouter } from 'found'

import ArrowRight from '../../icons/ArrowRight'
import CircleToggle from '../circle/CircleToggle'
import ServiceOrderHeader from './ServiceOrderHeader'
import { ServiceOrder } from './types'

enum ServiceOrderStatus {
  COMPLETED = 'COMPLETED',
  RELEASED = 'RELEASED',
}

const ServiceOrderRowContainer = styled('div')(({ theme }) => ({
  width: '100%',
  color: theme.palette.text.primary,
  background: theme.palette.background.default,
  ...theme.layout.flexColumn,
  alignItems: 'flex-start',
  flexBasis: '100%',
  boxShadow: '0 2px 6px 1px rgba(0, 0, 0, 0.1)',
}))

const contentWidthAndPadding = (theme: Theme): React.CSSProperties => ({
  width: `calc(100% - ${theme.spacing('smaller')} * 2)`,
  padding: theme.spacing('smaller'),
})

const Content = styled('div')(({ theme }) => ({
  ...theme.layout.flexRow,
  justifyContent: 'space-between',
  ...contentWidthAndPadding(theme),
}))

type Props = {
  serviceOrder: ServiceOrder
  updateSelectedServiceOrder: (serviceOrder: ServiceOrder) => void
}

const ServiceOrderRow = ({ serviceOrder, updateSelectedServiceOrder }: Props): JSX.Element => {
  const { router } = useRouter()

  const handleSelect = (serviceOrder: ServiceOrder) => {
    updateSelectedServiceOrder(serviceOrder)
    router.push(`/serviceOrders/${serviceOrder.id}`)
  }

  const totalActivitiesCount: number = serviceOrder.activities.length
  const completedActivitiesCount: number = serviceOrder.activities.filter(
    (a) => a.status === ServiceOrderStatus.COMPLETED
  ).length
  const completionPercent: number = (completedActivitiesCount / totalActivitiesCount) * 100

  return (
    <ServiceOrderRowContainer>
      <Content>
        <ServiceOrderHeader serviceOrder={serviceOrder} showStatus={false} />
        <CircleToggle
          icon={<ArrowRight iconSize="smallest" />}
          left="12px"
          onClick={() => {
            handleSelect(serviceOrder)
          }}
          color={serviceOrder.status === ServiceOrderStatus.COMPLETED ? 'green' : 'blue'}
        />
      </Content>
      <Box sx={(theme) => ({ ...contentWidthAndPadding(theme) })}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress
              sx={(theme) => ({ width: '100%', height: theme.spacing('smaller') })}
              variant="determinate"
              value={completionPercent}
              color="primary"
            />
          </Box>
          <Box sx={{ minWidth: 35, textAlign: 'center' }}>
            <Typography>
              {completedActivitiesCount}/{totalActivitiesCount}
            </Typography>
          </Box>
        </Box>
      </Box>
    </ServiceOrderRowContainer>
  )
}

export default ServiceOrderRow
