import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'

import Success from '../../icons/Success'
import moment from '../../lib/moment-fi'
import { getColor, theme } from '../../Theme'
import { ObservationMessage } from '../../types/Input'

const MessageContainer = styled.div`
  margin-bottom: ${theme.spacing.sizes.tiny};
`

const Message = styled.div`
  ${theme.layout.flexColumn};
  width: 100%;
  background: ${(p) => getColor(p.theme, ['primaryGreenTinted'], ['primaryGreen'])}};
  justify-content: flex-start;
  ${theme.spacing.ends('tiny')};
  ${theme.effects.shadow};
`

const Row = styled.div`
  ${theme.layout.flexRow};
  ${theme.spacing.sides('tiny')};
  ${theme.spacing.ends('smallest')};
  flex: 1;
  width: 80%;
  justify-content: space-between;
`
const IconContainer = styled.div`
  ${theme.spacing.sides('tiny')};
  margin-bottom: -${theme.spacing.sizes.tiny};
`

const TitleRow = styled(Row)`
  ${theme.text('normal', 'content')}
  ${theme.layout.flexCenterH};
  color: ${(p) => getColor(p.theme, ['yellowDark'], ['primaryYellow'])};
`

const Text = styled.span<{ defaultText?: boolean }>`
  ${theme.text()};
  color: ${(p) =>
    p.defaultText
      ? getColor(p.theme, ['darkGreen'], ['shallowGreen'])
      : getColor(p.theme, ['yellowDark'], ['primaryYellow'])};
  width: 70%;
  flex-basis: 70%;
  align-text: left;
  > p {
    margin: 0;
  }
`

const BoldText = styled(Text)`
  font-weight: 600;
  align-text: left;
  flex-basis: 25%;
`

type Props = {
  message: ObservationMessage
  idx: number
  open: boolean
  setOpenedIndex: (index: number | null) => void
  t: TFunction
}

const ObservationMessageRow = ({ message, idx, open, setOpenedIndex, t }: Props) => {
  const handleClick = () => {
    setOpenedIndex(open ? null : idx)
  }
  const dateTimeString = moment(message.messageDateTime).format('HH:mm')
  return (
    <MessageContainer>
      <Message onClick={() => handleClick()}>
        <TitleRow>
          {t('observationMessageSent', { dateTime: dateTimeString })}
          <IconContainer>
            <Success iconSize="normal" />
          </IconContainer>
        </TitleRow>
        {open ? (
          <Fragment>
            <Row>
              <BoldText>{`${t('reason')}: `}</BoldText>
              <Text>{message.title}</Text>
            </Row>
            <Row>
              <BoldText>{`${t('freeDescription')}: `}</BoldText>
              <Text>{message.description || '-'}</Text>
            </Row>
            {message.reference && (
              <Row>
                <BoldText>{`${t('train')}: `}</BoldText>
                <Text>{message.reference && message.reference.trainNumber}</Text>
              </Row>
            )}
          </Fragment>
        ) : null}
      </Message>
    </MessageContainer>
  )
}

export default withTranslation()(ObservationMessageRow)
