import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit'

import { Equipment } from '../../components/create-defect/types'
import { apiGET } from '../../lib/api'
import { uniqByKey } from '../../lib/createDefectFormUtils'
import { OptionsWithID } from '../create-defect'

interface BaseFetchError {
  message: string
}

interface ThunkAPIConfig {
  rejectValue: BaseFetchError
}

export const createFetchAction = <Returned, ThunkArg, StatePaths extends Record<string, unknown>>(
  type: keyof StatePaths,
  apiFetch: (arg: ThunkArg) => Promise<Returned> // I don't know why wrapping in Promise is needed
): AsyncThunk<Returned, ThunkArg, ThunkAPIConfig> => {
  return createAsyncThunk<Returned, ThunkArg, ThunkAPIConfig>(
    `data/fetch${String(type)}`,
    async (arg) => {
      return (await apiFetch(arg)) as Returned
    }
  )
}

const getInstallationGroups = async (): OptionsWithID<'equipments'> => {
  const response = await apiGET<Equipment[]>('serialNumbers')
  return {
    data: uniqByKey(
      response.filter((e) => e.installationGroup),
      'installationGroup'
    ),
    id: 'equipments',
  }
}

export const fetchEquipmentsAction = createFetchAction('equipments', getInstallationGroups)
