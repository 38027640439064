import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import { withTranslation } from 'react-i18next'

import { Modal as ModalType, Route } from '../../types'
import LoadingIndicator from '../LoadingIndicator'
import ConfirmButton from './ConfirmButton'
import ConfirmModal from './ConfirmModal'
import { ConfirmCheckOrInspection } from './types'

const LoadingContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-content: center;
  z-index: 9;
  width: 100%;
  align-items: center;
  height: 100%;
  top: 0;
  background: grey;
  opacity: 0.8;
`

const InspectionWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
`

type Props = {
  t: TFunction
  route: Route
  queryDate: string
  posting: boolean
  confirmCheckOrInspection: ConfirmCheckOrInspection
  modalMessage?: string
  modalOpen?: boolean
  sourceSystem: string
  confirmType?: string
  modal: ModalType
  openModal: (modalType: ModalType) => void
}

const InspectionAndCheckButtons = ({
  t,
  route,
  queryDate,
  posting,
  confirmCheckOrInspection,
  openModal,
  sourceSystem,
  modal,
}: Props) => {
  if (sourceSystem === 'RCS' && route.trainType === 'VET') {
    const locomotives = [
      ...route.locomotives,
      ...route.cars.map((car, index) => ({
        orderNumber: route.locomotives.length + (index + 1),
        locomotiveNumber: car.carNumber,
        indPulling: false,
      })),
    ]
    return (
      <div>
        <div>{t('trafficInspection')}</div>
        <InspectionWrapper>
          <ConfirmModal
            modal={modal}
            confirmCheckOrInspection={confirmCheckOrInspection}
            openModal={openModal}
            route={route}
            queryDate={queryDate}
          />
          <ConfirmButton
            route={route}
            confirmCompleted={route.brakeConditionCheckCompleted}
            buttonName="TJT"
            onClick={() =>
              openModal({
                action: undefined,
                confirmType: '1',
                confirmCompleted: route.brakeConditionCheckCompleted,
                message: t('confirmCompleteBrakeCheck'),
                open: true,
                locomotives,
                sourceSystem,
                secondAction: undefined,
              })
            }
            text={t('completeBrakeCheck')}
            fullBrakeCheckCompleted={route.brakeConditionCheckCompleted}
            sourceSystem={sourceSystem}
          />
          <ConfirmButton
            route={route}
            confirmCompleted={route.smallBrakeCheckCompleted}
            buttonName="OJT"
            onClick={() =>
              openModal({
                action: undefined,
                confirmType: '2',
                confirmCompleted: route.brakeConditionCheckCompleted,
                message: t('confirmSmallBrakeCheck'),
                open: true,
                locomotives,
                sourceSystem,
                secondAction: undefined,
              })
            }
            text={t('smallBrakeCheck')}
            fullBrakeCheckCompleted={route.brakeConditionCheckCompleted}
            sourceSystem={sourceSystem}
          />
          <ConfirmButton
            route={route}
            confirmCompleted={route.brakeConditionCheckCompleted}
            buttonName="JT"
            onClick={() =>
              openModal({
                action: undefined,
                confirmType: '3',
                confirmCompleted: route.brakeConditionCheckCompleted,
                message: t('confirmBrakeCableCheck'),
                open: true,
                locomotives,
                sourceSystem,
                secondAction: undefined,
              })
            }
            text={t('brakeCableCheck')}
            fullBrakeCheckCompleted={route.brakeConditionCheckCompleted}
            sourceSystem={sourceSystem}
          />
          <ConfirmButton
            route={route}
            confirmCompleted={route.brakeConditionCheckCompleted}
            buttonName="EJT"
            onClick={() =>
              openModal({
                action: undefined,
                confirmType: '4',
                confirmCompleted: route.brakeConditionCheckCompleted,
                message: t('confirmNoBrakeCheckNeeded'),
                open: true,
                locomotives,
                sourceSystem,
                secondAction: undefined,
              })
            }
            text={t('noBrakeCheckNeeded')}
            fullBrakeCheckCompleted={route.brakeConditionCheckCompleted}
            sourceSystem={sourceSystem}
          />
          {posting && (
            <LoadingContainer>
              <LoadingIndicator size="normal" padded={true} />
            </LoadingContainer>
          )}
        </InspectionWrapper>
      </div>
    )
  }
  if (sourceSystem === 'KAPU') {
    const fullBrakeCheckCompleted =
      route.brakeConditionCheckCompleted !== undefined && route.brakeConditionCheckCompleted !== ''
    return (
      <InspectionWrapper>
        <ConfirmModal
          modal={modal}
          confirmCheckOrInspection={confirmCheckOrInspection}
          openModal={openModal}
          route={route}
          queryDate={queryDate}
        />
        <ConfirmButton
          route={route}
          confirmCompleted={route.trafficInspectionCompleted}
          buttonName="L"
          onClick={() =>
            openModal({
              action: undefined,
              confirmType: '1',
              confirmCompleted: route.trafficInspectionCompleted,
              message: t('confirmTrafficInspection'),
              open: true,
              sourceSystem,
              locomotives: undefined,
              secondAction: undefined,
            })
          }
          text={t('trafficInspection')}
          sourceSystem={sourceSystem}
        />
        <ConfirmButton
          route={route}
          confirmCompleted={route.brakeConditionCheckCompleted}
          buttonName="TJT"
          onClick={() =>
            openModal({
              confirmType: '2',
              confirmCompleted: route.brakeConditionCheckCompleted,
              action: fullBrakeCheckCompleted ? 'remove' : undefined,
              message: fullBrakeCheckCompleted
                ? t('removeCompleteBrakeCheck')
                : t('confirmCompleteBrakeCheck'),
              open: true,
              sourceSystem,
              locomotives: undefined,
              secondAction:
                route.smallBrakeCheckCompleted && fullBrakeCheckCompleted ? '3' : undefined,
            })
          }
          text={t('completeBrakeCheck')}
          sourceSystem={sourceSystem}
          updateConfirmation={() =>
            openModal({
              confirmType: '2',
              confirmCompleted: route.brakeConditionCheckCompleted,
              message: t('confirmCompleteBrakeCheck'),
              open: true,
              sourceSystem,
              action: 'update',
              locomotives: undefined,
              secondAction: undefined,
            })
          }
        />
        <ConfirmButton
          route={route}
          confirmCompleted={route.smallBrakeCheckCompleted}
          buttonName="OJT"
          onClick={() =>
            openModal({
              action: route.smallBrakeCheckCompleted ? 'remove' : undefined,
              confirmType: '3',
              confirmCompleted: route.smallBrakeCheckCompleted,
              message: route.smallBrakeCheckCompleted
                ? t('removeSmallBrakeCheck')
                : t('confirmSmallBrakeCheck'),
              open: true,
              sourceSystem,
              locomotives: undefined,
              secondAction: undefined,
            })
          }
          text={t('smallBrakeCheck')}
          fullBrakeCheckCompleted={route.brakeConditionCheckCompleted}
          sourceSystem={sourceSystem}
        />
        {posting && (
          <LoadingContainer>
            <LoadingIndicator size="normal" padded={true} />
          </LoadingContainer>
        )}
      </InspectionWrapper>
    )
  }
  return <div />
}

export default withTranslation()(InspectionAndCheckButtons)
