import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'

import moment from '../../lib/moment-fi'
import { getColor, theme } from '../../Theme'
import { Route } from '../../types'
import { MomentInput } from '../../types/Common'

const Text = styled.div`
  font-size: ${theme.font.large};
  font-weight: bold;
  margin-top: 10px;
  color: ${(p) => getColor(p.theme, ['black'], ['white'])};
`

const handleTimestamp = (dateString: MomentInput) =>
  moment(dateString).format('DD.MM.YYYY HH:mm:ss')

type Props = {
  route: Route
  sourceSystem: string
}

const BrakeCheckTimestamp = ({ route, sourceSystem }: Props) => {
  const { t } = useTranslation()
  if (
    sourceSystem === 'RCS' &&
    route.brakeConditionCheckCompleted !== undefined &&
    route.brakeConditionCheckCompleted !== ''
  )
    return (
      <Text>
        {t('fullBrakeCheckDone', { date: handleTimestamp(route.brakeConditionCheckCompleted) })}
      </Text>
    )

  if (
    sourceSystem === 'KAPU' &&
    route.brakeConditionCheckCompleted !== undefined &&
    route.brakeConditionCheckCompleted !== ''
  )
    return (
      <Text>
        {t('fullBrakeCheckDone', { date: handleTimestamp(route.brakeConditionCheckCompleted) })}
      </Text>
    )

  return <div />
}

export default BrakeCheckTimestamp
