import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React from 'react'

import { getColor } from '../../Theme'

interface DisabledProps {
  disabled: boolean
}

interface ButtonProps extends DisabledProps {
  halveTopmargin: boolean
  customTopMargin?: string
}

const ButtonStyle = styled.div<ButtonProps>`
  background-color: ${(p) =>
    p.disabled ? getColor(p.theme, ['grayLight'], ['nightGray']) : '#2479B3'};
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 56px;
  margin-top: ${(p) =>
    p.customTopMargin ? p.customTopMargin : p.halveTopmargin ? '16px' : '32px'};
  margin-bottom: 16px;
  justify-content: center;
`

const ButtonText = styled.div<DisabledProps>`
  color: ${(p) => (p.disabled ? getColor(p.theme, ['black'], ['grayBlue']) : 'white')};
`

type Props = {
  t: TFunction
  validate: () => boolean
  setError: (arg0: string) => void
  errorKey: string
  onClick: () => void
  text?: string
  halveTopMargin?: boolean
  customTopMargin?: string
  disabled?: boolean
}

const SearchButton = ({
  t,
  validate,
  setError,
  errorKey,
  onClick,
  text = 'menu.search',
  halveTopMargin = false,
  disabled = false,
  customTopMargin,
}: Props) => (
  <ButtonStyle
    halveTopmargin={halveTopMargin}
    disabled={disabled}
    customTopMargin={customTopMargin}
    onClick={() => {
      if (validate() && !disabled) {
        return onClick()
      }
      if (!disabled) setError(t(errorKey))
    }}
  >
    <ButtonText disabled={disabled}>{t(text)}</ButtonText>
  </ButtonStyle>
)

export default SearchButton
