import { isEqual } from 'lodash'

import {
  CLEAR_LAST_SEARCHED,
  CLEAR_TOWING_VEHICLE,
  REFETCH_TOWING_VEHICLE,
  START_FETCHING_TOWING_VEHICLE,
  TOWING_ERROR,
  UPDATE_LAST_SEARCHED,
  UPDATE_TOWING_VEHICLE,
  UPDATE_TOWING_VEHICLES,
} from '../actions/towingVehicle'
import { TowingAction } from '../actions/towingVehicle'
import { TowingVehicleSearchState } from '../types'

export function replaceWithCompound<
  T extends {
    readonly vehicleCompound: string
  },
>(map: Record<string, T>, value: T): Record<string, T> {
  const vehicleCompound = value.vehicleCompound
  if (map[vehicleCompound] && isEqual(map[vehicleCompound], value)) {
    return map
  }
  return { ...map, [vehicleCompound]: value }
}

export function replaceManyWithCompound<
  T extends {
    readonly vehicleCompound: string
  },
>(map: Record<string, T>, objects: Array<T>): Record<string, T> {
  return objects.reduce(replaceWithCompound, map)
}

export const initialState = {
  loading: false,
  error: '',
  towingVehiclesByCompound: {},
  lastSearched: {
    vehicleType: '',
    vehicleNumber: '',
    formName: '',
  },
}

const towingVehicleReducer = (
  state: TowingVehicleSearchState = initialState,
  action: TowingAction
): TowingVehicleSearchState => {
  switch (action.type) {
    case START_FETCHING_TOWING_VEHICLE:
      return {
        ...state,
        loading: true,
        error: '',
      }

    case UPDATE_TOWING_VEHICLE: {
      return {
        ...state,
        error: '',
        loading: false,
        towingVehiclesByCompound: replaceWithCompound(
          state.towingVehiclesByCompound,
          action.payload
        ),
      }
    }

    case UPDATE_TOWING_VEHICLES: {
      return {
        ...state,
        error: '',
        loading: false,
        towingVehiclesByCompound: replaceManyWithCompound(
          state.towingVehiclesByCompound,
          action.payload
        ),
      }
    }

    case TOWING_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CLEAR_TOWING_VEHICLE:
      return initialState

    case REFETCH_TOWING_VEHICLE:
      return {
        ...state,
        error: '',
        loading: false,
        towingVehiclesByCompound: replaceWithCompound(
          state.towingVehiclesByCompound,
          action.payload
        ),
      }

    case UPDATE_LAST_SEARCHED:
      return {
        ...state,
        lastSearched: action.payload,
      }

    case CLEAR_LAST_SEARCHED:
      return {
        ...state,
        lastSearched: {
          vehicleType: '',
          vehicleNumber: '',
          formName: '',
        },
      }

    default:
      return state
  }
}

export default towingVehicleReducer
