import styled from '@emotion/styled'
import { Router } from 'found'
import { TFunction } from 'i18next'
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'

import Towing from '../../icons/Towing'
import {
  getVehicleNumberFromTowedLocomotive,
  getVehicleTypeFromTowedLocomotive,
  locomotiveIsInTowing,
  vehicleStates,
} from '../../lib/towingUtils'
import { currentVehicleStateSelector } from '../../Selectors'
import { color, defaultTextColor, theme } from '../../Theme'
import { TowingVehicle, TowingVehiclePattern } from '../../types'
import TaskButton from './TaskButton'

const ConfirmedText = styled.div`
  ${theme.text('mediumSmall', 'content', 'bold')};
  color: ${(p) => (p.color ? p.color : defaultTextColor)};
`

const Text = styled.span`
  ${theme.text('mediumSmall', 'content')};
  color: ${(p) => (p.color ? p.color : defaultTextColor)};
`

const TowingRowContainer = styled.div`
  ${theme.layout.flexRow};
  width: 100%;
  justify-content: space-between;
`

const TowingRowColumn = styled.div`
  ${theme.layout.flexColumn};
  width: 100%;
  color: ${(p) => (p.color ? p.color : '')};
`

const BorderedTowingRowColumn = styled(TowingRowColumn)`
  border: 2px solid;
  color: red;
  ${theme.spacing.sides('small')};
`
const InnerColumn = styled(TowingRowColumn)`
  align-items: start;
`

const LinkTextContainer = styled.a`
  ${theme.text('mediumSmall')};
  color: ${defaultTextColor};
`

type Props = {
  t: TFunction
  router: Router
  vehicleType?: string
  towingVehicle?: TowingVehicle
  activeFormId?: string
  vehicleCompound?: string
  locomotive: string
  towingPatterns: Array<TowingVehiclePattern>
  createTowingVehicle: (inferredVehicleType: string, inferredVehicleNumber: string) => void
}

type TowingTextProps = {
  vehicleType: string
  vehicleNumber: string
  text: string
  color?: string
  handleOpen: () => void
}

const TowingTextContainer = ({
  vehicleType,
  vehicleNumber,
  text,
  color,
  handleOpen,
}: TowingTextProps) => (
  <TowingRowColumn>
    <TowingRowContainer>
      <ConfirmedText>Hinattava kalusto</ConfirmedText>
    </TowingRowContainer>
    <TowingRowContainer>
      <InnerColumn>
        <Fragment>
          <ConfirmedText>
            {vehicleType} {vehicleNumber}
          </ConfirmedText>
          <Text>{text}</Text>
        </Fragment>
      </InnerColumn>
      <TaskButton onClick={handleOpen} background={color}>
        <Towing iconSize="smallest" />
      </TaskButton>
    </TowingRowContainer>
  </TowingRowColumn>
)

type TowingWarningContainerProps = {
  vehicleType: string
  vehicleNumber: string
  handleOpen: () => void
  color?: string
  t: TFunction
}

const TowingWarningContainer = ({
  vehicleType,
  vehicleNumber,
  handleOpen,
  color,
  t,
}: TowingWarningContainerProps) => (
  <BorderedTowingRowColumn>
    <TowingRowContainer>
      <InnerColumn>
        <Text color="red">
          <b>
            {vehicleType} {vehicleNumber}
          </b>{' '}
          {t('locomotiveInTowingWarning')}
        </Text>
      </InnerColumn>
      <TaskButton onClick={handleOpen} background={color}>
        <Towing iconSize="smallest" />
      </TaskButton>
    </TowingRowContainer>
  </BorderedTowingRowColumn>
)

// TODO: handle missing vehicle data. Should getting the data be delegated to this component instead of higher up in the tree?
// TODO: add translations
const TaskTowingRow = ({
  towingVehicle,
  vehicleCompound,
  locomotive,
  towingPatterns,
  router,
  createTowingVehicle,
  t,
}: Props) => {
  const openFormState = (formStateId: string | null | undefined, type: string) => {
    if (formStateId && type) {
      router.push(`/towing/${formStateId}/${type}`)
    }
  }

  const isInTowing = locomotiveIsInTowing(locomotive)
  const vehicleState = currentVehicleStateSelector(towingVehicle)

  if (
    (!towingVehicle || !vehicleCompound || !vehicleState || !towingVehicle.activeFormId) &&
    isInTowing
  ) {
    const inferredVehicleNumber = getVehicleNumberFromTowedLocomotive(locomotive)
    const inferredVehicleType = getVehicleTypeFromTowedLocomotive(locomotive, towingPatterns)
    if (!inferredVehicleNumber || !inferredVehicleType) {
      return (
        <ConfirmedText color={color('red')}>
          {t('missingTowingParamsForLocomotive', { locomotive })}
        </ConfirmedText>
      )
    }
    return (
      <Fragment>
        <TowingTextContainer
          vehicleType={inferredVehicleType}
          vehicleNumber={inferredVehicleNumber}
          text={`${t('locomotiveNotTowable')}, ${t(
            'completeLocomotiveTowingSetup'
          ).toLowerCase()}!`}
          color={color('red')}
          handleOpen={() => createTowingVehicle(inferredVehicleType, inferredVehicleNumber)}
        />
      </Fragment>
    )
  }

  // TODO: handle locomotives in towing and locomotives that are not in towing separately
  if (!isInTowing) {
    if (vehicleState && vehicleState.status !== vehicleStates.READY) {
      let formType = 'finish'
      if (
        vehicleState &&
        (vehicleState.status === vehicleStates.IN_SETUP ||
          vehicleState.status === vehicleStates.START_IN_PROGRESS)
      ) {
        formType = 'setup'
      }
      return (
        <Fragment>
          <TowingWarningContainer
            vehicleType={towingVehicle.vehicleType}
            vehicleNumber={towingVehicle.vehicleNumber}
            color={color('red')}
            handleOpen={() => openFormState(towingVehicle.activeFormId, formType)}
            t={t}
          />
        </Fragment>
      )
    } else {
      return null
    }
  }

  if (!vehicleState) {
    //unreachable, but flow cant infer this
    return null
  }

  // TODO: abstract common functionality
  // TODO: handle IN_PROGRESS states
  if (
    vehicleState.status === vehicleStates.HANDOVER ||
    vehicleState.status === vehicleStates.SETUP_DONE ||
    vehicleState.status === vehicleStates.START_IN_PROGRESS
  ) {
    // "ei hinauskunnossa" "Viimeistele"
    return (
      <Fragment>
        <TowingTextContainer
          vehicleType={towingVehicle.vehicleType}
          vehicleNumber={towingVehicle.vehicleNumber}
          text={`${t('locomotiveNotTowable')}, ${t('completeLocomotiveTowingSetup').toLowerCase()}`}
          color={color('primaryYellow')}
          handleOpen={() => openFormState(towingVehicle.activeFormId, 'setup')}
        />
      </Fragment>
    )
  } else if (vehicleState.status === vehicleStates.IN_TOWING) {
    // "hinauksessa," "hinauskunto tarkistettu" "päätä hinaus" "Noudata vetokaluston hinausohjeiden yleisein osan vaatimuksia"
    return (
      <Fragment>
        <TowingTextContainer
          vehicleType={towingVehicle.vehicleType}
          vehicleNumber={towingVehicle.vehicleNumber}
          text={`${t('towedLocomotiveChecked')}`}
          handleOpen={() => openFormState(towingVehicle.activeFormId, 'finish')}
        />
        <InnerColumn>
          <LinkTextContainer
            href="https://vrgroup.sharepoint.com/sites/Turvallisuusohjeet/Jaetut%20asiakirjat/Rautatieturvallisuusohjeet/Vetokaluston_hinausohjeet.pdf"
            target="_blank"
            rel="noreferrer"
          >
            {t('locomotiveFollowGeneralInstructions')}
          </LinkTextContainer>
        </InnerColumn>
      </Fragment>
    )
  } else if (
    vehicleState.status === vehicleStates.IN_SETUP ||
    vehicleState.status === vehicleStates.READY
  ) {
    // "ei hinauskunnossa" "aseta hinauskuntoon"
    return (
      <Fragment>
        <TowingTextContainer
          vehicleType={towingVehicle.vehicleType}
          vehicleNumber={towingVehicle.vehicleNumber}
          text={`${t('locomotiveNotTowable')}, ${t('locomotiveSetupTowing').toLowerCase()}!`}
          color={color('red')}
          handleOpen={() => openFormState(towingVehicle.activeFormId, 'setup')}
        />
      </Fragment>
    )
  } else if (
    vehicleState.status === vehicleStates.FINISH_IN_PROGRESS ||
    vehicleState.status === vehicleStates.STOP_IN_PROGRESS
  ) {
    return (
      <Fragment>
        <TowingTextContainer
          vehicleType={towingVehicle.vehicleType}
          vehicleNumber={towingVehicle.vehicleNumber}
          text={`${t('locomotiveNotTowable')}, ${t('locomotiveSetupTowing').toLowerCase()}!`}
          color={color('primaryYellow')}
          handleOpen={() => openFormState(towingVehicle.activeFormId, 'finish')}
        />
      </Fragment>
    )
  } else {
    // TODO: handle all unknown states
    return null
  }
}

export default withTranslation()(TaskTowingRow)
