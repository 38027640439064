import { Button, Typography } from '@mui/material'
import { useParams } from 'found'
import { useTranslation } from 'react-i18next'

import { useTypedDispatch, useTypedSelector } from '../../..'
import { startWorkshopBookingAction } from '../../../actions/serviceOrders'
import {
  selectStartWorkshopBookingStatus,
  selectWorkshopBookingId,
  selectWorkshopBookingStatus,
} from '../../../reducers/serviceOrders/serviceOrdersSlice'
import LoadingIndicator from '../../LoadingIndicator'
import NotificationBar from '../../notification/NotificationBar'

const WorkshopBokingStatus = (): JSX.Element => {
  const { t } = useTranslation()
  const { orderId, activityId } = useParams()

  const dispatch = useTypedDispatch()

  const workshopBookingStatus = useTypedSelector((state) => selectWorkshopBookingStatus(state))
  const startWBActionStatus = useTypedSelector((state) => selectStartWorkshopBookingStatus(state))

  const workshopBookingId = useTypedSelector((state) => selectWorkshopBookingId(state))

  const startWorkshopBooking = () =>
    dispatch(
      startWorkshopBookingAction({ orderId, activityId, workshopBookingId: workshopBookingId })
    )

  switch (workshopBookingStatus?.toUpperCase()) {
    case 'FREE':
      return (
        <Button
          variant="submitButtonFullWidth"
          disabled={startWBActionStatus === 'loading'}
          endIcon={startWBActionStatus === 'loading' && <LoadingIndicator size="small" />}
          onClick={() => startWorkshopBooking()}
        >
          <Typography variant="subtitle1">{t('serviceOrder.actions.startWB')}</Typography>
        </Button>
      )
    case 'START':
      return <NotificationBar text={t('serviceOrder.actions.WBStart')} neutralContent />
    case 'HANDOVER':
      return <NotificationBar text={t('serviceOrder.actions.WBHandover')} neutralContent />
    default:
      return <></>
  }
}

export default WorkshopBokingStatus
