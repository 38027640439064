import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import moment from 'moment-timezone'
import React, { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { sendCustomerFeedback } from '../actions/api'
import ActionError from '../components/ActionError'
import Button from '../components/button/Button'
import ConstrainToColumn from '../components/ConstrainToColumn'
import LoadingIndicator from '../components/LoadingIndicator'
import Page from '../components/page/Page'
import ArrowRight from '../icons/ArrowRight'
import { visitURL } from '../lib/url'
import { isMobileSelector } from '../Selectors'
import { defaultTextColor, getColor, theme } from '../Theme'
import { Action, AppState, Dispatch, Timestamp } from '../types'

const Container = styled(ConstrainToColumn)`
  ${theme.spacing.all('large')};
`

const Form = styled.form``

const Title = styled.h1`
  ${theme.text('larger', 'content', 'bold')};
  color: ${defaultTextColor};
  text-align: center;
  margin: 0;
`

const DescriptionArea = styled.textarea`
  width: 99%;
  margin-top: 10px;
  background: ${(p) => getColor(p.theme, ['white'], ['grayBackground'])};
  color: ${(p) => getColor(p.theme, ['black'], ['black'])};
  ${theme.text('normal', 'content')};
`

const SendButton = styled(Button)`
  background: ${(p) =>
    p.disabled
      ? getColor(p.theme, ['grayPink'], ['grayPink'])
      : getColor(p.theme, ['primaryBlue'], ['primaryBlue'])};
`

const FinishTitle = styled.h1`
  ${theme.text('larger', 'content', 'bold')};
  color: ${defaultTextColor};
  text-align: center;
  margin: auto;
  ${theme.spacing.top('large')}
  ${theme.spacing.bottom('larger')}
`

type Props = {
  t: TFunction
  loading: boolean
  error: string
  isMobile: boolean
  sendFeedback: (arg0: string, arg1: string) => void
  visitOptionsPage: () => void
}

const DevPage = ({ t, loading, error, isMobile, sendFeedback, visitOptionsPage }: Props) => {
  const [feedback, setFeedback] = useState('')
  const [state, setState] = useState('start')

  const send = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    !loading && feedback !== '' && sendFeedback(feedback, moment().toISOString())
    setState('sent')
  }

  if (state === 'sent' && !loading) {
    if (error) setState('error')
    else setState('confirmed')
  }

  return (
    <Page menu="options">
      <Container>
        {loading ? (
          <LoadingIndicator size={'normal'} padded={true} />
        ) : (
          <Fragment>
            {state === 'start' && (
              <Fragment>
                <Title>{t('relayCustomerFeedback')}</Title>
                <Form>
                  <DescriptionArea
                    name="description"
                    placeholder={t('writeCustomerFeedback')}
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    rows={6}
                  />
                  <SendButton onClick={send} disabled={feedback === ''}>
                    {t('send')}
                  </SendButton>
                </Form>
              </Fragment>
            )}
            {state === 'error' && (
              <ActionError
                error={error}
                action={() => setState('start')}
                actionIcon={<ArrowRight iconSize="large" />}
              />
            )}
            {state === 'confirmed' && (
              <Fragment>
                <FinishTitle>{t('feedbackGiven')}</FinishTitle>
                <Button onClick={visitOptionsPage}>{t('return')}</Button>
              </Fragment>
            )}
          </Fragment>
        )}
      </Container>
    </Page>
  )
}

const mapStateToProps = (state: AppState) => ({
  loading: state.customerFeedback.loading,
  error: state.customerFeedback.error,
  isMobile: isMobileSelector(state),
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  sendFeedback: (feedback: string, messageDateTime: Timestamp) =>
    dispatch(sendCustomerFeedback(feedback, messageDateTime) as unknown as Action),
  visitOptionsPage: () => dispatch(visitURL('/options') as unknown as Action),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DevPage))
