import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const Search = ({ iconSize, fillColor }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={size} height={iconSize} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          d="M13.0469231,2.37846154 C11.6220513,0.953333333 9.72769231,0.168717949 7.7125641,0.168717949 C5.6974359,0.168717949 3.80333333,0.953589744 2.37846154,2.37846154 C0.953333333,3.80333333 0.168717949,5.69769231 0.168717949,7.71282051 C0.168717949,9.72794872 0.953589744,11.6223077 2.37846154,13.0474359 C3.80307692,14.4725641 5.69717949,15.2569231 7.7125641,15.2569231 C9.7274359,15.2569231 11.6220513,14.4723077 13.0471795,13.0474359 C15.9882051,10.1058974 15.9882051,5.31974359 13.0469231,2.37846154 Z M12.3220513,12.3223077 C11.0907692,13.5535897 9.45358974,14.2312821 7.7125641,14.2312821 C5.97153846,14.2312821 4.33461538,13.5533333 3.10358974,12.3223077 C1.87230769,11.0910256 1.19435897,9.45384615 1.19435897,7.71282051 C1.19435897,5.97179487 1.8725641,4.33487179 3.10358974,3.10358974 C4.33461538,1.87230769 5.97153846,1.19435897 7.7125641,1.19435897 C9.45358974,1.19435897 11.0905128,1.8725641 12.3217949,3.10358974 C14.8633333,5.64538462 14.8633333,9.78076923 12.3220513,12.3223077 Z M19.6810256,18.9558974 L15.3792308,14.654359 C15.1789744,14.4541026 14.854359,14.4541026 14.6541026,14.654359 C14.4538462,14.8546154 14.4538462,15.1792308 14.6541026,15.3794872 L18.955641,19.6807692 C19.0558974,19.7810256 19.1869231,19.8310256 19.3182051,19.8310256 C19.4494872,19.8310256 19.5805128,19.7810256 19.6807692,19.6807692 C19.8812821,19.4807692 19.8812821,19.1561538 19.6810256,18.9558974 Z"
          fill={fillColor ?? 'currentColor'}
        />
      </g>
    </svg>
  )
}

export default Search
