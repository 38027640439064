import type { Timestamp } from './types'

type Change = {
  type: string
  title: string
  description: string
}

type Version = {
  number: string
  timestamp: Timestamp
  changes: Array<Change>
}

type TypedChange = (title: string, description: string) => Change

const version = (number: string, timestamp: string, changes: Array<Change>): Version => ({
  number,
  timestamp,
  changes,
})

const changeType =
  (type: string) =>
  (title: string, description: string): Change => ({
    type,
    title,
    description,
  })

const newFeature: TypedChange = changeType('new-feature')
const featureChange: TypedChange = changeType('change')
const bugfix: TypedChange = changeType('bugfix')

const changelog_test: Array<Version> = [
  version('5.6.12', '2024-09-11T11:00:00', [
    bugfix(
      'Lähiliikenteen työvuorot',
      'Lähiliikenteen työvuoroilla ollut puuttuvia lähtöraiteita, korjattua taustajärjestelmän käsittelyä.'
    ),
    bugfix(
      'Ajotietotuloste',
      'Ajotietotulosteen latauksessa näkynyt virheellinen varoitusteksti Kuplan käytöstä. Teksti poistettu.'
    ),
    newFeature(
      'Yleinen',
      'Tekninen vienti, jolla ei ole vaikutusta tuotantoympäristössä. SM-liikenteen merkittävä häiriötilanne ja lähiliikenteen työvuorojen pseudonymisointi.'
    ),
  ]),
  version('5.7.0-beta-5', '2024-09-05T16:00:00', [
    featureChange('Lahiksen työvuorot', 'Lahiksen työvuorot näkyvät pseudonymisoituna devilla'),
  ]),
  version('5.7.0-beta-3', '2024-06-28T16:00:00', [
    featureChange(
      'Etusivun Radalla-ilmoitukset',
      'Ovat nyt jaettu tänään ja vanhemmat kategorioihin'
    ),
    featureChange(
      'Etusivun Radalla-ilmoitukset',
      'Eivät näytä yli viikon vanhoja ilmoituksia vaikka nämä olisivat aktiivisia'
    ),
    featureChange(
      'Etusivun Radalla-ilmoitukset',
      'Lisätty muokattu aika jos ilmoitus on luonnin jälkeen muokattu'
    ),
    featureChange(
      'Etusivun Radalla-ilmoitukset',
      'Viestien kielet ovat nyt organisoitu FI, SE, EN'
    ),
  ]),
  version('5.7.0-beta-2', '2024-06-28T16:00:00', [
    newFeature(
      'SM-häiriöviestit',
      'SM lähiliikenteen merkittävät häriötilanteiden ilmoitukset, etusivu ilmoitus ja aikaisempien viestin historia sivu'
    ),
  ]),
  version('5.6.11', '2024-09-03T13:30:00', [
    featureChange(
      'Liito-häiriöviestit',
      'Liito-häiriöviestit sallittu kaikille kaukoliikenteen konduktööreille.'
    ),
  ]),
  version('5.6.10', '2024-08-22T11:00:00', [
    bugfix('Lisää sivu', 'Piilotettu ei-admin -käyttäjiltä linkki kehittäjien työkalusivuun.'),
  ]),
  version('5.6.3', '2024-06-19T10:00:00', [
    bugfix(
      'Liito-häiriöviestit',
      'Korjaus Liito-häiriöviestien lataamiseen; viestejä ladataan vasta kun käyttäjän henkilöryhmä on tiedossa.'
    ),
  ]),
  version('5.6.0', '2024-06-05T12:00:00', [
    newFeature(
      'Liito-häiriöviestit',
      'Liito-häiriöviestien näkyminen konduktöörien-pilottiryhmälle.'
    ),
  ]),
  version('5.5.5', '2024-03-25T09:00:00', [
    featureChange('Taustajärjestelmä', 'Optimointeja taustajärjeselmän tekemiin kyselyihin'),
    bugfix('Vikailmoittaminen', 'Korjaus tiedostojen lataamiseen'),
    bugfix('Veksi / Lähiveksi -ilmoitukset', 'Korjaus ilmoitusten näkymiseen'),
  ]),
  version('5.5.2', '2024-03-06T11:00:00', [
    bugfix('Huoltotyöt', 'Korjaus huoltokuljettajien roolin tunnistukseen'),
  ]),
  version('5.5.1', '2024-03-06T11:00:00', [
    bugfix('Henkilökuntahaku', 'Korjaus tietojen näyttöön'),
  ]),
  version('5.3.1', '2024-03-05T09:00:00', [
    featureChange(
      'Vikailmoittaminen',
      'Yksinkertaistettu vikailmoittamisen lomaketta, lisätty mahdollisuus ladata myös suljettuja vikoja'
    ),
    bugfix('Vikailmoittaminen', 'Korjaus vikojen statuksen käsittelyyn'),
    bugfix('Vikatiedot', 'Kriittisyysluokan 3 näkyminen oikein jos vialla on käyttörajoite'),
  ]),
  version('5.3.0-beta-2', '2023-02-19T16:00:00', [
    newFeature('Huoltotyöt', 'KEN-1030: Huoltokujettajille mahdollisuus tehdä pikkuhuoltoja'),
    featureChange(
      'Vikailmoittaminen',
      'KEN-1068: Parannuksia toiminnallisuuteen käyttäjäpalautteen pohjalta'
    ),
    bugfix('Vikailmoittaminen', 'KEN-1071: hyväksytty status käsitellään suljettuna statuksena'),
  ]),
  version('5.1.2-beta-3', '2023-12-07T17:00:00', [
    bugfix(
      'Ajotietotuloste',
      'KEN-1047: korruptoitunut tuloste ja virheellisellä junanumerolla hakeminen korjattu'
    ),
  ]),
  version('5.1.2-beta-2', '2023-12-01T09:00:00', [
    featureChange('Ajotietotuloste', 'KEN-1047: Ajotietotuloste haetaan Myllyn kautta'),
    newFeature('Yleinen', 'KEN-1058: Uusi AD-ryhmä lähiliikenteen komennuksia tekeville'),
    featureChange(
      'Haastot',
      'KEN-1036: Haastoviestien vastaanottajat siirretty ylläpidettäväksi Contentfuliin'
    ),
  ]),
  version('5.1.1-beta-1', '2023-11-09T14:30:00', [
    featureChange('Yleinen', 'Korjaus versiopäivityksen aiheuttamiin ongelmiin'),
  ]),
  version('5.0.8-beta-1', '2023-11-02T12:00:00', [
    featureChange('Työvuorohaku', 'KEN-630: Lähiksen työvuorojen haku'),
  ]),
  version('5.0.2', '2023-10-11T17:30:00', [
    bugfix('Kokoonpanohaku', 'KEN-1045: Korjaus kokoonpanotietojen hakemiseen.'),
  ]),
  version('5.0.1', '2023-10-10T17:30:00', [
    bugfix(
      'Työvuoronäkymä',
      'KEN-1044: Korjaus junahenkilökunnan näyttämisessä lähiliikenteen työvuorolla'
    ),
    bugfix('Yleinen', 'KEN-1043: Korjauksia yöteeman väreihin'),
  ]),
  version('5.0.0-beta-5', '2023-09-28T18:00:00', [
    featureChange('Vikailmoitus', 'Vikojen listaus ja ilmoittaminen'),
  ]),
  version('4.17.0-beta-5', '2023-09-11T16:00:00', [
    featureChange(
      'Työvuoropalaute',
      'KEN-1034: Työvuoropalaute vapaatekstikentän pituusrajoituksen huomiointi'
    ),
    featureChange('Työvuoronäkymä', 'KEN-1031: Lähiliikenteen työvuorojen päivittyminen näkymään'),
    featureChange(
      'Työvuorohaku',
      'KEN-1023: Työvuorohaussa näkyy tehtävien ajat, ei työvuoron aikoja'
    ),
    bugfix('Täsmällisyystiedot', 'KEN-980: Täsmällisyystiedot kehäradan junissa'),
  ]),
  version('4.17.0-beta-4', '2023-09-05T16:00:00', [
    featureChange(
      'Kokoonpanotiedot',
      'KEN-1019: Pendolinot näkyvät Kentässä konduktööreille aina muuttuneella kokoonpanolla'
    ),
  ]),
  version('4.17.0-beta-2', '2023-08-28T12:15:00', [
    newFeature(
      'Kokoonpanotiedot',
      'KEN-1016: Kokoonpanotietojen pitäisi leventyä kun puhelimen kääntää vaakasuuntaan'
    ),
  ]),
  version('4.17.0-beta-1', '2023-08-24T16:00:00', [
    featureChange(
      'Käyttäjäroolit',
      'KEN-1027: Lisätty lähiliikenteen henkilöstönohjaukselle käyttäjäryhmä. Rajattu kentän ominaisuuksia tälle ryhmälle'
    ),
    featureChange('Yleinen', 'KEN-1020: Päivitetty lisää-sivun PowerBI-linkki'),
  ]),
  version('4.15.0-beta-1', '2023-08-07T12:15:00', [
    newFeature('Työvuoronäkymä', 'KEN-987: Päivitetty palkattoman ruokatauon mäppäyslogiikkaa'),
  ]),
  version('4.14.0-beta-2', '2023-06-21T12:00:00', [
    featureChange('Työvuoropalaute', 'KEN-991: Palaute työvuorosta pois lähiksen kuljettajilta'),
    bugfix('Työvuoropalaute', 'KEN-992: eSälli palautteen antaminen, spinneri jää pyörimään'),
    bugfix(
      'Käyttörajoitteet',
      'KEN-1010: Käyttörajoitehaku ei toimi oikein jos kalustolla yli 30 rajoitetta'
    ),
    bugfix('Kalenteri', 'KEN-1017: Google kalenteriviennissä ongelmia'),
    featureChange('Yleinen', 'KEN-1018: Google Analytics poistaminen'),
  ]),
  version('4.13.0-beta-1', '2023-05-02T10:00:00', [
    newFeature(
      'Käyttöoikeudet',
      'KEN-1008: Logistiikan RO-kuljettajien ja vaihtotyönjohtajien eriyttäminen omalle roolilleen'
    ),
    bugfix('Käyttöoikeudet', 'KEN-994: Kentän frontin sessiovanhenemisen parantaminen'),
    bugfix('Energiatehokkuus', 'KEN-1011: Energiatehokkuuden korjaukset'),
  ]),
  version('4.12.0-beta-2', '2023-04-18T15:00:00', [
    newFeature(
      'Lähiliikenteen työvuorot',
      'KEN-995: Lisätään lähiliikenteen palkaton ruokatauko riveille kellonajat'
    ),
    bugfix(
      'Lähiliikenteen työvuorot',
      'KEN-990: Osavälin kulkevat konduktöörit näkyvät koko matkan'
    ),
  ]),
  version('4.11.0-beta-9', '2023-04-17T08:30:00', [
    newFeature('Ajoympäristö', 'KEN-1002: Luotu uusi test-kentta.vr.fi -ajoympäristö'),
  ]),
  version('4.11.0-beta-8', '2023-04-03T16:00:00', [
    newFeature('Käyttöoikeudet', 'KEN-1007: Lisätty ad-ryhmä junahenkilökunnalle'),
  ]),
  version('4.11.0-beta-7', '2023-03-28T16:00:00', [
    newFeature(
      'Energiatehokkuus',
      'KEN-1005: Vain käyttäjän omien työvuorojen lähetys Commuter Enricherille'
    ),
  ]),
  version('4.11.0-beta-6', '2023-03-24T16:00:00', [
    newFeature('Energiatehokkuus', 'KEN-1004: Energiatehokuuden parannukset'),
  ]),
  version('4.11.0-beta-5', '2023-03-24T13:00:00', [
    newFeature(
      'Veksi / LähiVeksi ilmoitukset',
      'KEN-1001: Näytetään Veksi / LähiVeksi ilmoitukset kuljettajille tänään sivulla'
    ),
  ]),
  version('4.11.0-beta-4', '2023-03-20T12:00:00', [
    newFeature('Työvuoroilmoitukset', 'KEN-1001: Työvuoroilmoitukset'),
  ]),
  version('4.11.0-beta-3', '2023-03-17T16:30:00', [
    featureChange('Energiatehokkuus', 'KEN-993/KEN-1000: Energiatehokkuuden UI-parannuksia'),
  ]),
  version('4.11.0-beta-2', '2023-02-28T16:30:00', [
    featureChange('Energiatehokkuus', 'KEN-988: Korjauksia energiatehokkuuden datahakuihin'),
  ]),
  version('4.11.0-beta-1', '2023-02-14T16:30:00', [
    featureChange('Energiatehokkuus', 'KEN-988: Energiatehokkuus tänään ja työvuoronäkymään'),
  ]),
  version('4.10.6-beta-2', '2023-01-31T14:30:00', [
    featureChange('Hinauksenhallinta', 'KEN-984: Korjauksia hinauksenhallintaan Sm3-junien osalta'),
  ]),
  version('4.10.6-beta-1', '2023-01-24T14:30:00', [
    featureChange('Hinauksenhallinta', 'KEN-984: Uusien kalustojen luotettavuusparannukset'),
  ]),
  version('4.10.5-beta-1', '2023-01-09T13:00:00', [
    bugfix(
      'Hinauksenhallinta',
      'KEN-981: Korjaus tilanteeseen missä hinauksenasetuslomake on jäänyt jumiin'
    ),
  ]),
  version('4.10.4', '2023-01-03T13:00:00', [
    bugfix('Elpymisprosentit', 'Korjaus lähiliikenteen työvuorojen elpymisprosenttien näkyvyyteen'),
  ]),
  version('4.10.1-beta-1', '2022-12-21T13:00:00', [
    featureChange('Hinauksenhallinta', 'KEN-979: Hinauksen yleisohjeen linkin päivittäminen'),
  ]),
  version('4.10.0-beta-1', '2022-11-14T10:00:00', [
    featureChange(
      'Elpymisprosentit',
      'KEN-977: Näytetään lähiliikenteen kuljettajille työvuoron elpymisprosentit, mikäli tiedossa'
    ),
  ]),
  version('4.9.0-beta-2', '2022-10-14T15:00:00', [
    featureChange(
      'Hinauksenhallinta',
      'KEN-975: Pieniä tyyliparannuksia hinauksenhallinnan tehtyjen tehtävien näyttämiseen'
    ),
  ]),
  version('4.9.0-beta-1', '2022-10-11T12:00:00', [
    featureChange(
      'Hinauksenhallinta',
      'KEN-975: Hinauksenhallinnan tehtyjen tehtävien näyttäminen paikallapysymisen varmistaminen -lomakkeella'
    ),
  ]),
  version('4.8.0-beta-1', '2022-09-29T16:00:00', [
    featureChange('Kokoonpanomuutokset', 'KEN-974: Kokoonpanohakujen suorituskykyparannukset'),
  ]),
  version('4.7.3', '2022-09-22T11:00:00', [
    bugfix('Työvuoronäkymä', 'Korjaus vikatietojen hakuun'),
  ]),
  version('4.7.0-beta-15', '2022-09-16T13:30:00', [
    bugfix('Vikatiedot', 'KEN-947: Korjattu vikatietojen värejä yömoodissa'),
    bugfix('Vikatiedot', 'KEN-947: KEN-947: parannettu MOTO-vikatietojen parsintaa'),
    featureChange('Vikatiedot', 'KEN-947: MOTO-kalustoille haetaan litterat contentfulista'),
    bugfix(
      'Täsmällisyystiedot',
      'Parannuksia täsmällisyystietojen luotettavuuteen ja täsmällisyystietosivuun. Korjattu täsmällisyystietojen haku vuorokausirajan ylittävissä junissa.'
    ),
  ]),
  version('4.7.0-beta-14', '2022-09-03T22:30:00', [
    featureChange('Kokoonpanomuutokset', 'KEN-960: Parannettu kokoonpanomuutosten asettelua'),
  ]),
  version('4.7.0-beta-13', '2022-09-03T22:30:00', [
    featureChange(
      'Kokoonpanomuutokset',
      'KEN-960: Taustajärjestelmään välitetään enemmän tietoa kulkupäivän päättelemiseksi.'
    ),
  ]),
  version('4.7.0-beta-12', '2022-09-03T13:30:00', [
    bugfix(
      'Push-viestit',
      'Parannettu promisejen ja virheiden käsittelyä. Rejektoidut promiset käsitellään lokaalisti.'
    ),
    featureChange(
      'Taustajärjestelmä',
      'Lisätty Point-in-Time recovery test-ympäristön tietokantatauluille'
    ),
    featureChange(
      'Taustajärjestelmä',
      'Lisätty Time To Live hinauksenhallinnan tietokantatauluille'
    ),
  ]),
  version('4.7.0-beta-11', '2022-09-09T13:30:00', [
    bugfix('Push-viestit', 'Parannettu virheenkäsittelyä ohjus-viestien käsittelyssä'),
    featureChange('Taustajärjestelmä', 'Parannettu globaalia virheenkäsittelyä kenttä backendissä'),
    featureChange(
      'Kokoonpanomuutokset',
      'KEN-960: parannettu kokoonpanomuutosten esitystä kentässä.'
    ),
    bugfix(
      'Hinauksenhallinta',
      'KEN-965: parannettu kalustonumeron käsittelyä Sm3-junien yhteydessä'
    ),
    featureChange(
      'Hinauksenhallinta',
      'KEN-964: parannettu varoituksen näkyvyyttä, kun ajossa on kentässä hinaukseen merkitty veturi'
    ),
    bugfix(
      'Hinauksenhallinta',
      'KEN-967: Korjattu tilanne, jossa purettavien tehtävien joukossa näkyy duplikaatteja'
    ),
  ]),
  version('4.7.0-beta-10', '2022-08-29T16:30:00', [
    featureChange(
      'Kokoonpanotiedot',
      'KEN-960: Myllyä kutsutaan rallibusin kautta. Korjattu joitain bugeja.'
    ),
    bugfix('Hinauksenhallinta', 'Parannettu purkavien toimenpiteiden tunnistamista'),
  ]),
  version('4.7.0-beta-9', '2022-08-24T16:30:00', [
    newFeature('Kokoonpanotiedot', 'KEN-960: Kokoonpanomuutokset lähiliikenteen konduktööreille'),
    bugfix('Poikkeusraportointi', 'KEN-962: Korjattu vika poikkeusraporttien tallennuksessa'),
    bugfix('Push-viestit', 'KEN-963: Korjattu vika push-viestien lähetyksen virheenkäsittelyssä.'),
  ]),
  version('4.7.0-beta-8', '2022-08-24T16:30:00', [
    bugfix('Vikatiedot', 'KEN-947: Puutteelliset moto rajoitustiedot käsitellään nyt oikein'),
  ]),
  version('4.7.0-beta-7', '2022-08-24T15:30:00', [
    bugfix('Vikatiedot', 'KEN-947: Korjattu rajoitustietojen virheen esitys'),
    featureChange(
      'Vikatiedot',
      'KEN-848 ja KEN-947: Rajoitustiedot haetaan motosta myös käytetteässä findingsV2-rajapintaa'
    ),
  ]),
  version('4.6.8-beta-1', '2022-08-17T15:30:00', [
    bugfix('Haku', 'KEN-958: Palautettu kalenteri suomalaiseksi'),
    bugfix(
      'Vikatiedot',
      'KEN-959: Korjattu vikatietonäkymän kaatuminen, kun kalustolla ei ole vikatietoja'
    ),
    featureChange('Työvuoroluettelo', 'KEN-961: Poistettu levon pituusrajoitus työvuoronäkymästä'),
  ]),
  version('4.7.0-beta-6', '2022-06-30T16:00:00', [
    featureChange(
      'Vikatiedot',
      'KEN-848: mergetty feature-haara masteriin. Testiversio tuotantopäivitystä varten.'
    ),
  ]),
  version('4.7.0-beta-5', '2022-06-23T12:00:00', [
    featureChange('Lähiliikenteen työvuorot', 'KEN-934: Parannettu työvuoron keston laskutapaa'),
    featureChange(
      'Lähiliikenteen työvuorot',
      'KEN-937: Näytetään IVU:lta tuleva shortName työtehtävän tiedoissa'
    ),
  ]),
  version('4.7.0-beta-4', '2022-06-22T14:00:00', [
    featureChange('Vikatiedot', 'KEN-848: palautettu muutokset testiympäristöön testausta varten.'),
  ]),
  version('4.7.0-beta-3', '2022-06-21T17:00:00', [
    featureChange('Vikatiedot', 'Parannettu MotoERP-tietojen käsittelyä'),
    featureChange('Vikatiedot', 'Lisätty rivinvaihtojen näkyminen vikatietojen kuvauksiin'),
  ]),
  version('4.7.0-beta-2', '2022-06-21T12:00:00', [
    featureChange('Vikatiedot', 'Palautettu MotoERP-muutokset testiympäristöön'),
  ]),
  version('4.6.8-beta-1', '2022-08-11T12:00:00', [
    featureChange('Haku', 'Muutettu päivämäärävalikot suomeksi.'),
  ]),
  version('4.6.7-beta-1', '2022-07-18T21:40:00', [featureChange('Vikatiedot', 'KEN-848')]),
  version('4.6.6-beta-1', '2022-06-21T12:00:00', [
    bugfix(
      'Kalenterilinkki',
      'Palautettu toimiva versio kalenteritiedoston generoivasta kirjastosta.'
    ),
  ]),
  version('4.7.0-beta-1', '2022-06-20T13:30:00', [
    featureChange('Vikatiedot', 'Palautettu MotoERP-muutokset testiympäristöön'),
  ]),
  version('4.6.5-beta-1', '2022-06-20T13:30:00', [
    bugfix('Käyttäjäroolit', 'Lisätty kunnossapidolle puuttuva luvitus'),
  ]),
  version('4.6.4-beta-1', '2022-06-17T16:00:00', [
    bugfix('Kokoonpanotiedot', 'KEN-798: Korjattu työvuoronäkymän latausindikaattori'),
    newFeature('Vikatiedot', 'Muutoksia MotoERP-toteutuksen tietojen formatointiin'),
    bugfix('Yleinen', 'Uloskirjautuminen ohjaa käyttäjän takaisin etusivulle'),
  ]),
  version('4.6.3-beta-1', '2022-06-16T12:00:00', [
    bugfix(
      'Hinauksenhallinta',
      'KEN-952: Poistattu hinausten haku tyhjälle listalle työvuoronäkymässä'
    ),
    bugfix('Syykoodin haasto', 'KEN-953: Korjattu haastonäkymän kaatuminen'),
    bugfix(
      'Työvuoronäkymä',
      'KEN-954: Korjattu vuoron alkamis- ja loppumisajan väritys yömoodissa'
    ),
  ]),
  version('4.6.0-beta-5', '2022-06-14T14:00:00', [
    newFeature('Vikatiedot', 'Alustava MotoERP-toteutus testausta varten'),
  ]),
  version('4.6.0-beta-4', '2022-06-14T14:00:00', [
    featureChange(
      'Käyttäjäroolit',
      'Lisätty kunnossapidolle käyttäjäryhmä. Rajattu kentän ominaisuuksia tälle ryhmälle'
    ),
  ]),
  version('4.6.0-beta-3', '2022-06-10T10:00:00', [
    bugfix('Yleinen', 'Uloskirjautuminen kirjaa käyttäjän myös ulos AAD-järjestelmästä'),
  ]),
  version('4.6.0-beta-2', '2022-06-07T15:30:00', [
    bugfix(
      'Vikatiedot',
      'Korjattu webpack-päivityksestä jäänyt vika, joka sai käyttörajoitteet-sivun kaatumaan'
    ),
  ]),
  version('4.6.0-beta-1', '2022-05-25T16:00:00', [
    featureChange('Työvuoronäkymä', 'KEN-562: Lisätty viikkonumerot työvuorolistaan'),
    bugfix(
      'Työvuoronäkymä',
      'KEN-876: Korjattu työvuoron ja työvuorolistan uudelleenlatauspainikkeen animaatio'
    ),
    featureChange(
      'Ajotietotuloste',
      'KEN-935: Kun ajotietotulosteen haku epäonnistuu tai tuloste yritetään hakea ajamiseen ilman riittäviä oikeuksia, näytetään nyt käyttöliittymässä virhe.'
    ),
    bugfix(
      'Hinauksenhallinta',
      'KEN-941: Korjattu tilanne, jossa setup-valinnan muutos ei poistanut start-vaihetta näkyvistä'
    ),
    featureChange('Hinauksenhallinta', 'KEN-942: Yhtenäistetty käännöksiä'),
  ]),
  version('4.5.0-beta-26', '2022-05-16T16:00:00', [
    featureChange(
      'Kota-sijaintitiedot',
      'KEN-929: Tarkkoja sijainteja ei enää näytetä käyttäjille. Muualle kuin tehtävän junaan paikantunutta konduktööriä ei enää erotella paikantumattomasta.'
    ),
  ]),
  version('4.5.0-beta-25', '2022-05-12T16:00:00', [
    featureChange('Yleinen', 'KEN-900: Parannus cors-konfiguraatioon'),
    featureChange(
      'Lähiliikenteen työvuorot',
      'KEN-928: Lähiliikenteen työvuorot julkaistaan nyt kolmen viikon välein'
    ),
    featureChange(
      'Lähiliikenteen työvuorot',
      'KEN-929 Konduktöörien tarkat sijainnit eivät enää näy työvuoroissa'
    ),
    featureChange(
      'Lähiliikenteen työvuorot',
      'KEN-932: Parannettu IVU:sta tulevien työvuoromuutosten ja -perumisten käsittelyä'
    ),
    bugfix('Hinauksenhallinta', 'Parannettu taustajärjestelmän validaatiota'),
  ]),
  version('4.5.0-beta-24', '2022-05-09T12:00:00', [
    featureChange('Hinauksenhallinta', 'KEN-917: Keskeneräisen hinaustiedon säilyttäminen'),
    featureChange('Hinauksenhallinta', 'KEN-916: Tuki ei-pakollisille purkutehtäville'),
    bugfix('Hinauksenhallinta', 'KEN-931: Korjaus validointivirheen näyttämiseen kalustohaussa'),
    bugfix(
      'Hinauksenhallinta',
      'KEN-930: Korjaus kuittaa valmis seuraavalle validointiin ja purkulomakkeen automaattisiin tilasiirtymiin'
    ),
  ]),
  version('4.5.0-beta-23', '2022-05-02T16:30:00', [
    featureChange(
      'Hinauksenhallinta',
      'KEN-914: Näppäimistö ei enää nouse esiin valitessa kalustoa'
    ),
    featureChange(
      'Hinauksenhallinta',
      'KEN-918: Kalustovalinnan vaihtoehdot ovat nyt aakkosjärjestyksessä'
    ),
    featureChange(
      'Hinauksenhallinta',
      'KEN-921: Estetty ongelmia aiheuttava usean ristiriitaisen muutoksen yhtäaikainen tekeminen'
    ),
  ]),
  version('4.5.0-beta-22', '2022-05-02T13:30:00', [
    featureChange(
      'Hinauksenhallinta',
      'KEN-913: Kaikki käyttökuntoiset kalustot näytetään tekstillä "Ei hinauksia"'
    ),
    bugfix(
      'Hinauksenhallinta',
      'KEN-919: Korjattu työvuoronäkymän hinausten avainnuksen generointi'
    ),
    bugfix(
      'Hinauksenhallinta',
      'KEN-924: Korjattu vika, jossa toimenpiteen kuittaminen start-vaiheelta piilottaa start-vaiheen.'
    ),
    bugfix(
      'Hinauksenhallinta',
      'KEN-921: Siirrytty käyttämään millisekunnin tarkkuutta hinauksenhallinnan aikaleimoissa'
    ),
    bugfix(
      'Hinauksenhallinta',
      'KEN-925: Korjattu vika, jossa lomake jää jumiin kun lomakkeen kuittaa seuraavalla start-vaiheessa.'
    ),
    bugfix(
      'Hinauksenhallinta',
      'KEN-926: Automaattinen tilasiirtymä suoritetaan nyt oikein, kun edeltävän vaiheen valintaa muutetaan.'
    ),
    bugfix(
      'Hinauksenhallinta',
      'KEN-930: Korjattu vika, jossa joissain tilanteissa vaadittiin purettavaksi toimenpidettä jolle ei ole määritelty purkutoimenpidettä.'
    ),
  ]),
  version('4.5.0-beta-21', '2022-04-26T16:30:00', [
    bugfix('Hinauksenhallinta', 'KEN-919: Tietokanta-avainnuksen korjaukset käyttöliittymässä'),
    featureChange('Hinauksenhallinta', 'KEN-915: augit login parannukset ja lisälokitus'),
    bugfix(
      'Hinauksenhallinta',
      'KEN-923: hinauksen aloituksen toimenpiteiden siivous paikallapysymisen varmistuksen jälkeen, korjaukset'
    ),
  ]),
  version('4.5.0-beta-20', '2022-04-22T15:30:00', [
    bugfix(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'Korjattu päivämäärätiedon käsittely jarru- ja liikennöintitarkastuksen yhteydessä.'
    ),
  ]),
  version('4.5.0-beta-17', '2022-04-13T15:30:00', [
    bugfix(
      'Hinauksenhallinta',
      'KEN-906: Kaluston tila päivitetään oikein, kun hinaukseen asetus perutaan perumalla toimenpiteet.'
    ),
  ]),
  version('4.5.0-beta-16', '2022-04-12T15:30:00', [
    bugfix(
      'Hinauksenhallinta',
      'KEN-903: Lomakehaku käyttää nyt kalustonumeroa oikean lomakepohjan päättelyyn'
    ),
  ]),
  version('4.5.0-beta-15', '2022-04-12T11:30:00', [
    featureChange(
      'Hinauksenhallinta',
      'KEN-889: Parannettu purkavien toimenpiteiden käsitelyä lomakkeella'
    ),
    featureChange(
      'Hinauksenhallinta',
      'KEN-909: Lomakepohjan vaihtumisen jäljeltä yli jääneet toimenpiteet esitetään lomakkeella'
    ),
    newFeature(
      'Hinauksenhallinta',
      'KEN-906: Kenttä tunnistaa poistuneen lomakepohjan ja suorittaa hinaukselle automaattisesti migraation uuteen lomakepohjaan'
    ),
    featureChange(
      'Hinauksenhallinta',
      'KEN-904: Kalustonumeron käsittelyä hinauksenhallinnan kalustohaussa parannettu'
    ),
    newFeature(
      'Hinauksenhallinta',
      'KEN-885: päättyneiden hinausten henkilötietoja ei lähetetä käyttöliittymälle'
    ),
    newFeature('Taustajärjestelmä', 'KEN-818: Kentän tietokantaan lisätty Time To Live -arvot'),
    newFeature(
      'Hinauksenhallinta',
      'KEN-886: Hinauksenhallinnan tiedoille kentän tietokannassa lisätty Time To Live -arvot'
    ),
    featureChange(
      'Hinauksenhallinta',
      'KEN-903: Uudistettu kalustotyypit ja kalustonumerot yhdistävien tietojen käsittely ja haku'
    ),
  ]),
  version('4.5.0-beta-14', '2022-03-21T12:30:00', [
    bugfix(
      'Hinauksenhallinta',
      'KEN-898: Näytetään ohjeet myös purkulomakkeella ja keskeneräisen laiton purkavilla toimenpiteillä.'
    ),
  ]),
  version('4.5.0-beta-13', '2022-03-21T14:15:00', [
    featureChange(
      'Hinauksenhallinta',
      'KEN-849: Uusittu työvuoronäkymän hinauksenhallinnan toiminnallisuuden ulkoasu'
    ),
  ]),
  version('4.5.0-beta-12', '2022-03-21T12:30:00', [
    featureChange(
      'Hinauksenhallinta',
      'KEN-889: Parannettu automaattisia siirtymiä tilojen välillä. Eroteltu eri vaiheiden purkavat toimenpiteet.'
    ),
    bugfix(
      'Hinauksenhallinta',
      'KEN-899: Korjattu vika, jossa toimenpiteen peruminen STOP-vaiheessa vie lomakkeen START_IN_PROGRESS-tilaan'
    ),
    featureChange(
      'Hinauksenhallinta',
      'KEN-882: Siirretty hinauslomakkeen otsikko ylempään otsikkokomponenttiin'
    ),
    bugfix('Hinauksenhallinta', 'Piilotettu contentfulin sisäisiä nimiä'),
    featureChange(
      'Hinauksenhallinta',
      'KEN-893: yhtenäistetty lomakkeen toimenpiteiden ja valintojen asettelua ja fonttikokoja.'
    ),
    featureChange('Hinauksenhallinta', 'KEN-890: Parannettu kalustohaun asettelua'),
  ]),
  version('4.5.0-beta-11', '2022-03-14T12:00:00', [
    bugfix('Hinauksen hallinta', 'KEN-862: Korjattu siirtymispainikkeen teksti'),
    bugfix('Hinauksen hallinta', 'KEN-863: Korjattu navigointiotsikon tyyli'),
    bugfix('Hinauksen hallinta', 'KEN-864: Korjattu sisällön kohdistaminen kaluston alisarjoihin'),
    bugfix('Webpack versiopäivitys', 'KEN-865: Korjattu päivitysongelmia'),
    bugfix('Hinauksen hallinta', 'KEN-869: Yömoodiin liittyviä korjauksia'),
    bugfix('Hinauksen hallinta', 'KEN-870: Kuittaa valmiiksi -painikkeen korjaus'),
    bugfix('Hinauksen hallinta', 'KEN-871: Kuittauspainikkeiden hiominen osa II'),
    bugfix('Hinauksen hallinta', 'KEN-872: Suorita jarrujen testaus -korjaus'),
    bugfix('Hinauksen hallinta', 'KEN-874: Ulkoasun hiominen'),
    bugfix('Hinauksen hallinta', 'KEN-880: Tehtävän poistamisen helpottaminen'),
    bugfix('Hinauksen hallinta', 'KEN-884: Puuttuvien tilasiirtymien lisäys audit logille'),
  ]),
  version('4.5.0-beta-10', '2022-03-07T15:30:00', [
    bugfix('Webpack versiopäivitys', 'Testiversio #2 version päivittymisen tutkimiseksi'),
  ]),
  version('4.5.0-beta-9', '2022-03-07T15:00:00', [
    bugfix('Webpack versiopäivitys', 'Testiversio version päivittymisen tutkimiseksi'),
  ]),
  version('4.5.0-beta-8', '2022-02-28T14:00:00', [
    bugfix('Hinauksen hallinta', 'KEN-861: Työvuoronäkymän hinauksen hallinnan ohjeiden linkkaus'),
    bugfix('Hinauksen hallinta', 'KEN-859-860: TowingContentin ulkoasun korjaus'),
    bugfix('Hinauksen hallinta', 'KEN-858: TowingConfirmButtonin korjaus'),
    bugfix(
      'Hinauksen hallinta',
      'KEN-855: Korjattu työvuoronäkymässä kalusto, jolle ei tietoa hinaukseenlaitosta'
    ),
    bugfix('Hinauksen hallinta', 'KEN-852: Lisätty käännökset hinauksen hallinnan virhekoodeille'),
  ]),
  version('4.5.0-beta-7', '2022-02-28T14:00:00', [
    bugfix('Hinauksen hallinta', 'KEN-847: Hakusivun ulkoasun korjaus'),
    bugfix('Hinauksen hallinta', 'KEN-846: Hinauslomakkeen ulkoasun korjaus'),
  ]),
  version('4.5.0-beta-6', '2022-02-17T13:00:00', [
    newFeature('Hinauksen hallinta', 'KEN-835: Lisätty hinaustietojen haku työvuoronäkymään'),
  ]),
  version('4.5.0-beta-5', '2022-02-17T13:00:00', [
    bugfix('Yleistä', 'Service workerin korjaukset Webpack-päivityksen jäljiltä.'),
  ]),
  version('4.5.0-beta-4', '2022-02-16T13:00:00', [
    bugfix('Hinauksen hallinta', 'KEN-841: Hakusivun ulkoasun korjaus'),
  ]),
  version('4.5.0-beta-3', '2022-02-11T14:00:00', [
    bugfix(
      'Hinauksen hallinta',
      'KEN-839: Hinauksen hallinnan ulkoasun ja lomakkeen tilasiirtymien parannuksia'
    ),
  ]),
  version('4.5.0-beta-2', '2022-11-30T13:00:00', [
    featureChange('Versionhallinta', 'Rebase masterin päälle 4.3.0 -> 4.4.0'),
  ]),
  version('4.4.2-beta-1', '2022-04-08T13:30:00', [
    featureChange(
      'Lähiliikenteen työvuorot',
      'KEN-912: Taustajärjestelmä palauttaa menneistä lähiliikenteen työvuoroista vain alle vuorokausi sitten alkaneet vuorot.'
    ),
    featureChange(
      'Lähiliikenteen työvuorot',
      'KEN-875: Taustajärjestelmä palauttaa kaikki saatavilla olevat tulevat lähiliikenteen työvuorot.'
    ),
  ]),
  version('4.4.1-beta-2', '2022-02-10T16:37:00', [bugfix('Yleistä', 'Korjattu kirjoitusvirhe')]),
  version('4.4.1-beta-1', '2022-02-10T16:37:00', [
    bugfix(
      'Työvuorotiedot',
      'KEN-843: Lisätty puuttuva tarkistus puuttellisen datan varalle KEN-831 muutoksiin liittyen.'
    ),
    bugfix('Työvuorotiedot', 'KEN-822: Sm6-kalustonumeron käsittelyn korjaus'),
  ]),
  version('4.4.1-beta-2', '2022-02-10T16:37:00', [bugfix('Yleistä', 'Korjattu kirjoitusvirhe')]),
  version('4.4.1-beta-1', '2022-02-10T16:37:00', [
    bugfix(
      'Työvuorotiedot',
      'KEN-843: Lisätty puuttuva tarkistus puuttellisen datan varalle KEN-831 muutoksiin liittyen.'
    ),
    bugfix('Työvuorotiedot', 'KEN-822: Sm6-kalustonumeron käsittelyn korjaus'),
  ]),
  version('4.4.0-beta-4', '2022-01-26T10:00:00', [
    featureChange(
      'Kokoonpanotiedot',
      'KEN-821: Parannettu kokoonpanohaun ohjetekstejä ja ulkoasua'
    ),
  ]),
  version('4.4.0-beta-3', '2022-01-13T10:00:00', [
    bugfix('Työvuorolista', 'KEN-831: Korjattu veturien vikatiedot jos mukana hinattava veturi'),
  ]),
  version('4.4.0-beta-2', '2021-12-21T11:00:00', [
    bugfix('Kokoonpanotiedot', 'KEN-798: Korjattu valitun käsittelyaseman latausindikaattori'),
  ]),
  version('4.4.0-beta-1', '2021-12-15T11:00:00', [
    featureChange('Kokoonpanotiedot', 'Palautettu KEN-797 ja KEN-798 tähän versioon'),
    bugfix('Työvuorolista', 'KEN-661: Poistettu lähiksen sen hetkinen tehtävä työvuorolistasta'),
    bugfix('Lähiliikenteen työvuorot', 'KEN-820: Suodatetaan tyhjä junatehtävä'),
    featureChange('Lähiliikenteen työvuorot', 'KEN-669: V_YUXEL-linjatunnus näytetään HV:nä'),
    featureChange('Lähiliikenteen työvuorot', 'KEN-819: VIRTMOV-tehtävä piilotetaan'),
    featureChange('Lähiliikenteen työvuorot', 'KEN-690: TFRTRV-tehtävät yhdistetään'),
    featureChange('Lähiliikenteen työvuorot', 'KEN-655: ETTAIKA-tehtävä näytetään ilman otsikkoa'),
    featureChange('Lähiliikenteen työvuorot', 'KEN-653: VARAS-tehtävä VARA-muotoon'),
  ]),
  version('4.3.0-beta-5', '2021-12-09T13:00:00', [
    newFeature(
      'Ratatiedot',
      'KEN-803: Käytetään täsmällisyystietojen raidetietoja tilanteissa, joissa raidetietoja puuttuu työvuorodatasta.'
    ),
  ]),
  version('4.3.0-beta-4', '2021-11-30T13:00:00', [
    featureChange('Työvuorotiedot', 'Poistettu KEN-796 tästä versiosta'),
    featureChange('Kokoonpanotiedot', 'Poistettu KEN-797 ja KEN-798 tästä versiosta'),
  ]),
  version('4.3.0-beta-3', '2021-11-30T11:00:00', [
    newFeature('Käyttöoikeudet', 'Lisätty ad-ryhmä Avecran junatarjoilijoille'),
  ]),
  version('4.3.0-beta-2', '2021-11-29T12:00:00', [
    bugfix(
      'Työvuorotiedot',
      'KEN-796: Yksittäisen työvuoron tiedot käsittellän nyt erillään työvuorolistan tiedoista.'
    ),
    featureChange(
      'Kokoonpanotiedot',
      'KEN-797: Haettaessa kokoonpanotietoja työvuoronäkymästä käytetään tarkkaa kellonaikaa pelkän päivämäärän sijaan.'
    ),
    newFeature(
      'Kokoonpanotiedot',
      'KEN-798: Kokoonpanotietoja hakiessa haetaan ensin lista käsittelyasemista ja saapumisajoista.\nKokoonpanotietoja voi yhä hakea vanhalla tavalla.'
    ),
    bugfix(
      'Kokoonpanotiedot',
      'KEN-789: Korjattu tilanne, jossa sopiva yhdistelmä puuttuvaa dataa kaataa käyttöliittymän'
    ),
    bugfix('Henkilökuntahaku', 'KEN-792: Puuttuvien tietojen käsittelyä parannettu'),
    bugfix(
      'Ohjusviestit',
      'KEN-756: Korjattu ilmoituspalkin leveys ja sulkemispainikkeen sijainti'
    ),
    bugfix('Ajotietotuloste', 'KEN-7774: Poistettu ylimääräinen välilyönti tiedostonimestä'),
    bugfix('Asiakaspalaute', 'KEN-759: Poistettu ylimääräiset feature flagit'),
  ]),
  version('4.3.0-beta-1', '2021-10-12T12:00:00', [
    bugfix(
      'Työvuorotiedot',
      'KEN-793: Korjattu tilanne, jossa puutteellinen työvuorodata kaataa käyttöliittymän'
    ),
    bugfix(
      'Kokoonpanotiedot',
      'KEN-791: Korjattu tilanne, jossa kaluston rajoitetietojen automaattinen vieritys kaatoi ajoittain käyttöliittymän'
    ),
    bugfix(
      'Kokoonpanotiedot',
      'KEN-789: Korjattu vika, jossa virheellinen data kaataa käyttöliittymän'
    ),
    bugfix(
      'Täsmällisyystiedot',
      'KEN-779: Työvuoron manuaalinen päivittäminen uudelleenhakee nyt myös täsmällisyystiedot'
    ),
    featureChange(
      'Ajotietotuloste',
      'KEN-774: Muutettu ladattavan ajotietotulosteen nimi yksilöiväksi'
    ),
    bugfix(
      'Ajotietotuloste',
      'KEN-777: RIPA:lle välitetään nyt oikea tieto sitä kutsuvasta järjestelmästä'
    ),
  ]),
  version('4.2.1-beta-1', '2021-10-12T12:00:00', [
    bugfix('Syykoodin haasto', 'KEN-784: Korjattu haastojen sähköpostilähetys'),
  ]),
  version('4.1.0-beta-1', '2021-07-08T12:00:00', [
    bugfix('Yleinen', 'KEN-762: Parannettu ja yhtenäistetty lokitusta'),
    bugfix('Ajotietotuloste', 'KEN-765: Ajotietotulosteen hakuparametrit logitetaan tarkemmin'),
    bugfix('Admin-toiminnot', 'KEN-766: Korjattu regressio erikoiskäyttäjien eSälli-hauissa'),
    newFeature(
      'Lähiliikenteen työvuorot',
      'KEN-764: Kalenterilinkki toimii nyt myös lähiliikenteen työvuoroille'
    ),
  ]),
  version('4.0.3-beta-1', '2021-07-01T13:00:00', [
    bugfix(
      'Ajotietotuloste',
      'KEN-760: Korjattu tilanne, jossa lähiliikenteen kuljattaja ei voinut ladata ajotietotulostetta ajamiseen.'
    ),
  ]),
  version('3.3.0-beta-7', '2021-06-14T13:00:00', [
    bugfix(
      'Ohjauksen viestit',
      'KEN-752: Kenttä osaa nyt aina uudelleenohjata notifikaatiosta työvuoroon'
    ),
    bugfix(
      'Ohjauksen viestit',
      'KEN-748: Korjattu tilanne, missä ohjusviesti ilman junia ei näy työvuoron yhteydessä'
    ),
  ]),
  version('3.3.0-beta-6', '2021-06-14T13:00:00', [
    featureChange('Asiakaspalaute', 'Tarkennettu asiakaspalautteen ohjetekstejä'),
    bugfix('Ohjauksen viestit', 'KEN-753: Parannettu ohjauksen viestien luotettavuutta'),
    bugfix(
      'Ohjauksen viestit',
      'KEN-754, KEN-757: Korjattu tilanne, jossa yhteyden katkeaminen kentän backendiin kaataa käyttöliittymän'
    ),
  ]),
  version('3.3.0-beta-5', '2021-06-14T13:00:00', [
    bugfix('Työvuorohaku', 'KEN-751: Parannettu työvuorohaun luotettavuutta backendissä'),
    bugfix('Ajotietotuloste', 'KEN-751: Parannettu ajotietotulosteen lataamisen luotettavuutta'),
    bugfix('Yleinen', 'KEN-751 personnelGroup tallennetaan cacheen nyt yksilöivällä avaimella'),
    featureChange('Yleinen', 'KEN-750: Lisätty PowerBI-linkki lisää-sivulle'),
  ]),
  version('3.3.0-beta-4', '2021-06-14T13:00:00', [
    featureChange(
      'Poikkeusraportointi',
      'Rajattu poikkeusraportointi lähiliikenteen työntekijöille'
    ),
    featureChange('Asiakaspalaute', 'Rajattu asiakaspalaute lähiliikenteen työntekijöille'),
  ]),
  version('3.3.0-beta-3', '2021-06-03T15:00:00', [
    bugfix('Poikkeusraportointi', 'Korjattu dataplatformille lähetettävien viestien formaatti'),
    featureChange('Poikkeusraportointi', 'Parannettu ulkoasua'),
    featureChange('Työvuorot', 'Parannuksia työvuoron tehtävien ulkoasuun'),
    featureChange('Lähiliikenteen työvuorot', 'Filtteröidään V_KN'),
  ]),
  version('3.3.0-beta-2', '2021-05-31T12:30:00', [
    featureChange('Lähiliikenteen työvuorot', 'Laajennettu ruokatauon tunnistamista'),
    bugfix('Lähiliikenteen työvuorot', 'Näytetään työvuoron pituus oikein etusivulla'),
    bugfix(
      'Lähiliikenteen työvuorot',
      'Piilotettu etusivun "ilmoittaudu vuoroon" -painike lähiliikenteen käyttäjiltä'
    ),
  ]),
  version('3.3.0-beta-1', '2021-05-25T12:00:00', [
    featureChange(
      'Poikkeusraportointi',
      'Poikkeusraportointi palautettu testattavaksi tuotantoversion jäljiltä'
    ),
    featureChange(
      'Asiakaspalaute',
      'Asiakaspalaute palautettu testattavaksi tuotantoversion jäljiltä'
    ),
  ]),
  version('3.2.1-beta-1', '2021-05-27T11:00:00', [
    bugfix(
      'Työvuorot',
      'Korjattu bugi, jossa kaukoliikenteen henkilötiedot näkyivät väärin työtehtävässä'
    ),
    bugfix(
      'Työvuorohaku',
      'Korjattu bugi, jossa kenttä kaatuu avatessa työvuoron henkilötiedot katsellessa työvuoroa työvuorohaun kautta'
    ),
  ]),
  version('3.2.0-beta-9', '2021-05-24T13:00:00', [
    bugfix(
      'Ohjauksen viestit',
      'Korjattu bugi, jossa ilmoituksen klikkaaminen vei väärään osoitteeseen'
    ),
    featureChange(
      'Työvuorot',
      'Palkattoman ruokatauon tunnistamisen kriteerit muutettu laveammiksi'
    ),
  ]),
  version('3.2.0-beta-8', '2021-05-24T13:00:00', [
    featureChange('Asiakaspalaute', 'Poistettu käytöstä tuotantovientiä varten'),
    featureChange('Poikkeusraportointi', 'Poistettu käytöstä tuotantovientiä varten'),
    bugfix(
      'Ohjauksen viestit',
      'KEN-732: Korjattu bugi, joka esti kuittaamaattomien viestien näkymisen'
    ),
  ]),
  version('3.2.0-beta-7', '2021-05-21T12:00:00', [
    featureChange(
      'Admin-toiminnot',
      'Lisätty mahdollisuus valita oma henkilönumero admin-palkissa jättämällä numerokenttä tyhjäksi'
    ),
  ]),
  version('3.2.0-beta-6', '2021-05-20T17:00:00', [
    newFeature(
      'Poikkeusraportointi',
      'Työvuoroihin lisätty mahdollisuus poikkeuksien raportointiin'
    ),
    newFeature(
      'Lähiliikenteen pilotointi',
      'Lähiliikenteen ominaisuudet näkyvät nyt kaikille lähiliikenteen käyttäjille'
    ),
    newFeature('Ohjauksen viestit', 'Kenttään lisätty mahdollisuus testata push-viestin toimintaa'),
    bugfix(
      'Kota-sijaintitiedot',
      'Korjattu aikavyöhykkeettömien aikaleimojen parsinta lähiliikenteen työvuoroissa'
    ),
    bugfix('Kota-sijaintitiedot', 'Yhtenäistetty henkilönumeroiden käsittelyä'),
    featureChange(
      'Työvuorot',
      'Lasketaan aloitus- ja lopetusaikoihin mukaan useampia aloitus- ja lopetustehtäviä'
    ),
  ]),
  version('3.2.0-beta-5', '2021-05-17T17:30:00', [
    bugfix(
      'Kokoonpanotiedot',
      'Korjattu vika, jossa vaunu ilman cn-koodeja näytetään KULA-tiedolla VKTK'
    ),
  ]),
  version('3.2.0-beta-4', '2021-05-11T11:00:00', [
    bugfix('Kota-sijaintitiedot', 'Korjattu vika sijaintietojen lisäämisessä työvuoron tietoihin'),
    featureChange('Ohjauksen viestit', 'Parannettu push-viestien luotettavuutta'),
    newFeature('Asiakaspalaute', 'Kenttään lisätty mahdollisuus syöttää asiakaspalautetta'),
    newFeature('Yleinen', 'Parannettu backendin lokitusta'),
    featureChange(
      'Lähiliikenteen työvuorot',
      'Palkattomia ruokataukoja ei enää lasketa työuvoron kokonaispituuteen'
    ),
  ]),
  version('3.2.0-beta-3', '2021-05-03T16:00:00', [
    bugfix('Kota-sijaintitiedot', 'Korjattu vika sijaintitietojen esittämisessä'),
  ]),
  version('3.2.0-beta-2', '2021-05-03T16:00:00', [
    newFeature('Kota-sijaintitiedot', 'Sijaintitiedot näkyvät nyt työtehtävän lisätiedoissa'),
    featureChange('Ohjauksen viestit', 'Push-viesti ohjaa kyseessä olevaan työvuoroon'),
  ]),
  version('3.2.0-beta-1', '2021-04-23T15:00:00', [
    featureChange('Kokoonpanohaku', 'KEN-585: Palautettu tyhjien VAK-konttien näyttäminen.'),
    newFeature('KOTA', 'Alustava KOTA-toteutus backendiin testausta ja kehitystä varten'),
    newFeature(
      'Poikkeusraportointi',
      'Alustava poikkeusraportoinnin backend-toteutus testausta ja kehitystä varten'
    ),
  ]),
  version('3.1.0-beta-2', '2021-04-21T17:00:00', [
    bugfix('Ohjauksen viestit', 'Yleisiä parannuksia ohjauksen viesteihin liittyen.'),
    bugfix(
      'Yleinen',
      'KEN-708: Parannukset frontendin ja backendin väliseen kommunikaatioon sekä tokenin käsittelyyn.'
    ),
    featureChange('Kokoonpanuhaku', 'Revertoitu KEN-585'),
  ]),
  version('3.1.0-beta-1', '2021-04-09T12:00:00', [
    newFeature(
      'Kokoonpanohaku',
      'KEN-585: Näytetään "tyhjä"-teksti kun vaarallista ainetta sisältävä vaunu on tyhjä'
    ),
    newFeature(
      'Lähiliikenteen työvuorot',
      'KEN-694: Näkymä vierittyy meneillään olevaan työtehtävään, kun meneillään oleva työvuoro avataan.'
    ),
  ]),
  version('3.0.0-beta-29', '2021-04-06T15:00:00', [
    featureChange('Kokoonpanohaku', 'Vaihdettu Rallibusin haun timeout minuuttiin'),
    featureChange(
      'Ohjauksen viestit',
      'Rajoitettu push-viestejä varten rekisteröinti lähiliikenteen työntekijöille ja admineille'
    ),
  ]),
  version('3.0.0-beta-27', '2021-03-31T16:00:00', [
    bugfix(
      'Lähiliikenteen työvuorot',
      'KEN-681: Tunti työvuoron alkua ennen ja tunti työvuoron lopun jälkeen tapahtuvat ohjusviestit näytetään nyt työvuoronäkymässä.'
    ),
  ]),
  version('3.0.0-beta-26', '2021-03-23T10:30:00', [
    bugfix(
      'Lähiliikenteen työvuorot',
      'KEN-692: Korjattu vika, jossa henkilönumeroiden etunollia käsiteltiin väärin.'
    ),
    featureChange('Lähiliikenteen työvuorot', 'Lähiliikenteen työvuoronäkymän tehtäviä siistitty'),
  ]),
  version('3.0.0-beta-25', '2021-03-23T10:30:00', [
    featureChange(
      'Lähiliikenteen työvuorot',
      'KEN-656: Pakettimatkatehtävien otsikot muutettu selvemmiksi'
    ),
    featureChange('Lähiliikenteen työvuorot', 'KEN-688: RFTRVO-tehtävät filtteröityvät pois'),
    featureChange(
      'Lähiliikenteen työvuorot',
      'KEN-691: Muutettu tehtävän ulkoasua, mikäli tekninen termi jätetään otsikosta pois'
    ),
  ]),
  version('3.0.0-beta-24', '2021-03-22T12:30:00', [
    featureChange(
      'Lähiliikenteen työvuorot',
      'KEN-689: Ympäriajoon liittyvät turhat kuvaukset jätetään pois'
    ),
    bugfix(
      'Lähiliikenteen työvuorot',
      'KEN-683: Korjattu virhe, joka esti ajotietotulosteiden hakemisen joillai työtehtävillä'
    ),
    featureChange(
      'Ohjauksen viestit',
      'KEN-681: Ohjauksen viestit ryhmittyvät nyt junan perusteella'
    ),
    bugfix('Ohjauksen viestit', 'KEN-682: Korjattu tehtävien avautuminen'),
  ]),
  version('3.0.0-beta-23', '2021-03-15T14:30:00', [
    newFeature(
      'Lähiliikenteen työvuorot',
      'KEN-685: Lisätty linjatunnukset työtehtävän lisätietokenttään'
    ),
    bugfix(
      'Lähiliikenteen työvuorot',
      'KEN-686: Korjattu bugi, joka esti täsmällisyystietojen näyttämisen lähiliikenteen työvuoroissa'
    ),
  ]),
  version('3.0.0-beta-22', '2021-03-09T14:30:00', [
    bugfix(
      'Admin-toiminnot',
      'Korjattu vika, jossa 0-alkuisia käyttäjänumeroita ei parsittu oikein.'
    ),
    featureChange('Lähiliikenteen työvuorot', 'KEN-675: M1234-tehtävien nimiä ei enää näytetä'),
    bugfix('Lähiliikenteen työvuorot', 'KEN-673: Tehtävän yhteystiedot aukeavat klikattaessa'),
    bugfix(
      'Lähiliikenteen työvuorot',
      'Korjattu tilanne, jossa lähiliikenteen työvuoronäkymä kaatoi joitain vanhoja selaimia.'
    ),
    featureChange('Ohjauksen viestit', 'Ilmoitukset näytetään uusimmasta vanhimpaan'),
  ]),
  version('3.0.0-beta-21', '2021-02-18T15:00:00', [
    bugfix(
      'Lähiliikenteen työvuorot',
      'KEN-640: Lisätty parsimissääntöjä erikoismerkeille työtehtävien nimissä'
    ),
    bugfix(
      'Lähiliikenteen työvuorot',
      'KEN-634: Kaikkien "sections"-kohtien tiedot näytetään, yhtenäistäminen tulee myöhemmässä vaiheessa'
    ),
    featureChange('Lähiliikenteen työvuorot', 'KEN-670: Tauon lopetus näytetään nyt vuorona'),
    featureChange(
      'Lähiliikenteen työvuorot',
      'KEN-667: Muutettu tiketin mukaisesti parsimissääntöjä'
    ),
    featureChange(
      'Lähiliikenteen työvuorot',
      'KEN-674: Muutettu työtehtävän reagointia painalluksiin'
    ),
    newFeature('Lähiliikenteen työvuorot', 'KEN-673: CE:n yhteystiedot näkyvät nyt käyttäjille'),
  ]),
  version('3.0.0-beta-20', '2021-02-16T11:30:00', [
    bugfix(
      'Lähiliikenteen työvuorot',
      'KEN-634: Korjattu vika, jossa tyhjä kalustotieto kaataa kentän'
    ),
  ]),
  version('3.0.0-beta-19', '2021-02-16T11:30:00', [
    newFeature(
      'Lähiliikenteen työvuorot',
      'KEN-634: Näytetään lähiliikenteen tehtävissä "Junasta"- ja "Junaan"-kalustotiedot'
    ),
  ]),
  version('3.0.0-beta-18', '2021-02-11T18:00:00', [
    bugfix(
      'Lähiliikenteen työvuorot',
      'KEN-668: Korjattu Juna -ohjeen filtteröinti, joka ei yltänyt viimeiseen tehtävään'
    ),
    bugfix(
      'Lähiliikenteen työvuorot',
      'Korjattu pieni bugi, jonka seurauksena tehtävän kaluston kohdalla saattoi lukea undefined'
    ),
  ]),
  version('3.0.0-beta-17', '2021-02-11T15:30:00', [
    newFeature('Lähiliikenteen työvuorot', 'KEN-633: Rikastettujen sanomien raidenumerot näkyvät'),
    newFeature('Lähiliikenteen työvuorot', 'KEN-665: Työvuoron otsikkoon on lisätty linjatunnus'),
    newFeature('Lähiliikenteen työvuorot', 'KEN-637: Kalusto näkyy työvuoron yhteydessä'),
    newFeature('Lähiliikenteen työvuorot', 'KEN-668: Pelkkä Juna -tehtäväselite ei enää näy'),
    featureChange(
      'Lähiliikenteen työvuorot',
      'KEN-654: Työtehtävät eivät enää näy automaattisesti laajennettuna'
    ),
  ]),
  version('3.0.0-beta-16', '2021-02-08T12:30:00', [
    featureChange('Lähiliikenteen työvuorot', 'KEN-666: teksti Tauko -> Lepo'),
    featureChange('Lähiliikenteen työvuorot', 'KEN-654: Menneet työvuorot näkyvät laajempana'),
  ]),
  version('3.0.0-beta-14', '2021-02-03T16:50:00', [
    bugfix('Ohjauksen viestit', 'KEN-628: Ohjusviestit näkyvät oikeiden tehtävien yläpuolella'),
    newFeature('Ohjauksen viestit', 'KEN-663: Kuitattu ohjauksen viesti näytetään vihreänä.'),
  ]),
  version('3.0.0-beta-13', '2021-01-28T18:30:00', [
    bugfix('Lähiliikenteen työvuorot', 'KEN-652: Jatkettu työtehtävien sulauttamislogiikkaa.'),
    bugfix('Lähiliikenteen työvuorot', 'KEN-654: Lähiksen työtehtävät näkyvät aina avattuna.'),
    bugfix('Lähiliikenteen työvuorot', 'KEN-660: Poistettu työvuorotoiveet näkyvistä.'),
    bugfix(
      'Ohjauksen viestit',
      'KEN-628: Ohjusviestit näkyvät työvuoronäkymässä nyt tehtävän yhteydessä.'
    ),
    bugfix(
      'Ohjauksen viestit',
      'KEN-658: Huomautusten vetolaatikossa on viestit nyt järjestetty saapumisajankohdan mukaan.'
    ),
  ]),
  version('3.0.0-beta-12', '2021-01-26T12:00:00', [
    bugfix(
      'Lähiliikenteen työvuorot',
      'KEN-645: Näytetään työvuorot myös tilanteissa, joissa aloitus- ja lopetusaikojen parsiminen epäonnistuu.'
    ),
  ]),
  version('3.0.0-beta-11', '2021-01-26T12:00:00', [
    featureChange(
      'Lähiliikenteen työvuorot',
      'KEN-645: Aloitus- ja lopetusajat näkyvät lähiksen työvuoronäkymän ylä- ja alalaidoissa.'
    ),
    featureChange(
      'Lähiliikenteen työvuorot',
      'KEN-646: Poistettu BRDPCE lyhenteet ja documentti-ikoni.'
    ),
    featureChange(
      'Lähiliikenteen työvuorot',
      "KEN-647: Poistettu ylimääräinen 'Juna' kuvauksesta."
    ),
    featureChange(
      'Lähiliikenteen työvuorot',
      'KEN-648: Poistettu ruokatauosta ylimääräinen teksti eikä vuoroa voi enää avata'
    ),
    featureChange('Lähiliikenteen työvuorot', 'KEN-649: Poistettu RB ja B tehtävät näkyvistä'),
    featureChange(
      'Lähiliikenteen työvuorot',
      'KEN-650: KAT.LOP ja KAT.AL yhdistetään nyt yhdeksi tauoksi'
    ),
  ]),
  version('3.0.0-beta-10', '2021-01-20T15:30:00', [
    bugfix('Ohjauksen viestit', 'KEN-626: Viestin alakulman kellonaika näkyy oikein'),
    bugfix('Ohjauksen viestit', 'KEN-623: Näytetään kuitatun viestin kuittausaika'),
  ]),
  version('3.0.0-beta-9', '2021-01-14T15:30:00', [
    featureChange(
      'Lähiliikenteen työvuorot',
      'KEN-631, KEN-632 KEN-636, KEN-638, KEN-639, KEN-640.'
    ),
    bugfix('Ohjauksen viestit', 'KEN-624: Työvuoronäkymän kautta kuitattu viesti päivittyy oikein'),
    featureChange('Ohjauksen viestit', 'KEN-621: Uudet viestit tulevat näkyviin työvuoronäkymässä'),
  ]),
  version('3.0.0-beta-8', '2021-01-13T18:45:00', [
    featureChange(
      'Lähiliikenteen työvuorot',
      'Lisätty mahdollisuus duplikoida lähiliikenteen työvuoroja toiselle käyttäjälle testaamisen helpottamista varten.'
    ),
  ]),
  version('3.0.0-beta-6', '2020-12-17T17:00:00', [
    bugfix('Yleinen', 'Poistettu ylimääräinen muutos'),
  ]),
  version('3.0.0-beta-5', '2020-12-17T16:30:00', [
    featureChange(
      'Lähiliikenteen työvuorot',
      'Lähiliikenteen työvuorojen ilmoittautumismahdollisuus poistettu myös työvuoroluettelosta'
    ),
    bugfix(
      'Lähiliikenteen työvuorot',
      'Työvuoroluettelosta poistettu ylimääräiset työvuorolistojen jakajat'
    ),
  ]),
  version('3.0.0-beta-3', '2020-12-16T16:30:00', [
    bugfix('Lähiliikenteen työvuorot', 'Lähiliikenteen työvuorot näytetään oikein'),
  ]),
  version('3.0.0-beta-2', '2020-12-16T11:00:00', [
    newFeature(
      'Lähiliikenteen työvuorot',
      'KEN-599: Lähiliikenteen työvuorot näykvät nyt kentässä'
    ),
  ]),
  version('2.3.2-beta-1', '2020-10-22T14:00:00', [
    bugfix('Tänään-näkymä', 'KEN-597: Korjattu ilmoittautumisen aikaraja.'),
    bugfix(
      'Työvuoroluettelo',
      'KEN-588: Korjattu tekstivirhe vara-ajotietotulosteen ilmoituksessa.'
    ),
  ]),
  version('2.3.1-beta-3', '2020-10-21T14:00:00', [
    bugfix(
      'Työvuoroluettelo',
      'KEN-593: Korjattu kesäajan päättymisen aiheuttama tunnin heitto työvuorojen ajoissa.'
    ),
  ]),
  version('2.3.1-beta-2', '2020-10-21T09:00:00', [
    bugfix('Etusivu', 'KEN-592: Korjattu ilmoittautumisnapin tila Tänään -näkymässä.'),
  ]),
  version('2.3.1-beta-1', '2020-10-14T16:35:00', [
    bugfix(
      'Kenttä backend',
      'Backend ei enää poista alku- ja loppuaseman parametreja haettaessa junan aikataulun osaa.'
    ),
  ]),
  version('2.3.0-beta-8', '2020-10-06T16:45:00', [
    featureChange('Kokoonpanotiedot', 'Revertoitu muutokseen KEN-412 liittynyt muutos KEN-578'),
  ]),
  version('2.3.0-beta-7', '2020-10-06T14:00:00', [
    featureChange('Kokoonpanotiedot', 'Revertoitu KEN-572 muutokset'),
  ]),
  version('2.3.0-beta-6', '2020-09-29T11:30:00', [
    featureChange('Työvuoronäkymä', 'KEN-583: Korostettu KEN-537 lisätietojen näkyvyyttä'),
  ]),
  version('2.3.0-beta-5', '2020-09-22T16:00:00', [
    bugfix(
      'Työvuoronäkymä',
      'KEN-537: Lisätty työvuoron taukojen näkymään lisätiedot, mikäli sellaisia on'
    ),
  ]),
  version('2.3.0-beta-4', '2020-09-21T16:30:00', [
    bugfix('Yleinen', 'Poistettu muutokset KEN-412 ja KEN-580'),
  ]),
  version('2.3.0-beta-3', '2020-09-02T12:00:00', [
    bugfix(
      'Työvuoro-ohje',
      'KEN-557: Disabloitu ilmoittaudu-painike tulee näkyviin, kun vuoron alkuun on 12h'
    ),
    bugfix(
      'Työvuoro-ohje',
      'KEN-557: Ilmoittaudu-painike ei näy enää aktiivisena, kun työvuoron latautuessa'
    ),
    bugfix(
      'Yleinen',
      'KEN-571: Korjattu bugi, jossa kenttää ei pystynyt avaamaan verstaan linkistä'
    ),
    bugfix(
      'Kokoonpanotiedot',
      'KEN-580: Korjattu kokoonpanotietojen avaaminen suoraan työvuoro-ohjeesta'
    ),
    bugfix('Kenttä backend', 'KEN-577: Parannettu eSällin palauttaman työvuoron parsintaa'),
  ]),
  version('2.2.6-beta-2', '2020-08-31T12:00:00', [
    bugfix('Kokoonpanotiedot', 'KEN-578: Korjattu jarrutarkistuksen aikaleiman päivitys'),
  ]),
  version('2.2.6-beta-1', '2020-08-27T14:45:00', [
    bugfix('Kokoonpanotiedot', 'KEN-572: Korjattu tyhjä "max nopeus" -kenttä'),
    bugfix(
      'Työvuoro-ohje',
      'KEN-557: yhtenäistetty ilmoittaudu-painikkeen näkyminen mobiililaitteilla'
    ),
    featureChange(
      'Työvuoro-ohje',
      'KEN-563: Näytetään tänään-sivun ja työvuoro-ohjeen seuraavan työvuoron linkissä sen viikonpäivä.'
    ),
    bugfix(
      'Työvuoroon ilmoittautuminen',
      'KEN-473: Kaksiosaisen työvuoron toiseen osaan ei voi enää ilmoittautua liian aikaisin'
    ),
    bugfix(
      'Työvuoro-ohje',
      'KEN-466: Nelikirjaiminen työvuoron otsikkorivi tunnus ei enää leikkaudu'
    ),
    featureChange('Haku', 'KEN-317: Näppäimistö ei enää nouse päivämäärää valittaessa'),
  ]),
  version('2.2.5-beta-1', '2020-08-17T14:45:00', [
    featureChange('Kokoonpanohaku', 'KEN-412: Muutetu kokoonpanohaku kaksivaiheiseksi'),
    newFeature('Kokoonpanotiedot', 'KEN-489: Kenttä muistaa vaununumeron formaatin valinnan'),
    bugfix('Yleinen', 'KEN-567: Parannettu "palaa etusivulle"-painikkeen luotettavuutta'),
    featureChange(
      'Työvuoro-ohje',
      'KEN-568: Lihavoitu ja siirretty lisätietokentän ei-vakioteksti omalle rivilleen'
    ),
    featureChange('Kenttä backend', 'KEN-408: Parannettu sessio- ja kutsutunnisteen logitusta'),
  ]),
  version('2.2.4-beta-4', '2020-08-17T14:45:00', [
    featureChange('Kalenteri', 'KEN-400: Vaihdettu kalenterin ohjeistuksen linkki'),
  ]),
  version('2.2.4-beta-3', '2020-08-14T10:00:00', [
    bugfix('Kalenteri', 'KEN-565: Korjattu kalenterilinkin asettelu työvuorolistassa'),
    bugfix('Kalenteri', 'KEN-400: Korjattu ohjeistuksen linkit ja lisätty käyttöohje'),
  ]),
  version('2.2.4-beta-2', '2020-08-12T08:00:00', [
    bugfix('Kalenteri', 'KEN-400: korjattu kalenterilinkin asettelu tablet- desktop-näkymässä'),
    bugfix('Hakunäkymä', 'KEN-547: Paluu-napin väri työvuorohaussa korjattu'),
  ]),
  version('2.2.4-beta-1', '2020-08-05T12:00:00+03:00', [
    bugfix('Kalenteri', 'KEN-400: työvuorokalenterin aikavyöhykkeet'),
    bugfix('Työvuoro-ohje', 'KEN-550: tietojen päivitys'),
    bugfix(
      'Käyttörajoitteet',
      'KEN-555: yksittäisen kaluston rajoitetta avattaessa siiretään näkymä ko.kaluston kohdalle'
    ),
    bugfix('Työvuoro-ohje', 'KEN-546: täsmällisyystiedot haetaan vain vuoroille joissa on kalusto'),
    bugfix('Yleinen', 'KEN-495: Virhetilanteissa näytetään virheilmoitus tyhjän ruudun sijaan'),
    bugfix('Hakunäkymä', 'KEN-547: Paluu-napin väri muutettu näkyväksi yömoodissa'),
  ]),
  version('2.2.3-beta-3', '2020-08-03T12:00:00+03:00', [
    bugfix('Kalenteri', 'Korjattu kalenteridatan formatointi'),
    featureChange(
      'Kirjautuminen',
      'Kirjautumisen voimassaolo pienennetty 5 minuuttiin testausta varten'
    ),
  ]),
  version('2.2.3-beta-2', '2020-07-27T12:00:00+03:00', [
    newFeature(
      'Kalenteriin liittäminen',
      'KEN-400: Lisätty toiminnallisuus työvuorojen liittämiseksi kalenteriin'
    ),
    bugfix(
      'Työvuoronäkymä',
      'KEN-544: Korjattu bugi, joka aiheutti virheellisen työvuorojen otsikoiden lyhentämisen'
    ),
  ]),
  version('2.2.1-beta-5', '2020-07-22T11:30:00+03:00', [
    bugfix(
      'Työvuoronäkymä',
      'KEN-391: SM3- ja SM6-kalustojen käyttörajoitteiden näkyvyys korjattu'
    ),
  ]),
  version('2.2.1-beta-4', '2020-07-21T16:00:00+03:00', [
    featureChange(
      'Työvuoronäkymä',
      'KEN-391: SM3- ja SM6 kalustoille näytetään rungon numero tehtävässä'
    ),
  ]),
  version('2.2.1-beta-3', '2020-07-21T14:00:00+03:00', [
    featureChange('Kenttä backend', 'KEN-542: Lokituksen aikaleimojen luotettavuuden parannus'),
    bugfix('Työvuoronäkymä', 'KEN-414: Työvuoronäkymän päivityksen korjaus session vanhentuessa'),
  ]),
  version('2.2.1-beta-2', '2020-07-15T11:00:00+03:00', [
    featureChange(
      'Työvuoronäkymä',
      'KEN-391: Sm3- ja Sm6 -kalustoille näytetään kaikkien vaunujen käyttörajoitukset'
    ),
    featureChange('Vuorolistanäkymä', 'KEN-496: Lepojen lukeminen eSällin datasta'),
  ]),
  version('2.2.1-beta-1', '2020-06-26T015:00:00+03:00', [
    bugfix(
      'Syykoodin haasto',
      'KEN-540: Tehdyt haastot näkyvät nyt myös syykoodeilla, joista puuttuu 1. ja 2. tarkentava taso'
    ),
    featureChange(
      'Syykoodien haasto',
      'Devisähköpostiin ei enää lähetetä ajastettua viestiä, mikäli uusia haastoja ei ole tehty'
    ),
  ]),
  version('2.2.0-beta-6', '2020-06-24T011:00:00+03:00', [
    bugfix('Kenttä backend', 'KEN-539 Haastojen ja tarkennusten koonnissa näytetään oikeat tiedot'),
  ]),
  version('2.2.0-beta-5', '2020-06-24T011:00:00+03:00', [
    bugfix('Kenttä backend', 'KEN-529: Koontisähköposteja ei enää lähetetä kahdesti'),
    featureChange('Kenttä backend', 'KEN-531: Koontisähköpostin asettelua parannettu'),
    bugfix(
      'Syykoodin haasto',
      'KEN-536: Täsmällisyysnäkymä näyttää tarkennetut ja haastetut syykoodit oikein'
    ),
    bugfix('Kenttä backend', 'Näytetään junavuoroille oikea päivämäärä koontisähköpostissa'),
    bugfix(
      'Syykoodin haasto',
      'KEN-534: Haaston viimeisessä näkymässä näytetään alkuperäisen syyn kuvaus'
    ),
    bugfix('Syykoodin haasto', 'KEN-533: Lisätty koodeille H105 ja E104 puuttuva lisätietokenttä'),
    bugfix(
      'Syykoodin haasto',
      'KEN-532: Palaa-painike syykoodin varmistukessa ei enää vie takaisin hakuun'
    ),
  ]),
  version('2.2.0-beta-4', '2020-06-23T011:00:00+03:00', [
    bugfix('Syykoodien haasto', 'KEN-533: Korjattu puuttuva lisätietokenttä syykoodilla H105 '),
    featureChange(
      'Syykoodien haasto',
      'KEN-534: Lisätty viimeiseen näkymään alkuperäisen syykoodin kuvaus'
    ),
    bugfix(
      'Junien täsmällisyys',
      'KEN-536: Korjattu bugi, joka aiheutti tarkennuksen näkymisen haastona'
    ),
    bugfix(
      'Junien täsmällisyys',
      'KEN-5232: "Palaa"-napin painaminen ei enää vie hakusivulle asti, vaan kulkutietoihin'
    ),
  ]),
  version('2.2.0-beta-4', '2020-06-23T011:00:00+03:00', [
    featureChange('Syykoodien haasto', 'KEN-430: tehty pieniä muutoksia sähköpostin muotoiluun'),
    bugfix(
      'Syykoodien haasto',
      'KEN-514: Lisätty taustajärjestelmään tarkistus estämään väärällä syykoodilla tarkentamisen'
    ),
    bugfix(
      'Syykoodien haasto',
      'KEN-507: Haaston virheilmoitukset katoavat nyt, jos sivulta navigoidaan pois'
    ),
    bugfix(
      'Syykoodien haasto',
      'KEN-519: Täsmällisyyssivun palaamisnapit eivät enää johda haaston jälkeen takaisin haastosivulle'
    ),
    bugfix(
      'Syykoodien haasto',
      'KEN-528: Korjattu bugi, joka aiheutti sen, että 2. tason alkuperäistä koodia ei voinut haastaa 1. tason koodilla'
    ),
    bugfix(
      'Syykoodien haasto',
      'KEN-525: Korjattu kirjoitusvirhe haaston lähetyksen vahvistusnäkymässä'
    ),
    bugfix(
      'Junien täsmällisyys',
      'KEN-512: Korjattu bugi, joka aiheutti latausindikaattorin ennenaikaisen katoamisen ja ikuisen latautumisen, jos kulkutietoja ei löytynyt'
    ),
    bugfix('Junien täsmällisyys', "KEN-524: Korjattu 'Näytä kaikki liikennepaikat'-täppä"),
  ]),
  version('2.2.0-beta-3', '2020-06-22T010:30:00+03:00', [
    bugfix('Kenttä backend', 'KEN-521: Sähköpostilähetyksen parametrit lisätty'),
    newFeature(
      'Syykoodien haasto',
      'KEN-516: Lisätty alkuperäisen syykoodin kuvaus haaston ja tarkennuksen viimeiseen vaiheeseen'
    ),
    bugfix('Hakunäkymä', 'KEN-510: Hakunäkymän otsikon fontti muutettu'),
  ]),
  version('2.2.0-beta-2', '2020-06-18T014:30:00+03:00', [
    newFeature(
      'Syykoodien haasto',
      'Lisätty erityistapauksissa kenttä, johon voi syöttää haastoon liittyvän junanumeron'
    ),
    newFeature(
      'Syykoodien haasto',
      'KEN-506: Lisätty lähetysvahvistus, kun taustapalvelu ottaa haaston tai tarkennuksen vastaan onnistuneesti'
    ),
    featureChange(
      'Junien täsmällisyys',
      'KEN-504: Kulkutietosivulle ei enää näy haastettujen tai tarkennettujen syykoodien statusta, sillä tieto ei päivity tällä hetkellä'
    ),
    bugfix(
      'Syykoodien haasto',
      'KEN-509: Korjattu ongelma, joka esti haaston lähettämisen, jos haasto liittyi viimeiseen asemaan'
    ),
    bugfix(
      'Syykoodien haasto',
      'KEN-503: Lähetysnapissa lukee nyt haasto tai tarkennus riippuen toiminnosta, ennen luki aina tarkennus'
    ),
    bugfix(
      'Syykoodien haasto',
      'KEN-508: Korjattu ongelma, jonka seurauksena virheilmoitukset eivät kadonneet, vaikka käyttäjä navigoi pois sivulta'
    ),
  ]),
  version('2.2.0-beta-1', '2020-06-16T09:30:00+03:00', [
    newFeature(
      'Aikatauluhaku',
      'Hakusivuille on ilmestynyt uusi hakuvalikko täsmällisyystietoja varten.'
    ),
    newFeature(
      'Syykoodien haasto',
      'Täsmällisyyssivujen kautta on nyt mahdollista päästä haastamaa tai tarkentamaan aikataulupoikkeamien syykoodeja'
    ),
  ]),
  version('2.1.0-beta-7', '2020-06-09T13:30:00+03:00', [
    bugfix('Yhteystietohaku', 'KEN-492: Näytetään puuttuvat yhteystiedot'),
    bugfix(
      'Yhteystietohaku',
      'KEN-493: Kenttä erottaa yhteystiedon ja yhteystietoryhmän lisätiedot pilkulla vain molempien läsnäollessa'
    ),
    featureChange('Yhteystietohaku', 'Näytetään oletuksena kymmenen yhteystietoa viiden sijaan'),
  ]),
  version('2.1.0-beta-6', '2020-06-04T14:15:00+03:00', [
    bugfix('Työvuorohaku', 'KEN-491: Kenttä ei enää kaadu, kun työvuorohaku ei löydä hakutuloksia'),
  ]),
  version('2.1.0-beta-5', '2020-06-04T14:15:00+03:00', [
    bugfix('Kenttä backend', 'Korjattu yhteystietohakuun liittyvä virhetilanne'),
    featureChange(
      'Yhteystietohaku',
      'KEN-490: Hakutoiminto näyttää tulokset riippumatta hakusanan merkkien koosta'
    ),
    bugfix(
      'Yhteystietohaku',
      'Käsitellään vielä Contentfulissa julkaisemattomat yhteystiedot oikein'
    ),
  ]),
  version('2.1.0-beta-4', '2020-06-04T10:15:00+03:00', [
    bugfix(
      'Kenttä backend',
      'KEN-431: Lisätty parametrien validointia tietoturvan kasvattamiseksi'
    ),
    bugfix(
      'Työvuoronäkymä',
      'Kokoonpano- ja täsmällisyystietojen napit eivät enää näy tehtävissä, joissa tieto ei ole relevanttia'
    ),
  ]),
  version('2.1.0-beta-3', '2020-06-03T13:30:00+03:00', [
    featureChange('Versionhallinta', 'Yhdistetty 2.1.0-beta-2 ja hotfix 2.0.3'),
  ]),
  version('2.1.0-beta-2', '2020-05-22T17:10:00+03:00', [
    newFeature('Yhteystietohaku', 'KEN-455: Uusi hakunäkymä yhteystiedoille'),
  ]),
  version('2.1.0-beta-1', '2020-05-13T14:10:00+03:00', [
    bugfix('Kokoonpanotiedot', 'KEN-403: näytetään varoitustiedot, kun ne ovat olemassa'),
  ]),
  version('2.0.2-beta-2', '2020-05-18T13:30:00+03:00', [
    bugfix('Työvuoro-ohje', 'Lisätty vetokaluston tunnus lisätietojen alkuun'),
  ]),
  version('2.0.2-beta-1', '2020-05-18T12:00:00+03:00', [
    bugfix('Työvuoro-ohje', 'KEN-477: Korjattu puuttuva veturin lisätieto'),
  ]),
  version('2.0.1-beta-1', '2020-05-14T12:00:00+03:00', [
    bugfix('Työvuoro-ohje', 'KEN-475: Parannettu pitkien tehdävätunnisteiden näkyvyyttä'),
  ]),
  version('2.0.0-beta-9', '2020-05-07T15:30:00+03:00', [
    bugfix('Työuoronäkymän regressio', 'Korjattu KEN-468 myötä tullut regressio työvuoronäkymään'),
  ]),
  version('2.0.0-beta-8', '2020-05-05T13:00:00+03:00', [
    bugfix('Kokoonpanotiedot', 'KEN-457: rivitykset tablettinäkymässä'),
    bugfix('Käyttörajoitteet', 'KEN-468: käyttörajoitteiden avaus kokoonpanotiedoista'),
  ]),
  version('2.0.0-beta-7', '2020-04-30T10:00:00+03:00', [
    bugfix(
      'Työvuoro-ohje',
      'KEN-460, KEN-463: Parannettu työvuorolistan ulkoasua ja tehtävien näkymistä yötilassa'
    ),
    bugfix(
      'Työvuoro-ohje',
      'KEN-465: Korjattu lukitustietoihin liittyvä sovelluksen kaatuminen tietyillä työvuoroilla'
    ),
    bugfix(
      'Työvuoroluettelo',
      'KEN-462: Korjattu tehtävänimmikeiden vuotaminen lähtöaikojen ja -paikkojen päälle'
    ),
    bugfix(
      'Yömoodi',
      'KEN-441: Korjattu yömoodin värejä täsmällisyyksien, työvuorolistauksen ja vahvistusvalikkojen osalta'
    ),
  ]),
  version('2.0.0-beta-6', '2020-04-29T09:30:00+03:00', [
    bugfix('Työvuoro-ohje', 'KEN-459: Korjattu regressiovirhe käyttörajoitusten näytössä'),
    bugfix(
      'Työvuoro-ohje',
      'KEN-453: Korjattu tehtävien rivitystä, kun vain lähtö- tai saapumissijainille oli raidetietoja'
    ),
    bugfix('Kokoonpanotiedot', 'KEN-460: Korjattu virhetekstien värit yömoodissa'),
    featureChange('Tuövuoro-ohje', 'KEN-446: Kasvatettu listanäkymän fonttia'),
    newFeature(
      'Kenttä backend',
      'KEN-424: Lisätty taustajärjestelmään lokitus, jossa näkyy jokaisen haetun työvuoron ensimmäiseen tehtävään liittyvä kalusto'
    ),
  ]),
  version('2.0.0-beta-5', '2020-04-28T14:00:00+03:00', [
    bugfix(
      'Käyttörajoitteet',
      'KEN-452, KEN-411, KEN-434, KEN-410: Korjattu käyttörajoitteiden lataus eri kalustoille'
    ),
  ]),
  version('2.0.0-beta-4', '2020-04-08T15:00:00+03:00', [
    featureChange('Työvuoroluettelo', 'Hiottu asettelun skaalaamista isommille resoluutioille'),
    bugfix(
      'Työvuoro-ohje',
      'KEN-442: Poistettu viimeisessä työtehtävässä näkyvä ylimääräinen vaakaviiva'
    ),
    bugfix('Etusivu', 'Korjattu "olet ilmoittautunut"-tekstin asettelua'),
    bugfix('Etusivu', 'Korjattu seuraavaan vuoroon osoittava linkki'),
    featureChange(
      'Tehtävän yhteystiedot',
      'Tehtävän yhteystietopainike on nyt harmaa, mikäli yhteystietoja ei löydy'
    ),
    bugfix(
      'Työvuoroluettelo',
      'KEN-437: Korjattu bugi, joka näytti käyttäjälle sisäänkirjautumispainikkeen kaksiosaisen työvuoron toiseen osaan'
    ),
    featureChange('Yönäkymä', 'KEN-441: Parannettu yötilan värityksiä useassa näkymässä'),
    bugfix('Työvuoro-ohje', 'KEN-434: Haetaan rajoitetiedot aina, kun tehtävä avataan'),
  ]),
  version('2.0.0-beta-3', '2020-04-08T15:00:00+03:00', [
    featureChange(
      'Työvuoro-ohje',
      'KEN-419: Tehty korjauksia uuteen käyttöliittymään 7.4. annetun palautteen perusteella '
    ),
    featureChange(
      'Kokoonpanotiedot',
      'KEN-350: Korostettu junanumeron näkyvyyttä kokoonpanosivulla'
    ),
    newFeature('Logitukset', 'KEN-427: Lisätty backendiin ilmoittautumiseen liittyvää logitusta'),
    bugfix('Pääkäyttäjä', 'KEN-257: "Lataa henkilön tiedot" -kenttä autofokusoituu'),
  ]),
  version('2.0.0-beta-2', '2020-04-01T14:00:00+03:00', [
    featureChange(
      'Työvuoro-ohje',
      'KEN-419: Jatkettu työvuoro-ohjeen uudistamista, korjattu kaikki kohdat, jotka mainittiin 31.3. tulleessa palautteessa'
    ),
    bugfix(
      'Käyttörajoitetiedot',
      'Korjattu bugi, jonka seurauksena käyttörajoitetiedot saattoivat jäädä lataamaan ikuisesti, kun tehtäviä availtiin nopeasti peräkkäin'
    ),
    bugfix(
      'Hakusivut',
      'KEN-271: Korjattu hakukenttien tekstien värit näkyvämmiksi yömoodia käyttäessä'
    ),
  ]),
  version('2.0.0-beta-1', '2020-03-18T15:30:00+03:00', [
    newFeature(
      'Työvuoro-ohje',
      'KEN-349: Tehty merkittäviä muutoksia työvuoronäkymään mobiililaitteilla palautteen perusteella'
    ),
    featureChange(
      'Yömoodi',
      'Käyttäjän asetus tallentuu nyt selaimen muistiin, eli käyttäjän yömoodiasetus ei unohdu yhtä helposti'
    ),
    bugfix(
      'Käyttörajoitetiedot',
      'KEN-411: Lisätty käyttöliittymän reagointia, kun käyttörajoituksien sijaan tulee tyhjä vastaus'
    ),
    bugfix('Työuvuoroluettelo', 'KEN-417: Backendi jättää nyt aikavyöhykkeet huomioimatta'),
  ]),
  version('1.6.0-beta-1', '2020-03-05T10:00:00+03:00', [
    newFeature('Käyttöoikeudet', 'KEN-415: Lisätty ad-ryhmä lähiliikenteen konduktööreille'),
    bugfix(
      'Työvuoroluettelo',
      'Lähiliikenteen kuskeille ja konduktööreille ei enää näytetä virheilmoitusta, kun työvuoroja ei löydy.'
    ),
  ]),
  version('1.5.1-beta-1', '2020-03-05T10:00:00+03:00', [
    bugfix(
      'Ajotietotuloste',
      'KEN-413: Korjattu ajotietotulosteen latauksen vahvistuksen skaalausta tablettiresoluutioilla'
    ),
  ]),
  version('1.5.0-beta-1', '2020-02-19T15:00:00+03:00', [
    bugfix(
      'Käyttörajoitetiedot',
      'KEN-375: Korjattu bugi, joka aiheutti väärien ajotietojen hakeutumisen välimuistista'
    ),
  ]),
  version('1.4.0-beta-2', '2020-02-11T18:00:00+03:00', [
    featureChange(
      'Tietoa sovelluksesta',
      'KEN-399: Poistettu teksti, jossa ilmoitettiin sovelluksen olevan pilottivaiheessa'
    ),
    featureChange(
      'Kenttä backend',
      'Lisätty logitukseen tarkemmat tiedot sisääntulevista requesteista'
    ),
  ]),
  version('1.4.0-beta-2', '2020-01-29T14:30:00+03:00', [
    featureChange(
      'Työvuoroluettelo',
      'KEN-315: Siirretty ajotietotulosteen latausnappi sivun loppuosaan'
    ),
    featureChange(
      'Työvuoroluettelo',
      'KEN-379: Poistettu sijainti lepovuoron kortista huonon toimivuuden takia'
    ),
    bugfix(
      'Käyttörajoitetiedot',
      'KEN-389: Korjattu käyttörajoitetietojen listauksen prioriteetijärjestystä'
    ),
    bugfix(
      'Hakusivut',
      'KEN-392: Hakusivun navigaatiopalkki mahtuu nyt mobiilillakin yhdelle ruudulle'
    ),
  ]),
  version('1.4.0-beta-1', '2020-01-21T09:30:00+03:00', [
    featureChange('Käyttörajoitetiedot', 'KEN-372: Tehty käyttörajoitetiedoista helpompia huomata'),
  ]),
  version('1.3.0-beta-2', '2020-01-14T15:00:00+03:00', [
    bugfix(
      'Täsmällisyys',
      'Korjattu virhe, joka saattoi aiheuttaa ohjelman kaatumisen, jos sisääntuleva täsmällisyysdata oli puutteellista'
    ),
  ]),
  version('1.3.0-beta-1', '2020-01-14T10:00:00+03:00', [
    bugfix('Kenttä backend', 'Kasvatettu lokituksen määrää ja tarkkutta'),
  ]),
  version('1.2.1-beta-3', '2020-01-13T14:00:00+03:00', [
    bugfix(
      'Käyttäjäroolit',
      'Lisätty dev-kentta.vr.fi:hin ympäristömuuttuja KENTTA_INSTANCE=dev-kentta testikäyttäjäryhmien kirjautumisen sallimiseksi'
    ),
    featureChange('eSälli', 'Kasvatettu eSälli-kutsujen timeout 10000ms --> 30000 ms'),
  ]),
  version('1.2.1-beta-2', '2019-12-18T10:30:00+03:00', [
    featureChange(
      'Käyttäjäroolit',
      'KEN-363: Muutettu käyttäjäryhmien roolitarkistukset additiivisiksi'
    ),
  ]),
  version('1.2.1-beta-1', '2019-12-11T20:30:00+03:00', [
    featureChange('Käyttäjäroolit', 'Poistettu Kulkurin tuotanto- ja testiryhmä'),
    featureChange('Sovelluksen konfiguraatio', 'Asetettu päälle tuotannossa NODE_ENV=production'),
    featureChange('Monitorointi', 'Lisätty health check -palvelu'),
  ]),
  version('1.2.0-beta-2', '2019-12-10T15:30:00+03:00', [
    bugfix(
      'Etusivu',
      'KEN-356: Korjattu bugi, joka esti edeltävänä päivänä alkaneen vuoron näkymisen etusivulla'
    ),
  ]),
  version('1.2.0-beta-1', '2019-12-03T16:30:00+03:00', [
    bugfix(
      'Kokoonpanotiedot',
      'KEN-285: Korjattu taulukoiden asettelua mobiilinäkymässä, kun junalla on VAK-tietoja'
    ),
    bugfix(
      'Työvuoroluettelo',
      'Kasvatettu väliä, jonka mittaiset tauot näytetään työvuoroluettelossa erillisenä "lepovuoron" (8 -> 9 tuntia)'
    ),
  ]),
  version('1.1.0-beta-4', '2019-11-20T10:20:00+03:00', [
    featureChange(
      'Työvuoro-ohje',
      'KEN-337: Ohjeen ylälaidassa näytetään nyt vuoron kesto ilman lepoja, eli työaika'
    ),
    bugfix(
      'Käyttörajoitetiedot',
      'KEN-341: Korjattu välimuistiongelma, joka aiheutti väärien käyttörajoitetietojen näyttämisen'
    ),
  ]),
  version('1.1.0-beta-3', '2019-11-20T10:20:00+03:00', [
    featureChange(
      'Käyttäjäroolit',
      'KEN-336: Lisätty logistiikan käyttäjäryhmä log_RO-kuljettajat ja vaihtotyönjohtajat kaukoliikenteen kuljettajarooliin'
    ),
    bugfix(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'KEN-331: Korjattu tapaus, jossa kokoonpanoja yritettiin hakea junanumerolla, jossa oli kirjaimia'
    ),
    bugfix(
      'Käyttörajoitetiedot',
      'KEN-321: Kokoonpanonäkymän vaunukohtainen käyttörajoitteiden haku toimii nyt'
    ),
    bugfix(
      'Työvuoroluettelo',
      'KEN-291: Työvuoroluettelon meneillään olevan vuoron kohdalla näkyy senhetkiseen työtehtävään liittyvän junan numero'
    ),
  ]),
  version('1.1.0-beta-2', '2019-11-15T14:30:00+03:00', [
    bugfix(
      'Käyttöoikeudet',
      'KEN-329: Lisätty konduktöörirooliin ryhmä M_240_Vaihtotyonjohtajat_AE'
    ),
  ]),
  version('1.1.0-beta-1', '2019-11-06T12:00:00+03:00', [
    bugfix(
      'Käyttörajoitetiedot',
      'KEN-323: Käyttörajoitteiden latausindikaattori pysyy nyt näkyvissä, kunnes lataus on loppuun asti valmis'
    ),
    bugfix(
      'Etusivu',
      'KEN-314: Korjattu bugi, joka muutti kellonaikoja, jos käyttäjä kävi päivittämässä työvuorolistan ja palasi etusivulle'
    ),
  ]),
  version('1.0.0-beta-4', '2019-11-04T14:15:00+03:00', [
    bugfix('Työvuoro-ohje', 'KEN-318: Korjattu bugi, joka esti veturin ottosaijinnin näytön'),
    bugfix(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'KEN-309: Vaihdettu Vaunun nro -listan lyhyt-/pitkänumerovaihdin päivitysikonista vivuksi'
    ),
  ]),
  version('1.0.0-beta-3', '2019-11-04T08:00:00+03:00', [
    bugfix(
      'Käyttäjäroolit',
      'KEN-253: Jos käyttäjällä admin- tai selaaja-oikeus tuotannossa, saa saman oikeuden myös testissä'
    ),
    bugfix('Työvuoroluettelo', 'KEN-308: Työvuoroluettelon tietoja yhtenäistetty'),
    bugfix(
      'Työvuoroluettelo',
      'KEN-259: Kaksiosaisten työvuorojen kestot näkyvät nyt oikein laskettuna'
    ),
  ]),
  version('1.0.0-beta-2', '2019-10-29T15:15:00+03:00', [
    featureChange(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'KEN-216: Klikkaamalla otsaketta, voi KAPU junien kanssa vaihdella pitkien ja lyhyiden vaununnumeroiden välillä'
    ),
    newFeature(
      'Hakusivut',
      'KEN-272: Hakusivuilla olevat haut voi nyt käynnistää painamalla enteriä'
    ),
  ]),
  version('0.6.0-beta-1', '2019-10-29T15:15:00+03:00', [
    bugfix(
      'Sovelluksen palaute',
      'KEN-286: Jarruvahvistusten tekstien tulisi nyt näkyä kokonaan myös pienemmillä kännykkäruuduilla'
    ),
    bugfix(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'KEN-270: KAPU-junille ei enää näytetä VEMPU-linkkiä, jos kokoonpanoa ei löydy'
    ),
    featureChange(
      'Työvuoroluettelo',
      'KEN-297: Muutettu konnareiden työtehtävien otsakkeita halutunlaiseksi'
    ),
  ]),
  version('0.5.0-beta-4', '2019-10-22T15:15:00+03:00', [
    featureChange(
      'Sovelluksen palaute',
      'KEN-303: Usabilla otettu pois käytöstä, tilalla mailto-linkki osoitteeseen kentta@avarko.com'
    ),
  ]),
  version('0.5.0-beta-3', '2019-10-15T12:20:00+03:00', [
    featureChange(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'KEN-284: Lisätietokenttään lisätty tekstin lihavoinnit'
    ),
    featureChange(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'KEN-281: Jos VAK-tietoja löytyy, korostetaan kenttiä harmaalla taustalla'
    ),
    featureChange(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'KEN-248: KAPU-junille lisätty lähtöraiteet kokoonpanonäkymässä'
    ),
    featureChange(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'KEN-287: Poistettu RCS-junilta SNJ -kenttä kokoonpanojen huomautukset-näkymässä'
    ),
    bugfix('Henkilökuntahaku', 'KEN-300: Muutettu yömoodin värejä näkyvämmäksi'),
    featureChange(
      'Työvuoro-ohje',
      'KEN-263: Matkustustehtävien otsakkeissa on nyt myös asiaankuuluvan junan numero'
    ),
  ]),
  version('0.5.0-beta-2', '2019-10-15T12:20:00+03:00', [
    bugfix(
      'Käyttörajoitetietojen haun ja esittämisen parantaminen',
      'KEN-298: Lisätty käyttörajoitteiden ikonit huomioimaan tapaukset joissa työtehtävällä useampi veturi ja veturilla useampi vika'
    ),
    featureChange(
      'Sovelluksen versiohistoria',
      'Dev- ja tuotantoympäristön versiohistoriat eri listaukset: devissä sovelluskehittäjien kuvaama tarkka lista, tuotannon kuvaukset käyttäjille suunnatut'
    ),
  ]),
  version('0.5.0-beta-1', '2019-10-11T16:30:00+03:00', [
    bugfix(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'KEN-269: Poistettu virheellinen aikaleima VET-junilta, kun TJT:tä ei oltu tehty'
    ),
    featureChange(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'KEN-282/KEN-283: Paranneltu keltaisen korostuksen ulkoasua'
    ),
    featureChange(
      'Käyttörajoitetietojen haun ja esittämisen parantaminen',
      'KEN-280: näytetään virheilmoitus jos vikailmoitusten haku KuntoBusista/Vikaosiosta epäonnistuu ja selkeytetty esitystapaa'
    ),
  ]),
  version('0.4.0-beta-3', '2019-10-08T12:30:00+03:00', [
    bugfix(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'KEN-292: Muutettu liikennöintitarkastuksen napin ulkoasua'
    ),
  ]),
  version('0.4.0-beta-2', '2019-10-04T23:30:00+03:00', [
    bugfix(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'KEN-290: Poistettu kokoonpanohakujen historialistasta välimuistitus'
    ),
  ]),
  version('0.4.0-beta-1', '2019-10-04T14:00:00+03:00', [
    bugfix(
      'Täydellisen jarrujentestauksen aikaleima',
      'Korjattu KEN-277: Täydellisen jarrujentestauksen aikaleimassa näkyi virheellisesti liikennöintitarkastuksen aikaleima'
    ),
  ]),
  version('0.3.2', '2019-10-02T10:00:00+03:00', [
    bugfix(
      'Käyttörajoitteiden ja veturitehtävän tietojen näyttäminen',
      'Korjattu käyttörajoitteiden värityksiä ja muita bugeja'
    ),
    featureChange(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'Muokattu kokoonpanolistaa helpompilukuiseksi VAK -tapauksissa'
    ),
    bugfix(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'Korjattu bugi, joka esti kokoonpanojen hakemisen ääkkösellisille sijainneille'
    ),
  ]),
  version('0.3.1', '2019-09-24T15:00:00+03:00', [
    featureChange(
      'Työvuoro-ohjeiden haku',
      'Lisätty konduktööreille tehtävien otsakkeisiin junanumerot'
    ),
    featureChange(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'Kohennettu liikennöinti- ja jarrutarkastuksien nappien ulkoasua ja toimivuutta'
    ),
    bugfix('Vara-aikataulujen haku', 'Vaihdettu vara-aikataulujen teksti ajotietotulosteeseen'),
  ]),
  version('0.3.0', '2019-08-22T14:00:00+03:00', [
    featureChange(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'Päivitetty kokoonpanonäkymän ulkoasua'
    ),
    bugfix(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'Korjattu useampia jarrupainoihin ja kokoonpanoihin liittyviä bugeja'
    ),
  ]),
  version('0.2.6', '2019-08-22T14:00:00+03:00', [
    featureChange(
      'Junien täsmällisyys',
      'Ajamattomien junien täsmällisyyssivun ulkoasua päivitetty'
    ),
    featureChange('Junien täsmällisyys', 'Syykoodien värit indikoivat nyt vastuullista osapuolta'),
    bugfix('Junien täsmällisyys', 'Vaihtotöiden täsmällisyyksien pitäisi nyt näkyä'),
    featureChange(
      'Junien täsmällisyys',
      'Muutettu logiikkaa, jolla tuotetaan arvo työvuoronäkymän työvuoroihin'
    ),
  ]),
  version('0.2.5', '2019-08-12T13:00:00+03:00', [
    featureChange('Junien täsmällisyys', 'Päivitetty täsmällisyyssivun ulkoasua'),
    featureChange(
      'Junien täsmällisyys',
      'Täsmällisyystietoja päivitetään automaattisesti taustalla (minuutin välein)'
    ),
  ]),
  version('0.2.4', '2019-07-31T15:30:00+03:00', [
    newFeature(
      'Junien täsmällisyys',
      'Työvuoron junille näytetään täsmällisyystiedot toteutuneiden ohitusaikojen ja avoimesta rajapinnasta saatavien syykoodien avulla.'
    ),
    bugfix('Henkilökuntahaku', 'Korjaus päivämäärien käsittelyyn.'),
    featureChange(
      'Työvuoro-ohjeiden haku',
      'Työvuorojen ohjeet haetaan uudesta rajapinnasta, jonka tulisi olla hieman tehokkaampi.'
    ),
  ]),
  version('0.2.3', '2019-07-26T15:00:00+03:00', [
    featureChange(
      'Haut taustajärjestelmään',
      'Parannuksia käyttäjän session vanhenemisen käsittelyyn.'
    ),
    featureChange('Asematiedot', 'Asematietodatan päivitys tuoreimman tiedon mukaisesti.'),
    featureChange('Kokoonpanohaku', 'Parannus hakuhistorian toimintaan.'),
  ]),
  version('0.2.2', '2019-06-20T16:00:00+03:00', [
    featureChange('Kokoonpanohaku', 'Parannuksia kokoonpanojen esittämiseen ja hakemiseen.'),
  ]),
  version('0.2.1', '2019-05-28T16:00:00+03:00', [
    featureChange(
      'Hakusivut',
      'Parannettu virheviestien toimintaa, poistettu hämäävät vihjetekstit syöttökentistä + muita pieniä parannuksia käytettävyyteen ja ulkoasuun.'
    ),
    newFeature(
      'Hakutuloshistoria',
      'Vanhoja hakuja pystyy poistamaan ja niihin lisätty myös automaattinen poisto 36 tunnin jälkeen.'
    ),
  ]),
  version('0.2.0', '2019-05-08T08:00:00+03:00', [
    featureChange(
      'Taustajärjestelmän ympäristö',
      'Siirrytty Lambda-pohajaisesta ympäristöstä kontitettuun ympäristöön. Muutoksen toivotaan parantavan sovelluksen vasteaikoja.'
    ),
    newFeature(
      'Kokoonpanojen ja jarrupainotietojen haku',
      'Lisätty kokoonpanojen hakutoiminnallisuus hakusivulle ja työvuoro-ohjeille.'
    ),
    newFeature(
      'Vara-aikataulujen haku',
      'Lisätty vara-aikataulujen hakutoiminnallisuus hakusivulle ja työvuoro-ohjeille.'
    ),
  ]),
  version('0.1.7', '2019-01-08T13:32:00+03:00', [
    newFeature(
      'Käyttörajoitteiden ja veturitehtävän tietojen näyttäminen',
      'Lisätty työvuoro-ohjeille veturien käyttörajoitteiden haku. Työvuoroilla näytetään myös veturin sijaintitiedot ja huomiot.'
    ),
  ]),
  version('0.1.6', '2018-10-26T13:32:00+03:00', [
    newFeature(
      'Työvuoro-ohjehaku',
      'Lisätty työvuoro-ohjeiden hakuominaisuus. Haku toimii päivämäärällä, lähtöasemalla ja junanumerolla, tai päivämäärällä ja työvuorotunnuksella.'
    ),
  ]),
  version('0.1.5', '2018-10-26T13:32:00+03:00', [
    newFeature(
      'Henkilökuntahaku',
      'Lisätty henkilökunnan hakuominaisuus. Haku toimii päivämäärällä ja junanumerolla.'
    ),
  ]),
  version('0.1.4', '2018-05-11T11:49:48+03:00', [
    featureChange(
      'Työvuoro-ohje',
      'Puuttuva työvuoron päättymisaika päätellään työvuoron viimeisen tehtävän lopetusajankohdasta ja työvuoron lopetusajasta.'
    ),
    bugfix(
      'Työvuoroluettelo',
      'Työvuoroluettelo ei näytä enää latausindikaattoria kun lataus on päättynyt.'
    ),
  ]),
  version('0.1.3', '2018-04-27T12:01:01+03:00', [
    featureChange(
      'Työvuoroon ilmoittautuminen',
      'eSäLListä poistetut työvuoroon ilmoittautumiset poistuvat nyt myös Kenttä -sovelluksesta työvuoroluettelon päivityksen yhteydessä.'
    ),
  ]),
  version('0.1.2', '2018-04-20T12:51:37+03:00', [
    featureChange(
      'Työvuoropalaute',
      'Työvuorosta voi nyt antaa palautetta heti työvuoroon ilmoittautumisen jälkeen.'
    ),
    featureChange(
      'Työvuoro-ohje',
      'Työvuoro-ohjeet latautuvat nyt vasta työvuoro-ohje näkymää avattaessa.'
    ),
  ]),
  version('0.1.1', '2018-04-13T16:26:46+03:00', [
    featureChange('Työvuoropalaute', 'Työvuoropalaute on nyt aktivoitu pilottiympäristössä.'),
    newFeature(
      'Ilmoitus versiopäivityksestä',
      'Sovelluksen päivittyessä uuteen versioon käyttäjälle näytetään ilmoitus.'
    ),
  ]),
  version('0.1.0', '2018-04-06T14:31:48+03:00', [
    newFeature(
      'Tietoa sovelluksesta',
      '"Lisää" -sivulle lisätty linkki "Tietoa sovelluksesta" -sivulle.'
    ),
    newFeature('Versiohistoria', 'Lisätty versiohistoria "Tietoa sovelluksesta" -sivulle.'),
    newFeature(
      'Tehtävien merkkaaminen tehdyiksi',
      'Tehdyiksi merkattuja tehtäviä voi nyt merkata tekemättömiksi.'
    ),
    bugfix('Tehtävän asematieto', 'Tehtävien asematieto ei leikkaudu enää pienillä ruuduilla.'),
  ]),
  version('0.0.9', '2018-03-23T13:27:00+02:00', [
    newFeature(
      'Ympäristö',
      'Ohjelmiston ympäristö (tuotanto tai kehitys) näkyy nyt versionumerossa teksteinä dev tai prod.'
    ),
    featureChange(
      'Tehtävien kuittaaminen tehdyiksi',
      'Tehtäviä voidaan nyt kuitata valmiiksi vain vuoroista joihin ollaan ilmoittauduttu.'
    ),
    featureChange(
      'Vastaus työvuoropalautteeseen',
      'Työvuoropalauteen vastaus on nyt näkyvissä myös palautesivulla.'
    ),
    bugfix(
      'Palautteen tähtimerkintä',
      'Palautteen tähtimerkinnän asettelu korjattu Internet Explorerissa.'
    ),
    bugfix(
      'Työvuoroluettelo',
      'Työvuoroluettelon "seuraava kuukausi" komponentin tekstin väri korjattu.'
    ),
  ]),
  version('0.0.8', '2018-03-13T12:17:53+02:00', [
    featureChange(
      'Offline tila',
      'Offline tilan tarkistusta yksinkertaistettu, poistettiin "heikko yhteys" välitila.'
    ),
    bugfix(
      'Palaute työvuorolistasta',
      'Työvuorolistapalautteen avaava widget sulkeutuu kun siirrytään palautesivulle.'
    ),
    bugfix(
      'Työvuoroluottelo',
      'Työvuoroluettelossa ei näytetä enää lepoja joiden pituus on 0 minuuttia.'
    ),
  ]),
  version('0.0.7', '2018-03-09T15:19:25+02:00', [
    featureChange(
      'Työvuoroon ilmoittautuminen',
      'Menneissä työvuoroissa ei näytetä enää "Ilmoittaudu työvuoroon" -painiketta.'
    ),
  ]),
  version('0.0.6', '2018-03-05T10:27:14+02:00', [
    featureChange(
      'Tehtävän yhteystiedot',
      'Tehtävän yhteystietojen väritystä parannettu käytettäessä yöteemaa.'
    ),
    featureChange(
      'Päivämäärien muotoilu',
      'Nykyhetkestä kaukana olevien päivämäärien esitystapaa muutettu.'
    ),
  ]),
  version('0.0.5', '2018-02-26T14:43:17+02:00', [
    bugfix('Työvuoron huomio', 'Työvuoron huomio lisätty työvuoro-ohjeen alkuun.'),
    featureChange(
      'Tehtävän yhteystiedot',
      'Yhteystietojen asettelua muokattu mahtumaan pienempään tilaan.'
    ),
    featureChange('Ratatiedot', 'Tehtävän lähtö- ja tuloraide näkyy nyt tehtävärivillä.'),
  ]),
]

export default changelog_test
