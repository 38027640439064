import styled from '@emotion/styled'
import React from 'react'

import { formatDuration } from '../../lib/time'
import { getColor } from '../../Theme'
import { MaybeColor, Timestamp } from '../../types'
import { EmphasisNormal } from '../Emphasis'
import Annotated from './Annotated'

const Duration = styled(EmphasisNormal)<{ horizontal: boolean; color: MaybeColor }>`
  color: ${(p) => getColor(p.theme, [p.color, 'black'], ['grayPink'])};
  display: block;
  flex: 2;
  text-align: ${(p) => (p.horizontal ? 'right' : 'inherit')};
  width: 100%;
`

type Props = {
  title: string
  duration: Timestamp
  horizontal: boolean
  color?: MaybeColor
}

function AnnotatedDuration({ title, duration, horizontal, color }: Props) {
  return (
    <Annotated title={title} horizontal={horizontal} color={color}>
      <Duration horizontal={horizontal} color={color}>
        {formatDuration(duration)}
      </Duration>
    </Annotated>
  )
}
export default AnnotatedDuration
