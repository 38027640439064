import {
  COMPOSITIONS_ERROR,
  OPEN_MODAL,
  SET_COMPOSITION_FROM_HISTORY,
  START_CHECK_OR_INSPECTION,
  START_FETCHING_COMPOSITIONS,
  UPDATE_COMPOSITION_HISTORY,
  UPDATE_COMPOSITIONS,
} from '../actions/compositions'
import { CompositionsAction } from '../actions/compositions'
import { CompositionsState } from '../types'

export const initialState = {
  loading: false,
  error: '',
  compositionForTrain: undefined,
  posting: false,
  history: [],
  modal: {
    open: false,
    message: '',
    confirmCompleted: undefined,
    confirmType: undefined,
    sourceSystem: '',
    locomotives: undefined,
    action: undefined,
    secondAction: undefined,
  },
}

const compositionsReducer = (
  state: CompositionsState = initialState,
  action: CompositionsAction
): CompositionsState => {
  switch (action.type) {
    case START_FETCHING_COMPOSITIONS:
      return {
        ...state,
        loading: true,
        compositionForTrain: undefined,
      }

    case UPDATE_COMPOSITIONS:
      return {
        ...state,
        error: '',
        loading: false,
        posting: false,
        compositionForTrain: action.payload,
      }

    case COMPOSITIONS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case START_CHECK_OR_INSPECTION:
      return {
        ...state,
        posting: true,
        modal: { ...state.modal, ...{ open: false } },
      }

    case OPEN_MODAL:
      return { ...state, modal: action.payload }

    case UPDATE_COMPOSITION_HISTORY:
      return {
        ...state,
        history: action.payload,
      }

    case SET_COMPOSITION_FROM_HISTORY:
      return {
        ...state,
        compositionForTrain: action.payload,
      }

    default:
      return state
  }
}

export default compositionsReducer
