import React from 'react'
import { connect } from 'react-redux'

import { saveTowingFormState } from '../../../actions/api'
import { TowingFormState } from '../../../types/Input'
import TowingConfirmButton from './TowingConfirmButton'

interface TowingConfirmToNextProps {
  enabled: boolean
  saving: boolean
  towingFormState: TowingFormState
  onActionChange: (towingFormState: TowingFormState, stepKey: string) => void
}

const TowingConfirmToNext = ({
  enabled,
  onActionChange,
  saving,
  towingFormState,
}: TowingConfirmToNextProps) => {
  const handleHandOverToNext = () => {
    onActionChange(towingFormState, 'TRANSITION_TO_NEXT')
  }

  return (
    <TowingConfirmButton
      onClick={(e) => {
        e.stopPropagation()
        handleHandOverToNext()
      }}
      disabled={!enabled}
      buttonText={saving ? 'Tallennetaan' : 'Kuittaa valmis seuraavalle'}
      confirmed={false}
    />
  )
}

const mapStateToProps = (state) => {
  const saving = state.towingFormState.savingStep === 'TRANSITION_TO_NEXT'
  const towingFormState = state.towingFormState
  return {
    saving,
    towingFormState,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onActionChange: (towingFormState, stepKey) =>
    dispatch(saveTowingFormState(towingFormState, 'handover', stepKey)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TowingConfirmToNext)
