import styled from '@emotion/styled'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'

import { defaultTextColor, theme } from '../../Theme'
import Button from '../button/Button'

const ModalStyle = {
  content: {
    height: '40%',
    alignContent: 'space-around',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    top: 'auto',
    bottom: '120px',
    left: '50px',
    right: '50px',
  },
} as const

const CloseModalButton = styled(Button)`
  width: 100px;
  height: 40px;
  margin-top: 4px;
`

const ErrorMessage = styled.div`
  ${theme.text('large', 'content')};
  color: ${defaultTextColor};
  text-align: center;
  ${theme.spacing.ends('tiny')};
  ${theme.spacing.sides('smallest')};
`

type PropsIn = {
  isOpen: boolean
  message: string
  closeErrorModal: () => void
}

const AmendmentErrorModal = ({ isOpen, message, closeErrorModal }: PropsIn) => {
  const determineButtonType = (): string => {
    switch (message) {
      case 'errors.amend.networkError':
        return 'close'
      case 'errors.amend.badRequest':
        return 'return'
      case 'errors.amend.invalidParameter':
        return 'close'
      case 'errors.amend.unknown':
        return 'close'
      default:
        return 'close'
    }
  }

  useEffect(() => {
    Modal.setAppElement('body')
  }, [])

  const type: string = determineButtonType()
  const { t } = useTranslation()

  return (
    <Modal isOpen={isOpen} style={ModalStyle}>
      <ErrorMessage>{t(message)}</ErrorMessage>
      <ErrorMessage>{t(`deviationAmendment.${type}Error`)}</ErrorMessage>
      {type === 'close' ? (
        <CloseModalButton onClick={() => closeErrorModal()}>{t('close')}</CloseModalButton>
      ) : (
        <CloseModalButton onClick={() => window.history.back()}>{t('return')}</CloseModalButton>
      )}
    </Modal>
  )
}

export default AmendmentErrorModal
