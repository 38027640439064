import SuperError from 'super-error'

import { info } from './logger'

export const BreakRetryError = SuperError.subclass('BreakRetryError')
export const APIError = SuperError.subclass('APIError')
export const BadRequest = APIError.subclass('BadRequest')
export const NotFound = APIError.subclass('NotFound')
export const InvalidParameter = APIError.subclass('InvalidParameter')
export const ExternalError = APIError.subclass('ExternalError')
export const ExternalTimeout = ExternalError.subclass('ExternalTimeout')
export const ExternalThrottle = ExternalError.subclass('ExternalThrottle')
export const Conflict = APIError.subclass('Conflict')
export const MigrationError = APIError.subclass('MigrationError')

type APIErrorInput = {
  payload: string
  code: string
  message: string
}

interface ErrorMessage {
  message: string
}
export interface ErrorProps extends ErrorMessage {
  name: string
}

const errorCodes = {
  BAD_REQUEST: BadRequest,
  NOT_FOUND: NotFound,
  INVALID_PARAMETER: InvalidParameter,
  EXTERNAL_TIMEOUT: ExternalTimeout,
  EXTERNAL_TOO_MANY_REQUESTS: ExternalThrottle,
  EXTERNAL_ERROR: ExternalError,
  CONFLICT: Conflict,
} as Record<string, typeof APIError>

export const transformAPIError = (err: APIErrorInput): SuperError => {
  const code = typeof err.code === 'string' ? err.code : ''
  let Constructor = APIError

  if (code && errorCodes[code]) {
    Constructor = errorCodes[code]
  }

  return new Constructor(err)
}

export const getApiErrorInput = (err: unknown): APIErrorInput | null => {
  if (err && typeof err === 'object') {
    const inputError = (err as any).response?.data?.error
    if (inputError && typeof inputError === 'object') {
      return {
        code: inputError.code,
        message: inputError.message,
        payload: inputError.details,
      }
    }
  }
  return null
}

const axiosStatus = (err: unknown): number => {
  if (err && typeof err === 'object') {
    const status = (err as any).response?.status
    if (typeof status === 'number') {
      return status
    }
  }
  return 0
}

// When axios call is made in task executor
// make sure some errors are not retried, e.g. 404
// since retry would not help
export const returnNonRetryable = (err: ErrorProps): unknown => {
  const status = axiosStatus(err)
  // 4XX status codes will not be retried
  if (status >= 400 && status < 500) {
    return new BreakRetryError('').causedBy(err)
  }
  // other errors still cause retry
  throw err
}

// When errors have been returned they need to be rethrown
// so that promise rejects
export const rethrowNonRetryable = (input: unknown): any => {
  if (input instanceof BreakRetryError) {
    throw input.cause
  }
  return input
}

export const isConflictError = (err: ErrorMessage): boolean => {
  return err.message === 'Request failed with status code 409'
}

export const isNetworkError = (err?: ErrorProps): boolean => {
  return err?.message === 'Network Error'
}

// Returns translate key for error in scope
// e.g. shift.notFound
export const getErrorString = (err: ErrorProps, scope = 'general'): string => {
  let name = 'unknown'
  if (isNetworkError(err)) {
    name = 'networkError'
  }

  const apiErrorInput = getApiErrorInput(err)
  if (apiErrorInput) {
    const apiError = transformAPIError(apiErrorInput)
    if (apiError instanceof BadRequest) {
      name = 'badRequest'
    } else if (apiError instanceof NotFound) {
      name = 'notFound'
    } else if (apiError instanceof InvalidParameter) {
      name = 'invalidParameter'
    } else if (apiError instanceof ExternalTimeout) {
      name = 'externalTimeout'
    } else if (apiError instanceof ExternalThrottle) {
      name = 'externalThrottle'
    } else if (apiError instanceof ExternalError) {
      name = 'externalError'
    } else if (apiError instanceof Conflict) {
      name = 'conflictError'
    }
  }

  if (name === 'unknown') {
    info('Unspecified error', err)
  }

  return `errors.${scope}.${name}`
}
