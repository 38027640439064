import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Modal from 'react-modal'

import Close from '../../icons/Close'
import Download from '../../icons/Download'
import { getColor, theme } from '../../Theme'
import { SizeStringProps } from '../../types/App'
import { CheckedModal as ModalType, TimetableParams } from '../../types/Input'
import YesNoButtons from './YesNoButtons'

interface ExclamationModalTextProps {
  mobile: boolean
}

const RoundBackground = styled.div<SizeStringProps>`
  width: ${(p) => p.size || '26px'};
  height: ${(p) => p.size || '26px'};
  border-radius: 50%;
  background: white;
  color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Exclamation = styled.div<ExclamationModalTextProps>`
  font-size: ${(p) => (p.mobile ? '28px' : '42px')};
  color: red;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  background-color: ${(p) => getColor(p.theme, ['white'], ['nightBlack'])};
  width: 100%;
  height: 60px;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  bottom: 0;
`

const ModalContent = styled.div``

const ModalStyle = {
  content: {
    backgroundColor: getColor({ nightMode: false }, ['primaryGreen'], ['primaryGreen']),
    alignContent: 'space-around',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    left: '50%',
    transform: 'translate(-50%)',
    maxWidth: theme.maxWidths.content,
    minWidth: theme.maxWidths.paddedColumn,
    minHeight: '270px',
    maxHeight: '300px',
  },
}

const ModalText = styled.div<ExclamationModalTextProps>`
  color: ${(p) => getColor(p.theme, ['white'], ['white'])};
  font-size: ${theme.font.normal};
  padding-top: ${(p) => (p.mobile ? '15px' : '30px')};
`

const Button = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ButtonText = styled.div`
  color: ${(p) => p.color};
  padding-left: 8px;
`

type Props = {
  t: TFunction
  fetchTimetable: (isUsedForDriving: boolean, parts: Array<TimetableParams>) => void
  timetableParams: Array<TimetableParams>
  modal: ModalType
  openModal: (isOpen: boolean, usedTimetableParams: Array<TimetableParams>) => void
  checkInModal: (checkInModal: boolean) => void
  isMobile: boolean
}

class UsedForDrivingModal extends Component<Props> {
  componentDidMount() {
    Modal.setAppElement('body')
  }

  render() {
    const { t, fetchTimetable, timetableParams, modal, openModal, checkInModal, isMobile }: Props =
      this.props
    return (
      <Modal isOpen={modal.open} style={ModalStyle}>
        <ModalContent>
          <RoundBackground size={isMobile ? '40px' : '66px'}>
            <Exclamation mobile={isMobile}>!</Exclamation>
          </RoundBackground>
        </ModalContent>
        <ModalText mobile={isMobile}>{t('useForDriving')}</ModalText>
        <YesNoButtons customSelected={modal.checked} t={t} onClick={checkInModal} />
        <Row>
          <Button onClick={() => fetchTimetable(modal.checked, timetableParams)}>
            <Download iconSize="smaller" fillColor="green" />
            <ButtonText color="green">{t('load')}</ButtonText>
          </Button>
          <Button onClick={() => openModal(false, [])}>
            <Close iconSize="smaller" fillColor="red" />
            <ButtonText color="red">{t('discard')}</ButtonText>
          </Button>
        </Row>
      </Modal>
    )
  }
}

export default withTranslation()(UsedForDrivingModal)
