import { Schedule } from '../types'

export type UpdateSchedule = {
  type: 'UPDATE_SCHEDULE'
  payload: Schedule
}
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE'
export const updateSchedule = (schedule: Schedule): UpdateSchedule => ({
  type: UPDATE_SCHEDULE,
  payload: schedule,
})

export type ReloadSchedule = {
  type: 'RELOAD_SCHEDULE'
}
export const RELOAD_SCHEDULE = 'RELOAD_SCHEDULE'
export const reloadSchedule = (): ReloadSchedule => ({
  type: RELOAD_SCHEDULE,
})

export type ClearShifts = {
  type: 'CLEAR_SHIFTS'
}
export const CLEAR_SHIFTS = 'CLEAR_SHIFTS'
export const clearShifts = (): ClearShifts => ({
  type: CLEAR_SHIFTS,
})

export type ShiftAction = UpdateSchedule | ReloadSchedule | ClearShifts
