import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const Towing = ({ iconSize, width }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={width ?? size} height={size} viewBox="0 0 24 24">
      <rect key="0" opacity="0.7" x="2" y="9" width="6" height="6" rx="1" fill="currentColor" />
      <path
        d="M16.5 18C19.5376 18 22 15.5376 22 12.5C22 10.6687 21.5309 9.293 20.7497 8.32406C19.741 7.07295 18.2118 6.5 16.5 6.5C15.5 6.5 14 6.5 12.5 5C12.5 5 12 6.5 13.5 7.5C13.9921 7.74605 15.695 8.40063 17 9.5C18.3468 10.6346 19.2697 12.2303 18 13.5C16.8414 14.6586 15.6794 13.848 14 12.5C12.7804 11.5211 11.0517 10 10 10H8V14H9.5C11 14 12 16 13.5 17C14.3628 17.5625 15.3932 18 16.5 18Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Towing
