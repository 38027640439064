import styled from '@emotion/styled'
import React from 'react'

import { getColor, MaybeColor, theme } from '../../Theme'

interface DutyEvaluationContainerProps {
  background: string
  color: MaybeColor
}

const DutyEvaluationContainer = styled.div<DutyEvaluationContainerProps>`
  display: table;
  flex-grow: 0;
  float: right;
  text-align: center;
  background: ${(p) => p.background};
  margin-right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  color: ${(p) => getColor(p.theme, [p.color], [p.color])};
`

const DutyEvaluationPercentage = styled.span`
  ${theme.text('large', 'decorative', 'bold')};
  display: table-cell;
  vertical-align: middle;
`

type Props = {
  percentage: number
}

const chooseColors = (percentage: number) => {
  if (percentage >= 0.2) {
    return { background: '#57a50b', color: 'white' }
  } else {
    return { background: '#f2605f', color: 'white' }
  }
}

const DutyEvaluation = ({ percentage }: Props) => {
  const { background, color } = chooseColors(percentage)
  return (
    <DutyEvaluationContainer background={background} color={color as 'white'}>
      <DutyEvaluationPercentage>
        {(percentage * 100).toFixed(1).replace('.', ',')}%
      </DutyEvaluationPercentage>
    </DutyEvaluationContainer>
  )
}
export default DutyEvaluation
