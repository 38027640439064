declare module '@mui/material/styles' {
  interface Theme {
    spacing: (s: SpaceAmount | number) => number
  }
  interface ThemeOptions {
    spacing?: (s: SpaceAmount | number) => number
  }
}

type SpaceAmount = keyof typeof spaces

const spaces = {
  smallest: 4, // smallest
  tiny: 6, // tiny
  smaller: 8, // smaller
  small: 10, // small
  normal: 16, // normal
  large: 20, // large
  larger: 30, // larger
  huge: 40, // huge
  moreHuge: 48, // moreHuge
  biggest: 60, // biggest
}

const spacing = (amount: SpaceAmount | number) => {
  if (typeof amount === 'string') return spaces[amount]

  // support mui default 8px scaling factor along with named amounts
  if (typeof amount === 'number') return 8 * amount

  return 0
}

export default spacing
