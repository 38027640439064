import styled from '@emotion/styled'
import React from 'react'

import { color, theme } from '../../Theme'

const Row = styled.div`
  ${theme.layout.flexRow};
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  ${theme.spacing.left('large')};
  min-width: ${theme.maxWidths.column};
  margin-top: ${theme.spacing.sizes.small};
`

const Circle = styled.div`
  flex: 1;
  max-width: ${theme.spacing.sizes.small};
  height: ${theme.spacing.sizes.small};
  border-radius: ${theme.borderRadius.circle};
  background: ${color('primaryBlue')};
`

const Line = styled.div`
  flex: 9;
  background: ${color('primaryBlue')};
  width: 100%;
  height: 1px;
  margin-bottom: 1px;
`

function ScheduleLine() {
  return (
    <Row>
      <Circle />
      <Line />
    </Row>
  )
}

export default ScheduleLine
