import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React, { ChangeEvent, Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { sendObservationMessage } from '../../actions/api'
import { setObservationMessageError } from '../../actions/observationMessages'
import { OBSERVATION_MESSAGE_CAUSES } from '../../constants'
import ArrowLeft from '../../icons/ArrowLeft'
import Close from '../../icons/Close'
import moment from '../../lib/moment-fi'
import { color, defaultTextColor, getColor, theme } from '../../Theme'
import { Action, AppState, Dispatch } from '../../types'
import { ObservationMessage, Shift, Task } from '../../types/Input'
import { CauseSelector } from '../amendment/CauseSelector'
import { CauseDescription, CauseDisplay } from '../amendment/Common'
import Button from '../button/Button'
import LoadingIndicator from '../LoadingIndicator'

const FormContainer = styled.div`
  ${theme.layout.flexColumn};
  align-items: flex-start;
  border: 0px solid ${(p) => getColor(p.theme, ['grayBlue'], ['nightBlack'])};
  border-radius: ${theme.borderRadius.button};
  padding: ${theme.spacing.sizes.small};
  margin-top: ${theme.spacing.sizes.small};
  ${theme.effects.shadow};
`

const Title = styled.div`
  ${theme.text('large', 'decorative', 'bold')};
  ${theme.layout.flexRow};
  color: ${defaultTextColor};
  justify-content: space-between;
  width: 100%;
  flex-basis: 100%;
  margin-bottom: 0;
`

//Width needs to be 99% because the textarea will otherwise look
//wider than other elements on the page
const DescriptionArea = styled.textarea`
  width: 99%;
  margin-top: -10px;
  background: ${(p) => getColor(p.theme, ['white'], ['grayBackground'])};
  color: ${(p) => getColor(p.theme, ['black'], ['black'])};
  ${theme.text('normal', 'content')};
  border-radius: 4px;
`
DescriptionArea.displayName = 'DescriptionArea'

const DescriptionText = styled.span`
  margin-right: 6px;
  ${theme.text('normal', 'content')}
  color: ${defaultTextColor}
`
DescriptionText.displayName = 'TrainNumberDescription'

const TrainNumberArea = styled.input`
  resize: none;
  height: ${theme.spacing.sizes.larger};
  ${theme.text()}
`
TrainNumberArea.displayName = 'TrainNumberArea'

const ErrorText = styled.div`
  ${theme.text('normal', 'decorative')};
  white-space: nowrap;
  color: red;
  align-self: center;
`
const Column = styled.div`
  ${theme.layout.flexColumn};
  align-items: start;
  justify-content: center;
`
const Row = styled.div`
  ${theme.layout.flexRow};
  align-text: left;
`

const SubmitButton = styled(Button)<{ valid: boolean }>`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  background: ${(p) => (p.valid ? '' : color('grayDisabled'))};
`
const SendingIndicator = styled.div`
  align-self: center;
`

const IconContainerRight = styled.div`
  align-self: right;
`

const IconContainerLeft = styled.div`
  align-self: left;
  padding-top: ${theme.spacing.sizes.tiny};
  padding-right: ${theme.spacing.sizes.tiny};
`

const SuccessButton = styled(SubmitButton)`
  background: ${color('primaryGreen')};
`

type Props = {
  trainNumber: string
  toggleReport: () => void
  loading: boolean
  error: string
  personnelNumber: string
  sendObservationMessage: (observationMessage: ObservationMessage) => void
  setError: (arg0: string) => void
  shift: Shift
  task: Task
  nightMode: boolean
  t: TFunction
}

const TaskObservation = ({
  trainNumber,
  toggleReport,
  loading,
  error,
  personnelNumber,
  sendObservationMessage,
  setError,
  shift,
  task,
  nightMode,
  t,
}: Props) => {
  const [relatedTrainNumber, setRelatedTrainNumber] = useState(trainNumber)
  const [description, setDescription] = useState('')
  const [selectedCause, setSelectedCause] = useState('')
  const [sent, setSent] = useState(false)
  const [success, setSuccess] = useState(false)
  const validDescription = selectedCause !== 'Muu syy' || description !== ''

  useEffect(() => {
    if (sent && !loading && error === '' && !success) {
      setSuccess(true)
      setTimeout(() => {
        toggleReport()
      }, 1000)
    }
  }, [loading, error, sent, success, toggleReport])

  const handleTrainNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRelatedTrainNumber(event.target.value)
  }

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value)
  }

  const handleCauseSelection = (cause: string) => {
    setSelectedCause(cause)
  }

  const handleSubmit = () => {
    if (!validDescription) {
      setError('noDescriptionError')
      return
    }

    // extract logic to handle other reference elements when specced
    const observationReference =
      relatedTrainNumber !== ''
        ? {
            type: 'TRAIN',
            trainNumber: relatedTrainNumber,
            operatingDate: moment(task.taskStartDateTime).format('YYYY-MM-DD'),
          }
        : undefined

    const observationMessageObject: ObservationMessage = {
      title: selectedCause,
      description: description !== '' ? description : undefined,
      personnelNumber,
      dutyDate: moment(shift.startDateTime).format('YYYY-MM-DD'),
      dutyShortName: shift.shiftId,
      messageDateTime: moment().toISOString(),
      reference: observationReference,
    }

    sendObservationMessage(observationMessageObject)
    setSent(true)
  }

  return (
    <FormContainer>
      <Title>
        {t('observationMessage').toUpperCase()}
        <IconContainerRight onClick={() => toggleReport()}>
          <Close iconSize="normal" />
        </IconContainerRight>
      </Title>
      <CauseDisplay>
        <IconContainerLeft onClick={() => handleCauseSelection('')}>
          {selectedCause && <ArrowLeft iconSize="large" />}
        </IconContainerLeft>
        <Column>
          <Row>
            <DescriptionText>{t('trainNumber')}</DescriptionText>
            {selectedCause ? (
              relatedTrainNumber
            ) : (
              <TrainNumberArea
                type="search"
                size={8}
                name="trainNumber"
                value={relatedTrainNumber}
                onChange={handleTrainNumberChange}
                autoComplete="false"
              />
            )}
          </Row>
          <Row>
            <DescriptionText>{selectedCause}</DescriptionText>
          </Row>
        </Column>
      </CauseDisplay>
      {!selectedCause ? (
        <Fragment>
          {OBSERVATION_MESSAGE_CAUSES.map((cause) => (
            <CauseSelector
              key={cause}
              onClick={() => handleCauseSelection(cause)}
              description={cause}
              nightMode={nightMode}
            />
          ))}
        </Fragment>
      ) : (
        <Fragment>
          <CauseDescription>
            {selectedCause !== 'Muu syy'
              ? t('writeOptionalAdditionalInformation')
              : t('writeAdditionalInformation')}
          </CauseDescription>
          <DescriptionArea
            name="description"
            value={description}
            onChange={handleDescriptionChange}
            rows={3}
          />
          {error !== '' ? <ErrorText>{t(error)}</ErrorText> : null}
          {loading ? (
            <SendingIndicator>
              <LoadingIndicator size="normal" padded={true} />
            </SendingIndicator>
          ) : success ? (
            <SuccessButton valid={false}>{t('taskObservationSuccess')}</SuccessButton>
          ) : (
            <SubmitButton valid={validDescription} onClick={handleSubmit}>
              {t('sendObservationMessage')}
            </SubmitButton>
          )}
        </Fragment>
      )}
    </FormContainer>
  )
}

const mapStateToProps = (state: AppState) => {
  const loading = state.observationMessages.loading
  const error = state.observationMessages.error

  const personnelNumber = state.user.originalNumber
  return {
    loading,
    error,
    personnelNumber,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  sendObservationMessage: (observationMessage: ObservationMessage) =>
    dispatch(sendObservationMessage(observationMessage) as unknown as Action),
  setError: (error: string) => dispatch(setObservationMessageError(error)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskObservation)
