import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import Modal from 'react-modal'

import Close from '../../icons/Close'
import SuccessPlain from '../../icons/SuccessPlain'
import moment from '../../lib/moment-fi'
import { getColor, theme } from '../../Theme'
import { Modal as ModalType, Route } from '../../types'
import { ExclamationProps, SizeStringProps } from '../../types/App'
import { ConfirmCheckOrInspection } from './types'

const RoundBackground = styled.div<SizeStringProps>`
  width: ${(p) => p.size || '26px'};
  height: ${(p) => p.size || '26px'};
  border-radius: 50%;
  background: white;
  color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Exclamation = styled.div<ExclamationProps>`
  font-size: 42px;
  color: ${(p) =>
    p.inspectionRequired
      ? getColor(p.theme, ['red'], ['red'])
      : getColor(p.theme, ['primaryGreen'], ['primaryGreen'])};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  background-color: ${(p) => getColor(p.theme, ['white'], ['nightBlack'])};
  width: 100%;
  height: 60px;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  bottom: 0;
`

const ModalContent = styled.div``

const ModalStyle = {
  content: {
    backgroundColor: getColor({ nightMode: false }, ['primaryGreen'], ['primaryGreen']),
    alignContent: 'space-around',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    bottom: 'auto',
    minHeight: '25%',
  },
}

const ModalText = styled.div`
  color: ${(p) => getColor(p.theme, ['white'], ['white'])};
  font-size: ${theme.font.normal};
  padding: 9px 0px 45px;
  text-align: center;
`

const Button = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ButtonText = styled.div`
  color: ${(p) => p.color};
  padding-left: 8px;
`

type Props = {
  t: TFunction
  confirmCheckOrInspection: ConfirmCheckOrInspection
  modalOpen?: boolean
  route: Route
  queryDate: string
  modal: ModalType
  openModal: (modal: ModalType) => void
}

const getModalMessage = (modalText: string, t: TFunction, action?: string) => {
  if (action === 'remove') {
    return `${t('removeConfirmation')} ${modalText}`
  }
  if (action === 'update') {
    return `${t('updateConfirmation')} ${modalText}`
  }
  return `${t('modalConfirmation')} ${modalText}`
}

class ConfirmModal extends Component<Props> {
  componentDidMount() {
    Modal.setAppElement('body')
  }

  render() {
    const { modal, openModal, confirmCheckOrInspection, route, queryDate, t }: Props = this.props
    return (
      <Modal isOpen={modal.open} style={ModalStyle}>
        <ModalContent>
          <RoundBackground size="66px">
            {modal.confirmCompleted ? (
              <SuccessPlain iconSize="large" fillColor="red" />
            ) : (
              <Exclamation inspectionRequired={!modal.confirmCompleted}>!</Exclamation>
            )}
          </RoundBackground>
        </ModalContent>
        <ModalText>{getModalMessage(modal.message, t, modal.action)}</ModalText>
        <Row>
          <Button
            onClick={() =>
              confirmCheckOrInspection(
                route.trainNumber,
                route.departureDate,
                queryDate,
                route.origStation.countryCode,
                route.origStation.stationAbbreviation,
                modal.sourceSystem,
                modal.action === 'remove' ? '' : moment().toISOString(),
                modal.confirmType,
                modal.locomotives,
                modal.secondAction
              )
            }
          >
            <SuccessPlain fillColor="green" />
            <ButtonText color="green">{t('confirm')}</ButtonText>
          </Button>
          <Button onClick={() => openModal({ open: false, message: '' })}>
            <Close iconSize="smaller" fillColor="red" />
            <ButtonText color="red">{t('discard')}</ButtonText>
          </Button>
        </Row>
      </Modal>
    )
  }
}

export default withTranslation()(ConfirmModal)
