import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import moment from 'moment'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { signInToShift } from '../../actions/api'
import { nowSelector, shiftCanSignIn, shiftSignInStatus, showSignIn } from '../../Selectors'
import { color, getColor, px, theme } from '../../Theme'
import { Action, AppState, Dispatch, Shift } from '../../types'
import { FullProps } from '../../types/App'
import LoadingIndicator from '../LoadingIndicator'

const Row = styled.div`
  ${theme.layout.flexRow};
  justify-content: flex-start;
`

const Success = styled.span`
  color: ${color('grayDark')};
`

const SignInButton = styled.button<FullProps>`
  ${(p) =>
    p.full ? theme.text('smaller', 'decorative', 'bold') : theme.text('normal', 'content', 'bold')};
  ${(p) => theme.spacing.sides(p.full ? 'large' : 'small')};
  ${(p) => theme.spacing.ends(p.full ? 'small' : 'tiny')};
  background: ${color('primaryBlue')};
  border: none;
  border-radius: ${theme.borderRadius.button};
  color: ${color('white')};
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  text-transform: ${(p) => (p.full ? 'uppercase' : 'none')};
  letter-spacing: ${(p) => (p.full ? px(0.8) : 0)};
  width: ${(p) => (p.full ? '100%' : 'auto')};
  height: 34px;
  display: flex;
  flex-direction: row;
  &[disabled] {
    color: ${(p) => getColor(p.theme, ['white'], ['grayDark'])};
    background: ${(p) => getColor(p.theme, ['shallowBlue'], ['nightGray'])};
  }
`

type ActionsProps = {
  t: TFunction
  signInPossible: boolean
  onClick: () => void
  actionText: string
  shift: Shift
}

function Actions({ t, signInPossible, onClick, actionText, shift }: ActionsProps) {
  if (signInPossible && !shift.loading) {
    return (
      <SignInButton disabled={false} onClick={onClick}>
        {actionText}
      </SignInButton>
    )
  }

  return <SignInButton disabled={true}>{t('signIn')}</SignInButton>
}

type Props = {
  t: TFunction
  isMobile: boolean
  signedIn: boolean
  loading: boolean
  signInPossible: boolean
  showSignInButton: boolean
  shift: Shift
  stateText: string
  actionText: string
  onClick: () => void
}

function ShiftSignIn({
  t,
  shift,
  loading,
  signedIn,
  signInPossible,
  showSignInButton,
  actionText,
  isMobile,
  onClick,
}: Props) {
  if (!showSignInButton) {
    return null
  }

  if (loading || shift.loading) {
    return (
      <SignInButton disabled={true}>
        <LoadingIndicator size="small" padded={false} />
      </SignInButton>
    )
  }

  if (signedIn) {
    return <Success>{t('youAreSignedIn')}</Success>
  }

  return (
    <Row>
      <Actions
        t={t}
        shift={shift}
        signInPossible={signInPossible}
        onClick={onClick}
        actionText={actionText}
      />
    </Row>
  )
}

const mapStateToProps = (
  state: AppState,
  {
    t,
    shift,
  }: {
    t: TFunction
    shift: Shift
  }
) => {
  const now = nowSelector(state)
  const signInStatus = shiftSignInStatus(state)(shift.id)
  const signedIn = signInStatus.state === 'signed-in'
  const hadError = !!shift.error
  const loading = signInStatus.loading
  const shiftStartMoment = moment(shift.scheduleStartTime || shift.startDateTime)
  const signInPossible = hadError || shiftCanSignIn(now, shift, signInStatus, shiftStartMoment)
  const actionText = hadError ? t('retry') : t('signIn')
  const stateText = hadError
    ? t(shift.error)
    : signInPossible
    ? t('signInIsOpen')
    : t('signInNotYetOpen')
  const showSignInButton = showSignIn(now, shift)

  return {
    signedIn,
    loading,
    signInPossible,
    actionText,
    stateText,
    showSignInButton,
  }
}
const mapDispatchToProps = (
  dispatch: Dispatch,
  {
    shift,
  }: {
    shift: Shift
  }
) => ({
  onClick: () => dispatch(signInToShift(shift) as unknown as Action),
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ShiftSignIn))
