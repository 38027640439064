import {
  CLEAR_SHIFT_DETAILS,
  RELOAD_SHIFT,
  SHIFT_LOAD_ERROR,
  START_LOADING_SHIFT,
  UPDATE_DUTY_EVALUATION,
  UPDATE_SHIFT,
} from '../actions/shiftDetails'
import { ShiftDetailsAction } from '../actions/shiftDetails'
import { ShiftDetailsState } from '../types'
import { DutyEvaluation, Shift } from '../types/Input'
import { replaceWithId } from './Common'

export const initialState = {
  loading: false,
  error: '',
  byId: {},
}

const withUpdatedDutyEvaluations = (
  shift: Shift,
  dutyEvaluations: Array<DutyEvaluation>
): Shift => {
  const shiftDate = shift.startDateTime.split('T')[0]
  const evaluation = dutyEvaluations.find(
    (e) => e.shiftId === shift.shiftId && e.shiftDate === shiftDate
  )
  return evaluation
    ? { ...shift, dutyEvaluationPercentage: evaluation.evaluationPercentage }
    : shift
}

const shiftDetailsReducer = (
  state: ShiftDetailsState = initialState,
  action: ShiftDetailsAction
) => {
  let byId: Record<string, Shift>

  switch (action.type) {
    case CLEAR_SHIFT_DETAILS:
      return initialState

    case START_LOADING_SHIFT:
      return {
        ...state,
        loading: true,
        error: '',
      }

    case RELOAD_SHIFT:
      return {
        ...state,
        loading: true,
        error: '',
      }

    case UPDATE_SHIFT:
      return {
        ...state,
        loading: false,
        byId: replaceWithId(state.byId, action.payload),
      }

    case UPDATE_DUTY_EVALUATION:
      byId = {}
      for (const id in state.byId) {
        byId[id] = withUpdatedDutyEvaluations(state.byId[id], action.payload)
      }
      return { ...state, byId }

    case SHIFT_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    default:
      return state
  }
}

export default shiftDetailsReducer
