import {
  CLEAR_TOWING_FORM,
  START_FETCHING_TOWING_FORM,
  START_FETCHING_TOWING_STEP,
  TOWING_FORM_ERROR,
  UPDATE_TOWING_FORM,
  UPDATE_TOWING_STEPS,
} from '../actions/towingForm'
import { TowingFormAction } from '../actions/towingForm'
import { TowingFormContentState } from '../types'
import { replaceManyWithId } from './Common'

export const initialState = {
  // TODO: consider splitting extra towingSteps into a separate reducer
  loading: false,
  error: '',
  stepsById: {},
  towingForm: {},
}

const towingFormReducer = (
  state: TowingFormContentState = initialState,
  action: TowingFormAction
): TowingFormContentState => {
  switch (action.type) {
    case START_FETCHING_TOWING_STEP:
      return {
        ...state,
        loading: true,
        error: '',
      }

    case UPDATE_TOWING_STEPS:
      return {
        ...state,
        loading: false,
        error: '',
        stepsById: replaceManyWithId(state.stepsById, action.payload),
      }

    case START_FETCHING_TOWING_FORM:
      return {
        ...state,
        loading: true,
        error: '',
        towingForm: {},
      }

    case UPDATE_TOWING_FORM:
      return {
        ...state,
        loading: false,
        error: '',
        towingForm: action.payload,
      }

    case TOWING_FORM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }

    case CLEAR_TOWING_FORM:
      return initialState

    default:
      return state
  }
}

export default towingFormReducer
