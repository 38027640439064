const colors = {
  common: {
    black: '#333',
    white: '#FFF',
    kenttaBlue: '#2479B3',
    primaryGreen: '#57a50b',
  },
  grey: {
    50: '#FAFAFA',
    100: '#E2E2E2',
    200: '#D8D8D8',
    300: '#B0B4BB',
    400: '#90959D',
    500: '#404040',
    600: '#7F7F7F',
  },
  warning: {
    main: '#E85430',
  },
  info: {
    dark: '#1B50AF',
    main: '#0078D4',
    light: '#00ACF5',
    lighter: '#D5EEFA',
  },
  primary: {
    main: '#00A149',
    dark: '#008751',
    light: '#00B451',
    lighter: '#EDFFF8',
  },
  backgroundShades: {
    1: '#F2F2F2',
    2: '#E5E5E5',
    3: '#6E7176',
    4: '#121212',
    5: '#181818',
    6: '#272727',
    7: '#B0B4B8',
    8: '#404040',
    9: '#1D1C1C',
  },
  network: {
    background: '#f36a60',
    color: '#ffffff',
  },
} as const

export default colors
