import styled from '@emotion/styled'
import React, { MouseEventHandler } from 'react'

import { getColor, theme } from '../Theme'
import { SelectedProps } from '../types/App'
import Checkbox from './Checkbox'

const Row = styled.div<SelectedProps>`
  ${theme.layout.flexRow};
  ${theme.text('large')};
  ${theme.spacing.all('large')};
  cursor: pointer;
  background: ${(p) =>
    getColor(
      p.theme,
      [p.selected ? 'primaryBlue' : 'white'],
      [p.selected ? 'primaryBlue' : 'nightBlack']
    )};
  color: ${(p) =>
    getColor(p.theme, [p.selected ? 'white' : 'black'], [p.selected ? 'white' : 'grayPink'])};
  -webkit-touch-callout: none;
  user-select: none;
`

type Props = {
  title: string
  selected: boolean
  disabled: boolean
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => unknown
}

/* eslint-disable @typescript-eslint/no-empty-function */
const noop = () => {}

const SelectableRow = ({ disabled, onClick, selected, title }: Props) => (
  <Row
    selected={selected}
    onClick={disabled ? noop : (onClick as unknown as MouseEventHandler<HTMLDivElement>)}
  >
    <Checkbox selected={selected} disabled={disabled} onClick={noop} />
    {title}
  </Row>
)

export default SelectableRow
