import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import {
  resetError,
  resetSelectedCause,
  setCauseGroup,
  setSelectedFirstLevelCause,
  setSelectedSecondLevelCause,
} from '../actions/amendment'
import {
  fetchReasonCodesFromAPI,
  fetchTrainPunctuality,
  sendDeviationAmendment,
} from '../actions/api'
import { CauseSelector } from '../components/amendment/CauseSelector'
import {
  AMEND_TYPE_AMEND,
  AMEND_TYPE_CHALLENGE,
  BackNavigationButton,
  CauseDisplay,
  CauseSelectionHeader,
  STATE_COMPLETE,
  STATE_INIT,
  STATE_SELECT_GROUP,
  STATE_SELECT_LEVEL1,
  STATE_SELECT_LEVEL2,
  TYPE_ARRIVAL,
} from '../components/amendment/Common'
import CompleteAmendment from '../components/amendment/CompleteAmendment'
import Overview from '../components/amendment/Overview'
import HeaderTitle from '../components/header/HeaderTitle'
import LoadingIndicator from '../components/LoadingIndicator'
import { Container, Content, PageHeader } from '../components/page/PageComponents'
import ArrowLeft from '../icons/ArrowLeft'
import moment from '../lib/moment-fi'
import {
  CAUSE_FIRST_LEVEL,
  CAUSE_SECOND_LEVEL,
  flattenCauses,
  getPunctualityKey,
  nightModeSelector,
  selectPunctuality,
} from '../Selectors'
import { getColor } from '../Theme'
import {
  Action,
  AppState,
  Dispatch,
  FirstLevelCause,
  PunctualityState,
  SecondLevelCause,
} from '../types'
import { TrainParams } from '../types/App'
import {
  CauseGroup,
  Causes,
  FlattenedCause,
  FlattenedCauses,
  TimetableRow,
  TrainPunctuality,
} from '../types/Input'
import { AmendmentData, AmendmentDataInput, SendStatus } from '../types/States'

const BackIcon = styled.div`
  align-content: start;
  display: flex;
  padding-left: 8px;
`

type GroupSelectorProps = {
  nightMode: boolean
  causeTree: Causes
  selectGroup: (groupKey: string) => void
}

type FirstLevelSelectorProps = {
  nightMode: boolean
  causeTree: Causes
  selectedGroupKey?: string | null
  selectFirstLevel: (firstLevelKey: string, hasSecondLevel: boolean) => void
  returnToPreviousDisplay: () => void
  forbiddenKey?: string
}

type SecondLevelSelectorProps = {
  nightMode: boolean
  causeTree: Causes
  selectedGroupKey?: string | null
  selectedFirstLevelKey: string
  selectSecondLevel: (secondLevelKey: string) => void
  returnToPreviousDisplay: () => void
  forbiddenKey?: string
}

type Params = {
  trainNumber: string
  runningDate: string
  ocpCode: string
  depOrArr: string
  causeCode: string
}

type PropsIn = {
  t: TFunction
  depOcp: string
  arrOcp: string
  nightMode: boolean
  fetchTrainPunctuality: (array: TrainParams[]) => void
  runningDate: string
  trainNumber: string
  causeCode: string
  scheduledTime: string
  actualTime: string
  trainType: string
  isArrival: boolean
  fetchCauses: () => void
  causes: FlattenedCauses
  currentCause: FlattenedCause
  currentCauseGroup: FlattenedCause
  currentFirstLevelCause: FlattenedCause
  currentSecondLevelCause?: FlattenedCause
  causeTree: Causes
  depOrArr: string
  delayMinutes: number
  loading: boolean
  sendAmendment: (data: AmendmentData) => void
  sendStatus: SendStatus
  amendmentError: string
  amendmentData: AmendmentDataInput
  selectedCauseGroup?: string | null
  selectedFirstLevelCause?: string | null
  selectedSecondLevelCause?: string | null
  setCauseGroup: (currentCauseGroupCode?: string | null) => void
  setFirstLevel: (firstLevelKey?: string | null) => void
  setSecondLevel: (secondLevelKey: string | null) => void
  resetSelectedCause: () => void
  resetError: () => void
  forbiddenKey?: string
  amendable?: boolean
  askTrainNumber?: boolean
  originalDescription: string | null
}

const GroupSelector = ({ nightMode, causeTree, selectGroup }: GroupSelectorProps) => {
  return (
    <div>
      {Object.keys(causeTree).map((groupKey: string) => {
        const group: CauseGroup = causeTree[groupKey]
        if (!group) return null
        return (
          <CauseSelector
            key={groupKey}
            nightMode={nightMode}
            code={groupKey}
            description={group.description}
            onClick={() => selectGroup(groupKey)}
          />
        )
      })}
    </div>
  )
}

const FirstLevelSelector = ({
  nightMode,
  causeTree,
  selectedGroupKey,
  selectFirstLevel,
  returnToPreviousDisplay,
  forbiddenKey,
}: FirstLevelSelectorProps) => {
  const selectedGroup = causeTree[selectedGroupKey || '']
  if (!selectedGroup) return null
  return (
    <div>
      <CauseSelectionHeader>
        <CauseDisplay onClick={returnToPreviousDisplay}>
          <BackNavigationButton>
            <ArrowLeft
              iconSize="normal"
              stroke={getColor({ nightMode }, ['primaryGreen'], ['primaryGreen'])}
            />
          </BackNavigationButton>
          {selectedGroupKey} - {selectedGroup.description}
        </CauseDisplay>
      </CauseSelectionHeader>
      {Object.keys(selectedGroup.firstLevelCodes).map((key: string) => {
        const entry: FirstLevelCause | null = selectedGroup.firstLevelCodes[key]
        if (!entry || key === forbiddenKey) return null
        return (
          <CauseSelector
            key={key}
            code={key}
            description={entry.description || ''}
            onClick={() => selectFirstLevel(key, !!entry.secondLevelCodes)}
            nightMode={nightMode}
          />
        )
      })}
    </div>
  )
}

const SecondLevelSelector = ({
  nightMode,
  causeTree,
  selectedGroupKey,
  selectedFirstLevelKey,
  selectSecondLevel,
  returnToPreviousDisplay,
  forbiddenKey,
}: SecondLevelSelectorProps) => {
  const selectedGroup = causeTree[selectedGroupKey || '']
  const selectedFirstLevel: FirstLevelCause | null =
    selectedGroup.firstLevelCodes[selectedFirstLevelKey]
  if (!selectedFirstLevel) return null
  if (!selectedFirstLevel.secondLevelCodes) return null
  return (
    <div>
      <CauseSelectionHeader>
        <CauseDisplay notSelectable>
          {selectedGroupKey} - {selectedGroup.description}
        </CauseDisplay>
        <CauseDisplay onClick={returnToPreviousDisplay}>
          <BackNavigationButton>
            <ArrowLeft
              iconSize="normal"
              stroke={getColor({ nightMode }, ['primaryGreen'], ['primaryGreen'])}
            />
          </BackNavigationButton>
          {selectedFirstLevelKey} - {selectedFirstLevel.description}
        </CauseDisplay>
      </CauseSelectionHeader>
      {Object.keys(selectedFirstLevel.secondLevelCodes).map((key: string) => {
        const entry: SecondLevelCause | null = selectedFirstLevel.secondLevelCodes[key]
        if (!entry || forbiddenKey === key) return null
        return (
          <CauseSelector
            key={key}
            code={key}
            description={entry.description}
            onClick={() => selectSecondLevel(key)}
            nightMode={nightMode}
          />
        )
      })}
    </div>
  )
}

const DeviationAmendmentPage = ({
  t,
  nightMode,
  depOcp,
  arrOcp,
  fetchTrainPunctuality,
  trainNumber,
  trainType,
  runningDate,
  causeCode,
  scheduledTime,
  actualTime,
  currentFirstLevelCause,
  currentSecondLevelCause,
  currentCauseGroup,
  fetchCauses,
  causeTree,
  delayMinutes,
  depOrArr,
  loading,
  sendAmendment,
  sendStatus,
  amendmentError,
  amendmentData,
  selectedCauseGroup,
  selectedFirstLevelCause,
  selectedSecondLevelCause,
  setCauseGroup,
  setFirstLevel,
  setSecondLevel,
  resetSelectedCause,
  resetError,
  forbiddenKey,
  amendable,
  askTrainNumber,
  originalDescription,
}: PropsIn) => {
  useEffect(() => {
    fetchCauses()
  }, [fetchCauses])
  // TODO actual content

  useEffect(() => {
    resetSelectedCause()
  }, [resetSelectedCause])

  const [displayState, setDisplayState] = useState(STATE_INIT)

  const selectGroup = (groupKey: string | null) => {
    setCauseGroup(groupKey)
    setDisplayState(STATE_SELECT_LEVEL1)
  }

  const selectFirstLevel = (firstLevelKey: string | null | undefined, hasSecondLevel: boolean) => {
    setFirstLevel(firstLevelKey)
    setDisplayState(hasSecondLevel ? STATE_SELECT_LEVEL2 : STATE_COMPLETE)
  }

  const selectSecondLevel = (secondLevelKey: string | null) => {
    setSecondLevel(secondLevelKey)
    setDisplayState(STATE_COMPLETE)
  }

  const returnToPreviousDisplay = () => {
    switch (displayState) {
      case STATE_INIT:
        window.history.back()
        break
      case STATE_SELECT_GROUP:
        setDisplayState(STATE_INIT)
        break
      case STATE_SELECT_LEVEL1:
        setCauseGroup(null)
        setDisplayState(STATE_SELECT_GROUP)
        break
      case STATE_SELECT_LEVEL2:
        setFirstLevel(null)
        setDisplayState(STATE_SELECT_LEVEL1)
        break
      case STATE_COMPLETE:
        setSecondLevel(null)
        if (
          causeTree[selectedCauseGroup || ''].firstLevelCodes[selectedFirstLevelCause || '']
            .secondLevelCodes
        ) {
          setDisplayState(STATE_SELECT_LEVEL2)
        } else {
          setFirstLevel(null)
          setDisplayState(STATE_SELECT_LEVEL1)
        }
        break
      default:
        window.history.back()
    }
  }

  const resetDisplay = () => {
    setCauseGroup(null)
    setFirstLevel(null)
    setSecondLevel(null)
    setDisplayState(STATE_INIT)
  }

  const completeAmendment = (description: string | null, relatedTrainNumber: number) => {
    const amendGroup = selectedCauseGroup
    const amendedLevel1 = selectedFirstLevelCause
    const amendedLevel2 = selectedSecondLevelCause
    const data: AmendmentData = {
      ...amendmentData,
      description,
      relatedTrainNumber,
      amendedReasonCode: {
        groupCode: amendGroup,
        level1Code: amendedLevel1,
        level2Code: amendedLevel2,
      },
    }
    sendAmendment(data)
  }

  const returnToPunctualityPage = () => {
    fetchTrainPunctuality([{ trainDate: runningDate, trainNumber }])
    window.history.back()
    resetSelectedCause()
  }

  const title =
    depOrArr === TYPE_ARRIVAL
      ? `${trainType} ${trainNumber} ${depOcp} - ${arrOcp} ${causeCode}`
      : `${trainType} ${trainNumber} ${depOcp} ${causeCode}`

  return (
    <Container>
      <PageHeader onClick={() => window.history.back()} height="64px">
        <BackIcon>
          <ArrowLeft
            iconSize="normal"
            stroke={getColor({ nightMode }, ['primaryGreen'], ['primaryGreen'])}
          />
        </BackIcon>
        <HeaderTitle title={t('deviationAmendment.header')} subtitle={title} />
      </PageHeader>
      {loading ? (
        <LoadingIndicator size={'normal'} padded={true} />
      ) : (
        <Content>
          {displayState === STATE_INIT && (
            <Overview
              t={t}
              nightMode={nightMode}
              delayMinutes={delayMinutes}
              depOcp={depOcp}
              arrOcp={arrOcp}
              causeCode={causeCode}
              currentCauseGroup={currentCauseGroup}
              currentFirstLevelCause={currentFirstLevelCause}
              currentSecondLevelCause={currentSecondLevelCause}
              depOrArr={depOrArr}
              setDisplayState={setDisplayState}
              setCauseGroup={setCauseGroup}
              setFirstLevel={setFirstLevel}
              scheduledTime={scheduledTime}
              actualTime={actualTime}
              amendable={amendable}
            />
          )}
          {displayState === STATE_SELECT_GROUP && (
            <GroupSelector nightMode={nightMode} causeTree={causeTree} selectGroup={selectGroup} />
          )}
          {displayState === STATE_SELECT_LEVEL1 && (
            <FirstLevelSelector
              nightMode={nightMode}
              causeTree={causeTree}
              selectFirstLevel={selectFirstLevel}
              selectedGroupKey={selectedCauseGroup}
              returnToPreviousDisplay={returnToPreviousDisplay}
              forbiddenKey={forbiddenKey}
            />
          )}
          {displayState === STATE_SELECT_LEVEL2 && selectedFirstLevelCause && (
            <SecondLevelSelector
              nightMode={nightMode}
              selectSecondLevel={selectSecondLevel}
              causeTree={causeTree}
              selectedGroupKey={selectedCauseGroup}
              selectedFirstLevelKey={selectedFirstLevelCause}
              returnToPreviousDisplay={returnToPreviousDisplay}
              forbiddenKey={forbiddenKey}
            />
          )}
          {displayState === STATE_COMPLETE && (
            <CompleteAmendment
              t={t}
              causeTree={causeTree}
              selectedGroupKey={selectedCauseGroup}
              selectedFirstLevelKey={selectedFirstLevelCause}
              selectedSecondLevelKey={selectedSecondLevelCause}
              resetDisplay={resetDisplay}
              sendAmendment={completeAmendment}
              sendStatus={sendStatus}
              amendmentError={amendmentError}
              type={amendmentData.type}
              askTrainNumber={askTrainNumber}
              resetError={resetError}
              returnToPunctualityPage={returnToPunctualityPage}
              delayMinutes={delayMinutes}
              depOcp={depOcp}
              arrOcp={arrOcp}
              causeCode={causeCode}
              depOrArr={depOrArr}
              originalDescription={originalDescription}
            />
          )}
        </Content>
      )}
    </Container>
  )
}

const mapStateToProps = (state: AppState) => {
  // TODO move to selector function
  const punctuality: PunctualityState = state.punctuality
  const params = state.found.match.params
  const { trainNumber, runningDate, ocpCode, depOrArr } = params as Params
  const timetableData: TrainPunctuality = selectPunctuality(
    punctuality,
    getPunctualityKey(trainNumber, runningDate)
  )
  const timeTableRows: Array<TimetableRow> = timetableData ? timetableData.timeTableRows : []
  const rowIndex: number = timeTableRows.findIndex(
    (ttr: TimetableRow) => ttr.stationShortCode === ocpCode && ttr.type === depOrArr
  )

  const departureRowIndex = depOrArr === TYPE_ARRIVAL ? rowIndex - 1 : rowIndex

  const departureRow: TimetableRow | undefined =
    departureRowIndex !== -1 ? timeTableRows[departureRowIndex] : undefined
  const depOcp: string = departureRow ? departureRow.stationShortCode : ''
  const arrivalRow: TimetableRow | undefined =
    depOrArr === TYPE_ARRIVAL && rowIndex > 0 ? timeTableRows[rowIndex] : undefined
  const arrOcp: string = arrivalRow ? arrivalRow.stationShortCode : ''

  const rowForTime: TimetableRow | undefined = depOrArr === TYPE_ARRIVAL ? arrivalRow : departureRow

  const scheduledTime: string = rowForTime ? moment(rowForTime.scheduledTime).format('HH:mm') : ''
  const actualTime: string = rowForTime ? moment(rowForTime.actualTime).format('HH:mm') : ''

  const nextRow: TimetableRow | null =
    rowIndex < timeTableRows.length - 1 ? timeTableRows[rowIndex + 1] : null

  const causeCode: string =
    timeTableRows && rowIndex > -1
      ? timeTableRows[rowIndex].causes.length > 0
        ? timeTableRows[rowIndex].causes[0].categoryCode
        : ''
      : ''
  const flatCauses: FlattenedCauses = flattenCauses(state.causes.causes) || {}
  const currentCause: FlattenedCause = flatCauses[causeCode] || {}
  const currentCauseGroup: FlattenedCause = currentCause?.causeGroupKey
    ? flatCauses[currentCause.causeGroupKey]
    : {}
  const currentFirstLevelCause: FlattenedCause | undefined =
    currentCause.level === CAUSE_FIRST_LEVEL ? currentCause : flatCauses[currentCause.parent || '']
  const currentSecondLevelCause: FlattenedCause | undefined =
    currentCause.level === CAUSE_SECOND_LEVEL ? currentCause : undefined
  const delayMinutes: number = rowForTime ? rowForTime.differenceInMinutes : 0

  const {
    selectedGroup,
    selectedFirstLevelCause: firstLevelKey,
    selectedSecondLevelCause: secondLevelKey,
  } = state.amendment
  const selectedSecondLevelCause: string | null | undefined = secondLevelKey
  const selectedFirstLevelCause: string | null | undefined = firstLevelKey
  const selectedCauseGroup: string | null | undefined = selectedGroup
  const trainType: string = timetableData ? timetableData.trainType : ''
  const trainCategory: string = timetableData ? timetableData.trainCategory : ''

  let forbiddenKey = undefined
  if (currentSecondLevelCause) forbiddenKey = currentSecondLevelCause.code
  else if (currentFirstLevelCause && !currentFirstLevelCause.hasSubCodes)
    forbiddenKey = currentFirstLevelCause.code

  const amendable = currentCauseGroup.amendable
  const askTrainNumber: boolean | undefined =
    !!selectedSecondLevelCause && flatCauses[selectedSecondLevelCause].askRelatedTrainNumber

  const originalDescription: string | null =
    (currentFirstLevelCause ? currentFirstLevelCause.description : '') +
    ': ' +
    (currentSecondLevelCause ? currentSecondLevelCause.description : '')

  // TODO move to selector
  const amendmentData: AmendmentDataInput | null = {
    trainNumber: timetableData && timetableData.trainNumber,
    trainRunningTransactionType: depOrArr === TYPE_ARRIVAL ? 'T' : 'L',
    departureDate: moment(runningDate).format('YYYY-MM-DD'),
    stationShortCode: (rowForTime && rowForTime.stationShortCode) || '',
    previousStationCode: (departureRow && departureRow.stationShortCode) || undefined,
    nextStationCode: (nextRow && nextRow.stationShortCode) || undefined,
    originalReasonCode: {
      groupCode: currentCauseGroup.code,
      level1Code: currentFirstLevelCause ? currentFirstLevelCause.code : null,
      level2Code: currentSecondLevelCause ? currentSecondLevelCause.code : null,
    },
    delayMinutes,
    trainCategory,
    trainType,
    type:
      !currentCauseGroup.amendable ||
      (currentFirstLevelCause && selectedFirstLevelCause !== currentFirstLevelCause.code) ||
      (currentSecondLevelCause && selectedSecondLevelCause !== currentSecondLevelCause.code)
        ? AMEND_TYPE_CHALLENGE
        : AMEND_TYPE_AMEND,
  }

  return {
    depOcp,
    arrOcp,
    trainNumber,
    runningDate,
    trainType,
    causeCode,
    scheduledTime,
    actualTime,
    causes: flatCauses,
    currentSecondLevelCause,
    currentFirstLevelCause,
    currentCauseGroup,
    causeTree: state.causes.causes,
    depOrArr,
    delayMinutes,
    nightMode: nightModeSelector(state),
    loading: state.causes.loading,
    sendStatus: state.amendment.sendStatus,
    amendmentError: state.amendment.error,
    amendmentData,
    selectedCauseGroup,
    selectedFirstLevelCause,
    selectedSecondLevelCause,
    forbiddenKey,
    amendable,
    askTrainNumber,
    originalDescription,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  sendAmendment: (data: AmendmentData) =>
    dispatch(sendDeviationAmendment(data) as unknown as Action),
  fetchTrainPunctuality: (trainsArray: TrainParams[]) =>
    dispatch(fetchTrainPunctuality(trainsArray) as unknown as Action),
  fetchCauses: () => dispatch(fetchReasonCodesFromAPI() as unknown as Action),
  setCauseGroup: (group?: string | null) => dispatch(setCauseGroup(group)),
  setFirstLevel: (firstLevel?: string | null) => dispatch(setSelectedFirstLevelCause(firstLevel)),
  setSecondLevel: (secondLevel?: string | null) =>
    dispatch(setSelectedSecondLevelCause(secondLevel)),
  resetSelectedCause: () => dispatch(resetSelectedCause()),
  resetError: () => dispatch(resetError()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DeviationAmendmentPage))
