import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const Exclamation = ({ iconSize, fillColor }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          d="M 2.93 17.07 A 10 10 0 1 1 17.07 2.93 A 10 10 0 0 1 2.93 17.07 Z M 9 5 v 6 h 2 V 5 H 9 Z m 0 8 v 2 h 2 v -2 H 9 Z"
          fill={fillColor ?? 'currentColor'}
        />
      </g>
    </svg>
  )
}

export default Exclamation
