import { Contact, ContactLocation } from '../types'

export type UpdateContacts = {
  type: 'UPDATE_CONTACTS'
  payload: Array<Contact>
}
export const UPDATE_CONTACTS = 'UPDATE_CONTACTS'
export const updateContacts = (contacts: Array<Contact>): UpdateContacts => ({
  type: UPDATE_CONTACTS,
  payload: contacts,
})

export type UpdateContactLocation = {
  type: 'UPDATE_CONTACT_LOCATION'
  payload: ContactLocation
}
export const UPDATE_CONTACT_LOCATION = 'UPDATE_CONTACT_LOCATION'
export const updateContactLocation = (location: ContactLocation): UpdateContactLocation => ({
  type: UPDATE_CONTACT_LOCATION,
  payload: location,
})

export type ContactsAction = UpdateContacts | UpdateContactLocation
