import { Button, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import fi from 'date-fns/locale/fi'
import { TFunction } from 'i18next'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import {
  disableVirtualTime,
  fastForwardVirtualTime,
  normalSpeedVirtualTime,
  updateOffsetVirtualTime,
} from '../../actions/system'
import moment from '../../lib/moment-fi'
import { formatDuration } from '../../lib/time'
import { nowSelector } from '../../Selectors'
import { AppState, Moment } from '../../types'
import DevSection from './DevSection'
import InputRow, { inputField } from './InputRow'

type Props = {
  t: TFunction
  real: number
  now: Moment
  offset: number
  offsetString: string
  multiplier: number
  onReality: () => void
  onNormalSpeed: () => void
  onFastForward: () => void
  onOffset: (arg0: number) => void
}

const VirtualTime = ({
  t,
  offset,
  offsetString,
  now,
  real,
  multiplier,
  onReality,
  onNormalSpeed,
  onFastForward,
  onOffset,
}: Props) => {
  return (
    <DevSection>
      <Typography variant="h3" sx={{ color: 'text.primary' }}>
        {t('virtualTime')}
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.primary' }}>
        {t('realTime')}: {moment(real).format('LLLL')}
      </Typography>
      {offset || multiplier ? (
        <Button variant="submitButtonFullWidth" onClick={onReality}>
          <Typography variant="button">{t('useRealtime')}</Typography>
        </Button>
      ) : undefined}
      <InputRow>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fi}>
          <Typography variant="body2" sx={{ color: 'text.primary' }}>
            {t('currentTime')}:{' '}
          </Typography>
          <DatePicker
            sx={{ ...inputField, maxWidth: '30%' }}
            value={new Date(real + offset)}
            onChange={(newValue) => {
              const target = moment(newValue)
              const offset = moment(target).diff(real)
              onOffset(offset)
            }}
          />
          <TimePicker
            sx={{ ...inputField, maxWidth: '30%' }}
            value={new Date(real + offset)}
            onChange={(newValue) => {
              if (newValue) {
                const [hours, minutes] = newValue.toString().split(':')
                const target = now
                  .clone()
                  .startOf('day')
                  .add(hours, 'hours')
                  .add(minutes, 'minutes')
                const offset = moment(target).diff(real)
                onOffset(offset)
              }
            }}
          />
        </LocalizationProvider>
      </InputRow>
      {offsetString ? (
        <Typography variant="body2" sx={{ color: 'text.primary' }}>
          {t('differenceToRealtime')} {offsetString}
        </Typography>
      ) : undefined}
      <Typography variant="body2" sx={{ color: 'text.primary' }}>
        {t('timeAdvances')} {multiplier ? t('fastForward', { multiplier }) : t('normalSpeed')}
      </Typography>
      <Button variant="submitButtonFullWidth" onClick={onFastForward}>
        <Typography variant="button">{t('increaseSpeed')}</Typography>
      </Button>
      {multiplier > 1 ? (
        <Button variant="submitButtonFullWidth" onClick={onNormalSpeed}>
          <Typography variant="button">{t('keepOffsetButSlowDown')}</Typography>
        </Button>
      ) : undefined}
    </DevSection>
  )
}

const mapStateToProps = (state: AppState) => ({
  real: state.system.now,
  now: nowSelector(state),
  offset: state.system.virtualTime.offset,
  offsetString: formatDuration(state.system.virtualTime.offset),
  multiplier: state.system.virtualTime.enabledAt > 0 ? state.system.virtualTime.multiplier : 0,
})

const mapDispatchToProps = {
  onReality: disableVirtualTime,
  onNormalSpeed: normalSpeedVirtualTime,
  onFastForward: fastForwardVirtualTime,
  onOffset: updateOffsetVirtualTime,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VirtualTime))
