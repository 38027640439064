import styled from '@emotion/styled'
import React from 'react'

import { color, theme } from '../../Theme'

interface ButtonStyleProps {
  disabled: boolean
  selected: boolean
  secondary: boolean
  padding: string
}

const ButtonStyle = styled.div<ButtonStyleProps>`
  background-color: ${(p) =>
    p.disabled
      ? color('grayDisabled')
      : p.selected
      ? color('primaryGreen')
      : p.secondary
      ? color('primaryBlueDisabled')
      : color('primaryBlue')};
  border-radius: 3px;
  display: flex;
  align-items: center;
  min-height: 40px;
  min-width: 130px;
  max-width: 230px;
  justify-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: ${theme.font.mediumLarge};
  line-height: 19px;
  text-align: center;
  &[disabled] {
    background-color: ${color('grayDisabled')};
  }
  flex: 1;
  padding-left: ${(p) => p.padding};
  padding-right: ${(p) => p.padding};
  box-sizing: border-box;
`

const ButtonText = styled.div`
  color: white;
`

type Props = {
  onClick: (syntheticEvent: React.SyntheticEvent) => void
  buttonText: string
  selected?: boolean
  disabled: boolean
  secondary?: boolean
  padding?: string
}

const TowingSelectionButton = ({
  onClick,
  buttonText,
  selected,
  secondary,
  disabled,
  padding,
}: Props) => (
  <ButtonStyle
    disabled={disabled}
    selected={selected}
    secondary={secondary}
    padding={padding}
    onClick={(e) => {
      if (!disabled) return onClick(e)
    }}
  >
    <ButtonText>{buttonText}</ButtonText>
  </ButtonStyle>
)

export default TowingSelectionButton
