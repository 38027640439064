import { CHANGE_MAIN_DAY, TOGGLE_MAIN_HEADER } from '../actions/mainpage'
import { MainPageAction } from '../actions/mainpage'
import { MainPageState } from '../types'

export const initialState = {
  headerOpen: true,
  dayIndex: 0,
}

const mainPageReducer = (
  state: MainPageState = initialState,
  action: MainPageAction
): MainPageState => {
  let dayIndex: number

  switch (action.type) {
    case CHANGE_MAIN_DAY:
      dayIndex = Math.min(1, Math.max(0, parseInt(action.payload.index, 10)))
      if (dayIndex === state.dayIndex) {
        return state
      }
      return { ...state, dayIndex }

    case TOGGLE_MAIN_HEADER:
      return { ...state, headerOpen: !state.headerOpen }

    default:
      return state
  }
}

export default mainPageReducer
