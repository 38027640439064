import styled from '@emotion/styled'

import { theme } from '../Theme'

const Card = styled.div<{ nightMode?: boolean }>`
  ${(p) => (p.nightMode ? theme.effects.shadowNight : theme.effects.shadow)};
  margin-bottom: ${theme.spacing.sizes.small};
  border-radius: ${theme.borderRadius.button};
`

export default Card
