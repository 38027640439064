import ErrorIcon from '@mui/icons-material/Error'
import { Stack, Typography } from '@mui/material'
import React from 'react'

export const FormErrorHelperText = ({ error }: { error: string }): JSX.Element => (
  <Stack direction="row" gap={1} alignItems="center">
    <ErrorIcon color="error" sx={{ fontSize: '1.4em' }} />
    <Typography color="error">{error}</Typography>
  </Stack>
)
