import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  AffectToUsage,
  AssetIdentifier,
  CreateDefectFormData,
  CreateDefectPayload,
  CreateDefectResponse,
  DefectGroup,
  DefectTree,
  ItemData,
  ItemOptions,
  OptionTypes,
  Position,
  Restriction,
} from '../../components/create-defect/types'
import { apiGET, apiPOST } from '../../lib/api'
import { createFetchAction } from '../defect-common'

interface CreateDefectError {
  message: string
}

const formatPayload = (payload: CreateDefectFormData): CreateDefectPayload => {
  const installationItem =
    payload.type === 'equipment' ? payload.vehicleType : payload.installationItem
  const serialNumber =
    payload.type === 'equipment' ? payload.vehicleSerialNumber : payload.installationSerialNumber
  return {
    affectToUsage: payload.affectToUsage,
    comment: payload.comment,
    criticality: +payload.criticality,
    defectGroup: payload.defectGroup,
    digitalAssetUuids: payload.digitalAssetUuids,
    installationGroup: payload.installationGroup,
    installationItem: `         ${installationItem}`, // spaces are intentional
    installationSerialNumber: serialNumber,
    issuedTime: payload.issuedTime.toISOString().split('.')[0] + 'Z',
    reportedDefectCode: payload.defectCode,
    restrictionCode: payload.restrictionCode,
  }
}

const createDefect = (payload: CreateDefectPayload): Promise<CreateDefectResponse> =>
  apiPOST('defect', payload)

export const createDefectAction = createAsyncThunk<
  { response: CreateDefectResponse; request: CreateDefectPayload },
  CreateDefectFormData | undefined,
  { rejectValue: CreateDefectError }
>('defect/createDefect', async (payload, thunkApi) => {
  try {
    if (!payload) {
      return thunkApi.rejectWithValue({
        message: 'Failed to create defect',
      })
    }
    const formattedPayload = formatPayload(payload)
    const response = await createDefect(formattedPayload)

    if (!response.id) {
      return thunkApi.rejectWithValue({
        message: 'Failed to create defect',
      })
    }

    return { response, request: formattedPayload }
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: 'Failed to create defect',
    })
  }
})

const file2Base64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const raw = reader.result?.toString()
      if (raw) {
        const withoutMetaDataType = raw.split(',')[1]
        resolve(withoutMetaDataType)
      } else {
        reject(new Error('Failed to convert file to base64'))
      }
    }
    reader.onerror = (error) => reject(error)
  })
}

const postDigitalAsset = async (payload: File, uuid: string): Promise<string> => {
  const path = `digitalAssets?uuid=${uuid}&filename=${payload.name}`
  const base64 = await file2Base64(payload)
  if (base64 === null) {
    throw new Error('file2Base64 returned null')
  }

  return apiPOST(path, { base64 })
}

export const postDigitalAssetAction = createAsyncThunk<
  AssetIdentifier,
  File,
  { rejectValue: CreateDefectError }
>('defect/postDigitalAsset', async (payload: File, thunkApi) => {
  try {
    const id = crypto.randomUUID()
    const response = await postDigitalAsset(payload, id)

    if (response !== 'OK') {
      return thunkApi.rejectWithValue({
        message: response,
      })
    }

    return {
      id,
      filename: payload.name,
    }
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: 'Failed to send digital asset',
    })
  }
})

interface OptionFetch<T extends keyof OptionTypes> {
  id: T
  data: OptionTypes[T]
}

export type OptionsWithID<T extends keyof OptionTypes> = Promise<OptionFetch<T>>

const getItems = async ({
  filter,
  itemId,
}: {
  filter: string
  itemId: string
}): OptionsWithID<'items'> => {
  const response = await apiGET<{
    physicalBreakdowns: Omit<ItemData, 'uniqueId' | 'parentUniqueId'>[]
  }>(`physicalBreakdown?serialNumber=${filter}&itemId=${itemId}`)
  const dataArray = response.physicalBreakdowns.map(
    ({ itemId, serialNumber, parentItemId, parentSerialNumber, description }) => ({
      itemId,
      serialNumber,
      parentItemId,
      parentSerialNumber,
      description,
      uniqueId: `${itemId}|${serialNumber}`,
      parentUniqueId: `${parentItemId}|${parentSerialNumber}`,
      seperator: '|' as const,
    })
  )
  const data: ItemOptions = {}
  dataArray.forEach((item) => {
    data[item.uniqueId] = item
  })
  return { data, id: 'items' }
}

const getRestrictions = async (): OptionsWithID<'restriction'> => {
  const response = await apiGET<{ codes: Restriction[] }>('codes?group=limitationsofusage')
  const data = response.codes.map(({ id, description }) => ({
    id,
    description,
  }))
  return { data, id: 'restriction' }
}

const getDefects = async (itemId: string): OptionsWithID<'defectTrees'> => {
  const response = await apiGET<{ defectTrees: DefectTree[] }>(`defectTrees?itemId=${itemId}`)
  const data = response.defectTrees.map(
    ({
      productGroups,
      defectType,
      criticality,
      defectCode,
      mpgDescription,
      spgDescription,
      vrpg1Description,
      vrpg2Description,
    }) => ({
      productGroups,
      defectType,
      criticality,
      defectCode,
      mpgDescription,
      spgDescription,
      vrpg1Description,
      vrpg2Description,
    })
  )
  return { data, id: 'defectTrees' }
}

const getAffectToUsage = async (): OptionsWithID<'affectToUsage'> => {
  const response = await apiGET<{ codes: AffectToUsage[] }>('codes?group=affecttousage')
  const data = response.codes.map(({ id, description }) => ({
    id,
    description,
  }))
  return { data, id: 'affectToUsage' }
}

const DEFECT_GROUPS_OF_INTEREST = new Set(['IL', 'VA', 'VI'])

const getDefectGroups = async (): OptionsWithID<'defectGroups'> => {
  const response = await apiGET<{ codes: DefectGroup[] }>('codes?group=callgroups')
  const groupsOfInterest = response.codes.filter((group) => DEFECT_GROUPS_OF_INTEREST.has(group.id))
  const data = groupsOfInterest.map(({ id, description }) => ({
    id,
    description,
  }))
  return { data, id: 'defectGroups' }
}

const getPositions = async (): OptionsWithID<'positions'> => {
  const response = await apiGET<{ codes: Position[] }>('codes?group=positioninvehicle')
  const data = response.codes.map(({ id, description }) => ({
    id,
    description,
  }))
  return { data, id: 'positions' }
}

const fetchDefectGroupsAction = createFetchAction('defectGroups', getDefectGroups)
const fetchItemsAction = createFetchAction('items', getItems)
const fetchPositionsAction = createFetchAction('positions', getPositions)
const fetchDefectTreesAction = createFetchAction('defects', getDefects)
const fetchRestrictionsAction = createFetchAction('restriction', getRestrictions)
const fetchAffectToUsageAction = createFetchAction('affectToUsage', getAffectToUsage)

export const options = {
  fetchItemsAction,
  fetchDefectTreesAction,
  fetchRestrictionsAction,
  fetchAffectToUsageAction,
  fetchDefectGroupsAction,
  fetchPositionsAction,
}
