import { getColor } from '../Theme'
import { Criticality, Fault, Finding } from '../types'

export const findMostCritical = (findings: Array<Finding>) => {
  let criticality: Criticality | null = null
  if (findings.length > 0) {
    findings.forEach((finding) => {
      if (finding.faults.length === 0) return

      finding.faults.forEach((fault) => {
        let apparentCriticality = fault.criticality
        if (fault.usageCode && fault.criticality.level === '3') {
          apparentCriticality = {
            level: '4', // if prio3 with usagecode handle as prio4
            description: 'Rajoittaa käyttöä',
          }
        }

        if (
          criticality === null ||
          compareCriticality('' + apparentCriticality.level, '' + criticality.level)
        ) {
          criticality = {
            ...apparentCriticality,
            ...{ equipmentId: finding.equipment.equipmentId },
          }
        }
      })
    })
  }
  return criticality && criticality
}

export const findMostCriticalFaultLevel = (finding: Finding): string => {
  const faults = finding.faults
  let criticality: Criticality | null = null
  if (faults.length > 0) {
    faults.forEach((fault) => {
      let apparentCriticality = fault.criticality
      if (fault.usageCode && fault.criticality.level === '3') {
        apparentCriticality = {
          level: '4', // if prio3 with usagecode handle as prio4
          description: 'Rajoittaa käyttöä',
        }
      }

      if (
        criticality === null ||
        compareCriticality('' + apparentCriticality.level, '' + criticality.level)
      ) {
        criticality = { ...apparentCriticality, ...{ equipmentId: finding.equipment.equipmentId } }
      }
    })
  }
  return criticality === null ? '' : (criticality as Criticality).level
}

export const compareFaults = (a: Fault, b: Fault) => {
  //Criticality levels are from highest to lowest 1,2,4,3
  const aLevel =
    a.criticality.level === '3' ? parseInt(a.criticality.level) + 2 : parseInt(a.criticality.level)
  const bLevel =
    b.criticality.level === '3' ? parseInt(b.criticality.level) + 2 : parseInt(b.criticality.level)
  return aLevel - bLevel
}

//The criticality level priority is not linear. 1 is most critical but 3 is less critical than 4.
//returns true if the first parameter is of higher criticality than the second one
const compareCriticality = (criticalityLevel1: string | null, criticalityLevel2: string | null) => {
  const criticalities = '1243'
  if (!criticalityLevel1) {
    return false
  } else if (!criticalityLevel2) {
    return true
  }
  return criticalities.indexOf(criticalityLevel1) < criticalities.indexOf(criticalityLevel2)
}

export const chooseTextColor = (criticality: string | null, nightMode: boolean) => {
  if (!criticality) {
    return getColor({ nightMode }, ['white'], ['black'])
  }
  if (criticality === '1') {
    return getColor({ nightMode }, ['red'], ['red'])
  }
  if (criticality === '2') {
    return getColor({ nightMode }, ['red'], ['red'])
  }
  if (criticality === '3') {
    return getColor({ nightMode }, ['secondaryGreen'], ['secondaryGreen'])
  }
  if (criticality === '4') {
    return getColor({ nightMode }, ['primaryYellow'], ['primaryYellow'])
  }
}

export const chooseIconColor = (criticality: string | null, nightMode: boolean) => {
  if (!criticality) {
    return {
      fillColor: getColor({ nightMode }, ['white'], ['black']),
      strokeColor: getColor({ nightMode }, ['black'], ['white']),
    }
  }
  if (criticality === '1') {
    return {
      fillColor: getColor({ nightMode }, ['red'], ['black']),
      strokeColor: getColor({ nightMode }, ['black'], ['red']),
    }
  }
  if (criticality === '2') {
    return {
      fillColor: getColor({ nightMode }, ['primaryYellow'], ['black']),
      strokeColor: getColor({ nightMode }, ['black'], ['primaryYellow']),
    }
  }
  if (criticality === '3') {
    return {
      fillColor: getColor({ nightMode }, ['secondaryGreen'], ['black']),
      strokeColor: getColor({ nightMode }, ['black'], ['secondaryGreen']),
    }
  }
  if (criticality === '4') {
    return {
      fillColor: getColor({ nightMode }, ['secondaryGreen'], ['black']),
      strokeColor: getColor({ nightMode }, ['black'], ['secondaryGreen']),
    }
  }
  return {
    fillColor: getColor({ nightMode }, ['white'], ['black']),
    strokeColor: getColor({ nightMode }, ['black'], ['white']),
  }
}

export const chooseButtonColor = (
  criticality: string | null,
  nightMode: boolean,
  loading: boolean | null
) => {
  if (loading) return getColor({ nightMode }, ['transparent'], ['transparent'])
  if (criticality === null) {
    return getColor({ nightMode }, ['grayDark'], ['grayDark'])
  }
  if (criticality === '1') {
    return getColor({ nightMode }, ['red'], ['red'])
  }
  if (criticality === '2') {
    return getColor({ nightMode }, ['primaryYellow'], ['primaryYellow'])
  }
  if (criticality === '3') {
    return getColor({ nightMode }, ['primaryBlue'], ['primaryBlue'])
  }
  if (criticality === '4') {
    return getColor({ nightMode }, ['primaryYellow'], ['primaryYellow'])
  }
}
