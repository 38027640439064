import {
  PHONE_CONTACTS_ERROR,
  START_FETCHING_PHONE_CONTACTS,
  UPDATE_PHONE_CONTACTS,
} from '../actions/phoneContacts'
import { PhoneContactsAction } from '../actions/phoneContacts'
import { PhoneContactsState } from '../types'

export const initialState = {
  loading: false,
  error: '',
  phoneContacts: undefined,
}

const phoneContactsReducer = (
  state: PhoneContactsState = initialState,
  action: PhoneContactsAction
): PhoneContactsState => {
  switch (action.type) {
    case START_FETCHING_PHONE_CONTACTS:
      return {
        ...state,
        error: '',
        loading: true,
        phoneContacts: undefined,
      }

    case UPDATE_PHONE_CONTACTS:
      return {
        ...state,
        error: '',
        loading: false,
        phoneContacts: action.payload,
      }

    case PHONE_CONTACTS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default phoneContactsReducer
