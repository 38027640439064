export type MarkAsRead = {
  type: 'MARK_READ_FEEDBACK'
  payload: {
    id: string
    read: boolean
  }
}
export const MARK_READ_FEEDBACK = 'MARK_READ_FEEDBACK'
export const markAsRead = (id: string, read = true): MarkAsRead => ({
  type: MARK_READ_FEEDBACK,
  payload: { id, read },
})

export type ResponseReadAction = MarkAsRead
