import styled from '@emotion/styled'
import { Router } from 'found'
import { TFunction } from 'i18next'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { MOMENT_TIME_FORMAT } from '../constants'
import ArrowRight from '../icons/ArrowRight'
import moment from '../lib/moment-fi'
import { shiftURL } from '../lib/url'
import { color, getColor, LAYOUT_LEFT_EDGE, theme } from '../Theme'
import { AppState, Moment, Shift } from '../types'
import { FullProps } from '../types/App'

const Centered = styled.div<FullProps>`
  ${theme.spacing.all('tiny')};
  padding-left: ${LAYOUT_LEFT_EDGE + 10}px;
  padding-right: 22px;
  ${theme.layout.staticWidth(theme.maxWidths.content)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Text = styled.span<{ bold?: string | boolean }>`
  font-weight: ${(p) => (p.bold ? 'bold' : 'normal')};
  color: ${(p) => (p.bold ? getColor(p.theme, ['black'], ['grayDark']) : color('grayDark'))};
`

const LinkButton = styled.button<FullProps>`
  ${theme.text('normal', 'content', 'bold')};
  ${(p) => theme.spacing.sides(p.full ? 'large' : 'small')};
  ${(p) => theme.spacing.ends(p.full ? 'small' : 'tiny')};
  background: ${color('primaryBlue')};
  border: none;
  border-radius: ${theme.borderRadius.button};
  color: ${color('white')};
  cursor: pointer;
  width: 70px;
  height: 34px;
  &[disabled] {
    color: ${(p) => getColor(p.theme, ['white'], ['grayDark'])};
    background: ${(p) => getColor(p.theme, ['shallowBlue'], ['nightGray'])};
  }
`

type Props = {
  t: TFunction
  url: string
  full?: boolean
  startText?: string
  date?: string
  weekday?: string
  time?: string
  router: Router
}

const NextShift = ({ t, url, date, weekday, time, router }: Props) => (
  <Centered full>
    <Text>
      {t('nextShift')}{' '}
      <Text bold>
        {weekday} {date}
      </Text>{' '}
      {t('clockTime')} <Text bold="true">{time}</Text>
    </Text>
    <LinkButton onClick={() => router.push(url)}>
      <ArrowRight iconSize="normal" style={{ margin: 'auto' }} />
    </LinkButton>
  </Centered>
)

type PropsIn = {
  t: TFunction
  now: Moment
  shift: Shift
}

const mapStateToProps = (state: AppState, { t, shift }: PropsIn) => {
  const startsAt = shift.startDateTime
  const startMoment = moment(startsAt)
  const url = shiftURL(shift.id)
  const startText = t('startDateTime')
  const date = moment(startsAt).format('D.M.')
  const weekday = moment(startsAt).format('ddd')
  const time = startMoment.format(MOMENT_TIME_FORMAT)
  return {
    url,
    startText,
    date,
    weekday,
    time,
  }
}

export default withTranslation()(connect(mapStateToProps)(NextShift))
