import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const LongArrowLeft = ({ iconSize, fillColor }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"
          fill={fillColor ?? 'currentColor'}
        />
      </g>
    </svg>
  )
}

export default LongArrowLeft
