import { Backdrop, Box, Button, Card, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface ConfirmationDialogProps {
  submitHandler: () => void
  closeDialog: () => void
  dialogText: string
  primaryActionText?: string
  secondaryActionText?: string
}

const ConfirmationDialog = ({
  submitHandler,
  closeDialog,
  dialogText,
  primaryActionText,
  secondaryActionText,
}: ConfirmationDialogProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '32px',
          gap: '32px',
          width: '386px',
          height: '196px',
          bgcolor: 'background.secondary',
          borderRadius: '16px',
        }}
      >
        <Box textAlign="center">
          <Typography variant="h3">{dialogText}</Typography>
        </Box>
        <Stack direction="row" spacing={1}>
          <Button variant="submitButtonDialogSecondary" onClick={closeDialog}>
            <Typography variant="subtitle1">{secondaryActionText ?? t('action.cancel')}</Typography>
          </Button>
          <Button variant="submitButtonDialogPrimary" onClick={submitHandler}>
            <Typography variant="subtitle1">{primaryActionText ?? t('action.submit')}</Typography>
          </Button>
        </Stack>
      </Card>
    </Backdrop>
  )
}

export default ConfirmationDialog
