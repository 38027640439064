import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const SuccessPlain = ({ iconSize, fillColor }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          d="M1.78737546,12.4434783 C1.42661357,12.026087 0.756627219,11.973913 0.344327923,12.3391304 C-0.0679713722,12.7043478 -0.119508784,13.3826087 0.241253099,13.8 C0.241253099,13.8 0.292790511,13.8521739 0.292790511,13.8521739 L6.52881736,20.6869565 C6.734967,20.8956522 6.99265406,21 7.25034112,21 C7.50802818,21 7.76571524,20.8956522 7.97186489,20.6869565 L23.6907755,4.77391304 C24.1030748,4.35652174 24.1030748,3.73043478 23.6907755,3.31304348 C23.2784762,2.89565217 22.6600273,2.89565217 22.247728,3.31304348 L7.30187853,18.4434783 L1.78737546,12.4434783 Z"
          fill={fillColor ?? 'currentColor'}
        />
      </g>
    </svg>
  )
}

export default SuccessPlain
