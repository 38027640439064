import { color } from '../Theme'
import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const InfoFill = ({
  iconSize,
  fillColor = color('white'),
  stroke = color('grayDark'),
}: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <rect
          key="0"
          x="1"
          y="1"
          width="15.6754"
          height="16"
          rx="7.83772"
          fill={color('primaryBlue')}
          stroke={stroke}
        />
        <path
          key="1"
          d="M8.7404 13V7.5H9.5874V13H8.7404ZM8.7404 6.29V5.3H9.5874V6.29H8.7404Z"
          fill={fillColor}
        />
      </g>
    </svg>
  )
}

export default InfoFill
