import { TimetableParams } from '../types/Input'

export type UpdateTimetable = {
  type: 'UPDATE_TIMETABLE'
}
export const UPDATE_TIMETABLE = 'UPDATE_TIMETABLE'
export const updateTimetable = (): UpdateTimetable => ({
  type: UPDATE_TIMETABLE,
})

export type SetTimetableError = {
  type: 'TIMETABLE_ERROR'
  payload: string
}
export const TIMETABLE_ERROR = 'TIMETABLE_ERROR'
export const timetableError = (error: string): SetTimetableError => ({
  type: TIMETABLE_ERROR,
  payload: error,
})

export type StartFetchTimetable = {
  type: 'START_FETCHING_TIMETABLE'
}
export const START_FETCHING_TIMETABLE = 'START_FETCHING_TIMETABLE'
export const startFetchingTimetable = (): StartFetchTimetable => ({
  type: START_FETCHING_TIMETABLE,
})

type OpenModal = {
  type: 'OPEN_MODAL'
  payload: {
    isOpen: boolean
    usedTimetableParams: Array<TimetableParams>
  }
}
export const OPEN_MODAL = 'OPEN_MODAL'
export const openModal = (payload: {
  isOpen: boolean
  usedTimetableParams: Array<TimetableParams>
}): OpenModal => ({
  type: OPEN_MODAL,
  payload,
})

export type CheckInModal = {
  type: 'CHECK_IN_MODAL'
  payload: boolean
}
export const CHECK_IN_MODAL = 'CHECK_IN_MODAL'
export const checkInModal = (checked: boolean): CheckInModal => ({
  type: CHECK_IN_MODAL,
  payload: checked,
})

export type TimetableAction =
  | UpdateTimetable
  | SetTimetableError
  | StartFetchTimetable
  | OpenModal
  | CheckInModal
