import { Button, Input, styled, Typography } from '@mui/material'
import { TFunction } from 'i18next'
import { useState } from 'react'
import { withTranslation } from 'react-i18next'

import { sendPushNotification } from '../../lib/data'
import DevSection from './DevSection'
import InputRow, { inputField } from './InputRow'

const PushNotificationForm = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing('small'),
  paddingBottom: theme.spacing('small'),
  display: 'flex',
  flexDirection: 'column',
}))

type Props = {
  t: TFunction
}

const PushNotification = ({ t }: Props) => {
  const [recipient, setRecipient] = useState('')
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')

  return (
    <DevSection>
      <Typography variant="h3" sx={{ color: 'text.primary' }}>
        {t('pushMessage')}
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.primary' }}>
        {t('pushInstructions')}
      </Typography>
      <PushNotificationForm>
        <InputRow>
          <Typography variant="body2" sx={{ color: 'text.primary' }}>
            {t('recipient')}:{' '}
          </Typography>
          <Input
            sx={inputField}
            disableUnderline
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
          />
        </InputRow>
        <InputRow>
          <Typography variant="body2" sx={{ color: 'text.primary' }}>
            {t('title')}:{' '}
          </Typography>
          <Input
            sx={inputField}
            disableUnderline
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </InputRow>
        <InputRow>
          <Typography variant="body2" sx={{ color: 'text.primary' }}>
            {t('message')}:{' '}
          </Typography>
          <Input
            sx={inputField}
            disableUnderline
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </InputRow>
      </PushNotificationForm>
      <Button
        variant="submitButtonFullWidth"
        onClick={() => sendPushNotification({ recipient, title, body: message })}
      >
        <Typography variant="button">{t('send')}</Typography>
      </Button>
    </DevSection>
  )
}

export default withTranslation()(PushNotification)
