import 'react-datepicker/dist/react-datepicker.css'

import fi from 'date-fns/locale/fi'
import { TFunction } from 'i18next'
import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'

import LoadingIndicator from '../../components/LoadingIndicator'
import moment from '../../lib/moment-fi'
import { ActivateFunction } from '../../types/App'
import { PunctualityPersonnelState } from '../../types/States'
import DateContainer from '../search/DateContainer'
import SearchBox from '../search/SearchBox'
import SearchButton from '../search/SearchButton'
import SearchContainer from '../search/SearchContainer'
import SearchHeader from '../search/SearchHeader'
import SearchInput from '../search/SearchInput'
import SearchPageHeader from '../search/SearchPageHeader'

type Props = {
  activateDate: () => void
  activateTrainNumber: ActivateFunction
  changeDate: (date: Date | null) => void
  changeTrainNumber: (value: string) => void
  fetchPunctuality: () => void
  loading: boolean
  openCalendar: (arg0: boolean) => void
  setError: (arg0: string) => void
  state: PunctualityPersonnelState
  t: TFunction
}

const PunctualitySearch = ({
  fetchPunctuality,
  activateTrainNumber,
  changeTrainNumber,
  activateDate,
  openCalendar,
  state,
  changeDate,
  t,
  loading,
  setError,
}: Props) => {
  const { date, dateActive, trainNumber, trainNumberActive } = state

  const validate = () => {
    if (trainNumber !== '') return true
    return false
  }

  registerLocale('fi', fi)

  return (
    <div
      onKeyDown={
        validate()
          ? (ev) => {
              return ev.keyCode === 13 && fetchPunctuality()
            }
          : undefined
      }
    >
      <SearchPageHeader name="stopwatch" text={t('searchPunctuality')} />
      <SearchContainer>
        <SearchBox marginRight="8px" onClick={activateTrainNumber} active={trainNumberActive}>
          <SearchHeader>{t('trainNumber')}</SearchHeader>
          <SearchInput
            type="number"
            value={trainNumber}
            onChange={(event) => changeTrainNumber(event.target.value)}
          />
        </SearchBox>
        <SearchBox marginLeft="8px" onClick={activateDate} active={dateActive}>
          <SearchHeader>{t('startDay')}</SearchHeader>
          <SearchInput
            type="text"
            readOnly
            onClick={() => openCalendar(true)}
            value={date}
            onChange={() => null}
          />
        </SearchBox>
      </SearchContainer>
      {dateActive && (
        <DateContainer>
          <DatePicker
            inline
            locale="fi"
            readOnly
            selected={moment(date, 'DD.MM.YYYY').toDate()}
            onChange={(value) => changeDate(value)}
          />
        </DateContainer>
      )}
      {loading ? (
        <LoadingIndicator size="normal" padded={true} />
      ) : (
        <SearchButton
          t={t}
          validate={validate}
          setError={setError}
          errorKey="trainNumberMandatory"
          onClick={fetchPunctuality}
        />
      )}
    </div>
  )
}

export default PunctualitySearch
