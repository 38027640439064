import styled from '@emotion/styled'
import React from 'react'

import { px, theme } from '../Theme'
import { SizeNumberProps } from '../types/App'

interface CenteredProps extends SizeNumberProps {
  padded: boolean
}

const Loader = styled.img<SizeNumberProps>`
  max-height: ${(p) => px(p.size)};
  max-width: ${(p) => px(p.size)};
`

const Centered = styled.div<CenteredProps>`
  ${theme.layout.flexColumn};
  justify-content: center;
  height: ${(p) => px(p.size)};
  ${(p) => (p.padded ? theme.spacing.all('large') : '')};
`

type Props = {
  size?: 'small' | 'normal' | 'custom'
  padded?: boolean
  customSize?: number
}

const sizes = {
  small: 20,
  normal: 60,
}

function LoadingIndicator({ size = 'normal', padded = false, customSize = 60 }: Props) {
  const sizePx = size === 'custom' ? customSize : sizes[size]
  return (
    <Centered size={sizePx} padded={padded}>
      <Loader size={sizePx} src="/loader.gif" />
    </Centered>
  )
}

export default LoadingIndicator
