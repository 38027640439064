import styled from '@emotion/styled'
import React from 'react'

import Criticality from '../../icons/findings/Criticality'
import CriticalityNoRestriction from '../../icons/findings/CriticalityNoRestriction'
import { chooseIconColor } from '../../lib/findings'
import { color } from '../../Theme'
import { Status } from '../create-defect/types'

const OverflowIcon = styled.div`
  height: 36px;
`

interface RestrictionInfoProps {
  criticality: string
  usageCode: string | null
  nightMode: boolean
  status: Status
}

const getFillColor = (status: Status, criticality: string, nightMode: boolean) => {
  if (status === 'closed') {
    return color('grayLight')
  }
  return chooseIconColor(criticality, nightMode).fillColor
}

const getStrokeColor = (status: Status, criticality: string, nightMode: boolean) => {
  if (status === 'closed') {
    return color('nightBlack')
  }
  return chooseIconColor(criticality, nightMode).strokeColor
}

const getCriticalityIcon = (
  status: Status,
  criticality: string,
  usageCode: string | null,
  nightMode: boolean
) => {
  const fillColor = getFillColor(status, criticality, nightMode)
  const strokeColor = getStrokeColor(status, criticality, nightMode)

  if (
    (criticality && ['1', '2', '4'].includes(criticality)) ||
    (criticality === '3' && usageCode)
  ) {
    return <Criticality iconSize="huge" fillColor={fillColor} stroke={strokeColor} />
  } else {
    return <CriticalityNoRestriction iconSize="huge" fillColor={fillColor} stroke={strokeColor} />
  }
}

const RestrictionIcon = ({ criticality, usageCode, nightMode, status }: RestrictionInfoProps) => {
  return (
    <OverflowIcon>{getCriticalityIcon(status, criticality, usageCode, nightMode)}</OverflowIcon>
  )
}

export default RestrictionIcon
