import ClearIcon from '@mui/icons-material/Clear'
import {
  CircularProgress,
  FormHelperText,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import { TFunction } from 'i18next'
import React from 'react'
import { FocusEventHandler, MouseEventHandler, ReactNode, useMemo } from 'react'
import { DropzoneState } from 'react-dropzone'

import { ActionStatus } from '../../../types/Input'
import * as S from '../component.styles'
import { FormErrorHelperText } from '../FormErrorHelperText'
import { AssetIdentifiers } from '../types'
import UploadCloud from './UploadCloud'

interface UploadBoxProps {
  id: string
  error: boolean
  dropzoneProps: DropzoneState
  children: ReactNode
  onBlur: FocusEventHandler
}

export const UploadInstructions = ({
  required,
  instructions,
  fileWord,
  t,
}: {
  required: boolean
  instructions: string
  fileWord: string
  t: TFunction
}): JSX.Element => {
  return (
    <Stack
      spacing={2}
      width="80%"
      padding="1rem"
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        '& > svg': {
          flexShrink: 0,
        },
      }}
    >
      <UploadCloud size={42} />
      <Typography color="text.secondary">
        {instructions}{' '}
        <Link color="info.main" component="label">
          {fileWord}
        </Link>
        {required && ` (${t('field.requiredText').toLowerCase()})`}
      </Typography>
    </Stack>
  )
}

export const UploadBox = ({
  id,
  children,
  dropzoneProps,
  error,
  onBlur,
}: UploadBoxProps): JSX.Element => {
  const { getRootProps, getInputProps, isDragAccept, isDragReject } = dropzoneProps
  const variant = useMemo(() => {
    if (error) return 'error'
    if (isDragReject) return 'error'
    if (isDragAccept) return 'drag'
    return 'normal'
  }, [error, isDragAccept, isDragReject])
  return (
    <S.UploadBox {...getRootProps({ onBlur })} variant={variant} sx={{ overflowY: 'hidden' }}>
      <input id={id} {...getInputProps()} />
      {children}
    </S.UploadBox>
  )
}

const FileIcon = ({
  filename,
  onClick,
}: {
  filename: string
  onClick: MouseEventHandler<HTMLElement>
}): JSX.Element => (
  <S.AttachmentItem display="flex" flexShrink={0}>
    <Typography
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {filename}
    </Typography>
    <IconButton onClick={onClick} color="inherit">
      <ClearIcon sx={{ fontSize: '1rem' }} />
    </IconButton>
  </S.AttachmentItem>
)

export const FileList = ({
  files,
  status,
  deleteAttachment,
}: {
  files: AssetIdentifiers
  status?: ActionStatus
  deleteAttachment: (id: string) => MouseEventHandler<HTMLElement>
}): JSX.Element => {
  const fileIcons = Object.entries(files).map(([id, filename]) => (
    <FileIcon filename={filename} key={id} onClick={deleteAttachment(id)} />
  ))
  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      width="100%"
      alignItems="flex-start"
      sx={{ overflowY: 'auto' }}
    >
      {status === 'loading' ? <CircularProgress color="info" /> : fileIcons}
      {status === 'failed' && <Typography color="error">{'Failed to upload'}</Typography>}
    </Stack>
  )
}

export const FileErrors = ({
  errorsList,
}: {
  errorsList: { fileName: string; messages: string[] }[]
}): JSX.Element => {
  const errors = errorsList.map(({ fileName, messages }) => (
    <FormErrorHelperText key={fileName} error={messages.join(', ')} />
  ))
  return <FormHelperText component="div">{errors}</FormHelperText>
}

export const AddAnotherFileText = ({ children }: { children: ReactNode }): JSX.Element => (
  <Typography
    color="info.text"
    marginTop="auto"
    alignSelf="flex-start"
    sx={{
      cursor: 'pointer',
    }}
  >
    {children}
  </Typography>
)
