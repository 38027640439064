import type { Shift } from '../types'

export type ChangeShiftTaskState = {
  type: 'CHANGE_SHIFT_TASK_STATE'
  payload: { shiftId: string; taskIndex: number }
}
export const CHANGE_SHIFT_TASK_STATE = 'CHANGE_SHIFT_TASK_STATE'
export const changeShiftTaskState = (shiftId: string, taskIndex: number): ChangeShiftTaskState => {
  return {
    type: CHANGE_SHIFT_TASK_STATE,
    payload: { shiftId, taskIndex },
  }
}

export type OpenShiftTask = {
  type: 'OPEN_SHIFT_TASK'
  payload: { shiftId: string; taskIndex: number }
}
export const OPEN_SHIFT_TASK = 'OPEN_SHIFT_TASK'
export const openShiftTask = (shiftId: string, taskIndex: number): OpenShiftTask => {
  return {
    type: OPEN_SHIFT_TASK,
    payload: { shiftId, taskIndex },
  }
}

export type CloseShiftTasks = {
  type: 'CLOSE_SHIFT_TASKS'
  payload: { shiftId: string }
}
export const CLOSE_SHIFT_TASKS = 'CLOSE_SHIFT_TASKS'
export const closeShiftTasks = (shiftId: string): CloseShiftTasks => {
  return {
    type: CLOSE_SHIFT_TASKS,
    payload: { shiftId },
  }
}

export type MarkShiftTasksDone = {
  type: 'MARK_SHIFT_TASKS_DONE'
  payload: { shiftId: string; indexes: Array<number> }
}
export const MARK_SHIFT_TASKS_DONE = 'MARK_SHIFT_TASKS_DONE'
export const markShiftTasksDone = (shiftId: string, indexes: Array<number>): MarkShiftTasksDone => {
  return {
    type: MARK_SHIFT_TASKS_DONE,
    payload: { shiftId, indexes },
  }
}

export type MarkShiftTasksUndone = {
  type: 'MARK_SHIFT_TASKS_UNDONE'
  payload: { shiftId: string; indexes: Array<number> }
}
export const MARK_SHIFT_TASKS_UNDONE = 'MARK_SHIFT_TASKS_UNDONE'
export const markShiftTasksUndone = (
  shiftId: string,
  indexes: Array<number>
): MarkShiftTasksUndone => {
  return {
    type: MARK_SHIFT_TASKS_UNDONE,
    payload: { shiftId, indexes },
  }
}

export type OpenNextTask = {
  type: 'OPEN_NEXT_TASK'
  payload: {
    shiftId: string
    taskCount: number
  }
}
export const OPEN_NEXT_TASK = 'OPEN_NEXT_TASK'
export const openNextTask = (shift: Shift): OpenNextTask => ({
  type: OPEN_NEXT_TASK,
  payload: { shiftId: shift.id, taskCount: shift.tasks ? shift.tasks.length : 0 },
})

export type SelectShiftTask = {
  type: 'SELECT_SHIFT_TASK'
  payload: {
    shiftId: string
    taskIndex: number
    selected: boolean
  }
}
export const SELECT_SHIFT_TASK = 'SELECT_SHIFT_TASK'
export const selectShiftTask = (
  shiftId: string,
  taskIndex: number,
  selected: boolean
): SelectShiftTask => ({
  type: SELECT_SHIFT_TASK,
  payload: { shiftId, taskIndex, selected },
})

export type ClearShiftTaskSelection = {
  type: 'CLEAR_SHIFT_TASK_SELECTION'
  payload: {
    shiftId: string
  }
}
export const CLEAR_SHIFT_TASK_SELECTION = 'CLEAR_SHIFT_TASK_SELECTION'
export const clearShiftTaskSelection = (shiftId: string): ClearShiftTaskSelection => ({
  type: CLEAR_SHIFT_TASK_SELECTION,
  payload: { shiftId },
})

export type ToggleShowTaskContact = {
  type: 'TOGGLE_SHOW_TASK_CONTACT'
  payload: { shiftId: string; taskIndex: number; contactNumber: string | null }
}
export const TOGGLE_SHOW_TASK_CONTACT = 'TOGGLE_SHOW_TASK_CONTACT'
export const toggleShowTaskContact = (
  shiftId: string,
  taskIndex: number,
  contactNumber: string | null
): ToggleShowTaskContact => ({
  type: TOGGLE_SHOW_TASK_CONTACT,
  payload: { shiftId, taskIndex, contactNumber },
})

export type ShiftPageAction =
  | OpenNextTask
  | ChangeShiftTaskState
  | CloseShiftTasks
  | OpenShiftTask
  | SelectShiftTask
  | ClearShiftTaskSelection
  | MarkShiftTasksDone
  | MarkShiftTasksUndone
  | ToggleShowTaskContact
