import { FormHelperText } from '@mui/material'
import React from 'react'
import { MouseEventHandler, useCallback, useEffect, useState } from 'react'
import { ErrorCode as DropZoneErrorCode, useDropzone } from 'react-dropzone'
import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useTypedDispatch } from '../../'
import { postDigitalAssetAction } from '../../actions/create-defect'
import { removeFile } from '../../reducers/createDefectSlice'
import { ActionStatus } from '../../types/Input'
import ConfirmationDialog from './ConfirmationDialog'
import { FormErrorHelperText } from './FormErrorHelperText'
import {
  AddAnotherFileText,
  FileErrors,
  FileList,
  UploadBox,
  UploadInstructions,
} from './input/AttachmentUpload'
import { AssetIdentifiers } from './types'

interface AttachmentUploadProps {
  instructions: string
  fileWord: string
  onChange?: (files?: string[]) => void
  files: AssetIdentifiers
  status?: ActionStatus
  required?: boolean
  label?: string
  name: string
}

const base64Multiplier = 4 / 3
const MB = 2 ** 20
const API_MAX_FILE_SIZE = 10 * MB
const BUFFER = 0.5 * MB
export const MAX_FILE_SIZE = Math.floor(API_MAX_FILE_SIZE / base64Multiplier) - BUFFER

export const AttachmentUpload = ({
  instructions,
  fileWord,
  required = false,
  onChange: customOnChange,
  status,
  files,
  label,
  name,
}: AttachmentUploadProps): JSX.Element => {
  const { control } = useFormContext()
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()
  const [open, setOpen] = useState(false)
  const [fileToDelete, setFileToDelete] = useState('')
  const id = `${name}-${label}`

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: { value: required, message: t('field.requiredHelperText') } },
  })

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((acceptedFile) => {
        dispatch(postDigitalAssetAction(acceptedFile))
      })
    },
    [dispatch]
  )
  useEffect(() => {
    if (customOnChange) {
      customOnChange(Object.keys(files))
    }
  }, [customOnChange, files])

  const dropZoneState = useDropzone({
    onDrop,
    maxSize: MAX_FILE_SIZE,
  })

  const handleClickOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const remove = (id: string): void => {
    dispatch(removeFile(id))
    setFileToDelete('')
  }

  const deleteAttachment =
    (id: string): MouseEventHandler<HTMLButtonElement> =>
    (e): void => {
      e.stopPropagation()
      setFileToDelete(id)
      handleClickOpen()
    }

  const fileErrors = dropZoneState.fileRejections.map((fileRejection) => {
    const {
      file: { name: fileName },
      errors: fileErrors,
    } = fileRejection
    const errorMessages = fileErrors.map((fileError) => {
      const { message, code } = fileError
      if (code === DropZoneErrorCode.FileTooLarge) {
        return t('defect.attachmentTooBig', {
          maxSize: Math.floor(MAX_FILE_SIZE / MB),
        })
      }
      return message
    })
    return { fileName, messages: errorMessages }
  })

  return (
    <>
      <input type="hidden" value={field.value} />
      <UploadBox
        onBlur={field.onBlur}
        error={!!error?.message}
        id={id}
        dropzoneProps={dropZoneState}
      >
        {Object.keys(files).length > 0 ? (
          <>
            <FileList files={files} status={status} deleteAttachment={deleteAttachment} />
            <AddAnotherFileText>{t('defect.addAnotherFile')}</AddAnotherFileText>
          </>
        ) : (
          <UploadInstructions
            instructions={instructions}
            fileWord={fileWord}
            t={t}
            required={required}
          />
        )}
      </UploadBox>
      <FormHelperText component="div">
        {error?.message ? <FormErrorHelperText error={error.message} /> : null}
      </FormHelperText>
      <FileErrors errorsList={fileErrors} />
      {open && (
        <ConfirmationDialog
          closeDialog={handleClose}
          submitHandler={() => {
            remove(fileToDelete)
            handleClose()
          }}
          dialogText={t('defect.removeAttachmentConfirmation')}
          primaryActionText={t('action.confirmRemove')}
        />
      )}
    </>
  )
}
