import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const Train = ({ iconSize, fillColor }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          d="M19.82,3.88 L19.82,21.12 L19.82,21.12 C19.82,21.6501934 19.3901934,22.08 18.86,22.08 L18.86,3.84 L4.46,3.84 L4.46,22.08 C3.92980664,22.08 3.5,21.6501934 3.5,21.12 L3.5,3.88 C3.5,3.32771525 3.94771525,2.88 4.5,2.88 L18.82,2.88 L18.82,2.88 C19.3722847,2.88 19.82,3.32771525 19.82,3.88 L19.82,3.88 Z M4.46,21.12 L18.86,21.12 L18.86,22.08 L4.46,22.08 L4.46,21.12 Z M7.34,-8.8817842e-16 L15.98,-8.8817842e-16 L15.98,0.96 L12.14,0.96 L12.14,2.88 L11.18,2.88 L11.18,0.96 L7.34,0.96 L7.34,-8.8817842e-16 Z M6.38,5.76 L8.08229124,5.4504925 L8.08229124,5.4504925 C10.4480378,5.02035677 12.8719622,5.02035677 15.2377088,5.4504925 L16.94,5.76 L16.94,6.72 L15.2377088,6.4104925 L15.2377088,6.4104925 C12.8719622,5.98035677 10.4480378,5.98035677 8.08229124,6.4104925 L6.38,6.72 L6.38,5.76 Z M8.78,18.24 C7.98470996,18.24 7.34,17.59529 7.34,16.8 C7.34,16.00471 7.98470996,15.36 8.78,15.36 C9.57529004,15.36 10.22,16.00471 10.22,16.8 C10.22,17.59529 9.57529004,18.24 8.78,18.24 Z M14.54,18.24 C13.74471,18.24 13.1,17.59529 13.1,16.8 C13.1,16.00471 13.74471,15.36 14.54,15.36 C15.33529,15.36 15.98,16.00471 15.98,16.8 C15.98,17.59529 15.33529,18.24 14.54,18.24 Z M4.46,10.56 L6.50674699,11.1057992 L6.50674699,11.1057992 C9.88325303,12.0062008 13.436747,12.0062008 16.813253,11.1057992 L18.86,10.56 L18.86,11.52 L16.813253,12.0657992 L16.813253,12.0657992 C13.436747,12.9662008 9.88325303,12.9662008 6.50674699,12.0657992 L4.46,11.52 L4.46,10.56 Z M5.42,22.08 L6.38,22.08 L5.42,24 L4.46,24 L5.42,22.08 Z M16.94,22.08 L17.9,22.08 L18.86,24 L17.9,24 L16.94,22.08 Z"
          fill={fillColor ?? 'currentColor'}
        />
      </g>
    </svg>
  )
}

export default Train
