import styled from '@emotion/styled'
import React from 'react'

import StarIcon from '../../icons/Star'
import { Color, color, getColor, theme } from '../../Theme'
import { ColorProps } from '../../types/App'

const Row = styled.div`
  ${theme.layout.flexRow};
  ${theme.spacing.ends('small')};
  min-height: 60px;
  background: ${(p) => getColor(p.theme, ['white'], ['nightBlack'])};
  box-sizing: border-box;
  flex: 2;
  justify-content: center;
  text-align: center;
`

const Star = styled.div<ColorProps>`
  ${theme.layout.flexRow};
  ${theme.spacing.all('small')};
  ${theme.text('largest', 'content', 'bold')};
  box-sizing: border-box;
  color: ${(p) => color(p.color)};
  justify-content: center;
  width: ${theme.spacing.sizes.huge};
  text-align: center;
`

const Rating = ({ stars }: { stars: number }) => (
  <Row>
    {[1, 2, 3, 4].map((value) => (
      <Star key={value} color={stars >= value ? (`star${value}` as Color) : 'grayDark'}>
        <StarIcon iconSize="normal" />
      </Star>
    ))}
  </Row>
)

export default Rating
