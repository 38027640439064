import { UPDATE_CONTACT_LOCATION, UPDATE_CONTACTS } from '../actions/contacts'
import { ContactsAction } from '../actions/contacts'
import { ContactState } from '../types'
import { replaceManyWithId } from './Common'

export const initialState = {
  byId: {},
}

const contactsReducer = (
  state: ContactState = initialState,
  action: ContactsAction
): ContactState => {
  switch (action.type) {
    case UPDATE_CONTACTS:
      return {
        byId: replaceManyWithId(state.byId, action.payload),
      }

    case UPDATE_CONTACT_LOCATION: {
      const contactLocation = action.payload
      const updatedState = { ...state.byId }
      updatedState[contactLocation.personnelNumber].location = {
        ...contactLocation.location,
        timestamp: contactLocation.timestamp,
      }
      return {
        byId: { ...updatedState },
      }
    }

    default:
      return state
  }
}

export default contactsReducer
