import { TFunction } from 'i18next'

import { DefectTree, DropdownItem, Equipment } from '../components/create-defect/types'

const COLLATOR = new Intl.Collator('en', { numeric: true, sensitivity: 'base' })

export const createEquipmentDropdownOption = (option: Equipment): DropdownItem => ({
  value: option.installationGroup,
  label: `${option.installationGroup}(${option.itemId})`,
})

export const convertToDropDownItem = <T extends Record<K, string>, K extends keyof T>(
  option: T,
  value: K,
  label: K
): DropdownItem => {
  return { value: option[value], label: option[label] }
}

export const createEquipmentDropdownOptionItemId = (option: string): DropdownItem => ({
  value: option,
  label: option,
})

export const uniqByKey = <T extends Record<K, string>, K extends keyof T>(arr: T[], key: K): T[] =>
  arr.filter((v, i, a) => a.findIndex((v2) => v2[key] === v[key]) === i)

export const createCriticalityDisplayValue = (criticality: string, t: TFunction): string =>
  criticality === '1' || criticality === '2' || criticality === '3'
    ? `${t(`defect.criticality.${criticality}`)} (${criticality})`
    : criticality

const createCriticalityDropdownOption = (option: string, t: TFunction): DropdownItem => ({
  value: option,
  label: createCriticalityDisplayValue(option, t),
})

export const createCriticalityDropdownOptions = (t: TFunction): DropdownItem[] =>
  ['1', '2', '3'].map((o) => createCriticalityDropdownOption(o, t))

export const addIdenticalLabels = (options: string[]): DropdownItem[] =>
  options.map((option) => ({ value: option, label: option }))

export const addIdtoDescription = (
  codes: { id: string; description: string }[]
): DropdownItem[] => {
  const results = codes?.map(({ id, description }) => {
    return { value: id, label: `${id}: ${description}` }
  })

  results?.sort((a, b) => COLLATOR.compare(a.value, b.value))
  return results
}

const getDefectGroupsLabel = (defect: DefectTree): string => {
  const { mpgDescription, spgDescription, vrpg1Description, vrpg2Description } = defect
  const label = [mpgDescription, spgDescription, vrpg1Description, vrpg2Description]
    .filter((e) => e !== '')
    .join(' \u279C ') // unicode for Heavy Round-Tipped Rightwards Arrow
  return label
}

/*
Above returns of value:
- defectCode: 1-4 product Group letters e.g. RBKD followed by numbers
  - made up of other fields:
  - mpg, spg, vrpg1, vrpg2
  - each also includes a description version
- criticality
- defectType
*/
export const getDefectCodes = (defects: DefectTree[]): DropdownItem[] => {
  return uniqByKey(defects, 'productGroups').map((defect) => {
    return { value: defect.productGroups, label: getDefectGroupsLabel(defect) }
  })
}

/**
 * check if current value is in option, to avoid having an invalid value
 * also to avoid marking MUI input as uncontrolled return null instead of undefined
 * see: https://mui.com/material-ui/react-text-field/#uncontrolled-vs-controlled
 */
export default function findValueInOptions(
  value: string,
  options: DropdownItem[]
): DropdownItem | null {
  return options.find((option) => option.value === value) ?? null
}
