import React, { ReactNode, useEffect, useRef } from 'react'

import moment from '../../lib/moment-fi'
import { Moment } from '../../types'

type Props = {
  startTime: Moment
  endTime: Moment
  children: ReactNode
}

const ScrollWrapper = ({ startTime, endTime, children }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const scroll = () => scrollRef.current?.scrollIntoView({ behavior: 'smooth' })

  useEffect(() => {
    if (moment().isBetween(startTime, endTime)) {
      scroll()
    }
    // objects have to be stringified to not trigger the effect unnecessarily
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(endTime), JSON.stringify(startTime)])

  return <div ref={scrollRef}>{children}</div>
}

export default ScrollWrapper
