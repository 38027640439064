import styled from '@emotion/styled'
import { Router } from 'found'
import { TFunction } from 'i18next'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import {
  ChangeViewFunction,
  deleteCompositionFromHistory,
  deletePersonnelFromHistory,
  deleteSearchedShiftFromHistory,
  fetchCompositions,
  fetchPersonnelInformation,
  fetchShifts,
  fetchTimetable,
  fetchTowingPatterns,
  getCompositionSearchHistory,
  getPersonnelSearchHistory,
  getSearchedShiftSearchHistory,
  personnelFromHistory,
  searchedShiftFromHistory,
  searchPunctuality,
} from '../actions/api'
import CompositionSearch from '../components/compositions/search/CompositionSearch'
import ErrorText from '../components/ErrorText'
import Page from '../components/page/Page'
import PersonnelSearch from '../components/personnel/PersonnelSearch'
import PunctualitySearch from '../components/punctuality/PunctualitySearch'
import ShiftSearch from '../components/shift/ShiftSearch'
import TimetableSearch from '../components/timetable/TimetableSearch'
import TowingSearch from '../components/towing/search/TowingSearch'
import moment from '../lib/moment-fi'
import { isMaintenanceUser } from '../lib/roleUtils'
import { theme } from '../Theme'
import {
  Action,
  AppState,
  Compositions,
  Dispatch,
  Personnel,
  Shift,
  TowingVehiclePattern,
} from '../types'
import { MatchProps } from '../types/App'
import { SearchDeleteParams, TimetableParams, User } from '../types/Input'

const Container = styled.div`
  ${theme.layout.flexCenterH};
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  flex-direction: row;
`

const Content = styled.div`
  ${theme.layout.fluidWidthMargin(theme.maxWidths.content, theme.spacing.sizes.small)};
`

const Pad = styled.div`
  ${theme.spacing.right('small')};
  ${theme.spacing.left('small')};
  ${theme.spacing.bottom('small')};
`

type Props = {
  t: TFunction
  router: Router
  params: unknown
  match: MatchProps
  // personnel state params and functions
  loadingPersonnel: boolean
  personnelError: string
  personnelHistory: Array<Personnel>
  fetchPersonnelInformation: (arg0: string, arg1: string, func: () => unknown) => Action
  getPersonnelSearchHistory: () => void
  personnelFromHistory: (personnel: Personnel, func: () => unknown) => Action
  deletePersonnelFromHistory: (searchDeleteParams: SearchDeleteParams) => Action
  // composition state params and functions
  loadingCompositions: boolean
  compositionsError: string
  compositionsHistory: Array<Compositions>
  fetchCompositions: (arg0: string, arg1: string, arg2: string, func: () => unknown) => Action
  getCompositionSearchHistory: () => void
  compositionFromHistory: (composition: Compositions, changeView: ChangeViewFunction) => Action
  deleteCompositionFromHistory: (searchDeleteParams: SearchDeleteParams) => Action
  // searched shift state params and functions
  loadingShifts: boolean
  shiftsError: string
  shiftsHistory: Array<Shift>
  fetchShifts: (params: State, changeView?: ChangeViewFunction) => Action
  getSearchedShiftSearchHistory: () => void
  searchedShiftFromHistory: (shift: Shift, func: () => unknown) => Action
  deleteSearchedShiftFromHistory: (searchDeleteParams: SearchDeleteParams) => Action
  // fallback timetable state params and functions
  loadingTimetable: boolean
  timetableError: string
  fetchTimetable: (arg0: boolean, array: Array<TimetableParams>) => void
  // punctuality
  loadingPunctuality: boolean
  punctualityError: string
  searchPunctuality: (arg0: string, arg1: string, func: () => unknown) => void
  // towing
  fetchTowingPatterns: () => void
  towingPatterns: Array<TowingVehiclePattern>
  loadingTowing: boolean
  towingError: string
  maintenance: boolean
  user: User
}

export type State = {
  date: string
  dateActive?: boolean
  error?: string
  shiftId: string
  showCalendar?: boolean
  startStation: string
  startStationActive?: boolean
  shiftIdActive?: boolean
  trainNumberActive?: boolean
  trainNumber: string
  isUsedForDriving: boolean
  vehicleTypeActive: boolean
  vehicleNumberActive: boolean
  user: User
}

class SearchPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      trainNumber: '',
      date: moment().format('DD.MM.YYYY'),
      shiftId: '',
      showCalendar: false,
      startStation: '',
      error: undefined,
      isUsedForDriving: false,
      vehicleTypeActive: false,
      vehicleNumberActive: false,
      user: props.user,
    }
  }

  componentDidMount() {
    this.props.getPersonnelSearchHistory()
    this.props.getSearchedShiftSearchHistory()
    this.props.getCompositionSearchHistory()
    this.props.fetchTowingPatterns()
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    const { t, punctualityError } = this.props
    if (!punctualityError && Boolean(nextProps.punctualityError)) {
      this.setState({ error: t('punctualityError') })
    }
  }

  changeTrainNumber(value: string) {
    const matchPositiveNumber = /^[1-9]\d*$/
    if ((this.state.trainNumber !== '' && value === '') || matchPositiveNumber.test(value)) {
      return this.setState({ trainNumber: value, error: undefined, shiftId: '' })
    }
    this.setState({ error: 'Junanumeron pitää olla positiivinen' })
  }

  changeDate(value: moment.MomentInput) {
    this.setState({
      date: moment(value).format('DD.MM.YYYY'),
      showCalendar: false,
      error: undefined,
    })
  }

  openCalendar(showCalendar: boolean) {
    this.setState({ showCalendar })
  }

  activateTrainNumber() {
    this.setState({ trainNumberActive: true, dateActive: false, showCalendar: false })
  }

  activateDate() {
    this.setState({ trainNumberActive: false, dateActive: true })
  }

  activateField(obj: State) {
    this.setState(obj)
  }

  changeValue(obj: State) {
    this.setState(obj)
  }

  fetchPersonnelInformation() {
    this.props.fetchPersonnelInformation(
      moment(this.state.date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      this.state.trainNumber,
      () => this.props.router.push(`/personnelList/${this.state.trainNumber}/${this.state.date}`)
    )
  }

  fetchShifts() {
    const isValidShiftIdSearch = this.state.shiftId !== '' && this.state.date !== ''
    const isValidTrainNumberSearch =
      this.state.trainNumber !== '' && this.state.date !== '' && this.state.startStation !== ''
    if (isValidTrainNumberSearch) {
      this.props.fetchShifts(this.state, () =>
        this.props.router.push(
          `/shiftInstructions/trainNumber=${this.state.trainNumber}&date=${this.state.date}&startStation=${this.state.startStation}`
        )
      )
    } else if (isValidShiftIdSearch) {
      this.props.fetchShifts(this.state, () =>
        this.props.router.push(
          `/shiftInstructions/shiftId=${this.state.shiftId}&date=${this.state.date}`
        )
      )
    } else {
      this.setState({ error: this.props.t('fillAllMandatoryFields') })
    }
  }

  fetchCompositions() {
    this.fetchCompositionsWithParams(
      moment(this.state.date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      this.state.trainNumber,
      this.state.startStation
    )
  }

  fetchCompositionsWithParams(date: string, trainNumber: string, station: string) {
    this.props.fetchCompositions(date, trainNumber, station, () =>
      this.props.router.push(`/composition/${date}/${station}/${trainNumber}`)
    )
  }

  fetchPunctuality() {
    const { date, trainNumber } = this.state
    const { router } = this.props
    this.props.searchPunctuality(
      moment(this.state.date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      trainNumber,
      () => router.push(`/punctuality/${date}/${trainNumber}`)
    )
  }

  personnelSelected() {
    return this.props.match.params[0] === '/personnel'
  }

  compositionsSelected() {
    return this.props.match.params[0] === '/compositions'
  }

  shiftsSelected() {
    return this.props.match.params[0] === '/shifts'
  }

  timetablesSelected() {
    return this.props.match.params[0] === '/fallbackTimetables'
  }

  punctualitySelected() {
    return this.props.match.params[0] === '/punctuality'
  }

  towingSelected() {
    return this.props.match.params[0] === '/towings'
  }

  render() {
    const {
      t,
      router,
      loadingPersonnel,
      personnelError,
      personnelHistory,
      personnelFromHistory,
      deletePersonnelFromHistory,
      loadingCompositions,
      compositionsError,
      compositionsHistory,
      deleteCompositionFromHistory,
      loadingShifts,
      shiftsError,
      shiftsHistory,
      searchedShiftFromHistory,
      deleteSearchedShiftFromHistory,
      loadingTimetable,
      timetableError,
      fetchTimetable,
      loadingPunctuality,
      loadingTowing,
      towingError,
      towingPatterns,
    }: Props = this.props

    // set error from sub pages if needed
    if (this.state.error !== t(personnelError) && this.personnelSelected())
      this.changeValue({ ...this.state, error: t(personnelError) })
    if (this.state.error !== t(compositionsError) && this.compositionsSelected())
      this.changeValue({ ...this.state, error: t(compositionsError) })
    if (this.state.error !== t(shiftsError) && this.shiftsSelected())
      this.changeValue({ ...this.state, error: t(shiftsError) })
    if (this.state.error !== t(timetableError) && this.timetablesSelected())
      this.changeValue({ ...this.state, error: t(timetableError) })
    if (this.state.error !== t(towingError) && this.towingSelected())
      this.changeValue({ ...this.state, error: t(towingError) })

    // TODO change shifts icon once it is available
    return (
      <Page overflowVisible>
        <Container>{this.state.error && <ErrorText>{this.state.error}</ErrorText>}</Container>
        <Container>
          <Content>
            <Pad>
              {this.personnelSelected() && (
                <PersonnelSearch
                  activateDate={() => this.activateDate()}
                  activateTrainNumber={() => this.activateTrainNumber()}
                  changeDate={(value) => this.changeDate(value)}
                  changeTrainNumber={(value) => this.changeTrainNumber(value)}
                  fetchPersonnelInformation={() => this.fetchPersonnelInformation()}
                  personnelHistory={personnelHistory}
                  personnelFromHistory={personnelFromHistory}
                  deletePersonnelFromHistory={deletePersonnelFromHistory}
                  loading={loadingPersonnel}
                  openCalendar={(bool) => this.openCalendar(bool)}
                  router={router}
                  setError={(error) => this.setState({ error })}
                  state={this.state}
                  t={t}
                />
              )}
              {this.compositionsSelected() && (
                <CompositionSearch
                  fetchCompositions={() => this.fetchCompositions()}
                  fetchCompositionsWithParams={(date, trainNumber, station) =>
                    this.fetchCompositionsWithParams(date, trainNumber, station)
                  }
                  compositionsHistory={compositionsHistory}
                  deleteCompositionFromHistory={deleteCompositionFromHistory}
                  loading={loadingCompositions}
                  router={router}
                  setError={(error) => this.setState({ error })}
                  state={this.state}
                  t={t}
                />
              )}
              {this.shiftsSelected() && (
                <ShiftSearch
                  activateDate={() =>
                    this.activateField({
                      ...this.state,
                      ...{
                        dateActive: true,
                        trainNumberActive: false,
                        startStationActive: false,
                        shiftIdActive: false,
                      },
                    })
                  }
                  activateShiftId={() =>
                    this.activateField({
                      ...this.state,
                      ...{
                        dateActive: false,
                        shiftIdActive: true,
                        trainNumberActive: false,
                        startStationActive: false,
                      },
                    })
                  }
                  activateStartStation={() =>
                    this.activateField({
                      ...this.state,
                      ...{
                        dateActive: false,
                        trainNumberActive: false,
                        startStationActive: true,
                        shiftIdActive: false,
                      },
                    })
                  }
                  activateTrainNumber={() =>
                    this.activateField({
                      ...this.state,
                      ...{
                        dateActive: false,
                        trainNumberActive: true,
                        startStationActive: false,
                        shiftIdActive: false,
                      },
                    })
                  }
                  changeDate={(value) => this.changeDate(value)}
                  changeTrainNumber={(value) => this.changeTrainNumber(value)}
                  changeStartStation={(startStation: string) =>
                    this.changeValue({ ...this.state, ...{ startStation, error: undefined } })
                  }
                  changeShiftId={(shiftId: string) =>
                    this.changeValue({
                      ...this.state,
                      ...{ shiftId, trainNumber: '', error: undefined },
                    })
                  }
                  fetchShifts={() => this.fetchShifts()}
                  shiftHistory={shiftsHistory}
                  searchedShiftFromHistory={searchedShiftFromHistory}
                  deleteSearchedShiftFromHistory={deleteSearchedShiftFromHistory}
                  loading={loadingShifts}
                  openCalendar={(bool) => this.openCalendar(bool)}
                  router={router}
                  setError={(error) => this.setState({ error })}
                  state={this.state}
                  t={t}
                  user={this.state.user}
                />
              )}
              {this.timetablesSelected() && (
                <TimetableSearch
                  activateDate={() =>
                    this.activateField({
                      ...this.state,
                      ...{
                        dateActive: true,
                        trainNumberActive: false,
                        startStationActive: false,
                        shiftIdActive: false,
                      },
                    })
                  }
                  activateTrainNumber={() =>
                    this.activateField({
                      ...this.state,
                      ...{
                        dateActive: false,
                        trainNumberActive: true,
                        startStationActive: false,
                        shiftIdActive: false,
                      },
                    })
                  }
                  changeDate={(value) => this.changeDate(value)}
                  changeTrainNumber={(value) => this.changeTrainNumber(value)}
                  changeUseForDriving={(isUsedForDriving) =>
                    this.changeValue({ ...this.state, ...{ isUsedForDriving } })
                  }
                  fetchTimetable={() =>
                    fetchTimetable(this.state.isUsedForDriving, [
                      {
                        trainNumber: this.state.trainNumber,
                        timetableDate: moment(this.state.date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
                      },
                    ])
                  }
                  loading={loadingTimetable}
                  openCalendar={(bool) => this.openCalendar(bool)}
                  setError={(error) => this.setState({ error })}
                  state={this.state}
                />
              )}
              {this.punctualitySelected() && (
                <PunctualitySearch
                  activateDate={() =>
                    this.activateField({
                      ...this.state,
                      ...{
                        dateActive: true,
                        trainNumberActive: false,
                        startStationActive: false,
                        shiftIdActive: false,
                      },
                    })
                  }
                  activateTrainNumber={() =>
                    this.activateField({
                      ...this.state,
                      ...{
                        dateActive: false,
                        trainNumberActive: true,
                        startStationActive: false,
                        shiftIdActive: false,
                      },
                    })
                  }
                  changeDate={(value) => this.changeDate(value)}
                  changeTrainNumber={(value) => this.changeTrainNumber(value)}
                  fetchPunctuality={() => this.fetchPunctuality()}
                  loading={loadingPunctuality}
                  openCalendar={(bool) => this.openCalendar(bool)}
                  setError={(error) => this.setState({ error })}
                  state={this.state}
                  t={t}
                />
              )}
              {this.towingSelected() && (
                <TowingSearch
                  activateVehicleNumber={() =>
                    this.activateField({
                      ...this.state,
                      ...{ vehicleNumberActive: true, vehicleTypeActive: false },
                    })
                  }
                  activateVehicleType={() =>
                    this.activateField({
                      ...this.state,
                      ...{ vehicleNumberActive: false, vehicleTypeActive: true },
                    })
                  }
                  loading={loadingTowing}
                  setError={(error) => this.setState({ error })}
                  state={this.state}
                  router={router}
                  towingPatterns={towingPatterns}
                  t={t}
                />
              )}
            </Pad>
          </Content>
        </Container>
      </Page>
    )
  }
}

const mapStateToProps = (state: AppState) => {
  const loadingPersonnel = state.personnel.loading
  const personnelError = state.personnel.error
  const personnelHistory = state.personnel.personnelHistory

  const loadingCompositions = state.compositions.loading
  const compositionsError = state.compositions.error
  const compositionsHistory = state.compositions.history

  const loadingShifts = state.searchedShifts.loading
  const shiftsError = state.searchedShifts.error
  const shiftsHistory = state.searchedShifts.history

  const loadingTimetable = state.timetable.loading
  const timetableError = state.timetable.error

  const loadingPunctuality = state.punctuality.loading
  const punctualityError = state.punctuality.error

  const loadingTowing = state.towingSearch.loading
  const towingError = state.towingSearch.error

  const towingPatterns = state.towingPatterns.towingPatterns

  const user = state.user

  const maintenance = isMaintenanceUser(state.user)

  return {
    loadingPersonnel,
    personnelError,
    personnelHistory,
    loadingCompositions,
    compositionsError,
    compositionsHistory,
    loadingShifts,
    shiftsError,
    shiftsHistory,
    loadingTimetable,
    timetableError,
    loadingPunctuality,
    punctualityError,
    loadingTowing,
    towingError,
    towingPatterns,
    maintenance,
    user,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  // personnel functions
  fetchPersonnelInformation: (
    date: string,
    trainNumber: string,
    changeView?: ChangeViewFunction | null
  ) => dispatch(fetchPersonnelInformation(date, trainNumber, changeView) as unknown as Action),
  getPersonnelSearchHistory: () => dispatch(getPersonnelSearchHistory() as unknown as Action),
  personnelFromHistory: (train: Personnel, changeView: ChangeViewFunction) =>
    dispatch(personnelFromHistory(train, changeView) as unknown as Action),
  deletePersonnelFromHistory: (params: SearchDeleteParams) =>
    dispatch(deletePersonnelFromHistory(params) as unknown as Action),
  // composition functions
  fetchCompositions: (
    date: string,
    trainNumber: string,
    station: string,
    changeView?: ChangeViewFunction
  ) => dispatch(fetchCompositions(date, trainNumber, station, changeView) as unknown as Action),
  getCompositionSearchHistory: () => dispatch(getCompositionSearchHistory() as unknown as Action),
  deleteCompositionFromHistory: (params: SearchDeleteParams) =>
    dispatch(deleteCompositionFromHistory(params) as unknown as Action),
  // searched shift functions
  fetchShifts: (params: State, changeView?: ChangeViewFunction) =>
    dispatch(fetchShifts(params, changeView) as unknown as Action),
  getSearchedShiftSearchHistory: () =>
    dispatch(getSearchedShiftSearchHistory() as unknown as Action),
  searchedShiftFromHistory: (shift: Shift, changeView: ChangeViewFunction) =>
    dispatch(searchedShiftFromHistory(shift, changeView) as unknown as Action),
  deleteSearchedShiftFromHistory: (params: SearchDeleteParams) =>
    dispatch(deleteSearchedShiftFromHistory(params) as unknown as Action),
  // fallback timetable functions
  fetchTimetable: (isUsedForDriving: boolean, timetableParams: TimetableParams[]) =>
    dispatch(fetchTimetable(isUsedForDriving, timetableParams) as unknown as Action),
  // punctuality
  searchPunctuality: (trainDate: string, trainNumber: string, changeView: (() => void) | null) =>
    dispatch(searchPunctuality(trainDate, trainNumber, changeView) as unknown as Action),
  //towing
  fetchTowingPatterns: () => dispatch(fetchTowingPatterns() as unknown as Action),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SearchPage))
