import styled from '@emotion/styled'
import { Router } from 'found'
import { TFunction } from 'i18next'
import { isEqual } from 'lodash'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { fetchTrainPunctuality } from '../actions/api'
import { setPunctualityParams } from '../actions/punctuality'
import Checkbox from '../components/Checkbox'
import HeaderTitle from '../components/header/HeaderTitle'
import LoadingIndicator from '../components/LoadingIndicator'
import {
  Container,
  Content,
  Footer,
  FooterText,
  PageHeader,
} from '../components/page/PageComponents'
import PunctualityRow from '../components/punctuality/PunctualityRow'
import { MOMENT_TIME_FORMAT, PUNCTUALITY_PAGE_REFRESH_INTERVAL_MS } from '../constants'
import ArrowLeft from '../icons/ArrowLeft'
import moment from '../lib/moment-fi'
import { showStationInPunctualityListing } from '../lib/stations'
import { getPunctualityKey, isMobileSelector, nightModeSelector } from '../Selectors'
import { getColor, theme } from '../Theme'
import type { Action, AppState, Dispatch } from '../types'
import { BoldProps, isMobileProps, LabelsProps, TrainParams } from '../types/App'
import type { MappedPunctuality, TimetableRow, TrainPunctuality } from '../types/Input'

const BackIcon = styled.div`
  align-content: start;
  display: flex;
  padding-left: 8px;
`

const Constrain = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  text-align: center;
  color: ${(p) => getColor(p.theme, ['black'], ['grayPink'])};
`

const CheckBoxRow = styled.div`
  ${theme.layout.flexRow};
  ${theme.layout.fluidWidth(theme.maxWidths.paddedContent)};
  margin-top: 40px;
`

const PunctualityHeader = styled.div`
  line-height: ${theme.font.small};
  font-size: ${theme.font.small};
  margin-top: 30px;
  margin-bottom: 10px;
`

const ActualRow = styled.div<BoldProps>`
  ${theme.layout.flexRow};
  ${theme.layout.fluidWidth(theme.maxWidths.paddedContent)};
  justify-content: flex-start;
  font-weight: ${(p) => (p.bold ? 'bold' : 'normal')};
`

const Time = styled.span<isMobileProps>`
  width: 20%;
  ${theme.layout.flexRow};
  flex-direction: ${(p) => (p.isMobile ? 'column' : 'row')};
  align-items: ${(p) => (p.isMobile ? 'start' : 'center')};
`

const Station = styled.span<LabelsProps>`
  max-width: 50%;
  text-align: left;
  height: ${(p) => (p.labels ? '62px' : 'inherit')};
`

const mapPunctuality = (punctuality?: TrainPunctuality | null): Array<MappedPunctuality> => {
  const punctualityRows: MappedPunctuality[] = []
  let punctualityObj: Partial<MappedPunctuality> = {}
  const timeTableRows: Array<TimetableRow> = punctuality ? punctuality.timeTableRows : []
  timeTableRows.forEach((t: TimetableRow) => {
    // TODO differenceInMinutes ei ole aivan sama kuin mitä näkyy julia.dy.fi:ssä
    // juliassa laskettaneen erotuksena actualista ja scheduledista ja jätetään sekunnit huomiotta
    // eli otetaan vaan täydet minuutit huomioon ehkä
    // => kumpi lienee oikeampi?
    punctualityObj['estimate'] = !t.actualTime && moment(t.liveEstimateTime).isAfter(moment())
    punctualityObj['notDriving'] = !t.actualTime && !t.liveEstimateTime
    if (t.type === 'DEPARTURE') {
      punctualityObj['stationShortCode'] = t.stationShortCode
      punctualityObj['scheduledDepartureTime'] = moment(t.scheduledTime).format(MOMENT_TIME_FORMAT)
      punctualityObj['actualDepartureTime'] = t.actualTime
        ? moment(t.actualTime).format(MOMENT_TIME_FORMAT)
        : t.liveEstimateTime
        ? moment(t.liveEstimateTime).format(MOMENT_TIME_FORMAT)
        : ''
      punctualityObj['differenceDepartureMinutes'] = t.differenceInMinutes
      punctualityObj['departureCauses'] = t.causes ? t.causes : []

      punctualityRows.push(punctualityObj as MappedPunctuality)
      punctualityObj = {}
    }
    if (t.type === 'ARRIVAL') {
      punctualityObj['stationShortCode'] = t.stationShortCode
      if (t.trainStopping || (t.causes && t.causes.length !== 0)) {
        punctualityObj['scheduledArrivalTime'] = moment(t.scheduledTime).format(MOMENT_TIME_FORMAT)
        punctualityObj['actualArrivalTime'] = t.actualTime
          ? moment(t.actualTime).format(MOMENT_TIME_FORMAT)
          : t.liveEstimateTime
          ? moment(t.liveEstimateTime).format(MOMENT_TIME_FORMAT)
          : ''
      }
      punctualityObj['differenceArrivalMinutes'] = t.differenceInMinutes
      punctualityObj['arrivalCauses'] = t.causes ? t.causes : []

      if (timeTableRows.indexOf(t) === timeTableRows.length - 1)
        punctualityRows.push(punctualityObj as MappedPunctuality)
    }
  })
  return punctualityRows
}

const updatePunctuality = (
  params: Array<string>,
  refetchPunctuality: (arg1: TrainParams[]) => any,
  punctuality: TrainPunctuality | null | undefined,
  forceFetch: boolean
) => {
  if (params && params[0]) {
    const pathParameters = params[0].split('/')
    const trainsArray: TrainParams[] = []
    trainsArray.push({
      trainDate: moment(pathParameters[0], 'DD.MM.YYYY').format('YYYY-MM-DD'),
      trainNumber: pathParameters[1],
    })
    if (forceFetch || !punctuality) {
      refetchPunctuality(trainsArray)
    }
  }
}

type Props = {
  t: TFunction
  punctuality: TrainPunctuality | undefined
  loading: boolean
  error: string
  nightMode: boolean
  isMobile: boolean
  params: Array<any>
  setPunctualityParams: (arg1: string, arg2: string) => void
  fetchTrainPunctuality: (arg1: TrainParams[]) => any
  router: Router
}

type State = {
  showAll: boolean
}

class PunctualityPage extends Component<Props, State> {
  timer: any
  constructor(props: any) {
    super(props)
    this.state = {
      showAll: false,
    }
  }

  componentDidMount() {
    const pathParameters = this.props.params && this.props.params[0].split('/')
    this.props.setPunctualityParams(
      moment(pathParameters[0], 'DD.MM.YYYY').format('YYYY-MM-DD'),
      pathParameters[1]
    )
    this.timer = setInterval(
      () =>
        updatePunctuality(
          this.props.params,
          this.props.fetchTrainPunctuality,
          this.props.punctuality,
          true
        ),
      PUNCTUALITY_PAGE_REFRESH_INTERVAL_MS
    )
    if (!this.props.punctuality) {
      updatePunctuality(
        this.props.params,
        this.props.fetchTrainPunctuality,
        this.props.punctuality,
        true
      )
    }
  }

  componentDidUpdate(prevProps: { params: any[] }) {
    if (
      this.props.params &&
      this.props.params[0] &&
      !this.props.error &&
      this.props.params[0] !== prevProps.params[0]
    )
      updatePunctuality(
        this.props.params,
        this.props.fetchTrainPunctuality,
        this.props.punctuality,
        false
      )
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  checkboxClicked() {
    this.setState({
      ...this.state,
      showAll: !this.state.showAll,
    })
  }

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>) {
    if (
      this.props.punctuality &&
      isEqual(this.state, nextState) &&
      isEqual(nextProps.punctuality, this.props.punctuality)
    ) {
      return false
    }
    return true
  }

  amendDeviationReason = (ocpCode: string, depOrArr: string) => {
    const { router, punctuality } = this.props
    if (!punctuality) return
    const { trainNumber, departureDate } = punctuality
    router.push(`/amendDeviationReason/${trainNumber}/${departureDate}/${ocpCode}/${depOrArr}`)
  }

  render() {
    const { t, punctuality, loading, error, nightMode, isMobile }: Props = this.props

    const header = (punctuality?: TrainPunctuality) =>
      punctuality
        ? `${punctuality.trainType} ${punctuality.trainNumber} ${
            punctuality.timeTableRows[0].stationShortCode
          } - ${punctuality.timeTableRows[punctuality.timeTableRows.length - 1].stationShortCode}`
        : ''

    const trainCategory = punctuality ? punctuality.trainCategory : ''
    //We don't want to filter punctualities if it would leave the page empty
    const mappedPunctualities =
      mapPunctuality(punctuality).filter((p) =>
        this.state.showAll
          ? true
          : showStationInPunctualityListing(p.stationShortCode, trainCategory)
      ).length <= 0
        ? mapPunctuality(punctuality)
        : mapPunctuality(punctuality).filter((p) =>
            this.state.showAll
              ? true
              : showStationInPunctualityListing(p.stationShortCode, trainCategory)
          )

    return (
      <Container>
        <PageHeader onClick={() => window.history.back()} height="64px">
          <BackIcon>
            <ArrowLeft
              iconSize="normal"
              stroke={getColor({ nightMode }, ['primaryBlue'], ['grayLight'])}
            />
          </BackIcon>
          <HeaderTitle title={t('punctualityHeader')} subtitle={header(punctuality)} />
        </PageHeader>
        <Content>
          {loading && !punctuality ? (
            <LoadingIndicator size="normal" padded={true} />
          ) : error !== '' ? (
            <div>{t(error)}</div>
          ) : (
            <Constrain>
              <CheckBoxRow>
                <Checkbox
                  selected={this.state.showAll}
                  disabled={false}
                  onClick={() => this.checkboxClicked()}
                />
                {t('showAllOperationPoints')}
              </CheckBoxRow>
              <PunctualityHeader>
                <ActualRow>
                  <Time>{t('arrival')}</Time>
                  <Time>{t('departure')}</Time>
                  <Station>{t('operationPoint')}</Station>
                </ActualRow>
              </PunctualityHeader>
              {mappedPunctualities.map((row, i) => (
                <PunctualityRow
                  t={t}
                  key={i}
                  i={i}
                  isMobile={isMobile}
                  row={row}
                  trainCategory={trainCategory || ''}
                  first={i === 0}
                  last={i === mappedPunctualities.length - 1}
                  nightMode={nightMode}
                  diffToNext={
                    i < mappedPunctualities.length - 1
                      ? mappedPunctualities[i + 1].differenceArrivalMinutes || 0
                      : 0
                  }
                  nextArrivalCauses={
                    i < mappedPunctualities.length - 1
                      ? mappedPunctualities[i + 1].arrivalCauses || null
                      : null
                  }
                  nextStation={
                    i < mappedPunctualities.length - 1
                      ? mappedPunctualities[i + 1].stationShortCode
                      : undefined
                  }
                  amendDeviationReason={(station, depOrArr) =>
                    this.amendDeviationReason(station, depOrArr)
                  }
                />
              ))}
            </Constrain>
          )}
        </Content>
        <Footer onClick={() => window.history.back()}>
          <BackIcon>
            <ArrowLeft iconSize="normal" />
          </BackIcon>
          <FooterText>{t('back')}</FooterText>
        </Footer>
      </Container>
    )
  }
}

const mapStateToProps = (state: AppState) => {
  const loading = state.punctuality.loading
  const error = state.punctuality.error

  const trainDate = state.punctuality.selectedTrainDate
  const trainNumber = state.punctuality.selectedTrainNumber
  const punctuality =
    state.punctuality.punctualityByTrain[getPunctualityKey(trainNumber, trainDate)]
  const params = state.found.match.params

  return {
    punctuality,
    loading,
    error,
    params,
    nightMode: nightModeSelector(state),
    isMobile: isMobileSelector(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchTrainPunctuality: (trainsArray: TrainParams[]) =>
    dispatch(fetchTrainPunctuality(trainsArray) as unknown as Action),
  setPunctualityParams: (trainDate: string, trainNumber: string) =>
    dispatch(setPunctualityParams({ trainDate: trainDate, trainNumber: trainNumber })),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PunctualityPage))
