import styled from '@emotion/styled'
import React from 'react'

import { color, getColor, MaybeColor, theme } from '../Theme'
import SeasonalScenery from './SeasonalScenery'

interface BgProps {
  large: boolean
  timeOfDay: MaybeColor
  transparentBg: boolean
}

interface TimeOfDayToBrightness {
  morning: number
  day: number
  evening: number
  night: number
}

const Bg = styled.div<BgProps>`
  z-index: -1;
  width: 100%;
  height: ${(p) => (p.large ? theme.maxHeights.greetingOpen : theme.maxHeights.greetingClosed)};
  transition: height 0.3s;
  overflow: hidden;
  position: absolute;
  top: 0;
  background-color: ${(p) =>
    p.transparentBg ? color('transparent') : getColor(p.theme, [p.timeOfDay], ['night'])};
`

const timeOfDayToBrightness: TimeOfDayToBrightness = {
  morning: 80,
  day: 100,
  evening: 70,
  night: 50,
}

type Props = {
  timeOfDay: keyof TimeOfDayToBrightness
  season: string
  large: boolean
  transparentBg: boolean
}

function Illustration({ large, season, timeOfDay, transparentBg }: Props) {
  const brightness = timeOfDayToBrightness[timeOfDay]
  return (
    <Bg large={large} timeOfDay={timeOfDay} transparentBg={transparentBg}>
      <SeasonalScenery simple={true} brightness={brightness} season={season} />
    </Bg>
  )
}

export default Illustration
