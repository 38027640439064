import { resolver } from 'found'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { Provider, TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { checkSession, fetchSessionData } from './actions/api'
import { resizeScreen, toggleShortNumbers } from './actions/system'
import { toggleNightMode } from './actions/system'
import { toggleTaskRowInfoDebug } from './actions/system'
import ErrorBoundary from './components/ErrorBoundary'
import i18n from './I18n'
import messageListener from './lib/messageListener'
import { offlineEvent, onlineEvent } from './lib/network'
import { listenReloadEvents } from './lib/pwa'
import { width } from './lib/screen'
import { startTicker, stopTicker } from './lib/time'
import { listenUpdates } from './lib/update'
import { rewriteURL } from './lib/url'
import FrontPage from './pages/FrontPage'
import registerServiceWorker from './registerServiceWorker'
import initRouter from './Router'
import initRoutes from './Routes'
import initStore from './Store'
import { Action } from './types'

listenUpdates()

const routeConfig = initRoutes()
export const store = initStore(routeConfig)
export type RootState = ReturnType<typeof store.getState>
export type TypedDispatch = ThunkDispatch<RootState, any, AnyAction>
export type TypedThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>

export const useTypedDispatch = () => useDispatch<TypedDispatch>()
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector

const Router = initRouter(FrontPage)

const rootEl = document.getElementById('root')

if (rootEl instanceof HTMLElement) {
  const root = createRoot(rootEl)
  root.render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ErrorBoundary store={store}>
          <Router resolver={resolver} matchContext={{ store }} />
        </ErrorBoundary>
      </I18nextProvider>
    </Provider>
  )
}

registerServiceWorker()

if (localStorage.getItem('nightMode') === 'true') {
  store.dispatch(toggleNightMode())
} else {
  localStorage.setItem('nightMode', 'false')
}

if (localStorage.getItem('shortNumbers') === 'true') {
  store.dispatch(toggleShortNumbers())
} else {
  localStorage.setItem('shortNumbers', 'false')
}

// TODO: remove this before production deployment
if (localStorage.getItem('taskRowInfoDebug') === 'true') {
  store.dispatch(toggleTaskRowInfoDebug())
} else {
  localStorage.setItem('taskRowInfoDebug', 'false')
}

// Extract Kenttä Backend's temporary exchange token from URL
const matches = /\/token\/([\w-]+)/.exec(window.location.pathname)
const exchangeToken = matches ? matches[1] : ''
/*
  Importing the store from index.js in /lib/api.js breaks the store in jest/enzyme for some reason
  It is a known bug and there is no known easily implementable fix
  This fixes the tests and allows deploying
*/
if (process.env.NODE_ENV !== 'test') {
  store.dispatch(fetchSessionData(exchangeToken) as unknown as Action)
}

// Remove Kenttä Backend's temporary exchange token from url
if (exchangeToken) {
  store.dispatch(rewriteURL('/'))
}

document.addEventListener('visibilitychange', () => {
  // User activates tab, refresh content
  if (!document.hidden) {
    const state = store.getState()
    store.dispatch(checkSession(state.user) as unknown as Action)
    startTicker(store)
    // Disable ticker when tab is inactive
  } else {
    stopTicker()
  }
})

// Check window size
window.addEventListener('resize', () => store.dispatch(resizeScreen(width())))

navigator.serviceWorker &&
  navigator.serviceWorker.addEventListener('message', (event) => messageListener(event, store))

// Listen reload messages
listenReloadEvents()

startTicker(store)

// Detect network state
window.addEventListener('online', onlineEvent(store))
window.addEventListener('offline', offlineEvent(store))
