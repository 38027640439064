import styled from '@emotion/styled'

import { getColor, MaybeColor, theme } from '../../Theme'

interface CircleProps {
  color?: MaybeColor
  nightMode?: boolean
}

const Circle = styled.div<CircleProps>`
  ${theme.text()};
  ${theme.spacing.all('small')};
  width: ${theme.spacing.sizes.normal};
  height: ${theme.spacing.sizes.normal};
  border: 1px solid ${(p) => (theme.nightMode ? 'transparent' : theme.buttons.border)};
  color: ${(p) => getColor(p.theme, [p.color, 'grayDark'], ['nightBackground'])};
  border-radius: ${theme.spacing.sizes.huge};
`

export default Circle
