import {
  Button,
  CircularProgress,
  Dialog,
  inputBaseClasses,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import React from 'react'
import { useEffect, useState } from 'react'
import { FieldValues, useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import createTree from '../../../lib/createTree'
import * as S from '../component.styles'
import { FormErrorHelperText } from '../FormErrorHelperText'
import CustomizedTreeView, { itemLabel } from '../TreeView'
import { FormControls, InputTreeOptions, ItemData, ItemOptions, Tree } from '../types'
import { FormInputLabel } from './InputLabel'

const TreePopUp = ({
  open,
  cancelHandler,
  items,
  expanded,
  submitHandler,
}: {
  open: boolean
  cancelHandler: (event?: object, reason?: string) => void
  items: Tree<ItemData>
  expanded: string[]
  submitHandler: (value: string) => void
}): JSX.Element => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [selected, setSelected] = useState<string>('')
  return (
    <Dialog
      open={open}
      onClose={cancelHandler}
      PaperProps={{
        sx: {
          padding: '1rem',
          bgcolor: 'background.secondary',
          backgroundImage: 'none',
        },
      }}
    >
      <CustomizedTreeView
        items={items}
        defaultExpanded={expanded}
        selected={selected}
        setSelected={setSelected}
      />
      <Stack
        borderTop={`solid 1px ${theme.palette.fieldBorderGrey}`}
        justifyContent="end"
        marginTop="1rem"
        direction="row"
        paddingTop="0.75rem"
        spacing={1}
      >
        <Button variant="submitButtonSecondary" onClick={() => cancelHandler()}>
          <Typography variant="subtitle1">{t('action.cancel')}</Typography>
        </Button>
        <Button
          variant="submitButtonPrimary"
          onClick={() => {
            submitHandler(selected)
          }}
        >
          <Typography variant="subtitle1">{t('field.placeholderSelect')}</Typography>
        </Button>
      </Stack>
    </Dialog>
  )
}

const getParents = (items: ItemOptions, selected: keyof ItemOptions): string[] => {
  const parents = []
  let parent = items[items[selected].parentUniqueId]
  while (parent) {
    parents.push(parent.uniqueId)
    parent = items[parent.parentUniqueId]
  }
  return parents
}

export const FormInputTreeSelection = <T extends FieldValues>({
  name,
  options,
  label,
  rootEquipment,
  required = false,
  isDisabled = false,
  status = 'succeeded',
  customOnChange,
}: FormControls<T> & InputTreeOptions): JSX.Element => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [parents, setParents] = useState<string[]>([rootEquipment.uniqueId])
  const [shownValue, setShownValue] = useState<string>('')

  // update default parent on new root
  useEffect(() => {
    setParents([rootEquipment.uniqueId])
    setShownValue('')
  }, [rootEquipment.uniqueId])

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: { value: required, message: t('field.requiredHelperText') } },
  })

  const id = `${name}-${label}`
  const tree = createTree(Object.values(options), rootEquipment, 'uniqueId', 'parentUniqueId')
  const cancelHandler = (): void => {
    setOpenDialog(false)
  }

  const handleClick = (): void => {
    setOpenDialog(true)
  }

  const popupIcon =
    status === 'loading' ? (
      <CircularProgress color="info" size="1.7142857142857142rem" />
    ) : (
      <Button color="info" onClick={handleClick} disabled={isDisabled}>
        <Typography>{t('field.placeholderSelect')}</Typography>
      </Button>
    )

  const submitHandler = (value: string): void => {
    setOpenDialog(false)
    // assumed root not in options
    field.onChange(value)
    customOnChange?.(value)
    const item = options[value] ?? rootEquipment
    setShownValue(itemLabel(item))

    if (item !== rootEquipment) {
      setParents([rootEquipment.uniqueId, ...getParents(options, value)])
    } else {
      setParents([rootEquipment.uniqueId])
    }
  }

  return (
    <>
      {label && (
        <FormInputLabel
          disabled={isDisabled}
          label={label}
          htmlFor={id}
          requiredText={required ? t('field.required') : undefined}
        />
      )}
      <S.VrTextField
        value={shownValue}
        id={id}
        onBlur={field.onBlur}
        color="info"
        disabled={isDisabled}
        sx={{
          [`& .${inputBaseClasses.input}`]: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        }}
        InputProps={{
          endAdornment: popupIcon,
        }}
        error={!!error?.message}
        helperText={error?.message ? <FormErrorHelperText error={error.message} /> : null}
      />
      <TreePopUp
        submitHandler={submitHandler}
        items={tree}
        expanded={parents}
        open={openDialog}
        cancelHandler={cancelHandler}
      />
    </>
  )
}
