import { Button, Typography } from '@mui/material'
import { TFunction } from 'i18next'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { toggleTaskRowInfoDebug } from '../../actions/system'
import { AppState } from '../../types'
import DevSection from './DevSection'

type Props = {
  t: TFunction
  debugEnabled: boolean
  onDebugToggle: () => void
}

const DebugToggle = ({ t, debugEnabled, onDebugToggle }: Props) => {
  const handleDebugToggle = () => {
    localStorage.setItem('taskRowInfoDebug', debugEnabled ? 'false' : 'true')
    onDebugToggle()
  }

  return (
    <DevSection>
      <Typography variant="body2" sx={{ color: 'text.primary' }}>
        {debugEnabled ? t('debugEnabled') : t('debugDisabled')}
      </Typography>
      <Button variant="submitButtonFullWidth" onClick={() => handleDebugToggle()}>
        <Typography variant="button">
          {debugEnabled ? t('disableDebug') : t('enableDebug')}
        </Typography>
      </Button>
    </DevSection>
  )
}

const mapStateToProps = (state: AppState) => ({
  debugEnabled: state.system.taskRowDebugEnabled,
})

const mapDispatchToProps = {
  onDebugToggle: toggleTaskRowInfoDebug,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DebugToggle))
