import { styled } from '@mui/material/styles'

const DevSection = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing('large'),
  [theme.breakpoints.down('tablet')]: {
    maxWidth: '100%',
  },
  [theme.breakpoints.up('tablet')]: {
    padding: theme.spacing('large'),
  },
  width: `${theme.breakpoints.values.tablet}px`,
}))

export default DevSection
