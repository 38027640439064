import styled from '@emotion/styled'
import { styled as muiStyled } from '@mui/material/styles'

import { defaultTextColor, theme } from '../Theme'

const ErrorText = styled.div`
  ${theme.text('larger', 'decorative', 'bold')};
  color: ${defaultTextColor};
  margin: ${theme.spacing.sizes.huge} 0;
  max-width: 100%;
  text-align: center;
`

export const MuiErrorText = muiStyled('div')(({ theme }) => ({
  ...theme.typography.h4,
  color: 'text.primary',
  margin: `${theme.spacing('huge')} 0`,
  maxWidth: '100%',
  textAlign: 'center',
}))

export const MediumErrorText = muiStyled(MuiErrorText)(({ theme }) => ({
  margin: theme.spacing('large'),
}))

export const SmallErrorText = muiStyled(MuiErrorText)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.warning.main,
  margin: theme.spacing('smaller'),
}))

export default ErrorText
