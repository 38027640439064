import styled from '@emotion/styled'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import moment from '../../lib/moment-fi'
import { roleOrder } from '../../lib/roleOrder'
import { width } from '../../lib/screen'
import { getColor, theme } from '../../Theme'
import { PersonnelList } from '../../types'
import Annotated from '../annotated/Annotated'
import { ExternalLink } from '../Link'
import { DefaultText } from '../Text'
import { Person, Train } from './../../types/Input'

const Flex = styled.div`
  padding-top: 8px;
  ${width() <= 320 ? theme.layout.flexColumn : theme.layout.flexRow};
`

type Props = {
  personnel: PersonnelList
  trainNumber: string
}

const Name = styled(DefaultText)`
  ${theme.text('normal', 'decorative')};
`

const Contact = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`

const Divider = styled.div`
  height: 1px;
  background-color: ${(p) => getColor(p.theme, ['grayLight'], ['grayDark'])};
  width: 100%;
  margin-bottom: 8px;
`

const TrainInfoText = styled.div`
  font-size: 13px;
  padding-top: 4px;
  color: ${(p) => getColor(p.theme, ['black'], ['white'])};
`

const RoleText = styled.div`
  color: grey;
`

const SearchList = ({ personnel, trainNumber }: Props) => {
  const { t } = useTranslation()

  let personsRes: ReactElement[] = []
  roleOrder.forEach((role) => {
    const persons: ReactElement[] = []
    if (Object.keys(personnel).includes(role.name)) {
      personnel[role.name as keyof PersonnelList]?.forEach((person: Person, index: number) => {
        const trainData = person.trains.find((train: Train) => train.number === trainNumber)
        const fromStation = trainData?.from?.station ?? ''
        const toStation = trainData?.to?.station ?? ''

        const fromTimeStamp = trainData?.from?.timestamp
        const toTimeStamp = trainData?.to?.timestamp

        const fromDate = `${fromTimeStamp ? moment(fromTimeStamp).format('DD.MM') : '-'}`
        const fromTime = `${fromTimeStamp ? moment(fromTimeStamp).format('HH.mm') : '-'}`

        const toDate = `${toTimeStamp ? moment(toTimeStamp).format('DD.MM') : '-'}`
        const toTime = `${toTimeStamp ? moment(toTimeStamp).format('HH.mm') : '-'}`

        persons.push(
          <Contact key={person.name}>
            {index !== 0 && <Divider />}
            {index === 0 && <RoleText>{t(`roles.${role.name}`)}</RoleText>}
            <Name>{person.name}</Name>
            <Flex>
              <Annotated title={t('phoneNumber')} horizontal={false} alignStart>
                <ExternalLink href={`tel:${person.phone}`}>{person.phone}</ExternalLink>
              </Annotated>
              <Annotated title={t('email')} horizontal={false} alignStart>
                <ExternalLink href={`mailto:${person.email}`}>{person.email}</ExternalLink>
              </Annotated>
            </Flex>
            <TrainInfoText>{`${fromDate} ${fromStation} (${fromTime}) - 
          ${toDate} ${toStation} (${toTime})`}</TrainInfoText>
          </Contact>
        )
      })
      personsRes = [...personsRes, ...persons]
    }
  })

  return <>{personsRes}</>
}

export default SearchList
