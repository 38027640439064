import { Chip, styled } from '@mui/material'

import { DisruptionLevel } from './types'

interface LabelProps {
  major: boolean
}

const Label = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'major',
})<LabelProps>(({ theme, major }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: '12px',
  color: major ? theme.palette.common.kenttaBlue : theme.palette.text.primary,
}))

type Props = {
  disruptionLevel: DisruptionLevel
}

export const DisruptionLevelIndicator = ({ disruptionLevel }: Props) => {
  return <Label size="small" label={disruptionLevel} major={disruptionLevel === 'VAKAVA'} />
}
