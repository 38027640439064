import styled from '@emotion/styled'
import { Link } from 'found'
import { TFunction } from 'i18next'
import React from 'react'
import { withTranslation } from 'react-i18next'

import More from '../../icons/More'
import Search from '../../icons/Search'
import Train from '../../icons/Train'
import { color, getColor, px, theme, unPx } from '../../Theme'
import { SelectedProps } from '../../types/App'
import FeedbackIcon from '../FeedbackIcon'
import NightMode from './NightMode'

const Container = styled.div`
  ${theme.layout.flexRow};
  ${theme.spacing.right('larger')};
  height: 100%;
  background: ${(p) => getColor(p.theme, ['white'], ['nightBlack'])};
  border-top: 1px solid ${(p) => getColor(p.theme, ['grayBlue'], ['transparent'])};
  justify-content: center;
`

const MENU_ITEMS = 4

const CenterThird = styled(Link)<SelectedProps>`
  display: block;
  text-decoration: none;
  width: ${theme.maxWidths.tab};
  text-align: center;
  ${theme.spacing.ends('smaller')};
  color: ${(p) => color(p.selected ? 'primaryGreen' : 'grayDark')};
  cursor: pointer;
  @media (max-width: ${px(unPx(theme.maxWidths.tab) * MENU_ITEMS)}) {
    width: ${100 / MENU_ITEMS}vw;
  }
`

const MenuText = styled.div`
  ${theme.text('smaller')};
`

type Props = {
  t: TFunction
  current?: string
  maintenance?: boolean
  notification?: boolean
}

type Tabs = Record<string, { icon: React.ReactNode; link: string }>

function MainMenu({ t, current, maintenance, notification }: Props) {
  const iconSize = 25
  const isStart = current === 'start'
  const tabs: Tabs = {
    start: {
      icon: (
        <FeedbackIcon
          color={isStart ? 'primaryGreen' : 'grayDark'}
          notification={notification}
          styledForMainMenu
        />
      ),
      link: '/',
    },
    schedule: { icon: <Train iconSize={iconSize} />, link: '/shifts' },
    search: { icon: <Search iconSize={iconSize} />, link: '/search' },
    options: { icon: <More iconSize={iconSize} />, link: '/options' },
  }

  const tabsForMaintenance: Tabs = {
    search: { icon: <Search iconSize={iconSize} />, link: '/search' },
    options: { icon: <More iconSize={iconSize} />, link: '/options' },
  }

  return (
    <Container>
      {maintenance // workaround for flow
        ? Object.keys(tabsForMaintenance).map((key) => (
            <CenterThird key={key} selected={key === current} to={tabsForMaintenance[key].link}>
              {tabsForMaintenance[key].icon}
              <MenuText>{t(`menu.${key}`)}</MenuText>
            </CenterThird>
          ))
        : Object.keys(tabs).map((key) => (
            <CenterThird key={key} selected={key === current} to={tabs[key].link}>
              {tabs[key].icon}
              <MenuText>{t(`menu.${key}`)}</MenuText>
            </CenterThird>
          ))}
      <NightMode key="4" />
    </Container>
  )
}

export default withTranslation()(MainMenu)
