import styled from '@emotion/styled'
import React, { useState } from 'react'

import { theme } from '../../Theme'
import { ObservationMessage } from '../../types/Input'
import ObservationMessageRow from './ObservationMessageRow'

const ObservationListContainer = styled.div`
  ${theme.spacing.ends('small')};
`

type Props = {
  observationMessages: Array<ObservationMessage>
}

const ObservationMessageList = ({ observationMessages }: Props) => {
  const [openedIndex, setOpenedIndex] = useState<number | null>(null)
  return (
    <ObservationListContainer>
      {observationMessages.map((message, idx) => (
        <ObservationMessageRow
          message={message}
          idx={idx}
          open={openedIndex === idx}
          setOpenedIndex={setOpenedIndex}
          key={`${message.dutyShortName}-${idx}`}
        />
      ))}
    </ObservationListContainer>
  )
}

export default ObservationMessageList
