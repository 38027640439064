import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import moment from '../../lib/moment-fi'
import { selectLatestAssemblyChangeTimestamp, selectTaskAssembly } from '../../Selectors'
import { color, defaultTextColor, LAYOUT_LEFT_EDGE, theme } from '../../Theme'
import { AppState, Assembly, MergedAssembly, Task, TaskLeg, Timestamp } from '../../types'
import { ActiveProps, MergedWagon } from '../../types/App'

interface RowProps {
  hasChange?: boolean
}

const AssemblyChangeHeader = styled.div`
  ${theme.text('mediumSmall')};
  color: ${color('red')};
  padding-left: 11px;
`

const Row = styled.div<RowProps>`
  ${theme.layout.flexRow};
  justify-content: space-between;
  ${theme.text('mediumSmall')};
  color: ${(p) => (p.hasChange ? color('red') : defaultTextColor)};
`

const AssemblyHeader = styled(Row)`
  ${theme.text('normal', 'content', 'bold')};
`

const Column = styled.div`
  min-width: 80px;
  text-align: start;
`

const FirstColumn = styled.div`
  min-width: 100px;
  text-align: start;
  @media (min-width: ${theme.breakpoints.large}) {
    width: 100px;
  }
  ${theme.spacing.right('normal')};
`
const MarginSides = styled.div<ActiveProps>`
  margin: 0 12px 0 ${(p) => `${LAYOUT_LEFT_EDGE - (p.active ? 6 : 1)}px`};

  @media (min-width: ${theme.maxWidths.paddedContent}) {
    margin-left: ${(p) => LAYOUT_LEFT_EDGE + (p.active ? 110 : 115)}px;
  }

  @media (min-width: ${theme.breakpoints.large}) {
    margin-left: ${(p) => LAYOUT_LEFT_EDGE + (p.active ? 130 : 135)}px;
  }
`

const isWagonChanged = (wagon: MergedWagon) => {
  const { salesWagon, operativeWagon } = wagon
  return (
    operativeWagon?.isIncluded &&
    (salesWagon?.bookingNumber !== operativeWagon?.bookingNumber ||
      salesWagon?.type !== operativeWagon?.type)
  )
}

type AssemblyChangesProps = {
  Task?: Task
  open: boolean
  active: boolean
  taskAssembly?: Assembly
  hasChanges?: boolean
  taskLegs?: Array<TaskLeg>
  timestamp?: Timestamp
  t: TFunction
}

const AssemblyChanges = ({
  open,
  active,
  taskLegs,
  hasChanges,
  timestamp,
  t,
}: AssemblyChangesProps) => {
  const wagonHasChange = (wagon: MergedWagon) => isWagonChanged(wagon)

  return (
    <>
      {hasChanges ? (
        <AssemblyChangeHeader>
          {t('assemblyChanged', {
            dateTime: timestamp ? moment(timestamp).format('D.M HH.mm') : '',
          })}
        </AssemblyChangeHeader>
      ) : null}
      {open && (
        <MarginSides active={active}>
          {taskLegs &&
            taskLegs.map((leg) => (
              <div key={`${leg.startOcp}${leg.endOcp}`}>
                <AssemblyHeader>
                  <FirstColumn>
                    {leg.startOcp} - {leg.endOcp}
                  </FirstColumn>
                  <Column>Myynti</Column>
                  <Column>Toteutuma</Column>
                </AssemblyHeader>
                {leg.wagons.map((wagon, i) => (
                  <Row
                    key={`${wagon?.salesWagon?.bookingNumber || i}-${
                      wagon?.operativeWagon?.bookingNumber || i
                    }`}
                    hasChange={wagonHasChange(wagon)}
                  >
                    <FirstColumn>{i + 1}.</FirstColumn>
                    <Column>
                      {wagon.salesWagon
                        ? `${wagon.salesWagon.bookingNumber} ${wagon.salesWagon.type}`
                        : '-'}
                    </Column>
                    <Column>
                      {wagon.operativeWagon
                        ? `${wagon.operativeWagon.bookingNumber} ${wagon.operativeWagon.type}`
                        : '-'}
                    </Column>
                  </Row>
                ))}
              </div>
            ))}
        </MarginSides>
      )}
    </>
  )
}

const mapStateToProps = (state: AppState, { task }: { task: Task }) => {
  const assembliesById = state.assemblies.byId
  const taskAssembly: MergedAssembly | null = selectTaskAssembly(assembliesById, task)
  const taskLegs = taskAssembly?.legs
  const hasChanges =
    taskLegs && taskLegs.some((leg) => leg.wagons.some((wagon) => isWagonChanged(wagon)))

  const timestamp = selectLatestAssemblyChangeTimestamp(taskLegs)?.lastUpdated

  return {
    taskLegs,
    hasChanges,
    timestamp,
  }
}

export default connect(mapStateToProps, null)(withTranslation()(AssemblyChanges))
