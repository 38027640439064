import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React from 'react'

import { color } from '../../Theme'
import { Defect } from '../../types'
import { Status } from '../create-defect/types'
import DefectsRow from './DefectsRow'

type Props = {
  t: TFunction
  defects: Array<Defect>
  nightMode: boolean
  status: Status
}

type ListContainerProps = {
  status: Status
  nightMode?: boolean
}

const ListContainer = styled.div<ListContainerProps>`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  ${(p) => p.status === 'closed' && `color: ${color(p.nightMode ? 'nightGray' : 'grayDark')}`};
`

const DefectsList = ({ defects, t, nightMode, status }: Props): JSX.Element => {
  return (
    <ListContainer status={status} nightMode={nightMode}>
      {defects.map((defect) => (
        <DefectsRow key={defect.id} defect={defect} t={t} nightMode={nightMode} status={status} />
      ))}
    </ListContainer>
  )
}

export default DefectsList
