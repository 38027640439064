import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Autocomplete, autocompleteClasses, CircularProgress, Paper } from '@mui/material'
import React from 'react'
import { FieldValues, useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import findValueInOptions from '../../../lib/createDefectFormUtils'
import * as S from '../component.styles'
import { FormErrorHelperText } from '../FormErrorHelperText'
import { AutoCompleteOptions, FormControls } from '../types'

export const FormInputAutocomplete = <T extends FieldValues>({
  name,
  options,
  label,
  required = false,
  isDisabled = false,
  status = 'succeeded',
  multiline = false,
  customOnChange,
}: FormControls<T> & AutoCompleteOptions): JSX.Element => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: { value: required, message: t('field.requiredHelperText') } },
  })

  const id = `${name}-${label}`
  const popupIcon =
    status === 'loading' ? (
      <CircularProgress size="1em" color="info" />
    ) : (
      <ExpandMoreIcon color={isDisabled ? 'disabled' : 'info'} />
    )

  const formattedLabel = required ? `${label} ${t('field.required')}` : label

  return (
    <Autocomplete
      value={findValueInOptions(field.value, options)}
      disabled={isDisabled}
      popupIcon={popupIcon}
      onBlur={field.onBlur}
      disablePortal
      id={id}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(_event, newValue) => {
        field.onChange(newValue?.value ?? '')
        if (customOnChange) {
          customOnChange(newValue?.value)
        }
      }}
      sx={{
        flex: '1 1 0',
      }}
      PaperComponent={(props) => (
        <Paper
          sx={(theme) =>
            S.DropdownItemCss(theme, `& .${autocompleteClasses.option}`, '&[aria-selected="true"]')
          }
          {...props}
        />
      )}
      options={options}
      renderInput={(params) => (
        <S.VrTextField
          label={formattedLabel}
          error={!!error?.message}
          helperText={error?.message ? <FormErrorHelperText error={error.message} /> : null}
          placeholder={t('field.placeholderSelect')}
          multiline={multiline}
          {...params}
        />
      )}
    />
  )
}
