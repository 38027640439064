import 'react-datepicker/dist/react-datepicker.css'

import styled from '@emotion/styled'
import fi from 'date-fns/locale/fi'
import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import moment from '../../lib/moment-fi'
import { getColor } from '../../Theme'
import { AppState } from '../../types'
import { TimeTableSearchState } from '../../types/States'
import LoadingIndicator from '../LoadingIndicator'
import DateContainer from '../search/DateContainer'
import SearchBox from '../search/SearchBox'
import SearchButton from '../search/SearchButton'
import SearchContainer from '../search/SearchContainer'
import SearchHeader from '../search/SearchHeader'
import SearchInput from '../search/SearchInput'
import SearchPageHeader from '../search/SearchPageHeader'
import BackupTimetable from './BackupTimetable'
import YesNoButtons from './YesNoButtons'

const Container = styled.div`
  padding-top: 16px;
  width: 100%;
`
const UseForDriving = styled.div`
  color: ${(p) => getColor(p.theme, ['black'], ['white'])};
`

type Props = {
  activateDate: () => void
  activateTrainNumber: () => void
  allowUsedForDrivingQuery?: boolean
  changeDate: (date: Date | null) => void
  changeTrainNumber: (value: string) => void
  changeUseForDriving: (isUsedForDriving: boolean) => void
  fetchTimetable: () => void
  loading: boolean
  openCalendar: (arg0: boolean) => void
  setError: (arg0: string) => void
  state: TimeTableSearchState
}

const _TimetableSearch = ({
  activateTrainNumber,
  changeTrainNumber,
  activateDate,
  openCalendar,
  state,
  changeDate,
  loading,
  fetchTimetable,
  changeUseForDriving,
  setError,
  allowUsedForDrivingQuery,
}: Props) => {
  const { t } = useTranslation()
  const { date, dateActive, trainNumber, trainNumberActive, isUsedForDriving } = state

  const validate = () => {
    if (trainNumber !== '') return true
    return false
  }

  registerLocale('fi', fi)

  return (
    <div
      onKeyDown={
        validate()
          ? (ev) => {
              return ev.keyCode === 13 && fetchTimetable()
            }
          : undefined
      }
    >
      <SearchPageHeader name="download" text={t('searchTimetable')} />
      <SearchContainer>
        <SearchBox
          marginRight="8px"
          onClick={activateTrainNumber}
          active={trainNumberActive}
          disabled={false}
          disableWidth={true}
        >
          <SearchHeader>{t('trainNumber')}</SearchHeader>
          <SearchInput
            type="number"
            value={trainNumber}
            onChange={(event) => changeTrainNumber(event.target.value)}
            disabled={false}
          />
        </SearchBox>
        <SearchBox marginLeft="8px" disableWidth onClick={activateDate} active={dateActive}>
          <SearchHeader>{t('startDay')}</SearchHeader>
          <SearchInput
            type="text"
            readOnly
            onClick={() => openCalendar(true)}
            value={date}
            onChange={() => null}
          />
        </SearchBox>
      </SearchContainer>
      {dateActive && (
        <DateContainer>
          <DatePicker
            inline
            locale="fi"
            readOnly
            selected={moment(date, 'DD.MM.YYYY').toDate()}
            onChange={(value) => changeDate(value)}
          />
        </DateContainer>
      )}
      {allowUsedForDrivingQuery && (
        <Container>
          <UseForDriving>{t('useForDriving')}</UseForDriving>
          <YesNoButtons t={t} customSelected={isUsedForDriving} onClick={changeUseForDriving} />
        </Container>
      )}
      {loading ? (
        <LoadingIndicator size="normal" padded={true} />
      ) : (
        <SearchButton
          t={t}
          validate={validate}
          setError={setError}
          errorKey="trainNumberMandatory"
          onClick={fetchTimetable}
        />
      )}
      <BackupTimetable t={t} />
    </div>
  )
}

const mapStateToProps = (state: AppState): unknown => {
  return {
    allowUsedForDrivingQuery:
      state.user.admin ||
      state.user.commuter_driver ||
      state.user.commuter_manager ||
      state.user.driver ||
      state.user.logistics_driver,
  }
}

const TimetableSearch = connect(mapStateToProps)(_TimetableSearch)

export default TimetableSearch
