import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useParams } from 'found'
import { useTranslation } from 'react-i18next'

import { useTypedDispatch } from '../../../'
import { updateActionValueAction } from '../../../actions/serviceOrders/actions'
import { Action, ActionPayload } from '../types'
import CompleteTaskButton from './input/CompleteTaskButton'
import Measurement from './Measurement'

const ActionContent = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  background: theme.palette.background.default,
  ...theme.layout.flexColumn,
  alignItems: 'flex-start',
  padding: '8px',
  flexBasis: '100%',
  marginBottom: theme.spacing('small'),
}))

type Props = {
  parentTaskCode: string
  containsNotMeasured: boolean
  containsDefective: boolean
  wbNotStarted: boolean
  action: Action
}

const ActionRow = ({
  parentTaskCode,
  containsNotMeasured,
  containsDefective,
  wbNotStarted,
  action,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const { orderId, activityId, taskId } = useParams()
  const dispatch = useTypedDispatch()

  const updateAction = (
    orderId: string,
    activityId: string,
    taskId: string,
    actionId: string,
    payload: ActionPayload
  ) =>
    dispatch(
      updateActionValueAction({
        params: { orderId, activityId, taskId },
        actionId,
        payload,
      })
    )

  const handleUpdate = (actionId: string, payload: ActionPayload) => {
    updateAction(orderId, activityId, taskId, actionId, payload)
  }

  return (
    <>
      {parentTaskCode !== action.measurementType.id ? (
        <ActionContent key={action.measurementType.id}>
          <Measurement
            wbNotStarted={wbNotStarted}
            measurement={action.measurementType}
            action={action}
            updateAction={handleUpdate}
          />
          {action.position && (
            <Typography>
              {t('serviceOrder.actions.list.position', { position: action.position })}
            </Typography>
          )}
        </ActionContent>
      ) : (
        <CompleteTaskButton
          key={action.measurementType.id}
          action={action}
          containsNotMeasured={containsNotMeasured}
          containsDefective={containsDefective}
          measurement={action.measurementType}
          updateAction={handleUpdate}
        />
      )}
    </>
  )
}

export default ActionRow
