import { TFunction } from 'i18next'
import React from 'react'
import { withTranslation } from 'react-i18next'

import { MOMENT_SHIFT_DATE_FORMAT } from '../../constants'
import ArrowLeft from '../../icons/ArrowLeft'
import Close from '../../icons/Close'
import Refresh from '../../icons/Refresh'
import moment from '../../lib/moment-fi'
import { scheduleURL } from '../../lib/url'
import { Shift } from '../../types'
import HeaderContainer from '../header/HeaderContainer'
import HeaderLink, { HeaderAction } from '../header/HeaderLink'
import HeaderTitle from '../header/HeaderTitle'

type Props = {
  t: TFunction
  shift: Shift
  reloading?: boolean
  onReload: () => void
}

function ShiftHeader({ t, shift, reloading, onReload }: Props) {
  return (
    <HeaderContainer border={true}>
      <>
        <HeaderLink to={scheduleURL()}>
          <ArrowLeft iconSize="normal" />
        </HeaderLink>
        <HeaderTitle
          title={t(`shiftNumber`, { number: shift.shiftId })}
          subtitle={moment(shift.startDateTime).format(MOMENT_SHIFT_DATE_FORMAT)}
        />
        <HeaderAction onClick={onReload} loading={reloading}>
          <Refresh iconSize="normal" />
        </HeaderAction>
        <HeaderLink to={scheduleURL()}>
          <Close iconSize="normal" />
        </HeaderLink>
      </>
    </HeaderContainer>
  )
}
export default withTranslation()(ShiftHeader)
