import styled from '@emotion/styled'
import React from 'react'

import { getColor, px, theme, unPx } from '../../Theme'

const Container = styled.div`
  @media (max-width: ${theme.maxWidths.column}) {
    display: none;
  }
`

const Line = styled.div`
  width: ${theme.spacing.sizes.smallest};
  margin-right: ${theme.timeline.paddingLeftBig};
  @media (max-width: ${theme.breakpoints.medium}) {
    margin-right: ${theme.timeline.paddingLeftSmall};
  }
`

const DashedLine = styled(Line)`
  height: ${theme.timeline.dashingHeight};
  background-image: linear-gradient(
    ${(p) => getColor(p.theme, ['shallowBlue'], ['nightGray'])} 66%,
    transparent 0%
  );
  background-position: right;
  background-size: ${theme.spacing.sizes.smallest} ${theme.spacing.sizes.normal};
  background-repeat: repeat-y;
`

const VerticalLine = styled(Line)`
  height: calc(100% - ${px(unPx(theme.timeline.dashingHeight) * 2)});
  background: ${(p) => getColor(p.theme, ['shallowBlue'], ['nightGray'])};
`

function Timeline() {
  return (
    <Container>
      <DashedLine />
      <VerticalLine />
      <DashedLine />
    </Container>
  )
}
export default Timeline
