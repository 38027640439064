import styled from '@emotion/styled'
import React from 'react'

import { MOMENT_REST_TIME_FORMAT } from '../../constants'
import moment from '../../lib/moment-fi'
import { formatDuration } from '../../lib/time'
import { color, MaybeColor, theme } from '../../Theme'
import { ColorProps } from '../../types/App'
import { Timestamp } from '../../types/Common'
import { EmphasisNormal } from '../Emphasis'

const Emphasis = styled(EmphasisNormal)`
  color: ${(p) => color(p.color, 'black')};
`

const DeEmphasis = styled.div<ColorProps>`
  ${theme.text('small')}
  color: ${(p) => color(p.color, 'grayDark')};
`

type Props = {
  start: Timestamp
  end: Timestamp
  color: MaybeColor
}

function TaskDuration({ start, end, color }: Props) {
  const startTs = moment(start).format(MOMENT_REST_TIME_FORMAT)
  const endTs = moment(end).format(MOMENT_REST_TIME_FORMAT)
  const duration = formatDuration(moment(start).diff(moment(end)))
  return (
    <div>
      <Emphasis color={color}>
        <DeEmphasis color={color}>
          {startTs}&nbsp;– {endTs} ({duration})
        </DeEmphasis>
      </Emphasis>
    </div>
  )
}

export default TaskDuration
