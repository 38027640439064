function accumulateResults<T>(promise: Promise<T>, results: Array<T> = []): Promise<Array<T>> {
  return promise.then((result: T) => results.concat(result))
}

// Applies array of params to fn sequentially
// so that next params are applied once previous have succeeded
// function fn should return promise
function sequentially<T>(params: Array<T>, fn: (arg0: unknown) => Promise<T>): Promise<Array<T>> {
  const [first, ...rest] = params

  if (first === undefined) {
    return Promise.resolve([]) as Promise<Array<T>>
  }

  return rest.reduce(
    (promise: Promise<Array<T>>, param: unknown) =>
      promise.then((results: Array<T>) => accumulateResults(fn(param), results)),
    accumulateResults(fn(first))
  )
}

export { accumulateResults, sequentially }
