import { Actions, BrowserProtocol, createHistoryEnhancer, queryMiddleware } from 'farce'
import { createMatchEnhancer, foundReducer, Matcher, RouteConfig } from 'found'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import config from './config'
import amendmentReducer from './reducers/Amendment'
import assemblyReducer from './reducers/Assembly'
import calendarReducer from './reducers/Calendar'
import causesReducer from './reducers/Causes'
import compositionsReducer from './reducers/Compositions'
import contactsReducer from './reducers/Contacts'
import createDefectSlice from './reducers/createDefectSlice'
import crewNoticeReducer from './reducers/CrewNotice'
import customerFeedbackReducer from './reducers/CustomerFeedback'
import defectCommonDataSlice from './reducers/defectCommonDataSlice'
import defectsSlice from './reducers/defectsSlice'
import energyEfficiencyReducer from './reducers/EnergyEfficiency'
import feedbacksReducer from './reducers/Feedbacks'
import findingsReducer from './reducers/Findings'
import handlingStationsReducer from './reducers/handlingStations'
import incidentsSlice from './reducers/incidentsSlice'
import mainPageReducer from './reducers/MainPage'
import newsReducer from './reducers/News'
import observationMessagesReducer from './reducers/Observationmessages'
import personnelPageReducer from './reducers/Personnel'
import phoneContactsReducer from './reducers/PhoneContacts'
import punctualityReducer from './reducers/Punctuality'
import responseReadsReducer from './reducers/ResponseRead'
import rollingGuidesReducer from './reducers/RollingGuides'
import schedulePageReducer from './reducers/SchedulePage'
import searchedShiftsReducer from './reducers/SearchedShifts'
import serviceOrderActionsSlice from './reducers/serviceOrders/actionsSlice'
import serviceOrdersSlice from './reducers/serviceOrders/serviceOrdersSlice'
import shiftDetailsReducer from './reducers/ShiftDetails'
import shiftNoticesReducer from './reducers/ShiftNotice'
import shiftPageReducer from './reducers/ShiftPage'
import shiftsReducer from './reducers/Shifts'
import signInStatusReducer from './reducers/SignInStatus'
import smDisruptionSlice from './reducers/smDisruptionSlice'
import systemReducer from './reducers/System'
import testPushMessagesReducer from './reducers/TestPushMessages'
import timetableReducer from './reducers/Timetable'
import towingFormReducer from './reducers/TowingForm'
import towingFormStateReducer from './reducers/TowingFormState'
import towingVehicleReducer from './reducers/TowingVehicle'
import towingPatternsReducer from './reducers/TowingVehiclePatterns'
import userReducer from './reducers/User'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const enhancedCompose =
  (config.environment !== 'prod' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const initStore = (routeConfig: RouteConfig) => {
  const store = createStore(
    combineReducers({
      found: foundReducer,
      system: systemReducer,
      shifts: shiftsReducer,
      shiftDetails: shiftDetailsReducer,
      signInStatuses: signInStatusReducer,
      responseReads: responseReadsReducer,
      feedbacks: feedbacksReducer,
      user: userReducer,
      mainPage: mainPageReducer,
      schedulePage: schedulePageReducer,
      shiftPage: shiftPageReducer,
      personnel: personnelPageReducer,
      searchedShifts: searchedShiftsReducer,
      findings: findingsReducer,
      compositions: compositionsReducer,
      timetable: timetableReducer,
      calendar: calendarReducer,
      punctuality: punctualityReducer,
      phoneContacts: phoneContactsReducer,
      causes: causesReducer,
      amendment: amendmentReducer,
      crewNotices: crewNoticeReducer,
      shiftNotices: shiftNoticesReducer,
      contacts: contactsReducer,
      customerFeedback: customerFeedbackReducer,
      observationMessages: observationMessagesReducer,
      testPushMessages: testPushMessagesReducer,
      handlingStations: handlingStationsReducer,
      towingForm: towingFormReducer,
      towingFormState: towingFormStateReducer,
      towingSearch: towingVehicleReducer,
      towingPatterns: towingPatternsReducer,
      assemblies: assemblyReducer,
      energyEfficiency: energyEfficiencyReducer,
      rollingGuides: rollingGuidesReducer,
      news: newsReducer,
      defects: defectsSlice,
      defect: createDefectSlice,
      defectCommonData: defectCommonDataSlice,
      serviceOrders: serviceOrdersSlice,
      serviceOrderActions: serviceOrderActionsSlice,
      incidents: incidentsSlice,
      smDisruptions: smDisruptionSlice,
    }),
    enhancedCompose(
      applyMiddleware(thunk),
      createHistoryEnhancer({
        protocol: new BrowserProtocol(),
        middlewares: [queryMiddleware],
      }),
      createMatchEnhancer(new Matcher(routeConfig))
    )
  )

  store.dispatch(Actions.init())
  return store
}

export default initStore
