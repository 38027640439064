import { createAsyncThunk } from '@reduxjs/toolkit'

import { LiitoIncident } from '../../components/incidents/types'
import { apiGET } from '../../lib/api'

interface IncidentsError {
  message: string
}

const fetchIncidentsEnabled = (): Promise<IncidentsEnabledResponseWrapper> =>
  apiGET('incidentsEnabled')

interface IncidentsEnabledResponseWrapper {
  incidentsEnabled: boolean
}

export const fetchIncidentsEnabledAction = createAsyncThunk<
  boolean,
  void,
  { rejectValue: IncidentsError }
>('incidents/fetchIncidentsEnabled', async (none, thunkApi) => {
  try {
    const response = await fetchIncidentsEnabled()
    return response.incidentsEnabled
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: 'Failed to fetch incidents allowed',
    })
  }
})

const fetchIncidents = (): Promise<LiitoIncident[]> => apiGET('incidents')

export const fetchIncidentsAction = createAsyncThunk<
  LiitoIncident[],
  void,
  { rejectValue: IncidentsError }
>('incidents/fetchIncidents', async (_: void, thunkApi) => {
  try {
    return await fetchIncidents()
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: 'Failed to fetch incidents',
    })
  }
})

export interface IncidentsTrainFilter {
  trainNumber: string
  operatingDay: string
}

const fetchIncidentsForTrain = (params: IncidentsTrainFilter): Promise<LiitoIncident[]> =>
  apiGET(`incidentsForTrain?trainNumber=${params.trainNumber}&operatingDay=${params.operatingDay}`)

export const fetchIncidentsForTrainAction = createAsyncThunk<
  LiitoIncident[],
  IncidentsTrainFilter,
  { rejectValue: IncidentsError }
>('incidents/fetchIncidentsForTrain', async (incidentsFilter, thunkApi) => {
  try {
    return await fetchIncidentsForTrain(incidentsFilter)
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: `Failed to fetch incidents for trainNumber: ${incidentsFilter.trainNumber} and operatingDay: ${incidentsFilter.operatingDay}`,
    })
  }
})
