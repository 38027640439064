import styled from '@emotion/styled'
import { useRouter } from 'found'
import { ReactNode } from 'react'

import { useTypedSelector } from '../../'
import ArrowLeft from '../../icons/ArrowLeft'
import Compositions from '../../icons/Compositions'
import Defect from '../../icons/Defect'
import Download from '../../icons/Download'
import Personnel from '../../icons/Personnel'
import Phone from '../../icons/Phone'
import ServiceOrder from '../../icons/ServiceOrders'
import Shift from '../../icons/Shift'
import Stopwatch from '../../icons/Stopwatch'
import Towing from '../../icons/Towing'
import { color, getColor, theme } from '../../Theme'
import { HeaderText, PageHeader } from '../page/PageComponents'

type SearchPageHeaderProps = {
  name: string
  text: string
}

type Icon = Record<string, ReactNode>

const Constrain = styled.div`
  ${theme.layout.flexRow};
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  position: relative;
  box-sizing: border-box;
  width: 100%;
`

const HeaderIcon = styled.div`
  align-content: start;
  display: flex;
`

const BackIcon = styled(HeaderIcon)`
  padding-left: 24px;
  color: ${(p) => getColor(p.theme, ['grayDark'], ['white'])};
`

const IllustrativeIcon = styled(HeaderIcon)`
  padding-right: 24px;
  color: ${color('primaryGreen')};
`

const icon: Icon = {
  personnel: <Personnel />,
  compositions: <Compositions width="44" />,
  defect: <Defect />,
  shift: <Shift />,
  download: <Download />,
  phone: <Phone />,
  stopwatch: <Stopwatch />,
  towing: <Towing />,
  serviceOrders: <ServiceOrder />,
}

const SearchPageHeader = ({ name, text }: SearchPageHeaderProps) => {
  const { router } = useRouter()
  const found = useTypedSelector((state) => state.found)

  return (
    <PageHeader
      onClick={() => {
        if (found?.match?.location?.delta > 0) router.go(-1)
        else router.push('/search')
      }}
      height="64px"
    >
      <Constrain>
        <BackIcon>
          <ArrowLeft iconSize="normal" />
        </BackIcon>
        <HeaderText>{text}</HeaderText>
        <IllustrativeIcon>{icon[name]}</IllustrativeIcon>
      </Constrain>
    </PageHeader>
  )
}

export default SearchPageHeader
