import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React, { HTMLAttributes } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { signInToShift } from '../../actions/api'
import { ShiftPageAction } from '../../actions/shiftpage'
import { MOMENT_TIME_FORMAT } from '../../constants'
import ArrowRight from '../../icons/ArrowRight'
import moment from '../../lib/moment-fi'
import { formatDuration } from '../../lib/time'
import { shiftURL, visitURL } from '../../lib/url'
import {
  nightModeSelector,
  nowSelector,
  shiftCanSignIn,
  shiftIsPassed,
  shiftSignInStatus,
} from '../../Selectors'
import { Color, color, defaultTextColor, getColor, MaybeColor, px, theme } from '../../Theme'
import { Action, AppState, Dispatch, Shift } from '../../types'
import { ColorProps, PastProps } from '../../types/App'
import { TrainPunctuality } from '../../types/Input'
import Button from '../button/Button'
import CircleToggle from '../circle/CircleToggle'
import { EmphasisNormal } from '../Emphasis'
import LoadingIndicator from '../LoadingIndicator'
import Text from '../Text'
import DurationIcon from './DurationIcon'

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  signedIn: boolean
  past: boolean
  color: MaybeColor
  isCommuter?: boolean
  height: number
}

const Row = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  display: flex;
  margin-top: ${theme.spacing.sizes.small};
  min-width: ${theme.maxWidths.column};
`

const Container = styled.div<ContainerProps>`
  ${theme.spacing.all('small')};
  ${theme.spacing.right('biggest')};
  ${(p) => (p.past ? '' : theme.effects.shadow)};
  position: relative;
  flex: 1;
  color: ${(p) => color(p.color)};
  background-color: ${(p) => {
    if (p.past) {
      return getColor(p.theme, ['grayBlue'], ['nightBlack'])
    }
    if (p.signedIn) {
      return color('secondaryGreen')
    }
    return getColor(p.theme, ['white'], ['nightHighlight'])
  }};
  border-radius: ${theme.borderRadius.button};
  margin-right: ${theme.spacing.sizes.small};
  height: ${(p) => (p.past || p.isCommuter ? 'auto' : px(p.height))};
`

const ShiftDate = styled.div<{ position: number }>`
  ${theme.spacing.left('tiny')};
  ${theme.text('larger', 'content')};
  color: ${defaultTextColor};
  margin-top: ${(p) => px(p.position)};
  text-align: left;
  width: ${theme.spacing.sizes.huge};
`

const ShiftDay = styled.div`
  ${theme.text('large', 'content')};
  color: ${defaultTextColor};
  text-align: left;
`

const Flex = styled.div`
  ${theme.layout.flexRow};
`

const FlexRow = styled.div`
  ${theme.layout.flexRow};
  flex: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
  position: relative;
`

const Title = styled.div<ColorProps>`
  ${theme.text('normal', 'decorative', 'bold')};
  color: ${(p) => color(p.color)};
`

const PaddingRight = styled.div`
  padding-right: 2px;
  ${theme.spacing.right('small')};
`

const ShiftLink = styled.div<PastProps>`
  position: absolute;
  top: 20%;
  right: ${theme.spacing.sizes.small};
`

const SignInButton = styled(Button)`
  ${theme.layout.flexRow};
  ${theme.text('normal', 'decorative', 'bold')};
  ${theme.spacing.ends('small')};
  background: ${color('primaryBlue')};
  bottom: 0;
  box-sizing: border-box;
  color: ${color('white')};
  height: ${theme.spacing.sizes.huge};
  left: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
`

const ClickTarget = styled.div`
  cursor: pointer;
`

type ShiftSearchFunction = () => Action

type Props = {
  t: TFunction
  shift: Shift
  nightMode: boolean
  openShift: () => void
  signInToShift: () => void
  startDateTime: string
  endDateTime: string
  past: boolean
  signedIn: boolean
  signingIn: boolean
  canSignIn: boolean
  preparation: string
  wrapUp: string
  punctualities: Array<TrainPunctuality>
  height: number
  duration: string
  search?: ShiftSearchFunction
  searchShift: () => void
  hideArrow?: boolean
}

const CalendarShift = ({
  t,
  nightMode,
  shift,
  openShift,
  signInToShift,
  startDateTime,
  endDateTime,
  past,
  canSignIn,
  signingIn,
  signedIn,
  preparation,
  wrapUp,
  punctualities,
  height,
  duration,
  search,
  searchShift,
  hideArrow,
}: Props) => {
  let textColor: Color = nightMode ? 'grayPink' : 'black'
  if (past) {
    textColor = 'grayDark'
  } else if (signedIn) {
    textColor = 'white'
  }

  return (
    <Row>
      <ShiftDate position={0}>
        <ShiftDay>
          {(shift.scheduleStartTime
            ? moment(shift.scheduleStartTime)
            : moment(shift.startDateTime)
          ).format('dd')}
        </ShiftDay>
        {(shift.scheduleStartTime
          ? moment(shift.scheduleStartTime)
          : moment(shift.startDateTime)
        ).format('DD')}
      </ShiftDate>
      <Container
        height={height}
        isCommuter={shift.isCommuter}
        past={past}
        signedIn={signedIn}
        color={textColor}
      >
        <ClickTarget onClick={search ? searchShift : openShift}>
          <Title color={textColor}>{t('shiftNumber', { number: shift.shiftId })}</Title>
          <FlexRow>
            <Text color={textColor}>
              {startDateTime} {preparation ? `(${preparation})` : undefined} -&nbsp;
            </Text>
            <PaddingRight>
              <Text color={textColor}>
                {endDateTime} {wrapUp ? `(${wrapUp})` : undefined}
              </Text>
            </PaddingRight>
            <Flex>
              <PaddingRight>
                <DurationIcon color={textColor} />
              </PaddingRight>
              <EmphasisNormal color={textColor}>{duration}</EmphasisNormal>
            </Flex>
          </FlexRow>
        </ClickTarget>
        {!hideArrow && (
          <ShiftLink past={past}>
            <CircleToggle
              icon={<ArrowRight iconSize="smallest" />}
              left="12px"
              onClick={search ? searchShift : openShift}
              color={signedIn && !past ? 'green' : 'blue'}
            />
          </ShiftLink>
        )}
        {!shift.isCommuter && punctualities.length > 0 && (
          <EmphasisNormal color={textColor}>
            {punctualities[0].trainType} {punctualities[0].trainNumber}
          </EmphasisNormal>
        )}
        {!search && (canSignIn || signingIn) ? (
          <SignInButton topOpen={true} bottomOpen={false} onClick={signInToShift}>
            {signingIn ? <LoadingIndicator size="small" padded={false} /> : undefined}
            {signingIn ? t('signingIn') : t('signIn')}
          </SignInButton>
        ) : undefined}
      </Container>
    </Row>
  )
}

type PropsIn = {
  shift: Shift
  search?: ShiftSearchFunction
}

const mapStateToProps = (state: AppState, { shift, search }: PropsIn) => {
  const now = nowSelector(state)
  const startsAtM = moment(shift.scheduleStartTime || shift.startDateTime)
  const endsAtM = moment(shift.scheduleEndTime || shift.endDateTime)

  if (shift?.tasks?.length) {
    const preparationMinutes = shift.tasks[0].preparationMinutes
    const wrapUpMinutes = shift.tasks[shift.tasks.length - 1].wrapUpMinutes

    if (preparationMinutes) {
      startsAtM.subtract(preparationMinutes, 'minutes')
    }

    if (wrapUpMinutes) {
      endsAtM.add(wrapUpMinutes, 'minutes')
    }
  }

  const past = shiftIsPassed(now, shift)
  const startDateTime = startsAtM.format(MOMENT_TIME_FORMAT)
  const endDateTime = endsAtM.format(MOMENT_TIME_FORMAT)
  const signedInStatus = shiftSignInStatus(state)(shift.id)

  const canSignIn = shiftCanSignIn(now, shift, signedInStatus, startsAtM)
  const signedIn = signedInStatus.state === 'signed-in'
  const signingIn = signedInStatus.loading

  const preparationTime = shift.schedulePreparation || shift.preparation
  const wrapUpTime = shift.scheduleWrapUp || shift.wrapUp
  const preparation =
    formatDuration(preparationTime) || formatDuration(parseInt(preparationTime) * 60000)
  const wrapUp = formatDuration(wrapUpTime) || formatDuration(parseInt(wrapUpTime) * 60000)
  const duration = shift.partDuration
    ? formatDuration(shift.partDuration)
    : formatDuration(shift.duration)
  const height = 90 + (!search && (canSignIn || signingIn) ? 30 : 0)

  const keys =
    shift.tasks && shift.tasks.length > 0
      ? shift.tasks
          .filter(
            (t) =>
              t.trainNumberNumeric !== undefined &&
              moment(t.taskStartDateTime).isBefore(moment()) &&
              moment(t.taskEndDateTime).isAfter(moment())
          )
          .map((t) => `${t.trainNumberNumeric}+${moment(t.taskStartDateTime).format('YYYY-MM-DD')}`)
      : []
  const uniqueKeys: string[] = []
  keys.forEach((p) => {
    uniqueKeys.indexOf(p) < 0 && uniqueKeys.push(p)
  })
  const punctualities = uniqueKeys
    .map((k) => state.punctuality.punctualityByTrain[k])
    .filter((p) => p !== undefined)

  return {
    past,
    startDateTime,
    endDateTime,
    canSignIn,
    signedIn,
    signingIn,
    preparation,
    wrapUp,
    duration,
    punctualities,
    height,
    nightMode: nightModeSelector(state),
    search,
  }
}

CalendarShift.displayName = 'CalendarShift'

const mapDispatchToProps = (
  dispatch: Dispatch,
  { shift, search }: { shift: Shift; search?: ShiftSearchFunction }
) => {
  const signingIn = shift.signInStatus === 'signing-in'

  return {
    openShift: (/*ev*/) => dispatch(visitURL(shiftURL(shift.id)) as unknown as ShiftPageAction),
    signInToShift: (/*ev*/) => (signingIn ? null : signInToShift(shift)(dispatch)),
    searchShift: (/*ev*/) => (search ? dispatch(search as unknown as Action) : null),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CalendarShift))
