import { PhoneContactGroup } from '../types'

export type UpdatePhoneContacts = {
  type: 'UPDATE_PHONE_CONTACTS'
  payload: Array<PhoneContactGroup>
}
export const UPDATE_PHONE_CONTACTS = 'UPDATE_PHONE_CONTACTS'
export const updatePhoneContacts = (contacts: Array<PhoneContactGroup>): UpdatePhoneContacts => ({
  type: UPDATE_PHONE_CONTACTS,
  payload: contacts,
})

export type SetPhoneContactsError = {
  type: 'PHONE_CONTACTS_ERROR'
  payload: string
}
export const PHONE_CONTACTS_ERROR = 'PHONE_CONTACTS_ERROR'
export const phoneContactsFetchingError = (error: string): SetPhoneContactsError => ({
  type: PHONE_CONTACTS_ERROR,
  payload: error,
})

export type FetchPhoneContacts = {
  type: 'START_FETCHING_PHONE_CONTACTS'
}
export const START_FETCHING_PHONE_CONTACTS = 'START_FETCHING_PHONE_CONTACTS'
export const startFetchingPhoneContacts = (): FetchPhoneContacts => ({
  type: START_FETCHING_PHONE_CONTACTS,
})

export type PhoneContactsAction = UpdatePhoneContacts | SetPhoneContactsError | FetchPhoneContacts
