import {
  CLEAR_HANDLING_STATIONS,
  START_FETCHING_STATIONS,
  STATIONS_ERROR,
  UPDATE_STATIONS,
} from '../actions/handlingStations'
import { HandlingStationsAction } from '../actions/handlingStations'
import { HandlingStationsState } from '../types'

export const initialState = {
  loading: false,
  error: '',
  stations: [],
}

const handlingStationsReducer = (
  state: HandlingStationsState = initialState,
  action: HandlingStationsAction
): HandlingStationsState => {
  switch (action.type) {
    case START_FETCHING_STATIONS:
      return {
        ...state,
        loading: true,
        stations: [],
      }

    case UPDATE_STATIONS:
      return {
        ...state,
        error: '',
        loading: false,
        stations: action.payload,
      }

    case STATIONS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CLEAR_HANDLING_STATIONS:
      return initialState

    default:
      return state
  }
}

export default handlingStationsReducer
