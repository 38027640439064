import { Box, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useTypedSelector } from '..'
import Page from '../components/page/Page'
import SearchToolCard from '../components/search/SearchToolCard'
import Compositions from '../icons/Compositions'
import Defect from '../icons/Defect'
import Download from '../icons/Download'
import Personnel from '../icons/Personnel'
import Phone from '../icons/Phone'
import ServiceOrder from '../icons/ServiceOrders'
import Shift from '../icons/Shift'
import Stopwatch from '../icons/Stopwatch'
import Towing from '../icons/Towing'

type SearchToolConfiguration = Record<string, { icon: JSX.Element; link: string }>

const searchToolConfiguration: SearchToolConfiguration = {
  personnel: {
    icon: <Personnel iconSize="extraHuge" />,
    link: '/search/personnel',
  },
  compositions: {
    icon: <Compositions iconSize="colossal" />,
    link: '/search/compositions',
  },
  defects: {
    icon: <Defect iconSize="extraHuge" />,
    link: '/search/defects',
  },
  shifts: {
    icon: <Shift iconSize="extraHuge" />,
    link: '/search/shifts',
  },
  download: {
    icon: <Download iconSize="extraHuge" />,
    link: '/search/fallbackTimetables',
  },
  phone: {
    icon: <Phone iconSize="extraHuge" />,
    link: '/search/contacts',
  },
  stopwatch: {
    icon: <Stopwatch iconSize="extraHuge" width="38" />,
    link: '/search/punctuality',
  },
  towing: {
    icon: <Towing iconSize="gigantic" />,
    link: '/search/towings',
  },
  serviceOrders: {
    icon: <ServiceOrder iconSize="extraHuge" />,
    link: '/search/serviceOrders',
  },
}

const shouldShowServiceOrders = (serviceDriver: boolean, actAsServiceDriver: boolean) =>
  serviceDriver || actAsServiceDriver

const SearchToolPage = (): JSX.Element => {
  const { t } = useTranslation()

  const serviceDriver = useTypedSelector((state) => state.user.serviceDriver)
  const actAsServiceDriver = useTypedSelector((state) => state.user.actAsServiceDriver)

  const searchToolKeys = shouldShowServiceOrders(serviceDriver, !!actAsServiceDriver)
    ? Object.keys(searchToolConfiguration)
    : Object.keys(searchToolConfiguration).filter((key) => key !== 'serviceOrders')

  return (
    <Page menu="search" overflowVisible>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 3,
          paddingBottom: 4.5,
          margin: '0 auto',
          maxWidth: '382px',
        }}
      >
        <Grid container sx={{ gap: 3, justifyContent: 'center' }}>
          {searchToolKeys.map((key) => {
            return (
              <SearchToolCard
                key={key}
                title={t(`searchTool.title.${key}`)}
                icon={searchToolConfiguration[key].icon}
                link={searchToolConfiguration[key].link}
              />
            )
          })}
        </Grid>
      </Box>
    </Page>
  )
}

export default SearchToolPage
