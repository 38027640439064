import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const Defect = ({ iconSize, fillColor }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={size} height={size} viewBox="0 0 27 27">
      <path
        d="M21.6 23.625L14.5406 16.5656L16.1438 14.9625L23.2031 22.0219L21.6 23.625ZM5.25938 23.625L3.65625 22.0219L11.8125 13.8656L8.80312 10.8562L8.15625 11.5031L6.91875 10.2656V12.6562L6.24375 13.3312L2.8125 9.89999L3.4875 9.22499H5.90625L4.55625 7.87499L8.24062 4.19061C8.55937 3.87186 8.90625 3.65624 9.28125 3.54374C9.65625 3.43124 10.0688 3.37499 10.5188 3.37499C10.9688 3.37499 11.3812 3.45468 11.7562 3.61405C12.1312 3.77343 12.4781 4.01249 12.7969 4.33124L9.7875 7.34061L11.1375 8.69061L10.4625 9.36561L13.3875 12.2906L16.8187 8.85936C16.6687 8.61561 16.5516 8.33436 16.4672 8.01561C16.3828 7.69686 16.3406 7.35936 16.3406 7.00311C16.3406 6.00936 16.7016 5.15155 17.4234 4.42968C18.1453 3.7078 19.0031 3.34686 19.9969 3.34686C20.2781 3.34686 20.5172 3.37499 20.7141 3.43124C20.9109 3.48749 21.075 3.56249 21.2063 3.65624L18.8156 6.04686L20.925 8.15624L23.3156 5.76561C23.4094 5.91561 23.4891 6.09843 23.5547 6.31405C23.6203 6.52968 23.6531 6.77811 23.6531 7.05936C23.6531 8.05311 23.2922 8.91093 22.5703 9.6328C21.8484 10.3547 20.9906 10.7156 19.9969 10.7156C19.6594 10.7156 19.3687 10.6922 19.125 10.6453C18.8813 10.5984 18.6562 10.5281 18.45 10.4344L5.25938 23.625Z"
        fill={fillColor ?? 'currentColor'}
      />
    </svg>
  )
}

export default Defect
