import styled from '@emotion/styled'
import React from 'react'

import { color, getColor, theme } from '../../Theme'

interface ButtonProps {
  background?: string
  isCommuter?: boolean
  timetableMode?: boolean
  noClick?: boolean
}

const Button = styled.button<ButtonProps>`
  ${theme.text('normal', 'content', 'bold')};
  ${theme.spacing.sides('smallest')};
  ${theme.spacing.ends('tiny')};
  background: ${(p) => p.background || color('primaryBlue')};
  border: none;
  border-radius: ${theme.borderRadius.button};
  color: ${color('white')};
  cursor: ${(p) => (p.noClick ? 'default' : 'pointer')};
  text-transform: none;
  letter-spacing: 0;
  height: 34px;
  outline: none;
  display: flex;
  justify-content: center;
  flex: 0 1 ${(p) => (p.isCommuter ? '24%' : '19%')};
  ${(p) =>
    p.timetableMode
      ? `
    &[disabled] {
      color: ${getColor(p.theme, ['white'], ['grayPink'])};
      background: ${getColor(p.theme, ['shallowBlue'], ['nightGray'])};
    }
    `
      : `
    &[disabled] {
      display: none;
    }
  `}
  margin: 0 1px;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
`

type Props = {
  children: JSX.Element
  background?: string
  disabled?: boolean
  isCommuter?: boolean
  full?: boolean
  noClick?: boolean
  timetableMode?: boolean
  onClick: () => void
}

const TaskButton = ({
  children,
  background,
  disabled,
  isCommuter,
  noClick,
  timetableMode,
  onClick,
}: Props) => {
  return (
    <Button
      background={background}
      disabled={disabled}
      isCommuter={isCommuter}
      noClick={noClick}
      timetableMode={timetableMode}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

export default TaskButton
