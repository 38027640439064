import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import moment from 'moment'
import React from 'react'

import ArrowRight from '../../icons/ArrowRight'
import { getResponsibleColor } from '../../lib/responsibles'
import { getColor, theme } from '../../Theme'
import { DelayCause } from '../../types/Input'
import { OutlineButton } from '../button/Button'
import { DelayLabel } from '../DelayLabelRow'
import Shadow from '../Shadow'

const LabelRow = styled(Shadow)`
  ${theme.layout.flexRow};
  align-items: flex-start;
  padding: 4px 0 6px 8px;
  margin: 0;
`
LabelRow.displayName = 'LabelRow'

const DelayColumn = styled.div`
  ${theme.layout.flexColumn};
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
`
DelayColumn.displayName = 'DelayColumn'

const DelayLabelWrapper = styled.div`
  padding: 2px 0 0 2px;
`

const DelayName = styled.div``
DelayName.displayName = 'DelayName'

const ButtonWrapper = styled.div`
  height: 100%;
`
ButtonWrapper.displayName = 'ButtonWrapper'

const AmendedAlert = styled.div`
  ${theme.text('smaller', 'content', 'bold')};
  color: ${(p) => getColor(p.theme, ['red'], ['red'])};
  ${theme.spacing.sides('smallest')};
`

/* Uncomment once status data is available
const UpperCase = styled.span`
  text-transform: uppercase;
`*/

type PropsIn = {
  t: TFunction
  nightMode: boolean
  cause: DelayCause
  amendDeviationReason: () => void
}

const AmendmentLink = ({ t, nightMode, cause, amendDeviationReason }: PropsIn) => (
  <LabelRow
    nightMode={nightMode}
    onClick={cause.amendmentChallenge ? undefined : amendDeviationReason}
  >
    <DelayColumn>
      <DelayName>{cause.categoryName}</DelayName>
      <DelayLabelWrapper>
        <DelayLabel color={getResponsibleColor(cause.categoryCode)}>
          {cause.categoryCode}
        </DelayLabel>
      </DelayLabelWrapper>
      {cause.amendmentChallenge && (
        <AmendedAlert>
          {t(`deviationAmendment.amended.${cause.amendmentChallenge.type}`)}
          &nbsp;
          {t('deviationAmendment.withCode')}
          &nbsp;
          {(cause.amendmentChallenge && cause.amendmentChallenge.amendedReasonCode.level2Code) ||
            (cause.amendmentChallenge && cause.amendmentChallenge.amendedReasonCode.level1Code)}
          <br />(
          {cause.amendmentChallenge &&
            moment(cause.amendmentChallenge.creationTimestampUtc).format('D.M.YYYY HH:mm')}
          )
          <br />
          {/* Uncomment once status data is available
            Status:&nbsp;
            <UpperCase>{cause.amendmentChallenge && t(`deviationAmendment.status.${cause.amendmentChallenge.status.toLowerCase()}`)}</UpperCase>*/}
        </AmendedAlert>
      )}
    </DelayColumn>
    {!cause.amendmentChallenge && (
      <ButtonWrapper>
        <OutlineButton>
          <ArrowRight iconSize="normal" />
        </OutlineButton>
      </ButtonWrapper>
    )}
  </LabelRow>
)

export default AmendmentLink
