import { sendVersionInfo } from '../actions/api'
import { recordNewUpdate } from '../lib/data'

// Listen postMessage events with update
export const listenUpdates = (): void => {
  window.addEventListener('message', (event) => {
    if (event.data === 'update') {
      recordNewUpdate()
      sendVersionInfo()
    }
  })
}
