import styled from '@emotion/styled'

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
`

export default SearchContainer
