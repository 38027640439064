import styled from '@emotion/styled'
import React from 'react'

import { getColor, theme } from '../../../Theme'
import { getTowingFlatListWithTowingSelection } from './TowingConfirmContainer'
import TowingFormRow from './TowingFormRow'
import TowingStep from './TowingStep'

const StepHeaderText = styled.div`
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-grow: 1;
  font-weight: bold;
  color: ${(p) => getColor(p.theme, ['black'], ['white'])};
`

const TextContainer = styled.div`
  padding-top: ${theme.spacing.sizes.normal};
  padding-left: ${theme.spacing.sizes.normal};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: ${(p) => getColor(p.theme, ['black'], ['white'])};
`

export const getValidCounterSteps = (
  branchElements,
  selectedBranchElements,
  actionsAndSelectionsByKey
) => {
  const selectedBranchKeys = selectedBranchElements.map((el) => el.key)
  return branchElements.filter(
    (el) =>
      el.contentType === 'towingStep' &&
      el.hasCounterAction &&
      !selectedBranchKeys.includes(el.key) &&
      actionsAndSelectionsByKey[el.key] &&
      actionsAndSelectionsByKey[el.key] !== 'REVERSED' &&
      actionsAndSelectionsByKey[el.key] !== 'CLEARED'
  )
}

const getTowingSelections = (branchElements, selectedBranchElements, actionsAndSelectionsByKey) => {
  const selectedBranchIds = selectedBranchElements.map((el) => el.key)
  return branchElements.filter(
    (el) =>
      el.contentType === 'towingSelection' &&
      !selectedBranchIds.includes(el.key) &&
      actionsAndSelectionsByKey[el.key]
  )
}

const getRecursiveCounterSteps = (
  branchElements,
  selectedBranchElements,
  actionsAndSelectionsByKey
) => {
  const namePattern = /^name[\d]+$/
  const towingSelections = getTowingSelections(
    branchElements,
    selectedBranchElements,
    actionsAndSelectionsByKey
  )
  if (towingSelections && towingSelections.length > 0) {
    const names = towingSelections.flatMap((el) =>
      Object.keys(el)
        .filter((key) => namePattern.test(key))
        .map((name) => ({ name, branch: name.replace('name', 'branch') }))
    )
    const steps = towingSelections
      .flatMap((el) =>
        names.flatMap((name) => {
          return el[name.branch].elements
        })
      )
      .filter((el) => !!el)
    if (steps.length > 0) {
      const counterSteps = towingSelections.flatMap((el) =>
        names.flatMap((name) => {
          return getValidCounterSteps(
            el[name.branch].elements,
            selectedBranchElements,
            actionsAndSelectionsByKey
          )
        })
      )
      return counterSteps.concat(
        getRecursiveCounterSteps(steps, selectedBranchElements, actionsAndSelectionsByKey)
      )
    } else {
      return []
    }
  }
}

const TowingCounterStepList = ({
  branchElements,
  selectedBranchElements,
  actionsAndSelectionsByKey,
  formAction,
}) => {
  const flattenedBranchSubtreeElements = getTowingFlatListWithTowingSelection(
    selectedBranchElements,
    actionsAndSelectionsByKey
  ).filter((el) => !!el)
  const counterSteps = getValidCounterSteps(
    branchElements,
    selectedBranchElements,
    actionsAndSelectionsByKey
  )
    .concat(
      getRecursiveCounterSteps(
        branchElements,
        flattenedBranchSubtreeElements,
        actionsAndSelectionsByKey
      )
    )
    .filter((el) => !!el)
  return (
    <>
      {counterSteps && counterSteps.length > 0 && (
        <>
          <StepHeaderText>Keskeneräisen laiton purkavat toimenpiteet</StepHeaderText>
          {[...(new Set(counterSteps) as any)].map((el, i) => {
            if (el.counterActionPreContent) {
              return (
                <TowingStep
                  step={el}
                  key={(el.key || el.name) + i}
                  stepText={el.counterActionPreContent[0].title}
                  counterActionForSetup={true}
                  formAction={formAction}
                />
              )
            } else {
              return (
                <TowingFormRow key={el.key}>
                  <TextContainer>{el.key}</TextContainer>
                </TowingFormRow>
              )
            }
          })}
          <StepHeaderText>Tarvittavat toimenpiteet</StepHeaderText>
        </>
      )}
    </>
  )
}

export default TowingCounterStepList
