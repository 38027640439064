import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { toggleScheduleFeedback } from '../../actions/schedulepage'
import config from '../../config'
import { MOMENT_SHIFTLIST_DATE_FORMAT } from '../../constants'
import moment from '../../lib/moment-fi'
import { shiftListFeedback } from '../../Selectors'
import { getColor, theme } from '../../Theme'
import type { AppState, Dispatch, Shift, UserState } from '../../types'
import CalendarIllustration from '../calendar/CalendarIllustration'
import { EmphasisNormal } from '../Emphasis'
import FeedbackIcon from '../FeedbackIcon'
import { HeaderAction } from '../header/HeaderLink'
import ShiftListFeedbackWidget from '../shift/ShiftListFeedbackWidget'

const FeedbackAction = styled(HeaderAction)`
  position: absolute;
  right: ${theme.spacing.sizes.small};
`

const Title = styled(EmphasisNormal)`
  ${theme.text('normal', 'decorative', 'bold')};
  ${theme.spacing.right('biggest')};
  position: absolute;
  top: ${theme.spacing.sizes.small};
  left: ${theme.spacing.sizes.large};
  color: ${(p) => getColor(p.theme, ['black'], ['grayPink'])};
`

const Relative = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  position: relative;
`

type Props = {
  t: TFunction
  hasFeedback: boolean
  feedbackOpen: boolean
  shift: Shift
  user: UserState
  onToggleFeedback: () => void
}

const CalendarShiftList = ({
  t,
  hasFeedback,
  feedbackOpen,
  shift,
  onToggleFeedback,
  user,
}: Props) => {
  const startDate = moment(shift.listStartTimestamp)
  return (
    <Relative>
      <CalendarIllustration startsAt={shift.listStartTimestamp}>
        <>
          {shift && config.features.shiftFeedback && !user.commuter_driver ? (
            <FeedbackAction
              onClick={onToggleFeedback}
              title={hasFeedback ? t('feedbackGiven') : t('yourThoughtsOnShiftList')}
            >
              <FeedbackIcon color="primaryBlue" bgColor="" notification={!hasFeedback} />
            </FeedbackAction>
          ) : undefined}
          <Title>
            {t('shiftList')} {startDate.format(MOMENT_SHIFTLIST_DATE_FORMAT)} –{' '}
            {startDate
              .clone()
              .add(3, 'weeks')
              .subtract(1, 'days')
              .format(MOMENT_SHIFTLIST_DATE_FORMAT)}
          </Title>
        </>
      </CalendarIllustration>
      {shift && feedbackOpen && config.features.shiftFeedback ? (
        <ShiftListFeedbackWidget top={24} shift={shift} onToggle={onToggleFeedback} />
      ) : undefined}
    </Relative>
  )
}

type PropsIn = {
  shift: Shift
}

const mapStateToProps = (state: AppState, { shift }: PropsIn) => {
  const ts = shift.listStartTimestamp
  const feedback = shift ? shiftListFeedback(shift, state) : null

  return {
    hasFeedback: feedback && feedback.id,
    feedbackOpen: state.schedulePage.feedbackOpen[ts],
    user: state.user,
  }
}
const mapDispatchToProps = (dispatch: Dispatch, { shift }: PropsIn) => ({
  onToggleFeedback: () => dispatch(toggleScheduleFeedback(shift.listStartTimestamp)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CalendarShiftList) as React.ComponentType<PropsIn>)
