export type Station = {
  passengerTraffic: boolean
  type: string
  stationName: string
  stationShortCode: string
  stationUICCode: number
  countryCode: string
  longitude: number
  latitude: number
}

export const stations: Array<Station> = [
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Ahonpää',
    stationShortCode: 'AHO',
    stationUICCode: 1343,
    countryCode: 'FI',
    longitude: 25.006783,
    latitude: 64.53711800000001,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Ahvenus',
    stationShortCode: 'AHV',
    stationUICCode: 1000,
    countryCode: 'FI',
    longitude: 22.498185,
    latitude: 61.291923,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Ainola',
    stationShortCode: 'AIN',
    stationUICCode: 628,
    countryCode: 'FI',
    longitude: 25.101494,
    latitude: 60.456863,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Airaksela',
    stationShortCode: 'ARL',
    stationUICCode: 869,
    countryCode: 'FI',
    longitude: 27.4295,
    latitude: 62.724396,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Aittaluoto',
    stationShortCode: 'ATL',
    stationUICCode: 676,
    countryCode: 'FI',
    longitude: 21.84537,
    latitude: 61.476933,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Ajos',
    stationShortCode: 'AJO',
    stationUICCode: 767,
    countryCode: 'FI',
    longitude: 24.519756,
    latitude: 65.666123,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Alapitkä',
    stationShortCode: 'APT',
    stationUICCode: 415,
    countryCode: 'FI',
    longitude: 27.535426,
    latitude: 63.200823,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Alavus',
    stationShortCode: 'ALV',
    stationUICCode: 284,
    countryCode: 'FI',
    longitude: 23.600437,
    latitude: 62.617769,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Alholma',
    stationShortCode: 'ALH',
    stationUICCode: 308,
    countryCode: 'FI',
    longitude: 22.695265,
    latitude: 63.706765,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Arola',
    stationShortCode: 'ARO',
    stationUICCode: 939,
    countryCode: 'FI',
    longitude: 29.022513,
    latitude: 64.45078700000001,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Asola',
    stationShortCode: 'ASO',
    stationUICCode: 1340,
    countryCode: 'FI',
    longitude: 25.047287,
    latitude: 60.320974,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Aviapolis',
    stationShortCode: 'AVP',
    stationUICCode: 1331,
    countryCode: 'FI',
    longitude: 24.956191,
    latitude: 60.30435,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Dragsvik',
    stationShortCode: 'DRA',
    stationUICCode: 167,
    countryCode: 'FI',
    longitude: 23.488607,
    latitude: 59.990199,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Dynamiittivaihde',
    stationShortCode: 'DMV',
    stationUICCode: 581,
    countryCode: 'FI',
    longitude: 23.084138,
    latitude: 59.866408,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Eläinpuisto-Zoo',
    stationShortCode: 'EPZ',
    stationUICCode: 623,
    countryCode: 'FI',
    longitude: 24.190381,
    latitude: 62.540979,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Eno',
    stationShortCode: 'ENO',
    stationUICCode: 464,
    countryCode: 'FI',
    longitude: 30.141691,
    latitude: 62.806375,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Ervelä',
    stationShortCode: 'ERV',
    stationUICCode: 1004,
    countryCode: 'FI',
    longitude: 23.22228,
    latitude: 60.203374,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Eskola',
    stationShortCode: 'ELA',
    stationUICCode: 318,
    countryCode: 'FI',
    longitude: 24.151369,
    latitude: 63.914902,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Espoo',
    stationShortCode: 'EPO',
    stationUICCode: 66,
    countryCode: 'FI',
    longitude: 24.655992,
    latitude: 60.205067,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Haapajärvi',
    stationShortCode: 'HPJ',
    stationUICCode: 330,
    countryCode: 'FI',
    longitude: 25.33434,
    latitude: 63.754515,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Haapakoski',
    stationShortCode: 'HPS',
    stationUICCode: 402,
    countryCode: 'FI',
    longitude: 27.175449,
    latitude: 62.452365,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Haapamäen kyllästämö',
    stationShortCode: 'HMK',
    stationUICCode: 1008,
    countryCode: 'FI',
    longitude: 24.406759,
    latitude: 62.280833,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Haapamäki',
    stationShortCode: 'HPK',
    stationUICCode: 200,
    countryCode: 'FI',
    longitude: 24.455067,
    latitude: 62.246476,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Haaparanta',
    stationShortCode: 'HPA',
    stationUICCode: 1408,
    countryCode: 'FI',
    longitude: 24.131104,
    latitude: 65.82809899999999,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Haarajoki',
    stationShortCode: 'HAA',
    stationUICCode: 13,
    countryCode: 'FI',
    longitude: 25.13382,
    latitude: 60.496261,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Hakosilta',
    stationShortCode: 'HLT',
    stationUICCode: 1014,
    countryCode: 'FI',
    longitude: 25.513357,
    latitude: 60.925032,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Haksi',
    stationShortCode: 'HSI',
    stationUICCode: 1015,
    countryCode: 'FI',
    longitude: 25.554663,
    latitude: 60.408207,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Hamina',
    stationShortCode: 'HMA',
    stationUICCode: 527,
    countryCode: 'FI',
    longitude: 27.166505,
    latitude: 60.55232,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Hammaslahti',
    stationShortCode: 'HSL',
    stationUICCode: 451,
    countryCode: 'FI',
    longitude: 29.968366,
    latitude: 62.430216,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Hanala',
    stationShortCode: 'HNA',
    stationUICCode: 1018,
    countryCode: 'FI',
    longitude: 25.072363,
    latitude: 60.339607,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Hangonsaari',
    stationShortCode: 'HGS',
    stationUICCode: 1020,
    countryCode: 'FI',
    longitude: 21.344905,
    latitude: 60.795884,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Hanhikoski',
    stationShortCode: 'HNH',
    stationUICCode: 812,
    countryCode: 'FI',
    longitude: 27.219836,
    latitude: 66.695831,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Hankasalmi',
    stationShortCode: 'HKS',
    stationUICCode: 427,
    countryCode: 'FI',
    longitude: 26.478116,
    latitude: 62.302807,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Hanko asema',
    stationShortCode: 'HNK',
    stationUICCode: 73,
    countryCode: 'FI',
    longitude: 22.968291,
    latitude: 59.826865,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Hanko tavara',
    stationShortCode: 'HNKT',
    stationUICCode: 1317,
    countryCode: 'FI',
    longitude: 22.981473,
    latitude: 59.828617,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Hanko-Pohjoinen',
    stationShortCode: 'HKP',
    stationUICCode: 879,
    countryCode: 'FI',
    longitude: 22.988361,
    latitude: 59.830141,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Harjavalta',
    stationShortCode: 'HVA',
    stationUICCode: 218,
    countryCode: 'FI',
    longitude: 22.133261,
    latitude: 61.309689,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Harju',
    stationShortCode: 'HJ',
    stationUICCode: 985,
    countryCode: 'FI',
    longitude: 26.677153,
    latitude: 60.940616,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Harviala',
    stationShortCode: 'HRV',
    stationUICCode: 622,
    countryCode: 'FI',
    longitude: 24.57693,
    latitude: 60.95365,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Haukipudas',
    stationShortCode: 'HD',
    stationUICCode: 342,
    countryCode: 'FI',
    longitude: 25.407075,
    latitude: 65.19563599999999,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Haukivuori',
    stationShortCode: 'HAU',
    stationUICCode: 549,
    countryCode: 'FI',
    longitude: 27.202606,
    latitude: 62.025658,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Hausjärvi tavara',
    stationShortCode: 'HAS',
    stationUICCode: 340,
    countryCode: 'FI',
    longitude: 25.014992,
    latitude: 60.785411,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Haviseva',
    stationShortCode: 'HVS',
    stationUICCode: 1021,
    countryCode: 'FI',
    longitude: 24.087374,
    latitude: 61.550664,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Havukoski',
    stationShortCode: 'HVK',
    stationUICCode: 1334,
    countryCode: 'FI',
    longitude: 25.051833,
    latitude: 60.308402,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Heikkilä',
    stationShortCode: 'HEK',
    stationUICCode: 1023,
    countryCode: 'FI',
    longitude: 24.480906,
    latitude: 60.121966,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Heinola',
    stationShortCode: 'HA',
    stationUICCode: 113,
    countryCode: 'FI',
    longitude: 26.046963,
    latitude: 61.20317,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Heinoo',
    stationShortCode: 'HNO',
    stationUICCode: 1025,
    countryCode: 'FI',
    longitude: 22.971145,
    latitude: 61.394254,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Heinävaara',
    stationShortCode: 'HÄV',
    stationUICCode: 924,
    countryCode: 'FI',
    longitude: 30.190207,
    latitude: 62.55575,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Heinävesi',
    stationShortCode: 'HNV',
    stationUICCode: 437,
    countryCode: 'FI',
    longitude: 28.643419,
    latitude: 62.382542,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Helsinki asema',
    stationShortCode: 'HKI',
    stationUICCode: 1,
    countryCode: 'FI',
    longitude: 24.941249,
    latitude: 60.172097,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Helsinki Kivihaka',
    stationShortCode: 'KHK',
    stationUICCode: 1028,
    countryCode: 'FI',
    longitude: 24.917191,
    latitude: 60.209813,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Henna',
    stationShortCode: 'HNN',
    stationUICCode: 1164,
    countryCode: 'FI',
    longitude: 25.494389,
    latitude: 60.799132,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Herrala',
    stationShortCode: 'HR',
    stationUICCode: 96,
    countryCode: 'FI',
    longitude: 25.475624,
    latitude: 60.896944,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Hiekkaharju',
    stationShortCode: 'HKH',
    stationUICCode: 556,
    countryCode: 'FI',
    longitude: 25.049104,
    latitude: 60.303081,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Hiirola',
    stationShortCode: 'HIR',
    stationUICCode: 997,
    countryCode: 'FI',
    longitude: 27.315826,
    latitude: 61.808118,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Hikiä',
    stationShortCode: 'HK',
    stationUICCode: 91,
    countryCode: 'FI',
    longitude: 24.919201,
    latitude: 60.752588,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Hillosensalmi',
    stationShortCode: 'HLS',
    stationUICCode: 988,
    countryCode: 'FI',
    longitude: 26.789163,
    latitude: 61.193088,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Hinthaara',
    stationShortCode: 'HH',
    stationUICCode: 561,
    countryCode: 'FI',
    longitude: 25.477951,
    latitude: 60.399764,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Hirvineva',
    stationShortCode: 'HVN',
    stationUICCode: 1041,
    countryCode: 'FI',
    longitude: 25.227499,
    latitude: 64.73398400000001,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Humppila',
    stationShortCode: 'HP',
    stationUICCode: 144,
    countryCode: 'FI',
    longitude: 23.362536,
    latitude: 60.919507,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Huopalahti',
    stationShortCode: 'HPL',
    stationUICCode: 72,
    countryCode: 'FI',
    longitude: 24.893523,
    latitude: 60.21835,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Huutokoski',
    stationShortCode: 'HKO',
    stationUICCode: 430,
    countryCode: 'FI',
    longitude: 27.68887,
    latitude: 62.210069,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Hyrkäs',
    stationShortCode: 'HYR',
    stationUICCode: 1348,
    countryCode: 'FI',
    longitude: 26.22007,
    latitude: 64.78880599999999,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Hyrynsalmi',
    stationShortCode: 'HYS',
    stationUICCode: 392,
    countryCode: 'FI',
    longitude: 28.462708,
    latitude: 64.683757,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Hyvinkää',
    stationShortCode: 'HY',
    stationUICCode: 30,
    countryCode: 'FI',
    longitude: 24.857709,
    latitude: 60.631294,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Hämeenlinna',
    stationShortCode: 'HL',
    stationUICCode: 47,
    countryCode: 'FI',
    longitude: 24.478851,
    latitude: 61.002368,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Härmä',
    stationShortCode: 'HM',
    stationUICCode: 300,
    countryCode: 'FI',
    longitude: 22.876631,
    latitude: 63.234321,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Höljäkkä',
    stationShortCode: 'HÖL',
    stationUICCode: 938,
    countryCode: 'FI',
    longitude: 29.44413,
    latitude: 63.454874,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Ii',
    stationShortCode: 'II',
    stationUICCode: 343,
    countryCode: 'FI',
    longitude: 25.441902,
    latitude: 65.318819,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Iisalmen teollisuusraiteet',
    stationShortCode: 'ITR',
    stationUICCode: 1049,
    countryCode: 'FI',
    longitude: 27.213924,
    latitude: 63.545757,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Iisalmi',
    stationShortCode: 'ILM',
    stationUICCode: 420,
    countryCode: 'FI',
    longitude: 27.201109,
    latitude: 63.560099,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Iittala',
    stationShortCode: 'ITA',
    stationUICCode: 154,
    countryCode: 'FI',
    longitude: 24.140789,
    latitude: 61.090616,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Ilmala asema',
    stationShortCode: 'ILA',
    stationUICCode: 9,
    countryCode: 'FI',
    longitude: 24.920636,
    latitude: 60.208134,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Ilmala ratapiha',
    stationShortCode: 'ILR',
    stationUICCode: 1030,
    countryCode: 'FI',
    longitude: 24.928154,
    latitude: 60.21312,
  },
  {
    passengerTraffic: false,
    type: 'STOPPING_POINT',
    stationName: 'Ilola',
    stationShortCode: 'IOA',
    stationUICCode: 1345,
    countryCode: 'FI',
    longitude: 23.875154,
    latitude: 61.229936,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Ilomantsi',
    stationShortCode: 'ILO',
    stationUICCode: 459,
    countryCode: 'FI',
    longitude: 30.941562,
    latitude: 62.666381,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Imatra asema',
    stationShortCode: 'IMR',
    stationUICCode: 603,
    countryCode: 'FI',
    longitude: 28.776017,
    latitude: 61.195914,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Imatra tavara',
    stationShortCode: 'IMT',
    stationUICCode: 502,
    countryCode: 'FI',
    longitude: 28.812093,
    latitude: 61.208116,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Imatrankoski',
    stationShortCode: 'IMK',
    stationUICCode: 504,
    countryCode: 'FI',
    longitude: 28.788045,
    latitude: 61.170432,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Imatrankoski-raja',
    stationShortCode: 'IMKR',
    stationUICCode: 503,
    countryCode: 'FI',
    longitude: 28.831782,
    latitude: 61.124973,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Immola',
    stationShortCode: 'IM',
    stationUICCode: 1352,
    countryCode: 'FI',
    longitude: 28.883766,
    latitude: 61.246855,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Inha',
    stationShortCode: 'IN',
    stationUICCode: 264,
    countryCode: 'FI',
    longitude: 24.146053,
    latitude: 62.550706,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Inkeroinen',
    stationShortCode: 'IKR',
    stationUICCode: 530,
    countryCode: 'FI',
    longitude: 26.839178,
    latitude: 60.696674,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Inkoo',
    stationShortCode: 'IKO',
    stationUICCode: 62,
    countryCode: 'FI',
    longitude: 23.93585,
    latitude: 60.061511,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Isokyrö',
    stationShortCode: 'IKY',
    stationUICCode: 295,
    countryCode: 'FI',
    longitude: 22.394797,
    latitude: 62.951072,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Jalasjärvi',
    stationShortCode: 'JAL',
    stationUICCode: 276,
    countryCode: 'FI',
    longitude: 22.954266,
    latitude: 62.472534,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Jepua',
    stationShortCode: 'JPA',
    stationUICCode: 303,
    countryCode: 'FI',
    longitude: 22.655272,
    latitude: 63.408383,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Joensuu asema',
    stationShortCode: 'JNS',
    stationUICCode: 460,
    countryCode: 'FI',
    longitude: 29.777033,
    latitude: 62.599536,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Joensuu Peltola',
    stationShortCode: 'PLT',
    stationUICCode: 1070,
    countryCode: 'FI',
    longitude: 29.771729,
    latitude: 62.593453,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Joensuu Sulkulahti',
    stationShortCode: 'SUL',
    stationUICCode: 1071,
    countryCode: 'FI',
    longitude: 29.775371,
    latitude: 62.58571,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Jokela',
    stationShortCode: 'JK',
    stationUICCode: 28,
    countryCode: 'FI',
    longitude: 24.970682,
    latitude: 60.552798,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Joroinen',
    stationShortCode: 'JOR',
    stationUICCode: 431,
    countryCode: 'FI',
    longitude: 27.808684,
    latitude: 62.17479,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Jorvas',
    stationShortCode: 'JRS',
    stationUICCode: 578,
    countryCode: 'FI',
    longitude: 24.512776,
    latitude: 60.137885,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Joutseno',
    stationShortCode: 'JTS',
    stationUICCode: 499,
    countryCode: 'FI',
    longitude: 28.488252,
    latitude: 61.120291,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Juankoski',
    stationShortCode: 'JKI',
    stationUICCode: 414,
    countryCode: 'FI',
    longitude: 28.318074,
    latitude: 63.063182,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Jutila',
    stationShortCode: 'JUT',
    stationUICCode: 1085,
    countryCode: 'FI',
    longitude: 25.123661,
    latitude: 60.837131,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Juupajoki',
    stationShortCode: 'JJ',
    stationUICCode: 627,
    countryCode: 'FI',
    longitude: 24.365046,
    latitude: 61.796788,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Juurikorpi',
    stationShortCode: 'JRI',
    stationUICCode: 535,
    countryCode: 'FI',
    longitude: 26.938127,
    latitude: 60.604162,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Jyväskylä',
    stationShortCode: 'JY',
    stationUICCode: 240,
    countryCode: 'FI',
    longitude: 25.754984,
    latitude: 62.241455,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Jämsä',
    stationShortCode: 'JÄS',
    stationUICCode: 204,
    countryCode: 'FI',
    longitude: 25.173294,
    latitude: 61.865627,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Jämsänkoski',
    stationShortCode: 'JSK',
    stationUICCode: 205,
    countryCode: 'FI',
    longitude: 25.171247,
    latitude: 61.89613,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Järvelä',
    stationShortCode: 'JR',
    stationUICCode: 95,
    countryCode: 'FI',
    longitude: 25.274659,
    latitude: 60.867325,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Järvenpää asema',
    stationShortCode: 'JP',
    stationUICCode: 25,
    countryCode: 'FI',
    longitude: 25.090796,
    latitude: 60.473684,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kaipiainen',
    stationShortCode: 'KPA',
    stationUICCode: 485,
    countryCode: 'FI',
    longitude: 27.117721,
    latitude: 60.900137,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kaipola',
    stationShortCode: 'KLA',
    stationUICCode: 656,
    countryCode: 'FI',
    longitude: 25.214721,
    latitude: 61.816678,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Kairokoski',
    stationShortCode: 'KKO',
    stationUICCode: 230,
    countryCode: 'FI',
    longitude: 23.021921,
    latitude: 62.027131,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kaitjärvi',
    stationShortCode: 'KJR',
    stationUICCode: 944,
    countryCode: 'FI',
    longitude: 27.346311,
    latitude: 60.910768,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kajaani',
    stationShortCode: 'KAJ',
    stationUICCode: 387,
    countryCode: 'FI',
    longitude: 27.738374,
    latitude: 64.21971600000001,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Kaleton',
    stationShortCode: 'KTN',
    stationUICCode: 697,
    countryCode: 'FI',
    longitude: 24.791661,
    latitude: 62.239687,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kalkku',
    stationShortCode: 'KAU',
    stationUICCode: 639,
    countryCode: 'FI',
    longitude: 23.572333,
    latitude: 61.496551,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Kalliovarasto',
    stationShortCode: 'KAO',
    stationUICCode: 1090,
    countryCode: 'FI',
    longitude: 27.909191,
    latitude: 64.28115200000001,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kalvitsa',
    stationShortCode: 'KSA',
    stationUICCode: 548,
    countryCode: 'FI',
    longitude: 27.285129,
    latitude: 61.910592,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kangas',
    stationShortCode: 'KGS',
    stationUICCode: 1092,
    countryCode: 'FI',
    longitude: 24.71276,
    latitude: 64.14361599999999,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kannelmäki',
    stationShortCode: 'KAN',
    stationUICCode: 658,
    countryCode: 'FI',
    longitude: 24.877004,
    latitude: 60.239562,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kannonkoski',
    stationShortCode: 'KSI',
    stationUICCode: 256,
    countryCode: 'FI',
    longitude: 25.270005,
    latitude: 62.983598,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kannus',
    stationShortCode: 'KNS',
    stationUICCode: 317,
    countryCode: 'FI',
    longitude: 23.915361,
    latitude: 63.898425,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Karhejärvi',
    stationShortCode: 'KRR',
    stationUICCode: 1095,
    countryCode: 'FI',
    longitude: 23.390356,
    latitude: 61.750017,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Karhukangas',
    stationShortCode: 'KHG',
    stationUICCode: 1097,
    countryCode: 'FI',
    longitude: 24.43962,
    latitude: 64.024376,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Karjaa',
    stationShortCode: 'KR',
    stationUICCode: 60,
    countryCode: 'FI',
    longitude: 23.66126,
    latitude: 60.068724,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Karkku',
    stationShortCode: 'KRU',
    stationUICCode: 178,
    countryCode: 'FI',
    longitude: 23.049129,
    latitude: 61.443314,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Karviainen',
    stationShortCode: 'KAR',
    stationUICCode: 1100,
    countryCode: 'FI',
    longitude: 22.558744,
    latitude: 60.62317,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kaskinen',
    stationShortCode: 'KSK',
    stationUICCode: 267,
    countryCode: 'FI',
    longitude: 21.229972,
    latitude: 62.381061,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kattilaharju',
    stationShortCode: 'KTH',
    stationUICCode: 1319,
    countryCode: 'FI',
    longitude: 26.959698,
    latitude: 60.880135,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kauhajoki',
    stationShortCode: 'KJI',
    stationUICCode: 272,
    countryCode: 'FI',
    longitude: 22.211614,
    latitude: 62.459927,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kauhava',
    stationShortCode: 'KHA',
    stationUICCode: 299,
    countryCode: 'FI',
    longitude: 23.0353,
    latitude: 63.099643,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kauklahti asema',
    stationShortCode: 'KLH',
    stationUICCode: 65,
    countryCode: 'FI',
    longitude: 24.600354,
    latitude: 60.189456,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kaulinranta',
    stationShortCode: 'KLR',
    stationUICCode: 790,
    countryCode: 'FI',
    longitude: 23.712804,
    latitude: 66.466931,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kauniainen',
    stationShortCode: 'KNI',
    stationUICCode: 67,
    countryCode: 'FI',
    longitude: 24.730884,
    latitude: 60.211939,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kauppilanmäki',
    stationShortCode: 'KPL',
    stationUICCode: 423,
    countryCode: 'FI',
    longitude: 27.227847,
    latitude: 63.709557,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Kausala',
    stationShortCode: 'KA',
    stationUICCode: 477,
    countryCode: 'FI',
    longitude: 26.329366,
    latitude: 60.885347,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Keitelepohja',
    stationShortCode: 'KTP',
    stationUICCode: 257,
    countryCode: 'FI',
    longitude: 25.54797,
    latitude: 63.192764,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kekomäki',
    stationShortCode: 'KEK',
    stationUICCode: 1101,
    countryCode: 'FI',
    longitude: 24.911994,
    latitude: 60.750527,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kemi',
    stationShortCode: 'KEM',
    stationUICCode: 347,
    countryCode: 'FI',
    longitude: 24.574365,
    latitude: 65.736729,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kemijärvi',
    stationShortCode: 'KJÄ',
    stationUICCode: 367,
    countryCode: 'FI',
    longitude: 27.403715,
    latitude: 66.724273,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kempele',
    stationShortCode: 'KML',
    stationUICCode: 769,
    countryCode: 'FI',
    longitude: 25.514546,
    latitude: 64.912904,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Kera',
    stationShortCode: 'KEA',
    stationUICCode: 621,
    countryCode: 'FI',
    longitude: 24.75564,
    latitude: 60.216573,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kerava asema',
    stationShortCode: 'KE',
    stationUICCode: 20,
    countryCode: 'FI',
    longitude: 25.106028,
    latitude: 60.404297,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kerimäki',
    stationShortCode: 'KIÄ',
    stationUICCode: 522,
    countryCode: 'FI',
    longitude: 29.114053,
    latitude: 61.861412,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kesälahti',
    stationShortCode: 'KTI',
    stationUICCode: 966,
    countryCode: 'FI',
    longitude: 29.802865,
    latitude: 61.887467,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Keuruu',
    stationShortCode: 'KEU',
    stationUICCode: 235,
    countryCode: 'FI',
    longitude: 24.707325,
    latitude: 62.255309,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Kiiala',
    stationShortCode: 'KIA',
    stationUICCode: 1113,
    countryCode: 'FI',
    longitude: 25.613204,
    latitude: 60.406719,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Kilo',
    stationShortCode: 'KIL',
    stationUICCode: 580,
    countryCode: 'FI',
    longitude: 24.782393,
    latitude: 60.217939,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kilpua',
    stationShortCode: 'KUA',
    stationUICCode: 1115,
    countryCode: 'FI',
    longitude: 24.961514,
    latitude: 64.34656699999999,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Kinahmi',
    stationShortCode: 'KNH',
    stationUICCode: 873,
    countryCode: 'FI',
    longitude: 27.951398,
    latitude: 63.142738,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kinni',
    stationShortCode: 'KII',
    stationUICCode: 1120,
    countryCode: 'FI',
    longitude: 26.846707,
    latitude: 61.306328,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Kirjola',
    stationShortCode: 'KIJ',
    stationUICCode: 1123,
    countryCode: 'FI',
    longitude: 29.520088,
    latitude: 61.536416,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kirkkonummi',
    stationShortCode: 'KKN',
    stationUICCode: 63,
    countryCode: 'FI',
    longitude: 24.438814,
    latitude: 60.119648,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kirkniemi',
    stationShortCode: 'KRN',
    stationUICCode: 79,
    countryCode: 'FI',
    longitude: 23.963456,
    latitude: 60.175824,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kitee',
    stationShortCode: 'KIT',
    stationUICCode: 453,
    countryCode: 'FI',
    longitude: 30.049014,
    latitude: 62.142774,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kiukainen',
    stationShortCode: 'KN',
    stationUICCode: 169,
    countryCode: 'FI',
    longitude: 22.089236,
    latitude: 61.209665,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kiuruvesi',
    stationShortCode: 'KRV',
    stationUICCode: 417,
    countryCode: 'FI',
    longitude: 26.611334,
    latitude: 63.640907,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kivesjärvi',
    stationShortCode: 'KVJ',
    stationUICCode: 378,
    countryCode: 'FI',
    longitude: 27.471464,
    latitude: 64.468856,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Kivistö',
    stationShortCode: 'KTÖ',
    stationUICCode: 1330,
    countryCode: 'FI',
    longitude: 24.846938,
    latitude: 60.314607,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Kohtavaara',
    stationShortCode: 'KOH',
    stationUICCode: 848,
    countryCode: 'FI',
    longitude: 29.284261,
    latitude: 63.515134,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Koivu',
    stationShortCode: 'KVU',
    stationUICCode: 362,
    countryCode: 'FI',
    longitude: 25.206795,
    latitude: 66.16981,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Koivuhovi',
    stationShortCode: 'KVH',
    stationUICCode: 675,
    countryCode: 'FI',
    longitude: 24.701273,
    latitude: 60.207144,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Koivukylä',
    stationShortCode: 'KVY',
    stationUICCode: 559,
    countryCode: 'FI',
    longitude: 25.059797,
    latitude: 60.323284,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kokemäki',
    stationShortCode: 'KKI',
    stationUICCode: 170,
    countryCode: 'FI',
    longitude: 22.30335,
    latitude: 61.254199,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kokkola',
    stationShortCode: 'KOK',
    stationUICCode: 312,
    countryCode: 'FI',
    longitude: 23.131096,
    latitude: 63.834429,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kolari',
    stationShortCode: 'KLI',
    stationUICCode: 358,
    countryCode: 'FI',
    longitude: 23.836254,
    latitude: 67.34870600000001,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Kolho',
    stationShortCode: 'KLO',
    stationUICCode: 199,
    countryCode: 'FI',
    longitude: 24.507444,
    latitude: 62.128049,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kolppi',
    stationShortCode: 'KPI',
    stationUICCode: 309,
    countryCode: 'FI',
    longitude: 22.898299,
    latitude: 63.62756,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kommila',
    stationShortCode: 'KMM',
    stationUICCode: 500,
    countryCode: 'FI',
    longitude: 27.887689,
    latitude: 62.325423,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kontiolahti',
    stationShortCode: 'KHI',
    stationUICCode: 463,
    countryCode: 'FI',
    longitude: 29.857039,
    latitude: 62.732254,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kontiomäki',
    stationShortCode: 'KON',
    stationUICCode: 390,
    countryCode: 'FI',
    longitude: 28.114694,
    latitude: 64.338219,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Koria',
    stationShortCode: 'KRA',
    stationUICCode: 478,
    countryCode: 'FI',
    longitude: 26.60131,
    latitude: 60.847067,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Korkeakoski',
    stationShortCode: 'KAS',
    stationUICCode: 193,
    countryCode: 'FI',
    longitude: 24.375464,
    latitude: 61.807686,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Korso',
    stationShortCode: 'KRS',
    stationUICCode: 19,
    countryCode: 'FI',
    longitude: 25.078125,
    latitude: 60.350692,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Korvensuo',
    stationShortCode: 'KSU',
    stationUICCode: 1128,
    countryCode: 'FI',
    longitude: 25.218194,
    latitude: 60.582687,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Koskenkorva',
    stationShortCode: 'KOS',
    stationUICCode: 274,
    countryCode: 'FI',
    longitude: 22.462393,
    latitude: 62.688028,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kotka asema',
    stationShortCode: 'KTA',
    stationUICCode: 532,
    countryCode: 'FI',
    longitude: 26.928611,
    latitude: 60.464388,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kotka Hovinsaari',
    stationShortCode: 'HOS',
    stationUICCode: 980,
    countryCode: 'FI',
    longitude: 26.919254,
    latitude: 60.483022,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kotka Mussalo',
    stationShortCode: 'MSS',
    stationUICCode: 557,
    countryCode: 'FI',
    longitude: 26.89751,
    latitude: 60.431802,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kotka tavara',
    stationShortCode: 'KTT',
    stationUICCode: 1130,
    countryCode: 'FI',
    longitude: 26.919356,
    latitude: 60.478803,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kotkan satama',
    stationShortCode: 'KTS',
    stationUICCode: 644,
    countryCode: 'FI',
    longitude: 26.934952,
    latitude: 60.466492,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kotolahti',
    stationShortCode: 'KOO',
    stationUICCode: 1329,
    countryCode: 'FI',
    longitude: 26.898503,
    latitude: 60.445244,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kouvola asema',
    stationShortCode: 'KV',
    stationUICCode: 480,
    countryCode: 'FI',
    longitude: 26.704588,
    latitude: 60.865613,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kouvola lajittelu',
    stationShortCode: 'KVLA',
    stationUICCode: 1132,
    countryCode: 'FI',
    longitude: 26.723643,
    latitude: 60.865734,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kouvola Oikoraide',
    stationShortCode: 'OIK',
    stationUICCode: 1133,
    countryCode: 'FI',
    longitude: 26.764691,
    latitude: 60.86715,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kouvola tavara',
    stationShortCode: 'KVT',
    stationUICCode: 1134,
    countryCode: 'FI',
    longitude: 26.750447,
    latitude: 60.86857,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kovjoki',
    stationShortCode: 'KOI',
    stationUICCode: 745,
    countryCode: 'FI',
    longitude: 22.69252,
    latitude: 63.521231,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kruunupyy',
    stationShortCode: 'KPY',
    stationUICCode: 311,
    countryCode: 'FI',
    longitude: 23.034045,
    latitude: 63.7216,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kuivasjärvi',
    stationShortCode: 'KIS',
    stationUICCode: 1137,
    countryCode: 'FI',
    longitude: 23.036413,
    latitude: 62.174449,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kullasvaara',
    stationShortCode: 'KUV',
    stationUICCode: 1320,
    countryCode: 'FI',
    longitude: 26.809881,
    latitude: 60.872249,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kuopio asema',
    stationShortCode: 'KUO',
    stationUICCode: 408,
    countryCode: 'FI',
    longitude: 27.679581,
    latitude: 62.897218,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kuopio tavara',
    stationShortCode: 'KUOT',
    stationUICCode: 1139,
    countryCode: 'FI',
    longitude: 27.697282,
    latitude: 62.897796,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kupittaa',
    stationShortCode: 'KUT',
    stationUICCode: 126,
    countryCode: 'FI',
    longitude: 22.297114,
    latitude: 60.450274,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kurkimäki',
    stationShortCode: 'KRM',
    stationUICCode: 406,
    countryCode: 'FI',
    longitude: 27.517621,
    latitude: 62.771886,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kuurila',
    stationShortCode: 'KU',
    stationUICCode: 626,
    countryCode: 'FI',
    longitude: 23.994903,
    latitude: 61.133691,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kuusankoski',
    stationShortCode: 'KUK',
    stationUICCode: 537,
    countryCode: 'FI',
    longitude: 26.656252,
    latitude: 60.921019,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Kylänlahti',
    stationShortCode: 'KYN',
    stationUICCode: 937,
    countryCode: 'FI',
    longitude: 29.751617,
    latitude: 63.3614,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Kymi',
    stationShortCode: 'KY',
    stationUICCode: 534,
    countryCode: 'FI',
    longitude: 26.90714,
    latitude: 60.539229,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Kyminlinna',
    stationShortCode: 'KLN',
    stationUICCode: 981,
    countryCode: 'FI',
    longitude: 26.895812,
    latitude: 60.508258,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kyrö',
    stationShortCode: 'KÖ',
    stationUICCode: 139,
    countryCode: 'FI',
    longitude: 22.748862,
    latitude: 60.702716,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kytömaa',
    stationShortCode: 'KYT',
    stationUICCode: 1111,
    countryCode: 'FI',
    longitude: 25.110001,
    latitude: 60.425639,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Kälviä',
    stationShortCode: 'KLV',
    stationUICCode: 316,
    countryCode: 'FI',
    longitude: 23.493255,
    latitude: 63.863974,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Käpylä',
    stationShortCode: 'KÄP',
    stationUICCode: 977,
    countryCode: 'FI',
    longitude: 24.946034,
    latitude: 60.22017,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Köykkäri',
    stationShortCode: 'KÖK',
    stationUICCode: 1144,
    countryCode: 'FI',
    longitude: 22.772334,
    latitude: 63.343804,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Laajavuori',
    stationShortCode: 'LAV',
    stationUICCode: 1341,
    countryCode: 'FI',
    longitude: 24.851146,
    latitude: 60.282453,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Lahdenperä',
    stationShortCode: 'LPR',
    stationUICCode: 1149,
    countryCode: 'FI',
    longitude: 24.944751,
    latitude: 61.811782,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Lahnaslampi',
    stationShortCode: 'LHN',
    stationUICCode: 871,
    countryCode: 'FI',
    longitude: 28.072026,
    latitude: 64.12065800000001,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Lahti',
    stationShortCode: 'LH',
    stationUICCode: 100,
    countryCode: 'FI',
    longitude: 25.657397,
    latitude: 60.97647,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Laihia',
    stationShortCode: 'LAI',
    stationUICCode: 293,
    countryCode: 'FI',
    longitude: 22.003444,
    latitude: 62.990138,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Lakiala',
    stationShortCode: 'LAK',
    stationUICCode: 212,
    countryCode: 'FI',
    longitude: 23.526965,
    latitude: 61.625873,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Lamminkoski',
    stationShortCode: 'LMK',
    stationUICCode: 1151,
    countryCode: 'FI',
    longitude: 23.061226,
    latitude: 62.107789,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Lamminniemi',
    stationShortCode: 'LAM',
    stationUICCode: 845,
    countryCode: 'FI',
    longitude: 27.702541,
    latitude: 64.232062,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Lapinjärvi',
    stationShortCode: 'LPJ',
    stationUICCode: 108,
    countryCode: 'FI',
    longitude: 26.127856,
    latitude: 60.599097,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Lapinlahti',
    stationShortCode: 'LNA',
    stationUICCode: 416,
    countryCode: 'FI',
    longitude: 27.393552,
    latitude: 63.360666,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Lapinneva',
    stationShortCode: 'LPN',
    stationUICCode: 683,
    countryCode: 'FI',
    longitude: 22.935238,
    latitude: 61.980556,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Lappeenranta',
    stationShortCode: 'LR',
    stationUICCode: 495,
    countryCode: 'FI',
    longitude: 28.195304,
    latitude: 61.047973,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Lappila',
    stationShortCode: 'LAA',
    stationUICCode: 94,
    countryCode: 'FI',
    longitude: 25.17423,
    latitude: 60.848954,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Lappohja',
    stationShortCode: 'LPO',
    stationUICCode: 75,
    countryCode: 'FI',
    longitude: 23.236919,
    latitude: 59.904082,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Lapua',
    stationShortCode: 'LPA',
    stationUICCode: 298,
    countryCode: 'FI',
    longitude: 23.015112,
    latitude: 62.971338,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Larvakytö',
    stationShortCode: 'LYÖ',
    stationUICCode: 1153,
    countryCode: 'FI',
    longitude: 22.935915,
    latitude: 62.680182,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Laukaa',
    stationShortCode: 'LAU',
    stationUICCode: 249,
    countryCode: 'FI',
    longitude: 25.924359,
    latitude: 62.422807,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Laurila',
    stationShortCode: 'LLA',
    stationUICCode: 360,
    countryCode: 'FI',
    longitude: 24.534062,
    latitude: 65.79683300000001,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Lauritsala',
    stationShortCode: 'LRS',
    stationUICCode: 498,
    countryCode: 'FI',
    longitude: 28.259631,
    latitude: 61.066415,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Lautiosaari',
    stationShortCode: 'LI',
    stationUICCode: 829,
    countryCode: 'FI',
    longitude: 24.565179,
    latitude: 65.77909,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Leinelä',
    stationShortCode: 'LNÄ',
    stationUICCode: 1333,
    countryCode: 'FI',
    longitude: 25.039847,
    latitude: 60.322613,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Lelkola',
    stationShortCode: 'LKL',
    stationUICCode: 993,
    countryCode: 'FI',
    longitude: 26.966496,
    latitude: 61.526751,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Lempäälä',
    stationShortCode: 'LPÄ',
    stationUICCode: 156,
    countryCode: 'FI',
    longitude: 23.755094,
    latitude: 61.314233,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Lentoasema',
    stationShortCode: 'LEN',
    stationUICCode: 1332,
    countryCode: 'FI',
    longitude: 24.968343,
    latitude: 60.315732,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Leppäkoski',
    stationShortCode: 'LK',
    stationUICCode: 43,
    countryCode: 'FI',
    longitude: 24.695884,
    latitude: 60.87288,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Leppävaara',
    stationShortCode: 'LPV',
    stationUICCode: 68,
    countryCode: 'FI',
    longitude: 24.813541,
    latitude: 60.21948,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Leteensuo',
    stationShortCode: 'LTS',
    stationUICCode: 1154,
    countryCode: 'FI',
    longitude: 24.232505,
    latitude: 61.065513,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Lieksa',
    stationShortCode: 'LIS',
    stationUICCode: 468,
    countryCode: 'FI',
    longitude: 30.016394,
    latitude: 63.318195,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Lieksan teollisuuskylä',
    stationShortCode: 'LTK',
    stationUICCode: 1157,
    countryCode: 'FI',
    longitude: 30.064649,
    latitude: 63.314174,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Lielahti',
    stationShortCode: 'LLH',
    stationUICCode: 183,
    countryCode: 'FI',
    longitude: 23.6817,
    latitude: 61.510284,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Lievestuore',
    stationShortCode: 'LVT',
    stationUICCode: 246,
    countryCode: 'FI',
    longitude: 26.195893,
    latitude: 62.259872,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Liminka',
    stationShortCode: 'LKA',
    stationUICCode: 338,
    countryCode: 'FI',
    longitude: 25.423892,
    latitude: 64.810176,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Liminpuro',
    stationShortCode: 'LMP',
    stationUICCode: 1354,
    countryCode: 'FI',
    longitude: 27.231377,
    latitude: 64.54294899999999,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Lohiluoma',
    stationShortCode: 'LUO',
    stationUICCode: 1159,
    countryCode: 'FI',
    longitude: 22.280376,
    latitude: 62.533667,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Lohja',
    stationShortCode: 'LO',
    stationUICCode: 81,
    countryCode: 'FI',
    longitude: 24.116408,
    latitude: 60.265369,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Loimaa',
    stationShortCode: 'LM',
    stationUICCode: 142,
    countryCode: 'FI',
    longitude: 23.060408,
    latitude: 60.847378,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Louhela',
    stationShortCode: 'LOH',
    stationUICCode: 661,
    countryCode: 'FI',
    longitude: 24.853299,
    latitude: 60.270798,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Loukolampi',
    stationShortCode: 'LOL',
    stationUICCode: 861,
    countryCode: 'FI',
    longitude: 27.203502,
    latitude: 62.162673,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Loviisan satama',
    stationShortCode: 'LVS',
    stationUICCode: 106,
    countryCode: 'FI',
    longitude: 26.247612,
    latitude: 60.418261,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Luikonlahti',
    stationShortCode: 'LUI',
    stationUICCode: 411,
    countryCode: 'FI',
    longitude: 28.6752,
    latitude: 62.927654,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Lusto',
    stationShortCode: 'LUS',
    stationUICCode: 690,
    countryCode: 'FI',
    longitude: 29.322269,
    latitude: 61.800548,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Luumäki',
    stationShortCode: 'LÄ',
    stationUICCode: 487,
    countryCode: 'FI',
    longitude: 27.761801,
    latitude: 60.944407,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Länkipohja',
    stationShortCode: 'LÄP',
    stationUICCode: 203,
    countryCode: 'FI',
    longitude: 24.813105,
    latitude: 61.740823,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Maanselkä',
    stationShortCode: 'MLK',
    stationUICCode: 382,
    countryCode: 'FI',
    longitude: 28.499352,
    latitude: 63.882203,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Maaria',
    stationShortCode: 'MRI',
    stationUICCode: 1166,
    countryCode: 'FI',
    longitude: 22.365622,
    latitude: 60.538524,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Madesjärvi',
    stationShortCode: 'MD',
    stationUICCode: 217,
    countryCode: 'FI',
    longitude: 22.987474,
    latitude: 62.3116,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Majajärvi',
    stationShortCode: 'MJJ',
    stationUICCode: 1168,
    countryCode: 'FI',
    longitude: 23.469457,
    latitude: 61.682946,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Malmi',
    stationShortCode: 'ML',
    stationUICCode: 17,
    countryCode: 'FI',
    longitude: 25.011468,
    latitude: 60.25166,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Malminkartano',
    stationShortCode: 'MLO',
    stationUICCode: 659,
    countryCode: 'FI',
    longitude: 24.861283,
    latitude: 60.249316,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Mankala',
    stationShortCode: 'MKA',
    stationUICCode: 1336,
    countryCode: 'FI',
    longitude: 26.182911,
    latitude: 60.916315,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Markkala',
    stationShortCode: 'MRK',
    stationUICCode: 896,
    countryCode: 'FI',
    longitude: 27.190465,
    latitude: 62.54004,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Martinlaakso',
    stationShortCode: 'MRL',
    stationUICCode: 662,
    countryCode: 'FI',
    longitude: 24.852604,
    latitude: 60.27808,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Masala',
    stationShortCode: 'MAS',
    stationUICCode: 64,
    countryCode: 'FI',
    longitude: 24.539106,
    latitude: 60.158661,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Matkaneva',
    stationShortCode: 'MTV',
    stationUICCode: 1171,
    countryCode: 'FI',
    longitude: 23.348859,
    latitude: 63.84089,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Mattila',
    stationShortCode: 'MAT',
    stationUICCode: 1172,
    countryCode: 'FI',
    longitude: 23.758953,
    latitude: 61.261133,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Melalahti',
    stationShortCode: 'MLL',
    stationUICCode: 1355,
    countryCode: 'FI',
    longitude: 27.659328,
    latitude: 64.41290100000001,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Metsäkansa',
    stationShortCode: 'MSÄ',
    stationUICCode: 558,
    countryCode: 'FI',
    longitude: 23.884586,
    latitude: 61.234303,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Mikkeli',
    stationShortCode: 'MI',
    stationUICCode: 546,
    countryCode: 'FI',
    longitude: 27.277969,
    latitude: 61.687818,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Misi',
    stationShortCode: 'MIS',
    stationUICCode: 366,
    countryCode: 'FI',
    longitude: 26.687235,
    latitude: 66.617923,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Mommila',
    stationShortCode: 'MLA',
    stationUICCode: 93,
    countryCode: 'FI',
    longitude: 25.075141,
    latitude: 60.821177,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Moskova (Leningradski)',
    stationShortCode: 'MVA',
    stationUICCode: 6007,
    countryCode: 'RU',
    longitude: 30.379968,
    latitude: 59.946584,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Muhos',
    stationShortCode: 'MH',
    stationUICCode: 375,
    countryCode: 'FI',
    longitude: 25.991997,
    latitude: 64.805398,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Mukkula',
    stationShortCode: 'MUK',
    stationUICCode: 594,
    countryCode: 'FI',
    longitude: 25.657807,
    latitude: 61.005746,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Murtomäki',
    stationShortCode: 'MUR',
    stationUICCode: 386,
    countryCode: 'FI',
    longitude: 27.579581,
    latitude: 64.064426,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Mustio',
    stationShortCode: 'MSO',
    stationUICCode: 78,
    countryCode: 'FI',
    longitude: 23.868437,
    latitude: 60.140408,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Mustolan satama',
    stationShortCode: 'MST',
    stationUICCode: 77,
    countryCode: 'FI',
    longitude: 28.335587,
    latitude: 61.055862,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Muukko',
    stationShortCode: 'MKO',
    stationUICCode: 1180,
    countryCode: 'FI',
    longitude: 28.341566,
    latitude: 61.088163,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Muurame',
    stationShortCode: 'MUU',
    stationUICCode: 433,
    countryCode: 'FI',
    longitude: 25.651352,
    latitude: 62.110893,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Muurola',
    stationShortCode: 'MUL',
    stationUICCode: 363,
    countryCode: 'FI',
    longitude: 25.37511,
    latitude: 66.370875,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Myllykangas',
    stationShortCode: 'MYS',
    stationUICCode: 1183,
    countryCode: 'FI',
    longitude: 25.272566,
    latitude: 65.535747,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Myllykoski',
    stationShortCode: 'MKI',
    stationUICCode: 536,
    countryCode: 'FI',
    longitude: 26.787577,
    latitude: 60.770062,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Myllymäki',
    stationShortCode: 'MY',
    stationUICCode: 263,
    countryCode: 'FI',
    longitude: 24.280965,
    latitude: 62.524753,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Myllyoja',
    stationShortCode: 'MYL',
    stationUICCode: 606,
    countryCode: 'FI',
    longitude: 25.989681,
    latitude: 61.16369,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Mynttilä',
    stationShortCode: 'MYT',
    stationUICCode: 543,
    countryCode: 'FI',
    longitude: 26.923895,
    latitude: 61.487261,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Mynämäki',
    stationShortCode: 'MYN',
    stationUICCode: 123,
    countryCode: 'FI',
    longitude: 21.964129,
    latitude: 60.650417,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Myyrmäki',
    stationShortCode: 'MYR',
    stationUICCode: 660,
    countryCode: 'FI',
    longitude: 24.854751,
    latitude: 60.261327,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Mäkkylä',
    stationShortCode: 'MÄK',
    stationUICCode: 693,
    countryCode: 'FI',
    longitude: 24.842976,
    latitude: 60.22131,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Mäntsälä',
    stationShortCode: 'MLÄ',
    stationUICCode: 27,
    countryCode: 'FI',
    longitude: 25.307475,
    latitude: 60.647258,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Mänttä',
    stationShortCode: 'MÄN',
    stationUICCode: 198,
    countryCode: 'FI',
    longitude: 24.646888,
    latitude: 62.029018,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Mäntyharju',
    stationShortCode: 'MR',
    stationUICCode: 544,
    countryCode: 'FI',
    longitude: 26.881746,
    latitude: 61.421104,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Mäntyluoto',
    stationShortCode: 'MN',
    stationUICCode: 223,
    countryCode: 'FI',
    longitude: 21.511964,
    latitude: 61.58996,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Naantali',
    stationShortCode: 'NNL',
    stationUICCode: 124,
    countryCode: 'FI',
    longitude: 22.040037,
    latitude: 60.469929,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Naarajärvi',
    stationShortCode: 'NRI',
    stationUICCode: 895,
    countryCode: 'FI',
    longitude: 27.045557,
    latitude: 62.283143,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Nakkila',
    stationShortCode: 'NAL',
    stationUICCode: 672,
    countryCode: 'FI',
    longitude: 21.969594,
    latitude: 61.387035,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Nastola',
    stationShortCode: 'NSL',
    stationUICCode: 595,
    countryCode: 'FI',
    longitude: 25.935467,
    latitude: 60.935567,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Niemenpää',
    stationShortCode: 'NMP',
    stationUICCode: 1185,
    countryCode: 'FI',
    longitude: 23.92106,
    latitude: 66.165558,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Niinimaa',
    stationShortCode: 'NII',
    stationUICCode: 285,
    countryCode: 'FI',
    longitude: 23.420637,
    latitude: 62.642246,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Niinimäki',
    stationShortCode: 'NMÄ',
    stationUICCode: 1324,
    countryCode: 'FI',
    longitude: 26.384374,
    latitude: 60.877915,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Niinisalo',
    stationShortCode: 'NNS',
    stationUICCode: 227,
    countryCode: 'FI',
    longitude: 22.481919,
    latitude: 61.844661,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Niirala',
    stationShortCode: 'NRL',
    stationUICCode: 446,
    countryCode: 'FI',
    longitude: 30.600268,
    latitude: 62.175021,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Niirala-raja',
    stationShortCode: 'NRLR',
    stationUICCode: 445,
    countryCode: 'FI',
    longitude: 30.632051,
    latitude: 62.170426,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Niittylahti',
    stationShortCode: 'NTH',
    stationUICCode: 917,
    countryCode: 'FI',
    longitude: 29.845911,
    latitude: 62.51221,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Nikkilä',
    stationShortCode: 'NLÄ',
    stationUICCode: 22,
    countryCode: 'FI',
    longitude: 25.26654,
    latitude: 60.38237,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Niska',
    stationShortCode: 'NSK',
    stationUICCode: 1353,
    countryCode: 'FI',
    longitude: 26.543501,
    latitude: 64.631387,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Nivala',
    stationShortCode: 'NVL',
    stationUICCode: 328,
    countryCode: 'FI',
    longitude: 24.960768,
    latitude: 63.936389,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Nokia',
    stationShortCode: 'NOA',
    stationUICCode: 181,
    countryCode: 'FI',
    longitude: 23.49917,
    latitude: 61.481059,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Nummela',
    stationShortCode: 'NM',
    stationUICCode: 84,
    countryCode: 'FI',
    longitude: 24.316922,
    latitude: 60.328751,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Nurmes',
    stationShortCode: 'NRM',
    stationUICCode: 472,
    countryCode: 'FI',
    longitude: 29.137234,
    latitude: 63.541078,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Närpiö',
    stationShortCode: 'NÄR',
    stationUICCode: 268,
    countryCode: 'FI',
    longitude: 21.363959,
    latitude: 62.468168,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Ohenmäki',
    stationShortCode: 'OHM',
    stationUICCode: 1190,
    countryCode: 'FI',
    longitude: 27.266604,
    latitude: 63.494989,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Oitti',
    stationShortCode: 'OI',
    stationUICCode: 92,
    countryCode: 'FI',
    longitude: 25.021316,
    latitude: 60.789806,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Olli',
    stationShortCode: 'OLLI',
    stationUICCode: 570,
    countryCode: 'FI',
    longitude: 25.374228,
    latitude: 60.382804,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Onttola',
    stationShortCode: 'ONT',
    stationUICCode: 443,
    countryCode: 'FI',
    longitude: 29.700477,
    latitude: 62.635524,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Orimattila',
    stationShortCode: 'OM',
    stationUICCode: 109,
    countryCode: 'FI',
    longitude: 25.748738,
    latitude: 60.814493,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Orivesi',
    stationShortCode: 'OV',
    stationUICCode: 190,
    countryCode: 'FI',
    longitude: 24.368047,
    latitude: 61.650095,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Orivesi keskusta',
    stationShortCode: 'OVK',
    stationUICCode: 1316,
    countryCode: 'FI',
    longitude: 24.369838,
    latitude: 61.673906,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Otanmäki',
    stationShortCode: 'OTM',
    stationUICCode: 385,
    countryCode: 'FI',
    longitude: 27.092351,
    latitude: 64.112027,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Otava',
    stationShortCode: 'OT',
    stationUICCode: 545,
    countryCode: 'FI',
    longitude: 27.071576,
    latitude: 61.640827,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Oulainen',
    stationShortCode: 'OU',
    stationUICCode: 322,
    countryCode: 'FI',
    longitude: 24.82098,
    latitude: 64.269509,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Oulu asema',
    stationShortCode: 'OL',
    stationUICCode: 370,
    countryCode: 'FI',
    longitude: 25.486121,
    latitude: 65.01240900000001,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Oulu Nokela',
    stationShortCode: 'NOK',
    stationUICCode: 1195,
    countryCode: 'FI',
    longitude: 25.475726,
    latitude: 64.989283,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Oulu Oritkari',
    stationShortCode: 'ORI',
    stationUICCode: 1196,
    countryCode: 'FI',
    longitude: 25.452448,
    latitude: 64.98925,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Oulu tavara',
    stationShortCode: 'OLT',
    stationUICCode: 1197,
    countryCode: 'FI',
    longitude: 25.470291,
    latitude: 65.000913,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Oulu Tuira',
    stationShortCode: 'TUA',
    stationUICCode: 339,
    countryCode: 'FI',
    longitude: 25.474942,
    latitude: 65.02871,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Oulunkylä',
    stationShortCode: 'OLK',
    stationUICCode: 15,
    countryCode: 'FI',
    longitude: 24.967676,
    latitude: 60.229,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Oulunlahti',
    stationShortCode: 'OLL',
    stationUICCode: 1351,
    countryCode: 'FI',
    longitude: 25.504832,
    latitude: 64.962428,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Paimenportti',
    stationShortCode: 'PTI',
    stationUICCode: 768,
    countryCode: 'FI',
    longitude: 26.919345,
    latitude: 60.475938,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Paimio',
    stationShortCode: 'PO',
    stationUICCode: 128,
    countryCode: 'FI',
    longitude: 22.685879,
    latitude: 60.459453,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Palopuro',
    stationShortCode: 'PLP',
    stationUICCode: 562,
    countryCode: 'FI',
    longitude: 24.906231,
    latitude: 60.601471,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Paltamo',
    stationShortCode: 'PTO',
    stationUICCode: 379,
    countryCode: 'FI',
    longitude: 27.82218,
    latitude: 64.40061,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Pankakoski',
    stationShortCode: 'PAS',
    stationUICCode: 935,
    countryCode: 'FI',
    longitude: 30.12173,
    latitude: 63.321127,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Parikkala',
    stationShortCode: 'PAR',
    stationUICCode: 510,
    countryCode: 'FI',
    longitude: 29.502884,
    latitude: 61.55855,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Parkano',
    stationShortCode: 'PKO',
    stationUICCode: 215,
    countryCode: 'FI',
    longitude: 23.078439,
    latitude: 62.051967,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Parola',
    stationShortCode: 'PRL',
    stationUICCode: 49,
    countryCode: 'FI',
    longitude: 24.366497,
    latitude: 61.049922,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Pasila asema',
    stationShortCode: 'PSL',
    stationUICCode: 10,
    countryCode: 'FI',
    longitude: 24.933521,
    latitude: 60.198689,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Pasila autojuna-asema',
    stationShortCode: 'PAU',
    stationUICCode: 1328,
    countryCode: 'FI',
    longitude: 24.933169,
    latitude: 60.208303,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Pasila tavara',
    stationShortCode: 'PSLT',
    stationUICCode: 1034,
    countryCode: 'FI',
    longitude: 24.934411,
    latitude: 60.212086,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Patokangas',
    stationShortCode: 'PTG',
    stationUICCode: 1346,
    countryCode: 'FI',
    longitude: 27.519974,
    latitude: 66.724902,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Pelkola',
    stationShortCode: 'PA',
    stationUICCode: 1055,
    countryCode: 'FI',
    longitude: 28.813728,
    latitude: 61.13426,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Pello',
    stationShortCode: 'PEL',
    stationUICCode: 356,
    countryCode: 'FI',
    longitude: 23.993374,
    latitude: 66.78466,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Peltosalmi',
    stationShortCode: 'PMI',
    stationUICCode: 882,
    countryCode: 'FI',
    longitude: 27.246161,
    latitude: 63.520846,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Peräseinäjoki',
    stationShortCode: 'PSJ',
    stationUICCode: 687,
    countryCode: 'FI',
    longitude: 22.941988,
    latitude: 62.549545,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Pesiökylä',
    stationShortCode: 'PSK',
    stationUICCode: 393,
    countryCode: 'FI',
    longitude: 28.569552,
    latitude: 64.926213,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Petroskoi / Petrozavodsk',
    stationShortCode: 'PRK',
    stationUICCode: 1000,
    countryCode: 'RU',
    longitude: 32.176808,
    latitude: 62.11698,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Petäjävesi',
    stationShortCode: 'PVI',
    stationUICCode: 237,
    countryCode: 'FI',
    longitude: 25.189337,
    latitude: 62.256974,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Pieksämäki asema',
    stationShortCode: 'PM',
    stationUICCode: 400,
    countryCode: 'FI',
    longitude: 27.16753,
    latitude: 62.301253,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Pieksämäki lajittelu',
    stationShortCode: 'PMLA',
    stationUICCode: 1210,
    countryCode: 'FI',
    longitude: 27.175321,
    latitude: 62.324528,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Pieksämäki tavara',
    stationShortCode: 'PMT',
    stationUICCode: 1211,
    countryCode: 'FI',
    longitude: 27.182414,
    latitude: 62.335855,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Pieksämäki Temu',
    stationShortCode: 'TMU',
    stationUICCode: 1212,
    countryCode: 'FI',
    longitude: 27.169853,
    latitude: 62.31317,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Pietari (Finljandski)',
    stationShortCode: 'PTR',
    stationUICCode: 3820,
    countryCode: 'RU',
    longitude: 30.307694,
    latitude: 59.971855,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Pietarsaari',
    stationShortCode: 'PTS',
    stationUICCode: 306,
    countryCode: 'FI',
    longitude: 22.714689,
    latitude: 63.674326,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Pihlajavesi',
    stationShortCode: 'PH',
    stationUICCode: 261,
    countryCode: 'FI',
    longitude: 24.395608,
    latitude: 62.346234,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Pihtipudas',
    stationShortCode: 'PP',
    stationUICCode: 258,
    countryCode: 'FI',
    longitude: 25.556278,
    latitude: 63.371806,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Piikkiö',
    stationShortCode: 'PIK',
    stationUICCode: 127,
    countryCode: 'FI',
    longitude: 22.51586,
    latitude: 60.423105,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Pikkarala',
    stationShortCode: 'PKL',
    stationUICCode: 819,
    countryCode: 'FI',
    longitude: 25.754062,
    latitude: 64.912694,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Pitkämäki',
    stationShortCode: 'PTK',
    stationUICCode: 1350,
    countryCode: 'FI',
    longitude: 29.060715,
    latitude: 63.570689,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Pitäjänmäki',
    stationShortCode: 'PJM',
    stationUICCode: 69,
    countryCode: 'FI',
    longitude: 24.859649,
    latitude: 60.223412,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Pohjankuru',
    stationShortCode: 'PKU',
    stationUICCode: 59,
    countryCode: 'FI',
    longitude: 23.551872,
    latitude: 60.098215,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Pohjois-Haaga',
    stationShortCode: 'POH',
    stationUICCode: 657,
    countryCode: 'FI',
    longitude: 24.883252,
    latitude: 60.230078,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Pohjois-Louko',
    stationShortCode: 'PLU',
    stationUICCode: 1214,
    countryCode: 'FI',
    longitude: 22.941522,
    latitude: 62.646837,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Poikkeus',
    stationShortCode: 'PKK',
    stationUICCode: 1216,
    countryCode: 'FI',
    longitude: 23.137954,
    latitude: 61.989112,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Poiksilta',
    stationShortCode: 'POI',
    stationUICCode: 965,
    countryCode: 'FI',
    longitude: 29.741895,
    latitude: 61.790643,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Pori',
    stationShortCode: 'PRI',
    stationUICCode: 220,
    countryCode: 'FI',
    longitude: 21.786607,
    latitude: 61.477212,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Porokylä',
    stationShortCode: 'POR',
    stationUICCode: 473,
    countryCode: 'FI',
    longitude: 29.099672,
    latitude: 63.555702,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Porvoo',
    stationShortCode: 'PRV',
    stationUICCode: 23,
    countryCode: 'FI',
    longitude: 25.648462,
    latitude: 60.396602,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Puhos',
    stationShortCode: 'PUS',
    stationUICCode: 919,
    countryCode: 'FI',
    longitude: 29.976082,
    latitude: 62.088594,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Puistola',
    stationShortCode: 'PLA',
    stationUICCode: 553,
    countryCode: 'FI',
    longitude: 25.036683,
    latitude: 60.276313,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Pukinmäki',
    stationShortCode: 'PMK',
    stationUICCode: 551,
    countryCode: 'FI',
    longitude: 24.993288,
    latitude: 60.242216,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Pulsa',
    stationShortCode: 'PL',
    stationUICCode: 1217,
    countryCode: 'FI',
    longitude: 27.972716,
    latitude: 60.927309,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Punkaharju',
    stationShortCode: 'PUN',
    stationUICCode: 517,
    countryCode: 'FI',
    longitude: 29.382354,
    latitude: 61.761151,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Purola',
    stationShortCode: 'PUR',
    stationUICCode: 564,
    countryCode: 'FI',
    longitude: 25.050225,
    latitude: 60.500329,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Pyhäkumpu',
    stationShortCode: 'PYK',
    stationUICCode: 757,
    countryCode: 'FI',
    longitude: 26.042336,
    latitude: 63.662046,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Pyhäkumpu erkanemisvaihde',
    stationShortCode: 'PYE',
    stationUICCode: 1218,
    countryCode: 'FI',
    longitude: 26.030169,
    latitude: 63.677555,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Pyhäsalmi',
    stationShortCode: 'PHÄ',
    stationUICCode: 331,
    countryCode: 'FI',
    longitude: 25.982615,
    latitude: 63.682935,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Pännäinen',
    stationShortCode: 'PNÄ',
    stationUICCode: 305,
    countryCode: 'FI',
    longitude: 22.790363,
    latitude: 63.595382,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Pääskylahti',
    stationShortCode: 'PKY',
    stationUICCode: 519,
    countryCode: 'FI',
    longitude: 28.924534,
    latitude: 61.862291,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'R060',
    stationShortCode: 'R060',
    stationUICCode: 98999,
    countryCode: 'FI',
    longitude: 24.786983,
    latitude: 60.743504,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'R530',
    stationShortCode: 'R530',
    stationUICCode: 99994,
    countryCode: 'FI',
    longitude: 25.021967,
    latitude: 64.606205,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'R660',
    stationShortCode: 'R660',
    stationUICCode: 98997,
    countryCode: 'FI',
    longitude: 25.115166,
    latitude: 60.415815,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'R702',
    stationShortCode: 'R702',
    stationUICCode: 98998,
    countryCode: 'FI',
    longitude: 24.784822,
    latitude: 60.759175,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'R855',
    stationShortCode: 'R855',
    stationUICCode: 98991,
    countryCode: 'FI',
    longitude: 22.777103,
    latitude: 63.592508,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Raahe',
    stationShortCode: 'RHE',
    stationUICCode: 335,
    countryCode: 'FI',
    longitude: 24.490632,
    latitude: 64.683364,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Raide934',
    stationShortCode: 'R934',
    stationUICCode: 99998,
    countryCode: 'FI',
    longitude: 28.100614,
    latitude: 64.362961,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Raippo',
    stationShortCode: 'RPO',
    stationUICCode: 490,
    countryCode: 'FI',
    longitude: 28.10607,
    latitude: 60.912094,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Raisio',
    stationShortCode: 'RAI',
    stationUICCode: 125,
    countryCode: 'FI',
    longitude: 22.130194,
    latitude: 60.485475,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Rajamäki',
    stationShortCode: 'RM',
    stationUICCode: 88,
    countryCode: 'FI',
    longitude: 24.761636,
    latitude: 60.532706,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Rajaperkiö',
    stationShortCode: 'RJP',
    stationUICCode: 1220,
    countryCode: 'FI',
    longitude: 23.013344,
    latitude: 63.036254,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Rantasalmi',
    stationShortCode: 'RMI',
    stationUICCode: 524,
    countryCode: 'FI',
    longitude: 28.276594,
    latitude: 62.014348,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Rasinsuo',
    stationShortCode: 'RAS',
    stationUICCode: 1222,
    countryCode: 'FI',
    longitude: 27.892136,
    latitude: 60.97619,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Ratikylä',
    stationShortCode: 'RLÄ',
    stationUICCode: 596,
    countryCode: 'FI',
    longitude: 23.011184,
    latitude: 62.245426,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Rauha',
    stationShortCode: 'RAH',
    stationUICCode: 501,
    countryCode: 'FI',
    longitude: 28.687578,
    latitude: 61.178295,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Rauhalahti',
    stationShortCode: 'RHL',
    stationUICCode: 1225,
    countryCode: 'FI',
    longitude: 25.801006,
    latitude: 62.239957,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Rauma',
    stationShortCode: 'RMA',
    stationUICCode: 165,
    countryCode: 'FI',
    longitude: 21.492431,
    latitude: 61.132361,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Raunio',
    stationShortCode: 'RIO',
    stationUICCode: 1227,
    countryCode: 'FI',
    longitude: 22.955164,
    latitude: 63.171521,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Rautaruukki',
    stationShortCode: 'RAT',
    stationUICCode: 750,
    countryCode: 'FI',
    longitude: 24.443464,
    latitude: 64.658708,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Rautjärvi',
    stationShortCode: 'RJÄ',
    stationUICCode: 506,
    countryCode: 'FI',
    longitude: 29.085136,
    latitude: 61.300812,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Rautpohja',
    stationShortCode: 'RPH',
    stationUICCode: 1232,
    countryCode: 'FI',
    longitude: 25.690392,
    latitude: 62.232539,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Rekola',
    stationShortCode: 'RKL',
    stationUICCode: 554,
    countryCode: 'FI',
    longitude: 25.068666,
    latitude: 60.332871,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Retretti',
    stationShortCode: 'REE',
    stationUICCode: 793,
    countryCode: 'FI',
    longitude: 29.294294,
    latitude: 61.803308,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Riihimäki Arolampi',
    stationShortCode: 'ARP',
    stationUICCode: 1235,
    countryCode: 'FI',
    longitude: 24.818591,
    latitude: 60.697135,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Riihimäki asema',
    stationShortCode: 'RI',
    stationUICCode: 40,
    countryCode: 'FI',
    longitude: 24.781241,
    latitude: 60.736078,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Riihimäki lajittelu',
    stationShortCode: 'RILA',
    stationUICCode: 1238,
    countryCode: 'FI',
    longitude: 24.792909,
    latitude: 60.725385,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Riihimäki tavara',
    stationShortCode: 'RIT',
    stationUICCode: 1240,
    countryCode: 'FI',
    longitude: 24.802312,
    latitude: 60.714716,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Riijärvi',
    stationShortCode: 'RJR',
    stationUICCode: 1327,
    countryCode: 'FI',
    longitude: 22.66527,
    latitude: 63.465864,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Riippa',
    stationShortCode: 'RPA',
    stationUICCode: 747,
    countryCode: 'FI',
    longitude: 23.637151,
    latitude: 63.868454,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Ristiina',
    stationShortCode: 'RST',
    stationUICCode: 770,
    countryCode: 'FI',
    longitude: 27.264338,
    latitude: 61.461611,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Ristijärvi',
    stationShortCode: 'RJV',
    stationUICCode: 391,
    countryCode: 'FI',
    longitude: 28.18586,
    latitude: 64.488355,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Rovaniemi',
    stationShortCode: 'ROI',
    stationUICCode: 364,
    countryCode: 'FI',
    longitude: 25.705329,
    latitude: 66.497675,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Ruha',
    stationShortCode: 'RHA',
    stationUICCode: 742,
    countryCode: 'FI',
    longitude: 22.978534,
    latitude: 62.887473,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Runni',
    stationShortCode: 'RNN',
    stationUICCode: 886,
    countryCode: 'FI',
    longitude: 26.886487,
    latitude: 63.602052,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Ruokosuo',
    stationShortCode: 'RSU',
    stationUICCode: 1342,
    countryCode: 'FI',
    longitude: 27.740113,
    latitude: 63.09744,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Ruukki',
    stationShortCode: 'RKI',
    stationUICCode: 337,
    countryCode: 'FI',
    longitude: 25.0982,
    latitude: 64.66037300000001,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Ruusumäki',
    stationShortCode: 'RSM',
    stationUICCode: 1338,
    countryCode: 'FI',
    longitude: 24.878314,
    latitude: 60.31566,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Ryttylä',
    stationShortCode: 'RY',
    stationUICCode: 42,
    countryCode: 'FI',
    longitude: 24.758186,
    latitude: 60.818048,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Röyttä',
    stationShortCode: 'RÖY',
    stationUICCode: 833,
    countryCode: 'FI',
    longitude: 24.162896,
    latitude: 65.765795,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Saakoski',
    stationShortCode: 'SAA',
    stationUICCode: 668,
    countryCode: 'FI',
    longitude: 25.410596,
    latitude: 61.990369,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Saari',
    stationShortCode: 'SR',
    stationUICCode: 964,
    countryCode: 'FI',
    longitude: 29.660865,
    latitude: 61.695058,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Saarijärvi',
    stationShortCode: 'SRJ',
    stationUICCode: 254,
    countryCode: 'FI',
    longitude: 25.283095,
    latitude: 62.700076,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Salminen',
    stationShortCode: 'SLN',
    stationUICCode: 405,
    countryCode: 'FI',
    longitude: 27.286121,
    latitude: 62.676239,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Salo',
    stationShortCode: 'SLO',
    stationUICCode: 55,
    countryCode: 'FI',
    longitude: 23.121448,
    latitude: 60.384777,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Sammalisto',
    stationShortCode: 'SAM',
    stationUICCode: 1246,
    countryCode: 'FI',
    longitude: 24.78372,
    latitude: 60.763711,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Santala',
    stationShortCode: 'STA',
    stationUICCode: 827,
    countryCode: 'FI',
    longitude: 23.121052,
    latitude: 59.875008,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Saunakallio',
    stationShortCode: 'SAU',
    stationUICCode: 806,
    countryCode: 'FI',
    longitude: 25.065568,
    latitude: 60.487305,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Saunamäki',
    stationShortCode: 'SMÄ',
    stationUICCode: 1325,
    countryCode: 'FI',
    longitude: 26.519052,
    latitude: 60.852337,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Savio',
    stationShortCode: 'SAV',
    stationUICCode: 555,
    countryCode: 'FI',
    longitude: 25.097803,
    latitude: 60.381296,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Savonlinna asema',
    stationShortCode: 'SL',
    stationUICCode: 521,
    countryCode: 'FI',
    longitude: 28.888266,
    latitude: 61.8689,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Seinäjoki asema',
    stationShortCode: 'SK',
    stationUICCode: 280,
    countryCode: 'FI',
    longitude: 22.844217,
    latitude: 62.792016,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Seinäjoki tavara',
    stationShortCode: 'SKT',
    stationUICCode: 1252,
    countryCode: 'FI',
    longitude: 22.866289,
    latitude: 62.784847,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Selänpää',
    stationShortCode: 'SPÄ',
    stationUICCode: 539,
    countryCode: 'FI',
    longitude: 26.714088,
    latitude: 61.007729,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Sieppijärvi',
    stationShortCode: 'SPJ',
    stationUICCode: 796,
    countryCode: 'FI',
    longitude: 23.867383,
    latitude: 67.159514,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Sievi',
    stationShortCode: 'SVI',
    stationUICCode: 319,
    countryCode: 'FI',
    longitude: 24.278697,
    latitude: 63.978137,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Siikamäki',
    stationShortCode: 'SKÄ',
    stationUICCode: 429,
    countryCode: 'FI',
    longitude: 27.40773,
    latitude: 62.289158,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Siilinjärvi Asema',
    stationShortCode: 'SIJ',
    stationUICCode: 413,
    countryCode: 'FI',
    longitude: 27.666561,
    latitude: 63.074953,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Simo',
    stationShortCode: 'SIM',
    stationUICCode: 346,
    countryCode: 'FI',
    longitude: 25.060752,
    latitude: 65.663135,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Simpele',
    stationShortCode: 'SPL',
    stationUICCode: 507,
    countryCode: 'FI',
    longitude: 29.371696,
    latitude: 61.424787,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Sipilä',
    stationShortCode: 'SIP',
    stationUICCode: 1254,
    countryCode: 'FI',
    longitude: 25.420346,
    latitude: 60.711122,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Sisättö',
    stationShortCode: 'STÖ',
    stationUICCode: 1257,
    countryCode: 'FI',
    longitude: 23.310976,
    latitude: 61.838125,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Siuntio',
    stationShortCode: 'STI',
    stationUICCode: 576,
    countryCode: 'FI',
    longitude: 24.221671,
    latitude: 60.140648,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Siuro',
    stationShortCode: 'SIU',
    stationUICCode: 179,
    countryCode: 'FI',
    longitude: 23.33911,
    latitude: 61.47445,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Skogby',
    stationShortCode: 'SGY',
    stationUICCode: 817,
    countryCode: 'FI',
    longitude: 23.310799,
    latitude: 59.926083,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Sköldvik',
    stationShortCode: 'SLD',
    stationUICCode: 560,
    countryCode: 'FI',
    longitude: 25.481846,
    latitude: 60.305964,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Soinlahti',
    stationShortCode: 'SOA',
    stationUICCode: 422,
    countryCode: 'FI',
    longitude: 27.204458,
    latitude: 63.634206,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Sorsasalo',
    stationShortCode: 'SOR',
    stationUICCode: 870,
    countryCode: 'FI',
    longitude: 27.68315,
    latitude: 62.953371,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Sukeva',
    stationShortCode: 'SKV',
    stationUICCode: 424,
    countryCode: 'FI',
    longitude: 27.422313,
    latitude: 63.864618,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Suolahti',
    stationShortCode: 'SUO',
    stationUICCode: 251,
    countryCode: 'FI',
    longitude: 25.851545,
    latitude: 62.561352,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Suonenjoki',
    stationShortCode: 'SNJ',
    stationUICCode: 404,
    countryCode: 'FI',
    longitude: 27.124495,
    latitude: 62.623707,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Suoniemi',
    stationShortCode: 'SNM',
    stationUICCode: 638,
    countryCode: 'FI',
    longitude: 23.210006,
    latitude: 61.465246,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Syrjä',
    stationShortCode: 'SYR',
    stationUICCode: 435,
    countryCode: 'FI',
    longitude: 28.366542,
    latitude: 62.356168,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Syrjämäki',
    stationShortCode: 'SKI',
    stationUICCode: 1265,
    countryCode: 'FI',
    longitude: 22.912391,
    latitude: 62.756156,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Sysmäjärvi',
    stationShortCode: 'SMJ',
    stationUICCode: 912,
    countryCode: 'FI',
    longitude: 29.060797,
    latitude: 62.716898,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Säkäniemi',
    stationShortCode: 'SÄ',
    stationUICCode: 918,
    countryCode: 'FI',
    longitude: 30.120915,
    latitude: 62.317676,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Sänkimäki',
    stationShortCode: 'SKM',
    stationUICCode: 872,
    countryCode: 'FI',
    longitude: 27.8662,
    latitude: 63.151325,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Sääksjärvi',
    stationShortCode: 'SJ',
    stationUICCode: 157,
    countryCode: 'FI',
    longitude: 23.752219,
    latitude: 61.418654,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Taavetti',
    stationShortCode: 'TA',
    stationUICCode: 486,
    countryCode: 'FI',
    longitude: 27.559584,
    latitude: 60.913077,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Tahkoluoto',
    stationShortCode: 'TKO',
    stationUICCode: 702,
    countryCode: 'FI',
    longitude: 21.408136,
    latitude: 61.635423,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Taipale',
    stationShortCode: 'TE',
    stationUICCode: 1268,
    countryCode: 'FI',
    longitude: 27.311786,
    latitude: 63.459474,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Talviainen',
    stationShortCode: 'TV',
    stationUICCode: 1270,
    countryCode: 'FI',
    longitude: 24.658868,
    latitude: 61.724742,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Talvivaara',
    stationShortCode: 'TLV',
    stationUICCode: 1323,
    countryCode: 'FI',
    longitude: 27.994576,
    latitude: 63.979513,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Tammisaari',
    stationShortCode: 'TMS',
    stationUICCode: 76,
    countryCode: 'FI',
    longitude: 23.443501,
    latitude: 59.979553,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Tampere asema',
    stationShortCode: 'TPE',
    stationUICCode: 160,
    countryCode: 'FI',
    longitude: 23.773454,
    latitude: 61.498236,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Tampere Järvensivu',
    stationShortCode: 'JVS',
    stationUICCode: 1272,
    countryCode: 'FI',
    longitude: 23.785189,
    latitude: 61.491461,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Tampere tavara',
    stationShortCode: 'TPET',
    stationUICCode: 1273,
    countryCode: 'FI',
    longitude: 23.763974,
    latitude: 61.469888,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Tampere Viinikka',
    stationShortCode: 'VKA',
    stationUICCode: 1274,
    countryCode: 'FI',
    longitude: 23.773542,
    latitude: 61.480331,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Tapanila',
    stationShortCode: 'TNA',
    stationUICCode: 552,
    countryCode: 'FI',
    longitude: 25.030033,
    latitude: 60.2639,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Tapavainola',
    stationShortCode: 'TAP',
    stationUICCode: 1276,
    countryCode: 'FI',
    longitude: 28.08938,
    latitude: 61.017354,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Tavastila',
    stationShortCode: 'TSL',
    stationUICCode: 837,
    countryCode: 'FI',
    longitude: 26.954279,
    latitude: 60.572959,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Tervajoki',
    stationShortCode: 'TK',
    stationUICCode: 294,
    countryCode: 'FI',
    longitude: 22.171462,
    latitude: 63.000773,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Tervola',
    stationShortCode: 'TRV',
    stationUICCode: 361,
    countryCode: 'FI',
    longitude: 24.772291,
    latitude: 66.081811,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Teuva',
    stationShortCode: 'TUV',
    stationUICCode: 271,
    countryCode: 'FI',
    longitude: 21.742287,
    latitude: 62.474956,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Tikkala',
    stationShortCode: 'TKK',
    stationUICCode: 916,
    countryCode: 'FI',
    longitude: 30.083633,
    latitude: 62.364981,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Tikkaperä',
    stationShortCode: 'TKP',
    stationUICCode: 1335,
    countryCode: 'FI',
    longitude: 25.291811,
    latitude: 64.771091,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Tikkurila asema',
    stationShortCode: 'TKL',
    stationUICCode: 18,
    countryCode: 'FI',
    longitude: 25.044055,
    latitude: 60.292166,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Tohmajärvi',
    stationShortCode: 'TOH',
    stationUICCode: 448,
    countryCode: 'FI',
    longitude: 30.356148,
    latitude: 62.243484,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Toijala',
    stationShortCode: 'TL',
    stationUICCode: 150,
    countryCode: 'FI',
    longitude: 23.860905,
    latitude: 61.170611,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Toivala',
    stationShortCode: 'TOI',
    stationUICCode: 412,
    countryCode: 'FI',
    longitude: 27.723588,
    latitude: 62.996029,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Tolsa',
    stationShortCode: 'TOL',
    stationUICCode: 830,
    countryCode: 'FI',
    longitude: 24.472724,
    latitude: 60.118558,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Tommola',
    stationShortCode: 'TOM',
    stationUICCode: 1280,
    countryCode: 'FI',
    longitude: 25.492176,
    latitude: 60.906703,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Torkkeli',
    stationShortCode: 'TRK',
    stationUICCode: 1283,
    countryCode: 'FI',
    longitude: 24.548925,
    latitude: 61.70059,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Tornio asema',
    stationShortCode: 'TOR',
    stationUICCode: 351,
    countryCode: 'FI',
    longitude: 24.163844,
    latitude: 65.845798,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Tornio-Itäinen',
    stationShortCode: 'TRI',
    stationUICCode: 1318,
    countryCode: 'FI',
    longitude: 24.18508,
    latitude: 65.85082300000001,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Tornio-raja',
    stationShortCode: 'TRR',
    stationUICCode: 678,
    countryCode: 'FI',
    longitude: 24.150733,
    latitude: 65.827851,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Tuomarila',
    stationShortCode: 'TRL',
    stationUICCode: 579,
    countryCode: 'FI',
    longitude: 24.681767,
    latitude: 60.20605,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Tuomioja',
    stationShortCode: 'TJA',
    stationUICCode: 336,
    countryCode: 'FI',
    longitude: 25.027911,
    latitude: 64.608277,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Turenki',
    stationShortCode: 'TU',
    stationUICCode: 44,
    countryCode: 'FI',
    longitude: 24.63865,
    latitude: 60.918387,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Turku asema',
    stationShortCode: 'TKU',
    stationUICCode: 130,
    countryCode: 'FI',
    longitude: 22.252945,
    latitude: 60.453985,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Turku satama',
    stationShortCode: 'TUS',
    stationUICCode: 135,
    countryCode: 'FI',
    longitude: 22.226894,
    latitude: 60.434245,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Turku tavara',
    stationShortCode: 'TKUT',
    stationUICCode: 1285,
    countryCode: 'FI',
    longitude: 22.240471,
    latitude: 60.452001,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Tuupovaara',
    stationShortCode: 'TPV',
    stationUICCode: 458,
    countryCode: 'FI',
    longitude: 30.558938,
    latitude: 62.523103,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Tuuri',
    stationShortCode: 'TUU',
    stationUICCode: 283,
    countryCode: 'FI',
    longitude: 23.722323,
    latitude: 62.605349,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Törmä',
    stationShortCode: 'TÖR',
    stationUICCode: 1287,
    countryCode: 'FI',
    longitude: 24.625744,
    latitude: 65.89466,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Törölä',
    stationShortCode: 'TRÄ',
    stationUICCode: 1290,
    countryCode: 'FI',
    longitude: 27.99051,
    latitude: 61.007817,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Uimaharju',
    stationShortCode: 'UIM',
    stationUICCode: 465,
    countryCode: 'FI',
    longitude: 30.241497,
    latitude: 62.912482,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Urjala',
    stationShortCode: 'UR',
    stationUICCode: 148,
    countryCode: 'FI',
    longitude: 23.626264,
    latitude: 61.0635,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Utajärvi',
    stationShortCode: 'UTJ',
    stationUICCode: 376,
    countryCode: 'FI',
    longitude: 26.413565,
    latitude: 64.758926,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Utti',
    stationShortCode: 'UTI',
    stationUICCode: 484,
    countryCode: 'FI',
    longitude: 26.933289,
    latitude: 60.877673,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Uusikaupunki',
    stationShortCode: 'UKP',
    stationUICCode: 121,
    countryCode: 'FI',
    longitude: 21.425685,
    latitude: 60.797364,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Uusikylä',
    stationShortCode: 'UKÄ',
    stationUICCode: 105,
    countryCode: 'FI',
    longitude: 25.993966,
    latitude: 60.927722,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Vaajakoski',
    stationShortCode: 'VKO',
    stationUICCode: 245,
    countryCode: 'FI',
    longitude: 25.879324,
    latitude: 62.247587,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Vaala',
    stationShortCode: 'VAA',
    stationUICCode: 377,
    countryCode: 'FI',
    longitude: 26.842702,
    latitude: 64.558015,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Vaarala',
    stationShortCode: 'VRA',
    stationUICCode: 807,
    countryCode: 'FI',
    longitude: 25.8801,
    latitude: 66.51809799999999,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Vaasa',
    stationShortCode: 'VS',
    stationUICCode: 288,
    countryCode: 'FI',
    longitude: 21.621824,
    latitude: 63.097786,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Vahojärvi',
    stationShortCode: 'VJR',
    stationUICCode: 214,
    countryCode: 'FI',
    longitude: 23.232163,
    latitude: 61.913026,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Vainikkala asema',
    stationShortCode: 'VNA',
    stationUICCode: 492,
    countryCode: 'FI',
    longitude: 28.303201,
    latitude: 60.863835,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Vainikkala tavara',
    stationShortCode: 'VNAT',
    stationUICCode: 1292,
    countryCode: 'FI',
    longitude: 28.285662,
    latitude: 60.86817,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Vainikkala-raja',
    stationShortCode: 'VNAR',
    stationUICCode: 493,
    countryCode: 'FI',
    longitude: 28.337173,
    latitude: 60.855474,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Valimo',
    stationShortCode: 'VMO',
    stationUICCode: 847,
    countryCode: 'FI',
    longitude: 24.8758,
    latitude: 60.222172,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Valkeakoski',
    stationShortCode: 'VI',
    stationUICCode: 153,
    countryCode: 'FI',
    longitude: 24.017075,
    latitude: 61.273922,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Valkeasuo',
    stationShortCode: 'VSO',
    stationUICCode: 450,
    countryCode: 'FI',
    longitude: 30.163977,
    latitude: 62.304843,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Valtimo',
    stationShortCode: 'VLM',
    stationUICCode: 475,
    countryCode: 'FI',
    longitude: 28.800862,
    latitude: 63.686406,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Vammala',
    stationShortCode: 'VMA',
    stationUICCode: 176,
    countryCode: 'FI',
    longitude: 22.887664,
    latitude: 61.349519,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Vanattara',
    stationShortCode: 'VTR',
    stationUICCode: 1295,
    countryCode: 'FI',
    longitude: 23.759537,
    latitude: 61.370713,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Vantaankoski',
    stationShortCode: 'VKS',
    stationUICCode: 839,
    countryCode: 'FI',
    longitude: 24.848271,
    latitude: 60.285681,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Varkaus',
    stationShortCode: 'VAR',
    stationUICCode: 432,
    countryCode: 'FI',
    longitude: 27.873415,
    latitude: 62.312389,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Vartius',
    stationShortCode: 'VUS',
    stationUICCode: 941,
    countryCode: 'FI',
    longitude: 29.94809,
    latitude: 64.54016300000001,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Vartius-raja',
    stationShortCode: 'VUR',
    stationUICCode: 949,
    countryCode: 'FI',
    longitude: 29.989741,
    latitude: 64.545906,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Vasikkahaka',
    stationShortCode: 'VKH',
    stationUICCode: 1300,
    countryCode: 'FI',
    longitude: 24.524784,
    latitude: 60.146114,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Vaskiluoto',
    stationShortCode: 'VSK',
    stationUICCode: 291,
    countryCode: 'FI',
    longitude: 21.564228,
    latitude: 63.089086,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Vehkala',
    stationShortCode: 'VEH',
    stationUICCode: 1337,
    countryCode: 'FI',
    longitude: 24.843716,
    latitude: 60.295054,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Venetmäki',
    stationShortCode: 'VKI',
    stationUICCode: 428,
    countryCode: 'FI',
    longitude: 26.752862,
    latitude: 62.309222,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Vesanka',
    stationShortCode: 'VN',
    stationUICCode: 239,
    countryCode: 'FI',
    longitude: 25.557151,
    latitude: 62.269355,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Viekki',
    stationShortCode: 'VK',
    stationUICCode: 471,
    countryCode: 'FI',
    longitude: 29.61174,
    latitude: 63.421549,
  },
  {
    passengerTraffic: false,
    type: 'TURNOUT_IN_THE_OPEN_LINE',
    stationName: 'Vierumäki',
    stationShortCode: 'VRM',
    stationUICCode: 112,
    countryCode: 'FI',
    longitude: 25.932868,
    latitude: 61.101895,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Vihanti',
    stationShortCode: 'VTI',
    stationUICCode: 334,
    countryCode: 'FI',
    longitude: 24.993624,
    latitude: 64.48451,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Vihtari',
    stationShortCode: 'VIH',
    stationUICCode: 438,
    countryCode: 'FI',
    longitude: 29.029282,
    latitude: 62.380739,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Vihtavuori',
    stationShortCode: 'VRI',
    stationUICCode: 248,
    countryCode: 'FI',
    longitude: 25.889739,
    latitude: 62.373612,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Viiala',
    stationShortCode: 'VIA',
    stationUICCode: 155,
    countryCode: 'FI',
    longitude: 23.770683,
    latitude: 61.211787,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Viinijärvi',
    stationShortCode: 'VNJ',
    stationUICCode: 440,
    countryCode: 'FI',
    longitude: 29.237166,
    latitude: 62.641687,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Viipuri',
    stationShortCode: 'VYB',
    stationUICCode: 2000,
    countryCode: 'RU',
    longitude: 29.900653,
    latitude: 60.379534,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Villähde',
    stationShortCode: 'VLH',
    stationUICCode: 104,
    countryCode: 'FI',
    longitude: 25.833724,
    latitude: 60.946379,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Vilppula',
    stationShortCode: 'VLP',
    stationUICCode: 196,
    countryCode: 'FI',
    longitude: 24.506956,
    latitude: 62.025888,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Vinnilä',
    stationShortCode: 'VIN',
    stationUICCode: 1305,
    countryCode: 'FI',
    longitude: 24.110938,
    latitude: 61.099944,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Virkamies',
    stationShortCode: 'VMS',
    stationUICCode: 1339,
    countryCode: 'FI',
    longitude: 24.963318,
    latitude: 60.310517,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Voltti',
    stationShortCode: 'VT',
    stationUICCode: 302,
    countryCode: 'FI',
    longitude: 22.824405,
    latitude: 63.286164,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Vuohijärvi',
    stationShortCode: 'VHJ',
    stationUICCode: 541,
    countryCode: 'FI',
    longitude: 26.796227,
    latitude: 61.095852,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Vuojoki',
    stationShortCode: 'VJO',
    stationUICCode: 1310,
    countryCode: 'FI',
    longitude: 21.709937,
    latitude: 61.179379,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Vuokatti',
    stationShortCode: 'VKT',
    stationUICCode: 383,
    countryCode: 'FI',
    longitude: 28.273914,
    latitude: 64.14752,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Vuonislahti',
    stationShortCode: 'VSL',
    stationUICCode: 467,
    countryCode: 'FI',
    longitude: 29.99331,
    latitude: 63.152636,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Vuonos',
    stationShortCode: 'VNS',
    stationUICCode: 863,
    countryCode: 'FI',
    longitude: 29.08783,
    latitude: 62.761232,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Vuosaari',
    stationShortCode: 'VSA',
    stationUICCode: 1321,
    countryCode: 'FI',
    longitude: 25.167956,
    latitude: 60.227507,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Värtsilä',
    stationShortCode: 'VRS',
    stationUICCode: 2410,
    countryCode: 'RU',
    longitude: 30.660787,
    latitude: 62.169947,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Ykspihlaja tavara',
    stationShortCode: 'YKST',
    stationUICCode: 315,
    countryCode: 'FI',
    longitude: 23.083099,
    latitude: 63.838554,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Ykspihlaja väliratapiha',
    stationShortCode: 'YKSV',
    stationUICCode: 1326,
    countryCode: 'FI',
    longitude: 23.058247,
    latitude: 63.850442,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Ylistaro',
    stationShortCode: 'YST',
    stationUICCode: 296,
    countryCode: 'FI',
    longitude: 22.532729,
    latitude: 62.917801,
  },
  {
    passengerTraffic: true,
    type: 'STOPPING_POINT',
    stationName: 'Ylitornio',
    stationShortCode: 'YTR',
    stationUICCode: 789,
    countryCode: 'FI',
    longitude: 23.683194,
    latitude: 66.325333,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Ylivalli',
    stationShortCode: 'YLV',
    stationUICCode: 654,
    countryCode: 'FI',
    longitude: 22.958844,
    latitude: 62.402105,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Ylivieska',
    stationShortCode: 'YV',
    stationUICCode: 320,
    countryCode: 'FI',
    longitude: 24.540593,
    latitude: 64.071898,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Yläkoski',
    stationShortCode: 'YLK',
    stationUICCode: 867,
    countryCode: 'FI',
    longitude: 27.085998,
    latitude: 62.644012,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Ylämylly',
    stationShortCode: 'YLY',
    stationUICCode: 913,
    countryCode: 'FI',
    longitude: 29.564534,
    latitude: 62.620557,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Ylöjärvi',
    stationShortCode: 'YLÖ',
    stationUICCode: 211,
    countryCode: 'FI',
    longitude: 23.58604,
    latitude: 61.556526,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Ypykkävaara',
    stationShortCode: 'YPY',
    stationUICCode: 940,
    countryCode: 'FI',
    longitude: 29.474923,
    latitude: 64.47993200000001,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Äetsä',
    stationShortCode: 'ÄS',
    stationUICCode: 174,
    countryCode: 'FI',
    longitude: 22.706473,
    latitude: 61.296677,
  },
  {
    passengerTraffic: true,
    type: 'STATION',
    stationName: 'Ähtäri',
    stationShortCode: 'ÄHT',
    stationUICCode: 265,
    countryCode: 'FI',
    longitude: 24.064036,
    latitude: 62.553265,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Ämmänsaari',
    stationShortCode: 'ÄM',
    stationUICCode: 394,
    countryCode: 'FI',
    longitude: 28.890466,
    latitude: 64.887024,
  },
  {
    passengerTraffic: false,
    type: 'STATION',
    stationName: 'Äänekoski',
    stationShortCode: 'ÄKI',
    stationUICCode: 252,
    countryCode: 'FI',
    longitude: 25.747629,
    latitude: 62.596862,
  },
]

export type PunctualityStation = {
  stationName: string
  stationShortCode: string
  stationUICCode: number
  countryCode: string
  showForCargo: boolean // Tavaraliikenne,
  showForCommuter: boolean // Lähiliikenne,
  showForLocomotive: boolean // Veturi,
  showForLongDistance: boolean // Kaukoliikenne,
  showForOnTrackMachines: boolean // Työkone,
  showForShunting: boolean // Vaihtotyöliikenne,
  showForTestDrive: boolean // Koeajo
}

const punctualityStations: Array<PunctualityStation> = [
  {
    stationName: 'Espoo',
    stationShortCode: 'EPO',
    stationUICCode: 66,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: true,
    showForLocomotive: false,
    showForLongDistance: false,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Haapajärvi',
    stationShortCode: 'HPJ',
    stationUICCode: 330,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Haapamäki',
    stationShortCode: 'HPK',
    stationUICCode: 200,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Hamina',
    stationShortCode: 'HMA',
    stationUICCode: 527,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Hanko asema',
    stationShortCode: 'HNK',
    stationUICCode: 73,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Hanko tavara',
    stationShortCode: 'HNKT',
    stationUICCode: 1317,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Heinävaara',
    stationShortCode: 'HÄV',
    stationUICCode: 924,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Helsinki asema',
    stationShortCode: 'HKI',
    stationUICCode: 1,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: true,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Huopalahti',
    stationShortCode: 'HPL',
    stationUICCode: 72,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: true,
    showForLocomotive: false,
    showForLongDistance: false,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Hyrynsalmi',
    stationShortCode: 'HYS',
    stationUICCode: 392,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Hämeenlinna',
    stationShortCode: 'HL',
    stationUICCode: 47,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: true,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Iisalmi',
    stationShortCode: 'ILM',
    stationUICCode: 420,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Ilomantsi',
    stationShortCode: 'ILO',
    stationUICCode: 459,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Imatra asema',
    stationShortCode: 'IMR',
    stationUICCode: 603,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Imatra tavara',
    stationShortCode: 'IMT',
    stationUICCode: 502,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Inkeroinen',
    stationShortCode: 'IKR',
    stationUICCode: 530,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Joensuu asema',
    stationShortCode: 'JNS',
    stationUICCode: 460,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Joensuu Peltola',
    stationShortCode: 'PLT',
    stationUICCode: 1070,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Joensuu Sulkulahti',
    stationShortCode: 'SUL',
    stationUICCode: 1071,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Juurikorpi',
    stationShortCode: 'JRI',
    stationUICCode: 535,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Jyväskylä',
    stationShortCode: 'JY',
    stationUICCode: 240,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Jämsä',
    stationShortCode: 'JÄS',
    stationUICCode: 204,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kajaani',
    stationShortCode: 'KAJ',
    stationUICCode: 387,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kannonkoski',
    stationShortCode: 'KSI',
    stationUICCode: 256,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Karjaa',
    stationShortCode: 'KR',
    stationUICCode: 60,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: true,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kauppilanmäki',
    stationShortCode: 'KPL',
    stationUICCode: 423,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Keitelepohja',
    stationShortCode: 'KTP',
    stationUICCode: 257,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kemi',
    stationShortCode: 'KEM',
    stationUICCode: 347,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kemijärvi',
    stationShortCode: 'KJÄ',
    stationUICCode: 367,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kerava asema',
    stationShortCode: 'KE',
    stationUICCode: 20,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: true,
    showForLocomotive: false,
    showForLongDistance: false,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Keuruu',
    stationShortCode: 'KEU',
    stationUICCode: 235,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Kirkkonummi',
    stationShortCode: 'KKN',
    stationUICCode: 63,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: true,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Kirkniemi',
    stationShortCode: 'KRN',
    stationUICCode: 79,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kitee',
    stationShortCode: 'KIT',
    stationUICCode: 453,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kokemäki',
    stationShortCode: 'KKI',
    stationUICCode: 170,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kokkola',
    stationShortCode: 'KOK',
    stationUICCode: 312,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kolari',
    stationShortCode: 'KLI',
    stationUICCode: 358,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kommila',
    stationShortCode: 'KMM',
    stationUICCode: 500,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kontiomäki',
    stationShortCode: 'KON',
    stationUICCode: 390,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kotka Hovinsaari',
    stationShortCode: 'HOS',
    stationUICCode: 980,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kotka Mussalo',
    stationShortCode: 'MSS',
    stationUICCode: 557,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kotkan satama',
    stationShortCode: 'KTS',
    stationUICCode: 644,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kouvola asema',
    stationShortCode: 'KV',
    stationUICCode: 480,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: true,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kouvola lajittelu',
    stationShortCode: 'KVLA',
    stationUICCode: 1132,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kouvola Oikoraide',
    stationShortCode: 'OIK',
    stationUICCode: 133,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kouvola tavara',
    stationShortCode: 'KVT',
    stationUICCode: 1134,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kuopio asema',
    stationShortCode: 'KUO',
    stationUICCode: 408,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kuopio tavara',
    stationShortCode: 'KUOT',
    stationUICCode: 1139,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kuusankoski',
    stationShortCode: 'KUK',
    stationUICCode: 537,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Kymi',
    stationShortCode: 'KY',
    stationUICCode: 534,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Lahnaslampi',
    stationShortCode: 'LHN',
    stationUICCode: 871,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Lahti',
    stationShortCode: 'LH',
    stationUICCode: 100,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: true,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Lapinjärvi',
    stationShortCode: 'LPJ',
    stationUICCode: 108,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Lappeenranta',
    stationShortCode: 'LR',
    stationUICCode: 495,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Lappohja',
    stationShortCode: 'LPO',
    stationUICCode: 75,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Lentoasema',
    stationShortCode: 'LEN',
    stationUICCode: 1332,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: true,
    showForLocomotive: false,
    showForLongDistance: false,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Leppävaara',
    stationShortCode: 'LPV',
    stationUICCode: 68,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: true,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Lieksa',
    stationShortCode: 'LIS',
    stationUICCode: 468,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Loimaa',
    stationShortCode: 'LM',
    stationUICCode: 142,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Luumäki',
    stationShortCode: 'LÄ',
    stationUICCode: 487,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Maanselkä',
    stationShortCode: 'MLK',
    stationUICCode: 382,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Mikkeli',
    stationShortCode: 'MI',
    stationUICCode: 546,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Moskova (Leningradski)',
    stationShortCode: 'MVA',
    stationUICCode: 6007,
    countryCode: 'RU',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Myllyoja',
    stationShortCode: 'MYL',
    stationUICCode: 606,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Mynttilä',
    stationShortCode: 'MYT',
    stationUICCode: 543,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Mäntsälä',
    stationShortCode: 'MLÄ',
    stationUICCode: 27,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: true,
    showForLocomotive: false,
    showForLongDistance: false,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Mänttä',
    stationShortCode: 'MÄN',
    stationUICCode: 198,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Mäntyharju',
    stationShortCode: 'MR',
    stationUICCode: 544,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Niirala',
    stationShortCode: 'NRL',
    stationUICCode: 446,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Nurmes',
    stationShortCode: 'NRM',
    stationUICCode: 472,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Orivesi',
    stationShortCode: 'OV',
    stationUICCode: 190,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Oulainen',
    stationShortCode: 'OU',
    stationUICCode: 322,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Oulu asema',
    stationShortCode: 'OL',
    stationUICCode: 370,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Oulu Nokela',
    stationShortCode: 'NOK',
    stationUICCode: 1195,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Oulu tavara',
    stationShortCode: 'OLT',
    stationUICCode: 1197,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Oulunkylä',
    stationShortCode: 'OLK',
    stationUICCode: 15,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: true,
    showForLocomotive: false,
    showForLongDistance: false,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Parikkala',
    stationShortCode: 'PAR',
    stationUICCode: 510,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Parkano',
    stationShortCode: 'PKO',
    stationUICCode: 215,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Pello',
    stationShortCode: 'PEL',
    stationUICCode: 358,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Pesiökylä',
    stationShortCode: 'PSK',
    stationUICCode: 393,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Pieksämäki asema',
    stationShortCode: 'PM',
    stationUICCode: 400,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Pieksämäki lajittelu',
    stationShortCode: 'PMLA',
    stationUICCode: 1210,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Pieksämäki tavara',
    stationShortCode: 'PMT',
    stationUICCode: 1211,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Pieksämäki Temu',
    stationShortCode: 'TMU',
    stationUICCode: 1212,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Pietari (Finljandski)',
    stationShortCode: 'PTR',
    stationUICCode: 3820,
    countryCode: 'RU',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Pietarsaari',
    stationShortCode: 'PTS',
    stationUICCode: 306,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: true,
    showForTestDrive: true,
  },
  {
    stationName: 'Pihtipudas',
    stationShortCode: 'PP',
    stationUICCode: 258,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Pori',
    stationShortCode: 'PRI',
    stationUICCode: 220,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Pyhäsalmi',
    stationShortCode: 'PHÄ',
    stationUICCode: 331,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: true,
    showForTestDrive: true,
  },
  {
    stationName: 'Pännäinen',
    stationShortCode: 'PNÄ',
    stationUICCode: 305,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Raahe',
    stationShortCode: 'RHE',
    stationUICCode: 335,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: true,
    showForTestDrive: true,
  },
  {
    stationName: 'Raide934',
    stationShortCode: 'R934',
    stationUICCode: 99998,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Rauma',
    stationShortCode: 'RMA',
    stationUICCode: 165,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Riihimäki asema',
    stationShortCode: 'RI',
    stationUICCode: 40,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: true,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Riihimäki lajittelu',
    stationShortCode: 'RILA',
    stationUICCode: 1238,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Riihimäki tavara',
    stationShortCode: 'RIT',
    stationUICCode: 1240,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Ristijärvi',
    stationShortCode: 'RJV',
    stationUICCode: 391,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Rovaniemi',
    stationShortCode: 'ROI',
    stationUICCode: 364,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'R060',
    stationShortCode: 'R060',
    stationUICCode: 98999,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: true,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Saarijärvi',
    stationShortCode: 'SRJ',
    stationUICCode: 254,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Salo',
    stationShortCode: 'SLO',
    stationUICCode: 55,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Savonlinna asema',
    stationShortCode: 'SL',
    stationUICCode: 521,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Seinäjoki asema',
    stationShortCode: 'SK',
    stationUICCode: 280,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Siilinjärvi Asema',
    stationShortCode: 'SIJ',
    stationUICCode: 413,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Sköldvik',
    stationShortCode: 'SLD',
    stationUICCode: 560,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Suonenjoki',
    stationShortCode: 'SNJ',
    stationUICCode: 404,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Tampere asema',
    stationShortCode: 'TPE',
    stationUICCode: 160,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: true,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Tampere tavara',
    stationShortCode: 'TPET',
    stationUICCode: 1273,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Tampere Viinikka',
    stationShortCode: 'VKA',
    stationUICCode: 1274,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Tikkurila asema',
    stationShortCode: 'TKL',
    stationUICCode: 18,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: true,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Toijala',
    stationShortCode: 'TL',
    stationUICCode: 150,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: true,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Tornio-Itäinen',
    stationShortCode: 'TRI',
    stationUICCode: 1318,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Turku asema',
    stationShortCode: 'TKU',
    stationUICCode: 130,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Turku satama',
    stationShortCode: 'TUS',
    stationUICCode: 135,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Turku tavara',
    stationShortCode: 'TKUT',
    stationUICCode: 1285,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Tuupovaara',
    stationShortCode: 'TPV',
    stationUICCode: 458,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Uimaharju',
    stationShortCode: 'UIM',
    stationUICCode: 465,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Vaala',
    stationShortCode: 'VAA',
    stationUICCode: 377,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Vaasa',
    stationShortCode: 'VS',
    stationUICCode: 288,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: true,
    showForTestDrive: true,
  },
  {
    stationName: 'Vainikkala asema',
    stationShortCode: 'VNA',
    stationUICCode: 492,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Valtimo',
    stationShortCode: 'VLM',
    stationUICCode: 475,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Vammala',
    stationShortCode: 'VMA',
    stationUICCode: 176,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Vantaankoski',
    stationShortCode: 'VKS',
    stationUICCode: 839,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: true,
    showForLocomotive: false,
    showForLongDistance: false,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Varkaus',
    stationShortCode: 'VAR',
    stationUICCode: 432,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Vartius',
    stationShortCode: 'VUS',
    stationUICCode: 941,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Vihanti',
    stationShortCode: 'VTI',
    stationUICCode: 334,
    countryCode: 'FI',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Viipuri',
    stationShortCode: 'VYB',
    stationUICCode: 2000,
    countryCode: 'RU',
    showForCargo: false,
    showForCommuter: false,
    showForLocomotive: false,
    showForLongDistance: true,
    showForOnTrackMachines: false,
    showForShunting: false,
    showForTestDrive: false,
  },
  {
    stationName: 'Vilppula',
    stationShortCode: 'VLP',
    stationUICCode: 196,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Vuokatti',
    stationShortCode: 'VKT',
    stationUICCode: 383,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: true,
    showForTestDrive: true,
  },
  {
    stationName: 'Ylivieska',
    stationShortCode: 'YV',
    stationUICCode: 320,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: true,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Ämmänsaari',
    stationShortCode: 'ÄM',
    stationUICCode: 394,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
  {
    stationName: 'Äänekoski',
    stationShortCode: 'ÄKI',
    stationUICCode: 252,
    countryCode: 'FI',
    showForCargo: true,
    showForCommuter: false,
    showForLocomotive: true,
    showForLongDistance: false,
    showForOnTrackMachines: true,
    showForShunting: false,
    showForTestDrive: true,
  },
]

export const getStation = (shortCode: string): Station | undefined => {
  return stations.find((station) => station.stationShortCode === shortCode)
}

export const getPunctualityStation = (shortCode: string): PunctualityStation | undefined => {
  return punctualityStations.find((station) => station.stationShortCode === shortCode)
}

export const getName = (shortCode: string): string => {
  const station = getStation(shortCode)
  return station?.stationName ?? shortCode
}

export const getPunctualityStationName = (shortCode: string): string => {
  const station = getPunctualityStation(shortCode)
  return station ? station.stationName : getName(shortCode)
}

export const showStationInPunctualityListing = (
  shortCode: string,
  trainCategory: string
): boolean => {
  const station = getPunctualityStation(shortCode)
  return station
    ? (trainCategory === 'Cargo' && station.showForCargo) ||
        (trainCategory === 'Commuter' && station.showForCommuter) ||
        (trainCategory === 'Locomotive' && station.showForLocomotive) ||
        (trainCategory === 'Long-distance' && station.showForLongDistance) ||
        (trainCategory === 'On-track-machines' && station.showForOnTrackMachines) ||
        (trainCategory === 'Shunting' && station.showForShunting) ||
        (trainCategory === 'Test drive' && station.showForTestDrive)
    : false
}
