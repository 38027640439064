import styled from '@emotion/styled'
import { Router } from 'found'
import { TFunction } from 'i18next'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import {
  ChangeViewFunction,
  confirmCompositions,
  fetchCompositions,
  loadCompositionFromHistory,
} from '../actions/api'
import { openModal } from '../actions/compositions'
import { toggleShortNumbers } from '../actions/system'
import BrakeCheckTimestamp from '../components/compositions/BrakeCheckTimestamp'
import CarList from '../components/compositions/CarList'
import InspectionAndCheckButtons from '../components/compositions/InspectionAndCheckButtons'
import LocomotiveList from '../components/compositions/LocomotiveList'
import RouteList from '../components/compositions/RouteList'
import { ConfirmCheckOrInspection } from '../components/compositions/types'
import LoadingIndicator from '../components/LoadingIndicator'
import {
  Container,
  Content,
  Footer,
  FooterText,
  HeaderText,
  PageHeader,
} from '../components/page/PageComponents'
import config from '../config'
import ArrowLeft from '../icons/ArrowLeft'
import Close from '../icons/Close'
import Exclamation from '../icons/Exclamation'
import Refresh from '../icons/Refresh'
import moment from '../lib/moment-fi'
import { nightModeSelector, shortNumberSelector } from '../Selectors'
import { color, getColor, Theme, theme } from '../Theme'
import { Action, AppState, Compositions, Dispatch, Modal, Moment } from '../types'
import { MatchProps } from '../types/App'
import { Locomotive, Route } from '../types/Input'

interface TabSelectorProps {
  selected: boolean
  highlight?: boolean
}

interface ActionIconProps {
  loading?: boolean
}

const HeaderContainer = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  @media (min-width: ${theme.maxWidths.content}) {
    width: 432px;
  }
`

const ActionIcon = styled.div<ActionIconProps>`
  align-content: start;
  display: flex;
  padding-left: 8px;
  padding-right: 8px;
  color: ${(p) => getColor(p.theme, ['primaryBlue'], ['white'])};
`

const Row = styled.div`
  padding-top: 16px;
  ${theme.layout.flexRow};
`

const TabSelector = styled.div<TabSelectorProps>`
  ${(p) =>
    p.selected ? theme.text('smaller', 'content', 'bold') : theme.text('smaller', 'content')};
  color: ${(p) =>
    p.selected
      ? getColor(p.theme, ['black'], ['white'])
      : getColor(p.theme, ['grayDark'], p.highlight ? ['grayDark'] : ['grayPink'])};
  ${(p) =>
    p.highlight ? 'background: ' + getColor(p.theme, ['primaryYellow'], ['primaryYellow']) : ''};
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom: 1px solid ${(p) => getColor(p.theme, ['white'], ['grayDark'])};
  text-transform: uppercase;
  padding: 16px;
  display: flex;
  align-items: center;
  min-width: 100px;
`

const TabContainer = styled(Row)`
  padding-top: 32px;
  justify-content: space-evenly;
`

const Note = styled.div`
  margin-top: 10px;
  color: ${(p) => getColor(p.theme, ['black'], ['white'])};
`

const WarningNote = styled(Note)`
  font-weight: 700;
`

const Title = styled.div`
  ${theme.layout.flexRow}
  justify-content: center;
  align-items: center;
  color: ${(p) => getColor(p.theme, ['black'], ['grayLight'])};
`

const TitleTrain = styled.div`
  ${theme.text('larger', 'content', 'bold')};
  padding-right: 5px;
`

const ErrorText = styled.div`
  ${theme.text('large')};
  color: ${(p) => getColor(p.theme, ['black'], ['white'])};
`

type Props = {
  t: TFunction
  router: Router
  loading: boolean
  error: string
  now: Moment
  isMobile: boolean
  nightMode: boolean
  shortNumbers: boolean
  params: unknown
  theme: Theme
  composition?: Compositions
  posting: boolean
  fetchCompositions: (
    date: string,
    trainNumber: string,
    station: string,
    changeView?: ChangeViewFunction
  ) => unknown
  confirmCheckOrInspection: ConfirmCheckOrInspection
  loadCompositionFromHistory: (arg0: string, arg1: string, arg2: string) => void
  confirmCompositions: ConfirmCheckOrInspection
  onToggle: () => void
  modal: Modal
  openModal: (modalType: Modal) => void
  match: MatchProps
}

type State = {
  tab: string
  notesRead: boolean
}

const BW_FINAL = 'jp-lopullinen'
//const BW_FORECAST = "jp-ennakoitu";

const columnHeaderColor = (nightMode: boolean, composition?: Compositions) => {
  const successColor = getColor({ nightMode }, ['shallowGreen'], ['shallowGreen'])
  const failureColor = getColor({ nightMode }, ['shallowRed'], ['shallowRed'])
  let headerColor = ''
  let brakeHeaderColor = ''

  if (composition && composition.sourceSystem === 'KAPU') {
    headerColor =
      composition.route && composition.route.trafficInspectionCompleted
        ? successColor
        : failureColor
    brakeHeaderColor =
      composition.route &&
      composition.route.brakeConditionCheckCompleted &&
      composition.route.smallBrakeCheckCompleted
        ? headerColor
        : failureColor

    return { headerColor, brakeHeaderColor }
  }
  if (composition && composition.sourceSystem === 'RCS') {
    if (
      composition.route.status === 'V' &&
      composition.route.listStatus.toLowerCase().startsWith(BW_FINAL)
    ) {
      headerColor = successColor
    } else {
      headerColor = failureColor
    }
    // if (composition.route.status === 'E' && composition.route.listStatus.toLowerCase().startsWith(BW_FORECAST)) {
    //   return getColor({ nightMode }, ['shallowRed'], ['shallowRed']);
    // }

    // Kun junalle on tehty Liikennöintitarkastus ja V-vahvistus, mutta ei TJT:tä palautuu RalliBusista status = "-" ja listStatus = "-"
    // Tämä johtuu siitä, että RalliBusissa ei ole RSC-Kulkuri statustekstien kääntötaulussa käsitelty tapausta V-vahvistus + TJT tekemättä
    // Taulukoiden värityksen logiikkaa voisi järkevöittää, kun Kulkuri kuopataan
  }
  return { headerColor, brakeHeaderColor }
}

const getTitle = (route: Route, t: TFunction) => {
  const startStation = route.firstStation.stationAbbreviation.toUpperCase()
  const endStation = route.lastStation.stationAbbreviation.toUpperCase()
  const date = moment(route.departureDate).format('DD.MM.YYYY')
  return (
    <Title>
      <TitleTrain>
        {t('compositionPageTrainNumber', {
          trainType: route.trainType,
          trainNumber: route.trainNumber,
        })}
      </TitleTrain>
      {t('compositionPageTitle', { startStation, endStation, date })}
    </Title>
  )
}

const removeSNJFromNotes = (note: string): string => {
  const splits = note.split('SNJ')
  splits[1] = splits[1].substring(splits[1].indexOf(',') + 1)

  return splits[0] + splits[1]
}

class CompositionPage extends Component<Props, State> {
  static displayName: string

  constructor(props: Props) {
    super(props)
    this.state = {
      tab: 'composition',
      notesRead: false,
    }
  }
  componentDidMount() {
    const pathParameters = this.props.match.params[0].split('/')
    if (pathParameters.length === 3) {
      this.props.fetchCompositions(pathParameters[0], pathParameters[2], pathParameters[1])
    }
    if (pathParameters.length === 4 && pathParameters[3] === 'cached') {
      this.props.loadCompositionFromHistory(pathParameters[0], pathParameters[2], pathParameters[1])
    }
  }

  back() {
    window.history.back()
  }

  openTab(tabKey: string) {
    this.setState({
      tab: tabKey,
      notesRead: this.state.notesRead ? true : tabKey === 'notes',
    })
  }

  reloadComposition() {
    const pathParameters = this.props.match.params[0].split('/')
    this.props.fetchCompositions(pathParameters[0], pathParameters[2], pathParameters[1], () =>
      this.props.router.replace(
        `/composition/${pathParameters[0]}/${pathParameters[1]}/${pathParameters[2]}`
      )
    )
  }

  highlightNotes(composition?: Compositions) {
    return (
      !this.state.notesRead &&
      composition &&
      composition.noteList &&
      composition.noteList.length > 0
    )
  }

  render() {
    const {
      t,
      composition,
      nightMode,
      shortNumbers,
      onToggle,
      loading,
      router,
      error,
      posting,
      modal,
      openModal,
    }: Props = this.props
    const { headerColor, brakeHeaderColor } = columnHeaderColor(nightMode, composition)
    return (
      <Container>
        <PageHeader flexDirection="column">
          <HeaderContainer>
            <Row>
              <HeaderText>{t('compositions')}</HeaderText>
              <ActionIcon onClick={() => this.reloadComposition()} loading={loading}>
                <Refresh iconSize="normal" />
              </ActionIcon>
              <ActionIcon onClick={() => this.back()}>
                <Close iconSize="normal" />
              </ActionIcon>
            </Row>
            {!loading &&
              composition?.route &&
              composition?.resultCode === '0000' &&
              getTitle(composition.route, t)}
            {!loading && (
              <TabContainer>
                <TabSelector
                  selected={this.state.tab === 'composition'}
                  onClick={() => this.openTab('composition')}
                >
                  {t('composition')}
                </TabSelector>
                <TabSelector
                  selected={this.state.tab === 'notes'}
                  highlight={this.highlightNotes(composition)}
                  onClick={() => this.openTab('notes')}
                >
                  {t('notes')}
                  {this.highlightNotes(composition) ? (
                    <Exclamation iconSize="smallest" fillColor={color('white')} />
                  ) : (
                    ''
                  )}
                </TabSelector>
              </TabContainer>
            )}
          </HeaderContainer>
        </PageHeader>
        {loading ? (
          <LoadingIndicator size="normal" padded={true} />
        ) : (error && error !== '') || composition?.resultCode !== '0000' ? (
          <ErrorText>
            <div>{t(error) || composition?.resultDescription}</div>
            {composition?.sourceSystem === 'RCS' && (
              <div>
                {t('backupCompositionText')}
                <a
                  href="https://vempu.ext.vilant.com/traininfo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('backupCompositionLinkText')}
                </a>
              </div>
            )}
          </ErrorText>
        ) : (
          composition !== undefined && (
            <Content>
              {this.state.tab === 'notes' ? (
                <div>
                  {composition.warningNotes && (
                    <WarningNote>{composition.warningNotes}</WarningNote>
                  )}
                  {composition.noteList?.map((note, index) => {
                    if (
                      config.environment === 'prod' &&
                      composition.sourceSystem === 'RCS' &&
                      note.indexOf('SNJ') >= 0
                    ) {
                      note = removeSNJFromNotes(note)
                    }
                    return (
                      <div key={'note-' + index}>
                        <Note>{note}</Note>
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div>
                  <InspectionAndCheckButtons
                    confirmCheckOrInspection={this.props.confirmCompositions}
                    posting={posting}
                    route={composition.route}
                    queryDate={moment(this.props.match.params[0].split('/')[0]).format(
                      'YYYY-MM-DD'
                    )}
                    sourceSystem={composition.sourceSystem}
                    openModal={openModal}
                    modal={modal}
                  />
                  <RouteList
                    color={headerColor}
                    brakeHeaderColor={brakeHeaderColor}
                    title={getTitle(composition.route, t)}
                    route={composition.route}
                    brakeRegime={composition.route.brakeRegime}
                    noteList={composition.noteList}
                    sourceSystem={composition.sourceSystem}
                    tractionPower={composition.tractionPower}
                    departureTrack={composition.departureTrack}
                  />
                  <BrakeCheckTimestamp
                    route={composition.route}
                    sourceSystem={composition.sourceSystem}
                  />
                  {composition.route.locomotives?.length && (
                    <LocomotiveList
                      color={headerColor}
                      locomotives={composition.route.locomotives}
                      router={router}
                      sourceSystem={composition.sourceSystem}
                      brakeRegime={composition.route.brakeRegime}
                    />
                  )}
                  {composition.route.cars?.length && (
                    <CarList
                      cars={composition.route.cars}
                      color={headerColor}
                      router={router}
                      sourceSystem={composition.sourceSystem}
                      shortNumbers={shortNumbers}
                      onToggle={onToggle}
                    />
                  )}
                </div>
              )}
            </Content>
          )
        )}
        <Footer onClick={() => window.history.back()}>
          <ArrowLeft iconSize="normal" />
          <FooterText>{t('back')}</FooterText>
        </Footer>
      </Container>
    )
  }
}

CompositionPage.displayName = 'CompositionPage'

const mapStateToProps = (state: AppState) => {
  return {
    loading: state.compositions.loading,
    composition: state.compositions.compositionForTrain,
    nightMode: nightModeSelector(state) as boolean,
    shortNumbers: shortNumberSelector(state) as boolean,
    posting: state.compositions.posting,
    error: state.compositions.error,
    modal: state.compositions.modal,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCompositions: (
    date: string,
    trainNumber: string,
    station: string,
    changeView?: ChangeViewFunction
  ) => dispatch(fetchCompositions(date, trainNumber, station, changeView) as unknown as Action),
  loadCompositionFromHistory: (date: string, station: string, trainNumber: string) =>
    dispatch(loadCompositionFromHistory(date, station, trainNumber) as unknown as Action),
  confirmCompositions: (
    trainNumber: string,
    trainDate: string,
    queryDate: string,
    countryCode: string,
    locationCode: string,
    sourceSystem?: string,
    checkDateTime?: string,
    type?: string,
    locomotives?: Array<Locomotive> | null,
    secondAction?: string | null
  ) =>
    dispatch(
      confirmCompositions(
        trainNumber,
        trainDate,
        queryDate,
        countryCode,
        locationCode,
        sourceSystem,
        checkDateTime,
        type,
        locomotives,
        secondAction
      ) as unknown as Action
    ),
  openModal: (modal: Modal) => dispatch(openModal(modal)),
  onToggle: () => dispatch(toggleShortNumbers()),
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CompositionPage))
