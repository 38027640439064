import { Box, Link, Typography } from '@mui/material'
import { TFunction } from 'i18next'
import moment from 'moment-timezone'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LANGUAGE_ORDER } from '../../constants'
import { LiitoMessage, MessageType } from './types'

const activeLinkStyle = { fontWeight: 'bold', textDecoration: 'none' }

const getLinkList = (
  t: TFunction,
  presentLanguages: Array<string>,
  selectedLang: string,
  setLang: (lang: string) => void
) => {
  return presentLanguages.map((lang) => {
    return (
      <Link
        component="button"
        variant="body2"
        sx={{ ...(selectedLang === lang && activeLinkStyle) }}
        onClick={() => setLang(lang)}
        key={lang}
      >
        {t(`languages.${lang}`, { defaultValue: lang })}
      </Link>
    )
  })
}

type Props = {
  messages: Array<LiitoMessage>
  messageType: MessageType
}

const languageComparator = (l1, l2) => (LANGUAGE_ORDER[l1] ?? 3) - (LANGUAGE_ORDER[l2] ?? 3)

const IncidentMessage = ({ messages, messageType }: Props) => {
  const { t } = useTranslation()
  const [lang, setLang] = useState('')

  const availableLanguages = messages.map((message) => message.language.toUpperCase())
  availableLanguages.sort(languageComparator)
  if (lang === '') {
    setLang(availableLanguages[0])
  }

  return (
    <Box
      sx={(theme) => ({
        ...theme.layout.flexColumn,
        alignItems: 'flex-start',
        paddingTop: theme.spacing('small'),
        width: '100% ',
      })}
    >
      {messages.map((message) => {
        return (
          <Box sx={{ width: '100%' }} key={message.id}>
            {lang === message.language.toUpperCase() && (
              <>
                <Typography variant="h6">{message.subject}</Typography>
                <Typography sx={{ whiteSpace: 'pre-wrap' }}>{message.body}</Typography>
                <Box
                  sx={(theme) => ({
                    ...theme.layout.flexRow,
                    justifyContent: 'space-between',
                    paddingTop: theme.spacing('smallest'),
                  })}
                >
                  <Typography variant="body2" sx={{ width: '40%' }}>
                    {moment(message.date).format('DD.MM.YYYY HH:mm')}
                  </Typography>
                  <Typography variant="body2" sx={{ width: '30%' }}>
                    {t(`incidents.${messageType.toLowerCase()}`)}
                  </Typography>
                  <Box sx={{ width: '30%' }}>
                    {messageType === 'CUSTOMER' && (
                      <Box
                        sx={(theme) => ({
                          ...theme.layout.flexRow,
                          justifyContent: 'space-evenly',
                        })}
                      >
                        {getLinkList(t, availableLanguages, lang, setLang)}
                      </Box>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Box>
        )
      })}
    </Box>
  )
}

export default IncidentMessage
