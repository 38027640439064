import styled from '@emotion/styled'

import { color, defaultTextColor, theme } from '../Theme'
import { ColorProps } from '../types/App'

const Text = styled.div<Partial<ColorProps>>`
  ${theme.text()};
  color: ${(p) => (p.color ? color(p.color) : 'inherit')};
  display: inline-block;
  text-decoration: inherit;
`

export const DefaultText = styled.div`
  ${theme.text()};
  color: ${defaultTextColor};
  white-space: pre-line;
`

export default Text
