import styled from '@emotion/styled'
import React, {
  ChangeEvent,
  Children,
  cloneElement,
  Fragment,
  isValidElement,
  PropsWithChildren,
  ReactNode,
  useState,
} from 'react'

import { theme } from '../../Theme'

type DebugValuePanelProps = {
  label: string
  children: ReactNode
}

type IsSerializableValue = string | number | boolean | object
type IsSerializableValueArray = IsSerializableValue[]
type Serializable = IsSerializableValueArray | IsSerializableValue

const Panel = styled.div`
  ${theme.layout.flexColumn};
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 3px;
  background: white;
`
const DebugValuePanel = ({ label, ...props }: DebugValuePanelProps) => {
  const [visible, setVisible] = useState(false)

  const getInitialProps = (children: ReactNode) => {
    let initialProps: PropsWithChildren<Record<string, object>> | undefined = undefined
    React.Children.forEach(children, (child) => {
      if (React.isValidElement(child)) {
        initialProps = child.props
      }
    })
    return initialProps as PropsWithChildren<Record<string, object>> | undefined
  }

  const initialProps = getInitialProps(props.children)

  const isSerializable = (value?: Serializable): boolean => {
    if (!value) {
      return true
    }
    if (Array.isArray(value)) {
      return value.every((v) => isSerializable(v))
    } else if (typeof value === 'object') {
      return Object.values(value).every((v) => isSerializable(v))
    } else if (typeof value === 'function') {
      return false
    }
    return true
  }

  const plainInitialProps = Object.keys(initialProps || {}).reduce(
    (acc, curr) => {
      if (isSerializable(initialProps?.[curr])) {
        acc[curr] = initialProps?.[curr]
      }
      return acc
    },
    {} as Record<string, unknown>
  )

  const [localValue, setLocalValue] = useState(JSON.stringify(plainInitialProps, null, 2))

  const [passedProps, setPassedProps] = useState(plainInitialProps)
  const [error, setError] = useState('')

  const Toggle = () => (
    <button onClick={() => setVisible(!visible)}>{visible ? 'sulje' : `avaa ${label}`}</button>
  )

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setLocalValue(e.target.value)
  }

  const onSubmit = () => {
    try {
      setPassedProps(JSON.parse(localValue))
    } catch (err: any) {
      setError(err.toString())
    }
  }

  const onReset = () => {
    setLocalValue(JSON.stringify(plainInitialProps, null, 2))
    setPassedProps(plainInitialProps)
  }

  const stopPropagation = (e: { stopPropagation: () => void }) => {
    e.stopPropagation()
  }

  return (
    <>
      <Panel onClick={stopPropagation}>
        {visible ? (
          <div>
            <span>{label}</span>
            <br />
            <textarea value={localValue} onChange={onChange} />
            <br />
            {error}
            <br />
            <button onClick={onSubmit}>Syötä uudet arvot</button>
            <button onClick={onReset}>Reset</button>

            <Toggle />
          </div>
        ) : (
          <Toggle />
        )}
      </Panel>
      {Children.map(props.children, (child) =>
        isValidElement(child) ? (
          cloneElement(child, { ...child.props, ...passedProps })
        ) : (
          <Fragment />
        )
      )}
    </>
  )
}

export default DebugValuePanel
