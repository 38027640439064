import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React, { Component, ErrorInfo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { postVersionInfo } from '../lib/data'
import { visitURL } from '../lib/url'
import { defaultTextColor, theme } from '../Theme'
import { Action, Dispatch } from '../types'
import Button from './button/Button'
import ConstrainToColumn from './ConstrainToColumn'
import { FooterPage } from './page/Page'

const ErrorText = styled.div`
  ${theme.text('larger', 'decorative', 'bold')};
  color: ${defaultTextColor};
  margin: ${theme.spacing.sizes.huge} 0;
  max-width: 100%;
  text-align: center;
`

const ErrorTitle = styled(ErrorText)`
  ${theme.text('largest', 'decorative', 'bold')}
`
type Props = {
  t: TFunction
  openFrontPage: () => void
  children: any
}

type State = {
  hasError: boolean
  error?: Error
  errorInfo?: ErrorInfo
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  // for some reason eslint thinks this is unused
  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(_: Error) {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    })

    // error itself serializes as an empty object since its properties are not enumerable
    // additionally toJSON is not available on all supported browsers
    const errorObject: Record<string, any> = {}
    Object.getOwnPropertyNames(error).forEach((name) => {
      errorObject[name] = error[name as keyof Error]
    })
    postVersionInfo({
      message: 'Uncaught error in frontend',
      error: errorObject,
      errorInfo,
    })
  }

  clearError = () => {
    this.setState({ hasError: false })
    this.props.openFrontPage()
  }

  render() {
    if (this.state.hasError) {
      return (
        <FooterPage>
          <ConstrainToColumn>
            <ErrorTitle>{this.props.t('errors.general.unknown')}</ErrorTitle>
            <ErrorText>{this.props.t('ifProblemPersists')}</ErrorText>
            <Button onClick={() => this.clearError()}>{this.props.t('returnToFrontPage')}</Button>
          </ConstrainToColumn>
        </FooterPage>
      )
    }

    return this.props.children
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openFrontPage: () => dispatch(visitURL('/') as unknown as Action),
})

export default connect(() => ({}), mapDispatchToProps)(withTranslation()(ErrorBoundary))
