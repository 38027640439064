import { combineReducers } from 'redux'

import {
  RESET_ERROR,
  RESET_SELECTED_CAUSE,
  SEND_AMENDMENT_ERROR,
  SET_CAUSE_GROUP,
  SET_CAUSE_LEVEL1,
  SET_CAUSE_LEVEL2,
  START_SENDING_AMENDMENT,
  UPDATE_AMENDMENT,
} from '../actions/amendment'
import { Action } from '../types'
import { SendStatus } from '../types/States'

const amendmentSending = (state = 'ready' as SendStatus, action: Action): SendStatus => {
  switch (action.type) {
    case START_SENDING_AMENDMENT:
      return 'sending' as SendStatus
    case UPDATE_AMENDMENT:
      return 'sent' as SendStatus
    case SEND_AMENDMENT_ERROR:
      return 'ready' as SendStatus
    case RESET_SELECTED_CAUSE:
      return 'ready' as SendStatus
    default:
      return state
  }
}

const amendmentError = (state = '', action: Action) => {
  switch (action.type) {
    case START_SENDING_AMENDMENT:
      return ''
    case UPDATE_AMENDMENT:
      return ''
    case SEND_AMENDMENT_ERROR:
      return action.payload
    case RESET_ERROR:
      return ''
    default:
      return state
  }
}

const selectedGroup = (state: string | null = null, action: Action): string | null | undefined => {
  switch (action.type) {
    case SET_CAUSE_GROUP:
      return action.payload
    case RESET_SELECTED_CAUSE:
      return null
    default:
      return state
  }
}

const selectedFirstLevelCause = (
  state: string | null = null,
  action: Action
): string | null | undefined => {
  switch (action.type) {
    case SET_CAUSE_LEVEL1:
      return action.payload
    case RESET_SELECTED_CAUSE:
      return null
    default:
      return state
  }
}

const selectedSecondLevelCause = (
  state: string | null = null,
  action: Action
): string | null | undefined => {
  switch (action.type) {
    case SET_CAUSE_LEVEL2:
      return action.payload
    case RESET_SELECTED_CAUSE:
      return null
    default:
      return state
  }
}

const amendmentReducer = combineReducers({
  sendStatus: amendmentSending,
  error: amendmentError,
  selectedGroup,
  selectedFirstLevelCause,
  selectedSecondLevelCause,
})

export default amendmentReducer
