import styled from '@emotion/styled'
import React from 'react'

import { color } from '../../Theme'
import { Color } from '../../types'

type Props = {
  color: Color
}

const SIZE = 12
const BORDER = 1
const INNER_SIZE = SIZE - BORDER * 2
const BOX_SIZE = INNER_SIZE / 2

const Border = styled.div<Props>`
  display: inline-block;
  position: relative;
  width: ${SIZE}px;
  height: ${SIZE}px;
  border: ${BORDER}px solid ${(p) => color(p.color)};
  border-radius: ${SIZE}px;
  overflow: hidden;
`

const InnerCircle = styled.div`
  position: absolute;
  top: ${BORDER}px;
  left: ${BORDER}px;
  width: ${INNER_SIZE}px;
  height: ${INNER_SIZE}px;
  border-radius: ${INNER_SIZE}px;
  overflow: hidden;
`

const Box = styled.div<Props>`
  position: absolute;
  top: 0;
  left: ${BOX_SIZE}px;
  width: ${BOX_SIZE}px;
  height: ${INNER_SIZE}px;
  background: ${(p) => color(p.color)};
`

const Box2 = styled.div<Props>`
  position: absolute;
  top: ${BOX_SIZE}px;
  left: 0;
  width: ${BOX_SIZE}px;
  height: ${BOX_SIZE}px;
  background: ${(p) => color(p.color)};
`

function DurationIcon({ color }: Props) {
  return (
    <Border color={color}>
      <InnerCircle color={color}>
        <Box color={color} />
        <Box2 color={color} />
      </InnerCircle>
    </Border>
  )
}
export default DurationIcon
