import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const Refresh = ({ iconSize, fillColor }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={size} height={size} viewBox="0 0 22 22">
      <g fill="none" fillRule="evenodd">
        <path
          d="M4.299 4.299C7.994.604 14.006.604 17.7 4.299c2.256 2.255 3.127 5.374 2.629 8.302l1.097-.294c.387-3.112-.602-6.364-2.986-8.748-4.103-4.103-10.78-4.103-14.882 0L1.725 1.725.112 7.746l6.02-1.613L4.3 4.299zM21.888 14.254l-6.02 1.613 1.833 1.834c-3.695 3.695-9.707 3.695-13.402 0C2.043 15.445 1.172 12.327 1.67 9.4l-1.097.294c-.386 3.112.602 6.364 2.986 8.748 4.103 4.102 10.78 4.102 14.882 0l1.834 1.834 1.613-6.021z"
          fill={fillColor ?? 'currentColor'}
        />
      </g>
    </svg>
  )
}

export default Refresh
