import React from 'react'

import { formActions } from '../../../lib/towingUtils'
import TowingConfirmContainer from './TowingConfirmContainer'
import TowingCounterElementList from './TowingCounterElementList'
import TowingSelection from './TowingSelection'
import TowingStep from './TowingStep'

interface TowingElementListProps {
  elements: any[] | undefined
  counterElements?: unknown[] | undefined
  formType?: string
  formAction: string
}

interface TowingFormRootProps {
  root: any
  formAction: string
  counterRoot?: any
  formType: string
}

export const TowingElementList = ({
  elements,
  counterElements,
  formType,
  formAction,
}: TowingElementListProps) => (
  <>
    {elements.map((el, i) => {
      if (el.contentType === 'towingSelection') {
        return (
          <TowingSelection key={(el.name || el.key) + i} selection={el} formAction={formAction} />
        )
      } else if (el.contentType !== 'towingFunction') {
        return (
          <TowingStep
            step={el}
            key={(el.key || el.name) + i}
            stepText={el.preContent && el.preContent[0].title}
            formAction={formAction}
          />
        )
      } else if (
        el.contentType === 'towingFunction' &&
        counterElements &&
        (el.type === 'COUNTER_ACTIONS_FOR_SETUP_STEPS_IN_REVERSE_ORDER' ||
          el.type === 'COUNTER_ACTIONS_FOR_START_STEPS_IN_REVERSE_ORDER')
      ) {
        return (
          // eslint-disable-next-line react/jsx-key
          <TowingCounterElementList
            elements={counterElements}
            formType={formType}
            formAction={formAction}
          />
        )
      }

      return undefined
    })}
  </>
)

const TowingFormRoot = ({ root, counterRoot, formType, formAction }: TowingFormRootProps) => {
  return (
    <div>
      <div>
        {counterRoot ? (
          <>
            <TowingElementList
              elements={root.elements}
              counterElements={counterRoot.elements}
              formType={formType}
              formAction={formAction}
            />
            <TowingConfirmContainer
              elements={root.elements}
              counterElements={counterRoot.elements}
              formType={formType}
            />
          </>
        ) : (
          <>
            <TowingElementList elements={root.elements} formAction={formAction} />
            {formAction === formActions.EDIT && (
              <TowingConfirmContainer elements={root.elements} formType={formType} />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default TowingFormRoot
