import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const Document = ({ iconSize, fillColor }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          fill={fillColor ?? 'currentColor'}
          fillRule="nonzero"
          d="M20.55,2 L3.45,2 C3.18,2 3,2.18181818 3,2.45454545 L3,21.5454545 C3,21.8181818 3.18,22 3.45,22 L16.05,22 C16.185,22 16.275,21.9545455 16.365,21.8636364 L20.865,17.3181818 C20.955,17.2272727 21,17.1363636 21,17 L21,2.45454545 C21,2.18181818 20.82,2 20.55,2 Z M3.9,2.90909091 L20.1,2.90909091 L20.1,16.5454545 L16.05,16.5454545 C15.78,16.5454545 15.6,16.7272727 15.6,17 L15.6,21.0909091 L3.9,21.0909091 L3.9,2.90909091 Z M19.47,17.4545455 L16.5,20.4545455 L16.5,17.4545455 L19.47,17.4545455 Z M8.85,8.36363636 C8.85,8.09090909 9.03,7.90909091 9.3,7.90909091 L16.5,7.90909091 C16.77,7.90909091 16.95,8.09090909 16.95,8.36363636 C16.95,8.63636364 16.77,8.81818182 16.5,8.81818182 L9.3,8.81818182 C9.03,8.81818182 8.85,8.63636364 8.85,8.36363636 Z M7.05,11.0909091 C7.05,10.8181818 7.23,10.6363636 7.5,10.6363636 L16.5,10.6363636 C16.77,10.6363636 16.95,10.8181818 16.95,11.0909091 C16.95,11.3636364 16.77,11.5454545 16.5,11.5454545 L7.5,11.5454545 C7.23,11.5454545 7.05,11.3636364 7.05,11.0909091 Z M7.5,14.2727273 C7.23,14.2727273 7.05,14.0909091 7.05,13.8181818 C7.05,13.5454545 7.23,13.3636364 7.5,13.3636364 L16.5,13.3636364 C16.77,13.3636364 16.95,13.5454545 16.95,13.8181818 C16.95,14.0909091 16.77,14.2727273 16.5,14.2727273 L7.5,14.2727273 Z"
        />
      </g>
    </svg>
  )
}

export default Document
