import React, { useEffect, useRef } from 'react'

type Props = {
  ownId: string
  equipmentId: string
  children: React.ReactNode
}

const EquipmentScrollWrapper = ({ ownId, equipmentId, children }: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const scroll = () => scrollRef.current?.scrollIntoView()

  useEffect(() => {
    if (equipmentId === ownId) {
      scroll()
    }
  }, [ownId, equipmentId])

  return <div ref={scrollRef}>{children}</div>
}

export default EquipmentScrollWrapper
