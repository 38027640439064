import { TowingVehiclePattern } from '../types'
import { StepState } from '../types/Input'

export const stepStates: Record<string, StepState> = {
  DONE: StepState.DONE,
  COUNTERED_IN_SETUP: StepState.COUNTERED_IN_SETUP,
  COUNTERED_IN_FINISH: StepState.COUNTERED_IN_FINISH,
  REVERSED: StepState.REVERSED,
  CLEARED: StepState.CLEARED,
}

export const formStates = {
  READY: 'READY',
  HANDOVER: 'HANDOVER',
  IN_SETUP: 'IN_SETUP',
  SETUP_DONE: 'SETUP_DONE',
  START_IN_PROGRESS: 'START_IN_PROGRESS',
  IN_TOWING: 'IN_TOWING',
  STOP_IN_PROGRESS: 'STOP_IN_PROGRESS',
  FINISH_IN_PROGRESS: 'FINISH_IN_PROGRESS',
  FINISHED: 'FINISHED',
}

export const vehicleStates = {
  READY: 'READY',
  HANDOVER: 'HANDOVER',
  IN_SETUP: 'IN_SETUP',
  SETUP_DONE: 'SETUP_DONE',
  START_IN_PROGRESS: 'START_IN_PROGRESS',
  IN_TOWING: 'IN_TOWING',
  STOP_IN_PROGRESS: 'STOP_IN_PROGRESS',
  FINISH_IN_PROGRESS: 'FINISH_IN_PROGRESS',
}

export const formActions = {
  SHOW_SELECTIONS_WITHOUT_STEPS: 'SHOW_SELECTIONS_WITHOUT_STEPS',
  SHOW_SELECTIONS_AND_STEPS: 'SHOW_SELECTIONS_AND_STEPS',
  EDIT: 'EDIT',
}

export const getVehicleCompound = (vehicleType: string, vehicleNumber: string): string =>
  `${vehicleType}-${vehicleNumber}`

const inTowingLocomotivePattern = /^(\d+)H$/

export const locomotiveIsInTowing = (locomotive: string): boolean => {
  return inTowingLocomotivePattern.test(locomotive)
}

export const getVehicleNumberFromTowedLocomotive = (locomotive: string): string => {
  const result = inTowingLocomotivePattern.exec(locomotive)
  return (result && result[1]) || ''
}

export const mapLocsToNumbers = (locomotives: Array<string>): Array<string> => {
  return locomotives.map((loc) =>
    locomotiveIsInTowing(loc) ? getVehicleNumberFromTowedLocomotive(loc) : loc
  )
}

export const getCompoundFromLocomotive = (
  locomotive: string,
  towingPatterns: Array<TowingVehiclePattern>
): string | undefined => {
  const searchNumber = locomotiveIsInTowing(locomotive)
    ? getVehicleNumberFromTowedLocomotive(locomotive)
    : locomotive
  const pattern = towingPatterns.find(
    (pattern) => pattern && pattern.expression && RegExp(pattern.expression).test(searchNumber)
  )
  if (!pattern) return undefined

  const matches = RegExp(pattern.expression).exec(searchNumber)
  return matches ? getVehicleCompound(pattern.equipmentType, matches[1]) : undefined
}

export const locomotiveHasPattern = (
  loc: string,
  towingPatterns: Array<TowingVehiclePattern>
): boolean => {
  const trimmedVehicleNumber = locomotiveIsInTowing(loc)
    ? getVehicleNumberFromTowedLocomotive(loc)
    : loc
  return towingPatterns.some((pattern) => RegExp(pattern.expression).test(trimmedVehicleNumber))
}

export const getVehicleTypeFromTowedLocomotive = (
  locomotive: string,
  towingPatterns: Array<TowingVehiclePattern>
): string | undefined => {
  const vehicleNumber = getVehicleNumberFromTowedLocomotive(locomotive)
  const towingPattern = towingPatterns.find((a) => RegExp(a.expression).test(vehicleNumber))
  return towingPattern ? towingPattern.equipmentType : undefined
}
