import styled from '@emotion/styled'

import { getColor } from '../Theme'

const VerticalLayout = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    border-bottom: 1px solid ${(p) => getColor(p.theme, ['grayBlue'], ['nightSeparator'])};

    &:last-child {
      border-bottom: 1px transparent;
    }
  }
`

export default VerticalLayout
