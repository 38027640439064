/* eslint no-console: "off" */

export const error = (...msg: unknown[]): void => {
  //TODO: send errors to somewhere for analysis
  console.error(...msg)
}

export const info = (...msg: unknown[]): void => {
  console.log(...msg)
}

const logger = { info, error }

export default logger
