import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React from 'react'
import { withTranslation } from 'react-i18next'

import changelog_dev from '../changelog-dev'
import changelog_prod from '../changelog-prod'
import changelog_test from '../changelog-test'
import Header from '../components/header/Header'
import { FooterPage } from '../components/page/Page'
import config from '../config'
import { MOMENT_SHIFTLIST_DATE_FORMAT } from '../constants'
import moment from '../lib/moment-fi'
import { defaultTextColor, subtleTextColor, theme } from '../Theme'

const Container = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  ${theme.spacing.sides('small')};
`

const Title = styled.div`
  ${theme.spacing.ends('huge')};
  ${theme.text('largest', 'decorative', 'bold')};
  text-align: center;
  color: ${defaultTextColor};
`

const Version = styled.div`
  ${theme.spacing.bottom('huge')};
`

const Subtitle = styled.div`
  ${theme.text('large')};
  color: ${defaultTextColor};
`

const ListTitle = styled.div`
  ${theme.text('normal')};
  ${theme.spacing.top('tiny')};
  color: ${subtleTextColor};
`

const Change = styled.div`
  ${theme.spacing.top('normal')};
`

const ChangeTitle = styled.div`
  ${theme.text('normal', 'content', 'bold')};
  ${theme.spacing.bottom('tiny')};
  color: ${defaultTextColor};
`

const Text = styled.div`
  ${theme.text('normal', 'content')};
  color: ${defaultTextColor};
`

type Props = {
  t: TFunction
}

const AboutPage = ({ t }: Props) => {
  const changelog =
    config.environment === 'dev'
      ? changelog_dev
      : config.environment === 'test'
      ? changelog_test
      : changelog_prod
  const closeUrl = '/options'

  return (
    <FooterPage>
      <Header
        border={true}
        title={t('aboutApp')}
        links={{
          prev: { url: closeUrl, disabled: false },
          next: null,
          close: { url: closeUrl, disabled: false },
        }}
      >
        <div></div>
      </Header>
      <Container>
        <Title>{t('changeLog')}</Title>
        {changelog.map((version, i) => (
          <Version key={i}>
            <Subtitle>
              {moment(version.timestamp).format(MOMENT_SHIFTLIST_DATE_FORMAT)} – {version.number}
            </Subtitle>
            <ListTitle>{t('changes')}</ListTitle>
            {version.changes.map((change, j) => (
              <Change key={j}>
                <ChangeTitle>{t(`changeType.${change.type}`, { title: change.title })}</ChangeTitle>
                <Text>{change.description}</Text>
              </Change>
            ))}
          </Version>
        ))}
      </Container>
    </FooterPage>
  )
}

AboutPage.displayName = 'AboutPage'

export default withTranslation()(AboutPage)
