import { useParams } from 'found'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useTypedDispatch, useTypedSelector } from '../..'
import { fetchServiceOrderAction } from '../../actions/serviceOrders'
import { fetchActionsAction } from '../../actions/serviceOrders/actions'
import { MediumErrorText } from '../../components/ErrorText'
import LoadingIndicator from '../../components/LoadingIndicator'
import BasicFooter from '../../components/page/BasicFooter'
import BasicHeader from '../../components/page/BasicHeader'
import { Container } from '../../components/page/PageComponents'
import ActionsContainer from '../../components/serviceOrders/actions/ActionsContainer'
import { Action, ServiceOrder, Task } from '../../components/serviceOrders/types'
import { selectActions, selectActionsStatus } from '../../reducers/serviceOrders/actionsSlice'
import {
  selectSelectedServiceOrder,
  selectSelectedTask,
  updateSelectedServiceOrderTask,
} from '../../reducers/serviceOrders/serviceOrdersSlice'

const ActionsPage = (): JSX.Element => {
  const { orderId, activityId, taskId } = useParams()

  const { t } = useTranslation()
  const dispatch = useTypedDispatch()

  const selectedServiceOrder = useTypedSelector<ServiceOrder | undefined>((state) =>
    selectSelectedServiceOrder(state)
  )

  const key = `${orderId}:${activityId}:${taskId}`
  const actions = useTypedSelector<Action[]>((state) => selectActions(state, key))

  const actionsStatus = useTypedSelector<string>((state) => selectActionsStatus(state, key))

  const selectedTask = useTypedSelector<Task | undefined>((state) => selectSelectedTask(state))

  useEffect(() => {
    if (actions?.length === 0 && actionsStatus !== 'loading') {
      dispatch(fetchActionsAction({ orderId, activityId, taskId }))
    }
    if (!selectedServiceOrder) {
      dispatch(fetchServiceOrderAction(orderId))
    }
    if (!selectedTask) {
      if (selectedServiceOrder) {
        const task = selectedServiceOrder.activities
          .find((activity) => activity.id.toString() === activityId)
          ?.tasks.find((task) => task.id.toString() === taskId)
        dispatch(updateSelectedServiceOrderTask(task))
      }
    }
  }, [
    activityId,
    orderId,
    taskId,
    actions,
    dispatch,
    selectedServiceOrder,
    selectedTask,
    actionsStatus,
  ])

  return (
    <>
      <Container>
        <BasicHeader
          title={t('serviceOrder.actions.list.title', {
            workshopBooking: selectedServiceOrder?.activities.at(0)?.workshopBooking,
            installationGroup: selectedServiceOrder?.installationGroup,
          })}
        />
        {(() => {
          switch (actionsStatus) {
            case 'loading':
              return <LoadingIndicator size="normal" padded={true} />
            case 'failed':
              return <MediumErrorText>{t('errors.actions.failed')}</MediumErrorText>
            case 'succeeded':
              return <ActionsContainer actions={actions} taskCode={selectedTask?.taskCode || ''} />
            default:
              return <></>
          }
        })()}
      </Container>
      <BasicFooter />
    </>
  )
}

export default ActionsPage
