import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const Personnel = ({ iconSize, width }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={width ?? size} height={size} viewBox="0 0 20 18">
      <g fill="none">
        <rect width="64" height="34" rx="3" transform="translate(-23 -8)" />
        <g fill="currentColor">
          <path
            fillOpacity="0.5"
            d="M10 10a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v3a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1 1 1 0 0 1-1 1H1a1 1 0 0 1-1-1v-3a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3ZM5 6a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm10 0a3 3 0 1 1 0-6 3 3 0 0 1 0 6Z"
          />
          <path d="M10 10a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm-3 1h6a3 3 0 0 1 3 3v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a3 3 0 0 1 3-3Z" />
        </g>
      </g>
    </svg>
  )
}

export default Personnel
