import {
  SET_OBSERVATION_MESSAGE_ERROR,
  START_SENDING_OBSERVATION_MESSAGE,
  UPDATE_OBSERVATION_MESSAGES,
} from '../actions/observationMessages'
import { ObservationMessageAction } from '../actions/observationMessages'
import { ObservationMessagesState } from '../types'

export const initialState = {
  loading: false,
  error: '',
  observationMessages: [],
}

const observationMessagesReducer = (
  state: ObservationMessagesState = initialState,
  action: ObservationMessageAction
): ObservationMessagesState => {
  switch (action.type) {
    case START_SENDING_OBSERVATION_MESSAGE:
      return {
        ...state,
        loading: true,
        error: '',
      }

    case UPDATE_OBSERVATION_MESSAGES:
      return {
        ...state,
        loading: false,
        error: '',
        observationMessages: action.payload,
      }

    case SET_OBSERVATION_MESSAGE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

export default observationMessagesReducer
