import { Box, Card, Typography } from '@mui/material'
import { useRouter } from 'found'

interface SearchToolCardProps {
  title: string
  icon: JSX.Element
  link: string
}

const SearchToolCard = ({ title, icon, link }: SearchToolCardProps) => {
  const { router } = useRouter()

  return (
    <div onClick={() => router.push(link)}>
      <Card
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: '8px 8px 0',
          width: '150px',
          height: '120px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '63px',
            color: 'pageTitle',
          }}
        >
          {icon}
        </Box>

        <Typography variant="body2" component="h2" sx={{ fontSize: '14px' }}>
          {title}
        </Typography>
      </Card>
    </div>
  )
}

export default SearchToolCard
