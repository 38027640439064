import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const Info = ({ iconSize }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={size} height={size} viewBox="-9 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          key="0"
          stroke="currentColor"
          d="M2,2.5 C2,1.12 3.12,0 4.5,0 C5.88,0 7,1.12 7,2.5 C7,3.88 5.88,5 4.5,5 C3.12,5 2,3.88 2,2.5"
        />
        <path
          key="1"
          stroke="currentColor"
          d="M5.5,7.5 L0.5,7.5 L0.5,10.7857143 L2.5,10.7857143 L2.5,18.4157834 L0.5,19.1369369 L0.5,21.5 L7.5,21.5 L7.5,19.13537 L5.5,18.4047032 L5.5,7.5 Z"
        />
      </g>
    </svg>
  )
}

export default Info
