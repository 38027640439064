import styled from '@emotion/styled'
import React from 'react'

import { theme } from '../Theme'
import { SelectedProps } from '../types/App'

const Check = styled.input<SelectedProps>`
  display: inline-block;
  width: ${theme.spacing.sizes.large};
  height: ${theme.spacing.sizes.large};
  margin-right: ${theme.spacing.sizes.large};
  border-radius: ${theme.borderRadius.button};
`
/* eslint-disable @typescript-eslint/no-empty-function */
const noop = () => {}

type Props = {
  selected: boolean
  disabled: boolean
  onClick: () => void
}

const Checkbox = ({ selected, disabled, onClick }: Props) => (
  <Check
    selected={selected}
    type="checkbox"
    disabled={disabled}
    readOnly={true}
    checked={selected}
    onClick={disabled ? noop : onClick}
  />
)

export default Checkbox
