import { color } from '../Theme'
import { getSize, sizes } from '../theme/iconSizes'
import IconProps from './Icon'

const Train2 = ({ iconSize, width, fillColor = color('white') }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg
      width={width ?? (size === sizes.default ? 16 : size)}
      height={size === 24 ? 22 : size}
      viewBox="0 0 16 22"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="TrainIcon-VR" transform="translate(1.000000, 0.000000)" stroke={fillColor}>
          <g>
            <path
              d="M11.8817616,3.00481917 C12.4024985,3.00481917 13.5198162,4.66134776 13.5198162,6.06644621 L13.5198162,6.06644621 L13.5198162,17.1776576 L-7.50102203e-11,17.1776576 L-7.50102203e-11,17.1776576 L-7.50102203e-11,6.06644621 C-7.50102203e-11,4.66134776 1.1173177,3.00481917 1.63805458,3.00481917 L1.63805458,3.00481917 Z M0.11,12.2 L0.11,17 L3.61,17 L3.12564951,13.16746 L0.11,12.2 Z M13.43,12.2 L10.4143505,13.16746 L9.93,17 L13.43,17 L13.43,12.2 Z M9.7615006,4 L3.92043213,4 C2.9939399,4 1,4.95759018 1,7.00440772 L1,7.00440772 L1,9.73256142 C1,10.0299316 1.22017019,11.0285918 2.39357171,11.0285918 L2.39357171,11.0285918 L11.288361,11.0285918 C12.4617625,11.0285918 12.6819327,10.0299316 12.6819327,9.73256142 L12.6819327,9.73256142 L12.6819327,7.00440772 C12.6819327,4.95759018 10.6879928,4 9.7615006,4 L9.7615006,4 Z"
              id="Mainbody"
              fill={fillColor}
            ></path>
            <g id="Truck-frame" transform="translate(2.000000, 19.200000)">
              <polyline
                id="LowerFrame"
                points="4.75981622 1.34567203 0.378727212 1.34567203 0.188504452 0.953220645 4.75981622 0.953220645 4.76 0.953220645 9.33131176 0.953220645 9.141089 1.34567203 4.76 1.34567203"
              ></polyline>
              <polyline
                id="RightWheel"
                points="2.23815469 1.64944634 2.23815469 1.88624769 3.24329488 1.88624769 3.24329488 1.64944634"
              ></polyline>
              <polyline
                id="LeftWheel"
                transform="translate(6.779091, 1.767847) scale(-1, 1) translate(-6.779091, -1.767847) "
                points="6.27652134 1.64944634 6.27652134 1.88624769 7.28166152 1.88624769 7.28166152 1.64944634"
              ></polyline>
            </g>
            <polyline
              id="UpperTruckframe"
              points="6.75981622 18.5529396 0 18.5529396 0 18.7470604 6.75981622 18.7470604 6.76 18.7470604 13.5198162 18.7470604 13.5198162 18.5529396 6.76 18.5529396"
            ></polyline>
            <path
              d="M2.55152869,0.930127644 C2.47550753,0.930127644 2.36811809,1.17670921 2.22936038,1.66987236 L6.7693731,1.66987236 L11.3116681,1.66987236 C11.1769078,1.17670921 11.0701618,0.930127644 10.9914299,0.930127644 C10.9126981,0.930127644 9.50540709,0.930127644 6.76955688,0.930127644 C4.03355924,0.930127644 2.62754984,0.930127644 2.55152869,0.930127644 Z"
              id="Pantograph"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Train2
