import styled from '@emotion/styled'
import React from 'react'

import { px } from '../Theme'
import { ImageProps } from '../types/App'

const imagePath = (number: number, type: string) => `/illustrations/${type}/${number}`

interface ImgProps extends ImageProps {
  offset: number
  brightness: number
  url: string
}

const Img = styled.div<ImgProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: ${(p) => (p.offset ? px(p.offset) : '0')};
  opacity: ${(p) => (p.theme.nightMode ? 0.3 : 1)};
  filter: brightness(${(p) => p.brightness}%);
  background-image: url(${(p) => p.url}.png);
`

type Props = {
  season: string
  brightness: number
  simple: boolean
}

function SeasonalScenery({ simple, season, brightness }: Props) {
  return (
    <div>
      <Img offset={180} brightness={brightness} url={imagePath(0, season)} />
      {simple ? undefined : <Img offset={240} brightness={brightness} url={imagePath(1, season)} />}
      {simple ? undefined : <Img offset={360} brightness={brightness} url={imagePath(2, season)} />}
    </div>
  )
}

export default SeasonalScenery
