import { CSSObject, PaletteMode, ThemeOptions } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import React, { ReactNode, useMemo, useState } from 'react'

import { ColorModeContext } from '../ColorModeContext'
import iconSizes from './iconSizes'
import layout from './layout'
import colors from './MuiColors'
import { commonPalette, darkPalette, lightPalette } from './palette'
import spacing from './spacing'
import typography from './typography'

export const breakpoints = {
  mobile: 0,
  tablet: 768,
  desktop: 1280,
} as const

export enum Mode {
  LIGHT = 'light',
  DARK = 'dark',
}

export const MuiTheme = ({ children }: { children: ReactNode }): JSX.Element => {
  const [mode, setMode] = useState<PaletteMode>(
    localStorage.getItem('nightMode') === 'true' ? Mode.DARK : Mode.LIGHT
  )

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === Mode.LIGHT ? Mode.DARK : Mode.LIGHT))
      },
    }),
    []
  )

  const theme = useMemo(
    () => createTheme({ ...getDesignTokens(mode), ...getThemedComponents(mode) }),
    [mode]
  )

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  )
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false
    sm: false
    md: false
    lg: false
    xl: false
    mobile: true
    tablet: true
    desktop: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    submitButtonPrimary: true
    submitButtonSecondary: true
    submitButtonFullWidth: true
    submitButtonHalfWidth: true
    submitButtonDialogPrimary: true
    submitButtonDialogSecondary: true
    submitButtonReset: true
  }
}

const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  palette: {
    mode,
    ...commonPalette,
    ...(mode === Mode.LIGHT ? { ...lightPalette } : { ...darkPalette }),
  },
  typography,
  iconSizes,
  spacing,
  layout,
  breakpoints: {
    values: breakpoints,
  },
  components: {},
})

const createButtonBaseStyle = (mode: PaletteMode, drawBorder = true): CSSObject => ({
  border: drawBorder
    ? `1px solid ${mode === Mode.LIGHT ? lightPalette.background?.border : colors.info.light}`
    : 'none',
  borderRadius: '4px',
  textTransform: 'none',
  width: '120px',
})

const getThemedComponents = (mode: PaletteMode): ThemeOptions => ({
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'submitButtonPrimary' },
          style: {
            ...createButtonBaseStyle(mode, mode !== Mode.LIGHT),
            background: mode === Mode.LIGHT ? colors.info.main : colors.info.light,
            color: mode === Mode.LIGHT ? commonPalette.common?.white : commonPalette.common?.black,
            '&:hover': {
              backgroundColor: mode === Mode.LIGHT ? '#0065B3' : '#008FCC',
            },
          },
        },
        {
          props: { variant: 'submitButtonSecondary' },
          style: {
            ...createButtonBaseStyle(mode),
            background:
              mode === Mode.LIGHT
                ? commonPalette.common?.white
                : darkPalette.background?.altSecondary,
            color: mode === Mode.LIGHT ? colors.info.dark : colors.info.light,
            '&:hover': {
              backgroundColor:
                mode === Mode.LIGHT
                  ? commonPalette.common?.white
                  : darkPalette.background?.altSecondary,
            },
          },
        },
        {
          props: { variant: 'submitButtonFullWidth' },
          style: {
            ...createButtonBaseStyle(mode),
            background:
              mode === Mode.LIGHT
                ? commonPalette.common?.kenttaBlue
                : darkPalette.background?.altSecondary,
            color: colors.common.white,
            '&:hover': {
              backgroundColor:
                mode === Mode.LIGHT
                  ? commonPalette.common?.kenttaBlue
                  : darkPalette.background?.altSecondary,
            },
            '&:disabled': {
              backgroundColor:
                mode === Mode.LIGHT
                  ? commonPalette.grey[200]
                  : darkPalette.background?.altSecondary,
              border: mode === Mode.DARK ? `1px solid ${darkPalette.text.secondary}` : 'none',
            },
            width: '100%',
            maxWidth: breakpoints.tablet,
            padding: '10px 0 10px 0',
          },
        },
        {
          props: { variant: 'submitButtonHalfWidth' },
          style: {
            ...createButtonBaseStyle(mode),
            background:
              mode === Mode.LIGHT
                ? commonPalette.common?.kenttaBlue
                : darkPalette.background?.altSecondary,
            color: colors.common.white,
            '&:hover': {
              backgroundColor:
                mode === Mode.LIGHT
                  ? commonPalette.common?.kenttaBlue
                  : darkPalette.background?.altSecondary,
            },
            width: '50%',
            maxWidth: breakpoints.tablet,
            padding: '10px 0 10px 0',
          },
        },
        {
          props: { variant: 'submitButtonDialogPrimary' },
          style: {
            ...createButtonBaseStyle(mode, false),
            minHeight: '48px',
            background: colors.info.main,
            color: commonPalette.common?.white,
            '&:hover': {
              backgroundColor: '#0065B3',
            },
          },
        },
        {
          props: { variant: 'submitButtonDialogSecondary' },
          style: {
            ...createButtonBaseStyle(mode, false),
            minHeight: '48px',
            background: colors.backgroundShades[1],
            color: colors.info.dark,
            '&:hover': {
              backgroundColor: colors.backgroundShades[2],
            },
          },
        },
        {
          props: { variant: 'submitButtonReset' },
          style: {
            width: '120px',
            height: '30px',
            background: mode === Mode.LIGHT ? colors.grey[200] : colors.grey[500],
            borderRadius: '16px',
            padding: '4px',
            color: mode === Mode.LIGHT ? colors.grey[500] : colors.backgroundShades[1],
            '&:hover': {
              backgroundColor:
                mode === Mode.LIGHT ? colors.backgroundShades[2] : colors.backgroundShades[3],
            },
          },
        },
      ],
    },
  },
})

export const darkTheme = createTheme({
  ...getDesignTokens(Mode.DARK),
  ...getThemedComponents(Mode.DARK),
})

export const lightTheme = createTheme({
  ...getDesignTokens(Mode.LIGHT),
  ...getThemedComponents(Mode.LIGHT),
})
