import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const Compositions = ({ iconSize, width }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={width ?? size} height={size} viewBox="0 0 44 17">
      <g stroke="currentColor" fill="none">
        <path
          d="M8.147 14.33c-.1.502-.346.952-.694 1.301a2.52 2.52 0 0 1-1.791.746 2.52 2.52 0 0 1-1.792-.746 2.546 2.546 0 0 1-.694-1.302h4.971ZM16.492 14.33c-.1.502-.347.952-.694 1.301a2.52 2.52 0 0 1-1.792.746 2.52 2.52 0 0 1-1.791-.746 2.546 2.546 0 0 1-.694-1.302h4.971ZM32.423 14.33c-.1.502-.347.952-.694 1.301a2.52 2.52 0 0 1-1.792.746 2.52 2.52 0 0 1-1.79-.746 2.546 2.546 0 0 1-.695-1.302h4.971ZM40.768 14.33c-.1.502-.347.952-.694 1.301a2.52 2.52 0 0 1-1.792.746 2.52 2.52 0 0 1-1.791-.746 2.546 2.546 0 0 1-.694-1.302h4.971Z"
          fill="currentColor"
        />
        <path d="M19.944 13.829h4.304" />
        <path
          d="M.367 13.83h19.577V6.542c-6.485 2.49-13.01 3.902-19.577 4.238"
          fill="currentColor"
          opacity=".7"
        />
        <path d="M.367 1h19.577v5.591C12.186 9.385 5.66 10.781.367 10.781" fill="currentColor" />
        <path
          d="M43.593 13.83H24.248V10.78c7.7 1.15 14.148 1.586 19.345 1.306"
          fill="currentColor"
          opacity=".7"
        />
        <path
          d="M43.593 1H24.276l-.028 9.781c7.406 1.15 13.854 1.586 19.345 1.306"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default Compositions
