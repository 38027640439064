import styled from '@emotion/styled'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { LAHIVEKSI_LINK, NEWS_CRITICALITIES, VEKSI_LINK } from '../../constants'
import { useLocalStorage } from '../../hooks'
import Circle from '../../icons/Circle'
import { error } from '../../lib/logger'
import { DriverType } from '../../lib/roleUtils'
import { color, defaultTextColor, theme } from '../../Theme'
import { News } from '../../types/Input'

const OverflowIcon = styled.div`
  height: 24px;
`

const chooseIconColor = (criticality: string) => {
  switch (criticality) {
    case NEWS_CRITICALITIES.CRITICAL:
      return color('red')
    case NEWS_CRITICALITIES.IMPORTANT:
      return color('primaryYellow')
    case NEWS_CRITICALITIES.NORMAL:
      return color('secondaryGreen')
    default:
      error('Unknown criticality: ' + criticality)
      return color('secondaryGreen')
  }
}

const Container = styled.div`
  padding-top: ${theme.spacing.sizes.normal};
  margin-bottom: ${theme.spacing.sizes.huge};
  margin-top: ${theme.spacing.sizes.small};
`

const ActionsContainer = styled.div`
  ${theme.layout.flexRow};
  width: 100%;
  gap: ${theme.spacing.sizes.smaller};
  padding-top: ${theme.spacing.sizes.smallest};
`

const NewsItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.sizes.normal};
  padding-top: ${theme.spacing.sizes.normal};
  padding-bottom: ${theme.spacing.sizes.normal};
`
const NewsItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.sizes.small};
  align-items: center;
`

const Text = styled.div`
  ${theme.text('mediumLarge', 'content', 500)};
  color: ${color('grayDark')};
`

const NewsFeedName = styled.span`
  ${theme.text('mediumLarge', 'content', 500)};
  color: ${color('primaryBlue')};
`

const DateText = styled.span`
  ${theme.text()};
  color: ${defaultTextColor};
`

const StyledLink = styled.a`
  ${theme.text()};
  color: ${(p) => p.color || defaultTextColor};
  text-decoration: underline;
  cursor: pointer;
`

const StyledButton = styled.span`
  ${theme.text()};
  color: ${color('grayDark')};
  text-decoration: underline;
  cursor: pointer;
`

type Props = {
  news: {
    loading: boolean
    error: string
    news: Array<News>
  }
  role: DriverType
}

const NewsContent = ({ news, role }: { news: News[]; role: DriverType }) => (
  <NewsItemContainer>
    {news.map((item, index) => {
      const date = new Date(item.timestamp)
      return (
        <NewsItem key={index}>
          {role === 'commuter_driver' && (
            <OverflowIcon>
              <Circle fillColor={chooseIconColor(item.category)} />
            </OverflowIcon>
          )}
          <DateText>
            <StyledLink href={item.linkToItem} target="_blank">
              {item.title}
            </StyledLink>{' '}
            ({date.toLocaleDateString('fi-FI', { month: 'numeric', day: 'numeric' })})
          </DateText>
        </NewsItem>
      )
    })}
  </NewsItemContainer>
)

const NewsFeed = ({ news, role }: Props) => {
  const { t } = useTranslation()
  const [hideDateTime, setHideDateTime] = useLocalStorage('clearNewsTS', '')

  const setHideTime = () => {
    setHideDateTime(new Date().toISOString())
  }

  let filteredNews = news.news
  if (hideDateTime) {
    filteredNews = news.news.filter(
      (item) => new Date(item.timestamp) > new Date(hideDateTime as string)
    )
  }

  /* fixes bug with role change forcing rerender before new news are loaded
   * which meant driver news were shown to commuter drivers, and vice versa
   * which is a problem since driver news doesn't have a criticality value
   */
  const newsContent = useMemo(
    () => <NewsContent news={filteredNews} role={role} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredNews]
  )

  return (
    <Container>
      <Text>
        <Trans
          i18nKey={filteredNews.length > 0 ? 'readNews' : 'noNews'}
          values={{ feed: role === 'commuter_driver' ? 'LähiVeksi' : 'Veksi' }}
          components={{ bold: <NewsFeedName /> }}
        />
      </Text>
      {news.loading && <p>{t('load')}...</p>}
      {news.error && <p>{t('errorFetchingNews')}</p>}
      {newsContent}
      <ActionsContainer>
        <StyledLink
          color={color('grayDark')}
          href={role === 'commuter_driver' ? LAHIVEKSI_LINK : VEKSI_LINK}
          target="_blank"
        >
          {t('earlierNews') + ' >>'}
        </StyledLink>
        {filteredNews.length > 0 && (
          <>
            <Text>|</Text>
            <StyledButton onClick={setHideTime}> {t('emptyNews')}</StyledButton>
          </>
        )}
      </ActionsContainer>
    </Container>
  )
}

export default NewsFeed
