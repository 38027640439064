import {
  FETCH_SEARCHED_SHIFT,
  SEARCH_SHIFTS,
  SEARCHED_SHIFTS_ERROR,
  UPDATE_SEARCHED_SHIFTS,
  UPDATE_SELECTED_SHIFT,
  UPDATE_SHIFT_HISTORY,
} from '../actions/shift-search'
import { ShiftSearchAction } from '../actions/shift-search'
import { SearchedShift, SearchedShiftsState, Shift } from '../types'

/* eslint-disable @typescript-eslint/no-empty-function */
const noobj = () => {}

export const initialState = {
  loading: false,
  error: '',
  list: noobj() as unknown as SearchedShift,
  selected: noobj() as unknown as Shift,
  history: [],
}

const searchedShiftsReducer = (
  state: SearchedShiftsState = initialState,
  action: ShiftSearchAction
): SearchedShiftsState => {
  switch (action.type) {
    case UPDATE_SEARCHED_SHIFTS:
      return {
        ...state,
        list: action.payload,
        error: '',
        loading: false,
      }

    case SEARCHED_SHIFTS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case SEARCH_SHIFTS:
      return {
        ...state,
        error: '',
        loading: true,
      }

    case FETCH_SEARCHED_SHIFT:
      return {
        ...state,
        loading: true,
      }

    case UPDATE_SELECTED_SHIFT:
      return {
        ...state,
        selected: action.payload,
        error: '',
        loading: false,
      }

    case UPDATE_SHIFT_HISTORY:
      return {
        ...state,
        history: action.payload,
      }

    default:
      return state
  }
}

export default searchedShiftsReducer
