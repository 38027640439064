import styled from '@emotion/styled'

import { getColor, theme } from '../../Theme'

const SearchHistoryHeader = styled.div`
  font-size: ${theme.text('smaller')};
  color: ${(p) => getColor(p.theme, ['grayWarm'], ['grayDark'])};
  margin-bottom: 16px;
  text-transform: uppercase;
`

export default SearchHistoryHeader
