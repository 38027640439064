import { styled } from '@mui/material/styles'

import { useTypedSelector } from '../..'
import { selectServiceOrders } from '../../reducers/serviceOrders/serviceOrdersSlice'
import ServiceOrderRow from './ServiceOrderRow'
import { ServiceOrder } from './types'

type Props = {
  filterByInstallationGroupOrWB: string
  updateSelectedServiceOrder: (serviceOrder: ServiceOrder) => void
}

const ListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  margin-bottom: 30px;
`

const ServiceOrdersList = ({
  filterByInstallationGroupOrWB,
  updateSelectedServiceOrder,
}: Props): JSX.Element => {
  const serviceOrders = useTypedSelector((state) => selectServiceOrders(state))

  return (
    <ListContainer>
      {serviceOrders
        .filter((serviceOrder) =>
          filterByInstallationGroupOrWB
            ? serviceOrder.installationGroup.includes(filterByInstallationGroupOrWB) ||
              serviceOrder.activities
                .map((a) => a.workshopBooking)
                .filter((w) => w.includes(filterByInstallationGroupOrWB)).length !== 0
            : true
        )
        .map((serviceOrder) => (
          <ServiceOrderRow
            key={serviceOrder.id}
            serviceOrder={serviceOrder}
            updateSelectedServiceOrder={updateSelectedServiceOrder}
          />
        ))}
    </ListContainer>
  )
}

export default ServiceOrdersList
