import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useTypedSelector } from '../../..'
import {
  selectStartWorkshopBookingError,
  selectStartWorkshopBookingStatus,
  selectWorkshopBookingStatus,
} from '../../../reducers/serviceOrders/serviceOrdersSlice'
import { SmallErrorText } from '../../ErrorText'
import { Content } from '../../page/PageComponents'
import ActionRow from '../../serviceOrders/actions/ActionRow'
import { Action } from '../types'
import WorkshopBokingStatus from './WorkshopBookingStatus'

type Props = {
  actions: Array<Action>
  taskCode: string
}

const ActionsContainer = ({ actions, taskCode }: Props): JSX.Element => {
  const { t } = useTranslation()

  const workshopBookingStatus = useTypedSelector((state) => selectWorkshopBookingStatus(state))
  const startWBActionStatus = useTypedSelector((state) => selectStartWorkshopBookingStatus(state))
  const startWBActionError = useTypedSelector((state) => selectStartWorkshopBookingError(state))

  // Check if all but last actions are measured, the last action is the one that completes the whole task
  const containsNotMeasured = actions
    ? !actions.slice(0, -1).every((action) => action.status === 'MEASURED')
    : true

  const containsDefective = actions
    ? actions?.filter((action) => {
        const defectiveId = action.measurementType.values?.find(
          (value) => value.description.toUpperCase() === 'VIALLINEN'
        )?.id
        return defectiveId === action.measuredValueAlphanumeric.toString()
      }).length !== 0
    : false

  return (
    <Content>
      <Box sx={(theme) => ({ paddingY: theme.spacing('normal') })}>
        <WorkshopBokingStatus />
        {startWBActionStatus === 'failed' && (
          <SmallErrorText>
            {t(startWBActionError ?? 'serviceOrder.actions.error.unknown')}
          </SmallErrorText>
        )}
      </Box>
      {actions.map((action) => (
        <ActionRow
          key={action.id}
          parentTaskCode={taskCode}
          containsNotMeasured={containsNotMeasured}
          containsDefective={containsDefective}
          wbNotStarted={workshopBookingStatus?.toUpperCase() !== 'START'}
          action={action}
        />
      ))}
    </Content>
  )
}

export default ActionsContainer
