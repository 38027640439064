import styled from '@emotion/styled'
import React from 'react'

import { color } from '../../../Theme'

interface ButtonTextProps {
  confirmed?: boolean
}

interface ButtonStyleProps extends ButtonTextProps {
  disabled: boolean
  secondary?: boolean
}

const getBackgroundColor = (p: ButtonStyleProps) => {
  if (p.secondary) return color('primaryBlueTinted')
  if (p.confirmed) return p.disabled ? color('primaryYellowTinted') : color('primaryYellow')
  else return p.disabled ? color('grayDisabled') : color('primaryBlue')
}

const ButtonStyle = styled.div<ButtonStyleProps>`
  background-color: ${(p) => getBackgroundColor(p)};
  border-radius: 3px;
  display: flex;
  align-items: center;
  min-height: 40px;
  min-width: ${(p) => (p.confirmed ? '132px' : '90px')};
  justify-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  &[disabled] {
    background-color: ${color('grayDisabled')};
  }
  flex: 1;
`

const ButtonText = styled.div<ButtonTextProps>`
  color: ${(p) => (p.confirmed ? color('black') : color('white'))};
`

type Props = {
  onClick: (syntheticEvent: React.SyntheticEvent) => void
  buttonText: string
  confirmed?: boolean
  disabled: boolean
  secondary?: boolean
}

const TowingConfirmButton = ({ onClick, buttonText, disabled, confirmed, secondary }: Props) => (
  <ButtonStyle
    disabled={disabled}
    confirmed={confirmed}
    secondary={secondary}
    onClick={(e) => {
      if (!disabled) return onClick(e)
    }}
  >
    <ButtonText confirmed={confirmed}>{buttonText}</ButtonText>
  </ButtonStyle>
)

export default TowingConfirmButton
