import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React from 'react'
import { withTranslation } from 'react-i18next'

import { shiftURL } from '../lib/url'
import { theme } from '../Theme'
import { Shift } from '../types'
import Link from './Link'
import Text from './Text'

const Container = styled.div`
  ${theme.spacing.ends('large')};
`

type Props = {
  t: TFunction
  index: number
  shift: Shift
}

const DayOverview = ({ t, shift, index }: Props) => (
  <Container>
    <Text color="grayDark">
      {index > 0 ? t('dayContinuesWith') : t('dayStartsWith')}{' '}
      <Link to={shiftURL(shift.id)}>{shift.shiftId}</Link>. {t('hereIsShiftSummary')}
    </Text>
  </Container>
)

export default withTranslation()(DayOverview)
