import { Box } from '@mui/material'

import AssumeRoles from '../components/dev/AssumeRoles'
import DebugToggle from '../components/dev/DebugToggle'
import PushNotification from '../components/dev/PushNotification'
import VirtualTime from '../components/dev/VirtualTime'
import Page from '../components/page/Page'

const DevPage = () => {
  return (
    <Page menu="options" overflowVisible={true}>
      <Box
        sx={(theme) => ({
          padding: theme.spacing('large'),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        })}
      >
        <VirtualTime />
        <PushNotification />
        <AssumeRoles />
        <DebugToggle />
      </Box>
    </Page>
  )
}

export default DevPage
