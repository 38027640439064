import styled from '@emotion/styled'

import { getColor, theme } from '../../Theme'

const SearchTitle = styled.div`
  padding: ${theme.spacing.sizes.small} 0;
  ${theme.text('large')};
  color: ${(p) => getColor(p.theme, ['grayWarm'], ['grayWarm'])};
  text-transform: uppercase;
`

export default SearchTitle
