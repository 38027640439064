import i18n from 'i18next'

i18n.init({
  fallbackLng: 'fi',

  react: {
    // wait: true, // globally set to wait for loaded translations in translate hoc
    // exposeNamespace: true // exposes namespace on data-i18next-options to be used in eg. locize-editor
  },

  resources: {
    fi: require('./locales/fi.json'),
  },

  // have a common namespace used around the full app
  ns: ['common'],
  defaultNS: 'common',

  debug: process ? process.env.NODE_ENV === 'development' : false,
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },
})

export default i18n
