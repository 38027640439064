import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { getColor, theme } from '../../Theme'
import Link from '../Link'

interface HeaderProps {
  loading?: boolean
}

export const HeaderAction = styled.div<HeaderProps>`
  ${theme.spacing.all('small')};
  line-height: 0.5;
  color: ${(p) => getColor(p.theme, ['primaryBlue'], ['primaryBlue'])};
  cursor: pointer;
  animation: ${(p) =>
    p.loading
      ? css`
          ${theme.animations.spin} 1s linear infinite
        `
      : 'none'};
  animation-direction: reverse;
  transform-origin: center center;
`

const HeaderLink = styled(Link)<HeaderProps>`
  ${theme.spacing.all('small')};
  line-height: 0.5;
  color: ${(p) =>
    getColor(
      p.theme,
      [p.disabled ? 'grayDark' : 'primaryBlue'],
      [p.disabled ? 'grayDark' : 'primaryBlue']
    )};
  animation: ${(p) =>
    p.loading
      ? css`
          ${theme.animations.spin} 1s linear infinite
        `
      : 'none'};
  animation-direction: reverse;
  transform-origin: center center;
`

export default HeaderLink
