import styled from '@emotion/styled'
import React from 'react'

import { color, defaultTextColor, theme } from '../../Theme'

export const Title = styled.h1`
  ${theme.spacing.bottom('small')};
  ${theme.text('small', 'decorative', 'bold')};
  color: ${color('primaryGreen')};
  margin: 0;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
`

export const SubTitle = styled.h2`
  ${theme.text('larger', 'decorative')};
  box-sizing: border-box;
  color: ${defaultTextColor};
  margin: 0;
  text-align: center;
`

export const Container = styled.div`
  ${theme.spacing.all('large')};
  @media (max-width: ${theme.maxWidths.column}) {
    ${theme.spacing.all('tiny')};
  }
  flex: 8;
`

type Props = {
  title: string
  subtitle: JSX.Element | string
}

function HeaderTitle({ title, subtitle }: Props) {
  return (
    <Container>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
    </Container>
  )
}
export default HeaderTitle
