import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React from 'react'
import { withTranslation } from 'react-i18next'

import { theme } from '../../Theme'
import { Contact } from '../../types'
import { ExternalLink } from '../Link'

const PaddedEndsAndLeft = styled.div`
  ${theme.spacing.ends('smallest')};
`

const Column = styled.div`
  ${theme.layout.flexColumn};
`

const Row = styled.div`
  ${theme.layout.flexRow};
  justify-content: space-between;
  width: 100%;
`

type Props = {
  t: TFunction
  contact: Contact
  showLocation?: boolean
}

function ContactCard({ contact }: Props) {
  return (
    <PaddedEndsAndLeft>
      <Column>
        <Row>
          <ExternalLink small={true} href={`tel:${contact.telephone}`}>
            {contact.telephone}
          </ExternalLink>
          <ExternalLink small href={`mailto:${contact.email}`}>
            {contact.email}
          </ExternalLink>
        </Row>
      </Column>
    </PaddedEndsAndLeft>
  )
}

export default withTranslation()(ContactCard)
