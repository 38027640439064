import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'

import { Activity, ServiceOrder, UpdatedServiceOrder } from '../../components/serviceOrders/types'
import { apiGET, apiPOST } from '../../lib/api'

interface ServiceOrdersError {
  message: string
}

const fetchServiceOrders = (installationGroups: string): Promise<ServiceOrder[]> =>
  apiGET(`serviceOrders?installationGroups=${installationGroups}`)

export const fetchServiceOrdersAction = createAsyncThunk<
  ServiceOrder[],
  string,
  { rejectValue: ServiceOrdersError }
>('service-orders/fetchServiceOrders', async (installationGroups, thunkApi) => {
  try {
    return await fetchServiceOrders(installationGroups)
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: 'Failed to fetch service orders',
    })
  }
})

const fetchServiceOrder = (orderId?: string): Promise<ServiceOrder> =>
  apiGET(`serviceOrder?orderId=${orderId || ''}`)

export const fetchServiceOrderAction = createAsyncThunk<
  ServiceOrder,
  string | undefined,
  { rejectValue: ServiceOrdersError }
>('service-orders/fetchServiceOrder', async (orderId, thunkApi) => {
  try {
    return await fetchServiceOrder(orderId)
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: 'Failed to fetch service order',
    })
  }
})

type CompleteActivityParams = {
  orderId: string
  activityId: string
}

const completeActivity = (params: CompleteActivityParams): Promise<Activity> =>
  apiPOST(
    `serviceOrder/completeActivity?orderId=${params.orderId}&activityId=${params.activityId}`,
    {}
  )

export interface ActivityError {
  message: string
}

export const completeActivityAction = createAsyncThunk<
  Activity,
  CompleteActivityParams | undefined,
  { rejectValue: ActivityError }
>('complete-activity', async (object, thunkApi) => {
  try {
    if (!object) {
      return thunkApi.rejectWithValue({
        message: 'Cannot complete activity',
      })
    }
    return await completeActivity(object)
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: 'Failed to complete activity',
    })
  }
})

const getMessageFromServerReturnedError = (err: any): ActivityError => {
  if (err instanceof AxiosError) {
    return { message: err?.response?.data?.error?.message }
  }
  return { message: err.message }
}

type WorkshopBookingStartParams = {
  orderId: string
  activityId: string
  workshopBookingId: string
}

const postWorkshopBookingStart = (
  params: WorkshopBookingStartParams
): Promise<UpdatedServiceOrder> =>
  apiPOST(
    `workshopBooking/start?orderId=${params.orderId}&activityId=${params.activityId}&workshopBookingId=${params.workshopBookingId}`,
    {}
  )

export const startWorkshopBookingAction = createAsyncThunk<
  UpdatedServiceOrder,
  WorkshopBookingStartParams,
  { rejectValue: ActivityError }
>('start-workshop-booking-action', async (object, thunkApi) => {
  try {
    return await postWorkshopBookingStart(object)
  } catch (err) {
    return thunkApi.rejectWithValue(getMessageFromServerReturnedError(err))
  }
})

type WorkshopBookingHandoverParams = {
  orderId: string
  workshopBookingId: string
}

const postWorkshopBookingHandover = (params: WorkshopBookingHandoverParams): Promise<void> =>
  apiPOST(
    `workshopBooking/handover?orderId=${params.orderId}&workshopBookingId=${params.workshopBookingId}`,
    {}
  )

export const handoverWorkshopBookingAction = createAsyncThunk<
  void,
  WorkshopBookingHandoverParams,
  { rejectValue: ServiceOrdersError }
>('complete-service-orders-action', async (params, thunkApi) => {
  try {
    await postWorkshopBookingHandover(params)
  } catch (err) {
    return thunkApi.rejectWithValue(getMessageFromServerReturnedError(err))
  }
})
