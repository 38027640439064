import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { saveTowingFormState } from '../../../actions/api'
import moment from '../../../lib/moment-fi'
import { formStates, stepStates } from '../../../lib/towingUtils'
import { getColor, theme } from '../../../Theme'
import { TowingFormState } from '../../../types/Input'
import TowingConfirmButton from './TowingConfirmButton'
import TowingConfirmToNext from './TowingConfirmToNext'
import { getValidCounterSteps } from './TowingCounterStepList'

type TowingFlatList = (
  elements: any[] | undefined,
  elementStates: unknown[],
  includeTowingSelection?: unknown
) => any[]

interface TowingConfirmContainerProps {
  elements?: unknown[] | undefined
  counterElements?: unknown[]
  elementStates: unknown[]
  towingFormState: TowingFormState
  towingConfirmed: boolean
  confirmForm: (towingFormState: TowingFormState, formType: string) => unknown
  formType?: string
  t: TFunction
  saving: boolean
}

const Container = styled.div`
  padding-bottom: ${theme.spacing.sizes.small};
  margin-right: ${theme.spacing.sizes.small};
  margin-left: ${theme.spacing.sizes.small};
  margin-top: ${theme.spacing.sizes.large};
  margin-bottom: ${theme.spacing.sizes.tiny};
  & + & {
    margin-top: ${theme.spacing.sizes.smaller};
  }
  display: flex;
  flex-direction: row;
  gap: 20px;
`

const StepHeaderText = styled.div`
  color: ${(p) => getColor(p.theme, ['black'], ['white'])};
  padding-left: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-grow: 1;
  font-weight: bold;
`

const getSelectedElements = (el, elementStates) => {
  const namePattern = /^name[\d]+$/
  const names = Object.keys(el)
    .filter((key) => namePattern.test(key))
    .map((name) => ({ name, branch: name.replace('name', 'branch') }))
  const selectedBranch = names.find(
    (name) => el[name.branch] && elementStates[el.key] === name.branch
  )
  const selectedElements = selectedBranch && el[selectedBranch.branch].elements

  return {
    selectedElements,
    selectedBranch,
    names,
  }
}

const getBranchSelectedElements = (el, elementStates, includeTowingSelection) => {
  const { selectedElements, selectedBranch } = getSelectedElements(el, elementStates)
  if (selectedElements && selectedElements.length > 0) {
    return getTowingFlatList(
      el[selectedBranch.branch].elements,
      elementStates,
      includeTowingSelection
    )
  }
}

const getUnSelectedBranchElements = (el, elementStates) => {
  const { selectedElements, selectedBranch, names } = getSelectedElements(el, elementStates)
  if (selectedElements && selectedElements.length > 0) {
    const unSelectedBranches = names.filter(
      (name) => el[name.branch] && elementStates[el.key] !== name.branch
    )
    return unSelectedBranches
      .flatMap(
        (name) => getValidCounterSteps(el[name.branch].elements, selectedElements, elementStates),
        elementStates
      )
      .concat(
        getCounterActionsForUnselectedBranchesFlatList(
          el[selectedBranch.branch].elements.filter((el) => el.contentType === 'towingSelection'),
          elementStates
        )
      )
      .concat(
        unSelectedBranches.flatMap((name) =>
          getCounterActionsForUnselectedBranchesFlatList(
            el[name.branch].elements.filter((el) => el.contentType === 'towingSelection'),
            elementStates
          )
        )
      )
  }
}

const getFlatCounterList = (elements, elementStates) =>
  elements.flatMap((el) => {
    if (el.contentType === 'towingSelection' && elementStates[el.key]) {
      const namePattern = /^name[\d]+$/
      const names = Object.keys(el)
        .filter((key) => namePattern.test(key))
        .map((name) => ({ name, branch: name.replace('name', 'branch') }))
      const selectedBranch = names.find(
        (name) => el[name.branch] && elementStates[el.key] === name.branch
      )
      const selectedElements = selectedBranch && el[selectedBranch.branch].elements
      if (selectedElements && selectedElements.length > 0) {
        return getFlatCounterList(el[selectedBranch.branch].elements, elementStates)
      }
    } else if (el.hasCounterAction) {
      return el
    }

    return undefined
  })

export const getTowingFlatListWithTowingSelection = (elements, elementStates) => {
  return getTowingFlatList(elements, elementStates, true)
}

export const getTowingFlatList: TowingFlatList = (
  elements,
  elementStates,
  includeTowingSelection
) =>
  elements.flatMap((el) => {
    if (el.contentType === 'towingSelection' && elementStates[el.key]) {
      if (includeTowingSelection) {
        return getBranchSelectedElements(el, elementStates, includeTowingSelection).concat(el)
      } else {
        return getBranchSelectedElements(el, elementStates, includeTowingSelection)
      }
    }
    return el
  })

const getCounterActionsForUnselectedBranchesFlatList = (elements, elementStates) =>
  elements.flatMap((el) => {
    if (el.contentType === 'towingSelection' && elementStates[el.key]) {
      return getUnSelectedBranchElements(el, elementStates)
    }

    return undefined
  })

const getTowingFunctionState = (elements, elementStates) => {
  const enabled = true
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].contentType === 'towingFunction' && elements[i].type === 'TRANSITION_TO_NEXT')
      return enabled
    const key = elements[i].key
    if (
      elements[i].mandatory &&
      elements[i].contentType !== 'towingContent' &&
      (!elementStates[key] ||
        (elements[i].contentType !== 'towingSelection' && elementStates[key] !== stepStates.DONE))
    ) {
      return false
    }
  }
  return enabled
}

const getTowingFunctionStateForCounterSteps = (elements, elementStates) => {
  for (let i = 0; i < elements.length; i++) {
    const key = elements[i].key
    if (
      elements[i].mandatory &&
      elements[i].contentType !== 'towingContent' &&
      elementStates[key] === stepStates.DONE
    ) {
      return false
    }
  }
  return true
}

const getCounterActionsReady = (elements, elementStates, isFinishOrStop) => {
  for (let i = 0; i < elements.length; i++) {
    const key = elements[i].key
    if (
      elements[i].mandatory &&
      (!isFinishOrStop || elements[i].counterActionMandatoryInFinish) &&
      elementStates[key] === stepStates.DONE
    ) {
      return false
    }
  }
  return true
}

const getTowingReadyState = (elements, elementStates) => {
  for (let i = 0; i < elements.length; i++) {
    const key = elements[i].key
    if (
      (elements[i].mandatory || elements[i].contentType === 'towingSelection') &&
      (!elementStates[key] ||
        (elements[i].contentType !== 'towingSelection' &&
          elementStates[key] !== stepStates.DONE)) &&
      elements[i].contentType !== 'towingFunction'
    ) {
      return false
    }
  }
  return true
}

const getTowingConfirmedText = (formType, towingConfirmed, t) => {
  const changedBy = towingConfirmed.changedBy
  const changedAt = moment(towingConfirmed.changedAt).format('DD.MM.YYYY HH:mm')
  if (formType === 'setup') {
    // TODO: handle situations where personnel data is removed, add translations
    return changedBy && changedBy !== ''
      ? t('confirmedTowingSetup', { changedBy, changedAt })
      : t('confirmedTowingSetupWithoutPersonnel', { changedAt })
  } else if (formType === 'start') {
    return changedBy && changedBy !== ''
      ? t('confirmedTowingStart', { changedBy, changedAt })
      : t('confirmedTowingStartWithoutPersonnel', { changedAt })
  } else if (formType === 'stop') {
    return changedBy && changedBy !== ''
      ? t('confirmedTowingStop', { changedBy, changedAt })
      : t('confirmedTowingStopWithoutPersonnel', { changedAt })
  } else if (formType === 'finish') {
    return changedBy && changedBy !== ''
      ? t('confirmedTowingFinish', { changedBy, changedAt })
      : t('confirmedTowingFinishWithoutPersonnel', { changedAt })
  }
}

const TowingConfirmContainer = ({
  elements,
  counterElements,
  elementStates,
  towingFormState,
  towingConfirmed,
  confirmForm,
  formType,
  t,
  saving,
}: TowingConfirmContainerProps) => {
  const handleConfirmForm = () => {
    confirmForm(towingFormState, formType)
  }

  const towingFlatList = getTowingFlatList(elements, elementStates).filter((el) => !!el)
  const towingFunctionList = towingFlatList.filter((el) => {
    if (el) {
      return el.contentType === 'towingFunction' && el.type === 'TRANSITION_TO_NEXT'
    }
    return false
  })
  const towingFunction =
    towingFunctionList.length > 0 ? towingFunctionList.reduce((p, c) => p || c) : undefined
  const towingBranchCounterActionFlatList = getCounterActionsForUnselectedBranchesFlatList(
    elements,
    elementStates
  ).filter((el) => !!el)
  let towingReady =
    getTowingReadyState(elements, elementStates) &&
    getTowingReadyState(towingFlatList, elementStates) &&
    getCounterActionsReady(towingBranchCounterActionFlatList, elementStates, false)
  if (counterElements) {
    const flatCounterList = getFlatCounterList(counterElements, elementStates).filter((el) => !!el)
    towingReady = towingReady && getCounterActionsReady(flatCounterList, elementStates, true)
  }

  const handOverDone = towingFormState.stateChanges.find((el) => el.status === formStates.HANDOVER)

  return (
    <>
      {towingConfirmed ? (
        <Container>
          <StepHeaderText>{getTowingConfirmedText(formType, towingConfirmed, t)}</StepHeaderText>
        </Container>
      ) : (
        <>
          {towingFunction && handOverDone && (
            <Container>
              <StepHeaderText>
                {`${handOverDone.changedBy} kuitannut valmis seuraavalle ${moment(
                  handOverDone.changedAt
                ).format('DD.MM.YYYY HH:mm')}`}
              </StepHeaderText>
            </Container>
          )}
          <Container>
            {towingFunction && (
              <TowingConfirmToNext
                key={towingFunction.name || towingFunction.key}
                enabled={
                  !saving &&
                  !handOverDone &&
                  !towingReady &&
                  getTowingFunctionState(towingFlatList, elementStates) &&
                  getTowingFunctionStateForCounterSteps(
                    towingBranchCounterActionFlatList,
                    elementStates
                  )
                }
              />
            )}
            <TowingConfirmButton
              buttonText={getConfirmText(t, formType)}
              disabled={saving || !towingReady}
              onClick={handleConfirmForm}
            />
          </Container>
        </>
      )}
    </>
  )
}

const getConfirmText = (t, formType) => {
  if (formType === 'stop') {
    return `${t('confirmTowingStepForStop')}`
  } else if (formType === 'finish') {
    return t('confirmTowingStepForFinish')
  } else {
    return `${t('confirmTowingStepDefault')}`
  }
}

const mapStateToProps = (state, { formType }) => {
  const elementStates = state.towingFormState.actionsAndSelectionsByKey
  const towingFormState = state.towingFormState
  const stateChangesLatestFirst =
    state.towingFormState.stateChanges &&
    state.towingFormState.stateChanges.slice().sort((firstEl, secondEl) => {
      return moment(secondEl.changedAt).diff(moment(firstEl.changedAt))
    })
  const saving = state.towingFormState.saving

  const getTowingConfirmed = (stateChanges, formType) => {
    const setupDoneIdx = stateChanges.findIndex(
      (stateChange) => stateChange.status === formStates.SETUP_DONE
    )
    const inSetupIdx = stateChanges.findIndex(
      (stateChange) => stateChange.status === formStates.IN_SETUP
    )
    const setupDone =
      (setupDoneIdx >= 0 &&
        (setupDoneIdx < inSetupIdx || inSetupIdx < 0) &&
        stateChanges[setupDoneIdx]) ||
      null
    const startDone = stateChanges.find((el) => el.status === formStates.IN_TOWING)
    const stopInProgress = stateChanges.find((el) => el.status === formStates.STOP_IN_PROGRESS)
    const finished = stateChanges.find((el) => el.status === formStates.FINISHED)

    if (formType === 'setup') {
      return setupDone
    }
    if (
      formType === 'start' &&
      startDone &&
      moment(startDone.changedAt).isAfter(moment(setupDone.changedAt))
    )
      return startDone
    if (formType === 'stop' && stopInProgress) {
      const stopInProgressIndex = stateChangesLatestFirst.findIndex(
        (s) => s.status === formStates.STOP_IN_PROGRESS
      )
      if (
        stopInProgressIndex > 0 &&
        stateChangesLatestFirst[stopInProgressIndex - 1].status === formStates.SETUP_DONE
      )
        return stateChangesLatestFirst[stopInProgressIndex - 1]
    }
    if (formType === 'finish') return finished
  }
  const towingConfirmed =
    stateChangesLatestFirst && stateChangesLatestFirst.length > 0
      ? getTowingConfirmed(stateChangesLatestFirst, formType)
      : {}
  return {
    elementStates,
    towingFormState,
    towingConfirmed,
    saving,
  }
}

const mapDispatchToProps = (dispatch) => ({
  confirmForm: (towingFormState, formType) =>
    dispatch(saveTowingFormState(towingFormState, formType)),
})

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(TowingConfirmContainer)
)
