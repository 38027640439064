import { TextFieldProps } from '@mui/material'
import React from 'react'
import { FieldValues, useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import * as S from '../component.styles'
import { FormErrorHelperText } from '../FormErrorHelperText'
import { FormControls } from '../types'

export const FormInputText = <T extends FieldValues>({
  name,
  label,
  multiline,
  required = false,
}: FormControls<T> & Partial<TextFieldProps>): JSX.Element => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  const id = `${name}-${label}`
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: { value: required, message: t('field.requiredHelperText') } },
  })
  return (
    <S.VrTextField
      fullWidth
      error={!!error?.message}
      helperText={error?.message ? <FormErrorHelperText error={error.message} /> : null}
      multiline={!!multiline}
      rows={multiline ? 4 : 1}
      onChange={field.onChange}
      value={field.value}
      id={id}
      placeholder={t('field.placeholderTextbox')}
      label={label}
    />
  )
}
