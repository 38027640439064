// Utilities for handling SM3 and SM6 equipment
// We get only either first or last wagon for those but should show findings for all wagons

const sm6Pattern = /^7[17]5(\d)$/
const sm3Pattern = /^7[16](\d\d)$/

const SM6_PREFIXES = ['715', '725', '735', '745', '755', '765', '775']
const SM3_PREFIXES = ['71', '72', '73', '74', '75', '76']

export const isSm6 = (eqtId: string): boolean => {
  return sm6Pattern.test(eqtId)
}

export const isSm3 = (eqtId: string): boolean => {
  return sm3Pattern.test(eqtId)
}

export const getSm3TrainId = (equipmentId: string): string => {
  const result = sm3Pattern.exec(equipmentId)
  return (result && result[1]) || ''
}

export const getSm6TrainId = (equipmentId: string): string => {
  const result = sm6Pattern.exec(equipmentId)
  return (result && result[1]) || ''
}

const generateSm6Equipments = (equipmentId: string): Array<string> => {
  if (!isSm6(equipmentId)) return []
  const remainder = getSm6TrainId(equipmentId)
  return SM6_PREFIXES.map((pfx) => pfx + remainder)
}

const generateSm3Equipments = (equipmentId: string): Array<string> => {
  if (!isSm3(equipmentId)) return []
  const remainder = getSm3TrainId(equipmentId)
  return SM3_PREFIXES.map((pfx) => pfx + remainder)
}

export const amendSmEquipments = (equipments: Array<string>): Array<string> => {
  return equipments.reduce((result: Array<string>, val) => {
    if (isSm6(val)) {
      return [...result, ...generateSm6Equipments(val)]
    }
    if (isSm3(val)) {
      return [...result, ...generateSm3Equipments(val)]
    }
    return [...result, val]
  }, [])
}
