import { Dispatch } from '../types'

export type ThunkFunction = (...args: unknown[]) => any

/*  Converts normal action creator function to thunk

    // Action creator
    const toggle = (on) => { type: 'TOGGLE_LIGHT' }

    // Component
    const LightButton = ({onToggle}) => (
      <Button onClick={onToggle} />Toggle Light</Button>
    )

    // Without asThunk
    const mapDispatchToProps = dispatch => ({
      onToggle: () => dispatch(toggle())
    })
    
    // With asThunk
    const mapDispatchToProps = { onToggle: asThunk(toggle) }
*/
export const asThunk = (fn: ThunkFunction): ThunkFunction => {
  return (...args) => {
    return (dispatch: Dispatch) => dispatch(fn(...args))
  }
}

export const bindDispatch = (fn: ThunkFunction, dispatch: Dispatch): ThunkFunction => {
  return (...args) => dispatch(fn(...args))
}
