import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import { createDefectAction, options, postDigitalAssetAction } from '../actions/create-defect'
import {
  AssetIdentifiers,
  CreateDefectPayload,
  CreateDefectResponse,
  dataWithStatus,
  OptionStates,
} from '../components/create-defect/types'
import { ActionStatus } from '../types/Input'

type FormData = Omit<
  DefectState,
  'status' | 'error' | 'defectId' | 'response' | 'request' | 'preSelectedSerialNumber'
>

export interface DefectState extends OptionStates {
  status: ActionStatus
  error?: string
  assetIdentifiers: dataWithStatus<AssetIdentifiers>
  request?: CreateDefectPayload
  response?: CreateDefectResponse
  preSelectedSerialNumber: string
}

const initialState: Omit<DefectState, 'equipments'> = {
  status: 'none',
  defectGroups: { status: 'none', data: [] },
  items: { status: 'none', data: {} },
  positions: { status: 'none', data: [] },
  defectTrees: { status: 'none', data: [] },
  restriction: { status: 'none', data: [] },
  affectToUsage: { status: 'none', data: [] },
  assetIdentifiers: { status: 'none', data: {} },
  preSelectedSerialNumber: '',
}

export const createDefectSlice = createSlice({
  name: 'defect',
  initialState,
  reducers: {
    removeFile: (state, { payload }) => {
      delete state.assetIdentifiers.data[payload]
    },
    clearDataByKey: (state, { payload }: { payload: keyof Omit<FormData, 'equipments'> }) => {
      state[payload].data = initialState[payload].data
    },
    resetStatus: (state) => {
      state.status = initialState.status
    },
    resetOptionsStatusByKey: (
      state,
      { payload }: { payload: keyof Omit<FormData, 'equipments'> }
    ) => {
      state[payload].status = initialState[payload].status
    },
    resetStoredResponseRequest: (state) => {
      state.response = initialState.response
      state.request = initialState.request
    },
    setPreSelectedSerialNumber: (state, { payload }: { payload: string }) => {
      state.preSelectedSerialNumber = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createDefectAction.pending, (state) => {
      state.status = 'loading'
      state.error = 'undefined'
    })
    builder.addCase(createDefectAction.fulfilled, (state, { payload }) => {
      state.status = 'succeeded'
      state.response = payload.response
      state.request = payload.request
      state.assetIdentifiers.data = {}
    })
    builder.addCase(createDefectAction.rejected, (state) => {
      state.status = 'failed'
    })

    builder.addCase(postDigitalAssetAction.pending, (state) => {
      state.assetIdentifiers.status = 'loading'
    })
    builder.addCase(postDigitalAssetAction.fulfilled, (state, { payload }) => {
      state.assetIdentifiers.data[payload.id] = payload.filename
      state.assetIdentifiers.status = 'succeeded'
    })
    builder.addCase(postDigitalAssetAction.rejected, (state) => {
      state.assetIdentifiers.status = 'failed'
    })

    builder.addCase(options.fetchDefectGroupsAction.pending, (state) => {
      state.defectGroups.status = 'loading'
    })
    builder.addCase(options.fetchPositionsAction.pending, (state) => {
      state.positions.status = 'loading'
    })
    builder.addCase(options.fetchRestrictionsAction.pending, (state) => {
      state.restriction.status = 'loading'
    })
    builder.addCase(options.fetchAffectToUsageAction.pending, (state) => {
      state.affectToUsage.status = 'loading'
    })
    builder.addCase(options.fetchDefectTreesAction.pending, (state) => {
      state.defectTrees.status = 'loading'
    })
    builder.addCase(options.fetchItemsAction.pending, (state) => {
      state.items.status = 'loading'
    })

    // rejected
    builder.addCase(options.fetchDefectGroupsAction.rejected, (state) => {
      state.defectGroups.status = 'failed'
    })
    builder.addCase(options.fetchPositionsAction.rejected, (state) => {
      state.positions.status = 'failed'
    })
    builder.addCase(options.fetchRestrictionsAction.rejected, (state) => {
      state.restriction.status = 'failed'
    })
    builder.addCase(options.fetchAffectToUsageAction.rejected, (state) => {
      state.affectToUsage.status = 'failed'
    })
    builder.addCase(options.fetchDefectTreesAction.rejected, (state) => {
      state.defectTrees.status = 'failed'
    })
    builder.addCase(options.fetchItemsAction.rejected, (state) => {
      state.items.status = 'failed'
    })

    builder.addMatcher(
      isAnyOf(
        options.fetchDefectGroupsAction.fulfilled,
        options.fetchPositionsAction.fulfilled,
        options.fetchRestrictionsAction.fulfilled,
        options.fetchAffectToUsageAction.fulfilled,
        options.fetchDefectTreesAction.fulfilled,
        options.fetchItemsAction.fulfilled
      ),
      (state, { payload }) => {
        const { id, data } = payload
        const status = 'succeeded'
        // Would be nice to just use state[id] = data
        // but typescript can't figure out that state[id] and data are the same type
        switch (id) {
          case 'defectGroups':
            state.defectGroups = { data, status }
            break
          case 'items':
            state.items = { data, status }
            break
          case 'positions':
            state.positions = { data, status }
            break
          case 'defectTrees':
            state.defectTrees = { data, status }
            break
          case 'restriction':
            state.restriction = { data, status }
            break
          case 'affectToUsage':
            state.affectToUsage = { data, status }
            break
        }
      }
    )
  },
})
export const {
  removeFile,
  clearDataByKey,
  resetStatus,
  resetOptionsStatusByKey,
  resetStoredResponseRequest,
  setPreSelectedSerialNumber,
} = createDefectSlice.actions

export default createDefectSlice.reducer
