import { TowingVehiclePattern } from '../types'

export type UpdateVehiclePatterns = {
  type: 'UPDATE_VEHICLE_PATTERNS'
  payload: Array<TowingVehiclePattern>
}
export const UPDATE_VEHICLE_PATTERNS = 'UPDATE_VEHICLE_PATTERNS'
export const updateVehiclePatterns = (
  vehiclePatterns: Array<TowingVehiclePattern>
): UpdateVehiclePatterns => ({
  type: UPDATE_VEHICLE_PATTERNS,
  payload: vehiclePatterns,
})

export type SetVehiclePatternsError = {
  type: 'SET_VEHICLE_PATTERNS_ERROR'
  payload: string
}
export const SET_VEHICLE_PATTERNS_ERROR = 'SET_VEHICLE_PATTERNS_ERROR'
export const setVehiclePatternsError = (error: string): SetVehiclePatternsError => ({
  type: SET_VEHICLE_PATTERNS_ERROR,
  payload: error,
})

export type StartFetchingVehiclePatterns = {
  type: 'START_FETCHING_VEHICLE_PATTERNS'
}
export const START_FETCHING_VEHICLE_PATTERNS = 'START_FETCHING_VEHICLE_PATTERNS'
export const startFetchingVehiclePatterns = (): StartFetchingVehiclePatterns => ({
  type: START_FETCHING_VEHICLE_PATTERNS,
})

export type VehiclePatternAction =
  | UpdateVehiclePatterns
  | SetVehiclePatternsError
  | StartFetchingVehiclePatterns
