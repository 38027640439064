import { InputLabel, Stack, SxProps, Typography, TypographyProps } from '@mui/material'
import React from 'react'

interface FormInputLabelProps {
  htmlFor: string
  label: string
  requiredText?: string
  sx?: SxProps
  disabled?: boolean
}

type LabelProps = Pick<FormInputLabelProps, 'label' | 'requiredText' | 'sx'> & {
  labelVariant?: TypographyProps['variant']
}

export const Label = ({
  label,
  requiredText,
  sx = {},
  labelVariant = 'subtitle1',
}: LabelProps): JSX.Element => (
  <Stack sx={sx} direction="row" gap={0.1}>
    <Typography variant={labelVariant}>{label}</Typography>
    {requiredText !== undefined ? (
      <Typography color="warning.main">{requiredText}</Typography>
    ) : null}
  </Stack>
)

export const FormInputLabel = ({
  label,
  htmlFor,
  requiredText,
  sx = {},
  disabled = false,
}: FormInputLabelProps): JSX.Element => {
  return (
    <InputLabel
      shrink={false}
      disabled={disabled}
      sx={{ padding: '2px 10px', color: 'text.primary', ...sx }}
      htmlFor={htmlFor}
    >
      <Label label={label} requiredText={requiredText} />
    </InputLabel>
  )
}
