import styled from '@emotion/styled'
import { Router } from 'found'
import { TFunction } from 'i18next'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { refetchShift } from '../actions/api'
import { markShiftTasksDone, markShiftTasksUndone, openNextTask } from '../actions/shiftpage'
import ActionError from '../components/ActionError'
import Button from '../components/button/Button'
import ErrorText from '../components/ErrorText'
import LoadingIndicator from '../components/LoadingIndicator'
import MainMenu from '../components/main-menu/MainMenu'
import NextShift from '../components/NextShift'
import { FooterPage } from '../components/page/Page'
import ShiftContainer from '../components/shift/ShiftContainer'
import ShiftHeader from '../components/shift/ShiftHeader'
import Close from '../icons/Close'
import Success from '../icons/Success'
import { removeShiftDoneTasks, saveShiftDoneTasks } from '../lib/data'
import { isMobileSelector, nowSelector, shiftsSortedByDateSelector } from '../Selectors'
import { getColor, px, theme } from '../Theme'
import { Action, AppState, Children, Dispatch, Moment, Session, Shift, UserState } from '../types'

const Container = styled.div`
  height: auto;
`

const SuccessButton = styled(Button)`
  ${theme.spacing.ends('large')};
`

const DisabledButton = styled(SuccessButton)`
  background-color: ${(p) => getColor(p.theme, ['grayDark'], [''])};
`

/* eslint-disable @typescript-eslint/no-empty-function */
const noop = () => {}

type Props = {
  t: TFunction
  children: Children
  isMobile: boolean
  now: Moment
  scheduleError: string
  shiftDetailsError: string
  loading: boolean
  reloading: boolean
  shift: Shift
  nextShift: Shift
  hasSelectedTasks: boolean
  selectedIndexes: Array<number>
  selectedTypes: Array<boolean>
  user: Session // UserState and Session are equivalent types,
  reloadShift: (shift: Shift, session: Session) => void
  markDone: (shift: Shift, array: Array<number>) => void
  markUndone: (shift: Shift, array: Array<number>) => void
  router: Router
}

const ShiftPage = ({
  t,
  isMobile,
  now,
  shift,
  scheduleError,
  shiftDetailsError,
  loading,
  reloading,
  nextShift,
  hasSelectedTasks,
  selectedIndexes,
  selectedTypes,
  user,
  reloadShift,
  markDone,
  markUndone,
  router,
}: Props) => {
  return isMobile ? (
    <FooterPage
      disableNotifications={true}
      footer={<MainMenu current="schedule" />}
      footerHeight={theme.maxHeights.footer}
    >
      {!loading && scheduleError !== '' && shiftDetailsError !== '' ? (
        <ErrorText>{t(scheduleError)}</ErrorText>
      ) : undefined}
      {!loading && shiftDetailsError !== '' ? (
        <ActionError
          error={t(shiftDetailsError)}
          action={() => reloadShift(shift, user)}
          actionText="retry"
        /> // TODO: make this an ActionError with the option to try reloading
      ) : loading ? (
        <LoadingIndicator size="normal" padded={true} />
      ) : (
        <div>
          <ShiftHeader
            shift={shift}
            reloading={reloading}
            onReload={() => reloadShift(shift, user)}
          />
          <Container>
            {shift ? (
              <ShiftContainer
                now={now}
                shift={shift}
                isMobile={isMobile}
                reload={() => reloadShift(shift, user)}
                router={router}
              />
            ) : undefined}
            {nextShift ? <NextShift now={now} shift={nextShift} router={router} /> : undefined}
          </Container>
        </div>
      )}
    </FooterPage>
  ) : (
    <FooterPage
      disableNotifications={true}
      footer={
        <div>
          {hasSelectedTasks ? (
            selectedTypes.length > 1 ? (
              <DisabledButton topOpen={false} bottomOpen={true} onClick={noop}>
                {t('chooseEitherCrossedOrUncrossed')}
              </DisabledButton>
            ) : selectedTypes[0] === true ? (
              <SuccessButton
                topOpen={false}
                bottomOpen={true}
                onClick={() => markUndone(shift, selectedIndexes)}
              >
                <Close iconSize="normal" />
                &nbsp;{t('markAsUndone')}
              </SuccessButton>
            ) : (
              <SuccessButton
                topOpen={false}
                bottomOpen={true}
                onClick={() => markDone(shift, selectedIndexes)}
              >
                <Success iconSize="normal" />
                &nbsp;{t('markAsDone')}
              </SuccessButton>
            )
          ) : undefined}
          <MainMenu current="schedule" />
        </div>
      }
      footerHeight={hasSelectedTasks ? px(123) : theme.maxHeights.footer}
    >
      {loading ? (
        <LoadingIndicator size="normal" padded={true} />
      ) : (
        <div>
          <ShiftHeader shift={shift} onReload={() => reloadShift(shift, user)} />
          <Container>
            {shift ? (
              <ShiftContainer
                now={now}
                shift={shift}
                isMobile={isMobile}
                reload={() => reloadShift(shift, user)}
                router={router}
              />
            ) : undefined}
            {nextShift ? <NextShift full now={now} shift={nextShift} router={router} /> : undefined}
          </Container>
        </div>
      )}
    </FooterPage>
  )
}

ShiftPage.displayName = 'ShiftPage'

const mapStateToProps = (state: AppState) => {
  const now = nowSelector(state)
  const isMobile = isMobileSelector(state)
  const shifts = shiftsSortedByDateSelector(state)

  const shiftId =
    state.found.match && state.found.match.params ? state.found.match.params.shiftId : ''
  const baseShift = state.shifts.byId[shiftId]

  const shiftIndex = shifts.indexOf(baseShift)
  const nextShift = shifts[shiftIndex + 1]

  const shiftWithDetails = state.shiftDetails.byId[shiftId]

  const shift = { ...baseShift, ...shiftWithDetails, rests: baseShift && baseShift.rests }

  const taskStates = shift ? state.shiftPage.taskState[shift.id] : {}
  const selectedIndexes = _.keys(taskStates).reduce((indexes, key) => {
    const index = parseInt(key, 10)
    if (taskStates[index].selected) {
      return indexes.concat(index)
    }
    return indexes
  }, [] as Array<number>)

  const hasSelectedTasks = selectedIndexes.length > 0
  let selectedTypes: Array<boolean> = []
  if (hasSelectedTasks) {
    selectedTypes = _.uniq(selectedIndexes.map((index) => taskStates[index].crossed))
  }

  const loading = !shiftWithDetails && (state.shifts.loading || state.shiftDetails.loading)
  const reloading = shiftWithDetails && state.shiftDetails.loading
  const scheduleError = state.shifts.error
  const shiftDetailsError = state.shiftDetails.error
  const user = state.user

  return {
    isMobile,
    now,
    scheduleError,
    shiftDetailsError,
    loading,
    reloading,
    shift,
    nextShift,
    hasSelectedTasks,
    selectedIndexes,
    selectedTypes,
    user,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  reloadShift: (shift: Shift, user: UserState) =>
    shift?.id && !shift.loading ? dispatch(refetchShift(shift, user) as unknown as Action) : null,
  markDone: (shift: Shift, indexes: Array<number>) => {
    if (shift?.id) {
      const shiftId = shift.id
      saveShiftDoneTasks(shiftId, indexes).then(() => {
        dispatch(markShiftTasksDone(shiftId, indexes))
        dispatch(openNextTask(shift))
      })
    }
  },
  markUndone: (shift: Shift, indexes: Array<number>) => {
    if (shift?.id) {
      const shiftId = shift.id
      removeShiftDoneTasks(shiftId, indexes).then(() => {
        dispatch(markShiftTasksUndone(shiftId, indexes))
        dispatch(openNextTask(shift))
      })
    }
  },
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ShiftPage))
