import { News } from '../types/Input'

export type UpdateNews = {
  type: 'UPDATE_NEWS'
  payload: Array<News>
}
export const UPDATE_NEWS = 'UPDATE_NEWS'
export const updateNews = (payload: Array<News>): UpdateNews => ({
  type: UPDATE_NEWS,
  payload,
})

export type FetchNews = {
  type: 'START_FETCHING_NEWS'
}
export const START_FETCHING_NEWS = 'START_FETCHING_NEWS'
export const startFetchingNews = (): FetchNews => ({
  type: START_FETCHING_NEWS,
})

export type NewsError = {
  type: 'NEWS_ERROR'
  payload: string
}
export const NEWS_ERROR = 'NEWS_ERROR'
export const newsError = (payload: string): NewsError => ({
  type: NEWS_ERROR,
  payload,
})

export type NewsAction = UpdateNews | FetchNews | NewsError
