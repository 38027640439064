import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Document } from '@contentful/rich-text-types'
import styled from '@emotion/styled'
import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'

import { saveTowingSteps } from '../../../actions/api'
import Checked from '../../../icons/Checked'
import moment from '../../../lib/moment-fi'
import { formActions, formStates } from '../../../lib/towingUtils'
import { color, getColor, theme } from '../../../Theme'
import { StepState } from '../../../types/Input'
import TowingConfirmButton from './TowingConfirmButton'
import TowingFormRow from './TowingFormRow'

interface TowingStepProps {
  step: any
  stepState: StepState
  stepText: string
  onActionChange: (action: unknown, stepKey: string) => void
  savingStep: boolean
  counterActionForSetup?: boolean
  counterActionForFinish?: boolean
  savingForm: boolean
  vehicleNumber: string
  formType?: string
  currentFormState: string
  formAction: string
}

interface InstructionContainerProps {
  towingContent: boolean
}

interface TowingInstructionsProps {
  element: {
    expression: string
    content?: any[]
    additionalInfo?: any[]
    counterActionAdditionalInfo: any[]
    contentType: string
    title?: string
    richTextAbove: Document
    richTextBelow: Document
    media: any[]
  }
  isAdditionalInfo?: boolean
  towingContent?: boolean
  vehicleNumber: string
  isCounterAction?: boolean
}

const TowingStepText = ({ text }) => {
  return <Text>{text}</Text>
}

const Text = styled.div`
  flex-grow: 1;
  color: ${(p) => getColor(p.theme, ['black'], ['white'])};
  font-size: ${theme.font.mediumLarge};
`

const TowingInformationText = styled.div`
  flex-grow: 1;
  font-size: ${theme.font.mediumSmall};
  color: ${(p) => getColor(p.theme, ['grayDark'], ['grayPink'])};
  padding-right: ${theme.spacing.sizes.normal};
`

const TowingInformationLink = styled.a`
  flex-grow: 1;
  font-size: ${theme.font.mediumSmall};
  text-decoration: underline;
  text-decoration-color: ${color('grayDark')};
  color: ${(p) => getColor(p.theme, ['grayDark'], ['grayPink'])};
`

const TowingStepContentContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const TowingStepCheckedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`

const TowingStepTextContainer = styled.div`
  padding-top: ${theme.spacing.sizes.normal};
  padding-left: ${theme.spacing.sizes.normal};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const IconContainer = styled.div`
  margin-right: ${theme.spacing.sizes.normal};
  margin-top: ${theme.spacing.sizes.normal};
`

const TowingButtonContainer = styled.div`
  margin-right: ${theme.spacing.sizes.normal};
  margin-top: ${theme.spacing.sizes.normal};
  margin-left: ${theme.spacing.sizes.smallest};
`

const Image = styled.img`
  max-width: 100%;
  display: block;
  height: auto;
  margin-right: 16px;
`

const InstructionContainer = styled.p<InstructionContainerProps>`
  margin-right: ${(p) =>
    p.towingContent ? theme.spacing.sizes.small : theme.spacing.sizes.normal};
  margin-left: ${(p) => (p.towingContent ? theme.spacing.sizes.small : theme.spacing.sizes.normal)};
  color: ${(p) => getColor(p.theme, ['black'], ['white'])};
`

const Media = ({ media }) => {
  return (
    <Fragment>
      <a href={media.file.url} target="_blank" rel="noreferrer">
        <Image src={media.file.url} alt={media.title} />
      </a>
    </Fragment>
  )
}

const TowingInstructions = ({
  element,
  isAdditionalInfo,
  towingContent,
  vehicleNumber,
  isCounterAction,
}: TowingInstructionsProps) => {
  if (element === undefined) {
    return null
  }

  if (element.expression && !RegExp(element.expression).test(vehicleNumber)) {
    return null
  }

  if (isCounterAction && element.counterActionAdditionalInfo && isAdditionalInfo) {
    return (
      <Fragment>
        {element.counterActionAdditionalInfo.map((c) => (
          <TowingInstructions
            key={c.id}
            element={c}
            towingContent={towingContent}
            vehicleNumber={vehicleNumber}
          />
        ))}
      </Fragment>
    )
  }

  if (!isCounterAction && element.content && !isAdditionalInfo) {
    return (
      <Fragment>
        {element.content.map((c) => (
          <TowingInstructions
            key={c.id}
            element={c}
            towingContent={towingContent}
            vehicleNumber={vehicleNumber}
          />
        ))}
      </Fragment>
    )
  }

  if (!isCounterAction && element.additionalInfo && isAdditionalInfo) {
    return (
      <Fragment>
        {element.additionalInfo.map((c) => (
          <TowingInstructions
            key={c.id}
            element={c}
            towingContent={towingContent}
            vehicleNumber={vehicleNumber}
          />
        ))}
      </Fragment>
    )
  }

  return (
    <Fragment>
      {element.contentType === 'contentParagraphWithTitle' && element.title && (
        <InstructionContainer towingContent={towingContent}>
          <h2>{element.title}</h2>
        </InstructionContainer>
      )}
      {element.richTextAbove && (
        <InstructionContainer towingContent={towingContent}>
          {documentToReactComponents(element.richTextAbove)}
        </InstructionContainer>
      )}
      {element.media && (
        <InstructionContainer towingContent={towingContent}>
          {element.media.map((m) => (
            <Media key={m.id} media={m} />
          ))}
        </InstructionContainer>
      )}
      {element.richTextBelow && (
        <InstructionContainer towingContent={towingContent}>
          {documentToReactComponents(element.richTextBelow)}
        </InstructionContainer>
      )}
    </Fragment>
  )
}

const TowingStep = ({
  step,
  stepState,
  stepText,
  onActionChange,
  savingStep,
  counterActionForSetup,
  counterActionForFinish,
  savingForm,
  vehicleNumber,
  formType,
  currentFormState,
  formAction,
}: TowingStepProps) => {
  const [infoExtended, setInfoExtended] = useState(false)
  const [rowExtended, setRowExtended] = useState(false)
  const handleStepAction = (key) => {
    const action = counterActionForSetup
      ? 'COUNTERED_IN_SETUP'
      : counterActionForFinish
      ? 'COUNTERED_IN_FINISH'
      : 'DONE'
    onActionChange({ [key]: action }, step.key)
    setRowExtended(false)
    setInfoExtended(false)
  }

  const handleCancelStepAction = (key) => {
    const action = counterActionForSetup || counterActionForFinish ? 'DONE' : 'REVERSED'
    onActionChange({ [key]: action }, step.key)
    setRowExtended(false)
    setInfoExtended(false)
  }

  const isDoneCounterAction = () => {
    return (
      (counterActionForSetup && stepState === 'COUNTERED_IN_SETUP') ||
      (counterActionForFinish && stepState === 'COUNTERED_IN_FINISH')
    )
  }

  if (formAction === formActions.SHOW_SELECTIONS_WITHOUT_STEPS) return <Fragment></Fragment>
  return (
    <Fragment>
      {step.contentType !== 'towingContent' && (
        <TowingFormRow
          onClick={() => {
            if (
              (!counterActionForSetup && !counterActionForFinish && stepState === 'DONE') ||
              isDoneCounterAction()
            )
              if (infoExtended) {
                setInfoExtended(!rowExtended)
              }
            setRowExtended(!rowExtended)
          }}
        >
          <TowingStepContentContainer>
            <TowingStepTextContainer>
              <TowingStepText text={stepText} />
              {((!counterActionForSetup && !counterActionForFinish && step.additionalInfo) ||
                ((counterActionForSetup || counterActionForFinish) &&
                  step.counterActionAdditionalInfo)) && (
                <TowingInformationLink
                  onClick={(e) => {
                    e.stopPropagation()
                    setInfoExtended(!infoExtended)
                  }}
                >
                  {((stepState === 'DONE' && !counterActionForSetup && !counterActionForFinish) ||
                    isDoneCounterAction()) &&
                  !rowExtended ? (
                    <></>
                  ) : infoExtended ? (
                    'Piilota ohjeet'
                  ) : (
                    'Ohjeet >>'
                  )}
                </TowingInformationLink>
              )}
              {!counterActionForSetup &&
                !counterActionForFinish &&
                step.content &&
                formAction === formActions.EDIT && (
                  <TowingInstructions element={step} vehicleNumber={vehicleNumber} />
                )}
              {(!(stepState === 'DONE') || rowExtended) &&
                !counterActionForSetup &&
                !counterActionForFinish &&
                step.preContent?.[0].richTextAbove?.content?.[0].content?.[0].nodeType ===
                  'text' && (
                  <TowingInformationText>
                    {step.preContent[0].richTextAbove.content[0].content[0].value}
                  </TowingInformationText>
                )}
              {(counterActionForSetup || counterActionForFinish) &&
                (!isDoneCounterAction() || rowExtended) &&
                step.counterActionPreContent?.[0].richTextAbove?.content?.[0].content?.[0]
                  .nodeType === 'text' && (
                  <TowingInformationText>
                    {step.counterActionPreContent[0].richTextAbove.content[0].content[0].value}
                  </TowingInformationText>
                )}
            </TowingStepTextContainer>
            <>
              {(stepState === 'DONE' && !counterActionForSetup && !counterActionForFinish) ||
              isDoneCounterAction() ? (
                <TowingStepCheckedContainer>
                  <IconContainer>
                    <Checked />
                  </IconContainer>
                  {rowExtended && formAction === formActions.EDIT && (
                    <TowingButtonContainer>
                      <TowingConfirmButton
                        onClick={(e) => {
                          e.stopPropagation()
                          handleCancelStepAction(step.key)
                        }}
                        buttonText={savingStep ? 'Tallennetaan' : 'Poista kuittaus'}
                        disabled={
                          savingForm ||
                          ((currentFormState === formStates.SETUP_DONE ||
                            currentFormState === formStates.FINISH_IN_PROGRESS) &&
                            formType &&
                            formType === 'stop')
                        }
                        confirmed={true}
                      />
                    </TowingButtonContainer>
                  )}
                </TowingStepCheckedContainer>
              ) : (
                <TowingButtonContainer>
                  <TowingConfirmButton
                    onClick={(e) => {
                      if (formAction === formActions.EDIT) {
                        e.stopPropagation()
                        handleStepAction(step.key)
                      }
                    }}
                    buttonText={savingStep ? 'Tallennetaan' : 'Kuittaa'}
                    disabled={savingForm || formAction !== formActions.EDIT}
                    confirmed={false}
                  />
                </TowingButtonContainer>
              )}
            </>
          </TowingStepContentContainer>
          {infoExtended && (
            <TowingInstructions
              element={step}
              isAdditionalInfo={true}
              vehicleNumber={vehicleNumber}
              isCounterAction={counterActionForSetup || counterActionForFinish}
            />
          )}
        </TowingFormRow>
      )}
      {step.contentType === 'towingContent' && formAction === formActions.EDIT && (
        <TowingInstructions element={step} towingContent={true} vehicleNumber={vehicleNumber} />
      )}
    </Fragment>
  )
}

const mapStateToProps = (state, { step }) => {
  const stepState = state.towingFormState.actionsAndSelectionsByKey[step.key]
  const savingStep = state.towingFormState.savingStep === step.key
  const savingForm = state.towingFormState.saving

  const vehicleNumber = state.towingFormState.vehicleNumber

  const formState =
    state.towingFormState.stateChanges &&
    state.towingFormState.stateChanges
      .sort((firstEl, secondEl) => {
        return moment(secondEl.changedAt).diff(moment(firstEl.changedAt))
      })
      .find(Boolean)

  const currentFormState = formState ? formState.status : ''

  return {
    stepState,
    savingStep,
    savingForm,
    vehicleNumber,
    currentFormState,
  }
}

const mapDispatchToProps = (dispatch) => ({
  onActionChange: (actionsAndSelectionsByKey, stepKey) =>
    dispatch(saveTowingSteps(actionsAndSelectionsByKey, stepKey)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TowingStep)
