import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React, { useState } from 'react'
import { Trans, withTranslation } from 'react-i18next'

import InfoFill from '../../icons/InfoFill'
import { roundSafe } from '../../lib/generalUtils'
import moment from '../../lib/moment-fi'
import { defaultTextColor, theme } from '../../Theme'
import { RowProps, TextProps } from '../../types/App'
import { EnergyEfficiency } from '../../types/Input'
import { generateConsumptionIcon } from './EnergyEfficiencyOverview'

const Row = styled.div<RowProps>`
  ${(p) => (p.flexDirection === 'column' ? theme.layout.flexColumn : theme.layout.flexRow)};
  flex-wrap: wrap;
  justify-content: ${(p) => p.justifyContent || 'space-between'};
  margin-top: ${(p) => p.marginTop || '0px'};
  margin-bottom: ${(p) => p.marginBottom || '0px'};
  align-items: ${(p) => p.alignItems || 'center'};
  align-content: ${(p) => p.alignContent || 'center'};
  flex: 1 1 0;
  flex-grow: ${(p) => p.flexGrow || '1'};
  min-width: ${(p) => p.minWidth || '0px'};
`

const EnergyContainer = styled.div`
  margin-top: 20px;
  padding-bottom: 15px;
`

const IconContainer = styled.div`
  display: flex;
  margin-right: 40px;
`

export const InfoIconContainer = styled.div`
  display: flex;
  flex-grow: 1;
  margin-left: 10px;
`

const Text = styled.div<TextProps>`
  ${(p) => theme.text('normal', 'content', p.bold ? 'bold' : 'normal')};
  color: ${defaultTextColor};
  text-align: ${(p) => p.align || 'left'};
  margin-right: ${(p) => p.marginRight || '0px'};
`

export const RollingGuideLink = styled.a`
  ${theme.text('mediumSmall')};
  text-decoration-line: underline;
  color: ${defaultTextColor};
  cursor: pointer;
`

type Props = {
  t: TFunction
  energyEfficiency?: EnergyEfficiency
  rollingGuideLink?: string
}

const formatDate = (dateStr: string) => (dateStr ? moment(dateStr).format('DD.MM.') : '-')

const parseShiftsWithEnergyData = (energyEfficiency?: EnergyEfficiency) => {
  if (!energyEfficiency || !energyEfficiency.shifts) {
    return []
  }

  return energyEfficiency.shifts.filter(
    (shift) => shift.legs && shift.legs.length > 0 && shift.legs[0].correctedConsumptionInKWh
  )
}

const EnergyEfficiencyTaskInfo = ({ t, energyEfficiency, rollingGuideLink }: Props) => {
  const [showConsumptionInfo, setShowConsumptionInfo] = useState(false)
  const latestComparativeData = energyEfficiency?.latestComparativeData
  const shiftsWithEnergyData = parseShiftsWithEnergyData(energyEfficiency)

  return latestComparativeData?.pctl20 ||
    latestComparativeData?.median ||
    shiftsWithEnergyData.length > 0 ||
    rollingGuideLink ? (
    <EnergyContainer>
      <Row alignItems="flex-start" marginTop="20px">
        <Text bold>
          Top 20% {roundSafe(latestComparativeData?.pctl20) || '-'} kWh, med.{' '}
          {roundSafe(latestComparativeData?.median) || '-'} kWh
        </Text>
        <InfoIconContainer onClick={() => setShowConsumptionInfo(!showConsumptionInfo)}>
          <InfoFill iconSize="larger" />
        </InfoIconContainer>
      </Row>

      {rollingGuideLink && (
        <Row alignItems="flex-start">
          <RollingGuideLink target="_blank" href={rollingGuideLink}>
            {t('rollingGuide')}
          </RollingGuideLink>
        </Row>
      )}

      {shiftsWithEnergyData.map((shift) => (
        <Row
          marginTop="5px"
          alignItems="flex-start"
          key={shift.shiftId}
          justifyContent="flex-start"
        >
          <Text bold marginRight="10px">
            {formatDate(shift.shiftDate)}: {roundSafe(shift.legs[0].correctedConsumptionInKWh)} kWh
          </Text>
          <IconContainer>
            {generateConsumptionIcon(
              shift.legs[0].correctedConsumptionInKWh,
              shift.legs[0].comparativeData
            )}
          </IconContainer>
        </Row>
      ))}

      {showConsumptionInfo && (
        <Row marginTop="10px">
          <Text>
            <Trans
              i18nKey="energyConsumptionTaskRowDescription"
              default=""
              components={{ bold: <strong />, break: <br /> }}
            />
          </Text>
        </Row>
      )}
    </EnergyContainer>
  ) : null
}

export default withTranslation()(EnergyEfficiencyTaskInfo)
