import { Link } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

import { MAJOR_INCIDENT_FEEDBACK_LINK } from '../../constants'
import { EmphasisNormal } from '../Emphasis'

const FeedbackContainer = styled('div')(({ theme }) => ({
  boxShadow: '0 2px 6px 1px rgba(0, 0, 0, 0.1)',
  marginTop: theme.spacing('large'),
}))

const Flex = styled('div')(({ theme }) => ({
  ...theme.layout.flexColumn,
  alignItems: 'flex-start',
  padding: theme.spacing('small'),
  boxSizing: 'border-box',
  width: '100%',
  background: theme.palette.background.secondary,
}))

function IncidentFeedback() {
  const { t } = useTranslation()

  return (
    <FeedbackContainer>
      <Flex>
        <EmphasisNormal>{t('incidents.incidentFeedbackTitle')}</EmphasisNormal>
        <Link variant="body2" href={MAJOR_INCIDENT_FEEDBACK_LINK} target="_blank">
          {t('incidents.majorIncidentFeedback')}
        </Link>
      </Flex>
    </FeedbackContainer>
  )
}

export default IncidentFeedback
