import { useRouter } from 'found'

import { useTypedSelector } from '../../'
import ArrowLeft from '../../icons/ArrowLeft'
import { BackIcon, Constrain, HeaderText, PageHeader } from './PageComponents'

type Props = {
  title: string
}

const BasicHeader = ({ title }: Props): JSX.Element => {
  const { router } = useRouter()
  const found = useTypedSelector((state) => state.found)

  const handleClick = () => {
    if (found?.match?.location?.delta > 0) router.go(-1)
    else router.push('/search')
  }

  return (
    <PageHeader onClick={handleClick} height="64px">
      <Constrain>
        <BackIcon>
          <ArrowLeft iconSize="normal" />
        </BackIcon>
        <HeaderText>{title}</HeaderText>
      </Constrain>
    </PageHeader>
  )
}

export default BasicHeader
