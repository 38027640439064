import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const More = ({ iconSize, fillColor }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          d="M4,14.5 C2.61928813,14.5 1.5,13.3807119 1.5,12 C1.5,10.6192881 2.61928813,9.5 4,9.5 C5.38071187,9.5 6.5,10.6192881 6.5,12 C6.5,13.3807119 5.38071187,14.5 4,14.5 Z M12,14.5 C10.6192881,14.5 9.5,13.3807119 9.5,12 C9.5,10.6192881 10.6192881,9.5 12,9.5 C13.3807119,9.5 14.5,10.6192881 14.5,12 C14.5,13.3807119 13.3807119,14.5 12,14.5 Z M20,14.5 C18.6192881,14.5 17.5,13.3807119 17.5,12 C17.5,10.6192881 18.6192881,9.5 20,9.5 C21.3807119,9.5 22.5,10.6192881 22.5,12 C22.5,13.3807119 21.3807119,14.5 20,14.5 Z"
          fill={fillColor ?? 'currentColor'}
        />
      </g>
    </svg>
  )
}

export default More
