import styled from '@emotion/styled'
import { Router } from 'found'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { fetchMultipleTowings, fetchTowingFormState } from '../../actions/api'
import { getCompoundFromLocomotive, locomotiveHasPattern } from '../../lib/towingUtils'
import { theme } from '../../Theme'
import { Action, AppState, Dispatch, TowingVehicle, TowingVehiclePattern } from '../../types'
import LoadingIndicator from '../LoadingIndicator'
import TaskTowingRow from './TaskTowingRow'

const TowingRowColumn = styled.div`
  ${theme.layout.flexColumn};
`

type Props = {
  router: Router
  locomotives: Array<string>
  fetchTowingVehicles: (array: Array<string>) => void
  createTowingVehicle: (arg0: string, arg1: string) => void
  towingVehicles: Record<string, TowingVehicle>
  towingPatterns: Array<TowingVehiclePattern>
  loading: boolean
}

const TaskTowingRowContainer = ({
  router,
  locomotives,
  fetchTowingVehicles,
  createTowingVehicle,
  towingVehicles,
  towingPatterns,
  loading,
}: Props) => {
  const stringifiedLocomotives = JSON.stringify(locomotives)
  const stringifiedTowingPatterns = JSON.stringify(towingPatterns)
  const stringifiedLocs = JSON.stringify(locomotives)

  useEffect(() => {
    // TODO: should this be mover upwards to prevent unnecessary refetching?
    if (
      !loading &&
      locomotives &&
      locomotives.length &&
      locomotives.length !== 0 &&
      !loading &&
      towingPatterns
    ) {
      const vehicleCompounds = locomotives
        .map((loc) => getCompoundFromLocomotive(loc, towingPatterns))
        .filter(Boolean)
      if (vehicleCompounds.length > 0) {
        fetchTowingVehicles(vehicleCompounds as string[])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedLocs, stringifiedLocomotives, stringifiedTowingPatterns]) // a hack, but useEffect does not support arrays as a dependency

  return loading ? (
    <LoadingIndicator />
  ) : (
    <TowingRowColumn>
      {locomotives &&
        locomotives
          .filter((loc) => locomotiveHasPattern(loc, towingPatterns))
          .map((loc) => {
            const compound = getCompoundFromLocomotive(loc, towingPatterns)
            const towingVehicle = compound ? towingVehicles[compound] : undefined
            return (
              <TaskTowingRow
                key={loc}
                vehicleCompound={compound}
                locomotive={loc}
                towingVehicle={towingVehicle}
                router={router}
                towingPatterns={towingPatterns}
                createTowingVehicle={createTowingVehicle}
              />
            )
          })}
    </TowingRowColumn>
  )
}

type PropsIn = {
  router: Router
}

const mapStateToProps = (state: AppState) => {
  const towingVehicles = state.towingSearch.towingVehiclesByCompound
  const loading = state.towingSearch.loading
  const error = state.towingSearch.error

  const towingPatterns = state.towingPatterns.towingPatterns

  return {
    towingPatterns: towingPatterns as TowingVehiclePattern[],
    towingVehicles,
    loading,
    error,
  }
}

const mapDispatchToProps = (dispatch: Dispatch, { router }: PropsIn) => ({
  fetchTowingVehicles: (vehicleCompounds: Array<string>) =>
    dispatch(fetchMultipleTowings(vehicleCompounds) as unknown as Action),
  createTowingVehicle: (vehicleType: string, vehicleNumber: string) =>
    dispatch(
      fetchTowingFormState(vehicleType, vehicleNumber, null, (id) =>
        router.push(`/towing/${id}/setup`)
      ) as unknown as Action
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)(TaskTowingRowContainer)
