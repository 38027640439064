import { FETCH_CAUSES_ERROR, START_FETCHING_CAUSES, UPDATE_CAUSES } from '../actions/causes'
import { Action, CausesState } from '../types'

export const initialState: CausesState = {
  loading: false,
  error: false,
  causes: {},
}

const causesReducer = (state: CausesState = initialState, action: Action): CausesState => {
  switch (action.type) {
    case START_FETCHING_CAUSES:
      return { ...state, loading: true, error: false }
    case UPDATE_CAUSES:
      return { loading: false, error: false, causes: action.payload }
    case FETCH_CAUSES_ERROR:
      return { ...state, loading: false, error: true }
    default:
      return state
  }
}

export default causesReducer
