import type { Timestamp } from '../types'

export type ToggleScheduleFeedback = {
  type: 'TOGGLE_SCHEDULE_FEEDBACK'
  payload: { listStartTimestamp: Timestamp }
}
export const TOGGLE_SCHEDULE_FEEDBACK = 'TOGGLE_SCHEDULE_FEEDBACK'
export const toggleScheduleFeedback = (listStartTimestamp: Timestamp): ToggleScheduleFeedback => {
  return { type: TOGGLE_SCHEDULE_FEEDBACK, payload: { listStartTimestamp } }
}

export type ToggleScheduleEvents = { type: 'TOGGLE_SCHEDULE_EVENTS' }
export const TOGGLE_SCHEDULE_EVENTS = 'TOGGLE_SCHEDULE_EVENTS'
export const toggleScheduleEvents = (): ToggleScheduleEvents => ({
  type: TOGGLE_SCHEDULE_EVENTS,
})

export type SchedulePageAction = ToggleScheduleEvents | ToggleScheduleFeedback
