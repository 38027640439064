import { TestPushMessage } from '../types'

export type UpdateTestPushMessages = {
  type: 'UPDATE_TEST_PUSH_MESSAGES'
  payload: Array<TestPushMessage>
}
export const UPDATE_TEST_PUSH_MESSAGES = 'UPDATE_TEST_PUSH_MESSAGES'
export const updateTestPushMessages = (
  testPushMessages: Array<TestPushMessage>
): UpdateTestPushMessages => ({
  type: UPDATE_TEST_PUSH_MESSAGES,
  payload: testPushMessages,
})

export type StartSendingTestPushMessage = {
  type: 'START_SENDING_TEST_PUSH_MESSAGE'
}
export const START_SENDING_TEST_PUSH_MESSAGE = 'START_SENDING_TEST_PUSH_MESSAGE'
export const startSendingTestPushMessage = (): StartSendingTestPushMessage => ({
  type: START_SENDING_TEST_PUSH_MESSAGE,
})

export type TestPushMessageError = {
  type: 'TEST_PUSH_MESSAGE_ERROR'
  payload: string
}
export const TEST_PUSH_MESSAGE_ERROR = 'TEST_PUSH_MESSAGE_ERROR'
export const setTestPushMessageError = (error: string): TestPushMessageError => ({
  type: TEST_PUSH_MESSAGE_ERROR,
  payload: error,
})

export type StartFetchingPermissionState = {
  type: 'START_FETCHING_PERMISSION_STATE'
}
export const START_FETCHING_PERMISSION_STATE = 'START_FETCHING_PERMISSION_STATE'
export const startFetchingPermissionState = (): StartFetchingPermissionState => ({
  type: START_FETCHING_PERMISSION_STATE,
})

export type UpdatePermissionState = {
  type: 'UPDATE_PERMISSION_STATE'
  payload: string
}
export const UPDATE_PERMISSION_STATE = 'UPDATE_PERMISSION_STATE'
export const updatePermissionState = (permissionState: string): UpdatePermissionState => ({
  type: UPDATE_PERMISSION_STATE,
  payload: permissionState,
})

export type TestPushMessageAction =
  | UpdateTestPushMessages
  | StartSendingTestPushMessage
  | TestPushMessageError
  | StartFetchingPermissionState
  | UpdatePermissionState
