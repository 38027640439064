import styled from '@emotion/styled'

import { getColor } from '../../Theme'

const SearchHeader = styled.div`
  font-size: 11px;
  color: ${(p) => getColor(p.theme, ['grayWarm'], ['grayWarm'])};
  padding-left: 8px;
  padding-top: 4px;
  text-transform: uppercase;
`

export default SearchHeader
