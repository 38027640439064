interface ExtendedNavigator extends Navigator {
  standalone: boolean
}

// Detect if PWA is running in standalone mode
export const standaloneMode = (): boolean => {
  // https://stackoverflow.com/questions/21125337/how-to-detect-if-web-app-running-standalone-on-chrome-mobile
  return (
    window.matchMedia('(display-mode: standalone)').matches ||
    (window.navigator as ExtendedNavigator).standalone
  )
}

// Check if page was opened by page in same origin
export const hasParentPage = (): boolean => {
  let sameOrigin = false
  try {
    sameOrigin = window.opener.origin === window.origin
  } catch (error) {
    sameOrigin = false
  }
  return sameOrigin
}

// Listen postMessage events with reload
export const listenReloadEvents = (): void => {
  window.addEventListener('message', (event) => {
    if (event.data === 'reload') {
      window.document.location.reload()
    }
  })
}

// Send reload message to parent page
export const reloadParentPage = (): void => {
  window.opener.postMessage('reload', '*')
}
