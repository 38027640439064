import styled from '@emotion/styled'
import React from 'react'

import { getColor, px, theme, unPx } from '../../Theme'
import Circle from '../circle/Circle'

interface StyledCircleProps {
  size:
    | 'small'
    | 'smallest'
    | 'tiny'
    | 'smaller'
    | 'normal'
    | 'large'
    | 'larger'
    | 'huge'
    | 'moreHuge'
    | 'biggest'
}

interface AdjustProps extends StyledCircleProps {
  position: string
  isMobile: boolean
}

const StyledCircle = styled(Circle)<StyledCircleProps>`
  ${(p) => theme.spacing.all(p.size)};
  width: ${theme.spacing.sizes.small};
  height: ${theme.spacing.sizes.small};
  border: 2px solid ${(p) => getColor(p.theme, ['shallowBlue'], ['nightBlack'])};
  background: ${(p) => getColor(p.theme, ['white'], ['nightGray'])};
`

const Adjust = styled.div<AdjustProps>`
  position: absolute;
  top: ${(p) =>
    p.position === 'top'
      ? theme.timeline.dashingHeight
      : `calc(50% - ${theme.spacing.sizes.small})`};
  left: -${(p) => px(unPx(theme.timeline.paddingLeftBig) + 26 - (p.size === 'tiny' ? 0 : unPx(theme.spacing.sizes[p.size])))};
  @media (max-width: ${theme.breakpoints.medium}) {
    left: -${(p) => px(unPx(theme.timeline.paddingLeftSmall) + 26 - (p.size === 'tiny' ? 0 : unPx(theme.spacing.sizes[p.size])))};
  }
  @media (max-width: ${theme.maxWidths.column}) {
    display: none;
  }
`

type Props = {
  isMobile: boolean
  position: string
  size: string
}

function TimelineCircle({ isMobile, position, size }: Props) {
  const themedSize = size === 'normal' ? 'tiny' : 'smallest'
  return (
    <Adjust isMobile={isMobile} position={position} size={themedSize}>
      <StyledCircle size={themedSize} />
    </Adjust>
  )
}

export default TimelineCircle
