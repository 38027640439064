import styled from '@emotion/styled'

import Greeting from '../icons/Greeting'
import { color, MaybeColor, theme } from '../Theme'
import { ColorProps } from '../types/App'
import Circle from './circle/Circle'

interface IconCircleProps extends ColorProps {
  bgColor: MaybeColor
}

const Relative = styled.div`
  position: relative;
  box-sizing: border-box; # somehow setting this in Page was not inherited here after Mui changes
`

const IconCircle = styled.div<IconCircleProps>`
  ${theme.spacing.left('small')};
  ${theme.spacing.top('small')};
  border: 1px solid ${(p) => color(p.color, 'primaryBlue')};
  width: ${theme.spacing.sizes.larger};
  height: ${theme.spacing.sizes.larger};
  overflow: hidden;
  background-color: ${(p) => (p.bgColor ? color(p.bgColor) : 'transparent')};
  color: ${(p) => color(p.color, 'primaryBlue')};
  border-radius: ${theme.borderRadius.circle};
`

const Notification = styled(Circle)`
  ${theme.layout.flexRow};
  ${theme.spacing.all('smallest')};
  justify-content: center;
  position: absolute;
  top: -1px;
  right: -4px;
  width: ${theme.spacing.sizes.tiny};
  height: ${theme.spacing.sizes.tiny};
  color: ${color('white')};
  background: ${(p) => color(p.color, 'primaryYellow')};
  border: 1px solid ${(p) => color(p.color, 'primaryYellow')};
`

const PositioningBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BoundingBox = styled.div`
  width: fit-content;
  position: inherit;
`

function FeedbackIcon({
  color,
  bgColor,
  notification,
  styledForMainMenu,
}: {
  color?: MaybeColor
  bgColor?: MaybeColor
  notification?: boolean
  styledForMainMenu?: boolean
}) {
  if (styledForMainMenu) {
    return (
      <PositioningBox>
        <BoundingBox>
          <Greeting iconSize="large" />
          {notification ? <Notification color="red">!</Notification> : undefined}
        </BoundingBox>
      </PositioningBox>
    )
  }

  return (
    <Relative>
      <IconCircle color={color} bgColor={bgColor}>
        <Greeting iconSize="large" />
      </IconCircle>
      {notification ? <Notification>!</Notification> : undefined}
    </Relative>
  )
}
export default FeedbackIcon
