import { createAsyncThunk } from '@reduxjs/toolkit'

import config from '../../config'
import { apiGET } from '../../lib/api'
import { SmDisruptionMessage } from '../../types/Input'

const fetchSmDisruptions = (): Promise<SmDisruptionMessage[]> => apiGET('smDisruptions')

type FetchSmDisruptionsError = {
  message: string
}

export const fetchSmDisruptionsAction = createAsyncThunk<
  SmDisruptionMessage[],
  void,
  { rejectValue: FetchSmDisruptionsError }
>('sm-disruptions/fetchSmDisruptions', async (_: void, thunkApi) => {
  if (config.environment === 'prod') {
    return []
  }
  try {
    return await fetchSmDisruptions()
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: 'Failed to fetch sm disruptions',
    })
  }
})
