import { withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Error from '../icons/Error'
import Refresh from '../icons/Refresh'
import { color, defaultTextColor, theme } from '../Theme'
import { ThemeProps } from '../types/App'
import ConstrainToColumn from './ConstrainToColumn'

const Container = styled(ConstrainToColumn)`
  ${theme.layout.flexColumn};
  max-width: 100%;
  margin-top: ${theme.spacing.sizes.huge};
`

const ErrorText = styled.div`
  ${theme.text('larger', 'decorative', 'bold')};
  color: ${defaultTextColor};
  margin: ${theme.spacing.sizes.huge} 0;
  max-width: 100%;
  text-align: center;
`

const Action = styled.div`
  ${theme.layout.flexRow};
  ${theme.text('small', 'decorative', 'bold')};
  color: ${color('primaryBlue')};
  cursor: pointer;
  text-transform: uppercase;
`

const IconPadding = styled.div`
  margin-left: ${theme.spacing.sizes.small};
  width: ${theme.spacing.sizes.large};
`

type Props = {
  error: string
  action: () => void
  actionText?: string
  actionIcon?: JSX.Element
  tProps?: Record<string, string>
}

function ActionError({
  error,
  action,
  actionText,
  actionIcon = <Refresh iconSize="large" />,
  tProps,
}: Props & ThemeProps) {
  const { t } = useTranslation()
  return (
    <Container>
      <Error iconSize="colossal" />
      <ErrorText>{t(error)}</ErrorText>
      <Action onClick={action}>
        {tProps && actionText && t(actionText, tProps)}
        {!tProps && actionText && t(actionText)}
        <IconPadding>{actionIcon}</IconPadding>
      </Action>
    </Container>
  )
}

export default withTheme(ActionError)
