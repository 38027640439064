import styled from '@emotion/styled'
import { Router } from 'found'
import { TFunction } from 'i18next'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { fetchContacts } from '../actions/api'
import ErrorText from '../components/ErrorText'
import LoadingIndicator from '../components/LoadingIndicator'
import Page from '../components/page/Page'
import { Container } from '../components/page/PageComponents'
import PhoneContactList from '../components/phone-contact/PhoneContactList'
import SearchBox from '../components/search/SearchBox'
import SearchContainer from '../components/search/SearchContainer'
import SearchHeader from '../components/search/SearchHeader'
import SearchInput from '../components/search/SearchInput'
import SearchPageHeader from '../components/search/SearchPageHeader'
import { isPhoneSelector } from '../Selectors'
import { theme } from '../Theme'
import { Action, AppState, Dispatch, PhoneContactGroup } from '../types'

type Props = {
  t: TFunction
  router: Router
  params: unknown
  loadingContacts: boolean
  contactsError: string
  phoneContacts?: Array<PhoneContactGroup>
  fetchContacts: () => unknown
  isPhone: boolean
}

const PaddedContainer = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`

const MediumErrorText = styled(ErrorText)`
  ${theme.text('normal', 'decorative', 'bold')};
  margin: ${theme.spacing.sizes.large};
`

const StickySearchBox = styled(SearchBox)`
  color: white;
`

const StickySearchContainer = styled(SearchContainer)`
  position: sticky;
  position: -webkit-sticky;
  overflow: visible;
  top: 0px;
  z-index: 4;
`

const ContactsPage = ({
  t,
  loadingContacts,
  contactsError,
  phoneContacts,
  fetchContacts,
}: Props) => {
  const [searchString, setSearchString] = useState('')

  useEffect(() => {
    fetchContacts()
  }, [fetchContacts])

  return (
    <Page overflowVisible={true}>
      <Container>
        <SearchPageHeader name="phone" text={t('contacts')} />
        <PaddedContainer>
          <StickySearchContainer>
            <StickySearchBox marginRight="8px" active={true}>
              <SearchHeader>{t('searchString')}</SearchHeader>
              <SearchInput
                type="text"
                value={searchString}
                onChange={(event) => setSearchString(event.target.value)}
              />
            </StickySearchBox>
          </StickySearchContainer>
          {loadingContacts && <LoadingIndicator size="normal" padded={true} />}
          {contactsError !== '' && <MediumErrorText>{t(contactsError)}</MediumErrorText>}
          <PhoneContactList phoneContacts={phoneContacts} searchString={searchString} t={t} />
        </PaddedContainer>
      </Container>
    </Page>
  )
}

const mapStateToProps = (state: AppState) => {
  const loadingContacts = state.phoneContacts.loading
  const contactsError = state.phoneContacts.error
  const phoneContacts = state.phoneContacts.phoneContacts

  const isPhone = isPhoneSelector(state)

  return {
    loadingContacts,
    contactsError,
    phoneContacts,
    isPhone,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchContacts: () => dispatch(fetchContacts() as unknown as Action),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ContactsPage))
