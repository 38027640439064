import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

import ArrowLeft from '../../icons/ArrowLeft'
import { Constrain, Footer, FooterText } from './PageComponents'

const BasicFooter = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Footer onClick={() => window.history.back()}>
      <Constrain>
        <Box sx={(theme) => ({ paddingLeft: theme.spacing('smaller') })}>
          <ArrowLeft iconSize="normal" />
        </Box>
        <FooterText>{t('back')}</FooterText>
      </Constrain>
    </Footer>
  )
}

export default BasicFooter
