import { Shift } from '../types'
import { DutyEvaluation } from '../types/Input'

export type StartLoadingShift = {
  type: 'START_LOADING_SHIFT'
  payload: string
}
export const START_LOADING_SHIFT = 'START_LOADING_SHIFT'
export const startLoadingShift = (id: string): StartLoadingShift => ({
  type: START_LOADING_SHIFT,
  payload: id,
})

export type UpdateShift = {
  type: 'UPDATE_SHIFT'
  payload: Shift
}
export const UPDATE_SHIFT = 'UPDATE_SHIFT'
export const updateShift = (shift: Shift): UpdateShift => ({
  type: UPDATE_SHIFT,
  payload: shift,
})

export type UpdateDutyEvaluation = {
  type: 'UPDATE_DUTY_EVALUATION'
  payload: Array<DutyEvaluation>
}
export const UPDATE_DUTY_EVALUATION = 'UPDATE_DUTY_EVALUATION'
export const updateDutyEvaluation = (
  dutyEvaluations: Array<DutyEvaluation>
): UpdateDutyEvaluation => ({
  type: UPDATE_DUTY_EVALUATION,
  payload: dutyEvaluations,
})

export type ReloadShift = {
  type: 'RELOAD_SHIFT'
  payload: {
    id: string
  }
}
export const RELOAD_SHIFT = 'RELOAD_SHIFT'
export const reloadShift = (id: string): ReloadShift => ({
  type: RELOAD_SHIFT,
  payload: { id },
})

export type ShiftLoadError = {
  type: 'SHIFT_LOAD_ERROR'
  payload: {
    error: string
  }
}
export const SHIFT_LOAD_ERROR = 'SHIFT_LOAD_ERROR'
export const shiftLoadError = (error: string): ShiftLoadError => ({
  type: SHIFT_LOAD_ERROR,
  payload: { error },
})

export type ClearShiftDetails = {
  type: 'CLEAR_SHIFT_DETAILS'
}
export const CLEAR_SHIFT_DETAILS = 'CLEAR_SHIFT_DETAILS'
export const clearShiftDetails = (): ClearShiftDetails => ({
  type: CLEAR_SHIFT_DETAILS,
})

export type ShiftDetailsAction =
  | StartLoadingShift
  | UpdateShift
  | UpdateDutyEvaluation
  | ReloadShift
  | ShiftLoadError
  | ClearShiftDetails
