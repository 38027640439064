import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React from 'react'
import { withTranslation } from 'react-i18next'

import moment from '../lib/moment-fi'
import { defaultTextColor, theme } from '../Theme'
import { Timestamp } from '../types'

const ShiftDate = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
  margin-top: ${theme.spacing.sizes.small};
  min-width: ${theme.maxWidths.column};
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${theme.text('larger', 'content')};
  color: ${defaultTextColor};
  text-align: left;
  align-items: baseline;
  ${theme.spacing.left('tiny')}
`

const WeekNumber = styled.div`
  ${theme.spacing.left('tiny')}
  ${theme.text('larger')}
`

type Props = {
  startsAt: Timestamp
  t: TFunction
}

const NextWeek = ({ startsAt, t }: Props) => {
  return (
    <ShiftDate>
      {t('week')} <WeekNumber>{moment(startsAt).format('W')}</WeekNumber>
    </ShiftDate>
  )
}

export default withTranslation()(NextWeek)
