import { ShiftNotice } from '../types/Input'

export type FetchShiftNotices = {
  type: 'START_FETCHING_SHIFT_NOTICES'
}
export const START_FETCHING_SHIFT_NOTICES = 'START_FETCHING_SHIFT_NOTICES'
export const startFetchingShiftNotices = (): FetchShiftNotices => ({
  type: START_FETCHING_SHIFT_NOTICES,
})

export type UpdateShiftNotice = {
  type: 'UPDATE_SHIFT_NOTICES'
  payload: Array<ShiftNotice>
}
export const UPDATE_SHIFT_NOTICES = 'UPDATE_SHIFT_NOTICES'
export const updateShiftNotices = (payload: Array<ShiftNotice>): UpdateShiftNotice => ({
  type: UPDATE_SHIFT_NOTICES,
  payload,
})

export type ShiftNoticesError = {
  type: 'SHIFT_NOTICES_ERROR'
  payload: string
}
export const SHIFT_NOTICES_ERROR = 'SHIFT_NOTICES_ERROR'
export const shiftNoticeError = (payload: string): ShiftNoticesError => ({
  type: SHIFT_NOTICES_ERROR,
  payload,
})

export type ShiftNoticeAction = FetchShiftNotices | UpdateShiftNotice | ShiftNoticesError
