import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React, { Component } from 'react'

import LongArrowLeft from '../../icons/LongArrowLeft'
import { getPunctualityStationName } from '../../lib/stations'
import { color, theme } from '../../Theme'
import { isMobileProps, LabelsProps } from '../../types/App'
import { DelayCause, MappedPunctuality } from '../../types/Input'
import AmendmentLink from '../amendment/AmendmentLink'
import {
  DelayLabelRow,
  getTimeLabelColor,
  getTimeLabelText,
  getTimeLabelTextColor,
} from '../DelayLabelRow'
import Shadow from '../Shadow'

interface TimeProps extends isMobileProps {
  first?: boolean
}

interface TimeSpanProps extends isMobileProps {
  differenceInMinutes: number
  trainCategory: string
  estimate: boolean
  notDriving: boolean
}

interface RouteLineProps {
  circle: boolean
  totalCauses?: number
  amendedCauses?: number
}

const HEIGHT_PER_CAUSE = 68
const HEIGHT_PER_AMENDMENT = 40

const MarginLeft = styled.span<isMobileProps>`
  margin-left: ${(p) => (p.isMobile ? '8px' : '44px')};
`

const TimeSpan = styled.span<TimeSpanProps>`
  background: ${(p) =>
    color(
      p.isMobile
        ? getTimeLabelColor(p.differenceInMinutes, p.trainCategory, p.estimate, p.notDriving)
        : ''
    )};
  font-weight: ${(p) => (p.estimate ? 'normal' : 'bold')};
  font-style: ${(p) => (p.estimate ? 'italic' : '')};
  color: ${(p) =>
    color(p.isMobile ? getTimeLabelTextColor(p.differenceInMinutes, p.trainCategory) : '')};
`
TimeSpan.displayName = 'TimeSpan'

const RouteLine = styled.span<RouteLineProps>`
  width: 20px;
  height: ${(p) =>
    p.circle
      ? '24px'
      : p.totalCauses
      ? `${
          30 +
          p.totalCauses * HEIGHT_PER_CAUSE +
          (p.amendedCauses ? p.amendedCauses * HEIGHT_PER_AMENDMENT : 0)
        }px`
      : '68px'};
  margin-top: ${(p) => (p.circle ? '0px' : '-6px')};
`
RouteLine.displayName = 'RouteLine'

const ActualRow = styled.div`
  ${theme.layout.flexRow};
  ${theme.layout.fluidWidth(theme.maxWidths.paddedContent)};
  align-items: stretch;
  line-height: ${theme.font.small};
  font-size: ${theme.font.small};
`
ActualRow.displayName = 'ActualRow'

const LabelRow = styled(Shadow)`
  ${theme.layout.flexRow};
  align-items: flex-start;
  padding: 4px 0 6px 8px;
  margin: 0;
`
LabelRow.displayName = 'LabelRow'

const DelayColumn = styled.div`
  ${theme.layout.flexColumn};
  justify-content: space-evenly;
  align-items: flex-start;
  height: 100%;
`
DelayColumn.displayName = 'DelayColumn'

const DelayName = styled.div``
DelayName.displayName = 'DelayName'

const ScheduledRow = styled.div<{ bold: boolean }>`
  ${theme.layout.flexRow};
  ${theme.layout.fluidWidth(theme.maxWidths.paddedContent)};
  justify-content: flex-start;
  font-weight: ${(p) => (p.bold ? 'bold' : 'normal')};
`
ScheduledRow.displayName = 'ScheduledRow'

const Time = styled.span<TimeProps>`
  width: 20%;
  ${theme.layout.flexRow};
  flex-direction: ${(p) => (p.isMobile ? 'column' : 'row')};
  align-items: ${(p) => (p.isMobile ? 'start' : 'baseline')};
`
Time.displayName = 'Time'

const ButtonWrapper = styled.div`
  height: 100%;
`
ButtonWrapper.displayName = 'ButtonWrapper'

const Station = styled.span<LabelsProps>`
  ${theme.layout.flexColumn};
  max-width: 54%;
  text-align: left;
`
Station.displayName = 'Station'

const SpaceBetween = styled.div`
  flex-grow: 2;
`

const getRouteCircle = (
  first: boolean,
  last: boolean,
  differenceDepartureMinutes: number,
  differenceArrivalMinutes: number,
  trainCategory: string,
  estimate: boolean,
  notDriving: boolean
): React.JSX.Element => {
  const circleColorName = getTimeLabelColor(
    last ? differenceArrivalMinutes : differenceDepartureMinutes,
    trainCategory,
    estimate,
    notDriving
  )
  const circleColor = color(circleColorName)
  const size = first || last ? 8 : 6
  const strokedSize = first || last ? 7 : 5
  return (
    <svg width={20} height={24} viewBox="0 0 20 24">
      <g fill="none">
        {last ? (
          <circle cx={10} cy={12} r={strokedSize} stroke={circleColor} strokeWidth={2} />
        ) : (
          <circle cx={10} cy={12} r={size} fill={circleColor} />
        )}
      </g>
    </svg>
  )
}

const getRouteLine = (
  last: boolean,
  differenceArrivalMinutes: number,
  trainCategory: string,
  estimate: boolean,
  notDriving: boolean,
  totalCauses: number,
  amendedCauses: number
): string | React.JSX.Element => {
  const lineColorName = getTimeLabelColor(
    differenceArrivalMinutes,
    trainCategory,
    estimate,
    notDriving
  )
  const lineColor = color(lineColorName)
  const height: number =
    totalCauses > 0
      ? 30 + (totalCauses * (HEIGHT_PER_CAUSE + 6) + amendedCauses * HEIGHT_PER_AMENDMENT)
      : 74
  return last ? (
    ''
  ) : (
    <svg width={20} height={height} viewBox={`0 0 20 ${height}`}>
      <g fill="none">
        <line x1={10} y1={0} x2={10} y2={height} stroke={lineColor} strokeWidth={2} />
      </g>
    </svg>
  )
}

type Props = {
  t: TFunction
  isMobile: boolean
  i: number
  trainCategory: string
  row: MappedPunctuality
  first: boolean
  last: boolean
  nightMode: boolean
  diffToNext: number
  nextArrivalCauses: Array<DelayCause> | null
  nextStation?: string
  amendDeviationReason: (station: string, depOrArr: string) => void
}

class PunctualityRow extends Component<Props> {
  render() {
    const {
      t,
      isMobile,
      i,
      trainCategory,
      row,
      first,
      last,
      nightMode,
      diffToNext,
      nextArrivalCauses,
      nextStation,
      amendDeviationReason,
    }: Props = this.props

    const totalCauses: number =
      (row.departureCauses ? row.departureCauses.length : 0) +
      (nextArrivalCauses ? nextArrivalCauses.length : 0)

    const depCauses = row.departureCauses
      ? row.departureCauses.reduce((prev, acc) => {
          return prev + (acc.amendmentChallenge ? 1 : 0)
        }, 0)
      : 0

    const arrCauses = nextArrivalCauses
      ? nextArrivalCauses.reduce((prev, acc: DelayCause) => {
          return prev + (acc.amendmentChallenge ? 1 : 0)
        }, 0)
      : 0

    const amendedCauses = depCauses + arrCauses

    return (
      <div>
        <ScheduledRow bold={first || last}>
          {row.scheduledArrivalTime ? (
            <Time>
              {row.scheduledArrivalTime}
              {row.scheduledDepartureTime && (
                <MarginLeft isMobile={isMobile}>
                  <LongArrowLeft iconSize="smallest" />
                </MarginLeft>
              )}
            </Time>
          ) : (
            <Time />
          )}
          {row.scheduledDepartureTime ? <Time>{row.scheduledDepartureTime}</Time> : <Time />}
          <RouteLine circle={true}>
            {getRouteCircle(
              first,
              last,
              row.differenceDepartureMinutes || 0,
              row.differenceArrivalMinutes || 0,
              trainCategory,
              row.estimate,
              row.notDriving
            )}
          </RouteLine>
          <Station>{getPunctualityStationName(row.stationShortCode)}</Station>
        </ScheduledRow>
        <ActualRow>
          {row.actualArrivalTime ? (
            <Time first={first} isMobile={isMobile}>
              <TimeSpan
                differenceInMinutes={row.differenceArrivalMinutes}
                trainCategory={trainCategory}
                estimate={row.estimate}
                notDriving={row.notDriving}
                isMobile={isMobile}
              >
                {row.actualArrivalTime} {isMobile && getTimeLabelText(row.differenceArrivalMinutes)}
              </TimeSpan>
              <DelayLabelRow
                t={t}
                task={undefined}
                delays={[{ causes: row.arrivalCauses }]}
                delayMinutes={row.differenceArrivalMinutes || 0}
                trainCategory={trainCategory}
                left={!isMobile}
                nightMode={nightMode}
                isMobile={isMobile}
                estimate={row.estimate}
                notDriving={row.notDriving}
              />
            </Time>
          ) : (
            <Time />
          )}
          {row.actualDepartureTime ? (
            <Time first={first} isMobile={isMobile}>
              <TimeSpan
                isMobile={isMobile}
                differenceInMinutes={row.differenceDepartureMinutes}
                trainCategory={trainCategory}
                estimate={row.estimate}
                notDriving={row.notDriving}
              >
                {row.actualDepartureTime}{' '}
                {isMobile && getTimeLabelText(row.differenceDepartureMinutes)}
              </TimeSpan>
              <DelayLabelRow
                t={t}
                task={undefined}
                delays={[{ causes: row.departureCauses }]}
                delayMinutes={row.differenceDepartureMinutes || 0}
                trainCategory={trainCategory}
                left={!isMobile}
                nightMode={nightMode}
                isMobile={isMobile}
                estimate={row.estimate}
                notDriving={row.notDriving}
              />
            </Time>
          ) : (
            <Time />
          )}
          <RouteLine circle={false} totalCauses={totalCauses} amendedCauses={amendedCauses}>
            {getRouteLine(
              last,
              !last ? diffToNext : 0,
              trainCategory,
              row.estimate,
              row.notDriving,
              totalCauses,
              amendedCauses
            )}
          </RouteLine>
          <Station labels={true}>
            {row.departureCauses && row.departureCauses.length > 0
              ? row.departureCauses
                  .filter((cause, index, self) => {
                    const firstOccurenceOfCategoryCode = self.find(
                      (s) => s.categoryCode === cause.categoryCode
                    ) as DelayCause
                    return self.indexOf(firstOccurenceOfCategoryCode) === index
                  })
                  .map((cause, k) => (
                    <AmendmentLink
                      t={t}
                      nightMode={nightMode}
                      key={`${k}-${i}`}
                      cause={cause}
                      amendDeviationReason={() =>
                        amendDeviationReason(row.stationShortCode, 'DEPARTURE')
                      }
                    />
                  ))
              : null}
            <SpaceBetween />
            {!last && nextArrivalCauses && nextStation && nextArrivalCauses.length > 0
              ? nextArrivalCauses
                  .filter((cause: DelayCause, index, self: DelayCause[]) => {
                    const firstOccurenceOfCategoryCode = self.find(
                      (s) => s.categoryCode === cause.categoryCode
                    ) as DelayCause
                    return self.indexOf(firstOccurenceOfCategoryCode) === index
                  })
                  .map((cause, k) => {
                    if (Object.keys(cause).length === 0) {
                      return null
                    }
                    return (
                      <AmendmentLink
                        t={t}
                        nightMode={nightMode}
                        key={`${k}-${i}`}
                        cause={cause}
                        amendDeviationReason={() => amendDeviationReason(nextStation, 'ARRIVAL')}
                      />
                    )
                  })
              : null}
          </Station>
        </ActualRow>
      </div>
    )
  }
}

export default PunctualityRow
