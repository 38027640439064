import {
  PUNCTUALITY_ERROR,
  SET_PUNCTUALITY_PARAMS,
  START_FETCHING_PUNCTUALITY,
  UPDATE_PUNCTUALITY,
} from '../actions/punctuality'
import { PunctualityAction } from '../actions/punctuality'
import { PunctualityState } from '../types'

export const initialState = {
  loading: false,
  error: '',
  punctualityByTrain: {},
  selectedTrainDate: '',
  selectedTrainNumber: '',
}

const punctualityReducer = (
  state: PunctualityState = initialState,
  action: PunctualityAction
): PunctualityState => {
  switch (action.type) {
    case START_FETCHING_PUNCTUALITY:
      return {
        ...state,
        loading: true,
        error: '',
      }

    case UPDATE_PUNCTUALITY: {
      let newPunctualities = { ...state.punctualityByTrain, ...{} }
      action.payload.forEach((p) => {
        if (p) {
          const key = `${p.trainNumber.toString()}+${p.departureDate}`
          newPunctualities = { ...newPunctualities, ...{ [key]: p } }
        }
      })
      return {
        ...state,
        error: '',
        loading: false,
        punctualityByTrain: newPunctualities,
      }
    }

    case SET_PUNCTUALITY_PARAMS:
      return {
        ...state,
        selectedTrainDate: action.payload.trainDate,
        selectedTrainNumber: action.payload.trainNumber,
      }

    case PUNCTUALITY_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export default punctualityReducer
