import { createSlice } from '@reduxjs/toolkit'

import { fetchEquipmentsAction } from '../actions/defect-common'
import { dataWithStatus, Equipment } from '../components/create-defect/types'

interface OptionTypes {
  equipments: Equipment[]
}

export type CommonDataState = {
  [Key in keyof OptionTypes]: dataWithStatus<OptionTypes[Key]>
}

const initialState: CommonDataState = {
  equipments: { status: 'none', data: [] },
}

export const defectCommonDataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    resetEquipmentsStatus: (state) => {
      state.equipments.status = initialState.equipments.status
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEquipmentsAction.pending, (state) => {
      state.equipments.status = 'loading'
    })

    builder.addCase(fetchEquipmentsAction.fulfilled, (state, { payload }) => {
      state.equipments = { data: payload.data, status: 'succeeded' }
    })

    builder.addCase(fetchEquipmentsAction.rejected, (state) => {
      state.equipments = { data: [], status: 'failed' }
    })
  },
})

export const { resetEquipmentsStatus } = defectCommonDataSlice.actions

export default defectCommonDataSlice.reducer
