import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Action, ActionPayload, MeasurementType, MeasurementTypeValue } from '../types'
import MeasurementCheckbox from './input/MeasurementCheckbox'
import MeasurementRadioGroup from './input/MeasurementRadioGroup'
import MeasurementTextField from './input/MeasurementTextField'
import { MeasurementInput } from './MeasurementInput'

const ActionContent = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  background: theme.palette.background.default,
  ...theme.layout.flexColumn,
  alignItems: 'flex-start',
  width: '100%',
}))

const getInput = (
  wbNotStarted: boolean,
  action: Action,
  measurement: MeasurementType,
  values: Array<MeasurementTypeValue>,
  updateAction: (actionId: string, payload: ActionPayload) => void
): JSX.Element => {
  if (values.length === 1) {
    return (
      <MeasurementCheckbox
        wbNotStarted={wbNotStarted}
        action={action}
        measurement={measurement}
        updateAction={updateAction}
      />
    )
  } else if (values.length === 0) {
    return (
      <MeasurementTextField
        wbNotStarted={wbNotStarted}
        action={action}
        updateAction={updateAction}
      />
    )
  }
  return (
    <MeasurementRadioGroup
      wbNotStarted={wbNotStarted}
      action={action}
      measurement={measurement}
      updateAction={updateAction}
    />
  )
}

type Props = {
  wbNotStarted: boolean
  measurement: MeasurementType
  action: Action
  updateAction: (actionId: string, payload: ActionPayload) => void
}

const Measurement = ({ wbNotStarted, action, measurement, updateAction }: Props): JSX.Element => {
  return (
    <ActionContent>
      <Typography variant="h6">
        {measurement.description}
        {measurement.measurementUnit ? ` (${measurement.measurementUnit})` : undefined}
      </Typography>
      <MeasurementInput>
        {getInput(wbNotStarted, action, measurement, measurement.values, updateAction)}
      </MeasurementInput>
    </ActionContent>
  )
}

export default Measurement
