import { Compositions, Modal } from '../types'

export type UpdateCompositions = {
  type: 'UPDATE_COMPOSITIONS'
  payload: Compositions
}
export const UPDATE_COMPOSITIONS = 'UPDATE_COMPOSITIONS'
export const updateCompositions = (compositions: Compositions): UpdateCompositions => ({
  type: UPDATE_COMPOSITIONS,
  payload: compositions,
})

export type SetCompositionsError = {
  type: 'COMPOSITIONS_ERROR'
  payload: string
}
export const COMPOSITIONS_ERROR = 'COMPOSITIONS_ERROR'
export const compositionsFetchingError = (error: string): SetCompositionsError => ({
  type: COMPOSITIONS_ERROR,
  payload: error,
})

export type FetchCompositions = {
  type: 'START_FETCHING_COMPOSITIONS'
}
export const START_FETCHING_COMPOSITIONS = 'START_FETCHING_COMPOSITIONS'
export const startFetchingCompositions = (): FetchCompositions => ({
  type: START_FETCHING_COMPOSITIONS,
})

export type PostCheckOrInspection = {
  type: 'START_CHECK_OR_INSPECTION'
}
export const START_CHECK_OR_INSPECTION = 'START_CHECK_OR_INSPECTION'
export const startCheckOrInspection = (): PostCheckOrInspection => ({
  type: START_CHECK_OR_INSPECTION,
})

export type UpdateCompositionHistory = {
  type: 'UPDATE_COMPOSITION_HISTORY'
  payload: Array<Compositions>
}
export const UPDATE_COMPOSITION_HISTORY = 'UPDATE_COMPOSITION_HISTORY'
export const updateCompositionHistory = (
  history: Array<Compositions>
): UpdateCompositionHistory => ({
  type: UPDATE_COMPOSITION_HISTORY,
  payload: history,
})

export type SetCompositionFromHistory = {
  type: 'SET_COMPOSITION_FROM_HISTORY'
  payload: Compositions
}
export const SET_COMPOSITION_FROM_HISTORY = 'SET_COMPOSITION_FROM_HISTORY'
export const setCompositionFromHistory = (
  composition: Compositions
): SetCompositionFromHistory => ({
  type: SET_COMPOSITION_FROM_HISTORY,
  payload: composition,
})
type OpenModal = {
  type: 'OPEN_MODAL'
  payload: Modal
}
export const OPEN_MODAL = 'OPEN_MODAL'
export const openModal = (modal: Modal): OpenModal => ({
  type: OPEN_MODAL,
  payload: modal,
})

export type CompositionsAction =
  | UpdateCompositions
  | SetCompositionsError
  | FetchCompositions
  | PostCheckOrInspection
  | UpdateCompositionHistory
  | SetCompositionFromHistory
  | OpenModal
