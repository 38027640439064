import {
  CHECK_IN_MODAL,
  OPEN_MODAL,
  START_FETCHING_TIMETABLE,
  TIMETABLE_ERROR,
  UPDATE_TIMETABLE,
} from '../actions/timetable'
import { TimetableAction } from '../actions/timetable'
import { TimetableState } from '../types'

export const initialState = {
  loading: false,
  error: '',
  modal: {
    open: false,
    message: '',
    checked: false,
  },
  usedTimetableParams: [],
}

const timetableReducer = (
  state: TimetableState = initialState,
  action: TimetableAction
): TimetableState => {
  switch (action.type) {
    case START_FETCHING_TIMETABLE:
      return {
        ...state,
        loading: true,
        modal: { ...state.modal, ...{ open: false } },
        usedTimetableParams: [],
      }

    case UPDATE_TIMETABLE:
      return {
        ...state,
        error: '',
        loading: false,
        modal: { ...state.modal, ...{ open: false, checked: false } },
        usedTimetableParams: [],
      }

    case TIMETABLE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        modal: { ...state.modal, ...{ open: false, checked: false } },
        usedTimetableParams: [],
      }

    case OPEN_MODAL:
      return {
        ...state,
        usedTimetableParams: action.payload.usedTimetableParams,
        modal: { ...state.modal, ...{ open: action.payload.isOpen } },
      }

    case CHECK_IN_MODAL:
      return { ...state, modal: { ...state.modal, ...{ checked: action.payload } } }

    default:
      return state
  }
}

export default timetableReducer
