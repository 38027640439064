import { TowingFormState } from '../types'

export type StartFetchingTowingFormState = {
  type: 'START_FETCHING_TOWING_FORM_STATE'
}
export const START_FETCHING_TOWING_FORM_STATE = 'START_FETCHING_TOWING_FORM_STATE'
export const startFetchingTowingFormState = (): StartFetchingTowingFormState => ({
  type: START_FETCHING_TOWING_FORM_STATE,
})

export type StartTowingFormStateTransition = {
  type: 'START_TOWING_FORM_STATE_TRANSITION'
}
export const START_TOWING_FORM_STATE_TRANSITION = 'START_TOWING_FORM_STATE_TRANSITION'
export const startTowingFormStateTransition = (): StartTowingFormStateTransition => ({
  type: START_TOWING_FORM_STATE_TRANSITION,
})

export type UpdateTowingFormState = {
  type: 'UPDATE_TOWING_FORM_STATE'
  payload: TowingFormState
}
export const UPDATE_TOWING_FORM_STATE = 'UPDATE_TOWING_FORM_STATE'
export const updateTowingFormState = (towingFormState: TowingFormState): UpdateTowingFormState => ({
  type: UPDATE_TOWING_FORM_STATE,
  payload: towingFormState,
})

export type StartSavingTowingFormState = {
  type: 'START_SAVING_TOWING_FORM_STATE'
  payload: TowingFormState
}
export const START_SAVING_TOWING_FORM_STATE = 'START_SAVING_TOWING_FORM_STATE'
export const startSavingTowingFormState = (
  towingFormState: TowingFormState
): StartSavingTowingFormState => ({
  type: START_SAVING_TOWING_FORM_STATE,
  payload: towingFormState,
})

export type ClearTowingFormState = {
  type: 'CLEAR_TOWING_FORM_STATE'
}
export const CLEAR_TOWING_FORM_STATE = 'CLEAR_TOWING_FORM_STATE'
export const clearTowingFormState = (): ClearTowingFormState => ({
  type: CLEAR_TOWING_FORM_STATE,
})

export type TowingFormStateError = {
  type: 'TOWING_FORM_STATE_ERROR'
  payload: string
}
export const TOWING_FORM_STATE_ERROR = 'TOWING_FORM_STATE_ERROR'
export const towingFormStateError = (error: string): TowingFormStateError => ({
  type: TOWING_FORM_STATE_ERROR,
  payload: error,
})

export type StartSavingTowingStep = {
  type: 'START_SAVING_TOWING_STEP'
  payload: string
}
export const START_SAVING_TOWING_STEP = 'START_SAVING_TOWING_STEP'
export const startSavingTowingStep = (stepOrSelection: string): StartSavingTowingStep => ({
  type: START_SAVING_TOWING_STEP,
  payload: stepOrSelection,
})

export type TowingFormStateAction =
  | StartFetchingTowingFormState
  | StartTowingFormStateTransition
  | StartSavingTowingFormState
  | UpdateTowingFormState
  | ClearTowingFormState
  | TowingFormStateError
  | StartSavingTowingStep
