import styled from '@emotion/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'

import packageJson from '../../../package.json'
import { useTypedDispatch } from '../..'
import { newAppVersion } from '../../actions/system'
import { APP_VERSION_STORAGE_KEY } from '../../constants'
import Greeting from '../../icons/Greeting'
import { confirmUpdates } from '../../lib/data'
import { visitURL } from '../../lib/url'
import { colors, defaultTextColor, theme } from '../../Theme'
import { Action } from '../../types'
import Button, { OutlineButton } from '../button/Button'
import Popup from './Popup'

const IconWrapper = styled.div`
  ${theme.spacing.ends('large')};
  color: ${colors.primaryGreen};
`

const Title = styled.div`
  ${theme.spacing.top('large')};
  ${theme.spacing.bottom('small')};
  ${theme.text('larger', 'decorative', 'bold')};
  color: ${defaultTextColor};
`

const Describe = styled.div`
  ${theme.spacing.ends('larger')};
  ${theme.text('large', 'content')};
  color: ${defaultTextColor};
  text-align: center;
`

const Visit = styled(Button)`
  margin-top: ${theme.spacing.sizes.small};
`

const Close = styled(OutlineButton)`
  margin-top: ${theme.spacing.sizes.small};
`

const UpdatePopup = () => {
  const { t } = useTranslation()
  const dispatch = useTypedDispatch()

  const actionDone = () => {
    localStorage.setItem(APP_VERSION_STORAGE_KEY, packageJson.version)
    dispatch(newAppVersion(false))
    confirmUpdates()
  }

  const onSeeChanges = () => {
    actionDone()
    dispatch(visitURL('/about') as unknown as Action)
  }

  const onClose = () => {
    actionDone()
  }

  return (
    <Popup onClose={onClose}>
      <>
        <IconWrapper>
          <Greeting iconSize="biggest" />
        </IconWrapper>
        <Title>{t('appHasUpdated')}</Title>
        <Describe>{t('appUpdateDescription')}</Describe>
        <Visit onClick={onSeeChanges}>{t('seeUpdateChanges')}</Visit>
        <Close onClick={onClose}>{t('close')}</Close>
      </>
    </Popup>
  )
}

export default UpdatePopup
