import styled from '@emotion/styled'

import { getColor, theme } from '../Theme'

const Label = styled.div`
  ${theme.text('small')};
  color: ${(p) => getColor(p.theme, ['grayDark'], ['grayPink'])};
  text-transform: uppercase;
`

export default Label
