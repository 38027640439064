import { createSlice } from '@reduxjs/toolkit'

import {
  completeActivityAction,
  fetchServiceOrderAction,
  fetchServiceOrdersAction,
  handoverWorkshopBookingAction,
  startWorkshopBookingAction,
} from '../../actions/serviceOrders'
import { ServiceOrder, Task } from '../../components/serviceOrders/types'
import { ActionStatus } from '../../types/Input'

export interface DataWithStatus<T> {
  status: ActionStatus
  data: T
  error?: string
}

type StatusWithError = {
  status: ActionStatus
  error?: string
}

interface StateWithServiceOrders {
  serviceOrders: ServiceOrdersState
}

export interface ServiceOrdersState {
  serviceOrders: DataWithStatus<ServiceOrder[]>
  selectedServiceOrder: DataWithStatus<ServiceOrder | undefined>
  completeActivity: StatusWithError
  completeServiceOrder: StatusWithError
  startWorkshopBooking: StatusWithError
  selectedTask?: Task
}

const initialState: ServiceOrdersState = {
  serviceOrders: { status: 'none', data: [] },
  selectedServiceOrder: { status: 'none', data: undefined },
  completeActivity: { status: 'none' },
  completeServiceOrder: { status: 'none' },
  startWorkshopBooking: { status: 'none' },
}

export const serviceOrdersSlice = createSlice({
  name: 'serviceOrder',
  initialState,
  reducers: {
    updateSelectedServiceOrder: (state, action) => {
      state.selectedServiceOrder.data = action.payload
    },
    updateSelectedServiceOrderTask: (state, action) => {
      state.selectedTask = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchServiceOrdersAction.pending, (state) => {
      state.serviceOrders.status = 'loading'
      state.serviceOrders.error = undefined
    })

    builder.addCase(fetchServiceOrdersAction.fulfilled, (state, { payload }) => {
      state.serviceOrders.data = payload
      state.serviceOrders.status = 'succeeded'
    })

    builder.addCase(fetchServiceOrdersAction.rejected, (state, { payload }) => {
      if (payload) {
        state.serviceOrders.error = payload.message
      }
      state.serviceOrders.status = 'failed'
    })

    builder.addCase(fetchServiceOrderAction.pending, (state) => {
      state.selectedServiceOrder.status = 'loading'
      state.selectedServiceOrder.error = undefined
    })

    builder.addCase(fetchServiceOrderAction.fulfilled, (state, { payload }) => {
      state.selectedServiceOrder.data = payload
      state.selectedServiceOrder.status = 'succeeded'
    })

    builder.addCase(fetchServiceOrderAction.rejected, (state, { payload }) => {
      if (payload) {
        state.selectedServiceOrder.error = payload.message
      }
      state.selectedServiceOrder.status = 'failed'
    })

    builder.addCase(handoverWorkshopBookingAction.pending, (state) => {
      state.completeServiceOrder.status = 'loading'
      state.completeServiceOrder.error = undefined
    })

    builder.addCase(handoverWorkshopBookingAction.fulfilled, (state) => {
      const oldSelectedServiceOrder = state.selectedServiceOrder.data
      if (oldSelectedServiceOrder) {
        const newServiceOrder = {
          ...oldSelectedServiceOrder,
          status: 'COMPLETED',
        }
        state.selectedServiceOrder.data = newServiceOrder
        state.completeServiceOrder.status = 'succeeded'
      } else {
        // something went wrong
        state.completeServiceOrder.status = 'failed'
        state.startWorkshopBooking.error = 'serviceOrder.actions.error.unknown'
      }
    })

    builder.addCase(handoverWorkshopBookingAction.rejected, (state, { payload }) => {
      if (payload) {
        switch (payload.message) {
          case 'FAILED_TO_COMPLETE_SERVICE_ORDER':
            state.startWorkshopBooking.error = 'serviceOrder.error.complete'
            break
          case 'FAILED_TO_HANDOVER_WORKSHOP_BOOKING':
            state.startWorkshopBooking.error = 'serviceOrder.error.handover'
            break
          default:
            state.startWorkshopBooking.error = 'serviceOrder.actions.error.unknown'
            break
        }
      }
      state.startWorkshopBooking.status = 'failed'
    })

    builder.addCase(completeActivityAction.pending, (state) => {
      state.completeActivity.status = 'loading'
      state.completeActivity.error = undefined
    })

    builder.addCase(completeActivityAction.fulfilled, (state, { payload }) => {
      const oldSelectedServiceOrder = state.selectedServiceOrder.data
      const activities = oldSelectedServiceOrder?.activities.map((activity) => {
        if (activity.id === payload.id) {
          return {
            ...activity,
            status: 'COMPLETED',
          }
        } else {
          return activity
        }
      })
      state.selectedServiceOrder.data = oldSelectedServiceOrder
        ? { ...oldSelectedServiceOrder, activities: activities || [] }
        : undefined
      state.completeActivity.status = 'succeeded'
    })

    builder.addCase(completeActivityAction.rejected, (state, { payload }) => {
      if (payload) {
        state.completeActivity.error = payload.message
      }
      state.completeActivity.status = 'failed'
    })

    builder.addCase(startWorkshopBookingAction.pending, (state) => {
      state.startWorkshopBooking.status = 'loading'
      state.startWorkshopBooking.error = undefined
    })

    builder.addCase(startWorkshopBookingAction.fulfilled, (state, { payload }) => {
      const oldSelectedServiceOrder = state.selectedServiceOrder.data
      const activities = oldSelectedServiceOrder?.activities.map((activity) => {
        if (activity.id && payload.activities[0].id) {
          return {
            ...payload.activities[0],
            tasks: activity.tasks,
            status: payload.status.toUpperCase(), // set activity status to RELEASED from sercvieOrder status, to avoid need for refetching.. if so is released so are all its activities
          }
        } else {
          return activity
        }
      })
      state.selectedServiceOrder.data = oldSelectedServiceOrder
        ? {
            ...oldSelectedServiceOrder,
            activities: activities || [],
            status: payload.status.toUpperCase(),
          }
        : undefined

      state.startWorkshopBooking.status = 'succeeded'
    })

    builder.addCase(startWorkshopBookingAction.rejected, (state, { payload }) => {
      state.startWorkshopBooking.status = 'failed'
      if (payload) {
        switch (payload.message) {
          case 'FAILED_TO_ASSIGN_ACTIVITY':
            state.startWorkshopBooking.error = 'serviceOrder.actions.error.assignActivity'
            break
          case 'FAILED_TO_RELEASE_SERVICE_ORDER':
            state.startWorkshopBooking.error = 'serviceOrder.actions.error.releaseSO'
            break
          case 'FAILED_TO_START_WORKSHOP_BOOKING':
            state.startWorkshopBooking.error = 'serviceOrder.actions.error.startWB'
            break
          default:
            state.startWorkshopBooking.error = 'serviceOrder.actions.error.unknown'
            break
        }
      }
    })
  },
})

export const { updateSelectedServiceOrder, updateSelectedServiceOrderTask } =
  serviceOrdersSlice.actions

export const selectServiceOrders = (state: StateWithServiceOrders): ServiceOrder[] =>
  state.serviceOrders.serviceOrders.data

export const selectCompleteActivityStatus = (state: StateWithServiceOrders): ActionStatus =>
  state.serviceOrders.completeActivity.status

export const selectSelectedTask = (state: StateWithServiceOrders): Task | undefined =>
  state.serviceOrders.selectedTask

export const selectSelectedServiceOrder = (
  state: StateWithServiceOrders
): ServiceOrder | undefined => state.serviceOrders.selectedServiceOrder.data
export const selectSelectedServiceOrderStatus = (state: StateWithServiceOrders): ActionStatus =>
  state.serviceOrders.selectedServiceOrder.status

export const selectWorkshopBookingId = (state: StateWithServiceOrders): string =>
  state.serviceOrders.selectedServiceOrder.data?.activities[0]?.workshopBooking || ''
export const selectWorkshopBookingStatus = (state: StateWithServiceOrders): string =>
  state.serviceOrders.selectedServiceOrder.data?.activities[0]?.workshopMaintenanceStatus || ''

export const selectStartWorkshopBookingStatus = (state: StateWithServiceOrders): ActionStatus =>
  state.serviceOrders.startWorkshopBooking.status
export const selectStartWorkshopBookingError = (state: StateWithServiceOrders): string =>
  state.serviceOrders.startWorkshopBooking.error || 'serviceOrder.actions.error.unknown'

export const selectServiceOrderCompleteStatus = (state: StateWithServiceOrders): ActionStatus =>
  state.serviceOrders.completeServiceOrder.status
export const selectServiceOrderCompleteError = (state: StateWithServiceOrders): string =>
  state.serviceOrders.completeServiceOrder.error || 'serviceOrder.actions.error.unknown'

export default serviceOrdersSlice.reducer
