// https://gist.githubusercontent.com/crisu83/904d23d9357d60c82bd4/raw/9fda7a29fa6b39cc692e72a1a82804c00d0d04aa/moment-locale-fi.js
//! moment.js locale configuration
//! locale : finnish (fi)
//! author : Tarmo Aidantausta : https://github.com/bleadof
//! author : Christoffer Niska : https://github.com/crisu83
import moment from 'moment-timezone'
moment.tz.setDefault('Europe/Helsinki')

function processRelativeTime(
  number: number,
  _withoutSuffix: boolean,
  key: string,
  isFuture: boolean
): string {
  const format: Record<string, string[]> = {
    m: ['minuutti', 'minuutin'],
    mm: [number + ' minuuttia', number + ' minuutin'],
    h: ['tunti', 'tunnin'],
    hh: [number + ' tuntia', number + ' tunnin'],
    d: ['päivä', 'päivän'],
    dd: [number + ' päivää', number + ' päivän'],
    M: ['kuukausi', 'kuukauden'],
    MM: [number + ' kuukautta', number + ' kuukauden'],
    y: ['vuosi', 'vuoden'],
    yy: [number + ' vuotta', number + ' vuoden'],
  }
  return !isFuture ? format[key][0] : format[key][1]
}

moment.defineLocale('fi', {
  months:
    'tammikuu_helmikuu_maaliskuu_huhtikuu_toukokuu_kesäkuu_heinäkuu_elokuu_syyskuu_lokakuu_marraskuu_joulukuu'.split(
      '_'
    ),
  monthsShort: 'tammi_helmi_maalis_huhti_touko_kesä_heinä_elo_syys_loka_marras_joulu'.split('_'),
  weekdays: 'sunnuntai_maanantai_tiistai_keskiviikko_torstai_perjantai_lauantai'.split('_'),
  weekdaysShort: 'su_ma_ti_ke_to_pe_la'.split('_'),
  weekdaysMin: 'su_ma_ti_ke_to_pe_la'.split('_'),
  longDateFormat: {
    LT: 'HH.mm',
    LTS: 'HH.mm.ss',
    L: 'DD.MM.YYYY',
    LL: 'Do MMMM[ta] YYYY',
    LLL: 'Do MMMM[ta] YYYY, [klo] LT',
    LLLL: 'dddd, Do MMMM[ta] YYYY, [klo] LT',
    l: 'D.M.YYYY',
    ll: 'Do MMM YYYY',
    lll: 'Do MMM YYYY, [klo] LT',
    llll: 'ddd, Do MMM YYYY, [klo] LT',
  },
  calendar: {
    sameDay: '[tänään] [klo] LT',
    nextDay: '[huomenna] [klo] LT',
    nextWeek: 'dddd [klo] LT',
    lastDay: '[eilen] [klo] LT',
    lastWeek: '[viime] dddd[na] [klo] LT',
    sameElse: 'L',
  },
  relativeTime: {
    future: '%s päästä',
    past: '%s sitten',
    s: 'hetki',
    m: processRelativeTime,
    mm: processRelativeTime,
    h: processRelativeTime,
    hh: processRelativeTime,
    d: processRelativeTime,
    dd: processRelativeTime,
    M: processRelativeTime,
    MM: processRelativeTime,
    y: processRelativeTime,
    yy: processRelativeTime,
  },
  ordinalParse: /\d{1,2}\./,
  // @ts-expect-error ordinal expects a function, possibly a bug in moment typings
  // TODO: check moment docs for ordinal numbers typing
  ordinal: '%d.',
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
})

moment.locale('fi')

export default moment
