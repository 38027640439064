import styled from '@emotion/styled'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import { formActions } from '../../../lib/towingUtils'
import { getColor, theme } from '../../../Theme'
import TowingFormRow from './TowingFormRow'
import TowingStep from './TowingStep'

const TextContainer = styled.div`
  padding-top: ${theme.spacing.sizes.normal};
  padding-left: ${theme.spacing.sizes.normal};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: ${(p) => getColor(p.theme, ['black'], ['white'])};
`

const TowingCounterElementList = ({ elements, elementStates, formType, formAction }) => {
  if (formAction === formActions.SHOW_SELECTIONS_WITHOUT_STEPS) return <Fragment></Fragment>

  const reverseElements = elements.slice().reverse()
  return reverseElements.map((el) => {
    if (el.contentType === 'towingSelection') {
      const namePattern = /^name[\d]+$/
      const names = Object.keys(el)
        .filter((key) => namePattern.test(key))
        .map((name) => ({ name, branch: name.replace('name', 'branch') }))
      const selectedBranch = names.find(
        (name) => el[name.branch] && elementStates && elementStates[el.key] === name.branch
      )
      const selectedElements = selectedBranch && el[selectedBranch.branch].elements
      if (selectedElements) {
        return (
          <>
            <TowingCounterElementList
              key={selectedBranch.branch}
              elements={selectedElements}
              elementStates={elementStates}
              formType={formType}
              formAction={formAction}
            />
          </>
        )
      }
    } else if (el.contentType === 'towingStep' && el.hasCounterAction) {
      if (el.counterActionPreContent) {
        return (
          <TowingStep
            step={el}
            key={el.counterActionPreContent[0].title || el.counterActionPreContent[0].id}
            stepText={el.counterActionPreContent[0].title}
            counterActionForFinish={true}
            formType={formType}
            formAction={formAction}
          />
        )
      } else {
        return (
          <TowingFormRow key={el.key}>
            <TextContainer>{el.key}</TextContainer>
          </TowingFormRow>
        )
      }
    }

    return undefined
  })
}

const mapStateToProps = (state) => {
  const elementStates = state.towingFormState.actionsAndSelectionsByKey
  return {
    elementStates,
  }
}

export default connect(mapStateToProps)(TowingCounterElementList)
