import { Router } from 'found'
import { TFunction } from 'i18next'
import { ReactNode } from 'react'
import { connect } from 'react-redux'

import moment from '../../lib/moment-fi'
import { DEFAULT_TASK_STATE } from '../../reducers/ShiftPage'
import { mergeTasksWithNotices, nowSelector, shiftIsOver48hAway } from '../../Selectors'
import { theme } from '../../Theme'
import { AppState, Shift, Task, TaskState } from '../../types'
import { CrewNotice } from '../../types/Input'
import CrewNoticeNotification from '../custom-notification/CrewNoticeNotification'
import IncidentContainer from '../incidents/Incident'
import { LiitoIncident } from '../incidents/types'
import LoadingIndicator from '../LoadingIndicator'
import TaskRow from '../task/TaskRow'
import RestRow from './RestRow'
import ScrollWraper from './ScrollWrapper'

const getRelatedIncidents = (incidents: Array<LiitoIncident>, task: Task) => {
  return incidents.filter((incident) => {
    return incident.trains.some((t) => t.trainNumber === task.trainNumber)
  })
}

type Props = {
  shift: Shift
  tasks: Array<Task>
  taskStates?: Record<number, TaskState>
  over48hAway: boolean
  router: Router
  notices?: Array<CrewNotice>
  incidents: Array<LiitoIncident>
  t: TFunction
}

function ShiftTasks({
  shift,
  tasks,
  taskStates,
  over48hAway,
  router,
  notices,
  incidents,
  t,
}: Props) {
  const tasksWithNotices = (notices && mergeTasksWithNotices(tasks, notices)) || tasks

  if (tasks.length === 0 && shift.loading) {
    return <LoadingIndicator size="normal" padded={false} />
  }

  return (
    <div>
      {tasksWithNotices.reduce((taskElements, currentTask, i) => {
        let addedElements: ReactNode[] = []
        const relatedIncidents = getRelatedIncidents(incidents, currentTask)
        if (currentTask.independentNotices) {
          addedElements = currentTask.independentNotices
            .sort((a, b) => moment(a.sentAt).diff(b.sentAt))
            .map((n, index) => (
              <CrewNoticeNotification
                t={t}
                key={taskElements.length + addedElements.length + index}
                crewNotice={n}
                dynamic={false}
                marginTop={theme.spacing.sizes.normal}
                marginBottom={theme.spacing.sizes.normal}
              />
            ))
        }

        if (currentTask.leadingNotices) {
          addedElements = [
            ...addedElements,
            ...currentTask.leadingNotices
              .sort((a, b) => moment(a.sentAt).diff(b.sentAt))
              .map((n, index) => (
                <CrewNoticeNotification
                  t={t}
                  key={taskElements.length + addedElements.length + index}
                  crewNotice={n}
                  dynamic={false}
                  marginTop={index === 0 ? theme.spacing.sizes.normal : '0'}
                  borderTop={index > 0}
                />
              )),
          ]
        }

        addedElements = [
          ...addedElements,
          currentTask.restTask !== 'false' ? (
            <RestRow
              key={taskElements.length + addedElements.length}
              task={currentTask}
              taskState={taskStates?.[i] || DEFAULT_TASK_STATE}
              nextTask={tasksWithNotices[i + 1]}
              nextState={taskStates?.[i + 1] || DEFAULT_TASK_STATE}
              isCommuter={shift.isCommuter}
            />
          ) : (
            <>
              {relatedIncidents.map((incident) => {
                if (i > 0 && tasksWithNotices[i - 1].trainNumber === currentTask.trainNumber) {
                  return <></>
                }
                return <IncidentContainer incident={incident} key={incident.id} />
              })}
              <TaskRow
                over48hAway={over48hAway}
                key={taskElements.length + addedElements.length}
                shift={shift}
                task={currentTask}
                even={i % 2 === 0}
                taskState={taskStates?.[i] || DEFAULT_TASK_STATE}
                nextTask={tasksWithNotices[i + 1]}
                router={router}
              />
            </>
          ),
        ]

        if (currentTask.trailingNotices) {
          addedElements = [
            ...addedElements,
            ...currentTask.trailingNotices
              .sort((a, b) => moment(a.sentAt).diff(b.sentAt))
              .map((n, index) => (
                <CrewNoticeNotification
                  t={t}
                  key={taskElements.length + addedElements.length + index}
                  crewNotice={n}
                  dynamic={false}
                  marginBottom={
                    currentTask.trailingNotices && index === currentTask.trailingNotices.length - 1
                      ? theme.spacing.sizes.normal
                      : '0'
                  }
                  borderTop={index > 0}
                />
              )),
          ]
        }

        const taskStartTime = moment(currentTask.taskStartDateTime)
        const taskEndTime = moment(currentTask.taskEndDateTime)

        const firstElement = (
          <ScrollWraper
            key={taskElements.length + addedElements.length}
            startTime={taskStartTime}
            endTime={taskEndTime}
          >
            {addedElements[0]}
          </ScrollWraper>
        )
        addedElements = shift.isCommuter ? [firstElement, ...addedElements.slice(1)] : addedElements
        return [...taskElements, ...addedElements]
      }, [] as ReactNode[])}
    </div>
  )
}

type PropsIn = {
  shift: Shift
}

const mapStateToProps = (state: AppState, { shift }: PropsIn) => {
  const now = nowSelector(state)
  const tasks = shift.tasks || []
  const taskStates = state.shiftPage.taskState[shift.id] || {}
  const over48hAway = shiftIsOver48hAway(now, shift)

  return {
    tasks,
    taskStates,
    over48hAway,
  }
}

export default connect(mapStateToProps)(ShiftTasks)
