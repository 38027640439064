import { TFunction } from 'i18next'
import { DurationInputArg1 } from 'moment'

import { tickTimer } from '../actions/system'
import { INTERNAL_TICKER_RATE_MS, MOMENT_SHIFT_DATE_FORMAT } from '../constants'
import moment from '../lib/moment-fi'
import { Moment, Store, Timestamp } from '../types'

type Season = 'winter' | 'spring' | 'summer' | 'fall'
type TimeOfDay = 'morning' | 'day' | 'evening' | 'night'

const monthToSeason: Array<Season> = [
  'winter', // January
  'winter', // February
  'spring', // March
  'spring', // April
  'spring', // May
  'summer', // June
  'summer', // July
  'summer', // August
  'fall', // September
  'fall', // October
  'fall', // November
  'winter', // December
]

export const getTimeOfDayFromHours = (hours: number): TimeOfDay => {
  if (hours > 5 && hours <= 10) {
    return 'morning'
  } else if (hours > 10 && hours <= 17) {
    return 'day'
  } else if (hours > 17 && hours <= 22) {
    return 'evening'
  }
  return 'night'
}

export const getTimeOfDayFromTimestamp = (timestamp: Timestamp | number): TimeOfDay => {
  const endMoment = moment(timestamp)
  const hours = endMoment.hours()
  return getTimeOfDayFromHours(hours)
}

export const getSeasonFromMonth = (monthNumber: number): Season => {
  return monthToSeason[monthNumber]
}

export const relativeDate = (t: TFunction, now: Moment, timestamp: Timestamp): string => {
  const today = now.clone().startOf('day')
  const diffInMillis = moment(timestamp).startOf('day').diff(today)
  const duration = moment.duration(diffInMillis, 'ms')
  const fDays = duration.days()
  const days = Math.floor(fDays)
  let args = []

  if (days < 0) {
    args = ['value', { value: moment(timestamp).format(MOMENT_SHIFT_DATE_FORMAT) }]
  } else if (days === 0) {
    args = ['today']
  } else if (days === 1) {
    args = ['tomorrow']
  } else {
    args = ['value', { value: moment(timestamp).format(MOMENT_SHIFT_DATE_FORMAT) }]
  }

  /* eslint-disable prefer-spread */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return t.apply(null, args)
}

const combineFormat = (pieces: Array<Array<string | number>>): string => {
  return pieces
    .reduce((str, [unit, amount]) => {
      if (amount) {
        str += ` ${amount} ${unit}`
      }
      return str
    }, '')
    .trim()
}

export const formatTime = (timestamp: Timestamp): string => {
  const timeMoment = moment(timestamp)
  return combineFormat([
    ['h', timeMoment.hours()],
    ['min', timeMoment.minutes()],
  ])
}

export const formatDuration = (duration: DurationInputArg1): string => {
  const momentDuration = moment.duration(duration)
  let minutes: number = Math.abs(momentDuration.asMinutes())

  const days: number = Math.floor(minutes / (60 * 24))
  minutes -= days * 60 * 24

  const hours: number = Math.floor(minutes / 60)
  minutes -= hours * 60

  minutes = Math.floor(minutes)

  return combineFormat([
    ['d', days],
    ['h', hours],
    ['min', minutes],
  ])
}

export const unixTimestampMs = (): number => Date.now()
export const unixTimestamp = (): number => Math.floor(unixTimestampMs() / 1000)

// Shifts are published in lists of three weeks each having number and year
// E.g. 2018/2 Each calendar year has 17 or 18 lists
// First day of the new year is always in the list number 2
// For example 2017-12-25 started list 2018/2
// This function calculates previous shift list starting day when
// given (shift) starting date as input
export const calculatePreviousShiftListStartDay = (startsAt: Timestamp): Timestamp => {
  const startDay: Moment = moment(startsAt).endOf('day')
  const referenceDay: Moment = moment('2017-12-25T00:00:00Z').startOf('day')
  let listStartDay: Moment = referenceDay

  if (startDay.isAfter(referenceDay)) {
    while (startDay.isAfter(listStartDay)) {
      listStartDay = listStartDay.add(3, 'weeks')
    }
    return listStartDay.subtract(3, 'weeks').toISOString(true)
  } else if (startDay.isBefore(referenceDay)) {
    while (startDay.isBefore(listStartDay)) {
      listStartDay = listStartDay.subtract(3, 'weeks')
    }
    return listStartDay.toISOString(true)
  }

  return referenceDay.toISOString(true)
}

// Ticker
let tickerHandle: NodeJS.Timer | null = null

export const tick = (store: Store) => store.dispatch(tickTimer(Math.floor(Date.now())))

export const startTicker = (store: Store) => {
  tick(store)
  stopTicker()
  tickerHandle = setInterval(() => tick(store), INTERNAL_TICKER_RATE_MS)
}

export const stopTicker = () => {
  if (tickerHandle) {
    clearInterval(tickerHandle)
    tickerHandle = null
  }
}
