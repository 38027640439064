import { Backdrop, Box, Button, Card, Stack, Typography } from '@mui/material'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormInputDropdown } from './input/FormInputDrowpdown'

interface EquipmentSelectionDialogProps {
  submitHandler: (selectedSerialNumber: string) => void
  closeDialog: () => void
  dialogText: string
  primaryActionText?: string
  secondaryActionText?: string
  equipmentIds: string[]
}

interface EquipmentSelectionFormData {
  serialNumber: string
}

const EquipmentSelectionDialog = ({
  submitHandler,
  closeDialog,
  dialogText,
  primaryActionText,
  secondaryActionText,
  equipmentIds,
}: EquipmentSelectionDialogProps): JSX.Element => {
  const { t } = useTranslation()

  const methods = useForm<EquipmentSelectionFormData>({
    defaultValues: {
      serialNumber: '',
    },
    mode: 'onTouched',
  })

  const { handleSubmit } = methods

  const onSubmitHandler: SubmitHandler<EquipmentSelectionFormData> = (
    data: EquipmentSelectionFormData
  ) => {
    submitHandler(data.serialNumber)
  }

  return (
    <FormProvider {...methods}>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '32px',
            gap: '28px',
            width: '340px',
            height: '220px',
            bgcolor: 'background.secondary',
            borderRadius: '16px',
          }}
        >
          <Box textAlign="center">
            <Typography variant="h3">{dialogText}</Typography>
          </Box>
          <Box>
            <FormInputDropdown
              name="serialNumber"
              label={t('defect.filter.installationGroup')}
              options={equipmentIds?.map((o) => ({ label: o, value: o }))}
              status={'succeeded'}
            />
          </Box>
          <Stack direction="row" spacing={1}>
            <Button variant="submitButtonDialogSecondary" onClick={closeDialog}>
              <Typography variant="subtitle1">
                {secondaryActionText ?? t('action.cancel')}
              </Typography>
            </Button>
            <Button variant="submitButtonDialogPrimary" onClick={handleSubmit(onSubmitHandler)}>
              <Typography variant="subtitle1">{primaryActionText ?? t('action.submit')}</Typography>
            </Button>
          </Stack>
        </Card>
      </Backdrop>
    </FormProvider>
  )
}

export default EquipmentSelectionDialog
