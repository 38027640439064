import { Assembly } from '../types'

export type StartLoadingAssemblies = {
  type: 'START_LOADING_ASSEMBLIES'
}
export const START_LOADING_ASSEMBLIES = 'START_LOADING_ASSEMBLIES'
export const startLoadingAssemblies = (): StartLoadingAssemblies => ({
  type: START_LOADING_ASSEMBLIES,
})

export type UpdateAssemblies = {
  type: 'UPDATE_ASSEMBLIES'
  payload: Array<Assembly>
}
export const UPDATE_ASSEMBLIES = 'UPDATE_ASSEMBLIES'
export const updateAssemblies = (assemblies: Array<Assembly>): UpdateAssemblies => ({
  type: UPDATE_ASSEMBLIES,
  payload: assemblies,
})

export type AssemblyLoadError = {
  type: 'ASSEMBLY_LOAD_ERROR'
  payload: {
    error: string
  }
}
export const ASSEMBLY_LOAD_ERROR = 'ASSEMBLY_LOAD_ERROR'
export const assemblyLoadError = (error: string): AssemblyLoadError => ({
  type: ASSEMBLY_LOAD_ERROR,
  payload: { error },
})

export type ClearAssemblies = {
  type: 'CLEAR_ASSEMBLIES'
}
export const CLEAR_ASSEMBLIES = 'CLEAR_ASSEMBLIES'
export const clearAssemblies = (): ClearAssemblies => ({
  type: CLEAR_ASSEMBLIES,
})

export type AssemblyAction =
  | StartLoadingAssemblies
  | UpdateAssemblies
  | AssemblyLoadError
  | ClearAssemblies
