import { getSize } from '../theme/iconSizes'
import IconProps from './Icon'

const Success = ({ iconSize, fillColor }: IconProps) => {
  const size = getSize(iconSize)

  return (
    <svg width={size} height={size} viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          key="0"
          d="M7.78125,10.125 C7.5625,9.875 7.15625,9.84375 6.90625,10.0625 C6.65625,10.28125 6.625,10.6875 6.84375,10.9375 C6.84375,10.9375 6.875,10.96875 6.875,10.96875 L10.65625,15.0625 C10.78125,15.1875 10.9375,15.25 11.09375,15.25 C11.25,15.25 11.40625,15.1875 11.53125,15.0625 L21.0625,5.53125 C21.3125,5.28125 21.3125,4.90625 21.0625,4.65625 C20.8125,4.40625 20.4375,4.40625 20.1875,4.65625 L11.125,13.71875 L7.78125,10.125 Z"
          fill={fillColor ?? 'currentColor'}
        />
        <path
          key="1"
          d="M12,2 C6.45859873,2 2,6.49044586 2,12 C2,17.5095541 6.45859873,22 12,22 C17.5414013,22 22,17.5414013 22,12 C22,11.6496815 21.7133758,11.3630573 21.3630573,11.3630573 C21.0127389,11.3630573 20.7261146,11.6496815 20.7261146,12 C20.7261146,16.8089172 16.8089172,20.7261146 12,20.7261146 C7.1910828,20.7261146 3.27388535,16.8089172 3.27388535,12 C3.27388535,7.1910828 7.1910828,3.27388535 12,3.27388535 C12.3503185,3.27388535 12.6369427,2.98726115 12.6369427,2.63694268 C12.6369427,2.2866242 12.3503185,2 12,2 Z"
          fill={fillColor ?? 'currentColor'}
        />
      </g>
    </svg>
  )
}

export default Success
