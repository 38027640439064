import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import Exclamation from '../../icons/Exclamation'
import { isMobileSelector, nowSelector, taskIsActive } from '../../Selectors'
import { color, defaultTextColor, getColor, px, theme } from '../../Theme'
import { AppState, Task, TaskState } from '../../types'
import { isMobileProps } from '../../types/App'
import Emphasis from '../Emphasis'
import TaskDuration from '../task/TaskDuration'
import IconContent from './IconContent'

interface RestContainerProps extends isMobileProps {
  past?: boolean
  bottom?: boolean
}

interface CellProps extends isMobileProps {
  size: number
  past?: boolean
  hasInstruction?: boolean
}

const RowContainer = styled.div<RestContainerProps>`
  ${theme.layout.flexRow};
  ${theme.spacing.ends('smallest')};
  box-sizing: border-box;
  width: auto;
  min-height: ${px(30)};
  flex: 1;
  ${(p) => p.isMobile && 'justify-content: center;'}
  position: relative;
  background: ${(p) =>
    getColor(
      p.theme,
      [p.past ? 'grayBackground' : 'shallowBlue'],
      [p.past ? 'nightGray' : 'nightSeparator']
    )};
  color: ${(p) => color(p.past ? 'grayDark' : 'primaryBlue')};
  border: 1px solid ${(p) => getColor(p.theme, ['grayBlue'], ['blackAbsolute'])};
  border-bottom: ${(p) =>
    p.bottom ? `1px solid ${getColor(p.theme, ['grayBlue'], ['blackAbsolute'])}` : 'none'};
`

const Column = styled.div<isMobileProps>`
  ${theme.layout.flexColumn};
  align-items: ${(p) => (p.isMobile ? 'center' : 'stretch')};
  ${(p) =>
    !p.isMobile && `border-left: 1px solid ${getColor(p.theme, ['grayBlue'], ['nightBlack'])};`}
`

const Cell = styled.div<CellProps>`
  ${theme.layout.flexRow}
  justify-content: space-evenly;
  flex: ${(p) => p.size || 1};
  ${theme.text('small')};
  opacity: ${(p) => (p.past ? '0.3' : 'none')};
  text-decoration: ${(p) => (p.past ? 'line-through' : 'none')};
  ${(p) => (p.hasInstruction || !p.isMobile) && 'margin-left: 16px;'}
`

const FlexRow = styled.div<isMobileProps>`
  ${theme.layout.flexRow};
  & > * {
    ${(p) => theme.spacing.right(p.isMobile ? 'smallest' : 'small')};
  }
`

const InfoRow = styled.div<isMobileProps>`
  ${theme.layout.flexRow};
  border-top: 1px solid ${(p) => getColor(p.theme, ['grayBlue'], ['nightBlack'])};
  color: ${defaultTextColor};
  ${(p) => !p.isMobile && 'margin-left: 16px'};
`

const InfoText = styled.div`
  ${theme.text('small', 'content', 'bold')};
`

const SpacingElement = styled.div`
  width: 128px;
`

type Props = {
  t: TFunction
  progress?: number
  active?: boolean
  past?: boolean
  border?: boolean
  task: Task
  isMobile?: boolean
  hasInstruction?: boolean
  isCommuter?: boolean
}

function RestRow({ t, task, past, border, isMobile, hasInstruction, isCommuter }: Props) {
  return (
    <RowContainer past={past} bottom={border} isMobile={isMobile}>
      {!isMobile && <SpacingElement />}
      <Column isMobile={isMobile}>
        <Cell size={1} past={past} hasInstruction={hasInstruction} isMobile={isMobile}>
          <IconContent>
            <FlexRow isMobile={isMobile}>
              <Emphasis color={past ? 'grayDark' : 'primaryBlue'}>
                {isCommuter ? t('rest') : t('break')}
              </Emphasis>
              <TaskDuration
                color={past ? 'grayDark' : 'primaryBlue'}
                start={task.taskStartDateTime}
                end={task.taskEndDateTime}
              />
            </FlexRow>
          </IconContent>
        </Cell>
        {hasInstruction && (
          <InfoRow isMobile={isMobile}>
            <Exclamation iconSize="smallest" />
            &nbsp;
            <InfoText>{task.instruction}</InfoText>
          </InfoRow>
        )}
      </Column>
    </RowContainer>
  )
}

type PropsIn = {
  task: Task
  taskState: TaskState
  nextTask: Task | null
  nextState: TaskState
}

const mapStateToProps = (
  state: AppState,
  { taskState, nextTask, nextState, task }: PropsIn
): unknown => {
  const now = nowSelector(state)
  const border = nextTask ? nextState.open || taskIsActive(now, nextTask) : true
  const isMobile = isMobileSelector(state)
  const hasInstruction = task.instruction && task.instruction.length > 0

  return {
    past: taskState.crossed,
    border,
    isMobile,
    hasInstruction,
  }
}

export default withTranslation()(connect(mapStateToProps)(RestRow))
