import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import DurationRow from './DurationRow'
import { ServiceOrder } from './types'

type Props = {
  serviceOrder: ServiceOrder
  showStatus: boolean
}

const ServiceOrderHeader = ({ serviceOrder, showStatus }: Props): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Box>
      <Typography variant="h2">
        {serviceOrder.activities.map((a) => a.workshopBooking).at(0)}
      </Typography>
      <Box sx={(theme) => ({ ...theme.layout.flexRow })}>
        <Typography variant="h3" sx={(theme) => ({ paddingRight: theme.spacing('tiny') })}>
          {serviceOrder.installationGroup}
        </Typography>
        <Typography variant="subtitle1">
          {serviceOrder.serialNumberDescription
            ? serviceOrder.serialNumberDescription
            : serviceOrder.serialNumber}
        </Typography>
      </Box>
      <DurationRow
        startTime={serviceOrder.plannedStartTime}
        finishTime={serviceOrder.plannedFinishTime}
        duration={serviceOrder.duration}
      />
      {showStatus && (
        <>
          <Typography variant="subtitle2" sx={{ color: 'common.primaryGreen' }}>
            {t('serviceOrder.header.status')}
          </Typography>
          <Typography sx={{ textTransform: 'uppercase' }}>
            {t(`serviceOrder.status.${serviceOrder.status}`)}
          </Typography>
        </>
      )}
    </Box>
  )
}

export default ServiceOrderHeader
