import styled from '@emotion/styled'
import { Router } from 'found'
import { TFunction } from 'i18next'
import React, { Component, ComponentType } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { TypedDispatch } from '../../'
import {
  fetchEnergyEfficiencyDataByTrainAndDates,
  fetchFindings,
  fetchTimetable,
} from '../../actions/api'
import { setPunctualityParams } from '../../actions/punctuality'
import { FETCH_FINDINGS_POLL_RATE_MS, TASK_TITLE_MAX_CHAR } from '../../constants'
import moment from '../../lib/moment-fi'
import { getName } from '../../lib/stations'
import { setPreSelectedSerialNumber } from '../../reducers/createDefectSlice'
import {
  commuterFromTrainDetails,
  commuterToTrainDetails,
  filterEquipments,
  findingsSelector,
  inferTaskEquipment,
  inferTaskEquipmentDisplay,
  resolveTaskContactReferences,
  selectOperatingDateForTask,
  selectTaskEquipment,
  shiftTasksToTimetableParams,
} from '../../Selectors'
import { getColor, LAYOUT_LEFT_EDGE, theme } from '../../Theme'
import type {
  AppState,
  Contact,
  EnergyEfficiency,
  FindingsState,
  RollingGuideData,
  Shift,
  Task,
  TaskContactRef,
  TaskDetailsInput,
  TaskEquipment,
  Timestamp,
} from '../../types'
import type { ContactProps, TaskContact, TimetableParams } from '../../types/Input'
import ContactList from '../contact/ContactList'
import EquipmentSelectionDialog from '../create-defect/EquipmentSelectionDialog'
import { dataWithStatus, Equipment } from '../create-defect/types'
import { DelayLabelRow } from '../DelayLabelRow'
import EnergyEfficiencyTaskInfo from '../energy-efficiency/EnergyEfficiencyTaskInfo'
import TaskButtonRow from './TaskButtonRow'
import TaskDetails from './TaskDetails'
import TaskReport from './TaskObservation'
import TaskTowingRowContainer from './TaskTowingRowContainer'

interface MarginSidesProps {
  active: boolean
  isMobile: boolean
}

const MarginSides = styled.div<MarginSidesProps>`
  margin: 0 12px 0 ${(p) => `${LAYOUT_LEFT_EDGE - (p.active ? 6 : 1)}px`};

  @media (min-width: ${theme.maxWidths.paddedContent}) {
    margin-left: ${(p) => LAYOUT_LEFT_EDGE + (p.active ? 110 : 115)}px;
  }

  @media (min-width: ${theme.breakpoints.large}) {
    margin-left: ${(p) => LAYOUT_LEFT_EDGE + (p.active ? 130 : 135)}px;
  }
`

const Bold = styled.div`
  ${theme.text('normal', 'content', 'bold')};
  text-transform: uppercase;
  color: ${(p) => getColor(p.theme, ['grayDark'], ['grayPink'])};
`

const Weak = styled.div`
  ${theme.text('normal', 'content')};
  color: ${(p) => getColor(p.theme, ['grayDark'], ['grayPink'])};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const EquipmentWithFindings = styled.div`
  ${theme.text('normal')};
  color: ${(p) => getColor(p.theme, ['black'], ['grayPink'])};
`

const ErrorText = styled.div`
  ${theme.text('normal', 'content', 'bold')};
  color: ${(p) => getColor(p.theme, ['red'], ['red'])};
`

type Props = {
  t: TFunction
  open: boolean
  active: boolean
  shiftId: string
  taskIndex: number
  hasInfo: boolean
  isMobile: boolean
  isCommuter: boolean
  contacts: Array<ContactProps>
  hasContacts: boolean
  router: Router
  loading: boolean
  taskEquipment: TaskEquipment
  locs: Array<string>
  commaSeparatedLocs: string
  shownEquipment: string
  taskTitle: string
  nightMode: boolean
  currentTaskId: number
  task: Task
  vehicleNumbers: Array<string>
  timetableParams: TimetableParams
  timetableError: string
  fromDetails: TaskDetailsInput
  toDetails: TaskDetailsInput
  even: boolean
  drawDelays: boolean
  delayObject: any
  setPunctualityParams: (trainDate: string, trainNumber: string) => void
  fetchTimetable: (isUsedForDriving: boolean, parts: Array<TimetableParams>) => void
  fetchFindings: (equipments: Array<string>) => void
  fetchEnergyEfficiency: (
    trainNumber: string,
    taskStartDateTime: Timestamp,
    taskEndDateTime: Timestamp
  ) => void
  setPreselectedSerialNumber: (serialNumber: string) => void
  taskNameFull: string | null | undefined
  remarks: string | null | undefined
  shift: Shift
  operatingDate: string
  showEnergyEfficiency: boolean
  energyEfficiency?: EnergyEfficiency
  energyEfficiencyLoading?: boolean
  rollingGuides?: RollingGuideData
  equipments: dataWithStatus<Equipment[]>
}

type State = Partial<{
  showContacts: boolean
  reportOpen: boolean
  showConsumptionInfo: boolean
  showCreateDefectModal: boolean
}>

class TaskRowInfo extends Component<Props, State> {
  timer: any
  static displayName: string

  constructor(props: any) {
    super(props)
    this.state = {
      showContacts: false,
      reportOpen: false,
      showConsumptionInfo: false,
      showCreateDefectModal: false,
    }
    this.toggleContacts = this.toggleContacts.bind(this)
    this.toggleReport = this.toggleReport.bind(this)
    this.toggleCreateDefectModal = this.toggleCreateDefectModal.bind(this)
    this.handleCreateDefectEvent = this.handleCreateDefectEvent.bind(this)
  }

  componentDidMount() {
    const { fetchFindings, locs, task, fetchEnergyEfficiency, showEnergyEfficiency } = this.props
    fetchFindings(locs)

    if (showEnergyEfficiency) {
      fetchEnergyEfficiency(task.trainNumber, task.taskStartDateTime, task.taskEndDateTime)
    }

    // Start timer for fetching updated findings
    this.timer = setInterval(() => {
      fetchFindings(locs)
    }, FETCH_FINDINGS_POLL_RATE_MS)
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.locs &&
      this.props.locs.some((loc) => !prevProps.locs.find((prevLoc) => prevLoc === loc))
    ) {
      this.props.fetchFindings(this.props.locs)
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        fetchFindings(this.props.locs)
      }, FETCH_FINDINGS_POLL_RATE_MS)
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  toggleContacts() {
    this.setState({
      showContacts: !this.state.showContacts,
    })
  }

  toggleReport() {
    this.setState({
      reportOpen: !this.state.reportOpen,
    })
  }

  toggleShowConsumptionInfo() {
    this.setState({
      showConsumptionInfo: !this.state.showConsumptionInfo,
    })
  }

  toggleCreateDefectModal() {
    this.setState({ showCreateDefectModal: !this.state.showCreateDefectModal })
  }

  goToCreateDefectPage() {
    this.props.router.push('/create-defect')
  }

  handleCreateDefectEvent(eqs?: string[]) {
    const { setPreselectedSerialNumber } = this.props

    if (eqs && eqs?.length > 1) this.toggleCreateDefectModal()
    else {
      if (eqs?.length === 1) setPreselectedSerialNumber(eqs[0])

      this.goToCreateDefectPage()
    }
  }

  render() {
    const {
      t,
      isCommuter,
      active,
      shiftId,
      taskIndex,
      hasInfo,
      isMobile,
      contacts,
      hasContacts,
      router,
      taskEquipment,
      locs,
      commaSeparatedLocs,
      taskTitle,
      nightMode,
      task,
      shift,
      vehicleNumbers,
      timetableParams,
      timetableError,
      fromDetails,
      toDetails,
      setPunctualityParams,
      fetchFindings,
      drawDelays,
      delayObject,
      taskNameFull,
      remarks,
      shownEquipment,
      operatingDate,
      open,
      showEnergyEfficiency,
      energyEfficiency,
      energyEfficiencyLoading,
      rollingGuides,
      setPreselectedSerialNumber,
      equipments,
    }: Props = this.props

    const rollingGuideLink: string | undefined =
      rollingGuides?.[`${task.fromStation}-${task.toStation}`]

    const filterEquipments = (
      locs: string[],
      vehicleNumbers: string[],
      equipments?: Equipment[]
    ): string[] => [
      ...(locs?.filter((l) => equipments?.some((e) => e.installationGroup === l)) ?? []),
      ...(vehicleNumbers?.filter((v) => equipments?.some((e) => e.installationGroup === v)) ?? []),
    ]

    const filteredEquipments = filterEquipments(locs, vehicleNumbers, equipments.data)

    return (
      <div onClick={(e) => e.stopPropagation()}>
        {this.state.showCreateDefectModal && (
          <EquipmentSelectionDialog
            closeDialog={this.toggleCreateDefectModal}
            submitHandler={(selectedSerialNumber) => {
              setPreselectedSerialNumber(selectedSerialNumber)
              this.goToCreateDefectPage()
            }}
            dialogText={t('defect.selectEquipment')}
            primaryActionText={t('defect.createNew')}
            equipmentIds={filteredEquipments}
          />
        )}
        {hasInfo ? (
          <MarginSides active={active} isMobile={isMobile}>
            {drawDelays && (
              <DelayLabelRow
                t={t}
                task={task}
                delays={delayObject.delays}
                delayMinutes={delayObject.delayMinutes}
                trainCategory={delayObject.trainCategory}
                isMobile={false} // show full labels in task view
                estimate={false}
                notDriving={false}
              />
            )}
            {taskTitle.length > TASK_TITLE_MAX_CHAR && (
              <Row>
                <Weak>{t('task')}</Weak>
                &nbsp;
                <Bold>{taskTitle}</Bold>
              </Row>
            )}
            {taskNameFull && (
              <Row>
                <Weak>{t('task')}</Weak>
                &nbsp;
                <Bold>{taskNameFull}</Bold>
                <Weak>, {t('train')}</Weak>
                &nbsp;
                <Bold>{`${task.trainCategory ? task.trainCategory : ''} ${
                  task.trainNumberNumeric
                }`}</Bold>
              </Row>
            )}
            <Row>
              <TaskDetails t={t} details={fromDetails} />
              <TaskDetails t={t} details={toDetails} />
            </Row>
            <EquipmentWithFindings>
              {remarks && <div>{remarks}</div>}
              <b>
                {isCommuter
                  ? vehicleNumbers && vehicleNumbers.length !== 0
                    ? `${task.traction || ''}: ${vehicleNumbers.join(', ')}`
                    : ''
                  : `${task.traction || ''}`}
              </b>
              &nbsp;
              {locs.length > 0 && task.trainNumberNumeric && shownEquipment}
            </EquipmentWithFindings>
            {taskEquipment.error && <ErrorText>{t('fetchingFindingsFailed')}</ErrorText>}
          </MarginSides>
        ) : undefined}
        {/* Button ordering:
          1. create defect
          2. findings
          3. compositions
          4. personnel
          5. timetable
        */}
        {open && (
          <MarginSides active={active} isMobile={isMobile}>
            {timetableError !== '' && <ErrorText>{t(timetableError)}</ErrorText>}
            {this.state.reportOpen ? (
              <TaskReport
                trainNumber={task.trainNumberNumeric}
                toggleReport={this.toggleReport}
                task={task}
                shift={shift}
                nightMode={nightMode}
                t={t}
              />
            ) : (
              <TaskButtonRow
                fetchFindings={() => fetchFindings(locs)}
                findingsError={taskEquipment.error || ''}
                findingsEnabled={locs.length > 0 && !!task.trainNumberNumeric}
                compositionsEnabled={
                  !!task.taskStartDateTime &&
                  !!task.fromStation &&
                  !!task.trainNumberNumeric &&
                  !isCommuter
                }
                punctualityEnabled={!!task.trainNumberNumeric}
                criticality={
                  taskEquipment.findings && taskEquipment.highestCriticality
                    ? '' + taskEquipment.highestCriticality.level
                    : null
                }
                loading={taskEquipment.loading}
                openCreateDefect={(eqs) => this.handleCreateDefectEvent(eqs)}
                filteredEquipments={filteredEquipments}
                openFindings={() => {
                  router.push(`/restrictionInfos/${commaSeparatedLocs}`)
                }}
                openCompositions={() => {
                  router.push(
                    `/composition/${moment(task.taskStartDateTime).format('YYYY-MM-DDTHH:mm')}/${
                      task.fromStation
                    }/${task.trainNumberNumeric}`
                  )
                }}
                hasContacts={hasContacts}
                contactsOpen={hasContacts && this.state.showContacts}
                toggleContacts={this.toggleContacts}
                timetableParams={timetableParams}
                openPunctuality={
                  task.trainNumberNumeric
                    ? () => {
                        setPunctualityParams(operatingDate, task.trainNumberNumeric)
                        router.push(
                          `/punctuality/${moment(operatingDate).format('DD.MM.YYYY')}/${
                            task.trainNumberNumeric
                          }`
                        )
                        /* eslint-disable @typescript-eslint/no-empty-function */
                      }
                    : () => {}
                }
                nightMode={nightMode}
                toggleReport={this.toggleReport}
                isCommuter={isCommuter}
              />
            )}
          </MarginSides>
        )}

        {hasContacts && this.state.showContacts ? (
          <MarginSides active={active} isMobile={isMobile}>
            <ContactList contacts={contacts} shiftId={shiftId} taskIndex={taskIndex} />
          </MarginSides>
        ) : undefined}

        <MarginSides active={active} isMobile={isMobile}>
          <TaskTowingRowContainer router={router} locomotives={locs} />
        </MarginSides>

        {showEnergyEfficiency &&
          !energyEfficiencyLoading &&
          (energyEfficiency || rollingGuideLink) && (
            <MarginSides active={active} isMobile={isMobile}>
              <EnergyEfficiencyTaskInfo
                energyEfficiency={energyEfficiency}
                rollingGuideLink={rollingGuideLink}
              />
            </MarginSides>
          )}
      </div>
    )
  }
}

TaskRowInfo.displayName = 'TaskRowInfo'

type PropsIn = {
  open: boolean
  active: boolean
  isMobile: boolean
  shift: Shift
  vehicleNumbers?: Array<string>
  router: Router
  locs: Array<string>
  commaSeparatedLocs: string
  taskTitle: string
  nightMode: boolean
  even: boolean
  drawDelays: boolean
  delayObject: any
  task: Task
  info: Array<{
    title: string
    value: string
  }>
  contacts: Array<TaskContactRef | Contact>
}

const mapStateToProps = (state: AppState, { shift, task, info, contacts }: PropsIn): any => {
  const isCommuter = shift.isCommuter
  const hasInfo = info.length > 0
  const hasContacts = contacts.length > 0
  const findingsState = findingsSelector(state) as FindingsState
  const taskEquipment = selectTaskEquipment(findingsState, task)
  const timetableParams = shiftTasksToTimetableParams(shift).find(
    (p) =>
      p.trainNumber === task.trainNumber &&
      moment(task.taskStartDateTime).isBetween(
        moment(p.depTime),
        moment(p.arrTime),
        'minute',
        '[]'
      ) &&
      moment(task.taskEndDateTime).isBetween(moment(p.depTime), moment(p.arrTime), 'minute', '[]')
  )

  const timetableError = state.timetable.error

  // TODO clean up the info as this is the only place it is actually used
  const pickupLocation = info.find((i) => i.title === 'Ottosijainti')
  const dropOffLocation = info.find((i) => i.title === 'Jättösijainti')

  // TODO work out if we can show the remarks better at some point
  const remarks = task.locomotives
    ? task.locomotives
        .filter((loc) => !!loc.remarks && loc.remarks !== '')
        .map((loc) => `${loc.locId}: ${loc.remarks || ''}`)
        .join('\n')
    : null

  const shownEquipment = inferTaskEquipmentDisplay(task).join(',')
  const commaSeparatedLocs = filterEquipments(inferTaskEquipment(task)).join(',')
  const fromDetails = isCommuter
    ? commuterFromTrainDetails(task)
    : {
        type: 'from',
        station: getName(task.fromStation),
        trains: task.previousArrival
          ? [
              {
                trainNumber: task.previousArrival,
                time: null,
              },
            ]
          : [],
        location: (pickupLocation && pickupLocation.value) || '',
        lockingInformation: task.lockingInformation,
      }

  const toDetails = isCommuter
    ? commuterToTrainDetails(task)
    : {
        type: 'to',
        station: getName(task.toStation),
        trains: task.nextDeparture
          ? [
              {
                trainNumber: task.nextDeparture,
                time: null,
              },
            ]
          : [],
        location: (dropOffLocation && dropOffLocation.value) || '',
      }

  const taskContacts: Array<Partial<TaskContact>> = resolveTaskContactReferences(state)(contacts)

  const taskNameFull =
    state.user.personnelGroup === 'M' &&
    (task.taskName === 'kond' || task.taskName === 'akond' || task.taskName === 'akondp') &&
    task.taskName
  //How many characters the title can approximately fit, longer titles need to be shown in the task description

  const operatingDate = selectOperatingDateForTask(task)

  // Tasks are valid for energy efficiency if:
  // 0. user is admin, read_admin, driver or logistics_driver
  // 1. trainNumber is numeric
  // 2. taskName contains trainNumber
  // 3. taskName doesn't start with letter m
  const showEnergyEfficiency =
    (state.user.admin ||
      state.user.read_admin ||
      state.user.driver ||
      state.user.logistics_driver) &&
    task.trainNumber &&
    task.taskName &&
    /^\s*\d+\s*$/.test(task.trainNumber) &&
    task.taskName.includes(task.trainNumber) &&
    !task.taskName.startsWith('m')

  const energyEfficiency = showEnergyEfficiency
    ? state.energyEfficiency.energyEfficiency?.[task.trainNumber]
    : undefined
  const energyEfficiencyLoading = showEnergyEfficiency ? state.energyEfficiency.loading : false
  const rollingGuides = state.rollingGuides.rollingGuides

  return {
    isCommuter,
    hasInfo,
    shiftId: shift.id,
    taskIndex: task.id,
    contacts: isCommuter ? taskContacts : contacts,
    hasContacts,
    taskEquipment,
    commaSeparatedLocs,
    shownEquipment,
    timetableParams,
    timetableError,
    fromDetails,
    toDetails,
    taskNameFull,
    remarks,
    operatingDate,
    showEnergyEfficiency,
    energyEfficiency,
    energyEfficiencyLoading,
    rollingGuides,
    equipments: state.defectCommonData.equipments,
  }
}

const mapDispatchToProps = (dispatch: TypedDispatch) => ({
  fetchTimetable: (isUsedForDriving: boolean, parts: TimetableParams[]) =>
    dispatch(fetchTimetable(isUsedForDriving, parts)),
  setPunctualityParams: (trainDate: string, trainNumber: string) =>
    dispatch(setPunctualityParams({ trainDate: trainDate, trainNumber: trainNumber })),
  fetchFindings: (equipments: string[]) => dispatch(fetchFindings(filterEquipments(equipments))),
  fetchEnergyEfficiency: (
    trainNumber: string,
    taskStartDateTime: Timestamp,
    taskEndDateTime: Timestamp
  ) =>
    dispatch(
      fetchEnergyEfficiencyDataByTrainAndDates(trainNumber, taskStartDateTime, taskEndDateTime)
    ),
  setPreselectedSerialNumber: (serialNumber: string) =>
    dispatch(setPreSelectedSerialNumber(serialNumber)),
})

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(TaskRowInfo) as unknown as ComponentType<PropsIn>
)
