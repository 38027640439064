import styled from '@emotion/styled'
import { Router } from 'found'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { fetchCalendarURL, fetchSchedule, refetchScheduleAndShifts } from '../actions/api'
import ActionError from '../components/ActionError'
import CalendarButton from '../components/calendar/CalendarButton'
import CalendarRest from '../components/calendar/CalendarRest'
import CalendarShift from '../components/calendar/CalendarShift'
import CalendarShiftList from '../components/calendar/CalendarShiftList'
import LoadingIndicator from '../components/LoadingIndicator'
import NextMonth from '../components/NextMonth'
import NextWeek from '../components/NextWeek'
import Page from '../components/page/Page'
import ScheduleHeader from '../components/schedule/ScheduleHeader'
import ScheduleLine from '../components/schedule/ScheduleLine'
import { isMobileSelector, nextShiftSelector, schedulePartsSelector } from '../Selectors'
import { theme } from '../Theme'
import { Action, AppState, Dispatch, I18n, SchedulePart, Session, UserState } from '../types'

const Container = styled.div`
  ${theme.spacing.bottom('small')};
  height: auto;
`
const CalendarButtonContainer = styled.div`
  ${theme.layout.fluidWidth(theme.maxWidths.content)};
`

type Props = {
  t: I18n
  loading: boolean
  error: string
  parts: Array<SchedulePart>
  user: UserState
  onReload: (session: UserState) => void
  fetchCalendar: () => void
  router: Router
  scheduleFetchTime?: Date | null
}

const SchedulePage = ({
  t,
  loading,
  error,
  user,
  parts,
  router,
  scheduleFetchTime,
  onReload,
}: Props) => {
  const scheduleIntervalMs = 1000 * 60 * 5

  useEffect(() => {
    fetchSchedule(user.number)
    const fetchScheduleInterval = setInterval(() => fetchSchedule(user.number), scheduleIntervalMs)
    return () => clearInterval(fetchScheduleInterval)
  }, [user.number, scheduleIntervalMs])

  const actionErrorProps = {
    t,
    error,
    action: () => onReload(user),
  }

  const formatScheduleFetchTime = (scheduleFetchTime: Date) =>
    scheduleFetchTime.toLocaleTimeString([], {
      hour: 'numeric',
      minute: 'numeric',
      hourCycle: 'h23',
    })

  return (
    <Page menu="schedule">
      <ScheduleHeader loading={loading} onReload={() => (loading ? null : onReload(user))} />
      <Container>
        {error ? (
          !user.commuter_driver && !user.commuter_conductor && scheduleFetchTime ? (
            <ActionError
              {...actionErrorProps}
              tProps={{ time: formatScheduleFetchTime(scheduleFetchTime) }}
              actionText="errors.schedule.lastFetched"
            />
          ) : (
            <ActionError {...actionErrorProps} actionText="retry" />
          )
        ) : undefined}
        {parts.length === 0 && !error ? (
          <LoadingIndicator size="normal" padded={true} />
        ) : undefined}
        {parts.map((part, i) => {
          return (
            <div key={i}>
              {part.type === 'shiftlist' ? <CalendarShiftList shift={part.shift} /> : undefined}
              {part.type === 'line' ? <ScheduleLine /> : undefined}
              {part.type === 'month' ? <NextMonth startsAt={part.startDateTime} /> : undefined}
              {part.type === 'week' ? <NextWeek startsAt={part.startDateTime} /> : undefined}
              {part.type === 'rest' ? (
                <CalendarRest
                  startDateTime={part.startDateTime}
                  endDateTime={part.endDateTime}
                  location={part.location}
                />
              ) : undefined}
              {part.type === 'shift' ? <CalendarShift shift={part.shift} /> : undefined}
            </div>
          )
        })}

        {parts.length > 0 && !error ? (
          <CalendarButtonContainer>
            <CalendarButton optionsPageRedirect={() => router.push('/options')} />
          </CalendarButtonContainer>
        ) : undefined}
      </Container>
    </Page>
  )
}

SchedulePage.displayName = 'SchedulePage'

const mapStateToProps = (state: AppState) => {
  const isMobile = isMobileSelector(state) as boolean
  const nextShift = nextShiftSelector(state)
  const parts = schedulePartsSelector(state)
  const scheduleFetchTime = state.shifts.scheduleFetchTime

  const user = state.user

  const loading = state.shifts.loading
  const error = state.shifts.error

  return {
    isMobile,
    loading,
    error,
    user,
    nextShift,
    parts,
    scheduleFetchTime,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onReload: (user: Session) => dispatch(refetchScheduleAndShifts(user) as unknown as Action),
  fetchCalendar: () => dispatch(fetchCalendarURL() as unknown as Action),
  fetchSchedule: (number: string) => dispatch(fetchSchedule(number) as unknown as Action),
})

export default connect(mapStateToProps, mapDispatchToProps)(SchedulePage)
