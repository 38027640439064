import { fetchCrewNotice, receiveTestPushMessage } from '../actions/api'
import { updateContactLocation } from '../actions/contacts'
import { fetchIncidentsAction } from '../actions/incidents'
import { updateDutyEvaluation } from '../actions/shiftDetails'
import { updateRedirect } from '../actions/system'
import { addToUpdatedIncidents } from '../reducers/incidentsSlice'
import { addSmDisruptionMessage } from '../reducers/smDisruptionSlice'
import { crewNoticeTargetShiftSelector, shiftByIdSelector } from '../Selectors'
import { Action, Store } from '../types'
import { homeURL, scheduleURL, shiftURL, visitURL } from './url'

const redirectToShiftWithCrewNoticesWhenUserLoads = (store: Store, crewNoticeId: string) => {
  store.subscribe(() => {
    const state = store.getState()
    if (state.system.redirectAfterLoad && state.user.originalNumber) {
      if (!state.crewNotices.byCrewNoticeId[crewNoticeId]) {
        store.dispatch(fetchCrewNotice(crewNoticeId) as unknown as Action)
      } else if (Object.keys(state.shifts.byId).length > 0) {
        store.dispatch(updateRedirect(false))
        const shiftId = crewNoticeTargetShiftSelector(store.getState())(crewNoticeId)
        store.dispatch(visitURL(shiftId ? shiftURL(shiftId) : scheduleURL()) as unknown as Action)
      } else if (state.shifts.error) {
        store.dispatch(updateRedirect(false))
      }
    }
  })
}

const redirectToShiftWhenUserLoads = (store: Store, shiftId: string) => {
  store.subscribe(() => {
    const state = store.getState()
    if (state.system.redirectAfterLoad && state.user.originalNumber) {
      if (Object.keys(state.shifts.byId).length > 0) {
        store.dispatch(updateRedirect(false))
        store.dispatch(visitURL(shiftId ? shiftURL(shiftId) : scheduleURL()) as unknown as Action)
      } else if (state.shifts.error) {
        store.dispatch(updateRedirect(false))
      }
    }
  })
}

const listener = (event: MessageEvent<any>, store: Store) => {
  if (event.data.type === 'location-update') {
    store.dispatch(updateContactLocation(event.data.location))
  }
  if (event.data.type === 'fetch-notice') {
    store.dispatch(fetchCrewNotice(event.data.messageId) as unknown as Action)
  }
  if (event.data.type === 'navigate-to-shift') {
    if (store.getState().user.originalNumber) {
      store.dispatch(fetchCrewNotice(event.data.messageId) as unknown as Action)
      const shiftId = crewNoticeTargetShiftSelector(store.getState())(event.data.messageId)
      store.dispatch(visitURL(shiftId ? shiftURL(shiftId) : scheduleURL()) as unknown as Action)
    } else {
      store.dispatch(updateRedirect(true))
      redirectToShiftWithCrewNoticesWhenUserLoads(store, event.data.messageId)
    }
  }
  if (event.data.type === 'navigate-to-shift-after-incident') {
    store.dispatch(updateRedirect(true))
    const shiftLlId = shiftByIdSelector(store.getState())(event.data.shiftId)
    shiftLlId ? redirectToShiftWhenUserLoads(store, shiftLlId) : visitURL(scheduleURL())
  }
  if (event.data.type === 'navigate-to-home') {
    store.dispatch(updateRedirect(true))
    store.dispatch(visitURL(homeURL()) as unknown as Action)
  }
  if (event.data.type === 'duty-evaluation-update') {
    store.dispatch(updateDutyEvaluation(event.data.dutyEvaluations))
  }
  if (event.data.type === 'test-push') {
    store.dispatch(receiveTestPushMessage(event.data.testPushMessage) as unknown as Action)
  }
  if (event.data.type === 'sm-disruption') {
    store.dispatch(addSmDisruptionMessage(event.data.message) as unknown as Action)
  }
  if (event.data.type === 'liito-update') {
    const liitoIncidentWrapper = event.data.liitoIncident
    if (!liitoIncidentWrapper.silent) {
      const shiftLlId = shiftByIdSelector(store.getState())(liitoIncidentWrapper.shiftId)
      store.dispatch(visitURL(shiftLlId ? shiftURL(shiftLlId) : scheduleURL()) as unknown as Action)
    } else {
      store.dispatch(fetchIncidentsAction() as unknown as Action)
      store.dispatch(addToUpdatedIncidents(liitoIncidentWrapper.messageId) as unknown as Action)
    }
  }
}

export default listener
