import { ObservationMessage } from '../types'

export type StartSendingObservationMessage = {
  type: 'START_SENDING_OBSERVATION_MESSAGE'
}
export const START_SENDING_OBSERVATION_MESSAGE = 'START_SENDING_OBSERVATION_MESSAGE'
export const startSendingObservationMessage = (): StartSendingObservationMessage => ({
  type: START_SENDING_OBSERVATION_MESSAGE,
})

export type UpdateObservationMessages = {
  type: 'UPDATE_OBSERVATION_MESSAGES'
  payload: Array<ObservationMessage>
}
export const UPDATE_OBSERVATION_MESSAGES = 'UPDATE_OBSERVATION_MESSAGES'
export const updateObservationMessages = (
  observationMessages: Array<ObservationMessage>
): UpdateObservationMessages => ({
  type: UPDATE_OBSERVATION_MESSAGES,
  payload: observationMessages,
})

export type SetObservationMessageError = {
  type: 'SET_OBSERVATION_MESSAGE_ERROR'
  payload: string
}
export const SET_OBSERVATION_MESSAGE_ERROR = 'SET_OBSERVATION_MESSAGE_ERROR'
export const setObservationMessageError = (error: string): SetObservationMessageError => ({
  type: SET_OBSERVATION_MESSAGE_ERROR,
  payload: error,
})

export type ObservationMessageAction =
  | StartSendingObservationMessage
  | UpdateObservationMessages
  | SetObservationMessageError
