import { createSlice } from '@reduxjs/toolkit'

import {
  fetchClosedDefectsAction,
  fetchMoreClosedDefectsAction,
  fetchMoreOpenDefectsAction,
  fetchOpenDefectsAction,
} from '../actions/defects/index'
import { Defect } from '../types'
import { ActionStatus } from '../types/Input'

export interface DefectsState {
  openDefects: Defect[]
  closedDefects: Defect[]
  openStatus: ActionStatus
  closedStatus: ActionStatus
  openError?: string
  closedError?: string
  openToken?: string
  closedToken?: string
  moreOpenStatus: ActionStatus
  moreClosedStatus: ActionStatus
  moreOpenError?: string
  moreClosedError?: string
}

const initialState: DefectsState = {
  openDefects: [],
  closedDefects: [],
  openStatus: 'none',
  closedStatus: 'none',
  moreOpenStatus: 'none',
  moreClosedStatus: 'none',
}

export const defectsSlice = createSlice({
  name: 'defect',
  initialState,
  reducers: {
    resetDefects: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOpenDefectsAction.pending, (state) => {
      state.openStatus = 'loading'
      state.openError = 'undefined'
    })

    builder.addCase(fetchOpenDefectsAction.fulfilled, (state, { payload }) => {
      state.openDefects = payload.defects
      state.openToken = payload.token
      state.openStatus = 'succeeded'
    })

    builder.addCase(fetchOpenDefectsAction.rejected, (state, { payload }) => {
      if (payload) {
        state.openError = payload.message
      }
      state.openStatus = 'failed'
    })

    builder.addCase(fetchMoreOpenDefectsAction.pending, (state) => {
      state.moreOpenStatus = 'loading'
      state.moreOpenError = 'undefined'
    })

    builder.addCase(fetchMoreOpenDefectsAction.fulfilled, (state, { payload }) => {
      state.openDefects = [...state.openDefects, ...payload.defects]
      state.openToken = payload.token
      state.moreOpenStatus = 'succeeded'
    })

    builder.addCase(fetchMoreOpenDefectsAction.rejected, (state, { payload }) => {
      if (payload) {
        state.moreOpenError = payload.message
      }
      state.moreOpenStatus = 'failed'
    })

    builder.addCase(fetchClosedDefectsAction.pending, (state) => {
      state.closedStatus = 'loading'
      state.closedError = 'undefined'
    })

    builder.addCase(fetchClosedDefectsAction.fulfilled, (state, { payload }) => {
      state.closedDefects = payload.defects
      state.closedToken = payload.token
      state.closedStatus = 'succeeded'
    })

    builder.addCase(fetchClosedDefectsAction.rejected, (state, { payload }) => {
      if (payload) {
        state.closedError = payload.message
      }
      state.closedStatus = 'failed'
    })

    builder.addCase(fetchMoreClosedDefectsAction.pending, (state) => {
      state.moreClosedStatus = 'loading'
      state.moreClosedError = 'undefined'
    })

    builder.addCase(fetchMoreClosedDefectsAction.fulfilled, (state, { payload }) => {
      state.closedDefects = [...state.closedDefects, ...payload.defects]
      state.closedToken = payload.token
      state.moreClosedStatus = 'succeeded'
    })

    builder.addCase(fetchMoreClosedDefectsAction.rejected, (state, { payload }) => {
      if (payload) {
        state.moreClosedError = payload.message
      }
      state.moreClosedStatus = 'failed'
    })
  },
})

export const { resetDefects } = defectsSlice.actions

export default defectsSlice.reducer
