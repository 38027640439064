export type StartSendingAmendment = {
  type: 'START_SENDING_AMENDMENT'
}
export const START_SENDING_AMENDMENT = 'START_SENDING_AMENDMENT'
export const startSendingAmendment = (): StartSendingAmendment => ({
  type: START_SENDING_AMENDMENT,
})

export type UpdateAmendment = {
  type: 'UPDATE_AMENDMENT'
}
export const UPDATE_AMENDMENT = 'UPDATE_AMENDMENT'
export const updateAmendment = (): UpdateAmendment => ({
  type: UPDATE_AMENDMENT,
})

export type SendAmendmentError = {
  type: 'SEND_AMENDMENT_ERROR'
  payload: string
}
export const SEND_AMENDMENT_ERROR = 'SEND_AMENDMENT_ERROR'
export const sendAmendmentError = (error: string): SendAmendmentError => ({
  type: SEND_AMENDMENT_ERROR,
  payload: error,
})

export type SetSelectedCauseGroup = {
  type: 'SET_CAUSE_GROUP'
  payload?: string | null
}
export const SET_CAUSE_GROUP = 'SET_CAUSE_GROUP'
export const setCauseGroup = (group?: string | null): SetSelectedCauseGroup => ({
  type: SET_CAUSE_GROUP,
  payload: group,
})

export type SetSelectedFirstLevelCause = {
  type: 'SET_CAUSE_LEVEL1'
  payload?: string | null
}
export const SET_CAUSE_LEVEL1 = 'SET_CAUSE_LEVEL1'
export const setSelectedFirstLevelCause = (group?: string | null): SetSelectedFirstLevelCause => ({
  type: SET_CAUSE_LEVEL1,
  payload: group,
})

export type SetSelectedSecondLevelCause = {
  type: 'SET_CAUSE_LEVEL2'
  payload?: string | null
}
export const SET_CAUSE_LEVEL2 = 'SET_CAUSE_LEVEL2'
export const setSelectedSecondLevelCause = (
  group?: string | null
): SetSelectedSecondLevelCause => ({
  type: SET_CAUSE_LEVEL2,
  payload: group,
})

export type ResetSelectedCause = {
  type: 'RESET_SELECTED_CAUSE'
}
export const RESET_SELECTED_CAUSE = 'RESET_SELECTED_CAUSE'
export const resetSelectedCause = (): ResetSelectedCause => ({
  type: RESET_SELECTED_CAUSE,
})

export type ResetError = {
  type: 'RESET_ERROR'
}
export const RESET_ERROR = 'RESET_ERROR'
export const resetError = (): ResetError => ({
  type: RESET_ERROR,
})

export type AmendmentAction =
  | StartSendingAmendment
  | UpdateAmendment
  | SendAmendmentError
  | SetSelectedCauseGroup
  | SetSelectedFirstLevelCause
  | SetSelectedSecondLevelCause
  | ResetSelectedCause
  | ResetError
