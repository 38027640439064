import styled from '@emotion/styled'
import React from 'react'

import TimelineCircle from './TimelineCircle'

const Section = styled.div`
  position: relative;
`

type Props = {
  type: string
  isMobile: boolean
  children: React.JSX.Element
}

function TimelineSection({ type, isMobile, children }: Props) {
  const isMain = type === 'main'
  return (
    <Section>
      <TimelineCircle
        isMobile={isMobile}
        position={isMain ? 'top' : 'center'}
        size={isMain ? 'normal' : 'small'}
      />
      {children}
    </Section>
  )
}

export default TimelineSection
