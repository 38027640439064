export type ToggleMainHeader = { type: 'TOGGLE_MAIN_HEADER' }
export const TOGGLE_MAIN_HEADER = 'TOGGLE_MAIN_HEADER'
export const toggleMainHeader = (): ToggleMainHeader => {
  return { type: TOGGLE_MAIN_HEADER }
}

export type ChangeMainDay = { type: 'CHANGE_MAIN_DAY'; payload: { index: any } }
export const CHANGE_MAIN_DAY = 'CHANGE_MAIN_DAY'
export const changeMainDay = (index: any): ChangeMainDay => {
  return { type: CHANGE_MAIN_DAY, payload: { index } }
}

export type MainPageAction = ToggleMainHeader | ChangeMainDay
