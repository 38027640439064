import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  menuItemClasses,
  Select,
  selectClasses,
  Typography,
} from '@mui/material'
import React from 'react'
import { FieldValues, useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import findValueInOptions from '../../../lib/createDefectFormUtils'
import * as S from '../component.styles'
import { FormErrorHelperText } from '../FormErrorHelperText'
import { AutoCompleteOptions, FormControls } from '../types'

export const FormInputDropdown = <T extends FieldValues>({
  name,
  options,
  label,
  status,
  required = false,
  isDisabled = false,
  customOnChange,
}: FormControls<T> & AutoCompleteOptions): JSX.Element => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  const id = `${name}-${label}`

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: { value: required, message: t('field.requiredHelperText') } },
  })

  const PopupIcon = (props: { className: string }): JSX.Element => {
    return status === 'loading' ? (
      <CircularProgress {...props} size="1.7142857142857142rem" />
    ) : (
      <ExpandMoreIcon {...props} />
    )
  }
  const placeHolder = <Typography variant="placeholder">{t('field.placeholderSelect')}</Typography>

  return (
    <FormControl color="info" variant="outlined">
      <Select
        value={findValueInOptions(field.value, options)?.value || ''}
        disabled={isDisabled}
        color="info"
        displayEmpty
        id={id}
        onBlur={field.onBlur}
        onChange={(event) => {
          const { value } = event.target
          field.onChange(value)
          if (customOnChange) {
            customOnChange(value)
          }
        }}
        renderValue={(item) => {
          if (!item) {
            return placeHolder
          }

          return options.find((itemOption) => itemOption.value === item)?.label
        }}
        IconComponent={PopupIcon}
        error={!!error?.message}
        variant="outlined"
        MenuProps={{
          sx: (theme) =>
            S.DropdownItemCss(theme, `& .${menuItemClasses.root}`, `&.${menuItemClasses.selected}`),
        }}
        sx={{
          borderRadius: '8px',
          bgcolor: (theme) => theme.palette.background.altField,
          [`& .${selectClasses.outlined}`]: (theme) => ({
            ...theme.typography.h6,
          }),
          [`& .${selectClasses.icon}`]: {
            color: (theme) => theme.palette.info.main,
            width: '1em',
            height: '1em',
            right: '9px',
          },
          // Opacity needs to be changed for the disabled state to match other inputs
          // color for some reason is not working for icon
          [`& .${selectClasses.outlined}.${selectClasses.disabled}, & .${selectClasses.icon}.${selectClasses.disabled}`]:
            {
              color: (theme) => theme.palette.text.disabled,
              opacity: 0.5,
            },
        }}
      >
        <MenuItem value="">{placeHolder}</MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText component="div">
        {error?.message ? <FormErrorHelperText error={error.message} /> : null}
      </FormHelperText>
    </FormControl>
  )
}
