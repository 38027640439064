import { TrainPunctuality } from '../types/Input'

export type FetchTrainPunctuality = {
  type: 'START_FETCHING_PUNCTUALITY'
}
export const START_FETCHING_PUNCTUALITY = 'START_FETCHING_PUNCTUALITY'
export const startFetchingTrainPunctuality = (): FetchTrainPunctuality => ({
  type: START_FETCHING_PUNCTUALITY,
})

export type UpdatePunctuality = {
  type: 'UPDATE_PUNCTUALITY'
  payload: Array<TrainPunctuality | null>
}
export const UPDATE_PUNCTUALITY = 'UPDATE_PUNCTUALITY'
export const updatePunctuality = (payload: Array<TrainPunctuality | null>): UpdatePunctuality => ({
  type: UPDATE_PUNCTUALITY,
  payload,
})

export type PunctualityError = {
  type: 'PUNCTUALITY_ERROR'
  payload: string
}
export const PUNCTUALITY_ERROR = 'PUNCTUALITY_ERROR'
export const punctualityError = (payload: string): PunctualityError => ({
  type: PUNCTUALITY_ERROR,
  payload,
})

export type SetPunctualityParams = {
  type: 'SET_PUNCTUALITY_PARAMS'
  payload: {
    trainDate: string
    trainNumber: string
  }
}
export const SET_PUNCTUALITY_PARAMS = 'SET_PUNCTUALITY_PARAMS'
export const setPunctualityParams = (payload: {
  trainDate: string
  trainNumber: string
}): SetPunctualityParams => ({
  type: SET_PUNCTUALITY_PARAMS,
  payload,
})

export type PunctualityAction =
  | FetchTrainPunctuality
  | UpdatePunctuality
  | PunctualityError
  | SetPunctualityParams
