import { SignInStatus } from '../types'

export type StartSigningIn = {
  type: 'START_SIGNING_IN'
  payload: {
    id: string
  }
}
export const START_SIGNING_IN = 'START_SIGNING_IN'
export const startSigningIn = (id: string): StartSigningIn => ({
  type: START_SIGNING_IN,
  payload: { id },
})

export type UpdateSignInStatus = {
  type: 'UPDATE_SIGN_IN_STATUS'
  payload: SignInStatus
}
export const UPDATE_SIGN_IN_STATUS = 'UPDATE_SIGN_IN_STATUS'
export const updateSignInStatus = (signInStatus: SignInStatus): UpdateSignInStatus => ({
  type: UPDATE_SIGN_IN_STATUS,
  payload: signInStatus,
})

export type UpdateSignInStatuses = {
  type: 'UPDATE_SIGN_IN_STATUSES'
  payload: Array<SignInStatus>
}
export const UPDATE_SIGN_IN_STATUSES = 'UPDATE_SIGN_IN_STATUSES'
export const updateSignInStatuses = (statuses: Array<SignInStatus>): UpdateSignInStatuses => ({
  type: UPDATE_SIGN_IN_STATUSES,
  payload: statuses,
})

export type SignInStatusAction = StartSigningIn | UpdateSignInStatus | UpdateSignInStatuses
