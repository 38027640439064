import {
  ROLLING_GUIDES_ERROR,
  START_FETCHING_ROLLING_GUIDES,
  UPDATE_ROLLING_GUIDES,
} from '../actions/rollingGuides'
import { RollingGuidesAction } from '../actions/rollingGuides'
import { RollingGuidesState } from '../types'

export const initialState = {
  loading: false,
  error: '',
  rollingGuides: {},
}

const rollingGuidesReducer = (
  state: RollingGuidesState = initialState,
  action: RollingGuidesAction
): RollingGuidesState => {
  switch (action.type) {
    case START_FETCHING_ROLLING_GUIDES:
      return {
        ...state,
        loading: true,
        error: '',
      }

    case UPDATE_ROLLING_GUIDES: {
      return {
        ...state,
        error: '',
        loading: false,
        rollingGuides: action.payload,
      }
    }

    case ROLLING_GUIDES_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default rollingGuidesReducer
