import { Box, Typography } from '@mui/material'
import { useRouter } from 'found'

import ArrowRight from '../../icons/ArrowRight'
import CircleToggle from '../circle/CircleToggle'
import DurationRow from './DurationRow'
import { Task } from './types'

type Props = {
  task: Task
  orderId: string
  activityId: string
  activityStatus: string
  setSelectedTask: (task: Task) => void
}

const TaskRow = ({
  orderId,
  activityId,
  activityStatus,
  task,
  setSelectedTask,
}: Props): JSX.Element => {
  const { router } = useRouter()

  return (
    <Box
      sx={(theme) => ({
        ...theme.layout.flexRow,
        justifyContent: 'space-between',
        paddingBottom: theme.spacing('tiny'),
      })}
    >
      <Box sx={(theme) => ({ ...theme.layout.flexRow })}>
        <Typography sx={(theme) => ({ paddingRight: theme.spacing('tiny') })}>
          {task.description}
        </Typography>
        <DurationRow duration={task.estimatedDuration} />
      </Box>
      <CircleToggle
        icon={<ArrowRight iconSize="smallest" />}
        left="12px"
        onClick={() => {
          if (['PLANNED', 'RELEASED', 'COMPLETED'].includes(activityStatus)) {
            setSelectedTask(task)
            router.push(`/serviceOrders/${orderId}/activity/${activityId}/task/${task.id}`)
          }
        }}
        color={
          activityStatus === 'COMPLETED'
            ? 'blue'
            : ['PLANNED', 'RELEASED'].includes(activityStatus)
            ? 'green'
            : 'white'
        }
      />
    </Box>
  )
}

export default TaskRow
