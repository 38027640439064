import Error from '@mui/icons-material/Error'
import { InputAdornment, TextField } from '@mui/material'
import { useState } from 'react'

import { useTypedSelector } from '../../../..'
import Checked from '../../../../icons/Checked'
import { selectUpdatedActionStatus } from '../../../../reducers/serviceOrders/actionsSlice'
import { ActionStatus } from '../../../../types/Input'
import LoadingIndicator from '../../../LoadingIndicator'
import { Action, ActionPayload } from '../../types'
import { constructPayload } from './InputUtils'

type Props = {
  wbNotStarted: boolean
  action: Action
  updateAction: (actionId: string, payload: ActionPayload) => void
}

const MeasurementTextField = ({ wbNotStarted, action, updateAction }: Props): JSX.Element => {
  const [value, setValue] = useState<string>(
    action.status === 'MEASURED' ? action.measuredValueNumeric.toString() : ''
  )

  const updatedStatus = useTypedSelector<ActionStatus>((state) => selectUpdatedActionStatus(state))

  const initialValue = action.status === 'MEASURED' ? action.measuredValueNumeric.toString() : ''

  return (
    <TextField
      variant="outlined"
      fullWidth
      disabled={wbNotStarted || updatedStatus === 'loading'}
      value={value}
      type="number"
      inputProps={{
        inputMode: 'numeric',
        pattern: /[0-9]*/,
        disabled: wbNotStarted || updatedStatus === 'loading',
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {updatedStatus === 'succeeded' && value !== '' && <Checked />}
            {updatedStatus === 'loading' && value !== '' && <LoadingIndicator size="small" />}
            {updatedStatus === 'failed' && value !== '' && <Error color="error" />}
          </InputAdornment>
        ),
      }}
      onChange={(event) => {
        setValue(event.target.value)
      }}
      onBlur={(event) => {
        const submittedValue = event.target.value
        if (submittedValue ?? submittedValue !== initialValue) {
          updateAction(
            action.id.toString(),
            constructPayload(action.inspection, undefined, parseFloat(submittedValue))
          )
          setValue(event.target.value)
        }
        if (!submittedValue) {
          setValue(initialValue)
        }
      }}
    />
  )
}

export default MeasurementTextField
