import NotificationBar, { ColorScheme } from '../notification/NotificationBar'

const red: ColorScheme = { day: 'red', night: 'red' }
const white: ColorScheme = { day: 'white', night: 'white' }

type Props = {
  message: string
  onClose?: () => void
}

export const SmDisruptionNotification = ({ message, onClose }: Props) => {
  return (
    <NotificationBar
      text={message}
      colorOverrides={{
        Bar: { background: red, border: white },
        Circle: { color: white, border: white },
        Text: { color: white },
        CloseButtonText: { color: white },
      }}
      onClose={onClose}
    />
  )
}
