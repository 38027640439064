import { styled, SxProps } from '@mui/material/styles'

export const inputField: SxProps = {
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'inputBorder',
  background: 'field',
  '& input': {
    padding: '0',
  },
}

const InputRow = styled('div')(({ theme }) => ({
  flexDirection: 'row',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingBottom: theme.spacing('smallest'),
}))

export default InputRow
