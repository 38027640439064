import {
  CLEAR_TOWING_FORM_STATE,
  START_FETCHING_TOWING_FORM_STATE,
  START_SAVING_TOWING_FORM_STATE,
  START_SAVING_TOWING_STEP,
  TOWING_FORM_STATE_ERROR,
  UPDATE_TOWING_FORM_STATE,
} from '../actions/towingFormState'
import { TowingFormStateAction } from '../actions/towingFormState'
import { TowingFormReduxState } from '../types/States'

export const initialState = {
  loading: false,
  saving: false,
  error: '',
  id: '',
  vehicleType: '',
  vehicleNumber: '',
  actionsAndSelectionsByKey: {},
  contentfulId: '',
  stateChanges: [],
  savingStep: '',
  lastSaved: '',
}

// TODO: consider refactoring done and reversed towing steps into a separate reducer
const towingFormStateReducer = (
  state: TowingFormReduxState = initialState,
  action: TowingFormStateAction
): TowingFormReduxState => {
  switch (action.type) {
    case START_FETCHING_TOWING_FORM_STATE:
      return {
        ...initialState,
        loading: true,
        error: '',
      }

    case UPDATE_TOWING_FORM_STATE:
      return {
        ...state,
        loading: false,
        saving: false,
        error: '',
        id: action.payload.id,
        vehicleType: action.payload.vehicleType,
        vehicleNumber: action.payload.vehicleNumber,
        actionsAndSelectionsByKey: {
          ...state.actionsAndSelectionsByKey,
          ...action.payload.actionsAndSelectionsByKey,
        },
        lastSaved: action.payload.lastSaved,
        contentfulId: action.payload.contentfulId,
        stateChanges: action.payload.stateChanges,
        savingStep: '',
      }

    case START_SAVING_TOWING_STEP:
      return {
        ...state,
        savingStep: action.payload,
      }

    case START_SAVING_TOWING_FORM_STATE: // TODO: create a separate START_SAVING_TOWING_STEP action
      return {
        ...state,
        saving: true,
        actionsAndSelectionsByKey: {
          ...state.actionsAndSelectionsByKey,
          ...action.payload.actionsAndSelectionsByKey,
        },
      }

    case CLEAR_TOWING_FORM_STATE:
      return initialState

    case TOWING_FORM_STATE_ERROR:
      return {
        ...state,
        loading: false,
        saving: false,
        savingStep: '',
        error: action.payload,
      }

    default:
      return state
  }
}

export default towingFormStateReducer
