import styled from '@emotion/styled'
import _ from 'lodash'
import React, { Component } from 'react'

import Star from '../../icons/Star'
import { Color, color, getColor, theme } from '../../Theme'
import { MaybeColor } from '../../types'
import { isMobileProps } from '../../types/App'
import Link from '../Link'

interface RowProps extends isMobileProps {
  bg: MaybeColor
}

interface RatingLinkProps {
  color: MaybeColor
  hover: string
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => unknown | null
  onMouseOut: (event: React.MouseEvent<HTMLAnchorElement>) => unknown | null
  onMouseOver: (event: React.MouseEvent<HTMLAnchorElement>) => unknown | null
}

const Row = styled.div<RowProps>`
  ${theme.layout.flexRow};
  ${theme.spacing.ends('small')};
  background: ${(p) => getColor(p.theme, [p.bg, 'white'], ['nightHighlight'])};
  box-sizing: border-box;
  flex: 2;
  height: ${(p) => (p.isMobile ? 'auto' : '100%')};
  justify-content: center;
  text-align: center;
`

const RatingLink = styled(Link)<RatingLinkProps>`
  ${theme.layout.flexRow};
  ${theme.spacing.all('small')};
  ${theme.text('largest', 'content', 'bold')};
  box-sizing: border-box;
  color: ${(p) => color(p.hover === 'true' ? p.color : 'grayDark')};
  justify-content: center;
  width: ${theme.spacing.sizes.huge};
  text-align: center;
  &:hover {
    color: ${(p) => color(p.color)};
  }
`

type Props = {
  isMobile: boolean
  onClick?: (event: number | React.MouseEvent<HTMLAnchorElement>) => unknown | null
  bg: MaybeColor
  getUrl: (arg0: number) => string
  stars: number | null
}

type State = {
  hoverStars: number
  hover: Array<boolean>
  mouseIn: boolean
}

class RatingLinks extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      hoverStars: 0,
      hover: [false, false, false, false],
      mouseIn: false,
    }
    this.onHover = this.onHover.bind(this)
  }

  onOver(state: boolean): void {
    this.setState({ mouseIn: state })
  }

  onHover(number: number, state: boolean) {
    const hover = this.state.hover.slice(0)
    hover[number] = state
    const hoverStars = 1 + _.findIndex(hover, (flag) => flag === true)
    this.setState({
      hover,
      hoverStars,
    })
  }

  render() {
    const { isMobile, onClick, bg, getUrl, stars } = this.props
    const { hoverStars, hover, mouseIn } = this.state
    return (
      <Row
        onMouseOver={() => this.onOver(true)}
        onMouseOut={() => this.onOver(false)}
        isMobile={isMobile}
        bg={bg}
      >
        {hover.map((hoverState, index) => (
          <RatingLink
            key={index}
            color={`star${index + 1}` as Color}
            to={getUrl(index + 1)}
            hover={(hoverState || (mouseIn ? hoverStars : stars || 0) > index).toString()}
            onClick={() => (onClick ? onClick(hoverStars) : undefined)}
            onMouseOver={() => this.onHover(index, true)}
            onMouseOut={() => this.onHover(index, false)}
          >
            <Star iconSize="normal" />
          </RatingLink>
        ))}
      </Row>
    )
  }
}

export default RatingLinks
