import { Causes } from '../types'

export const UPDATE_CAUSES = 'UPDATE_CAUSES'
export type UpdateCauses = {
  type: 'UPDATE_CAUSES'
  payload: Causes
}
export const updateCauses = (causes: Causes): UpdateCauses => ({
  type: UPDATE_CAUSES,
  payload: causes,
})

export type SetCausesError = {
  type: 'FETCH_CAUSES_ERROR'
  payload: string
}
export const FETCH_CAUSES_ERROR = 'FETCH_CAUSES_ERROR'
export const causesFetchingError = (error: string): SetCausesError => ({
  type: FETCH_CAUSES_ERROR,
  payload: error,
})

export const START_FETCHING_CAUSES = 'START_FETCHING_CAUSES'
export type FetchCauses = {
  type: 'START_FETCHING_CAUSES'
}
export const startFetchingCauses = (): FetchCauses => ({
  type: START_FETCHING_CAUSES,
})

export type CausesAction = UpdateCauses | SetCausesError | FetchCauses
