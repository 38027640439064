import { TowingFormContent, TowingStep } from '../types'

export type StartFetchingTowingForm = {
  type: 'START_FETCHING_TOWING_FORM'
}
export const START_FETCHING_TOWING_FORM = 'START_FETCHING_TOWING_FORM'
export const startFetchingTowingForm = (): StartFetchingTowingForm => ({
  type: START_FETCHING_TOWING_FORM,
})

export type UpdateTowingForm = {
  type: 'UPDATE_TOWING_FORM'
  payload?: TowingFormContent
}
export const UPDATE_TOWING_FORM = 'UPDATE_TOWING_FORM'
export const updateTowingForm = (towingForm?: TowingFormContent): UpdateTowingForm => ({
  type: UPDATE_TOWING_FORM,
  payload: towingForm,
})

export type TowingFormError = {
  type: 'TOWING_FORM_ERROR'
  payload: {
    error: string
  }
}
export const TOWING_FORM_ERROR = 'TOWING_FORM_ERROR'
export const towingFormError = (error: string): TowingFormError => ({
  type: TOWING_FORM_ERROR,
  payload: { error },
})

export type ClearTowingForm = {
  type: 'CLEAR_TOWING_FORM'
}
export const CLEAR_TOWING_FORM = 'CLEAR_TOWING_FORM'
export const clearTowingForm = (): ClearTowingForm => ({
  type: CLEAR_TOWING_FORM,
})

export type StartFetchingTowingStep = {
  type: 'START_FETCHING_TOWING_STEP'
  payload: {
    towingStepId: string
  }
}
export const START_FETCHING_TOWING_STEP = 'START_FETCHING_TOWING_STEP'
export const startFetchingTowingStep = (towingStepId: string): StartFetchingTowingStep => ({
  type: START_FETCHING_TOWING_STEP,
  payload: { towingStepId },
})

export type UpdateTowingSteps = {
  type: 'UPDATE_TOWING_STEPS'
  payload: Array<TowingStep>
}
export const UPDATE_TOWING_STEPS = 'UPDATE_TOWING_STEPS'
export const updateTowingSteps = (towingSteps: Array<TowingStep>): UpdateTowingSteps => ({
  type: UPDATE_TOWING_STEPS,
  payload: towingSteps,
})

export type TowingFormAction =
  | StartFetchingTowingForm
  | UpdateTowingForm
  | TowingFormError
  | ClearTowingForm
  | StartFetchingTowingStep
  | UpdateTowingSteps
