import styled from '@emotion/styled'
import { TFunction } from 'i18next'
import React, { useState } from 'react'

import ArrowDown from '../../icons/ArrowDown'
import ArrowUp from '../../icons/ArrowUp'
import Exclamation from '../../icons/Exclamation'
import { defaultTextColor, getColor, theme } from '../../Theme'

const BackupContainer = styled.div`
  ${theme.layout.flexColumn};
  align-items: flex-start;
  ${theme.effects.shadow};
  background: ${(p) => getColor(p.theme, ['white'], ['nightBlack'])};
`

const DescriptionRow = styled.div`
  ${theme.layout.flexRow};
  ${theme.spacing.sides('small')};
  color: ${defaultTextColor};
`

const BackupDescription = styled.div`
  ${theme.text('normal', 'content', 'bold')};
  ${theme.spacing.sides('small')};
  text-align: center;
`

const BackupLink = styled.a`
  ${theme.text('small')};
  ${theme.spacing.sides('large')}
`

const LinkList = styled.div`
  ${theme.layout.flexRow};
  justify-content: space-evenly;
  width: 100%;
`

type Props = {
  t: TFunction
}

const BackupTimetable = ({ t }: Props) => {
  const [showLinks, setShowLinks] = useState(false)

  return (
    <BackupContainer>
      <DescriptionRow onClick={() => setShowLinks(!showLinks)}>
        <Exclamation iconSize="extraHuge" />
        <BackupDescription>{t('backupTimetableDescription')}</BackupDescription>
        {showLinks ? <ArrowUp iconSize="large" /> : <ArrowDown iconSize="large" />}
      </DescriptionRow>
      {showLinks && (
        <LinkList>
          <BackupLink target={'_blank'} href={t('backupTimetableLink.link1')}>
            {t('link1')}
          </BackupLink>
          <BackupLink target={'_blank'} href={t('backupTimetableLink.link2')}>
            {t('link2')}
          </BackupLink>
        </LinkList>
      )}
    </BackupContainer>
  )
}

export default BackupTimetable
